'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentConsignmentListCtrl',
    function (
      // common
      $state, $rootScope, $scope, $timeout, $filter, gettextCatalog,
      // info
      settings, userInfo,
      // SVC
      commonSVC, shipmentSVC, onlineProductSVC,
      // Model
      shipmentModel, commonModel, atalkModel,
      // List
      warehouseList, systemList, gridInfo, supplierList, deliveryInfoList, deliveryInfoListAll
    ) {
      // 권한있는 계정에서 위탁주문탭 북마크 후 권한없는 계정으로 접속 시 탭꺼지도록 수정
      if (!$rootScope.osse_sol) {
        commonSVC.showMessage('권한이 없는 사용자입니다.');
        $rootScope.$emit('$closeTab', 'order_shipment_consignment_list');
      }
      $scope.tabActive = 0;

      $scope.domesticInit = true;
      $scope.aggregationInit = false;

      $scope.excelFieldList = angular.copy(shipmentSVC.excelFieldList.consignment);
      const consignmentTableEditList = {}; // 리스트 수정 현재 값 데이터 객체
      const channelList = angular.copy($rootScope.use_channel_list.concat({ shop_name: '직접입력', shop_cd: 'A000' }));
      let selectedDate;
      // 별칭정보 담기
      const seller_nick_info = [];
      const supplier = angular.copy(supplierList.data.result);
      supplier.unshift({ supp_no: 'null', supp_name: '매입처 없음', use_yn: 1 }); // 매입처 없음 필터 추가

      _.forEach(channelList, function(chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });

      $scope.bundle_group = {}; // 묶음 코드
      let prodList = []; //주문리스트(SKU상품정보)
      let addProdList = []; //주문리스트(추가구매옵션상품정보)
      let orderList = {};
      let aggregationList = {};

      $scope.totalCount = 0;
      $scope.isDepotAccount = userInfo.user.auth_type === '배송처';
      $scope.selectCount = 'total';
      $scope.selectedShopType = systemList.data.main_use_mode === '국내' ? 'domestic' : 'global';
      // 사용 택배사 미설정 시 전체 택배사 노출
      $scope.deliveryList = deliveryInfoList || deliveryInfoListAll;
      $scope.tabActive = 0;

      // 컬럼 설정 정보
      const menu_data = gridInfo.menu_data ? gridInfo.menu_data : false;
      /* 검색 및 버튼 관련 변수 */
      const searchTemplate = {
        searchForm: {
          search_key: '',
          search_word: '',
          search_type: 'partial',
          shopType: '',
          date_type: 'wdate',
          sdate: $scope.tabActive === 0 ? moment().subtract(1, 'months').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          shop_id: '',
          shop_cd: '',
          recent_month: 1,
          page: 'consignment',
          bundle_yn: true,
          delivery_vendor: '',        // 배송처
          gift_prod_use_yn: '',       // 규칙사은품 사용여부
          gift_prod_name: '',         // 규칙적용사은품
          multi_search_word: '',      // 멀티서치워드,
          multi_type: 'shop_sale_no', // 멀티서치 타입
          memo_yn: '',  // 메모여부
          delay_status: '', // 배송지연 여부
          multi_shop_id: '',
          multi_deliver_vendor: '',
          multi_supplier_vendor: ''
        },
        searchData: {
          all_checked: false,  // 전체선택 여부
          selectType: true,    // 셀렉트카운팅시 번들갯수 말고 row별로 처리 여부
          selectCount: 0,       // 검색전체 건수
          selectBundleCnt: 0,  // 선택 묶음건수
          totalCount: 0,       // 검색전체 건수
          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: '' },
            { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
            { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
            { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name,attri' },
            { label: gettextCatalog.getString('옵션명, 추가구매옵션'), value: 'shop_opt_name,shop_add_opt_name' },
            { label: gettextCatalog.getString('주문자명, 수령자명'), value: 'order_name,order_id,to_name' },
            { label: gettextCatalog.getString('주소'), value: 'to_addr' },
            { label: gettextCatalog.getString('주문자, 수령자 연락처'), value: 'tel' },
            { label: gettextCatalog.getString('메모'), value: 'memo' },
            { label: gettextCatalog.getString('사은품'), value: 'gift_name' },
            { label: gettextCatalog.getString('배송메시지'), value: 'ship_msg' },
            { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
            { label: gettextCatalog.getString('배송번호'), value: 'shop_ship_no' }
          ],
          search_date_type: [
            { label: gettextCatalog.getString('주문 수집일'), value: 'wdate' },
            { label: gettextCatalog.getString('주문일'), value: 'ord_time' },
            { label: gettextCatalog.getString('결제 완료일'), value: 'pay_time' },
            { label: gettextCatalog.getString('상태 변경일'), value: 'ord_status_mdate' },
            { label: gettextCatalog.getString('발송 예정일'), value: 'ship_plan_date' },
            { label: gettextCatalog.getString('주문 확인일'), value: 'ord_confirm_time' },
            { label: gettextCatalog.getString('출고 지시일'), value: 'out_order_time' },
            { label: gettextCatalog.getString('출고 완료일'), value: 'out_time' },
            { label: gettextCatalog.getString('송장 전송일'), value: 'invoice_send_time' },
            { label: gettextCatalog.getString('송장 입력일'), value: 'invoice_time' },
            { label: gettextCatalog.getString('매출 기준일'), value: 'sales_standard_time' },
            { label: gettextCatalog.getString('지급 예정일'), value: 'depot_sett_plan_date' },
          ],
          search_multi_items: [
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
            { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('운송장 번호'), value: 'invoice_no' },
            { label: gettextCatalog.getString('쇼핑몰(계정)'), value: 'shop' },
            { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
            { label: gettextCatalog.getString('배송처'), value: 'depot' },
            { label: gettextCatalog.getString('매입처'), value: 'supp' }
          ]
        },
        searchDetail: [
          {
            // 지급 주기 선택
            title: gettextCatalog.getString('지급 주기 선택'),
            search_name: 'depot_sett_period',
            item_list: [
              { key: 'all', value: '전체' },
              { key: '7일', value: '7일' },
              { key: '15일', value: '15일' },
              { key: '1개월', value: '1개월' }
            ],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 배송처 선택
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'delivery_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            select_value: '',
            add_class: 'select-search'

          },
          {
            // 매입처 선택
            title: gettextCatalog.getString('매입처 선택'),
            search_name: 'supp_vendor',
            item_list: Array.from(supplier) || [],
            item_key: 'supp_name',
            item_value: 'supp_no',
            select_value: '',
            add_class: 'select-search'
          },
          {
            // 채널 선택
            title: gettextCatalog.getString('쇼핑몰 선택'),
            search_name: 'shop_cd',
            item_list: commonSVC.getSiteList(channelList),
            item_key: 'shop_name',
            item_value: 'shop_cd',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              // 글로벌 쇼핑몰 여부 확인
              return $scope.selectedShopType === 'domestic' ? !option.pa_shop_cd?.startsWith('X') : option.pa_shop_cd?.startsWith('X');
            },
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
            search_name: 'shop_id',
            item_list: commonSVC.getSiteIdList(channelList),
            item_key: 'shop_id',
            item_value: 'search_shop_id',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              return !option.pa_shop_cd?.startsWith('X') ? option.shop_cd == $scope.searchForm.shop_cd && option.shop_id : _.intersection([$scope.searchForm.shop_cd], option.shop_cds).length && option.shop_id;
            }
          },
          {
            // 배송지연 여부
            title: gettextCatalog.getString('배송지연여부'),
            search_name: 'delay_status',
            item_list: [{ key: 1, value: '배송지연 O' }, { key: 0, value: '배송지연 X' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 출고가능 여부
            title: gettextCatalog.getString('출고가능 여부'),
            search_name: 'unstore_status',
            item_list: [{ key: 1, value: '출고 가능' }, { key: 0, value: '출고 불가능' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 매칭여부
            title: gettextCatalog.getString('매칭 여부'),
            search_name: 'map_yn',
            item_list: [{ key: 1, value: '매칭 됨' }, { key: 0, value: '매칭 안됨' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 메모여부
            title: gettextCatalog.getString('메모여부'),
            search_name: 'memo_yn',
            item_list: [{ key: 1, value: '메모 있음' }, { key: 0, value: '메모 없음' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 합포장여부
            title: gettextCatalog.getString('합포장 여부'),
            search_name: 'multi_bundle_yn',
            item_list: [{ key: 1, value: '합포장' }, { key: 0, value: '합포장 아님' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          }
        ],
        searchDetailMulti: [
          {
            title: gettextCatalog.getString('쇼핑몰(계정) 선택'),
            search_name: 'multi_shop_id',
            item_list: angular.copy(channelList).sort(function(prev, curr) {
              return `{${curr.pa_shop_cd} ${curr.shop_name}` < `{${prev.pa_shop_cd} ${prev.shop_name}` ? 1 : -1;
            }),
            item_key: 'shop_id',
            item_value: 'shop_id',
            search_word: '',
            filter: function(option) {
              return $scope.selectedShopType === 'domestic' ? !option.pa_shop_cd?.startsWith('X') : option.pa_shop_cd?.startsWith('X') && !option.shop_cd?.startsWith('X');
            }
          },
          {
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'multi_deliver_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            search_word: ''
          },
          {
            title: gettextCatalog.getString('매입처 선택'),
            search_name: 'multi_supplier_vendor',
            item_list: supplierList.data.result || [],
            item_key: 'supp_name',
            item_value: 'supp_no',
            search_word: ''
          }
        ]
      };

      if (!$scope.isDepotAccount) {
        searchTemplate.searchDetail.unshift({
          // 지급 정산 확정 여부
          title: gettextCatalog.getString('지급 정산 확정 여부'),
          search_name: 'depot_sett_plan_date_yn',
          item_list: [{ key: '', value: '전체' }, { key: 1, value: '확정' }, { key: 0, value: '미확정' }],
          item_key: 'value',
          item_value: 'key',
          select_value: ''
        });
      }
      if ($rootScope.user_profile.auth_type === '배송처') {
        const searchIndex = _.findIndex(searchTemplate.searchDetail, { search_name: 'delivery_vendor' });

        if (searchIndex > -1) {
          searchTemplate.searchDetail.splice(searchIndex, 1);
        }
      }

      let domesticSearch = angular.copy(searchTemplate);

      function setSearch(search) {
        $scope.searchData = search.searchData;
        $scope.searchForm = search.searchForm;
        $scope.searchDetail = search.searchDetail;
        $scope.searchDetailMulti = search.searchDetailMulti;
        $scope.searchDetailMultiSelect = search.searchDetailMultiSelect;
      }

      function resetSearch() {
        const showCount = $scope.searchData?.showCount;
        const search = domesticSearch = angular.copy(searchTemplate);

        if (showCount) {
          search.searchData.showCount = showCount;
        }
      }

      $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function() {
          const page = $scope.tabNumber === 1 ? 'aggregation' : 'domestic';

          $scope.searchDo(true, true, null, null, page);
        },
        resetDo: function() {
          $scope.resetDo();
        },
        changeLen: function(count) {
          $scope.changeCount(count);
        },
        getOrderList: function () {
          // 주문 요약정보 매칭 정보
          _.forEach(orderList, function (order) {
            if (prodList[order.uniq]) {
              order.sku_pack = prodList[order.uniq][0].sku_cd;
              order.prod_name_pack = prodList[order.uniq][0].prod_name;
            }
          });

          return orderList;
        }
      };

      $scope.selectRowUniqList = [];

      $scope.isMemoDisabled = (uniq) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(uniq);

      // 데이터 테이블에서 선택한 rowList 정보 받아옴
      $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
        $scope.selectRowUniqList = data.map(({ uniq }) => uniq);
        $scope.$apply();
      }, 200));

      /* 건별 데이터 테이블 */
      $scope.domestic = {};
      $scope.domestic.methods = {};
      $scope.domestic.options = {
        page: 'consignment',
        pinningColumns: ['widget'],
        defaultSortingColumns: ['wdate'],
        notMovingColumns: [],
        notSortingColumns: ['sales_type'],
        notVisibleColumns: ['notice_msg', 'pay_time', 'ord_confirm_time', 'sales', 'ship_delay_yn', 'order_tel', 'order_htel', 'to_tel', 'c_sale_cd', 'barcode', 'last_exceldown_time', 'stock_cd', 'total_cnt', 'shop_ship_no', 'model_no', 'seller_discount', 'shop_discount', 'coupon_discount', 'point_discount', 'ship_hope_time', 'order_msg', 'bundle_avail_yn', 'shop_ord_no_real'],
        bundleOptions: {
          bundleCountKey: 'selectBundleCnt',
          bundleDataKey: 'bundle_no',
          bundleUniqKey: 'uniq'
        },
        multiSort: true, // 다중정렬 가능
        addColGrid: !$rootScope.user_profile.pa_sol_no, // LG 대리점 계정은 컬럼추가 작업 불가능
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/order/consignment-list`,
          requestWillAction: function(data) {
            $scope.searchForm.shopType = 'domestic';

            if (userInfo.user.auth_type === '배송처' && !userInfo.user.depot_no) {
              commonSVC.showMessage('담당 배송처가 없습니다.');
            }

            if ($scope.isDepotAccount) {
              data.depot_sett_plan_date_yn = '1';
            }

            data = angular.merge({}, data, $scope.searchForm);

            // DB의 1개 필드로 2개 테이블에 컬럼 생성 시 column의 name을 다르게 설정해야하는데, 정렬을 기존 컬럼으로 돌아야해서 해당 처리
            if (data.orderby.includes('exchange_com_time')) {
              data.orderby = data.orderby.replace('exchange_com_time', 'claim_com_time');
            }

            $scope.pageData = angular.copy(data);
            $scope.searchData.showCount = data.length;

            return data;
          },
          requestDidAction: function(result) {
            // SKU상품정보 처리
            prodList = {};
            addProdList = {};

            result.results_prod.forEach(function(subRow) {
              if (subRow.add_opt_yn == 1) {
                if (!addProdList[subRow.uniq]) { addProdList[subRow.uniq] = []; }

                addProdList[subRow.uniq].push(subRow);

              } else {
                if (!prodList[subRow.uniq]) { prodList[subRow.uniq] = []; }

                prodList[subRow.uniq].push(subRow);
              }
            });

            orderList = result.results;
            $scope.searchData.totalCount = result.recordsTotalCount;
            $scope.bundleCnt = result.recordsTotal;

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 120,
            template: function(row) {
              return `<button class="btn btn-default btn-xxs mr-5" ng-click="$event.preventDefault(); grid.appScope.showDetail('${row.uniq}', false, ${row.isEditing},'${row.bundle_no}')" >상세</button>
              <button ng-disabled="grid.appScope.isMemoDisabled('${row.uniq}')" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="$event.preventDefault(); grid.appScope.leftTabAction(2)">메모</button>`;
            }
          },
          {
            key: 'depot_no',
            title: '배송처코드',
            name: 'depot_no',
            width: 90
          },
          {
            key: 'depot_name',
            title: '배송처명',
            name: 'depot_name',
            width: 150
          },
          {
            key: 'depot_sett_period',
            title: '지급주기',
            name: 'depot_sett_period',
            width: 90,
            template: (row) => {
              return row.depot_sett_period || '-';
            }
          },
          {
            key: 'ord_time',
            title: '주문일',
            width: 130,
            name: 'ord_time',
            filter: 'dateValid',
          },
          {
            key: 'out_time',
            title: '출고일',
            width: 130,
            name: 'out_time',
            filter: 'dateValid',
          },
          {
            key: 'exchange_com_time',
            title: '교환완료일',
            width: 130,
            name: 'claim_com_time',
            template: (row) => {
              return row.ord_status !== '반품완료' && !Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? $filter('dateValid')(row.claim_com_time) : '-';
            },
          },
          {
            key: 'claim_com_time',
            title: '반품완료일',
            width: 130,
            name: 'claim_com_time',
            template: (row) => {
              return row.ord_status === '반품완료' && !Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? $filter('dateValid')(row.claim_com_time) : '-';
            },
          },
          {
            key: 'sales_standard_time',
            title: '매출기준일',
            width: 130,
            name: 'sales_standard_time',
            filter: 'dateValid',
          },
          {
            key: 'depot_sett_plan_date',
            title: '지급예정일',
            width: 80
          },
          {
            key: 'to_name',
            title: '수령자명',
            width: 100
          },
          {
            key: 'shop_name',
            title: '쇼핑몰(계정)',
            width: 150,
            template: function(row) {
              let img = '직접입력';

              // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
              if (row.shop_cd !== 'A000') {
                const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

                img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                  ${row.shop_cd?.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.pa_shop_cd}.png" style="width: 50px;">` }
                  ${shop_info[1]}
                </span>`;
              }

              return img;
            }
          },
          {
            key: 'shop_ord_no',
            title: '쇼핑몰 주문번호',
            width: 190,
            name: 'shop_ord_no',
            filter: 'isNullHyphen'
          },
          {
            key: 'sales_type',
            title: '판매구분',
            name: 'sales_type',
            width: 80
          },
          {
            key: 'shop_sale_no',
            title: '상품코드',
            width: 140,
            template: function(row) {
              if (row.shop_cd === 'A011') {
                row.shop_sale_no = row.misc16;
              }

              let sale_no = row.shop_sale_no || '';

              if (row.shop_cd === 'B378') { // 쿠팡
                sale_no = row.misc5;
                if (row.misc9) {
                  sale_no += `?vendorItemId=${row.misc9}`;
                }
              } else if (row.shop_cd === 'A524' && row.misc17) { // 롯데온
                sale_no += `?sitmNo=${row.misc17}`;
              }

              if (sale_no && !onlineProductSVC.impossibeDetailView.some(shop_cd => row.shop_cd.includes(shop_cd))) {
                return (
                  `<span>
                    <a ng-click="grid.appScope.goOlProdList('${row.shop_sale_no}')">${row.shop_sale_no}</a>
                    <i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${row.shop_cd}','${row.shop_id}','${sale_no}')"></i></span>`
                );
              } else {
                return row.shop_sale_no;
              }
            }
          },
          {
            key: 'sale_cnt',
            title: '수량',
            width: 60,
            template: function(row) {
              if (consignmentTableEditList?.[row.bundle_no]?.[row.uniq]?.sale_cnt) {
                row.sale_cnt = consignmentTableEditList[row.bundle_no][row.uniq].sale_cnt;
              }

              const value = row.sale_cnt;

              return `${value}`;
            }
          },
          {
            key: 'ship_cost',
            title: '배송비',
            width: 100,
            template: function(row) {
              return $filter('currency')(row.ship_cost, '', 0);
            }
          },
          {
            key: 'shop_cost_price',
            title: '원가',
            width: 100,
            template: function(row) {
              return row.shop_cost_price;
            }
          },
        ]
      };

      if (!$scope.isDepotAccount) {
        $scope.aggregation = {};
        $scope.aggregation.methods = {};
        $scope.aggregation.options = {
          page: 'consignment_aggregation',
          pinningColumns: ['widget'],
          defaultSortingColumns: ['depot'],
          notVisibleColumns: [],
          notMovingColumns: [],
          multiSort: true, // 다중정렬 가능
          addColGrid: !$rootScope.user_profile.pa_sol_no, // LG 대리점 계정은 컬럼추가 작업 불가능
          externalRequestOptions: {
            requestUrl: `${settings.pa20ApiUrl}/app/order/consignment-list`,
            requestWillAction: function(data) {
              $scope.searchForm.shopType = 'domestic';
              $scope.searchForm.isAggregation = !!$scope.tabActive;
              if (userInfo.user.auth_type === '배송처' && !userInfo.user.depot_no) {
                commonSVC.showMessage('담당 배송처가 없습니다.');
              }

              data = angular.merge({}, data, $scope.searchForm);
              $scope.searchData.showCount = data.length;
              $scope.pageData = angular.copy(data);

              return data;
            },
            requestDidAction: function(result) {
            // SKU상품정보 처리
              prodList = {};
              addProdList = {};

              result.results_prod.forEach(function(subRow) {
                if (subRow.add_opt_yn == 1) {
                  if (!addProdList[subRow.uniq]) { addProdList[subRow.uniq] = []; }

                  addProdList[subRow.uniq].push(subRow);

                } else {
                  if (!prodList[subRow.uniq]) { prodList[subRow.uniq] = []; }

                  prodList[subRow.uniq].push(subRow);
                }
              });

              $scope.searchData.totalCount = result.recordsTotalCount;
              $scope.bundleCnt = result.recordsTotal;

              aggregationList = result.results;

              return result.results;
            }
          },
          columns: [
            {
              key: 'depot_no',
              title: '배송처코드',
              name: 'depot_no',
              width: 300
            },
            {
              key: 'depot_name',
              title: '배송처명',
              name: 'depot_name',
              width: 300
            },
            {
              key: 'depot_sett_period',
              title: '지급주기',
              name: 'depot_sett_period',
              width: 300,
              template: (row) => {
                return row.depot_sett_period || '-';
              }
            },
            {
              key: 'shop_cost_price',
              title: '원가총액',
              width: 300,
              template: function(row) {
                return $filter('currency')(row.shop_cost_price, '', 0);
              }
            },
            {
              key: 'ship_cost',
              title: '배송비 총액',
              width: 300,
              template: function(row) {
                row.ship_cost = $filter('currency')(row.ship_cost, '', 0);

                return row.ship_cost;
              }
            }
          ]
        };
      }

      resetSearch();
      setSearch(domesticSearch);

      $scope.grid = $scope.tabActive ? $scope.aggregation : $scope.domestic;
      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, callback) {
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = function() {
        $scope.grid = $scope.tabActive === 0 ? $scope.domestic : $scope.aggregation;
        resetSearch();
        setSearch(domesticSearch);
        $scope.selectCount = 'total';
        // 일괄입력 값 초기화
        $scope.setAllData = {
          type: '',
          ship_method: '',
          ship_cost: 0,
          carr_no: '',
          carr_name: ''
        };

        $scope.searchDo(true, true);
      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };

      /**
       * 상세페이지 보여주기
       */
      $scope.showDetail = function(uniq, isGlobal, isEditing, bundle) {
        const resolve = {
          data: {
            fromPage: searchTemplate.searchForm.page,
            uniq: uniq,
            warehouseList: warehouseList.data.result || [],
            systemList: systemList.data
          }
        };
        let modal;
        if (isGlobal) {
          modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailGlobalCtrl', 'views/order/shipment/detailGlobal.html');
        } else {
          modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');
        }
        modal.result.then(function (re) {
          if (re === 'success') {
            if (isEditing) {
              consignmentTableEditList[bundle][uniq].isChangedData = true;
            }
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 상품코드 클릭시 쇼핑몰 상품 관리로 이동
       */
      $scope.goOlProdList = (shop_sale_no) => {
        $rootScope.order_search.page = 'online';
        $rootScope.order_search = {
          page: 'online',
          search: true,
          search_key: 'shop_sale_no',
          search_word: shop_sale_no
        };

        $state.go('main.online_product_list');
        $rootScope.$broadcast('ordListSearch');
      };

      /**
       * 주문 전체보기에서 주문코드 눌렀을 때
       */
      $scope.$on('integratedSearch_consignment', function() {
        $scope.searchDo(true, true);
      });

      /**
       * 지급 예정일 선택 시 이벤트 함수
       */
      $scope.selectAcceptSettDate = () => {
        const ord_list = _.uniqBy($scope.grid.methods.selectedData('all'), 'bundle_no').map(ord => ord.uniq);

        if (!ord_list.length) {
          return commonSVC.showMessage('선택된 주문이 없습니다.');
        }

        commonSVC.showConfirm('지급 예정일 일괄 변경', `선택한 ${ord_list.length}건 주문의 지급 예정일을 일괄 변경합니다.\n\n변경하신 정보는 지급예정일 저장을 하셔야 최종 저장이 됩니다.`, (confirm) => {
          $timeout(() => {
            if (confirm) {
              orderList = orderList.map(ord => {
                if (!_.map($scope.grid.methods.selectedData('all'), 'bundle_no').includes(ord.bundle_no)) {
                  return ord;
                }
                ord.depot_sett_plan_date = $scope.depot_sett_plan_date;
                ord.is_chage_sett_plan_date = true;
                $timeout(() => {});

                return ord;
              });

              $scope.domestic.methods.reDraw();
              selectedDate = $scope.depot_sett_plan_date;
            }

            $scope.depot_sett_plan_date = null;
          });
        });
      };

      /**
       * 지급 예정일 일괄 적용
       */
      $scope.setAcceptSettDate = async () => {
        const ord_list = orderList.filter(ord => ord.is_chage_sett_plan_date).map(ord => ({ uniq: ord.uniq, depot_sett_plan_date: ord.depot_sett_plan_date }));
        if (!selectedDate) {
          return commonSVC.showMessage('변경사항이 없습니다.');
        }

        commonSVC.showConfirm('주문에 입력된 지급예정일을 저장하시겠습니까?', '', async (confirm) => {
          if (confirm) {
            try {
              const result = await shipmentModel.updateSettDueDate({ ord_list, isDelete: false });

              if (result.data?.results === 'success') {
                commonSVC.showToaster('success', '성공', '지급 예정일 일괄 적용에 성공하였습니다.');
                $scope.searchDo(true);

                selectedDate = null;

                return;
              } else {
                throw new Error('지급 예정일 일괄 적용에 실패하였습니다.');
              }
            } catch (err) {
              return commonSVC.showToaster('error', '실패', err.data.messages?.length ? err.data.messages[0] : '알 수 없는 이유로 실패하였습니다. 관리자에게 문의해주세요.');
            }
          } else {
            return;
          }
        });
      };

      /**
       * 지급 예정일 일괄 삭제
       */
      $scope.deleteSettDate = async () => {
        const ord_list = $scope.grid.methods.selectedData()?.filter(ord => ord.depot_sett_plan_date).map(o => ({ uniq: o.uniq }));

        if (!ord_list.length) {
          return commonSVC.showMessage('삭제 가능한 주문이 없습니다.');
        }

        commonSVC.showConfirm('선택된 주문에 입력된 지급예정일을 삭제하시겠습니까?', '', async (confirm) => {
          if (confirm) {
            try {
              const result = await shipmentModel.updateSettDueDate({ ord_list, isDelete: true });

              if (result.data?.results === 'success') {
                commonSVC.showToaster('success', '성공', '지급 예정일 일괄 삭제에 성공하였습니다.');
              } else {
                throw new Error('지급 예정일 삭제에 실패하였습니다.');
              }
            } catch (err) {
              return commonSVC.showToaster('error', '실패', err.data.messages?.length ? err.data.messages[0] : '알 수 없는 이유로 실패하였습니다. 관리자에게 문의해주세요.');
            }
            selectedDate = null;
            $scope.depot_sett_plan_date = null;
            $scope.searchDo(true, true);

            return;
          }
        });
      };

      /**
       * 엑셀 다운로드
       */
      $scope.excelDown = async () => {
        const visibleTable = $scope.grid.methods.getColumnsVisible(); // 활성화된 필드 데이터
        const selectedData = $scope.grid.methods.selectedData('all');
        const resolve = {};

        resolve.data = {
          excelFieldList: $scope.excelFieldList,
          title: $scope.tabActive === 0 ? '위탁 주문 관리 - 건별' : '위탁 주문 관리 - 집계',
          url: '/app/order/excel/downIntegrated',
          searchForm: $scope.pageData,
          page: $scope.tabActive === 0 ? 'consignment-total' : 'consignment-aggregation',
          visibleTable: visibleTable,
          consignmentType: $scope.tabActive === 0 ? 'total' : 'aggregation',
          isGlobal: false,
          ...($scope.tabActive === 1 && { excelData: $scope.aggregation.methods.selectedData().length ? $scope.aggregation.methods.selectedData() : aggregationList })
        };

        if (!selectedData.length) {
          resolve.data.count = $scope.searchData.totalCount;
          resolve.data.isAll = true;
          resolve.data.type = 'all';
        } else {
          const uniq = $scope.tabActive === 0 ? $scope.grid.methods.selectedData('uniq') : [];

          resolve.data.type = 'select';
          resolve.data.uniq = uniq;
          resolve.data.count = $scope.grid.methods.selectedData('uniq').length;
          resolve.data.isAll = false;
        }

        commonSVC.openModal('lg', resolve, 'ExcelDownIntegratedCtrl', 'views/order/shipment/modals/excel_down_integrated.html');
      };

      $scope.setMemoBtnActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      // 메모 전부완료시 메뉴 버튼 색상 변경
      $scope.setMemoCompleteActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_complete_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      // 탭 변경
      $scope.selectTab = function (tabNumber) {
        $scope.tabActive = tabNumber;
        if (tabNumber === 0) {
          $scope.grid = $scope.domestic;
          searchTemplate.searchForm.sdate = moment().subtract(1, 'months').format('YYYY-MM-DD');
          searchTemplate.searchForm.date_type = 'wdate';
          searchTemplate.searchForm.selectDate = '1MONTH';
          $scope.searchData.search_date_type = angular.copy(searchTemplate.searchData.search_date_type);
          $scope.searchData.isAggregation = false;
          $scope.domesticInit = true;
        } else if (tabNumber === 1) {
          $scope.grid = $scope.aggregation;
          searchTemplate.searchForm.sdate = moment().format('YYYY-MM-DD');
          searchTemplate.searchForm.date_type = 'depot_sett_plan_date';
          searchTemplate.searchForm.selectDate = 'TODAY';
          $scope.searchData.isAggregation = true;
          $scope.aggregationInit = true;
        }

        $scope.resetDo();

        $timeout(() => {});
      };
    });