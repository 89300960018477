'use strict';

angular.module('gmpApp')
  .service('columnModel', function (settings, commonSVC, userInfo, $rootScope) {
    const Url = `${settings.pa20ApiUrl}/app/order/column`;
    const _oldData = {
      order: {},
      payment: {},
      unstoring: {},
      claim: {},
      integrated: {},
      // sku상품
      product: {}
    };

    return {
      setOld: function (old, type) {
        _oldData[type] = old;
      },

      getOld: function (type) {
        return _oldData[type];
      },

      // 커럼 추가 항목 조회
      addColumnList: function (params) {
        return commonSVC.sendUrl('POST', `${Url}/list`, params);
      },

      // 컬럼 추가
      addColumn: function (params) {
        return commonSVC.sendUrl('POST', Url, params);
      },

      // 컬럼 수정
      editColumn: function (params) {
        return commonSVC.sendUrl('PUT', Url, params);
      },

      // 컬럼 삭제
      deleteColumn: function (params) {
        return commonSVC.sendUrl('DELETE', Url, params);
      },

      // 컬럼 정데 직접 입력
      addColumnValue: function (params) {
        return commonSVC.sendUrl('POST', `${Url}/value`, params);
      },

      // 추가 컬럼 상세 정보 조회
      detailColumn: function (params) {
        return commonSVC.sendUrl('POST', `${Url}/detail`, params);
      },

      // 추가 컬럼 집계 정보 조회
      getCustomColumnData: function (params) {
        return commonSVC.sendUrl('GET', `${Url}/aggregations?addcol_nos=${params.addcol_nos}&type=${params.type}`);
      }

    };
  });
