'use strict';

angular.module('gmpApp')
  .controller('SettingsSupplierListCtrl', function ($scope, supplierSVC, commonSVC, settings, $compile, $timeout, supplierModel, gettextCatalog, systemList) {

    /**
     * 서치바 관련 변수
     */
    $scope.searchForm = angular.copy(supplierSVC.searchForm);

    // 검색영역 데이터
    $scope.searchData = angular.copy(supplierSVC.searchData);

    const searchDetail = [
      {
        title: gettextCatalog.getString('사용여부'),
        sub_title: gettextCatalog.getString('사용여부 선택'),
        name: gettextCatalog.getString('모두'),
        value: $scope.searchForm.use_yn,
        search_name: 'use_yn',
        item_list: [
          { name: gettextCatalog.getString('사용중'), value: '1' },
          { name: gettextCatalog.getString('사용안함'), value: '0' }
        ],
        item_key: 'name',
        item_value: 'value'
      }
    ];

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.initSearchForm();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    // 검색영역 상세검색 설정
    $scope.searchDetail = angular.copy(searchDetail);

    // 검색영역 버튼 설정
    $scope.searchBtn = {
      table_actions: [
        {
          label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
          test_id: 'dropdown-work',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('매입처 복사'),
              test_id: 'submit-copy',
              action: function () {
                $scope.copyDo();
              }
            }, {
              label: gettextCatalog.getString('매입처 삭제'),
              test_id: 'submit-del',
              action: function () {
                $scope.deleteDo();
              }
            }
          ]
        }, {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          test_id: 'dropdown-excel',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('전체 엑셀 다운로드'),
              test_id: 'submit-all-excel',
              action: function () {
                $scope.excelDown('all');
              }
            }, {
              label: gettextCatalog.getString('선택 엑셀 다운로드'),
              test_id: 'submit-select-excel',
              action: function () {
                $scope.excelDown('select');
              }
            }
          ]
        }
      ],

      // 검색 영역 오른쪽 버튼
      actions_right: [
        {
          label: gettextCatalog.getString('<i class="icon-plus2"></i><span>매입처 등록</span>'),
          small_label: '매입처등록',
          sub_label: '',
          test_id: 'submit-add',
          btn_type: 'button',
          add_class: 'col-xs-12',
          // perm_only:['order.shipment+write'],
          action: function() {
            //$scope.searchRefresh();
            $scope.open('SettingsSupplierAddCtrl', 'add');
          }
        }
      ]
    };

    //------------------------
    // 변수 설정
    //------------------------
    //엑셀 다운로드 필요 변수
    $scope.all_checked = false;   // 테이블 전체 체크 값
    $scope.checked_list = {};     // 테이블 row별 체크 여부 리스트

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'supp_no', 'ctry_cd', 'trade_prod_cnt', 'change_use'],
      defaultSortingColumns: ['wdate'],
      alignRightColumns: [],
      notSortingColumns: ['widget', 'ctry_cd', 'change_use'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: ['distance', 'charge_tel'],

      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/supplier/list`,
        requestWillAction: function (data) {
          $scope.checked_list = {};
          $scope.all_checked = false;
          data = angular.merge({}, data, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;

          angular.forEach(result.results, function(v) {
            $scope.workData.code[v.id] = v.code;
            $scope.workData.wdate[v.id] = v.wdate;
            $scope.workData.supplier_name[v.id] = v.supplier_name;
            $scope.workData.charge_name[v.id] = v.charge_name;
            $scope.workData.mdate[v.id] = v.mdate;
            $scope.workData.supplier_count[v.id] = v.supplier_count;
            $scope.workData.use_yn[v.id] = v.use_yn;
          });

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: function(row) {
            return (
              `${'' +
                "<button class=\"btn btn-default btn-xxs mr-5\" ng-click=\"grid.appScope.open('SettingsSupplierEditCtrl','edit',"}${
                row.supp_no
              })">수정</button>` +
              '<button class="btn btn-default btn-xxs mr-5"  ng-click="grid.appScope.deleteDo(row.entity)">삭제</button>'
            );
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 100,
          filter: 'dateValid'
        },
        {
          key: 'supp_no',
          title: '매입처코드',
          width: 80
        },
        {
          key: 'supp_name',
          title: '매입처명',
          width: 120
        },
        {
          key: 'charge_name',
          title: '담당자',
          width: 120
        },
        {
          key: 'charge_tel',
          title: '직통번호',
          width: 100,
          notCompile: true,
          template: function(row) {
            return row.charge_tel || '없음';
          }
        },
        {
          key: 'distance',
          title: '주소',
          width: 200,
          notCompile: true,
          template: function(row) {
            let addr = '';

            if (row.zipcd) {
              addr += `(${row.zipcd}) `;
            }
            if (row.addr1) {
              addr += `${row.addr1} `;
            }
            if (row.addr2) {
              addr += row.addr2;
            }

            return addr;
          }
        },
        {
          key: 'trade_prod_cnt',
          title: '거래상품수',
          width: 120,
          template: function(row) {
            return row.trade_prod_cnt
              ? `<a ng-click="grid.appScope.tradeProdList(${row.supp_no},'${row.supp_name}');">${row.trade_prod_cnt}</a>`
              : row.trade_prod_cnt;
          }
        },
        {
          key: 'change_use',
          title: '사용여부',
          width: 90,
          template: function(row) {
            row.use_yn = !!row.use_yn;

            return '<pa-ui-switch ng-model="row.entity.use_yn" fn="grid.appScope.changeDo(row.entity)"></pa-ui-switch>';
          }
        }
      ]
    };

    //------------------------
    // 함수
    //------------------------

    /**
     * 엑셀 다운 모달창
     * */
    $scope.excelDown = function (type) {
      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: angular.copy(supplierSVC.excelFieldList),
        title: '매입처',
        url: '/app/stock/supplier/excel-down',
        searchForm: $scope.pageData,
        page: 'supplier'
      };

      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const numberList = $scope.grid.methods.selectedData('supp_no');

        if (numberList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 매입처가 없습니다.'));

          return false;
        } else {
          resolve.data.number = numberList;
          resolve.data.count = numberList.length;
          resolve.data.isAll = false;
        }
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 작업관리 새로고침
     */
    $scope.searchRefresh = function() {
      $scope.grid.methods.reloadData(null, true);
    };

    /**
     * 모달열기
     */
    $scope.open = function (controller, type, number) {
      const data = {};
      let resolve = {};

      if (type === 'edit') {
        data.number = number;
        resolve = {
          data: data
        };
      } else {
        resolve = {
          data: data
        };
      }

      const modal = commonSVC.openModal('lg', resolve, controller, `views/settings/supplier/${type}.html`);

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(false);
        }
      });
    };

    /**
     * 검색 초기화
     */
    $scope.initSearchForm = function () {
      $scope.search_site = '';
      $scope.searchForm = angular.copy(supplierSVC.searchForm);
      $scope.searchData = angular.copy(supplierSVC.searchData);
      $scope.searchDetail = angular.copy(searchDetail);

      if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
        $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      }

      $scope.searchDo(true, true);
    };

    /**
     * 조회
     */
    $scope.searchDo = function(refresh, noDelay) {
      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 삭제
     */
    $scope.deleteDo = async function(row) {
      let suppNoList = [];
      let use_yn = false;

      if (row) {
        suppNoList.push(row.supp_no);
        use_yn = row.use_yn;
      } else {
        suppNoList = $scope.grid.methods.selectedData('supp_no');

        // 선택된 매입처중 하나라도 사용중이면 use_yn은 true
        use_yn = $scope.grid.methods.selectedData('use_yn').some(r => r);
      }

      if (suppNoList.length === 0) {
        commonSVC.showMessage('', '삭제할 매입처를 선택해주세요.');

        return false;
      }

      if (use_yn) {
        commonSVC.showMessage('실패', '현재 사용중인 매입처는 삭제가 불가능합니다.');

        return false;
      }

      const confirm = await commonSVC.showConfirm('삭제하시겠습니까?');

      if (confirm) {
        try {
          const re = await supplierModel.delete(suppNoList);

          if (re.data.results === 'success') {
            commonSVC.showToaster('success', '매입처 설정', `매입처 삭제 ${suppNoList.length}건 성공`);
            $scope.searchDo(false);
          }
        } catch (err) {
          commonSVC.showToaster('error', '매입처 설정', '매입처 삭제에 실패하였습니다.');
        }
      }
    };

    /**
     * 거래처 복사
     */
    $scope.copyDo = function() {
      const selectedSupp = $scope.grid.methods.selectedData('all');

      if (selectedSupp.length === 0) {
        commonSVC.showMessage('', '복사할 매입처를 선택해주세요.');

        return;
      } else {
        angular.forEach(selectedSupp, function (value) {
          if (value.supp_no === 'S1') {
            commonSVC.showMessage('', '본사 거래처의 경우 복사 불가합니다.');

            return false;
          }
        });
      }
      supplierModel.Copy(selectedSupp, function (state) {
        if (state === 'success') {
          commonSVC.showToaster('success', '매입처 설정', `매입처 복사 ${selectedSupp.length}건 성공`);
          $scope.searchDo(true);
        } else {
          commonSVC.showToaster('error', '매입처 설정', '매입처 복사에 실패하였습니다.');
        }
      });

    };

    /**
     * 단일 수정
     */
    $scope.changeDo = async function({ supp_no, use_yn }) {
      try {
        await supplierModel.editOne(supp_no, use_yn);

        commonSVC.showToaster('success', '성공', '사용여부 수정에 성공하였습니다.');
      } catch (err) {
        commonSVC.showToaster('error', '실패', err.data.message || '매입처 수정에 실패하였습니다.');

        return false;
      }
    };

    /**
     * 거래상품리스트 모달 열기
     */
    $scope.tradeProdList = function(supp_no, supp_name) {
      const templatePath = 'views/settings/supplier/modals/trade_prod_list.html';
      const resolve = {
        data: {
          supp_no: supp_no,
          supp_name: supp_name
        }
      };

      commonSVC.openModal('xg', resolve, 'tradeProdListCtrl', templatePath);
    };

    /**
     * 바인딩용 데이터 초기화 함수
     */
    function InitBindedData() {
      $scope.workData = {};
      $scope.workData.code = {};
      $scope.workData.wdate = {};
      $scope.workData.supplier_name = {};
      $scope.workData.charge_name = {};
      $scope.workData.mdate = {};
      $scope.workData.supplier_count = {};
      $scope.workData.use_yn = {};
    }
    InitBindedData(); // 1번 호출

  });
