'use strict';

angular.module('gmpApp')
  .controller('CategoryInfoDetailCtrl', function ($scope, $compile, categoryModel, $stateParams, $timeout, siteInfo, commonSVC, errorSVC, categorySVC) {
    /**
     * 변수
     * **/
    $stateParams.title = decodeURIComponent(decodeURIComponent($stateParams.title));
    $stateParams.parents_name = decodeURIComponent(decodeURIComponent($stateParams.parents_name));
    $scope.categoryData.parents_name = $stateParams.parents_name;
    $scope.categoryData.parents_id = $stateParams.parents_id;
    $scope.categoryData.code = $stateParams.id;
    $scope.categoryData.title = $stateParams.title;
    $scope.categoryData.bookmark = $stateParams.bookmark;
    $scope.categoryData.p_cate_cd = $stateParams.p_cate_cd;
    $scope.categoryData.selectMall = 'all';
    $scope.categoryData.selectId = 'all';
    $scope.Cdata = {};
    $scope.seletIndex = '';
    $scope.test = '';
    $scope.matchingData = ''; //매칭정보 초기화시 데이터
    $scope.scrapShopList = {};
    $scope.originData = {};
    $scope.placeholder = '쇼핑몰의 카테고리를 확인해주세요';
    $scope.isOpen = false;
    //표준카테고리 변수
    $scope.categoryData.L = '';
    $scope.categoryData.M = '';
    $scope.categoryData.S = '';
    $scope.categoryData.XS = '';
    $scope.updateCateData = {};
    $scope.simCateList = {};
    $scope.checkAdditionalInfo = categorySVC.checkAdditionalInfo;
    $scope.searchLoading = false;
    $scope.search_yn = false;
    $scope.applyList = {};
    $scope.saveCnt = 0;
    $scope.tick = 10;
    $scope.type = 'loading';
    $scope.percent = '0%';

    // 원본 카테고리명
    let originCateName = $scope.categoryData.title;

    let originSimCateList = {};
    let list = [];
    let openIdx = null;
    let searchWord = [];

    if ($stateParams.parents_name == '') {
      $scope.categoryData.catagoryLocation = $stateParams.title;
    } else {
      if ($stateParams.parents_id == null) {
        $scope.categoryData.catagoryLocation = $stateParams.title;
      } else {
        $scope.categoryData.catagoryLocation = `${$stateParams.parents_name} > ${$stateParams.title}`;
      }
    }

    categoryModel.categoryChannelList($stateParams.id, function(data) {

      _.forEach(data, function (val, key) {
        data[key].shop_name = siteInfo[data[key].shop_cd].name;
      });

      $scope.use_engines = data;

      $scope.use_engines.forEach(shop => {
        $scope.scrapShopList[shop.shop_cd] = shop.scrap_cate_yn;

        if (!$scope.updateCateData[shop.shop_cd]) {
          $scope.updateCateData[shop.shop_cd] = {};
        }

        if (!$scope.applyList[shop.shop_cd]) {
          $scope.applyList[shop.shop_cd] = {};
        }

        if (!$scope.originData[shop.shop_cd]) {
          $scope.originData[shop.shop_cd] = {};
        }

        $scope.updateCateData[shop.shop_cd][shop.shop_id] = '';
        $scope.applyList[shop.shop_cd][shop.shop_id] = [];

        $scope.originData[shop.shop_cd][shop.shop_id] = '';
      });
    });

    $scope.matching = function() {
      //모달추가
      const resolve = {};

      resolve.data = {
        sol_cate_no: $scope.categoryData.code,
        categoryId: $scope.categoryData.code
      };
      const redata = commonSVC.openModal('lg', resolve, 'SettingCateMatching', 'views/settings/category/cateMatching.html');

      redata.result.then(function () {
        $scope.infoLoad();
        /**
         * 2018-02-19 Daniel
         * 매칭시 카테고리명 변경후 로드, 리스트 로드
         */
        $scope.categoryInfo_list();
        $scope.loadCategory();
      });
    };

    $scope.cateLogOpen = function() {
      const resolve = {
        data: {
          solCateNo: $scope.categoryData.code
        }
      };

      commonSVC.openModal('lg', resolve, 'CateLogCtrl', 'views/settings/category/modals/categoryLog.html');
    };

    /**
     * 개별 상품 카테고리 일괄 적용
     * 2019-11-06 Alvin
     */
    $scope.applyBatchEachProduct = async function () {
      const resolve = {
        data: {
          sol_cate_no: $scope.categoryData.code
        }
      };

      commonSVC.openModal('lg', resolve, 'CateApplyEachProductCtrl', 'views/settings/category/modals/cateApplyEachProduct.html');
    };

    /**
     * 쇼핑몰 카테고리정보 가져오기
     **/
    $scope.categoryInfo_list = function() {
      const params = {
        category_code: $scope.categoryData.code
      };

      categoryModel.categoryInfo_list(params, function(data) {
        $scope.categoryData.title = data[0].sol_cate_name;
        $scope.categoryData.code = data[0].sol_cate_no;
        $scope.categoryData.bookmark = data[0].bookmark_yn;
      });
    };

    $scope.categoryInfo_list();

    /**
       * 쇼핑몰 매칭정보 가져오기
       * **/

    $scope.infoLoad = (init) => {
      $scope.loading = true;
      $scope.type = 'loading';
      setTimeout(function () {

        const params = {
          category_code: $scope.categoryData.code
        };

        categoryModel.matchingInfo_list(params, (data) => {
          data.forEach((val, idx) => {
            const shop_cd = data[idx].shop_cd;
            const shop_id = data[idx].shop_id;

            if (shop_cd === 'Z000') {
              return true;
            }

            data[idx].shop_name = siteInfo[shop_cd].name;
            if (data[idx].sol_cate_shop_add_info != null) {
              const val = {
                category_names: data[idx].sol_cate_shop_add_info.category_names,
                category_code: data[idx].sol_cate_shop_add_info.category_code,
                detailData: data[idx].sol_cate_shop_add_info,
                isOrigin: true,
                pa_shop_cd: data[idx].pa_shop_cd
              };

              $scope.updateCateData[shop_cd][shop_id] = val;
              $scope.originData[shop_cd][shop_id] = val;
            } else {
              // sol_cate_shop_add_info가 없으면서 오리진인 케이스는 초기화 된 케이스로 제외 처리
              if ($scope.updateCateData[shop_cd][shop_id] && $scope.updateCateData[shop_cd][shop_id].isOrigin) {
                $scope.updateCateData[shop_cd][shop_id] = '';
                $scope.originData[shop_cd][shop_id] = '';
              } else {
                // 기존 데이터가 있으면 기존데이터 덮어 쓰기
                $scope.updateCateData[shop_cd][shop_id] = $scope.updateCateData[shop_cd][shop_id] || '';
                $scope.originData[shop_cd][shop_id] = $scope.updateCateData[shop_cd][shop_id] || '';
              }

              data[idx].detail = '';
            }
          });

          $scope.resetCateSearch(init);

          list = data;
          if ($scope.categoryData.selectMall !== 'all' || $scope.categoryData.selectId !== 'all') {
            $scope.matchingInfoChange();
          } else {
            $scope.matchingInfo = list.filter(() => true);
          }

          const length = $scope.matchingInfo.length;

          if (!init) {
            $scope.simCateList = Object.assign(originSimCateList);
          }

          const tickUpdate = () => {
            if ($scope.tick < length) {
              $scope.tick += 10;
              // requestAnimationFrame 60 프레임 보장시켜 애니메이션 화 시킴.
              requestAnimationFrame(tickUpdate);
            } else {
              $scope.loading = false;
            }

            $scope.$digest();
          };

          requestAnimationFrame(tickUpdate);
        });
      }, 1000);

    };

    $scope.infoLoad(true);

    /**
       * 즐겨찾기 수정
       **/
    $scope.bookmark = function() {
      const param = {
        id: $scope.categoryData.code,
        bookmark: !$scope.categoryData.bookmark
      };

      categoryModel.CategorybookmarkEdit(param, function (state) {
        if (state != 'success') {
          commonSVC.showToaster('error', '실패', '카테고리 수정에 실패하였습니다.');
        } else {
          $scope.categoryData.bookmark = !$scope.categoryData.bookmark;
          commonSVC.showToaster('success', '성공', '카테고리 수정에 성공하였습니다.');

          $scope.loadCategory();
        }
      });
    };

    /**
       * 카테고리 정보 수정
       **/
    $scope.test = [];

    /**
       * 쇼핑몰별 매칭정보 초기화
       **/
    $scope.shopInfoDelete = function() {

      commonSVC.showConfirm('주의', '카테고리 정보를 초기화 하시겠습니까?')
        .then(function (confirm) {
          if (confirm) {
            const { site_code, site_id } = $scope.matchingData;

            if ($scope.originData[site_code][site_id]) {
              categoryModel.shopInfoDetailDelete($scope.matchingData)
                .then(function () {
                  commonSVC.showToaster('success', '성공', '카테고리 정보 초기화에 성공하였습니다.');
                  $scope.updateCateData[site_code][site_id] = '';
                  $scope.originData[site_code][site_id] = '';

                  // 카운팅
                  setSaveCnt();

                  // 닫기
                  $scope.infoOpen(openIdx);
                })
                .catch(function (err) {
                  commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
                });
            } else {
              $timeout(() => {
                $scope.updateCateData[site_code][site_id] = '';

                // 카운팅
                setSaveCnt();

                // 닫기
                $scope.infoOpen(openIdx);
              });

            }
          }
        });
    };

    /**
       * 쇼핑몰 매칭정보 open
       **/
    $scope.infoOpen = async (index, data) => {
      //리스트 선택시 매칭정보상세 초기화
      $('.shopListShow2').html('');
      $('.shopListShow2').hide();

      //열고 닫기
      if ($(`#cateInfo_${index}`).css('display') == 'none') {
        $scope.isOpen = true;

        //열기 일 경우 쇼핑몰 매칭정보 html 새로 그리기
        const url = angular.element(`<div data-ng-include='"views/settings/category/shopDetail/${data.pa_shop_cd}.html"' id='matchingInfo2_${index}' class='row blue-bg border-bottom border-bottom-grey-100 p-20 shopListShow2'></div>`);

        angular.element(`#cateInfo_${index}`).html(url);
        $compile(url)($scope);
        $scope.matchingId = data.shop_id;

        $scope.matchingData = {
          categoryId: $scope.categoryData.code,
          site_code: data.shop_cd,
          pa_shop_code: data.pa_shop_cd,
          site_id: data.shop_id,
          categoryNames: _.get(data, 'sol_cate_shop_add_info.category_names') || '매칭정보 없음',
          pin_yn: data.pin_yn,
          isOrigin: !!$scope.updateCateData[data.shop_cd][data.shop_id].isOrigin
        };

        if ($scope.updateCateData[data.shop_cd][data.shop_id] && ($scope.updateCateData[data.shop_cd][data.shop_id].isOrigin || $scope.updateCateData[data.shop_cd][data.shop_id].isApply)) {
          $scope.matchingData.sol_cate_shop_add_info = { ...$scope.updateCateData[data.shop_cd][data.shop_id].detailData };
        } else if ($scope.updateCateData[data.shop_cd][data.shop_id]) {
          const detailData = await categoryModel.getDetailData({ shop_cd: data.pa_shop_cd, cateData: $scope.updateCateData[data.shop_cd][data.shop_id] });

          $scope.matchingData.sol_cate_shop_add_info = detailData.data.result.detailData || null;
        } else {
          $scope.matchingData.sol_cate_shop_add_info = null;
        }

        //열기
        openIdx = index;
        $(`#cateInfo_${index}`).show();
      } else if ($(`#cateInfo_${index}`).css('display') == 'block') {
        $scope.isOpen = false;
        //닫기
        $(`#cateInfo_${index}`).hide();
      }

      $scope.shop_cd = data.shop_cd;
      $scope.shop_id = data.shop_id;
    };

    // 카테고리명 수정
    $scope.updateCateName = function () {
      // 카테고리명 변경사항 없으면 리턴
      if (originCateName === $scope.categoryData.title) {
        return false;
      }

      if (!$scope.categoryData.title) {
        commonSVC.showMessage('실패', '카테고리 이름을 입력해주세요.');

        return false;
      }

      const data = {
        reviseName: $scope.categoryData.title,
        categoryId: $scope.categoryData.code,
        depth: 2
      };

      categoryModel.categoryNameUpdate(data, function (state, res) {
        if (state == 'error') {
          let errMsg = errorSVC.getError('prod', res.data.error);

          if (!errMsg) {
            errMsg = '이름 변경에 실패하였습니다.';
          }

          commonSVC.showToaster('error', '', errMsg);
        } else {
          originCateName = $scope.categoryData.title;

          $timeout(() => {
            $scope.loadCategory();
          }, 300);

          commonSVC.showToaster('success', '성공', '이름 변경에 성공하였습니다.');
        }
      });
    };

    /**
       * 카테고리삭제
       **/
    $scope.categoryDelete = function(type) {
      let code, textType;

      if (type === 1) {
        code = $scope.categoryData.parents_id;
        textType = '분류';
      } else {
        code = $scope.categoryData.code;
        textType = '카테고리';
      }

      //삭제시 쇼핑몰 상품 관리 상태가 판매중지, 반려인상태를 제외하고 삭제불가 처리
      commonSVC.showConfirm(`${textType} 삭제`, `${textType}에 설정된 모든 정보가 삭제됩니다. \n삭제된 ${textType}정보는 복구되지 않으므로 주의바랍니다.`, function (confirm) {
        if (confirm) {
          const sendData = {
            id: code,
            depth: type
          };

          categoryModel.categoryDel(sendData, function (state, data) {
            if (state == 'error') {
              let errMsg = errorSVC.getError('prod', data.data.error);

              if (!errMsg) {
                errMsg = `알 수 없는 이유로 ${textType} 삭제에 실패했습니다. 삭제에 실패한 ${textType} 이름과 함께 본사에 문의해주시기 바랍니다.`;
              }

              commonSVC.showToaster('error', '실패', errMsg);
            } else {
              $scope.$parent.data = [];
              commonSVC.showToaster('success', '성공', `${textType} 삭제에 성공하였습니다.`);
              $('#detailView').css('display', 'none');
              $('#defaultView').css('display', 'block');
              $timeout(function() {
                $scope.loadCategory();
              }, 300);
            }
          });
        }
      });
    };

    /**
     * 카테고리 검색
     */
    $scope.findCategory = async () => {
      const shopList = $scope.use_engines.filter(s => s.scrap_cate_yn);

      if (!shopList.length) {
        commonSVC.showMessage('실패', '카테고리 검색을 지원하는 쇼핑몰이 없습니다.');

        return false;
      }

      if (!$scope.searchWord || !$scope.searchWord.replace(/[^a-zA-Z가-힣0-9]/g, '')) {
        commonSVC.showMessage('실패', '카테고리 검색어를 입력해주세요.');

        return false;
      }

      $scope.loading = true;
      $scope.type = 'search';

      try {
        const res = await categoryModel.findCategory({ searchWord: $scope.searchWord });

        await $timeout(() => {
          $scope.search_yn = true;
          $scope.placeholder = '검색어 관련 카테고리를 지원하지 않습니다.';
          $scope.simCateList = res.data.result;
          originSimCateList = Object.assign(res.data.result);

          // 중복 키워드 확인
          if (!searchWord.includes($scope.searchWord)) {
            searchWord.push($scope.searchWord);
          }

          for (const { pa_shop_cd, shop_cd, shop_id } of list) {
            if (shop_cd !== 'Z000' &&
            (!$scope.updateCateData[shop_cd][shop_id] || !$scope.updateCateData[shop_cd][shop_id].isOrigin) &&
            $scope.simCateList[pa_shop_cd]?.length) {
              $scope.updateCateData[shop_cd][shop_id] = $scope.originData[shop_cd][shop_id] || $scope.simCateList[pa_shop_cd][0] || '';
            }
          }

          // 열려있을 때 변경 이벤트 처리
          if (typeof openIdx === 'number' && $scope.isOpen) {
            const { shop_cd, shop_id } = $scope.matchingInfo[openIdx];

            if (!$scope.updateCateData[shop_cd][shop_id]) {
              return false;
            }

            if (!$scope.updateCateData[shop_cd][shop_id].isOrigin && !$scope.updateCateData[shop_cd][shop_id].isApply) {
              $scope.changeUpdate(openIdx, 'category_selection', { shop_cd, shop_id });
            }
          }

          setSaveCnt();
        });
      } catch (err) {
        commonSVC.showToaster('error', '실패', err.message || err.data.error);
      } finally {
        await $timeout(() => {
          $scope.loading = false;
        });
      }
    };

    // 카테고리 검색 초기화
    $scope.resetCateSearch = async (init) => {
      await $timeout(() => {
        for (const shop in $scope.updateCateData) {
          for (const shop_id in $scope.updateCateData[shop]) {
            $scope.updateCateData[shop][shop_id] = $scope.originData[shop][shop_id];
          }
        }

        $scope.simCateList = init ? [] : Object.assign(originSimCateList);
        $scope.placeholder = '선택';

        if (init) {
          $scope.searchWord = '';
          $scope.search_yn = false;
        }

        setSaveCnt();
      });
    };

    $scope.setPin = async (cate) => {
      // 고정 변경인 경우 업데이트 처리
      try {
        // 현재 고정상태일때 확인처리 추가
        if (cate.pin_yn) {
          // 매칭된 상품이 있는 지 확인
          const res = await categoryModel.findOlExsits({ shop_cd: cate.shop_cd, shop_id: cate.shop_id, sol_cate_no: cate.sol_cate_no });

          if (res.data.result) {
            const confirm = await commonSVC.showConfirmCustom({
              title: '카테고리 정보 고정을 해제하시겠습니까?',
              text: `<p class="no-padding help-text">- 해당 카테고리 정보는 온라인에서 판매중인 상품에 적용된 카테고리 정보입니다.</p>
                <p class="text-danger no-padding help-text"> - 해당 카테고리 정보를 변경하면 온라인에서 판매중인 상품의 카테고리 정보도 변경됩니다.</p>
                <p class="no-padding help-text">- 온라인에서 판매중인 상품의 카테고리 정보를 변경을 원하지 않는다면 고정을 유지하길 권장합니다.</p>`,
              confirmButtonText: '고정 해제',
              cancelButtonText: '닫기',
              html: true,
            });

            if (!confirm) {
              return false;
            }
          }
        } else {
          // 고정하고 변경 사항이 있을 대 확인 처리 추가
          if (!$scope.updateCateData[cate.shop_cd][cate.shop_id].isOrigin) {
            const confirm = await commonSVC.showConfirmCustom({
              title: '확인',
              text: '일괄저장하지 않고 고정 시 이전에 매칭된 카테고리 정보로 고정됩니다.',
              confirmButtonText: '고정',
              cancelButtonText: '닫기',
              html: true
            });

            if (!confirm) {
              return true;
            }

            $scope.updateCateData[cate.shop_cd][cate.shop_id] = $scope.originData[cate.shop_cd][cate.shop_id];
          }
        }

        await categoryModel.categoryPinEdit({ sol_cate_no: cate.sol_cate_no, pin_yn: !cate.pin_yn, shop_cd: cate.shop_cd, shop_id: cate.shop_id });

        await $timeout(() => {
          cate.pin_yn = !cate.pin_yn;
          setSaveCnt();
        });

        commonSVC.showToaster('success', '성공', `카테고리 고정 ${cate.pin_yn ? '' : '해제'}`);
      } catch (err) {
        commonSVC.showToaster('error', '실패', `카테고리 고정 ${cate.pin_yn ? '' : '해제'}`);
      }

      if ($scope.matchingData.categoryId === cate.sol_cate_no &&
        $scope.matchingData.site_code === cate.shop_cd &&
        $scope.matchingData.site_id === cate.shop_id) {
        $scope.matchingData.pin_yn = cate.pin_yn;
      }
    };

    $scope.changeUpdate = async (index, type, shop) => {
      if ($(`#cateInfo_${index}`).css('display') !== 'none') {
        if ($scope.updateCateData[shop.shop_cd][shop.shop_id].isApply || $scope.updateCateData[shop.shop_cd][shop.shop_id].isOrigin) {
          $scope.$broadcast(type, { ...$scope.updateCateData[shop.shop_cd][shop.shop_id], site_code: shop.shop_cd, site_id: shop.shop_id });
        } else {
          const detailData = await categoryModel.getDetailData({ shop_cd: shop.pa_shop_cd, cateData: $scope.updateCateData[shop.shop_cd][shop.shop_id] });

          $scope.$broadcast(type, { ...detailData.data.result, site_code: shop.shop_cd, site_id: shop.shop_id });
        }
      }

      setSaveCnt();
    };

    // 카테고리 적용
    $scope.applyCate = (param) => {
      // 동일 쇼핑몰 적용처리.
      if (param.set_all_shop) {
        for (const cate of $scope.matchingInfo.filter(cate => cate.pa_shop_cd === $scope.matchingData.pa_shop_code)) {
          // for (const site_id in $scope.updateCateData[param.site_code]) {
          $scope.updateCateData[cate.shop_cd][cate.shop_id] = param;
          $scope.applyList[cate.shop_cd][cate.shop_id][0] = param;
        }

      } else {
        $scope.updateCateData[param.site_code][param.site_id] = param;

        if (!$scope.applyList[param.site_code]) {
          $scope.applyList[param.site_code] = {};
        }

        if (!$scope.applyList[param.site_code][param.site_id]) {
          $scope.applyList[param.site_code][param.site_id] = [];
        }

        $scope.applyList[param.site_code][param.site_id][0] = param;
      }

      setSaveCnt();

      $scope.shopCateTmpSave(param.set_all_shop ? param.site_code : null);
    };

    $scope.matchingInfoChange = async () => {
      await $timeout(() => {
        $scope.matchingInfo = list.filter(cate => {
          return ($scope.categoryData.selectMall === 'all' ? true : $scope.categoryData.selectMall === cate.shop_cd)
            && ($scope.categoryData.selectId === 'all' ? true : $scope.categoryData.selectId === cate.shop_id);
        });

        setSaveCnt();
      });
    };

    // 레프트 영역 자동 높이 조절
    const setLeftHeight = () => {
      $timeout(function () {
        if (document.querySelector('#infoSidebar')) {
          const rect = document.querySelector('#infoSidebar').getClientRects();

          if (rect && rect[0]) {
            document.querySelector('#infoSidebar').style.height = `${window.innerHeight - rect[0].top - 90}px`;
          }
        }
      });
    };

    $scope.$on('$viewContentLoaded', function() {
      setLeftHeight();
    });

    $(window).on('resize', function () {
      setLeftHeight();
    });

    // 일괄저장
    $scope.saveAll = async () => {
      $scope.isOpen = false;

      try {
        const updateList = [];

        for (const shopIdx in $scope.matchingInfo) {
          const shop = $scope.matchingInfo[shopIdx];
          const data = $scope.updateCateData[shop.shop_cd] ? $scope.updateCateData[shop.shop_cd][shop.shop_id] : null;

          if ((!data
            // 원래 데이터
            || data.isOrigin
            // 원래데이터가 아니고 마지막 카테고리가 아니거나 추가 정보를 입력해야하는 경우
            || (!data.isApply && (!data.end_yn || $scope.checkAdditionalInfo[shop.pa_shop_cd]))
            // 쇼핑몰 필터링
            || ($scope.categoryData.selectMall !== 'all' ? $scope.categoryData.selectMall !== shop.shop_cd : false)
            // 계정 필터링
            || ($scope.categoryData.selectId !== 'all' ? $scope.categoryData.selectId !== shop.shop_id : false)
          )
            // 상품수집시 저장된 카테 정보 수정여부
            && !(data?.isOrigin && data.detailData?.isProdScrap)) {
            continue;
          }

          data.index = shopIdx;

          let param = {};

          // 상세에서 적용한 경우
          if (data.isApply) {
            param = { ...data, shop_cd: shop.shop_cd, shop_id: shop.shop_id, pa_shop_cd: shop.pa_shop_cd };
          } else {
            // 적용하지 않고 바로 저장하는 경우
            const detailData = await categoryModel.getDetailData({ shop_cd: shop.pa_shop_cd, cateData: data });
            param = {
              pa_shop_cd: shop.pa_shop_cd,
              shop_cd: shop.shop_cd,
              shop_id: shop.shop_id,
              category_codes: 'test',
              category_names: data.category_names,
              categoryId: $scope.categoryData.code,
              set_all_shop: false,
              country: 'kr',
              type: 0
            };
            Object.assign(param, detailData.data.result);
          }

          if (['A001', 'A006'].includes(param.shop_cd)) {
            param.cateData = data.detailData;
          }

          updateList.push(param);
        }

        if (!updateList.length) {
          commonSVC.showMessage('실패', '일괄 저장 가능한 쇼핑몰이 없습니다.');
        } else {
          const confirm = await commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.');

          if (confirm) {
            $scope.type = 'save';
            $scope.loading = true;

            const timeout = (2 * 60 * 1000) + parseInt(updateList.length / 100) * 10; // 100개 이후 100개 마다 10초씩 추가
            const res = await categoryModel.shopInfoDetailEditAll({ categoryData: updateList, searchWord, timeout });

            // 검색어 초기화
            searchWord = [];

            $scope.applyList = { ...$scope.applyList };

            commonSVC.showToaster('success', '성공', `카테고리 수정 ${res.data.result.success} 건 성공 ${res.data.result.fail} 건 실패`);

            $scope.infoLoad(true);
          }
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', err.message || err.data.error);
      } finally {
        $scope.loading = false;
      }
    };

    /**
     * 쇼핑몰 카테고리 임시저장
     */
    $scope.shopCateTmpSave = async (site_code) => {
      let pa_shop_cd, shop_cd, shop_id;
      const oriIdx = openIdx === null ? 0 : openIdx;

      do {
        if (openIdx === null) {
          openIdx = 0;
        } else {
          openIdx += 1;
        }

        if ($scope.matchingInfo[openIdx]) {
          shop_cd = $scope.matchingInfo[openIdx].shop_cd;
          shop_id = $scope.matchingInfo[openIdx].shop_id;
          pa_shop_cd = $scope.matchingInfo[openIdx].pa_shop_cd;
        }
      }
      while ($scope.matchingInfo[openIdx] && shop_cd !== 'Z000' &&
      ($scope.matchingInfo[openIdx].pin_yn // 고정돼 있거나
        || (site_code ? $scope.matchingInfo[openIdx].shop_cd === site_code : false) // 동일 쇼핑몰 적용이거나
        || ($scope.updateCateData[shop_cd][shop_id].end_yn && !$scope.checkAdditionalInfo[pa_shop_cd]) // 추가적용이 필요 없거나
        || $scope.updateCateData[shop_cd][shop_id].isOrigin // 변경사항이 없어서 설정이 필요 없는 경우
        || $scope.updateCateData[shop_cd][shop_id].isApply
      )); // 변경사항이 있는경우

      // 다음 카테고리가 없을 경우 현재 카테고리 창 닫음
      $timeout(() => {
        $scope.infoOpen(oriIdx, $scope.matchingInfo[oriIdx]);
      });
    };

    $scope.getSelectionCateClass = (data) => {
      let className = '';

      if (data?.detailData?.isProdScrap && data.isOrigin) { // 수집시 매칭된 카테고리인 경우
        if (data.detailData.isPlayautoCate) { // 표준카테고리 매칭
          className = 'border-blue-600';
        } else { // 유사카테고리 매칭
          className = 'border-success';
        }
      } else if (!data || data.isOrigin) { // 기존 데이터이거나 저장되지 않은 경우 디폴트
        className = 'border-default';
      } else if (!data.isApply && (!data.end_yn || $scope.checkAdditionalInfo[data.pa_shop_cd || data.shop_cd])) {
        className = 'border-danger';
      } else {
        className = 'border-success';
      }

      return className;
    };

    /**
     * 카테고리 분류 변경
     */
    $scope.updateParentCate = () => {
      if ($scope.categoryData.code) {
        const cateData = {
          rno: 1001, // 카테고리 순서
          id: $scope.categoryData.code,
          parents_id: $scope.categoryData.parents_id
        };

        categoryModel.categoryOrder([cateData])
          .then(() => {
            // 조회 딜레이가 있어서 timeout
            $timeout(() => {
              commonSVC.showToaster('success', '성공', '카테고리 분류 변경에 성공했습니다.');
              $scope.loadCategory();
            }, 300);
          })
          .catch(err => {
            commonSVC.showToaster('error', '실패', '카테고리 분류 변경에 실패했습니다.');
          });
      }
    };

    // 일괄저장 카운팅
    const setSaveCnt = () => {
      $scope.saveCnt = 0;

      for (const shop_cd in $scope.updateCateData) {
        for (const shop_id in $scope.updateCateData[shop_cd]) {
          // 필터링 되어 있는 경우 필터링 된 데이터만 카운트함.

          if ($scope.categoryData.selectMall !== 'all' && $scope.categoryData.selectMall !== shop_cd) {
            continue;
          }

          if ($scope.categoryData.selectId !== 'all' && $scope.categoryData.selectId !== shop_id) {
            continue;
          }

          const data = $scope.updateCateData[shop_cd][shop_id];
          // 기존 데이터이거나 저장되지 않은 경우 디폴트

          // 뷰와 같은 함수를 써서 카운트를 맞춤
          if (['border-success', 'border-blue-600'].includes($scope.getSelectionCateClass(data))) {
            $scope.saveCnt++;
          }
        }
      }
    };

    /**
     * 카테고리명 변경 (리스트에서 변경)
     */
    $scope.$on('cateNameChange', function(e, data) {
      $scope.categoryData.title = data.newTitle;
      originCateName = data.newTitle;
    });
  });
