import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import { showConfirm, showCustomPrompt, showMessage, showToaster } from '../../utills/common';
import * as imageHostModel from '../../models/imageHosting';
import ContextMenu from '../common/ContextMenu';
import moment from 'moment';
import CommonModal from '../common/commonModal';
import ImagePreviewComponent from './ImagePreview';

interface FileItem {
  id: string;
  name: string;
  size: string | number;
  type: '' | 'file' | 'directory';
  mdate: string;
}

interface FileListComponentProps {
  searchedData: FileItem[];
  fileData: any[];
  folderPath: string[];
  init: (currentFtpInfo: any, path: string) => void;
  currentFtpInfo: any;
  setFolderPath: React.Dispatch<React.SetStateAction<string[]>>;
  folderList: any;
  currentFtpPath: string;
  selectedFiles: FileItem[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileItem[]>>;
  getImagehostUrls: (type: '' | 'url' | 'html', imagehostingFiles: FileItem[]) => string[];
  handleClose: (result?: any) => void;
}

const FileListComponent: React.FC<FileListComponentProps> = ({
  searchedData,         // 검색 결과
  fileData,             // FTP 파일 목록 (현재 경로)
  init,                 // 파일 목록 초기화 함수
  currentFtpInfo,       // 현재 FTP 정보
  folderList,           // FTP 전체 폴더 항목
  currentFtpPath,       // 현재 FTP 경로 (문자열)  ex> /test/test2/test3/
  folderPath,           // 접속 경로 (배열) ex> ['test', 'test2', 'test3']
  setFolderPath,        // 접속 경로 설정 함수
  selectedFiles,        // 선택파일 리스트
  setSelectedFiles,     // 선택 파일 셋팅 함수
  getImagehostUrls,     // 이미지 호스팅 URL 생성 함수
  handleClose,          // 모달 닫기 (선택완료)
}) => {
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [sort, setSort] = useState(true);                     //오름차순 기본 상태(내림차순 아이콘 show)
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [hoverIndex, setHoverIndex] = useState(null);         // hover 상태를 추적
  const [contextMenu, setContextMenu] = useState(null);       // 메뉴 위치 및 상태
  const [contextMenuItem, setContextMenuItem] = useState([]); // 메뉴 아이템
  const contextMenuRef = useRef(null);                        // 메뉴 참조
  const [isDragging, setIsDragging] = useState(false);        // 파일 그래그드랍 체크
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false); // 이미지 미리보기 모달
  const imagePathRef = useRef('');                            // 이미지 미리보기 경로

  useEffect(() => {
    const handleClickOutside = (e) => {
      // 클릭한 대상이 contextMenu 내부가 아니면 닫기
      if (contextMenuRef.current && !contextMenuRef.current.contains(e.target)) {
        setContextMenu(null);
      }
    };

    // 문서에 mousedown 이벤트 리스너 추가
    document.addEventListener('mousedown', handleClickOutside);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /**
   * 선택 파일 변경에 따른 우클릭 메뉴 변경
   */
  useEffect(() => {
    changeContextMenu();
  }, [selectedFiles]);

  const onClickDropdown1 = (e) => {
    setDropdown1(!dropdown1);
    setDropdown2(false);
    e.stopPropagation();
  };

  const onClickDropdown2 = (e) => {
    setSort(!sort);
    setDropdown1(false);
    setDropdown2(true);
    e.stopPropagation();
  };

  /**
   * 전체선택
   */
  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedFiles([]); // 모두 해제
    } else {
      setSelectedFiles(fileData); // 모두 선택
    }
    setIsAllSelected(!isAllSelected);
  };

  /**
   * 파일 선택
   */
  const handleSelect = (file: FileItem) => {
    if (selectedFiles.some((selectedFile) => selectedFile.id === file.id)) {
      // 선택 해제
      setSelectedFiles(selectedFiles.filter((selectedFile) => selectedFile.id !== file.id));
    } else {
      // 선택 추가
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  /**
   * 새로고침
   */
  const refreshFiles = () => {
    init(currentFtpInfo, currentFtpPath === '/' ? currentFtpPath : currentFtpPath.slice(0, -1));
    setSelectedFiles([]);
  };

  /**
   * 행 클릭
   */
  const handleRowClick = (file: FileItem) => {
    handleSelect(file); // 행 클릭 시 파일 선택/해제
  };

  const handleMouseOver = (id: string) => {
    setHoveredRow(id);
  };

  const handleMouseOut = () => {
    setHoveredRow(null);
  };

  /**
   * 동일 폴더명이 있는지 체크
   */
  const checkDuplicateFolderName = (name: string): string => {
    let errorMessage = '';

    if (!name.trim().length) {
      errorMessage = '1글자 이상 입력해 주세요.';
    } else if (!folderPath.length) {
      if (folderList.children.some((folder) => folder.name === name)) {
        errorMessage = '같은 이름의 폴더가 있습니다.';
      }
    } else {
      let currentFolder = folderList;
      folderPath.forEach((path: string) => {
        currentFolder = currentFolder.children.find((folder) => folder.name === path);
      });

      if (currentFolder) {
        if (currentFolder.children.some((folder) => folder.name === name)) {
          errorMessage = '같은 이름의 폴더가 있습니다.';
        }
      }
    }

    return errorMessage;
  };

  /**
   * 동일 파일명이 있는지 체크
   */
  const checkDuplicateFileName = (name: string): string => {
    let errorMessage = '';

    if (fileData.some((file) => file.name.trim() === name.trim())) {
      errorMessage = '같은 이름의 파일이 있습니다.';
    }

    return errorMessage;
  };

  /*
  * 새 폴더 생성
  */
  const createFtpFolder = async (): Promise<void> => {
    try {
      const { isConfirmed, textValue } = await showCustomPrompt('새 폴더 만들기', null, null, null, null, null, '1글자 이상 입력해 주세요', '', checkDuplicateFolderName);

      if (isConfirmed) {
        const res = await imageHostModel.createFtpFolder(currentFtpInfo.ftp_no, `${currentFtpPath}${textValue}`);

        if (res?.results === 'success') {
          showToaster('success', '', '폴더를 생성했습니다.');
          init(currentFtpInfo, currentFtpPath === '/' ? currentFtpPath : currentFtpPath.slice(0, -1));
        } else {
          throw new Error(res.error || '폴더 생성에 실패했습니다.');
        }
      }
    } catch (err: any) {
      showToaster('error', '', err.message || '폴더 생성에 실패했습니다.');
    }
  };

  /**
   * 파일정보 확인후 아이콘 리턴
   */
  const checkFileExtension = (type: string, fileName: string) => {
    if (type === 'directory') {
      return (
        <i
          className="fa fa-folder"
          style={{ ...styles.fileTypeIcon, color: '#FFAD27' }}
        />
      );
    } else {
      const extension = fileName.split('.').pop()?.toLowerCase();
      if (['jpg', 'jpeg', 'png', 'webp'].includes(extension)) {
        return (
          <i
            className="fa fa-picture-o"
            style={{ ...styles.fileTypeIcon, color: '#0072CE' }}
          />
        );
      } else if (extension === 'gif') {
        return (
          <i
            className="fa fa-play-circle"
            style={{ ...styles.fileTypeIcon, color: '#0072CE' }}
          />
        );
      } else {
        return (
          <i
            className="fa fa-file-o"
            style={{ ...styles.fileTypeIcon, color: '#0072CE' }}
          />
        );
      }
    }
  };

  /**
   * 상위 폴더로 이동
   */
  const moveUpFolder = () => {
    if (!folderPath.length) {
      showMessage('최상위 폴더 입니다.');

      return;
    }

    setSelectedFiles([]);
    folderPath.pop();
    setFolderPath([...folderPath]);
  };

  /**
   * 우클릭 메뉴
   */
  const handleContextMenu = (e, file) => {
    e.preventDefault(); // 기본 우클릭 동작 방지
    setSelectedFiles(
      selectedFiles.some((selectedFile) => selectedFile.id === file.id) ? selectedFiles : [...selectedFiles, file]
    );
    setContextMenu({
      x: e.pageX,
      y: e.pageY,
    });
  };

  // 메뉴 닫기
  const contextMenuClose = () => {
    setContextMenu(null);
  };

  /**
   * 주소 및 HTML 태그 복사
   */
  const copyAddress = async (type: 'url' | 'html') => {
    try {
      // 선택된 순서가 아니라 정렬 순서로 복사되어야 하기 때문에 searchedData 에서 선택파일만 뽑아내는 로직이 추가됨.
      const filesToCopy = searchedData
        .filter(file => {
          return selectedFiles.some((selectedFile) => selectedFile.id === file.id);
        })
        .slice(0, 15);

      if (selectedFiles.length > 15) {
        const isOk = await showMessage('파일 개수 확인', `선택항목 중 15개 ${type === 'url' ? 'url을' : 'html 태그를'} 복사했습니다.<br />(한 번에 생성할 수 있는 url은 최대 15개입니다.)`);

        if (isOk) {
          setSelectedFiles(filesToCopy);
        }
      }

      const copyText = getImagehostUrls(type, filesToCopy).join('\n');

      await navigator.clipboard.writeText(copyText);
      showToaster('success', '', `${type === 'url' ? '주소' : 'html태그'}를 복사했습니다.`);
      contextMenuClose();
    } catch (err) {
      showToaster('error', '', `${type === 'url' ? '주소' : 'html태그'} 복사에 실패했습니다.`);
    }
  };

  /**
   * 파일 삭제
   */
  const deleteFiles = async () => {
    const isConfirmed = await showConfirm('파일/폴더 삭제', '선택 항목 및 하위 항목을 삭제합니다.');

    if (isConfirmed) {
      try {
        const deleteParams = {
          ftp_no: currentFtpInfo.ftp_no,
          remotePaths: selectedFiles.map((file) => `${currentFtpPath}${file.name}`)
        };
        const res = await imageHostModel.deleteFtpFiles(deleteParams);

        if (res.results) {
          if (res.results.success) {
            showToaster('success', '', `${res.results.success}개 항목을 삭제했습니다.`);
          }
          if (res.results.failed) {
            setTimeout(() => {
              showToaster('error', '', `${res.results.failed}개 항목의 삭제에 실패했습니다.`);
            }, 500);
          }

          refreshFiles(); // 파일 목록 새로고침
        } else {
          throw new Error(res.messages ? res.messages.join(', ') : '파일 삭제에 실패했습니다.');
        }
      } catch (err: any) {
        showToaster('error', '', err.message || '삭제에 실패했습니다.');
      }
    }
  };

  /**
   * 파일 다운로드
   */
  const handleDownload = async () => {
    if (!selectedFiles.length) {
      showMessage('선택된 항목이 없습니다.');

      return;
    }

    const isConfirmed = await showConfirm('다운로드', '선택 항목을 다운로드하시겠습니까?');

    if (isConfirmed) {
      try {
        const downloadParams = {
          ftp_no: currentFtpInfo.ftp_no,
          remotePaths: selectedFiles.map((file) => {
            return {
              name: `${currentFtpPath}${file.name}`,
              type: file.type
            };
          })
        };

        const currTime = moment().format('YYYYMMDD');
        const blob = await imageHostModel.downloadFtpFile(downloadParams);
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = downloadUrl;
        link.download = selectedFiles.length === 1 && selectedFiles[0].type === 'file' ? selectedFiles[0].name : `imagehosting_download_${currTime}.zip`;
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(downloadUrl);

        showToaster('success', '', `${selectedFiles.length}개 항목을 다운로드했습니다.`);
      } catch (err: any) {
        showToaster('error', '', `${selectedFiles.length}개 항목 다운로드에 실패했습니다.`);
      }
    }
  };

  /**
   * 우클릭 메뉴 아이템
   */
  const menuItems = [
    {
      icon: 'icon-eye2',
      label: '미리보기',
      action: function () {
        const extension = selectedFiles[0].name.split('.').pop()?.toLowerCase();

        if (!['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension)) {
          showMessage('에러', '미리보기 기능은 이미지/영상 파일만 지원됩니다.');

          return;
        }

        imagePathRef.current = `https://${currentFtpInfo.host}${currentFtpPath}${selectedFiles[0].name}`;
        setImagePreviewOpen(true);
      }
    },
    {
      line: true,
      icon: 'icon-copy3',
      label: '주소복사',
      action: async () => {
        copyAddress('url');
      }
    },
    {
      icon: 'fa fa-code',
      label: 'HTML 태그 복사',
      action: function () {
        copyAddress('html');
      }
    },
    {
      line: true,
      icon: 'icon-pencil4',
      label: '이름변경',
      action: async () => {
        try {
          const { isConfirmed, textValue } = await showCustomPrompt(
            '이름 변경', null, null, null, null, null, '1글자 이상 입력해 주세요', '', (selectedFiles[0].type === 'directory' ? checkDuplicateFolderName : checkDuplicateFileName), selectedFiles[0].name
          );

          if (isConfirmed) {
            let doEditName = true;
            const imgCheck = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;

            if (!imgCheck.test(textValue)) {
              doEditName = await showConfirm('주의!', '이미지 확장자가 없습니다.<br />확장자 없이 이름을 변경하시겠습니까?');
            }

            if (doEditName) {
              const renameParams = {
                ftp_no: currentFtpInfo.ftp_no,
                oldPath: encodeURIComponent(`${currentFtpPath}${selectedFiles[0].name}`),
                newPath: encodeURIComponent(`${currentFtpPath}${textValue}`),
              };
              const res = await imageHostModel.renameFtpFile(renameParams);

              if (res?.results === 'success') {
                showToaster('success', '', '이름을 변경했습니다.');
                refreshFiles();
              } else {
                throw new Error(res.error || '이름 변경에 실패했습니다.');
              }
            }
          }
        } catch (err: any) {
          showToaster('error', '', err.message || '이름 변경에 실패했습니다.');
        }
      }
    },
    {
      line: true,
      icon: 'icon-download4',
      label: '다운로드',
      action: function () {
        handleDownload();
      }
    },
    {
      icon: 'fa fa-exchange',
      label: '이동',
      action: function () {
        showMessage('알림', '지원 준비 중입니다.');
      }
    },
    {
      icon: 'icon-x',
      label: '삭제',
      action: function () {
        deleteFiles();
      }
    },
  ];

  /**
   * 우클릭 메뉴 변경
   */
  const changeContextMenu = () => {
    if (selectedFiles.length === 1) {
      if (selectedFiles[0].type === 'file') {
        setContextMenuItem(menuItems);
      } else {
        setContextMenuItem(menuItems.filter((item) => ['이름변경', '다운로드', '이동', '삭제'].includes(item.label)));
      }
    } else if (selectedFiles.length > 1) {
      if (selectedFiles.length === selectedFiles.filter((file) => file.type === 'file').length) {
        setContextMenuItem(menuItems.filter((item) => ['주소복사', 'HTML 태그 복사', '다운로드', '이동', '삭제'].includes(item.label)));
      } else {
        setContextMenuItem(menuItems.filter((item) => ['다운로드', '이동', '삭제'].includes(item.label)));
      }
    }
  };

  /**
   * 마우스 드래그 오버
   */
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  /**
   * 드래그드랍 파일 첨부
   */
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    const imgCheck = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;

    // 이미지 파일만 필터링
    const imageFiles = droppedFiles.filter((file: any) => !imgCheck.test(file.name));

    if (imageFiles.length) {
      showMessage('안내', 'jpg, jpeg, png, gif, webp 파일만 업로드 가능합니다.');

      return;
    }

    const fileList = new DataTransfer();
    droppedFiles.forEach(file => fileList.items.add(file as File));
    handleFileUpload({ target: { files: fileList.files } } as React.ChangeEvent<HTMLInputElement>);
  };

  /**
   * 파일 업로드
   */
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) { return; }

    try {
      // 한글 파일명 때문에 인코딩 후 전송해야 함.
      // sendUrlFile 에 할까 하다가 일단은 여기 처리.
      const encodedFiles = Array.from(files).map(file => {
        // 한글 인코딩 문제로 normalize('NFC') 해주어야 정상 한글로 인식됨.
        const encodedFileName = encodeURIComponent(file.name.normalize('NFC'));

        return new File([file], encodedFileName, { type: file.type });
      });
      const uploadParams = {
        ftp_no: currentFtpInfo.ftp_no,
        remotePath: currentFtpPath === '/' ? currentFtpPath : currentFtpPath.slice(0, -1),
        files: encodedFiles
      };

      // 서버로 파일 업로드 요청
      const res: { results: { success: number, failed: number, messages: string[] }, error_code?: number, messages?: string[] } = await imageHostModel.uploadFtpFiles(uploadParams);

      if (res.results) {
        if (res.results.failed === files.length) {
          showToaster('error', '', `${files.length}개 항목의 업로드를 실패했습니다.`);
        } else {
          showToaster('success', '', `${res.results.success}개 항목을 업로드했습니다.`);
          if (res.results.success !== files.length) {
            setTimeout(() => {
              showToaster('error', '', `${res.results.failed}개 항목의 업로드를 실패했습니다.`);
            }, 500);
          }
          refreshFiles(); // 파일 목록 새로고침
        }

        if (res.results?.messages?.length) {
          showMessage('파일 업로드 실패', res.results.messages.join('<br />'));
        }
      } else {
        throw new Error(res.messages ? res.messages.join(', ') : '파일 업로드에 실패했습니다.');
      }
    } catch (error: any) {
      showToaster('error', '', `${files.length}개 항목의 업로드를 실패했습니다.`);
    } finally {
      event.target.value = '';
    }
  };

  /**
   * 이미지 미리보기 모달 닫기
   */
  const closeImagePreview = () => {
    setImagePreviewOpen(false);
  };

  return (
    <>
      <div style={styles.isConnected}>
        <div style={styles.btnGroupContainer}>
          {/* left */}
          <div style={styles.btnArea}>
            {/* button, label 비활성화 스타일 : disabled */}
            <button
              type="button"
              className="btn"
              style={styles.btnType1}
              onClick={createFtpFolder}
            >
              <i style={styles.addFolderIcon} />
                새 폴더
            </button>
            <input
              type="file"
              id="uploadFiles"
              style={{ display: 'none' }}
              accept=".jpg, .jpeg, .png, .gif, .webp"
              multiple
              onChange={handleFileUpload}
              disabled={selectedFiles.length > 0}
            />
            <label
              htmlFor="uploadFiles"
              style={styles.btnType1}
              className={`btn ${selectedFiles.length ? 'disabled' : ''}`}
            >
              <i style={styles.uploadIcon} />
                업로드
            </label>
            <button
              type="button"
              className="btn"
              style={styles.btnType2}
              disabled={selectedFiles.length === 0}
              onClick={handleDownload}
            >
              <i style={styles.downloadIcon} />
                다운로드
            </button>
            <button
              type="button"
              className="btn"
              style={styles.btnType2}
              onClick={() => showMessage('알림', '지원 준비 중입니다.')}
            >
              <i style={styles.moveIcon} />
              이동
            </button>
            <button
              type="button"
              className="btn"
              style={styles.btnType2}
              disabled={selectedFiles.length === 0}
              onClick={deleteFiles}
            >
              <i style={styles.deleteIcon} />
              삭제
            </button>
            <button
              type="button"
              className="btn"
              style={styles.btnType1}
              onClick={refreshFiles}
            >
              <i style={styles.refreshIcon} />
                새로고침
            </button>
          </div>

          {/* right */}
          {/* 노출항목 설정 */}
          {/* <div style={styles.statusArea}>
            <div className="position-relative">
              <button
                type="button"
                className="btn"
                style={styles.btnType1}
                onClick={onClickDropdown1}
              >
                수정일
              </button>
              {dropdown1 &&
                <ul className="dropdown-menu" style={{ ...styles.dropDown, left: 'unset', right: '2px' }}>
                  <div className="dropdown-group">
                    <li>
                      <a>수정일</a>
                    </li>
                    <li>
                      <a>이름</a>
                    </li>
                    <li>
                      <a>크기</a>
                    </li>
                    <li>
                      <a>파일 유형</a>
                    </li>
                    <li>
                      <a>생성일</a>
                    </li>
                  </div>
                </ul>
              }
            </div>
            <div className="position-relative">
              <button
                type="button"
                className="btn"
                style={{ ...styles.btnType1, padding: '0', width: '30px' }}
                onClick={onClickDropdown2}
              >
                {sort ?
                  <i style={styles.sortDownIcon} />
                  :
                  <i style={styles.sortUpIcon} />
                }
              </button>
              {dropdown2 &&
                <ul className="dropdown-menu" style={{ ...styles.dropDown, left: 'unset', right: '2px' }}>
                  <div className="dropdown-group">
                    <li>
                      <a onClick={(e) => e.stopPropagation()}>
                        <label className="checkbox-inline" style={{ width: '100%', fontSize: '13px' }}>
                          <input type="checkbox" checked readOnly />
                          크기
                        </label>
                      </a>
                    </li>
                    <li>
                      <a onClick={(e) => e.stopPropagation()}>
                        <label className="checkbox-inline" style={{ width: '100%', fontSize: '13px' }}>
                          <input type="checkbox" />
                          파일 유형
                        </label>
                      </a>
                    </li>
                    <li>
                      <a onClick={(e) => e.stopPropagation()}>
                        <label className="checkbox-inline" style={{ width: '100%', fontSize: '13px' }}>
                          <input type="checkbox" checked readOnly />
                          수정일
                        </label>
                      </a>
                    </li>
                    <li>
                      <a onClick={(e) => e.stopPropagation()}>
                        <label className="checkbox-inline" style={{ width: '100%', fontSize: '13px' }}>
                          <input type="checkbox" />
                          생성일
                        </label>
                      </a>
                    </li>
                    <li>
                      <a onClick={(e) => e.stopPropagation()}>
                        <label className="checkbox-inline" style={{ width: '100%', fontSize: '13px' }}>
                          <input type="checkbox" />
                          수정한 사람
                        </label>
                      </a>
                    </li>
                  </div>
                </ul>
              }
            </div>
          </div> */}
        </div>
      </div>
      {/* 파일 리스트 */}
      <div style={styles.dataTableArea}>
        <table style={ searchedData.length ? styles.table : { ...styles.table, height: '100%' } }>
          <thead>
            <tr>
              <th style={{ ...styles.th, textAlign: 'left', width: '50px' }}>
                <input
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  style={styles.headerCheckbox}
                />
              </th>
              <th style={styles.th}>이름</th>
              <th style={{ ...styles.th, width: '70px' }}>크기</th>
              <th style={{ ...styles.th, width: '190px' }}>수정일</th>
            </tr>
          </thead>
          <tbody
            onDragOver={handleDragOver}
            onDragLeave={() => setIsDragging(false)}
            onDrop={handleDrop}
            style={isDragging ? styles.dragging : {}}
          >
            {!!(folderPath.length) && (
              <tr>
                <td colSpan={4}>
                  <button
                    type="button"
                    className="btn"
                    style={styles.backBtn}
                    onDoubleClick={moveUpFolder}
                  >
                    <i className="fa fa-reply" style={{ marginRight: '4px', color: '#555' }} />
                    back
                  </button>
                </td>
              </tr>
            )}
            {/* 파일 없을 때 UI */}
            {!searchedData.length && (
              <tr>
                <td colSpan={4} style={{ height: '100%' }}>
                  <div style={styles.isEmptyFileBox}>
                    <p style={styles.isEmptyFileText}>파일이 없습니다.</p>
                  </div>
                </td>
              </tr>
            )}
            {searchedData.map((file) => (
              <tr
                key={file.id}
                onMouseOver={() => handleMouseOver(file.id)}
                onMouseOut={handleMouseOut}
                onClick={() => handleRowClick(file)}
                onDoubleClick={() => {
                  // 폴더 더블클릭시 하위 폴더로 이동
                  if (file.type === 'directory') {
                    setSelectedFiles([]);
                    setFolderPath([ ...folderPath, file.name ]);
                  }
                  // 파일 더블클릭시 모달 닫고 이미지 주소 또는 태그 정보 리턴
                  else if (file.type === 'file') {
                    const extension = file.name.split('.').pop()?.toLowerCase();

                    if (!['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension)) {
                      showMessage('에러', '이미지 파일이 아닙니다.');

                      return;
                    }

                    handleClose(getImagehostUrls('', [file]));
                  }
                }}
                onContextMenu={(e) => handleContextMenu(e, file)}
                style={{
                  backgroundColor: selectedFiles.some((sFile) => sFile.id === file.id) ? '#d3d3d3' : hoveredRow === file.id ? '#f0f0f0' : 'transparent',
                  cursor: 'pointer',
                }}
              >
                <td style={{ ...styles.td, textAlign: 'left', width: '50px' }}>
                  <input
                    type="checkbox"
                    checked={selectedFiles.some((sFile) => sFile.id === file.id)}
                    onChange={() => handleSelect(file)}
                    style={styles.rowCheckbox}
                  />
                </td>
                <td style={{ ...styles.td, textAlign: 'left' }}>
                  {/* 아이콘 : 폴더 */}
                  {checkFileExtension(file.type, file.name)}
                  {file.name}
                </td>
                <td style={styles.td}>{file.size.toLocaleString()}</td>
                <td style={styles.td}>{file.mdate}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {contextMenu && (
          <ContextMenu
            x={contextMenu.x - 30}
            y={contextMenu.y - 30}
            menuItems={contextMenuItem}
            onClose={contextMenuClose}
          />
        )}
      </div>

      {/* 이미지미리보기 모달 */}
      <CommonModal
        isOpen={imagePreviewOpen}
        size="mini"
        backdrop={true}
        scrollable={true}
        escClose={true}
        handleClose={closeImagePreview}
        component={ImagePreviewComponent}
        data={{
          imageSrc: imagePathRef.current
        }}
      />
    </>
  );
};

const styles: { [key: string]: CSSProperties } = {
  isConnected: {
    display: 'flex',
    flexDirection: 'column',
  },
  btnGroupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  btnType1: {
    margin: '0 2px',
    padding: '0 10px',
    height: '30px',
    lineHeight: '28px',
    fontSize: '12px',
    color: '#1E88E5',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    border: '1px solid #1E88E5',
    background: '#fff',
  },
  btnType2: {
    margin: '0 2px',
    padding: '0 10px',
    height: '30px',
    lineHeight: '28px',
    fontSize: '12px',
    color: '#545454',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    border: '1px solid #ddd',
    background: '#fff',
  },
  addFolderIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_add_folder.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  uploadIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_upload.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  downloadIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_download.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  moveIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_move.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  deleteIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_delete.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  refreshIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_refresh.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  sortDownIcon: {
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_sort_down.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  sortUpIcon: {
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_sort_up.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  btnArea: {
    display: 'flex',
    alignItems: 'center',
  },
  statusArea: {
    display: 'flex',
  },
  dropDown: {
    display: 'block',
    padding: '0',
    margin: '0',
    position: 'absolute',
    top: '32px',
    border: '1px solid #ddd',
    borderRadius: '3px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    listStyle: 'none',
  },
  workTypeIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_work_type.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  workResultIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_work_result.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  workDateIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_work_date.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  fileExcelIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_file_excel.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  dateBox: {
    margin: '10px 0 0 20px',
  },
  dateBtnGroup: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dateResetBtn: {
    padding: '8px 10px',
    fontSize: '12px',
    color: '#545454',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    border: '1px solid #ddd',
    background: '#fff',
  },
  dateConfirmBtn: {
    marginLeft: '4px',
    padding: '8px 10px',
    fontSize: '12px',
    color: '#1E88E5',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    border: '1px solid #1E88E5',
    background: '#fff',
  },
  logCntBox: {
    marginRight: '10px',
    fontSize: '12px',
    color: '#545454',
    fontWeight: '600',
  },
  logCnt: {
    color: '#1E88E5',
  },
  dataTableArea: {
    flex: 1,
    marginTop: '10px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '6px',
  },
  th: {
    padding: '10px',
    textAlign: 'center',
    borderBottom: '2px solid #ccc',
    backgroundColor: '#f8f8f8'
  },
  td: {
    padding: '10px',
    textAlign: 'center',
    borderBottom: '1px solid #ddd',
  },
  headerCheckbox: {
    marginLeft: '4px',
  },
  rowCheckbox: {
    marginLeft: '4px',
  },
  backBtn: {
    paddingLeft: '58px',
    width: '100%',
    textAlign: 'left',
  },
  fileTypeIcon: {
    marginRight: '5px',
  },
  contextMenu: {
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: '#fff',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    margin: 0,
    padding: '10px 0',
    borderRadius: '5px'
  },
  contextMenuUl: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  isEmptyFileBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  isEmptyFileText: {
    paddingTop: '60px',
    fontSize: '14px',
    color: '#999',
    fontWeight: '500',
    letterSpacing: '-0.42px',
    background: 'url(../../assets/images/imagehosting/ico_is_empty_file.svg) no-repeat center top',
  },
  dragging: {
    border: '5px dashed #32cd32',
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  },
};

export default FileListComponent;