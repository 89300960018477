/**
 *
 *  2022-03-14 Denver
 */
'use strict';
angular.module('gmpApp')
  .controller('StockInoutListCtrl', function ($scope, $filter, commonSVC, $compile, $state, $q,
    warehouseList, inventorySVC, settings, productModel, supplierModel, warehouseModel, categoryModel, systemModel, inventoryModel,
    gettextCatalog, $timeout, $rootScope, systemList, userInfo) {

    $scope.tabs = [
      {
        type: 'all',
        name: '전체'
      },
      {
        type: 'manual',
        name: '수동입출고',
      },
      {
        type: 'ord',
        name: '주문입출고'
      }
    ];
    $scope.tabActive = 0;
    $scope.searchForm = angular.copy(inventorySVC.searchForm_inout);
    // 설정에서 검색 기간 변수 읽어서 강제로 sdate에 넣어줌 - service 수정 전까지는 이렇게 해야 할 듯
    systemList.data.search_date = 1 < +systemList.data.search_date ? 1 : systemList.data.search_date; // 검색 기간 default 1개월로 설정
    $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
    $scope.searchData = angular.copy(inventorySVC.searchData_inout);

    // 최대 조회 기간(월)
    $scope.maxSpanMonths = 3;

    // 검색어 선택박스 옵션 값 세팅 '전체'탭
    $scope.searchData.search_key_items = $scope.searchData.search_key_items.filter(function(item) {
      return ['all', 'sku_cd', 'stock_cd', 'prod_name'].includes(item.value);
    });

    $scope.selectCount = 'all';
    $scope.selectedInout = []; // 선택되어 있는 리스트 재고 로그 번호

    const searchDetail = [
      {
        title: gettextCatalog.getString('구분 선택'),
        search_name: 'inout_type',
        item_list: [
          { name: '입고', value: 'in' },
          { name: '출고', value: 'out' },
          { name: '판매불가', value: 'notsale' },
          { name: '재고이동', value: 'transfer' }
        ],
        item_key: 'name', // 2018-09-09 Jacob 상세검색에서 매입처 선택시 undefined 오류 수정
        item_value: 'value',
        select_value: ''
      },
      {
        title: gettextCatalog.getString('판매불가 유형 전체'),
        search_name: 'notsale_type',
        item_list: [
          { name: '불량', value: '2' },
          { name: '파손', value: '3' },
          { name: '판매기한 경과', value: '4' },
          { name: '판매기한 임박', value: '5' },
          { name: '폐기 대상', value: '6' },
          { name: '기타', value: '999' },
        ],
        item_key: 'name',
        item_value: 'value',
        select_value: '',
        add_class: 'select-search',
        filter: function() {
          return $scope.searchForm.inout_type === 'notsale';
        }
      },
      {
        title: gettextCatalog.getString('배송처 선택'),
        search_name: 'delivery_vendor',
        item_list: warehouseList.data.result || [],
        item_key: 'warehouse_name',
        item_value: 'code',
        select_value: '',
        add_class: 'select-search'

      }
    ];

    // 배송처일 때, 배송처 검색 조건 제거 #gmpkr-7591 2019-09-18 Jacob
    if ($rootScope.user_profile.auth_type === '배송처') {
      searchDetail.pop();
    }

    // 상세 조건검색 리스트
    $scope.searchDetail = angular.copy(searchDetail);
    $scope.searchBtn = {
      actions_right: [],
      table_actions: [
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          test_id: 'btn-stock-excel',
          item_list: [
            {
              label: gettextCatalog.getString('전체엑셀 다운로드'),
              test_id: 'dropdown-excel-all',
              action: function () {
                if (commonSVC.checkPermission('stock.roles.excelStock', userInfo.permission) === false) {
                  commonSVC.showToaster('error', '앗! 사용 권한이 없네요.', '요청하신 기능은 사용 권한이 필요합니다.\nPLAYAUTO 2.0 관리자에게 문의해보세요!');

                  return false;
                }
                $scope.excelDown('all');
              },
            }, {
              label: gettextCatalog.getString('선택엑셀 다운로드'),
              test_id: 'dropdown-excel-select',
              action: function () {
                if (commonSVC.checkPermission('stock.roles.excelStock', userInfo.permission) === false) {
                  commonSVC.showToaster('error', '앗! 사용 권한이 없네요.', '요청하신 기능은 사용 권한이 필요합니다.\nPLAYAUTO 2.0 관리자에게 문의해보세요!');

                  return false;
                }
                $scope.excelDown('select');
              },
            }
          ]
        }
      ]
    };

    if ($rootScope.user_profile.auth_type === '배송처') {
      $scope.searchBtn.actions_right = [];
    }

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    /**
     * 조회 버튼 클릭시 처리
     */
    $scope.searchDo = function (refresh, noDelay) {
      $scope.selectedInout = [];

      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);

    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.tabActive = 0;
      $scope.searchForm = angular.copy(inventorySVC.searchForm_inout);
      $scope.searchData.search_key_name = '전체';
      $scope.selectCount = 'all';
      $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      $scope.searchForm.selectDate = `${systemList.data.search_date}MONTH`;
      $scope.searchDetail = angular.copy(searchDetail);
      $scope.searchData.search_word = '';
      $scope.searchDo(true, true);
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 엑셀 다운 모달창
     * */
    $scope.excelDown = function (type) {
      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: angular.copy(inventorySVC.excelFieldList_inout),
        title: '입출고내역',
        url: '/app/stock/inout/excel-down',
        searchForm: $scope.pageData,
        page: 'stock'
      };
      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const selectList = $scope.grid.methods.selectedData('all');
        if (selectList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 상품이 없습니다.'));

          return false;
        } else {
          const newArray = [];

          _.forEach(selectList, function (item) {
            newArray.push(_.pick(item, ['inout_no', 'prod_no', 'memo_no']));
          });
          resolve.data.select_list = newArray;
          resolve.data.count = selectList.length;
          resolve.data.isAll = false;
        }
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    // 사유 일괄 입력
    $scope.editAllContent = () => {
      const selectedNotsale = $scope.grid.methods.selectedData('all').some(data => data.inout_type === '판매불가');
      const selectedinout = $scope.grid.methods.selectedData('all').some(data => data.inout_type !== '판매불가');

      if (!$scope.grid.methods.selectedData('all').length) {
        commonSVC.showMessage('선택된 데이터가 없습니다.', '리스트에서 내역을 선택하신 후 작업을 진행해 주세요.');

        return false;
      }

      if (selectedNotsale && selectedinout) {
        commonSVC.showConfirmCustom({
          title: gettextCatalog.getString('일괄 입력 안내'),
          text: '<div>판매불가 유형은 입고 또는 출고와 함께 사유를 입력할 수 없습니다.</div>',
          html: true,
          showCancelButton: true,
          confirmButtonColor: '#5c90d2',
          confirmButtonText: gettextCatalog.getString('입고/출고 내역만 선택 후 입력'),
          cancelButtonText: gettextCatalog.getString('취소'),
          animation: false,
        }, function (state) {
          if (state) {
            const onlyInoutType = $scope.grid.methods.selectedData('all').filter(data => data.inout_type !== '판매불가');
            const changeDatas = onlyInoutType.map(log => ({
              inout_no: log.inout_no,
              memo_no: log.memo_no,
              prod_no: log.prod_no,
              content: $scope.allContent ? commonSVC.cutByByte($scope.allContent, 60) : ''
            }));

            inventoryModel.inoutLogEdit({ editData: changeDatas }, function(state) {

              if (state === 'success') {
                commonSVC.showToaster('success', '성공', '입/출고 사유 수정에 성공하였습니다.');

              } else {
                commonSVC.showToaster('error', '실패', '입/출고 사유 수정에 실패하였습니다.');
              }

              $scope.searchDo(true, true);

              $timeout(() => {});
            });
            $scope.allContent = '';
            $scope.allSaleContent = '';
          }
        });
      } else if ((selectedNotsale && !$scope.allSaleContent) && !selectedinout) {
        return commonSVC.showToaster('error', '실패', '입/출고 사유 수정에 실패하였습니다.');
      } else {
        commonSVC.showConfirmCustom({
          title: gettextCatalog.getString('일괄 입력 안내'),
          text: `<div>리스트에서 선택한 내역에 입력하신 사유를 일괄로 반영합니다.<br/>
          이미 입력되어 있는 정보가 있더라도 입력하신 정보로 저장합니다.<br/><br/>
          입력한 사항을 저장 하시겠습니까?
          <ul>
          <li>저장 : 입력한 사항을 저장함.</li>
          <li>취소 : 입력중인 정보를 저장하지 않고 초기화 함.</li>
          </ul>
          </div>`,
          html: true,
          showCancelButton: true,
          confirmButtonColor: '#5c90d2',
          confirmButtonText: gettextCatalog.getString('저장'),
          cancelButtonText: gettextCatalog.getString('취소'),
          animation: false,
        }, function (state) {
          if (state) {
            const changeDatas = $scope.grid.methods.selectedData('all').map(log => ({
              inout_no: log.inout_no,
              memo_no: log.memo_no,
              prod_no: log.prod_no,
              content: log.inout_type === '판매불가' ? $scope.allSaleContent : ($scope.allContent ? commonSVC.cutByByte($scope.allContent, 60) : ''),
              ...log.inout_type === '판매불가' && { edit_notsale: log } }));

            inventoryModel.inoutLogEdit({ editData: changeDatas }, function(state, data) {

              if (state === 'success') {
                commonSVC.showToaster('success', '성공', '입/출고 사유 수정에 성공하였습니다.');

              } else {
                commonSVC.showToaster('error', '실패', data.data.error);
              }
              $scope.searchDo(true, true);
            });
            $scope.allContent = '';
            $scope.allSaleContent = '';
          }

          $timeout(() => {});
        });
      }
    };

    // 사유 수정 버튼
    // 클릭 시 선택된 row를 저장하여 div => input으로 바뀌어 입력 가능하도록 해줌
    $scope.editSelectContent = () => {
      if (!$scope.grid.methods.selectedData('inout_no').length) {

        commonSVC.showMessage('선택된 데이터가 없습니다.', '리스트에서 내역을 선택하신 후 작업을 진행해 주세요.');

        return false;
      }
      $scope.selectedInout = $scope.grid.methods.selectedData('inout_no');
    };

    // 판매불가 이력만 선택된 경우 일괄변경 disabled
    $scope.checkNotSaleSelect = () => {

      if (!$scope.grid.methods.selectedData || _.isEmpty($scope.grid.methods.selectedData('all'))) {
        return false;
      }

      return !($scope.grid.methods.selectedData('all').filter(data => data.inout_type === '판매불가').length < $scope.grid.methods.selectedData('all').length);

    };

    $scope.checkInoutSelect = () => {
      if (!$scope.grid.methods.selectedData || _.isEmpty($scope.grid.methods.selectedData('all'))) {
        return true;
      }

      return $scope.grid.methods.selectedData('all').filter(data => data.inout_type === '판매불가').length < $scope.grid.methods.selectedData('all').length;
    };

    // 데이터 테이블 스크롤 시 selectbox 값이 초기화되는 문제가 있어 null로 초기화될 경우 기존 저장된 값으로 변경
    $scope.checkChangeAuto = (row) => {
      if (!row.content) {
        row.content = row.temp_edit_type;
      } else {
        row.temp_edit_type = row.content;
      }
    };

    // 개별 사유 수정
    $scope.saveContent = () => {

      const changeDatas = _.differenceWith($scope.logListRow, $scope.originLogList, _.isEqual).map(log => ({
        inout_no: log.inout_no,
        memo_no: log.memo_no,
        prod_no: log.prod_no,
        content: commonSVC.cutByByte(log.content, 60),
        ...log.inout_type === '판매불가' && { edit_notsale: log }
      }));

      if (!changeDatas.length) {
        commonSVC.showToaster('error', '실패', '변경사항이 없습니다.');
        $scope.searchDo(true, true);

        return false;
      }

      inventoryModel.inoutLogEdit({ editData: changeDatas }, function(state, data) {
        if (state === 'success') {
          commonSVC.showToaster('success', '성공', '입/출고 사유 수정에 성공하였습니다.');

          $scope.selectedInout = [];
        } else {
          commonSVC.showToaster('error', '실패', data.data.error);
        }
        $timeout(() => {});
        $scope.searchDo(true, true);
      });
    };

    // 선택 되어있는지 판단하여 선택되었을 경우
    // 사유 영역 input 태그
    $scope.checkSelected = (select) => {
      if ($scope.selectedInout.length) {
        return !$scope.selectedInout.includes(select);
      }

      return true;
    };
    // 테이블 컬럼 사이즈 확인
    $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
      if (toState.name == 'main.prod_product_list') {
        const $wrapper = $('#prod_prodlist_grid_wrapper');
        const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

        if ($tableHeader.width() < 1) {
          $timeout(function () {
            $scope.grid.methods.calcLayout('set_width');
          }, 0);
        }
      }
    });

    $scope.selectRowUniqList = [];

    // 데이터 테이블에서 선택한 rowList 정보 받아옴
    $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
      $scope.selectRowUniqList = data.map(({ prod_no }) => prod_no);
      $scope.$apply();
    }, 200));

    /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    // 탭 변경
    $scope.changeInoutTab = function (idx, tab) {
      $scope.tabActive = idx;
      $scope.searchForm = angular.copy(inventorySVC.searchForm_inout);
      $scope.searchForm.tab = tab;
      // 탭 별 최대 겁색기간(수동입출고: 12개월 그 외: 3개월)
      $scope.maxSpanMonths = idx === 1 ? 12 : 3;

      // 탭 별 기본 검색기간(수동입출고: 3개월 그 외: 1개월)
      const defaultMonth = idx === 1 ? 3 : 1;
      if (defaultMonth < moment($scope.searchForm.edate).diff(moment($scope.searchForm.sdate), 'months')) {
        $scope.searchForm.sdate = moment().subtract(defaultMonth, 'month').format('YYYY-MM-DD');
        $scope.searchForm.selectDate = `${defaultMonth}MONTH`;
      }

      // 탭 별 특정컬럼 노출 처리
      function setColVisible(isVisible) {
        ['content', 'stock_cnt_real'].forEach(key => $scope.grid.methods.setColumnsVisible(key, isVisible, isVisible)); // 수동입출고 탭에서만 사유, 실재고 컬럼 노출
      }
      // 전체 탭 클릭 시 > 검색 선택박스 옵션 ['전체', 'SKU코드', '재고관리코드', 'SKU상품명'] 만 노출
      if (!['manual', 'ord'].includes(tab)) {
        $scope.searchData.search_key_items = $scope.searchData.search_key_items.filter((item) => {
          return ['all', 'sku_cd', 'stock_cd', 'prod_name'].includes(item.value);
        });
      } else {
        $scope.searchData.search_key_items = angular.copy(inventorySVC.searchData_inout.search_key_items);
      }

      // '주문입출고'탭 이동 시
      if ($scope.tabActive === 2) {
        // 날짜검색 '판매불가 처리일'제거
        $scope.searchData.search_date_type = $scope.searchData.search_date_type.filter((item) => !['notsaledate'].includes(item.value));

        // 상세검색 '판매불가', '재고이동' 제거
        $scope.searchDetail[0].item_list = $scope.searchDetail[0].item_list.filter((list) => ['in', 'out'].includes(list.value));

        // 상세검색 판매불가 유형 박스 제거
        const indexToRemove = $scope.searchDetail.findIndex(item => item.search_name === 'notsale_type');
        if (indexToRemove !== -1) {
          $scope.searchDetail.splice(indexToRemove, 1);
        }
        setColVisible(false);
      } else {
        $scope.searchData.search_date_type = angular.copy(inventorySVC.searchData_inout.search_date_type);
        $scope.searchDetail = angular.copy(searchDetail);
        setColVisible(true);
      }
      $scope.searchDo(true, true);
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['inout_type', 'sku_cd'],
      defaultSortingColumns: ['wdate'],
      alignRightColumns: ['in_cnt', 'out_cnt', 'notsale_type_cnt', 'stock_cnt_real'],
      notSortingColumns: [
        'wdate', 'inout_type', 'sku_cd', 'prod_name', 'attri', 'depot_name', 'in_cnt', 'out_cnt',
        'notsale_type_cnt', 'stock_cnt_real', 'content', 'work_name', 'wm_name', 'maker', 'brand',
        'barcode', 'stock_cd',
      ],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: ['maker', 'brand', 'barcode', 'stock_cd'],

      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/inout`,
        requestWillAction: function (data) {
          // 페이지 이동, n건씩 보기 시엔 카운트 조회하지 않고 기존 카운트 사용
          $scope.searchForm.prev_count = $scope.searchData.totalCount;
          data = angular.merge({}, data, $scope.searchForm);

          if ($rootScope.user_profile.auth_type === '배송처') {
            data.delivery_vendor = $rootScope.user_profile.depot_no;
          }

          $scope.pageData = angular.copy(data); //엑셀 모달용 데이터

          return data;
        },
        requestDidAction: function(result) {
          result.results.forEach(row => {
            row.before_notsale_type = row.temp_edit_type = row.content;

            if (row.inout_type_sub_cd.startsWith('S3')) {
              row.inout_type = '판매불가';
            }

            if (row.inout_type === '보관' && ['S107', 'S203'].includes(row.inout_type_sub_cd)) {
              row.inout_type = '재고이동';
              row[row.move_cnt >= 0 ? 'in_cnt' : 'out_cnt'] = row.move_cnt;
            }
          });

          $scope.logListRow = angular.copy(result.results);
          $scope.originLogList = angular.copy(result.results);
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'wdate',
          title: '등록일',
          width: 135,
          filter: 'dateValid'
        },
        {
          key: 'inout_type',
          title: '구분',
          width: 60
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 115
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 380,
        },
        { key: 'attri',
          title: '속성',
          width: 115
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 115
        },
        {
          key: 'in_cnt',
          title: '입고',
          width: 75
        },
        {
          key: 'out_cnt',
          title: '출고',
          width: 75
        },
        {
          key: 'notsale_type_cnt',
          title: '판매불가',
          width: 75
        },
        {
          key: 'stock_cnt_real',
          title: '실재고',
          width: 80,
          template: function(row) {
            return row.inout_input_type !== 'depot_inout_manual' ? '-' : row.stock_cnt_real;
          }
        },
        {
          key: 'content',
          title: '사유',
          width: 160,
          template: function(row) {
            if ($scope.tabActive === 2) {
              return false;
            }
            if (!['depot_inout_manual', 'ord_log'].includes(row.inout_input_type)) {
              return '-';
            }
            const idx = $scope.logListRow.findIndex(data => data.inout_no === row.inout_no);
            let notsale_type = '';

            if (row.inout_type === '판매불가' && row.pal_no !== 1) {
              switch (row.content?.toString()) {
                case '2':
                  notsale_type = '불량';
                  break;
                case '3':
                  notsale_type = '파손';
                  break;
                case '4':
                  notsale_type = '판매기한 경과';
                  break;
                case '5':
                  notsale_type = '판매기한 임박';
                  break;
                case '6':
                  notsale_type = '폐기 대상';
                  break;
                case '999':
                  notsale_type = '기타';
                  break;
              }
            }
            const template =
            `<div>
            <div ng-show="${row.content.length} && grid.appScope.checkSelected(${$scope.logListRow[idx].inout_no})">
            ${row.inout_type_sub_cd === 'S208' ? '[판매불가 출고]' : ''}${notsale_type || $scope.logListRow[idx].content}
            </div>
            <div ng-show="${!row.content.length} || !grid.appScope.checkSelected(${$scope.logListRow[idx].inout_no})">
                <input type="text" class="form-control" ng-if="grid.appScope.logListRow[${idx}].inout_type !== '보관' && (grid.appScope.logListRow[${idx}].pal_no === 1 || grid.appScope.logListRow[${idx}].inout_type_sub_cd === 'S208')" ng-model="grid.appScope.logListRow[${idx}].
                content" max-bytes="60"></input>
  
                <select class="form-control full-width no-padding" 
                  style="height: 22px;"
                  ng-if="grid.appScope.logListRow[${idx}].inout_type === '판매불가' && grid.appScope.logListRow[${idx}].pal_no !== 1" 
                  ng-model="grid.appScope.logListRow[${idx}].content" ng-change="grid.appScope.checkChangeAuto(grid.appScope.logListRow[${idx}])">
                  <option ng-value="''" hidden>유형을 선택하세요.</option>
                  <option ng-value="'2'">불량</option>
                  <option ng-value="'3'">파손</option>
                  <option ng-value="'4'">판매기한 경과</option>
                  <option ng-value="'5'">판매기한 임박</option>
                  <option ng-value="'6'">폐기 대상</option>
                  <option ng-value="'999'">기타</option>
                </select>
              </div>
            </div>`;

            return template;
          },
        },
        {
          key: 'work_name',
          title: '작업구분',
          width: 90
        },
        {
          key: 'wm_name',
          title: '작업자',
          width: 90
        },
        {
          key: 'maker',
          title: '제조사',
          notCompile: true,
          width: 100
        },
        {
          key: 'brand',
          title: '브랜드',
          notCompile: true,
          width: 100
        },
        {
          key: 'barcode',
          title: '바코드',
          notCompile: true,
          width: 100
        },
        {
          key: 'stock_cd',
          title: '재고관리코드',
          notCompile: true,
          width: 90
        }
      ]
    };
  });