'use strict';

angular.module('gmpApp')
  .service('columnSVC', function($rootScope, columnModel, commonSVC, gettextCatalog, $filter) {
    return {
      // 컬럼 추가 가능 리스트
      addColList: {
        ord: [
          {
            list_name: '신규 주문',
            col_type: 'order'
          },
          {
            list_name: '출고 관리',
            col_type: 'unstoring'
          },
          {
            list_name: '배송 관리',
            col_type: 'delivery'
          },
          {
            list_name: '클레임 주문 관리',
            col_type: 'claim'
          }
        ],
        product: [
          {
            list_name: 'SKU상품 관리',
            col_type: 'product'
          }
        ]
      },

      // 추가 컬럼 노출 스테이트
      addColStatus: [
        'main.order_shipment_order_list',
        'main.order_shipment_unstoring_list',
        'main.order_shipment_integrated_list',
        'main.order_shipment_claim_list',
        'main.order_shipment_delivery_list',
        'main.prod_product_list'
      ],

      // 컬럼명 저장 포맷
      setColumnName: function(name, type) {
        let return_name = '';

        if (type === 'SET') {
          return_name = `pa_addcol_${name}`;
        } else if (type === 'GET') {
          return_name = name.replace('pa_addcol_', '');
        }

        return return_name;
      },

      // 추가된 컬럼 데이터 테이블 세팅
      columnsSet: function (params) {
        const { gridOption, readOnly, addcolType, search_key_items, useEnumFirstValDefault } = params;
        const addCol = [];

        // $rootScope.addColumnList 는 무조건 들어온다고 보고 해당 로직 일단 주석. 추후 문제 발생시 다시 확인.
        // if (!$rootScope.addColumnList) {
        //   $rootScope.addColumnList = await columnModel.addColumnList({ addcol_type: 'all' });
        // }

        let list = [];

        if (addcolType === 'all') {
          list = $rootScope.addColumnList.data.results;
        } else if (addcolType === 'ord') {
          // 주문과 SKU만 있어서 ord로 넘어오면 product 제외 filter처리함
          list = $rootScope.addColumnList.data.results.filter(({ addcol_type }) => addcol_type !== 'product');
        } else {
          list = _.filter($rootScope.addColumnList.data.results, { addcol_type: addcolType });
        }

        list.forEach((o) => {
          addCol.push(
            {
              key: o.col_name,
              title: this.setColumnName(o.col_name, 'GET'),
              width: 120,
              template: (row) => {
                if (readOnly) {
                  let data = row[o.col_name];

                  // 값이 없는 경우 enum 첫번째 값 디폴트로 노출
                  if (useEnumFirstValDefault && o.input_type === 'enum' && !row[o.col_name]) {
                    data = o.enum_data.split(' ^ ')[0].split('||')[1];
                  }

                  return data;
                } else {
                  return this.columnTemplate(o);
                }
              },
              // 정렬 못하도록 PaUiGrid에 전달하는 값
              notSorting: true
            }
          );

          // 검색 조건 추가
          search_key_items.push(
            { label: gettextCatalog.getString(this.setColumnName(o.col_name, 'GET')), value: o.addcol_no }
          );
        });

        // 컬럼 추가
        gridOption.columns = gridOption.columns.concat(addCol);

        // return addCol;
      },

      // 추가 컬럼 및 로그 세팅
      setAddColumnValueParams: function (selectData, addcol_type, addColumnList) {
        const insertColumnList = [];    // 등록 리스트
        const oldData = [];             // 이전 데이터
        const logContent = [];          // 로그 정보
        const getOld = columnModel.getOld(addcol_type);

        _.forEach(selectData, (row) => {
          const findOld = _.find(getOld, (find) => {
            return addcol_type === 'product' ? find.prod_no === row.prod_no : find.uniq === row.uniq;
          });

          if (findOld) {
            oldData.push(findOld);
          }
        });

        addColumnList?.data?.results?.forEach((col) => {
          const data = {};

          data.input_type = col.input_type;
          data.values = _(selectData)
            .filter((row) => {
              const oldRow = _.find(oldData, (old) => {
                return addcol_type === 'product' ? old.prod_no === row.prod_no : old.uniq === row.uniq;
              });

              if (oldRow?.[col.col_name] != row[col.col_name]) {
                const log_data = {};

                if (addcol_type === 'product') {
                  log_data.prod_no = row.prod_no;
                } else {
                  log_data.uniq = row.uniq;
                }
                log_data.content = `(${col.col_name} : ${oldRow?.[col.col_name] || '없음'}->${row[col.col_name] || '없음'})`;
                logContent.push(log_data);

                return true;
              } else {
                return false;
              }
            })
            .map((row) => {
              const return_val = {};

              if (addcol_type == 'product') {
                return_val.prod_no = row.prod_no;
              } else {
                return_val.uniq = row.uniq;
              }
              return_val.value = row[col.col_name];
              return_val.addcol_no = col.addcol_no;
              return_val.input_type = col.input_type;

              if (col.enum_data) {
                if (Array.isArray(col.enum_data)) {
                  col.enumList = col.enum_data;
                  // enum_data = ['선택값1', '선택값2']
                  const findColVal = col.enumList.findIndex((o) => {
                    return o === row[col.col_name];
                  });

                  if (findColVal > -1) {
                    return_val.value = findColVal.toString();
                  }
                } else {
                  // enum_data = '0||선택값1 ^ 1||선택값2'
                  col.enumList = this.enumDataSet(col.enum_data);

                  const findColVal = _.find(col.enumList, (o) => {
                    return o[1] === row[col.col_name];
                  });

                  if (findColVal) {
                    return_val.value = findColVal[0];
                  }
                }
              }

              return return_val;
            })
            .value();

          if (data.values.length) {
            insertColumnList.push(data);
          }
        });

        return { insertColumnList, logContent };
      },

      // 추가 컬럼 정보 저장 (리스트)
      addColValue: async function (selectData, addcol_type, saveType) {
        const addColumnList = await columnModel.addColumnList({ addcol_type: addcol_type === 'product' ? 'product' : 'ord' });
        const { insertColumnList, logContent } = this.setAddColumnValueParams(selectData, addcol_type, addColumnList);

        if (insertColumnList.length) {
          return columnModel.addColumnValue({ insertColumnList, logContent, saveType, addcol_type });
        } else {
          return Promise.resolve(false);
        }
      },

      enumDataSet: (enum_data) => {
        const enumList = [];
        const enums = enum_data.split(' ^ ');

        enums.forEach((e) => {
          enumList.push(e.split('||'));
        });

        return enumList;
      },

      // 컬럼 추가 템플릿
      columnTemplate: function(col) {
        let results = '';

        let options = '<option value="">선택</option>';

        if (col.enumList && col.enumList.length) {
          col.enumList.forEach((e) => {
            options += `<option value="${e[1]}">${e[1]}</option>`;
          });
        } else if (col.enum_data) {
          const enums = col.enum_data.split(' ^ ');

          enums.forEach((e) => {
            const e_data = e.split('||');

            options += `<option value="${e_data[1]}">${e_data[1]}</option>`;
          });
        }

        const rowEntity = col.addcol_type !== 'product' ? 'row.entity.uniq' : 'row.entity.prod_no';

        switch (col.input_type) {
          case 'str':
            results = `<input type="text" class="form-control" ng-model="row.entity[col.name]" ptgui-regex-mask="^.{0,50}$" ng-click="$event.stopPropagation();" ng-class="{cornsilk:!!grid.appScope.listColorObj[grid.appScope.list_name][${rowEntity} + col.name]}" ng-change="grid.appScope.listColorObj[grid.appScope.list_name][${rowEntity} + col.name] = true"><div></div>`;
            break;

          case 'int':
            results = `<input type="text" class="form-control" ng-model="row.entity[col.name]" ptgui-regex-mask="onlyNum_minus" ng-click="$event.stopPropagation();" ng-class="{cornsilk:!!grid.appScope.listColorObj[grid.appScope.list_name][${rowEntity} + col.name]}" ng-change="grid.appScope.listColorObj[grid.appScope.list_name][${rowEntity} + col.name] = true"><div></div>`;
            break;

          case 'date':
            results = `<input type="text" class="form-control" ng-model="row.entity[col.name]" ptgui-regex-mask="onlyNum_"  ng-click="$event.stopPropagation();" ng-class="{cornsilk:!!grid.appScope.listColorObj[grid.appScope.list_name][${rowEntity} + col.name]}" ng-change="grid.appScope.listColorObj[grid.appScope.list_name][${rowEntity} + col.name] = true" date-single-picker><div></div>`;
            break;
          case 'enum':
            results = `<select class="form-control-select full-width" ng-model="row.entity[col.name]" ng-click="$event.stopPropagation();" ng-class="{cornsilk:!!grid.appScope.listColorObj[grid.appScope.list_name][${rowEntity} + col.name]}" ng-change="grid.appScope.listColorObj[grid.appScope.list_name][${rowEntity} + col.name] = true">
                        ${options}
                       </select><div></div>`;
            break;
        }

        return results;
      },

      /**
       * 리스트 상품저장 버튼
       * params = {
       *  grid
       *  table_actions
       *  searchDo
       *  page
       * }
       */
      saveBtnSet: async function (data) {
        // 배송처 사용자는 해당 버튼 출력 x
        if ($rootScope.user_profile.auth_type === '배송처') {
          return false;
        }

        const addcol_type = data.page === 'product' ? ['product'] : ['order', 'payment', 'unstoring', 'delivery', 'integrated', 'claim'];
        if ($rootScope.addColumnList?.data?.results?.find((col) => addcol_type.includes(col.addcol_type))) {
          // 추가 컬럼이 있을때
          data.table_actions.push(
            {
              label: '<i class="fa fa-bolt">' + '<span class="pl-5">커스텀 컬럼 작업</span>' + '<span class="caret"></span>',
              btn_type: 'dropdown',
              test_id: 'drop-work',
              add_class: 'btn-default',
              ngIfFunc: data.ngIfFunc,
              item_list: [
                // 리스트 에서 정보저장
                {
                  label: gettextCatalog.getString('정보 저장'),
                  action: async () => {
                    const selectData = data.grid.methods.selectedData('all');

                    if (!selectData.length) {
                      commonSVC.showMessage(`선택된 ${data.page === 'product' ? '상품' : '주문'}이 없습니다.`);

                      return false;
                    }

                    const confirm = await commonSVC.showConfirm('선택한 항목을 저장하시겠습니까?');

                    if (confirm) {
                      try {
                        const addValueResult = await this.addColValue(selectData, data.page);

                        if (addValueResult && addValueResult.status === 200) {
                          if (addValueResult.data.result) {
                            commonSVC.showToaster('success', '정보 저장', '정상적으로 등록되었습니다.');
                            data.searchDo();

                            return;
                          }
                          commonSVC.showToaster('error', '정보 저장', '변경된 항목이 없습니다.');
                        }

                      } catch (err) {
                        commonSVC.showToaster('error', '정보 저장', '등록에 실패하였습니다.');
                      }
                    }
                  },
                  ngIfFunc: data.ngIfFunc
                }, {
                  // 정보 일괄 저장 모달 띄우기
                  label: gettextCatalog.getString('일괄 저장'),
                  action: async () => {
                    const selectData = data.grid.methods.selectedData('all');

                    if (!selectData.length) {
                      commonSVC.showMessage(`선택된 ${data.page === 'product' ? '상품' : '주문'}이 없습니다.`);

                      return false;
                    }

                    const resolve = {
                      data: {
                        ordList: selectData,
                        page: data.page
                      }
                    };

                    const modal = commonSVC.openModal('md', resolve, 'SaveCustomColumnCTRL', 'views/order/shipment/modals/column.html');

                    modal.result
                      .then((res) => {
                        if (res === 'success') {
                          commonSVC.showToaster('success', '성공', '일괄 저장에 성공하였습니다.');

                          data.searchDo();
                        }
                      });
                  },
                  ngIfFunc: data.ngIfFunc
                }
              ]
            }
          );
        } else {
          // 추가 컬럼이 없을때
          data.table_actions.push(
            {
              label: '<i class="fa fa-bolt">' + '<span class="pl-5">커스텀 컬럼 작업</span>' + '<span class="caret"></span>',
              btn_type: 'button',
              add_class: 'btn-default',
              ngIfFunc: data.ngIfFunc,
              action: () => {
                commonSVC.showMessage('추가된 커스텀 컬럼이 없습니다.');
              }
            }
          );
        }
      },

      // 추가 컬럼 숫자 변경
      addColIntSet: (input_type, results) => {
        const col_list = _.filter($rootScope.addColumnList.data.results, (col) => {
          return (col.addcol_type === input_type || input_type === 'all') && col.input_type === 'int';
        });

        if (col_list.length) {
          results.forEach((row) => {
            col_list.forEach((col) => {
              if (row[col.col_name]) {
                row[col.col_name] = parseFloat(row[col.col_name]);
              }
            });
          });
        }
      },

      // 컬럼 인풋타입 체크
      columTypeCheck: function (type) {
        switch (type) {
          case 'str':
            return 'string';

          case 'int':
            return 'string';

          case 'date':
            return 'string';
        }
      },

      /**
       * OPEN-API 사용업체 컬럼 노출
       * @param {object} gridOptions
       */
      addColumns_API: (gridOptions) => {
        gridOptions.notVisibleColumns = gridOptions.notVisibleColumns.concat(['api_read_time']);
        gridOptions.columns = gridOptions.columns.concat([{
          key: 'api_read_time',
          title: 'API 호출시간',
          width: 130,
          filter: 'dateValid'
        }]);
      },

      /**
       * LG 전용컬럼 추가
       * @param {object} gridOptions
       */
      addColumns_LG: (gridOptions) => {
        gridOptions.columns = gridOptions.columns.concat([
          {
            key: 'seller_discount',
            title: '판매자부담할인액',
            width: 100,
            notCompile: true,
            template: function(row) {
              return $filter('currency')(row.seller_discount, '', 0);
            }
          },
          {
            key: 'shop_discount',
            title: '쇼핑몰부담할인액',
            width: 100,
            notCompile: true,
            template: function(row) {
              return $filter('currency')(row.shop_discount, '', 0);
            }
          },
          {
            key: 'coupon_discount',
            title: '쿠폰 할인액',
            width: 100,
            notCompile: true,
            template: function(row) {
              return $filter('currency')(row.coupon_discount, '', 0);
            }
          },
          {
            key: 'point_discount',
            title: '포인트 할인액',
            width: 100,
            notCompile: true,
            template: function(row) {
              return $filter('currency')(row.point_discount, '', 0);
            }
          },
          {
            key: 'ship_hope_time',
            title: '희망배송일',
            width: 130,
            filter: 'dateValid'
          }
        ]);
      }
    };
  });
