'use strict';

angular.module('gmpApp')
  .controller('StockWarehouseListCtrl', function ($rootScope, $scope, warehouseSVC, warehouseModel, commonSVC, userInfo, settings,
    gettextCatalog, $compile, $timeout, systemModel, systemList) {

    //------------------------
    // 변수 설정
    //------------------------
    systemModel.load(function (state, data) { // 기본 배송처 depot_no 설정
      if (state === 'success') {
        $scope.default_warehouse = data.default_depot_no;
      }
    });

    $scope.searchForm = angular.copy(warehouseSVC.searchForm);
    const excelFieldList = angular.copy(warehouseSVC.excelFieldList);

    // 오쎄 계정은 배송처 다운로드 시 지급주기 필드 추가
    if ($rootScope.osse_sol) {
      excelFieldList.push({ header: '지급 주기', key: 'depot_sett_period', select: true });
    }
    const init_order = true;           // 데이터테이블 정렬 초기화
    const searchDetail = [
      {
        title: gettextCatalog.getString('사용여부'),
        sub_title: gettextCatalog.getString('사용여부 선택'),
        name: gettextCatalog.getString('모두'),
        value: $scope.searchForm.use_yn,
        search_name: 'use_yn',
        item_list: [
          { name: gettextCatalog.getString('사용중'), value: '1' },
          { name: gettextCatalog.getString('사용안함'), value: '0' }
        ],
        item_key: 'name',
        item_value: 'value',
        select_value: ''
      }
    ];

    /**
     * 서치바 관련 변수
     */

    // 검색영역 데이터
    $scope.searchData = angular.copy(warehouseSVC.searchData);

    $scope.searchFn = {};

    // 검색 실행
    $scope.searchFn.searchDo = function() {
      $scope.searchDo(true, true);
    };

    // 검색 초기화
    $scope.searchFn.resetDo = function() {
      $scope.resetDo();
    };

    // 데이터 건수 변경
    $scope.searchFn.changeLen = function() {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    // 검색영역 버튼 설정
    $scope.searchBtn = {
      table_actions: [
        {
          label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
          test_id: 'dropdown-work',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('배송처복사'),
              test_id: 'submit-copy',
              hotkey: 'c',
              action: function () {
                $scope.copyDo();
              },
              perm_only: ['stock.warehouse+write']
            }, {
              label: gettextCatalog.getString('배송처삭제'),
              test_id: 'submit-del',
              hotkey: 'del',
              action: function () {
                $scope.selectDelete();
              },
              perm_only: ['stock.warehouse+admin']
            }
          ]
        },
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('전체 엑셀 다운로드'),
              action: function () {
                $scope.excelDown('all');
              },
              perm_only: ['stock.warehouse']
            }, {
              label: gettextCatalog.getString('선택 엑셀 다운로드'),
              action: function () {
                $scope.excelDown('select');
              },
              perm_only: ['stock.warehouse']
            }
          ]
        }
      ],

      // 검색 영역 오른쪽 버튼
      actions_right: [
        {
          label: `<i class="icon-plus2"></i> ${gettextCatalog.getString('배송처 등록')}`,
          small_label: '배송처등록',
          test_id: 'submit-add',
          sub_label: '',
          btn_type: 'button',
          perm_only: ['stock.warehouse+write'],
          action: function() {
            $scope.open('StockWarehouseAddCtrl', 'add');
          }
        }
      ]
    };

    //------------------------
    // datatable 설정
    //------------------------
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'depot_no', 'ctry_cd', 'default_warehouse', 'change_use'],
      defaultSortingColumns: ['depot_no'],
      alignRightColumns: [],
      notSortingColumns: ['widget', 'ctry_cd', 'default_warehouse', 'change_use'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: ['charge_tel'],

      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/depot/list`,
        requestWillAction: function (data) {
          const mergedData = { ...data, ...$scope.searchForm };
          mergedData.orderby = mergedData.orderby || 'depot_no';
          $scope.pageData = angular.copy(mergedData); // 엑셀 모달용 데이터

          return mergedData;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: function(row) {
            //도구
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.open('StockWarehouseEditCtrl','edit',${row.depot_no})" ${row.fulfillment_depot_yn ? 'disabled' : ''}>${gettextCatalog.getString('상세')}</button>`;
          }
        },
        {
          key: 'depot_no',
          title: '배송처코드',
          width: 100
        },
        {
          key: 'depot_name',
          title: '배송처명',
          width: 150
        },
        {
          key: 'ctry_cd',
          title: '국가',
          width: 40,
          notCompile: true,
          template: function(row) {
            const country = row.ctry_cd
              ? row.ctry_cd.toLowerCase()
              : userInfo.user.sol_country.toLowerCase();

            return `<span class="flag-icon flag-icon-${country}"></span>`;
          }
        },
        {
          key: 'distance',
          title: '주소',
          width: 200,
          notCompile: true,
          template: function(row) {
            let addr = '';

            if (row.zipcd) {
              addr += `(${row.zipcd}) `;
            }
            if (row.addr1) {
              addr += `${row.addr1} `;
            }
            if (row.addr2) {
              addr += row.addr2;
            }

            return addr;
          }
        },
        {
          key: 'charge_name',
          title: '담당자',
          width: 100,
          notCompile: true,
          template: function(row) {
            // charge_name 이 없는경우 charge_name2 로 담당자 노출 2018-04-16 rony
            return (row.charge_name != null ? row.charge_name : row.charge_name2) || '없음';
          }
        },
        {
          key: 'charge_tel',
          title: '직통번호',
          width: 100,
          notCompile: true,
          template: function(row) {
            return row.charge_tel || '없음';
          }
        },
        {
          key: 'default_warehouse',
          title: '기본 배송처',
          width: 60,
          template: function(row) {
            const isDefault = row.depot_no === $scope.default_warehouse;

            return `<input style="width: auto" type="radio" name="default_warehouse" value="${row.depot_no}" ng-checked="${isDefault}" ng-click="grid.appScope.setDefaultWareHouse(${row.depot_no})" ${row.fulfillment_depot_yn ? 'disabled' : ''}>`;
          }
        },
        {
          key: 'change_use',
          title: '사용여부',
          width: 60,
          template: function(row) {
            if (row.depot_no === $scope.default_warehouse) {
              return '';
            } else {
              row.use_yn = !!row.use_yn;

              return '<pa-ui-switch ng-model="row.entity.use_yn" fn="grid.appScope.changeDo(row.entity)" disabled="row.entity.fulfillment_depot_yn"></pa-ui-switch>';
            }
          }
        },
      ]
    };

    // 오쎄는 지급주기 컬럼 추가
    if ($rootScope.osse_sol) {
      $scope.grid.options.columns.push({
        key: 'depot_sett_period',
        title: '지급주기',
        width: 30,
      });
    }

    /**
     * G마켓 물류 스마일배송전용 필드추가 - Daniel
     */
    if ($rootScope.userProfileCheck('sol_ser', 'smile', 'like')) {
      $scope.grid.options.columns.splice(7, 0,
        {
          key: 'ebaydepot_yn',
          title: 'G마켓 물류서비스 여부',
          width: 100,
          template: ({ ebaydepot_yn }) => ebaydepot_yn === 0 ? '자체배송' : 'G마켓 물류서비스'
        },
        { key: 'ebaydepot_id', title: 'ESM Master ID', width: 100 }
      );
    } else {
      // 이메이 물류 아닌경우 필드 초과 오류 발생되므로 관련 처리 추가.
      // $scope.grid.options.columnDefs[1].targets.splice(4, 2);
    }

    //------------------------
    // 함수 설정
    //------------------------

    /**
     * 모달열기
     */
    $scope.open = function (controller, type, number) {

      const data = {},
            warehouse_cnt = $scope.searchData.totalCount; //모달이 열릴 때 현재 배송처 갯수
      let resolve = {};

      data.userList = $scope.userList;
      if (type === 'edit') {
        data.number = number;
        resolve = {
          data: data
        };
      } else {
        data.depotListLength = warehouse_cnt; // 2018-09-06 Jacob 모달 열릴때 현재 배송처 개수를 전달
        resolve = {
          data: data
        };
      }

      const modal = commonSVC.openModal('lg', resolve, controller, `views/stock/warehouse/${type}.html`, false, true, false);

      modal.result.then(function (re) {
        if (re.result === 'success') {
          if (re.warehouse_code && (!warehouse_cnt)) {
            $scope.default_warehouse = re.warehouse_code; // 2018-09-06 Jacob 결과값에 배송처 코드가 있으면 기본배송처로 체크
          }
          $scope.searchDo(true, false);
        }
      });
    };

    // 검색영역 상세검색 설정
    $scope.searchDetail = angular.copy(searchDetail);

    /**
     * 조회
     */
    $scope.searchDo = function(refresh, noDelay) {
      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function() {
      $scope.searchForm = angular.copy(warehouseSVC.searchForm);
      if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
        $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      }
      $scope.searchDetail = angular.copy(searchDetail);
      $scope.searchData.search_key_name = '';
      $scope.searchDo(true, true);
    };

    /**
     * 배송처 일괄 삭제
     */
    $scope.selectDelete = function() {
      const warehouse = $scope.grid.methods.selectedData('all');

      // 선택한 row 가 없을 때
      if (warehouse.length === 0) {
        commonSVC.showMessage(gettextCatalog.getString('실패'), gettextCatalog.getString('삭제할 배송처를 선택해주세요.'));

        return;
      }
      // 기본 배성처거나 사용중인 창고인 경우
      let deleteAble = true;

      angular.forEach(warehouse, function(value) {
        if ($scope.default_warehouse === value.depot_no) {
          commonSVC.showMessage(gettextCatalog.getString('실패'), gettextCatalog.getString('기본 배송처는 삭제가 불가능합니다.'));
          deleteAble = false;

          return false;
        } else if (value.use_yn) {
          commonSVC.showMessage(gettextCatalog.getString('실패'), gettextCatalog.getString('현재 사용중인 배송처는 삭제가 불가능합니다.'));
          deleteAble = false;

          return false;
        }
      });
      // 삭제 진행
      if (deleteAble) {
        const numbers = $scope.grid.methods.selectedData('depot_no');

        const next = (confirm) => {
          if (typeof confirm === 'boolean' ? confirm : !confirm.isConfirmed) {
            warehouseModel.delete(numbers, function (state, data) {
              if (state === 'success') {
                commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('배송처 삭제 {{count}}건 성공', { count: numbers.length }));
                $scope.searchDo(false);
              } else {
                let err_msg = gettextCatalog.getString('배송처 삭제 실패');

                switch (data.data.error_reason) {
                  case 'FK_ol_shop_opt_prod_prod_depot': err_msg = gettextCatalog.getString('온라인상품과 연동된 배송처는 삭제가 불가능합니다');
                    break;
                  case 'FK_prod_depot_pal_depot_pal': err_msg = gettextCatalog.getString('SKU상품과 연동된 배송처는 삭제가 불가능합니다');
                    break;
                }
                commonSVC.showToaster('error', gettextCatalog.getString('실패'), err_msg);
              }
            });
          }
        };

        if (warehouse.some(ware => ware.fulfillment_depot_yn)) {
          commonSVC.showConfirmCustomSwal2({

            title: '',
            text: `
            <div style="text-align: left">
            <h3>배송처 삭제 안내</h3>
            배송처가 삭제되는 경우 해당 배송처가 매칭된 주문에서도 배송처가 삭제되며,<br />
            해당 주문은 일반 주문 메뉴에서 조회되어 물류배송 주문으로 구분되지 않습니다.<br /><br />
            배송처를 삭제하시겠습니까?
            </div>
              `,
            showCancelButton: true,
            confirmButtonText: '취소',
            cancelButtonText: '확인',
            confirmButtonColor: '#3085d6',
            allowEscapeKey: false,
            allowOutsideClick: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn-grey mr-20',
              cancelButton: 'btn-primary'
            }
          }, next);
        } else {
          commonSVC.showConfirm(gettextCatalog.getString('삭제하시겠습니까?'), '', next);
        }
      }
    };

    /**
     * 기본 배송처 변경
     * */
    $scope.setDefaultWareHouse = function(depot_no) {
      warehouseModel.setDefaultWareHouse(depot_no)
        .then(re => {
          if (re.data.results === 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('기본배송처가 변경되었습니다.'));
            $scope.default_warehouse = depot_no;
            $scope.searchDo(false);
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('기본배송처 변경에 실패하였습니다.'));
        });
    };

    /**
     * 배송처 복사
     */
    $scope.copyDo = function() {
      const warehouse = $scope.grid.methods.selectedData('all');
      // const numbers = $scope.grid.dtInstance.selectedData('depot_no');

      // 선택한 row 가 없을 때
      if (warehouse.length === 0) {
        commonSVC.showMessage(gettextCatalog.getString('실패'), gettextCatalog.getString('복사할 배송처를 선택해주세요.'));

        return;
      } else {
        if (warehouse.some(o => o.ebaydepot_yn)) {
          commonSVC.showMessage('실패', '복사하려는 배송처중 G마켓물류 배송처가 포함되어있습니다.\nG마켓물류 배송처는 복사가 불가능합니다.');

          return;
        }

        if (warehouse.some(o => o.fulfillment_depot_yn)) {
          commonSVC.showMessage('실패', '복사하려는 배송처중 물류 주문 배송처가 포함되어있습니다.\n물류 주문 배송처는 복사가 불가능합니다.');

          return;
        }
      }

      warehouseModel.copy(warehouse, function (state, data) {
        if (data.results === 'success') {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('배송처 복사 {{count}}건 성공', { count: warehouse.length }));
          $scope.searchDo(true);
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('배송처 복사 실패'));
        }
      });
    };

    /**
     * 배송처 사용여부 수정.
     */
    $scope.changeDo = async function({ depot_no, use_yn }) {
      try {
        await warehouseModel.editOne(depot_no, use_yn);
        commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('사용여부 수정에 성공하였습니다.'));

      } catch (err) {
        commonSVC.showMessage(gettextCatalog.getString(err.data.message));

        return false;
      }
    };

    /**
     * 엑셀다운
     */
    $scope.excelDown = function (type) {
      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList,
        title: '배송처',
        url: '/app/stock/depot/excel-down',
        searchForm: $scope.pageData,
        page: 'stock_warehouse_list'
      };

      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const selectList = $scope.grid.methods.selectedData('depot_no');

        if (selectList.length == 0) {
          commonSVC.showMessage('선택된 내역이 없습니다.');

          return false;
        } else {
          resolve.data.select_list = selectList;
          resolve.data.count = selectList.length;
          resolve.data.isAll = false;
        }
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };
  });
