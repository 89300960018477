'use strict';

angular.module('gmpApp')
  .controller('SettingsSupplierEditCtrl', function ($uibModalInstance, $scope, supplierSVC, supplierModel, commonSVC, data, errorSVC) {

    $scope.number = data.number;

    $scope.supplier = {};
    $scope.tab_active = 0;              // 탭 활성화 설정
    $scope.validTab = [];

    init();

    /**
     * 초기 셋팅
     */
    function init() {
      //$scope.bank_list = supplierSVC.bank_list;                         // 은행 리스트
      $scope.business_type_list = supplierSVC.business_type_list;       // 사업자 리스트
      $scope.settlement_cycle_list = supplierSVC.settlement_cycle_list; // 정산주기 리스트

      supplierModel.load($scope.number, function (state, result) {
        if (state === 'success') {
          $scope.supplier = result;
          $scope.oldSupplier = angular.copy($scope.supplier);
          $scope.number = $scope.supplier.number;
        } else {
          commonSVC.showMessage('데이터 로드 실패');
          $scope.cancel();
        }
      });
    }

    /**
     * 매입처 수정
     */
    $scope.onSubmit = async function() {
      if ($scope.form1.$valid) {
        const param = {
          supplier: $scope.supplier,
          oldSupplier: $scope.oldSupplier
        };

        // 체크박스가 바로 반영이 안되어 처리.
        param.supplier.use_yn = $('#useYN').prop('checked') ? 1 : 0;

        try {
          await supplierModel.update(param);

          commonSVC.showToaster('success', '', '매입처 수정에 성공하였습니다.');
          $uibModalInstance.close('success');
        } catch (err) {
          commonSVC.showToaster('error', '실패', err.data.message || '매입처 수정에 실패하였습니다.');

          return false;
        }
      }
    };

    /**
     * 모달창 닫기
     */
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

  });
