import React, { useState, useEffect, CSSProperties, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ImageHostingHeader from '../../components/imageHosting/Header';
import TreeView from '../../components/imageHosting/TreeView';
import { showMessage, showToaster } from '../../utills/common';
import * as imageHostModel from '../../models/imageHosting';
import FileListComponent from '../../components/imageHosting/FileList';
import LogListComponent from '../../components/imageHosting/LogList';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

interface ImageHostingProps {
  returnType: 'url' | 'html';
  handleClose: (result?: any) => void;
  userInfo: any;
}

interface FileItem {
  id: string;
  name: string;
  size: string | number;
  type: '' | 'file' | 'directory';
  mdate: string;
}

const Home: React.FC<ImageHostingProps> = ({ returnType = 'url', handleClose, userInfo }) => {
  const [currentFtpInfo, setCurrentFtpInfo] = useState<{ ftp_no: number, ftp_name: string, remotePath: string, host: string } | null>(null);  // 현재 접속중인 FTP 정보
  const [fileData, setFileData] = useState<FileItem[]>([]);           // 조회된 파일 데이터 (실제 데이터 조회)
  const [searchedData, setSearchedData] = useState<FileItem[]>([]);   // 검색된 파일 데이터 (검색된 값)
  const [folderList, setFolderList] = useState<any>({});              // 전체 폴더 리스트
  const [folderPath, setFolderPath] = useState<string[]>([]);         // 선택 폴더 경로 (선택 폴더가 없는경우 빈배열)
  const currentFtpPath = useRef<string>('/');                         // 현재 FTP 경로
  const [selectedFiles, setSelectedFiles] = useState<FileItem[]>([]); // 선택된 파일 목록
  const [viewPage, setViewPage] = useState<'file' | 'log'>('file');    // 보여지는 페이지 (file: 파일목록, log: 로그목록)
  const [logList, setLogList] = useState([]);                         // 로그 리스트
  // 로그 검색 파라미터
  const [searchLogParams, setSearchLogParams] = useState({
    type: [],         // 작업유형
    state: '',        // 작업결과
    sdate: '',        // 검색 시작일
    edate: '',        // 검색 종료일
    search_word: '',  // 검색어
    start: 0,         // 페이지 시작
    limit: 5         // 검색 갯수
  });
  const [totalPages, setTotalPages] = useState(1);   // 로그 조회된 총 카운트
  const [pageSize, setPageSize] = useState(50);      // 로그 조회 갯수

  useEffect(() => {
    // clarity 에 페이지 접속 로깅
    window.clarity('identify', userInfo.email, '', 'imageHosting');

    checkFtpConnection();
  }, []);

  /**
   * 현재 경로 (폴더경로) 변경시 해당 경로의 파일목록 조회
   */
  useEffect(() => {
    if (currentFtpInfo) {
      const basePath = currentFtpInfo.remotePath.endsWith('/')
        ? currentFtpInfo.remotePath
        : `${currentFtpInfo.remotePath}/`;
      const folderPathString = folderPath.length
        ? `${folderPath.join('/')}/`
        : '';

      currentFtpPath.current = `${basePath}${folderPathString}`;

      getFtpFiles(currentFtpInfo.ftp_no, currentFtpPath.current);
      setSelectedFiles([]);
    }
  }, [folderPath]);

  /**
   * 파일 목록 조회시 검색된 데이터 초기화
   */
  useEffect(() => {
    setSearchedData(fileData);
  }, [fileData]);

  useEffect(() => {
    setSearchLogParams((prevParams) => ({ ...prevParams, limit: pageSize }));
  }, [pageSize]);

  /**
   * 로그 검색 파라미터 변경시 조회
   */
  useEffect(() => {
    getFtpLogs();
  }, [searchLogParams]);

  // 연결된 FTP 정보가 있는지 확인.
  const checkFtpConnection = async () => {
    const res = await imageHostModel.checkFtpConnection();

    if (res.results) {
      init(res.results);
    }
  };

  const onClickDropdownRemove = () => {
    // setDropdown1(false);
    // setDropdown2(false);
    // setDropdown3(false);
    // setDropdown4(false);
    // setDropdown5(false);
  };

  /**
   * FTP Manager 열기
   */
  const openFtpManager = (reuslt: { ftp_no: number, ftp_name: string, remotePath: string, ftp_addr: string }) => {
    if (reuslt?.ftp_no) {
      init(reuslt);
    }
  };

  /**
   * 특정 경로의 파일 목록 조회
   */
  const getFtpFiles = async (ftp_no: number, remotePath: string) => {
    try {
      const ftpList = await imageHostModel.getFtpFiles(ftp_no, remotePath);

      if (ftpList.error_code) {
        throw new Error(ftpList.message);
      } else {
        setFileData(ftpList.results);
      }
    } catch (err) {
      showToaster('error', '', '디렉토리 목록 조회에 실패했습니다.');
    }
  };

  /**
   * 로그 조회
   */
  const getFtpLogs = async () => {
    try {
      if (currentFtpInfo) {
        const logs = await imageHostModel.getFtpLogs({
          ...searchLogParams,
          ftp_no: currentFtpInfo.ftp_no,
        });

        setLogList(logs.results);
        setTotalPages(Math.ceil(logs.total / pageSize));
      }
    } catch (error) {
      showToaster('error', '', '로그 조회에 실패했습니다.');
      setLogList([]);
    }
  };

  /**
   * 페이지 정보 초기화
   */
  const init = async (ftpInfo, currentPath = '') => {
    if (!currentPath) {
      setCurrentFtpInfo({
        ftp_no: ftpInfo.ftp_no,
        ftp_name: ftpInfo.ftp_name,
        remotePath: ftpInfo.remotePath,
        host: ftpInfo.host
      });
      setSearchLogParams((prevParams) => ({ ...prevParams, ftp_no: ftpInfo.ftp_no }));
    }

    // 전체 폴더 리스트 조회
    try {
      const allFolderList = await imageHostModel.getAllFtpFolders(ftpInfo.ftp_no, ftpInfo.remotePath);

      if (allFolderList.error) {
        throw new Error(allFolderList.error);
      }

      setFolderList(allFolderList);

      getFtpFiles(ftpInfo.ftp_no, currentPath || ftpInfo.remotePath);
    } catch (err: any) {
      showToaster('error', '디렉토리 조회 실패!', err.message || '디렉토리 목록 조회에 실패했습니다.');
    }
  };

  /**
   * 이미지호스팅 주소 가져오기
   */
  const getImagehostUrls = (type: '' | 'url' | 'html', imagehostingFiles: FileItem[]): string[] => {
    const returnHostingType = type || returnType;

    return imagehostingFiles.map(file => {
      const hostUrl = `https://${currentFtpInfo.host}${currentFtpPath.current}${file.name}`;

      if (returnHostingType === 'url') {
        return hostUrl;
      } else {
        return `<img src="${hostUrl}" alt="${file.name}" />`;
      }
    });
  };

  /**
   * 선택완료시 선택된 파일의 호스팅 주소 리턴
   */
  const handleSelectComplete = () => {
    if (!selectedFiles.length) {
      showMessage('선택된 파일이 없습니다.');

      return;
    }

    // 이미지 파일 유효성 검사함.
    const noImageFiles = selectedFiles.some((file) => file.type !== 'file' || !file.name.match(/\.(jpg|jpeg|png|gif)$/i));

    if (noImageFiles) {
      showMessage('에러', '이미지 파일만 선택 가능합니다.');

      return;
    }

    handleClose(getImagehostUrls(returnType, selectedFiles));
  };

  /**
   * 창 닫기
   */
  const handleCancel = () => {
    handleClose(null);
  };

  return (
    <>
      <ImageHostingHeader
        isConnected={!!currentFtpInfo}
        folderPath={folderPath}
        setFolderPath={setFolderPath}
        fileData={fileData}
        setSearchedData={setSearchedData}
        viewPage={viewPage}
        setSearchLogParams={setSearchLogParams}
      />
      <div className="modal-body no-padding" style={{ height: '70vh' }} onClick={onClickDropdownRemove}>
        <div style={styles.container}>
          {/* Left Tree View */}
          <ResizableBox
            width={212}
            // height={Infinity}
            axis="x"
            minConstraints={[212, Infinity]}
            maxConstraints={[600, Infinity]}
            resizeHandles={['e']}
            style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #ddd', }}
          >
            <TreeView
              openFtpManager={openFtpManager}
              currentFtpInfo={currentFtpInfo}
              setCurrentFtpInfo={setCurrentFtpInfo}
              folderList={folderList}
              folderPath={folderPath}
              setFolderPath={setFolderPath}
              viewPage={viewPage}
              setViewPage={setViewPage}
              setFolderList={setFolderList}
              setFileData={setFileData}
            />
          </ResizableBox>

          {/* Right Content */}
          <div style={styles.contents}>
            {/* 연결없음 일 때 UI */}
            {!currentFtpInfo && (
              <div style={styles.isDisconnected}>
                <ul style={styles.disconnectedBox1}>
                  <li style={styles.disconnectedBox1Text}>
                  왼쪽 하단 [FTP 접속하기] 버튼을 클릭하여, 이용 중인 호스팅 정보를 입력 후 FTP 서버에 접속해 주세요.
                  </li>
                  <li style={styles.disconnectedBox1Text}>
                  본 서비스는, 이용 중이신 타사 FTP 서비스를 연동하는 외부 이미지 호스팅 서비스입니다.<br />
                  따라서 이미지 트래픽, 디스크 사용량 등 이미지 호스팅 비용은 이용 중이신 해당 업체에 직접 지불하셔야 합니다.
                  </li>
                  <li style={styles.disconnectedBox1Text}>
                  이용 중이신 이미지 호스팅 서비스에서 FTP 접속을 지원하지 않는 경우 FTP 연결이 제한될 수 있습니다.
                  </li>
                </ul>
                <div style={styles.disconnectedBox2}>
                  <p style={styles.disconnectedBox2Text}>연결된 호스팅이 없습니다.</p>
                </div>
              </div>
            )}

            {/* 연결 일 때 UI */}
            {currentFtpInfo && viewPage === 'file' && (
              <FileListComponent
                fileData={fileData}
                searchedData={searchedData}
                folderPath={folderPath}
                init={init}
                currentFtpInfo={currentFtpInfo}
                setFolderPath={setFolderPath}
                folderList={folderList}
                currentFtpPath={currentFtpPath.current}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                getImagehostUrls={getImagehostUrls}
                handleClose={handleClose}
              />
            )}

            {/* 로그 관리 일 때 UI */}
            {currentFtpInfo && viewPage === 'log' && (
              <LogListComponent
                logList={logList}
                getFtpLogs={getFtpLogs}
                searchLogParams={searchLogParams}
                setSearchLogParams={setSearchLogParams}
                totalPages={totalPages}
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
            )}

          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button className="btn btn-default" onClick={handleCancel} >
          취소
        </button>
        <button
          className="btn btn-primary"
          disabled={!selectedFiles.length}
          onClick={handleSelectComplete}
        >
          선택 완료
        </button>
      </div>
    </>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    height: '100%',
    minHeight: '640px',
  },
  contents: {
    flex: '1',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  isDisconnected: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  disconnectedBox1: {
    padding: '20px 20px 20px 40px',
    border: '1px solid #1E88E5',
    background: '#E8F0F6',
  },
  disconnectedBox1Text: {
    fontSize: '12px',
    color: '#545454',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    lineHeight: '20px',
  },
  disconnectedBox2: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disconnectedBox2Text: {
    paddingTop: '60px',
    fontSize: '14px',
    color: '#999',
    fontWeight: '500',
    letterSpacing: '-0.42px',
    background: 'url(../../assets/images/imagehosting/ico_is_empty.svg) no-repeat center top',
  },
  isConnected: {
    display: 'flex',
    flexDirection: 'column',
  },
  btnGroupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  btnType1: {
    margin: '0 2px',
    padding: '0 10px',
    height: '30px',
    lineHeight: '28px',
    fontSize: '12px',
    color: '#1E88E5',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    border: '1px solid #1E88E5',
    background: '#fff',
  },
  btnType2: {
    margin: '0 2px',
    padding: '0 10px',
    height: '30px',
    lineHeight: '28px',
    fontSize: '12px',
    color: '#545454',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    border: '1px solid #ddd',
    background: '#fff',
  },
  refreshIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_refresh.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  btnArea: {
    display: 'flex',
    alignItems: 'center',
  },
  statusArea: {
    display: 'flex',
  },
  volumeBoxContainver: {
    marginRight: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  volumeBox: {
    marginRight: '10px',
    position: 'relative',
    width: '180px',
    height: '12px',
    borderRadius: '6px',
    background: '#E8F0F6',
    overflow: 'hidden',
  },
  volumeBar: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    width: '10%',
    borderRadius: '6px',
    background: '#1E88E5',
  },
  volumeLabel: {
    fontSize: '12px',
    color: '#aaa',
    fontWeight: '500',
    letterSpacing: '-0.36px',
  },
  volumeStatus: {
    color: '#1E88E5',
  },
  dropDown: {
    display: 'block',
    padding: '0',
    margin: '0',
    position: 'absolute',
    top: '32px',
    border: '1px solid #ddd',
    borderRadius: '3px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    listStyle: 'none',
  },
  workTypeIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_work_type.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  workResultIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_work_result.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  workDateIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_work_date.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  fileExcelIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_file_excel.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  dateBox: {
    margin: '10px 0 0 20px',
  },
  dateBtnGroup: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dateResetBtn: {
    padding: '8px 10px',
    fontSize: '12px',
    color: '#545454',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    border: '1px solid #ddd',
    background: '#fff',
  },
  dateConfirmBtn: {
    marginLeft: '4px',
    padding: '8px 10px',
    fontSize: '12px',
    color: '#1E88E5',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    border: '1px solid #1E88E5',
    background: '#fff',
  },
  logCntBox: {
    marginRight: '10px',
    fontSize: '12px',
    color: '#545454',
    fontWeight: '600',
  },
  logCnt: {
    color: '#1E88E5',
  },
  headerCheckbox: {
    marginLeft: '4px',
  },
  rowCheckbox: {
    marginLeft: '4px',
  },
  backBtn: {
    paddingLeft: '58px',
    width: '100%',
    textAlign: 'left',
  },
  fileTypeIcon: {
    marginRight: '5px',
  }
};

export default Home;