import React, { useState, CSSProperties, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { showConfirm, showMessage, showToaster } from '../../utills/common';
import * as imageHostModel from '../../models/imageHosting';
import Datatable from '../common/Datatable';

interface LogListComponentProps {
  logList: any[];               // 조회된 로그 리스트
  getFtpLogs: () => void;       // 로그 조회 함수
  searchLogParams: any;         // 로그 조회 파라미터
  setSearchLogParams: React.Dispatch<React.SetStateAction<any>>;  // 로그 조회 파라미터 변경
  totalPages: number;           // 전체 페이지 수
  pageSize: number;            // 한 페이지에 보여줄 로그 수
  setPageSize: React.Dispatch<React.SetStateAction<number>>; // 한 페이지에 보여줄 로그 수 변경
}

const LogListComponent: React.FC<LogListComponentProps> = ({ logList, getFtpLogs, searchLogParams, setSearchLogParams, totalPages, pageSize, setPageSize }) => {
  const [dropdown3, setDropdown3] = useState(false);
  const [dropdown4, setDropdown4] = useState(false);
  const [dropdown5, setDropdown5] = useState(false);
  const [dateBox, setDateBox] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [checkedTypes, setCheckedTypes] = useState<string[]>([]);     // 작업유형 체크박스 선택값
  const matchLogTypes = {
    upload: '업로드',
    download: '다운로드',
    addfolder: '폴더추가',
    rename: '이름변경',
    delete: '삭제',
  };
  const [logState, setLogState] = useState(''); // 작업결과 선택값
  const logStateOptions = [
    { label: '작업결과 전체', value: '' },
    { label: '성공', value: 'success' },
    { label: '실패', value: 'failed' },
  ];

  const [selectedList, setSelectedList] = useState([]); // 선택로그

  useEffect(() => {
    getFtpLogs();
  }, []);

  /**
   * 작업유형 변경시 검색
   */
  useEffect(() => {
    setSearchLogParams((prevParams) =>
      ({ ...prevParams, type: checkedTypes })
    );
  }, [checkedTypes]);

  /**
   * 작업결과 변경시 검색
   */
  useEffect(() => {
    setSearchLogParams((prevParams) =>
      ({ ...prevParams, state: logState })
    );
  }, [logState]);

  const onClickDropdown3 = (e) => {
    setDropdown3(!dropdown3);
    setDropdown4(false);
    setDropdown5(false);
    e.stopPropagation();
  };

  const onClickDropdown4 = (e) => {
    setDropdown3(false);
    setDropdown4(!dropdown4);
    setDropdown5(false);
    e.stopPropagation();
  };

  const onClickDropdown5 = (e) => {
    setDropdown3(false);
    setDropdown4(false);
    setDropdown5(!dropdown5);
    e.stopPropagation();
  };

  /**
   * 새로고침
   */
  const refreshLogs = () => {
    setCheckedTypes([]);
    setSearchLogParams((prevParams) =>
      ({ ...prevParams, type: '', state: '', edate: '', wdate: '', search_word: '', start: 0 })
    );
  };

  /**
   * 작업유형 체크박스 선택
   */
  const handleCheckboxChange = (type: string) => {
    setCheckedTypes((prevCheckedTypes) =>
      prevCheckedTypes.includes(type)
        ? prevCheckedTypes.filter((t) => t !== type)
        : [...prevCheckedTypes, type]
    );
  };

  /**
   * 작업일 선택
   */
  const handleCheckDate = (e) => {
    const value = e.target.value;

    if (value === 'term') {
      setDateBox(true);
    } else {
      setDateBox(false);
      setStartDate(null);
      setEndDate(null);

      // 전체 기간 검색
      setSearchLogParams((prevParams) =>
        ({ ...prevParams, sdate: '', edate: '' })
      );
    }
  };

  /**
   * 검색 시작일 선택
   */
  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  /**
   * 검색 종료일 선택
   */
  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  /**
   * 기간별 검색
   */
  const handleSearchDate = () => {
    if (startDate && endDate) {
      const sdate = moment(startDate).format('YYYY-MM-DD');
      const edate = moment(endDate).format('YYYY-MM-DD');

      setSearchLogParams((prevParams) =>
        ({ ...prevParams, sdate, edate })
      );

      setDropdown5(false);
    } else {
      showMessage('시작일과 종료일을 선택해주세요.');
    }
  };

  /**
   * 엑셀 다운로드
   */
  const excelDown = async (type = 'all') => {
    if (type === 'all') {
      if (!logList.length) {
        showMessage('조회된 로그가 없습니다.');

        return;
      }
    } else {
      if (!selectedList.length) {
        showMessage('선택된 로그가 없습니다.');

        return;
      }
    }

    const isConfirmed = await showConfirm('엑셀 다운로드', `${type === 'all' ? '조회된 전체' : '선택된'} 로그를 다운로드 하시겠습니까?`);

    if (isConfirmed) {
      const res = await imageHostModel.downloadExcel(
        type === 'all' ? searchLogParams : { ...searchLogParams, ftp_log_nos: selectedList.map((log) => log.ftp_log_no) }
      );

      if (res?.results) {
        showToaster('success', '성공', '엑셀 다운로드에 성공하였습니다.');
        window.open(res.results, '');
      } else {
        showToaster('error', '실패', '엑셀 다운로드에 실패하였습니다.');
      }
    }
  };

  /**
   * size 가 하나라도 누락되는경우 컬럼 사이즈 적용 안됨
   * 임이의 사이즈는 auto 로 설정
   */
  const columns = [
    {
      id: 'select',
      header: ({ table }) => (
        <input
          id="header-checkbox"
          type="checkbox"
          checked={table.getIsAllPageRowsSelected()} // 전체 row가 선택되었는지 확인
          onChange={table.getToggleAllPageRowsSelectedHandler()} // 전체 row를 선택/해제하는 handler
        />
      ),
      cell: ({ row }) => (
        <input
          id={`cell-checkbox-${row.id}`}
          type="checkbox"
          checked={row.getIsSelected()} // row가 선택되었는지 확인
          disabled={!row.getCanSelect()} // row가 선택 가능한지 확인
          onChange={row.getToggleSelectedHandler()} // row를 선택/해제하는 handler
        />
      ),
      size: 50
    },
    { header: 'NO', accessorKey: 'ftp_log_no', enableResizing: false, size: 70, cell: (props) => {
      return props.getValue();
    } },
    { header: '작업 일시', accessorKey: 'wdate', enableResizing: false, size: 150, cell: (props) => props.getValue() },
    { header: '작업 대상', accessorKey: 'content', enableResizing: true, size: 'auto', cell: (props) => props.getValue() },
    { header: '작업 유형', accessorKey: 'type', enableResizing: false, size: 100, cell: (props) => {
      return matchLogTypes[props.getValue()];
    } },
    { header: '작업 결과', accessorKey: 'state', enableResizing: false, size: 100, cell: (props) => {
      return logStateOptions.find((option) => option.value === props.getValue())?.label;
    } },
    { header: '작업 계정', accessorKey: 'wm_name', enableResizing: false, size: 100, cell: (props) => props.getValue() },
    { header: 'IP', accessorKey: 'ip', enableResizing: false, size: 100, cell: (props) => props.getValue() },
  ];

  return (
    <>
      {/* 상단 버튼 영역 */}
      <div style={styles.isConnected}>
        <div style={styles.btnGroupContainer}>
          {/* left */}
          <div style={styles.btnArea}>
            <div className="position-relative">
              <button
                type="button"
                className="btn"
                style={{ ...styles.btnType1, ...(checkedTypes.length ? { background: '#E8F0F6' } : '') }}
                onClick={onClickDropdown3}
              >
                <i style={styles.workTypeIcon} />
                작업유형
              </button>
              {dropdown3 &&
                <ul className="dropdown-menu" style={{ ...styles.dropDown, left: '2px', right: 'unset' }}>
                  <div className="dropdown-group">
                    {Object.keys(matchLogTypes).map((type) => (
                      <li key={type}>
                        <a onClick={(e) => e.stopPropagation()}>
                          <label className="checkbox-inline" style={{ width: '100%', fontSize: '13px' }}>
                            <input
                              type="checkbox"
                              id={type}
                              checked={checkedTypes.includes(type)}
                              onChange={() => handleCheckboxChange(type)}
                            />
                            {matchLogTypes[type]}
                          </label>
                        </a>
                      </li>
                    ))}
                  </div>
                </ul>
              }
            </div>
            <div className="position-relative">
              <button
                type="button"
                className="btn"
                style={{ ...styles.btnType1, ...(logState ? { background: '#E8F0F6' } : '') }}
                onClick={onClickDropdown4}
              >
                <i style={styles.workResultIcon} />
                작업결과
              </button>
              {dropdown4 &&
                <ul className="dropdown-menu" style={{ ...styles.dropDown, left: '2px', right: 'unset' }}>
                  <div className="dropdown-group">
                    {logStateOptions.map((option) => (
                      <li key={option.value}>
                        <a onClick={(e) => e.stopPropagation()}>
                          <label className="radio-inline" style={{ width: '100%', fontSize: '13px' }}>
                            <input
                              type="radio"
                              name="radio1"
                              checked={logState === option.value}
                              onChange={() => setLogState(option.value)}
                            />
                            {option.label}
                          </label>
                        </a>
                      </li>
                    ))}
                  </div>
                </ul>
              }
            </div>
            <div className="position-relative">
              <button
                type="button"
                className="btn"
                style={{ ...styles.btnType1, ...(startDate && endDate ? { background: '#E8F0F6' } : '') }}
                onClick={onClickDropdown5}
              >
                <i style={styles.workDateIcon} />
                작업일
              </button>
              {dropdown5 &&
                <ul className="dropdown-menu" style={{ ...styles.dropDown, left: '2px', right: 'unset', width: '230px' }}>
                  <div className="dropdown-group">
                    <li>
                      <a onClick={(e) => e.stopPropagation()}>
                        <label className="radio-inline" style={{ width: '100%', fontSize: '13px' }}>
                          <input
                            type="radio"
                            name="radio2"
                            value='all'
                            checked={!(startDate && endDate)}
                            onChange={handleCheckDate}
                          />
                          전체 기간
                        </label>
                      </a>
                    </li>
                    <li>
                      <a onClick={(e) => e.stopPropagation()}>
                        <label className="radio-inline" style={{ width: '100%', fontSize: '13px' }}>
                          <input
                            type="radio"
                            name="radio2"
                            value='term'
                            checked={!!(startDate && endDate)}
                            onChange={handleCheckDate}
                          />
                          직접 입력
                        </label>
                        {dateBox &&
                        <div style={styles.dateBox}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="custom-react-datepicker-box">
                              <DatePicker
                                className="form-control custom-react-datepicker"
                                dateFormat="yyyy-MM-dd"
                                shouldCloseOnSelect
                                selected={startDate}
                                onChange={handleStartDateChange}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                            </div>
                            ~
                            <div className="custom-react-datepicker-box">
                              <DatePicker
                                className="form-control custom-react-datepicker"
                                dateFormat='yyyy-MM-dd'
                                shouldCloseOnSelect
                                selected={endDate}
                                onChange={handleEndDateChange}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                            </div>
                          </div>
                          <div style={styles.dateBtnGroup}>
                            <button type="button" className="btn" style={styles.dateResetBtn} onClick={() => setDropdown5(false)}>취소</button>
                            <button type="button" className="btn" style={styles.dateConfirmBtn} onClick={handleSearchDate}>적용</button>
                          </div>
                        </div>
                        }
                      </a>
                    </li>
                  </div>
                </ul>
              }
            </div>
            <button
              type="button"
              className="btn"
              style={styles.btnType2}
              onClick={() => excelDown('all')}
            >
              <i style={styles.fileExcelIcon} />
              전체 엑셀 다운로드
            </button>
            <button
              type="button"
              className="btn"
              style={styles.btnType2}
              onClick={() => excelDown('selected')}
            >
              <i style={styles.fileExcelIcon} />
              선택 엑셀 다운로드
            </button>
            <button
              type="button"
              className="btn"
              style={styles.btnType1}
              onClick={refreshLogs}
            >
              <i style={styles.refreshIcon} />
              새로고침
            </button>
          </div>
        </div>
      </div>

      {/* 로그 리스트 */}
      <Datatable
        dataList={logList}
        columns={columns}
        setSelectedList={setSelectedList}
        totalPages={totalPages}
        setSearchLogParams={setSearchLogParams}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );

};

const styles: { [key: string]: CSSProperties } = {
  isConnected: {
    display: 'flex',
    flexDirection: 'column',
  },
  btnGroupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  btnType1: {
    margin: '0 2px',
    padding: '0 10px',
    height: '30px',
    lineHeight: '28px',
    fontSize: '12px',
    color: '#1E88E5',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    border: '1px solid #1E88E5',
    background: '#fff',
  },
  btnType2: {
    margin: '0 2px',
    padding: '0 10px',
    height: '30px',
    lineHeight: '28px',
    fontSize: '12px',
    color: '#545454',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    border: '1px solid #ddd',
    background: '#fff',
  },
  refreshIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_refresh.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  btnArea: {
    display: 'flex',
    alignItems: 'center',
  },
  statusArea: {
    display: 'flex',
  },
  dropDown: {
    display: 'block',
    padding: '0',
    margin: '0',
    position: 'absolute',
    top: '32px',
    border: '1px solid #ddd',
    borderRadius: '3px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    listStyle: 'none',
  },
  workTypeIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_work_type.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  workResultIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_work_result.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  workDateIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_work_date.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  fileExcelIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_file_excel.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  dateBox: {
    margin: '10px 0 0 20px',
  },
  dateBtnGroup: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dateResetBtn: {
    padding: '8px 10px',
    fontSize: '12px',
    color: '#545454',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    border: '1px solid #ddd',
    background: '#fff',
  },
  dateConfirmBtn: {
    marginLeft: '4px',
    padding: '8px 10px',
    fontSize: '12px',
    color: '#1E88E5',
    fontWeight: '500',
    letterSpacing: '-0.36px',
    border: '1px solid #1E88E5',
    background: '#fff',
  },
  logCntBox: {
    marginRight: '10px',
    fontSize: '12px',
    color: '#545454',
    fontWeight: '600',
  },
  logCnt: {
    color: '#1E88E5',
  },
  headerCheckbox: {
    marginLeft: '4px',
  },
  rowCheckbox: {
    marginLeft: '4px',
  },
  backBtn: {
    paddingLeft: '58px',
    width: '100%',
    textAlign: 'left',
  },
  fileTypeIcon: {
    marginRight: '5px',
  },
  dataTableArea: {
    flex: 1,
    marginTop: '10px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '6px',
  },
  th: {
    padding: '10px',
    textAlign: 'center',
    borderBottom: '2px solid #ccc',
    backgroundColor: '#f8f8f8'
  },
  td: {
    padding: '10px',
    textAlign: 'center',
    borderBottom: '1px solid #ddd',
  },
  isEmptyLogBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  isEmptyLogText: {
    paddingTop: '60px',
    fontSize: '14px',
    color: '#999',
    fontWeight: '500',
    letterSpacing: '-0.42px',
    background: 'url(../../assets/images/imagehosting/ico_is_empty_log.svg) no-repeat center top',
  },
};

export default LogListComponent;