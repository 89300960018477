'use strict';

angular.module('gmpApp')
  .controller('ExcelEditIntegratedCtrl', function ($scope, data, $uibModalInstance, $timeout, commonSVC, deliveryModel, shipmentModel, shipmentSVC, $rootScope, columnModel, columnSVC, warehouseModel, warehouseList) {

    $scope.columnList = [];
    $scope.template = {};
    $scope.selectedList = [];                        // 선택 항목 리스트
    $scope.selectedColumns = [];
    $scope.deselectedColumns = [];
    $scope.selectedDepotList = [];
    $scope.renameColumn = '';
    $scope.pageType = data.pageType;
    $scope.isGlobal = data.isGlobal;
    $scope.consignmentType = data.consignmentType;
    $scope.warehouseList = warehouseList.data.result;
    $scope.view = 'views/order/shipment/modals/excel_template_modal_body.html';
    $scope.page = data.page;
    $scope.used_depot_list = data.used_depot_list?.map(Number);
    $scope.cell = {
      header: '',
      variable: '',
      key_settings: 0,
      key: '',
      separator: '',
      width: 20
    };
    $scope.edit = false;
    $scope.isNew = false;
    $scope.colIndex = null;
    $scope.sol_stock = $rootScope.user_profile.sol_stock;
    $scope.columnListSearch = '';
    $scope.isCarr = false; // 택배사 정보출력 여부-
    $scope.nameCheck = true; // 엑셀 양식명 체크
    // 주문 엑셀 수정 사용 가능여부
    const excelEditUseYn = $rootScope.affName === 'LG전자' && !!$rootScope.user_profile.pa_sol_no;
    let addColList;
    let repeatIndex = 0; // 항목명 사용하지 않을 때 동일 컬럼 구분자
    const except = ['sku_cd', 'prod_name', 'map_yn', 'pack_unit', 'sale_cnt_total', 'set_cd', 'set_name'
      , 'add_opt_sku_cd', 'add_opt_prod_name', 'add_opt_pack_unit', 'add_opt_sale_cnt_total', 'sku_cd_total'
      , 'add_opt_sku_cd_total', 'add_opt_barcode', 'barcode', 'add_opt_set_cd', 'add_opt_set_name', 'set_pack_unit'
      , 'add_opt_set_pack_unit', 'set_total', 'add_opt_set_total', 'prod_attri', 'add_opt_prod_attri'];
    const canSeparateCol = ['#SKU상품명', '#추가구매SKU상품명', '#SKU코드', '#SKU코드및출고수량', '추가구매SKU코드'
      , '추가구매SKU코드및출고수량', '#건별출고수량', '#추가구매건별출고수량', '#총출고수량', '#추가구매총출고수량'
      , '#추가구매주문수량', '#바코드', '#추가구매바코드', '#추가구매옵션', '#추가구매세트상품명', '#추가구매세트코드'
      , '#추가구매세트건별출고수량', '#추가구매세트총출고수량', '#SKU상품속성', '#추가구매SKU상품속성'];

    if (!$scope.sol_stock) {
      $scope.columnList = _.filter(shipmentSVC.integratedExcel, function (c) {
        return except.indexOf(c.key) === -1;
      });
    } else {
      // 락앤락 사용자인 경우 할인 항목 출력 할 수 있게 수정
      $scope.columnList = angular.copy(shipmentSVC.integratedExcel).filter(column => [1, 3104].includes($rootScope.user_profile.sol_no) ? true : !['shop_discount', 'seller_discount', 'coupon_discount', 'point_discount'].includes(column.key)); // 기본 컬럼 리스트는 초기화가 되어야 하므로 서비스에서 가져오는 것으로 수정
    }

    // 국내/해외 주문에 따라 셀 항목 다르게 설정
    $scope.columnList = $scope.columnList.filter(col => data.isGlobal ? col.isGlobal : col.isDomestic);

    // 위탁주문 관리 건별/집계 셀 항목 다르게 설정
    if ($scope.consignmentType) {
      $scope.template.template_type_cd = $scope.consignmentType === 'total' ? 'T115' : 'T116';
      $scope.columnList = $scope.consignmentType === 'total' ? angular.copy(shipmentSVC.consignmentTotalExcel) : angular.copy(shipmentSVC.consignmentAggregationExcel);
      $timeout(() => {});
    }

    // 엑셀 일괄 수정 사용시 uniq 필요하여 필드목록에 추가함
    if (excelEditUseYn) {
      $scope.columnList.splice(1, 0, { header: '주문고유번호', key: 'uniq', select: false, variable: '#주문고유번호', key_settings: '0', width: 35, isDomestic: true, isGlobal: false });
    }

    // 컬럼마다 구분자 넣어줌
    _.forEach($scope.columnList, function (c) {
      c.index = repeatIndex++;
    });

    const columnList = angular.copy($scope.columnList);

    if (($rootScope.userProfileCheck('sol_ser', 'addcol', 'like') || $rootScope.user_profile.pa_sol_no) && !$scope.consignmentType) {
      columnModel.addColumnList({ addcol_type: 'ord' })
        .then((res) => {
          if (res.data && res.data.results && res.data.results.length) {
            addColList = _.map(res.data.results, 'col_name');
            addColSet(addColList);
          }
        });
    }

    if ($scope.pageType === 'add') {
      let type_cd = '';

      switch (data.page) {
        case 'payment':
          type_cd = 'T102';
          break;
        case 'order':
          type_cd = 'T103';
          break;
        case 'unstoring':
          type_cd = 'T104';
          break;
        case 'delivery':
          type_cd = 'T105';
          break;
        case 'claim':
          type_cd = 'T106';
          break;
        case 'consignment-total':
          type_cd = 'T115';
          break;
        case 'consignment-aggregation':
          type_cd = 'T116';
          break;
        default:
          type_cd = 'T101';
          break;
      }

      // sale_name_type 사용안하고 이전 출고관리에서 사용하는 값과 구분하기 위해 공란으로 넣어줌

      const defaultData = {
        file_type: 'xlsx',
        rename_yn: false,
        sale_name_type: '',
        bundle_ord_down_type: 'ord',
        order_by: 'default',
        template_type_cd: type_cd,
        bundle_order_yn: false,
        bundle_order_type: 'desc',
        bundle_ship_cost_type: '직접입력',
      };

      $scope.template = angular.copy(defaultData);

      $scope.selectedList = _.filter(angular.copy($scope.columnList), function (c) {
        return c.select;
      });

      $scope.warehouseList = $scope.warehouseList.filter(depot => (!$scope.used_depot_list.includes(depot.code) && depot.charge_email));
    } else {
      shipmentModel.integratedExcelTemplate({ template_no: data.template_no }, function (state, result) {
        if (state === 'success') {
          $scope.template = result.results[0];
          $scope.template.bundle_order_yn = !!$scope.template.bundle_order_type;
          $scope.isCarr = !!$scope.template.default_carr_no;

          $scope.selectedDepotList = $scope.template.template_type_cd === 'T107' && !$scope.isGlobal ? angular.copy($scope.template.linkage_depot_list.map(depot => $scope.warehouseList.find(warehouse => depot === warehouse.code)).filter(Boolean)) : [];

          $scope.warehouseList = $scope.warehouseList.filter(depot => (!$scope.used_depot_list.includes(depot.code) && !$scope.template.linkage_depot_list.includes(depot.code)) && depot.charge_email);

          if (!$scope.sol_stock) {
            $scope.template.order_by = except.indexOf($scope.template.order_by) > -1 ? 'default' : $scope.template.order_by;
          }

          // 기존 사용자들은 select처리를 했으므로 select 된것을 가지고 옴
          $scope.selectedList = _.filter($scope.template.columns_rename, function (c) {
            return c.select || c.select === undefined;
          });

          // 컬럼마다 구분자 넣어줌
          _.forEach($scope.selectedList, function (s) {
            s.index = repeatIndex++;
          });
        }
      });
    }

    if (data.isGlobal) {
      $scope.carrList = [...$rootScope.deliveryInfoList, { carr_no: 944, carr_name: 'Shopee(SLS)' }];
    } else {
      $scope.carrList = $rootScope.deliveryInfoList;
    }

    // 추가컬럼 항목 세팅
    function addColSet (addColList) {
      let allColIdx = columnList.length - 1;

      _.forEach(addColList, (col) => {
        if (!columnList.find(({ key }) => key === col)) {
          // 커스텀 컬럼이 있는 경우 추가
          columnList.push({ header: columnSVC.setColumnName(col, 'GET'), key: col, key_settings: '0', unchangable: true, width: 30, index: allColIdx });
          $scope.columnList.push({ header: columnSVC.setColumnName(col, 'GET'), key: col, key_settings: '0', unchangable: true, width: 30, index: allColIdx });
          allColIdx++;
        }
      });
    }

    /**
     * 분류 변경
     */
    $scope.changeTemplateType = () => {
      // 분류가 신규주문/출고관리 아닌 경우 '출고가능여부' 선택컬럼 리스트에서 제외
      if (!['T103', 'T104'].includes($scope.template.template_type_cd)) {
        const selectedShipAvailYnIdx = $scope.selectedList.findIndex(({ key }) => key === 'ship_avail_yn');

        if (selectedShipAvailYnIdx > -1) {
          $scope.selectedList.splice(selectedShipAvailYnIdx, 1);
        }
      }

      // 배송처 타입인 경우 선택 컬럼 변경
      if ($scope.template.template_type_cd === 'T107' && !$scope.isGlobal) {
        $scope.selectedList = angular.copy(shipmentSVC.depotSelectedCols);
        $scope.selectedDepotList = [];
      }

      // 검색필터 초기화
      $scope.clearFilter();
    };

    /**
     * 출력 값 설정 변경
     */
    $scope.change_key_settings = function () {
      const pattern = /#[a-zA-Z가-힣\n]+(?=[-+/*#]|$)/g;
      const matches = $scope.cell.variable.match(pattern) || [];
      if (!$scope.isNew) {
        if ($scope.cell.key_settings === '0' && !$scope.cell.key) {
          if (!matches.length || !$scope.columnList.filter(v => v.variable).map(item => item.variable).includes(matches[0])) {
            return commonSVC.showMessage('실패', '출력값 설정이 [지정안함]일 시 실제 출력 값에 유효한 [#항목명] 을 입력해주세요.');
          }
          $scope.selectedList = $scope.selectedList.map(item => {
            if (item.header === $scope.cell.header) {
              return { ...item, key: $scope.columnList.filter(item => item.variable === matches[0])[0].key };
            } else {
              return item;
            }
          });
          $scope.cell.key = $scope.columnList.filter(item => item.variable === matches[0])[0].key;
        }
      }
      if ($scope.cell.key_settings === '0' && !$scope.isNew) {
        const origin = _.filter(columnList, function (c) {
          return $scope.cell.key === c.key;
        });
        $scope.cell.variable = origin[0].variable;
      }

      if ($scope.cell.key_settings !== '2') {
        delete $scope.cell.separator;
      }

      if ($scope.cell.key_settings === '5') {
        switch ($scope.cell.header) {
          case 'SKU코드및출고수량':
            $scope.cell.variable = '#세트코드및총출고수량';
            break;
          case 'SKU코드':
            $scope.cell.variable = '#세트코드';
            break;
          case 'SKU상품명':
            $scope.cell.variable = '#세트상품명';
            break;
          case '건별출고수량':
            $scope.cell.variable = '#세트건별출고수량';
            break;
          case '총출고수량':
            $scope.cell.variable = '#세트총출고수량';
            break;
          case '추가구매SKU코드':
            $scope.cell.variable = '#추가구매세트코드';
            break;
          case '추가구매SKU코드및출고수량':
            $scope.cell.variable = '#추가구매세트코드및총출고수량';
            break;
          case '추가구매SKU상품명':
            $scope.cell.variable = '#추가구매세트상품명';
            break;
          default:
            break;
        }
      } else {
        $scope.cell.variable = columnList.find(v => v.key === $scope.cell.key).variable;
      }
    };

    /**
     * 택배사 사용여부 변경
     */
    $scope.useDefaultCarr = function (flag) {
      $scope.isCarr = flag;
    };

    /**
     * 도움말 모달
     */
    $scope.openHelp = function () {
      commonSVC.openModal('lg', {}, 'ExcelEditIntegratedHelpCtrl', 'views/order/shipment/modals/excel_edit_integrated_help.html');
    };

    /**
     * 전체항목 검색
     */
    $scope.columnListFilter = column => {
      // 분류가 신규주문/출고관리 아닌 경우 '출고가능여부' 컬럼 리스트에서 제외
      if (!['T103', 'T104'].includes($scope.template.template_type_cd) && column.header === '출고가능여부') {
        return false;
      }

      // 검색어 처리
      if ($scope.columnListSearch) {
        return column.header.indexOf($scope.columnListSearch) >= 0;
      } else {
        return true;
      }
    };

    /**
     * 전체항목 검색 초기화
     */
    $scope.clearFilter = function () {
      $scope.columnList = angular.copy(columnList);
      $scope.columnListSearch = '';
    };

    /**
     * 저장
     */
    $scope.submit = function () {
      if (!$scope.template.name) {
        $scope.nameCheck = false;

        return false;
      }

      if (!$scope.selectedList.filter(o => o).length) {
        commonSVC.showMessage('실패', '선택항목이 비어 있습니다. 전체항목에서 다운로드 받을 항목을 선택해주세요.');

        return false;
      }

      if ($scope.template.template_type_cd === 'T107' && !$scope.selectedDepotList.length && !$scope.isGlobal) {
        commonSVC.showMessage('실패', '연동할 배송처를 선택하여 주세요.');

        return false;
      }

      let isErr = false;

      $scope.selectedList.forEach((col) => {
        if (col.message) {
          isErr = true;
          commonSVC.showMessageHtml('실패', `항목명: ${col.header} <br> 내용: ${col.message}`);

          return false;
        }
      });

      if (isErr) {
        return false;
      }

      if ($scope.deliveryExcelTemplateForm.$valid) {
        if ($scope.pageType === 'add') {
          insert();
        } else if ($scope.pageType === 'edit') {
          update();
        }
      }
    };

    /**
     * 양식 추가
     */
    function insert () {
      if ($scope.selectedList.length === 0) {
        commonSVC.showMessage('엑셀 항목을 선택해주세요.');

        return false;
      }

      // selectedList만 데이터에 넣음
      $scope.template.columns_rename = $scope.selectedList;
      $scope.template.default_carr_no = $scope.isCarr ? $scope.template.default_carr_no : null; //택배사 정보 출력 여부 체크
      $scope.template.bundle_order_type = $scope.template.bundle_order_yn ? $scope.template.bundle_order_type : '';
      $scope.template.global_yn = data.isGlobal ? 1 : 0;

      // 템플릿 타입 배송처일 경우 연동 배송처 저장
      if ($scope.template.template_type_cd === 'T107' && !$scope.isGlobal) {
        $scope.template.linkage_depot_list = $scope.selectedDepotList.map(depot => depot.code);
      }

      commonSVC.showConfirm('등록하시겠습니까?', '', function () {
        shipmentModel.insertIntegratedExcelTemplate($scope.template, function(state) {
          if (state === 'success') {
            commonSVC.showToaster('success', '성공', 'Excel 양식 등록 성공');
            $uibModalInstance.close('success');
          } else {
            commonSVC.showToaster('error', '실패', 'Excel 양식 등록 실패');
          }
        });
      });
    }

    /**
     * 양식 수정
     */
    function update () {
      $scope.template.columns_rename = $scope.selectedList;
      $scope.template.default_carr_no = $scope.isCarr ? $scope.template.default_carr_no : null; //택배사 정보 출력 여부 체크
      $scope.template.bundle_order_type = $scope.template.bundle_order_yn ? $scope.template.bundle_order_type : '';

      // 템플릿 타입 배송처일 경우 연동 배송처 수정
      if ($scope.template.template_type_cd === 'T107' && !$scope.isGlobal) {
        $scope.template.linkage_depot_list = $scope.selectedDepotList.map(depot => depot.code);
      }

      if ($scope.template.bundle_ord_down_type === 'sku' && $scope.selectedList.some(({ key_settings }) => key_settings === '5')) {
        commonSVC.showMessage('실패', `선택 항목의 ${$scope.selectedList.filter(v => v.key_settings === '5').map(v => v.header).join(',')} 출력 값 설정을 다시 선택해주세요.\n(SKU 코드별한줄로 출력인 경우 세트상품 데이터로 출력할 수 없습니다)`);

        return false;
      }

      commonSVC.showConfirm('수정하시겠습니까?', '', function () {
        delete $scope.template.bundle_order_yn;
        shipmentModel.updateIntegratedExcelTemplate({ template_no: $scope.template.template_no, template: $scope.template }, function(state) {
          if (state === 'success') {
            commonSVC.showToaster('success', '성공', 'Excel 양식 수정 성공');
            $uibModalInstance.close('success');
          } else {
            commonSVC.showToaster('error', '실패', 'Excel 양식 수정 실패');

            return false;
          }
        });
      });
    }

    /**
     * 선택항목에 추가
     */
    $scope.select = function(flag) {
      if (flag === 'all') {
        const temp = _.filter($scope.selectedList, function (s) {
          return s.custom;
        });

        $scope.selectedList = _.concat(temp, angular.copy($scope.columnList));
      } else {
        if ($scope.selectedColumns.length === 0) {
          return;
        }
        _.forEach($scope.selectedColumns, function(v) {
          const isDuplicated = _.findIndex($scope.selectedList, ['header', v]) !== -1; //중복 확인

          if (isDuplicated) {
            commonSVC.showMessage('이미 선택된 항목이 있습니다.');

            return false;
          } else {
            const idx = _.findIndex($scope.columnList, ['header', v]);

            $scope.selectedList.push(angular.copy($scope.columnList[idx]));
          }
        });
      }
    };

    /**
     * 선택항목에서 제거
     */
    $scope.deselect = function(flag) {
      if (flag === 'all') {
        const customList = _.filter($scope.selectedList, function (s) {
          return s.custom;
        });

        $scope.selectedList = angular.copy(customList);
      } else {
        if ($scope.deselectedColumns.length === 0) {
          return false;
        }
        _.forEach($scope.deselectedColumns, function (c) {
          const idx = _.findIndex($scope.selectedList, function (s) {

            return c === s.header + (s.key_settings || '') + s.index;
          });

          _.pullAt($scope.selectedList, idx);
        });
      }
    };

    /**
     * 순서 변경
     */
    $scope.change_seq = function(calcul, position) {
      if ($scope.deselectedColumns.length === 0) {
        return;
      }

      // if (idx == 0 && calcul === 'up' || idx == $scope.selectedList.length - 1 && calcul === 'down') {
      //   return false;
      // }

      // var tmp = $scope.selectedList[idx];
      const index = [];
      let sTemp = [];

      if (position === 'end') {
        _.forEach($scope.deselectedColumns, function (d) {
          index.push(_.findIndex($scope.selectedList, function (s) {
            return d === columnSVC.setColumnName(s.header, 'GET') + (s.key_settings || '') + (_.isNullOrEmpty(s.index) ? '' : s.index);
          }));
        });

        if (calcul === 'down') {
          if (_.isEqual(_.map($scope.selectedList.slice($scope.selectedList.length - index.length, $scope.selectedList.length), 'header'), $scope.deselectedColumns)) {
            commonSVC.showMessage('실패', '이동할 수 있는 컬럼이 존재하지 않습니다.');

            return false;
          }

          $scope.colIndex = $scope.selectedList.length - 1;
          sTemp = _.pullAt($scope.selectedList, index);

          $scope.selectedList = _.concat($scope.selectedList, sTemp);
        } else {
          if (_.isEqual(_.map($scope.selectedList.slice(0, index.length), 'header'), $scope.deselectedColumns)) {
            commonSVC.showMessage('실패', '이동할 수 있는 컬럼이 존재하지 않습니다.');

            return false;
          }

          $scope.colIndex = 0;
          sTemp = _.pullAt($scope.selectedList, index);

          $scope.selectedList = _.concat(sTemp, $scope.selectedList);
        }
      } else {

        const toIndex = [];

        _.forEach($scope.deselectedColumns, function (d) {
          const i = _.findIndex($scope.selectedList, function (s) {
            return d === s.header + (s.key_settings || '') + (_.isNullOrEmpty(s.index) ? '' : s.index);
          });

          if (calcul === 'down') {
            index.unshift(i);

            if (i !== $scope.selectedList.length - 1 && toIndex.indexOf(i + 1) === -1) {
              toIndex.unshift(i + 1);
            } else if ($scope.selectedList.length - 1 && toIndex.indexOf(i) === -1) {
              toIndex.unshift(i);
            } else {
              toIndex.unshift(i - 1);
            }
          } else {
            index.push(i);
            if (i !== 0 && toIndex.indexOf(i - 1) === -1) {
              toIndex.push(i - 1);
            } else {
              toIndex.push(i);
            }

          }
        });
        if (_.isEqual(_.sortBy(toIndex), _.sortBy(index))) {
          commonSVC.showMessage('실패', '이동할 수 있는 컬럼이 존재하지 않습니다.');

          return false;
        }

        if (calcul === 'up') {
          _.forEach(index, function (idxV, i) {
            const sTemp = $scope.selectedList[idxV];

            $scope.selectedList[idxV] = $scope.selectedList[toIndex[i]];
            $scope.selectedList[toIndex[i]] = sTemp;
            $scope.colIndex--;
          });
        } else {
          _.forEach(index, function (idxV, i) {
            const sTemp = $scope.selectedList[idxV];

            $scope.selectedList[idxV] = $scope.selectedList[toIndex[i]];
            $scope.selectedList[toIndex[i]] = sTemp;
            $scope.colIndex++;
          });
        }

      }
    };

    // 양식 변경 탭
    $scope.newColumn = function () {
      $scope.isNew = true;
      $scope.cell.header = '신규';
      $scope.cell.variable = '';
      $scope.cell.key_settings = '1';
      $scope.cell.width = 20;
    };

    // 컬럼 클릭(변경)
    $scope.changeColumn = function (index) {
      $scope.cell_key_options = [
        { value: '1', label: '고정텍스트' },
        { value: '2', label: '변수' },
        { value: '3', label: '수식' }
      ];

      if ($scope.deselectedColumns.length !== 1) {
        $scope.isEdit = false;

        return false;
      } else {
        $scope.isEdit = true;
      }

      if ($scope.isNew) {
        $scope.isNew = false;
        $scope.isEdit = true;
      }

      // 커스텀 컬럼인 경우 수정불가
      if ($scope.selectedList[index].unchangable) {
        $scope.isNew = false;
        $scope.isEdit = false;

        return true;
      }

      if ($scope.colIndex !== index) {
        const column = $scope.selectedList[index];

        $scope.cell.variable = column.variable;
        $scope.cell.header = column.header;
        $scope.cell.key_settings = column.key_settings;
        $scope.cell.key = column.key;
        $scope.cell.separator = column.separator;
        $scope.cell.width = column.width;
        if ($scope.deselectedColumns.length === 1) {
          $scope.colIndex = index;
        }
      }

      $scope.hasSet = false;
      const optionsMap = {
        SKU코드및출고수량: '세트상품인 경우 세트코드및총출고수량 출력',
        SKU코드: '세트상품인 경우 세트코드 출력',
        SKU상품명: '세트상품인 경우 세트상품명 출력',
        건별출고수량: '세트상품인 경우 세트건별출고수량 출력',
        총출고수량: '세트상품인 경우 세트총출고수량 출력',
        추가구매SKU코드: '세트상품인 경우 추가구매세트코드 출력',
        추가구매SKU코드및출고수량: '세트상품인 경우 추가구매세트코드및총출고수량 출력',
        추가구매SKU상품명: '세트상품인 경우 추가구매세트상품명 출력'
      };

      const optionLabel = optionsMap[$scope.selectedList[index].header];

      if (optionLabel) {
        $scope.cell_key_options.push({ value: '5', label: optionLabel });
      } else {
        $scope.hasSet = true; // 세트상품명 출력 가능여부(실제출력값 툴팁 노출용)
      }
    };

    // 양식 수정 저장(임시)
    $scope.updateRenameList = function () {
      $scope.template.rename_yn = true;
      let column = {};
      let flag = true;
      let message = '';
      // 출력 값 설정 === 고정텍스트 일 때만 빈값 허용
      if ($scope.cell.key_settings !== '1' && !$scope.cell.variable) {
        commonSVC.showMessage('실패', '고정텍스트 형식인 항목에서만 빈 값이 허용됩니다.');

        return false;
      }

      if ($scope.template.bundle_ord_down_type === 'sku' && $scope.cell.key_settings === '5') {
        commonSVC.showMessage('실패', '선택 항목의 출력 값 설정을 다시 선택해주세요.\n(SKU 코드별한줄로 출력인 경우 세트상품 데이터로 출력할 수 없습니다)');

        return false;
      }

      if ($scope.isNew) {
        column = {
          header: $scope.cell.header,
          key_settings: $scope.cell.key_settings,
          variable: $scope.cell.variable,
          custom: 1,
          index: repeatIndex++
        };

        // 항목명이 있을 때만 동일한 항목명 구분
        if (_.filter($scope.selectedList, function (s) {
          return $scope.cell.header === s.header;
        }).length && $scope.cell.header) {
          commonSVC.showMessage('실패', '동일한 항목명이 이미 존재합니다.');

          return false;
        }
      } else if ($scope.isEdit) {
        // 항목명이 있을 때만 동일한 항목명 구분
        if ($scope.cell.key_settings === '0') {
          const pattern = /#[a-zA-Z가-힣\n]+(?=[-+/*#]|$)/g;
          const matches = $scope.cell.variable.match(pattern) || [];
          if (!matches.length || !$scope.columnList.filter(v => v.variable).map(item => item.variable).includes(matches[0])) {
            commonSVC.showMessage('실패', '출력값 설정이 [지정안함]일 시 실제 출력 값에 유효한 [#항목명] 을 입력해주세요.');

            return false;
          } else {
            const originKey = $scope.selectedList.length ? $scope.selectedList[$scope.colIndex].key : $scope.columnList[$scope.colIndex].key;
            column.newKey = originKey;
            column.variable = _.filter($scope.columnList, function(o) { return o.key === originKey; })[0].variable;
          }
        }
        if ($scope.selectedList[$scope.colIndex].header !== $scope.cell.header && _.filter($scope.selectedList, function (s) {
          return $scope.cell.header === s?.header;
        }).length && $scope.cell.header) {
          commonSVC.showMessage('실패', '동일한 항목명이 이미 존재합니다.');

          return false;
        }
        $scope.cell.index = column.index;
        column = $scope.selectedList[$scope.colIndex]; // 수정될 컬럼
        column.key_settings = $scope.cell.key_settings;
        column.variable = $scope.cell.variable;
        column.header = $scope.cell.header;
      }

      if (!$scope.cell.key_settings || $scope.cell.key_settings === '0') {
        // 사용안함일때 key 원본 값 사용 및 variable 초기화
        // 선택항목이 있는경우 선택항목 값으로 복구해야 함. 2019-05-29 rony
        const originKey = $scope.selectedList.length ? $scope.selectedList[$scope.colIndex].key : $scope.columnList[$scope.colIndex].key;

        column.newKey = originKey;
        column.variable = _.filter($scope.columnList, function(o) { return o.key === originKey; })[0].variable;
      } else if ($scope.cell.key_settings === '1') {
        $scope.cell.index = column.index;
        const { header, key, key_settings, variable, width, index } = $scope.cell;
        // 고정 텍스트 사용할 때 variable 만 변경
        column = { header, key, key_settings, variable, width, index };
      } else if ($scope.cell.key_settings === '2') {
        column.newKey = $scope.cell.variable.replace(/#[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9]+/g, function (e) {
          const columns = _.filter(columnList, function (col) {
            return col.variable === e;
          });

          if (columns.length) {
            return `#${columns[0].key}`;
          } else {
            message = `<b class="text-danger">${e.replace('#', '')}</b> 변수명을 찾을 수 없습니다.`;

            flag = false;
          }
        });
        column.variable = $scope.cell.variable;
      } else if ($scope.cell.key_settings === '3') {
        // 수식 사용일 때 수식 + db 컬럼명으로 치환
        const check = $scope.cell.variable.split(/[+*/()-]/g); // 수식확인

        if (!_.isArray(check) || _.compact(check).length <= 1) {
          message = '정상적인 수식이 아닙니다.';
          flag = false;
        } else if (_.filter(check, (col) => { return col.match(/^[^#0-9]+/g); }).length) {
          const notMatch = _.filter(check, (col) => { return col.match(/^[^#0-9]/g); });

          message = `수식에 변수에 포함되지 않은 문자열이 있습니다. 문자열: <b class="text-danger">${notMatch.join(',')}</b>`;
          flag = false;
        } else {
          column.newKey = $scope.cell.variable.replace(/[a-zA-Zㄱ-힣]+/g, function (columnHeader) {
            // 구분자 사용하는 컬럼 수식 불가
            // 국가 사용불가..
            if (!columnHeader.match(/^[가-힣]+[가액비량]$/g) || canSeparateCol.indexOf(`#${columnHeader}`) > -1 || columnHeader === '국가') { // 인트형 컬럼명 찾기...
              message = `수식에 사용 불가능한 변수 또는 문자열이 입력되어 있습니다. \n 변수(문자열): <b class="text-danger">${columnHeader}</b>`;
              flag = false;

              return;
            } else {
              const columns = _.filter(columnList, function (col) {
                return col.header === columnHeader;
              });

              if (columns.length) {
                return columns[0].key;
              } else {
                // 일치하는 컬럼명이 없을 때
                message = `변수명이 올바르지 않습니다. \n 변수명: #${columnHeader}`;
                flag = false;
              }
            }
          }).replace(/[a-zA-Z_]+\(/g, function (e) {  // #변수(#변수+#변수) 곱하기 처리
            return e.replace('(', '*(');
          }).replace(/\)[a-zA-A_]+/g, function (e) {  // (#변수+#변수)#변수 곱하기 처리
            return e.replace(')', ')*');
          });

          column.variable = $scope.cell.variable;
        }
      }

      // 구분자 처리
      if ($scope.cell.key_settings === '2' && $scope.checkSeparator($scope.cell.variable)) {
        column.separator = $scope.cell.separator;
      } else {
        delete column.separator;
      }

      column.width = parseInt($scope.cell.width);

      if (!flag) {
        column.message = message;
        commonSVC.showMessageHtml('실패', message);

        return false;
      } else {
        delete column.message;

        if ($scope.isNew) {
          column.index = repeatIndex;
          $scope.selectedList.unshift(column);
        } else if ($scope.isEdit) {
          $scope.selectedList[$scope.colIndex] = column;
        }

        $scope.isNew = $scope.isEdit = false;
        commonSVC.showToaster('success', '성공', '저장 성공\n모달 가장 하단에 있는 저장버튼을 누르시면 최종적으로 저장되어 적용됩니다.');
      }

      return;
    };

    // 구분자 사용가능 여부 체크
    $scope.checkSeparator = function (value) {

      let flag = false;

      if ($scope.cell.key_settings === '2') {
        _.forEach(canSeparateCol, (col) => {
          if (value.indexOf(col) > -1) {
            flag = true;

            return false;
          }
        });
      }

      return flag;
    };

    // 배송처 선택 제거
    $scope.delSelectedDepot = (index) => {
      $scope.warehouseList.push($scope.selectedDepotList[index]);
      $scope.selectedDepotList.splice(index, 1);
    };

    // 배송처 선택 여부 확인
    $scope.isDepotSelected = function(depot) {
      return $scope.selectedDepotList.some(selectedDepot => selectedDepot.code === depot.code);
    };

    // 배송처 선택 토글
    $scope.toggleDepotSelection = function(event, depot) {
      event.preventDefault();
      event.stopPropagation();
      const index = $scope.selectedDepotList.findIndex(selectedDepot => selectedDepot.code === depot.code);
      if (index === -1) {
        $scope.selectedDepotList.push(depot);
      } else {
        $scope.selectedDepotList.splice(index, 1);
      }
    };

    // 배송처 전체 선택 여부 확인
    $scope.isAllSelected = function() {
      if (!$scope.selectedDepotList.length) {
        return false;
      }
      const selectedCodes = new Set($scope.selectedDepotList.map(depot => depot.code));

      return $scope.warehouseList.every(depot => selectedCodes.has(depot.code));
    };

    // 배송처 전체 선택 토글
    $scope.toggleSelectAll = function(event) {
      event.preventDefault();
      event.stopPropagation();
      if ($scope.isAllSelected()) {
        $scope.warehouseList = $scope.selectedDepotList;
        $scope.selectedDepotList = [];
      } else {
        const selectedCodes = new Set($scope.selectedDepotList.map(depot => depot.code));
        const combinedList = $scope.warehouseList.filter(depot => !selectedCodes.has(depot.code));

        $scope.selectedDepotList = [...$scope.selectedDepotList, ...combinedList];
        $scope.warehouseList = [];
      }
    };

    // 새 배송처 만들기
    $scope.addNewDepot = function() {
      const resolve = {
        data: {
          depotListLength: $scope.warehouseList.length
        }
      };

      const modal = commonSVC.openModal('lg', resolve, 'StockWarehouseAddCtrl', 'views/stock/warehouse/add.html', false, true, false);

      modal.result.then(function (re) {
        if (re.result === 'success') {
          warehouseModel.ListAll({ use_yn: true }, function(state, data) {
            $scope.warehouseList = data.result.filter(depot =>
              !$scope.used_depot_list.includes(depot.code) &&
              !$scope.template.linkage_depot_list?.includes(depot.code) &&
              depot.charge_email
            );
          });
        }
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  })

  .controller('ExcelEditIntegratedHelpCtrl', function ($rootScope, $scope, $uibModalInstance) {
    $scope.sol_stock = !!$rootScope.user_profile.sol_stock;
    $scope.disable = false;
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
