'use strict';

angular.module('gmpApp')
  .directive('leftContentSearch', function (commonSVC, $timeout, $compile, columnModel) {
    return {
      restrict: 'EA',
      templateUrl: 'views/tpls/leftContentSearch.html',
      link: function (scope, elem) {
        const default_search_key = scope.searchData.search_key_items[0].value;
        scope.searchForm.search_key = scope.searchForm.search_key || default_search_key;
        scope.searchForm.change_sdate = false;
        scope.searchForm.change_edate = false;

        const initSearchDate = moment(scope.searchForm.edate).diff(moment(scope.searchForm.sdate), 'months');

        if (scope.searchForm.search_key === 'all' && ['online_product_grid_searchbar', 'prod_prodlist_grid_searchbar', 'sub_online_product_grid_searchbar', 'sub_prod_prodlist_grid_searchbar'].includes(scope.$parent.el_id)) {
          scope.isDisabled = true;
        }

        switch (initSearchDate) {
          case 1:
            scope.searchForm.selectDate = '1MONTH';
            break;
          case 3:
            scope.searchForm.selectDate = '3MONTH';
            break;
          case 6:
            scope.searchForm.selectDate = '6MONTH';
            break;
          case 12:
            scope.searchForm.selectDate = '1YEARS';
            break;
        }
        /**
         * 검색
         * 메모 탭과 searchDo 공유하기위해 $parent 스코프로 생성
         */
        scope.$parent.searchDo = _.throttle(function (refresh = true) {
          // 검색조건 툴팁 변경되게
          setSearchTags();
          // 컨트롤러의 searchDo 바로 호출하도록 변경
          if (scope.$parent.location !== 'atalk') {
            if (scope.$parent.$parent.searchDo) {
              scope.$parent.$parent.searchDo(refresh, true, false, () => {
                $timeout(function () { scope.waitBtnDisableFlag = false; });
              });
            }
          } else {
            scope.$parent.$parent.searchDo(refresh, true, scope.$parent.el_id, () => {
              $timeout(function () { scope.waitBtnDisableFlag = false; });
            });
          }
          if (scope.$parent.rowSelectInit) {
            scope.$parent.rowSelectInit();
          }
        }, 1000);

        /**
         * 검색 초기화
         */
        scope.resetDo = _.throttle(function () {
          scope.searchFn.resetDo();
          // 검색조건 툴팁 변경되게
          setSearchTags();

          if (scope.$parent.rowSelectInit) {
            scope.$parent.rowSelectInit();
          }

          scope.date_change(scope.searchData.search_date_key_names);

          if (['online_product_grid_searchbar', 'prod_prodlist_grid_searchbar', 'sub_online_product_grid_searchbar', 'sub_prod_prodlist_grid_searchbar'].includes(scope.$parent.el_id)) {
            $timeout(function() {
              scope.isDisabled = scope.searchForm.search_key === 'all';
            }, 0);
          }
        }, 1000);

        // 검색 태그 삭제
        scope.searchTagsDel = function (type, value) {
          scope.searchForm[value] = '';

          if (scope.searchForm.search_key) {
            scope.searchForm.search_key = '';
          }

          if (value === 'shop_cd') {
            scope.searchForm.shop_id = '';
          }
          // else if (value === 'search_word') {
          //   // 페이지 별로 셀렉트 첫번째 값이 틀린경우가 있어 초기화시 셀렉트 옵션의 첫번째 value로 넣어줌
          //   scope.data.search_key_string = default_search_key;
          // }
          if (type === 'searchDetail') {
            const row = _.find(scope.searchDetail, {
              search_name: value
            });

            row.select_value = '';
          }
          scope.$parent.searchDo();

          // 쇼핑몰 계정 & 상품상태 변경시 트리 선택값 갱신위해 이벤트 호출
          if (_.includes(['shop_cd', 'shop_id', 'sale_status'], value)) {
            scope.$broadcast('olTreeUdateEvent', {
              act: 'searchTagsDel'
            });
          }

          // 삭제버튼 클릭시 태그 삭제하기 위해 태그설정 함수 재호출
          setSearchTags();
        };

        scope.$watchCollection('searchDetail', function () {
          $timeout(function () {
            const $searchDetailElem = $(`#searchDetailDiv-${scope.$id}`);
            const $selectSearchElems = $searchDetailElem.find('.select-search');

            // 2018-12-24 chris 상세검색 select에 검색 기능 추가
            if ($selectSearchElems.length > 0) {
              // 일반 select와 스타일 맞춤
              $selectSearchElems.attr('data-container-css-class', 'select2-custom input-xs mb-5');

              // 검색 서브키가 있는 경우 각 옵션에 서브키에 해당하는 값 추가로 저장해줌 (해당 요소에 `item_sub_key` 추가하면됨)
              // (`item_list`에서 `item_value`로 해당 object를 찾아 `item_sub_key` 값을 저장)
              //  - 사용예시: 해외쇼핑몰 한국어로도 검색가능하고 싶을때
              $selectSearchElems.each((_, el) => {
                const itemKey = $(el).attr('id').replace('search-detail-', '');
                const searchDetailItem = scope.searchDetail.find(o => o.search_name === itemKey);

                if (searchDetailItem?.item_sub_key) {
                  const { item_sub_key, item_list, item_value } = searchDetailItem;

                  $(el).find('option').each((_, optEl) => {
                    if ($(optEl).val()) {
                      $(optEl).data('additional', item_list.find(o => o[item_value] === $(optEl).val())[item_sub_key]);
                    }
                  });
                }
              });

              // select2 검색기능 추가
              $selectSearchElems.select2({
                matcher: function (params, data) {
                  if (!params.term?.trim()) {
                    return data;
                  }

                  // 기본적인 텍스트 매칭
                  if (data.text?.toUpperCase().includes(params.term.toUpperCase())) {
                    return data;
                  }

                  // 현재 옵션 요소의 'additional' 데이터를 가져옴
                  var additional = $(data.element).data('additional');

                  // `additional` 정보에 검색어가 포함되어 있는지 확인
                  if (additional?.includes(params.term)) {
                    return data;
                  }

                  return null;
                },
                dropdownCssClass: function (params) {
                  if (params[0].id.includes('search-detail-sol_cate_no')) {
                    return 'select2-searchbar-sol_cate';
                  } else {
                    return 'select2-searchbar-default';
                  }
                },
                dropdownAutoWidth: true
              });

              // 셀렉트 선택시 select-on클래스 추가
              $selectSearchElems.on('select2:select', function (e) {
                const $select_el = $(e.params.data.element);
                let _id = $select_el.parent().attr('id');

                _id = _id.replace('search-detail-', 'select2-search-detail-');

                if ($select_el.val() && $select_el.val() != '') {
                  $searchDetailElem.find(`#${_id}-container`).parent().addClass('select-on');
                } else {
                  $searchDetailElem.find(`#${_id}-container`).parent().removeClass('select-on');
                }
              });

              // 템플릿은 일단 비활성화
              // searchDetailDiv.find('#search-detail-template_no').prop('disabled', true);
              $searchDetailElem.find('.select2-selection__rendered').each(function (idx, el) {
                $(el).parent().attr('data-placement', 'top');
                $(el).parent().attr('data-popup', 'tooltip');
                let _id = $(el).attr('id');

                _id = _id.replace('select2-search-detail-', '').replace('-container', '');
                const selectVal = scope.searchDetail.find(list => list.search_name === _id);

                if (selectVal && selectVal.select_value) {
                  $(el).parent().addClass('select-on');
                } else {
                  $(el).parent().removeClass('select-on');
                }

                $timeout(function () {

                  if (el.title == '템플릿 전체') {
                    $(el).parent().attr('data-original-title', '쇼핑몰, 쇼핑몰(계정) 선택 후 검색가능');
                  } else {
                    $(el).parent().attr('data-original-title', el.title.trim());
                  }
                  $(el).attr('title', '');
                });
              });
              $('[data-popup="tooltip"]').tooltip();
            }
          });
        });

        scope.$watchCollection('searchDetailAddcolumn', function () {
          $timeout(function () {
            const $searchDetailAddcolumnElem = $(`#searchDetailAddcolumnDiv-${scope.$id}`);
            const $selectSearchElems = $searchDetailAddcolumnElem.find('.select-search');
            let nonDataFlag = false;
            let addcolType = '';
            let addcolNo = '';
            let addcolVal = '';
            let currentSelectElem = null;

            if ($selectSearchElems.length > 0) {
              // 일반 select와 스타일 맞춤
              $selectSearchElems.attr('data-container-css-class', 'select2-custom input-xs mb-5');

              // select2 검색기능 추가
              $selectSearchElems.select2({
                matcher: function (params, data) {
                  if (data.id === '') {
                    if ((Object.keys(params).includes('term') && !params.term?.trim()) || !Object.keys(params).includes('term')) {
                      return data;
                    }

                    return null;
                  }

                  if (!params.term?.trim()) {
                    nonDataFlag = false;

                    return data;
                  }

                  if (data.text?.toUpperCase().includes(params.term.toUpperCase())) {
                    nonDataFlag = false;

                    return data;
                  }

                  const additional = $(data.element).data('additional');
                  if (additional?.includes(params.term)) {
                    nonDataFlag = false;

                    return data;
                  }

                  nonDataFlag = true;
                  addcolType = $(data.element).data('type');
                  addcolNo = $(data.element).data('colno');
                  addcolVal = params.term?.trim();

                  return null;
                },
                dropdownCssClass: function (params) {
                  return params[0].id.includes('search-detail-sol_cate_no') ? 'select2-searchbar-sol_cate' : 'select2-searchbar-default';
                },
                dropdownAutoWidth: true
              });

              // 셀렉트 선택시 select-on클래스 추가
              $selectSearchElems.on('select2:select', function (e) {
                const $select_el = $(e.params.data.element);
                let _id = $select_el.parent().attr('id');

                _id = _id.replace('search-detail-', 'select2-search-detail-');

                if ($select_el.val() && $select_el.val() != '') {
                  $searchDetailAddcolumnElem.find(`#${_id}-container`).parent().addClass('select-on');
                } else {
                  $searchDetailAddcolumnElem.find(`#${_id}-container`).parent().removeClass('select-on');
                }
              });

              // 엔터 키 이벤트 처리 함수
              const handleEnterKey = (event) => {
                if (event.key === 'Enter') {
                  if (addcolVal.length < 2) {
                    commonSVC.showMessage('검색어는 최소 2글자 이상 입력해주세요.');
                  } else if (nonDataFlag) {
                    const params = {
                      type: 'product',
                      col_val: addcolVal,
                      addcol_type: addcolType,
                      addcol_no: addcolNo
                    };

                    columnModel.detailColumn(params).then(re => {
                      $timeout(() => {
                        const results = re.data.result[0]?.aggregated_val;

                        if (Object.keys(results).length) {
                          const type = results.type || '';
                          const colProdNo = results.prod_no || '';
                          const colNo = results.addcol_no || '';
                          const colName = results.col_name || '';
                          const colValue = results.col_val || '';

                          scope.searchDetailAddcolumn.forEach(row => {
                            if (row.item_key === `${colNo}`) {
                              row.item_list.push({
                                addcol_no: colNo,
                                col_name: colName,
                                col_val: colValue,
                                prod_no: colProdNo,
                                type,
                                use_yn: 1
                              });
                            }
                          });

                          // 드롭다운이 열린 상태로 검색어 유지
                          currentSelectElem.select2('open');
                          const $searchInput = currentSelectElem.data('select2').dropdown.$search || $('.select2-search__field');
                          $searchInput.val(addcolVal).trigger('input'); // 검색어를 유지한 채 드롭다운 재갱신
                        } else {
                          commonSVC.showMessage('검색 결과가 없습니다.');
                        }
                      });
                    });
                  }
                }
              };

              // select2:open 이벤트에서 검색 입력 필드의 keydown 이벤트 감지
              $selectSearchElems.on('select2:open', function (e) {
                currentSelectElem = $(e.target);
                const $searchInput = $('.select2-search__field');
                $searchInput.off('keydown').on('keydown', function (event) {
                  handleEnterKey(event);
                });
              });

            }
          });
        });

        /**
         * 상세검색 여부
         * @type {boolean}
         */
        scope.detailSearch = function () {
          scope.detailSearchFlag = scope.detailSearchFlag !== true;
          // 2017-12-29 chris 데이터 테이블 레이아웃 재계산
          if (scope.dtInstance && scope.dtInstance.calcLayout) {
            scope.dtInstance.calcLayout();
          }
          if (scope.dtInstance2 && scope.dtInstance2.calcLayout) {
            scope.dtInstance2.calcLayout();
          }
        };

        /**
         * 전체 선택/ 해제
         */
        scope.selectAllChecked = function () {
          scope.searchData.all_checked = !scope.searchData.all_checked;
        };

        /**
         * 멀티검색 구분 변경
         */
        scope.changeMultiType = function () {
          if (scope.searchForm.multi_type === 'shop') {
            scope.searchForm.multi_search_word = [];
          } else {
            scope.searchForm.multi_search_word = '';
          }

          scope.searchDetailMulti.forEach(row => row.select_value && (row.select_value = ''));
        };

        /**
         * 멀티검색 쇼핑몰 제거
         */
        scope.removeMultiShop = function (index) {
          scope.searchForm.multi_search_word.splice(index, 1);
        };

        // * @param reSearch // 해당 파라미터 사용 안해서 제거
        /**
         * 조건 검색
         * @param key
         * @param value
         * @param isMultiSearch
         */
        scope.selectSearchForm = function (key, values, item_list) {
          scope.searchForm.multi_search_word = scope.searchForm.multi_search_word || [];
          if (!Array.isArray(values)) {
            values = [ values ];
          }

          if (['multi_shop_id', 'multi_sub_sol_no', 'multi_group_no'].includes(key)) {
            scope.searchForm.multi_search_word = [];
          }

          if (key == 'sale_status') {
            scope.searchForm.sale_status = [];
          }

          for (const value of values) {
            if (key === 'multi_shop_id' && value && value != '') {

              const selShop = value.split('|');
              const shop_name = item_list.filter(function (o) {
                return o.shop_cd == selShop[0];
              })[0].shop_name;

              if (!scope.searchForm.multi_search_word.find(o => o.code === selShop[0] && o.id === selShop[1])) {
                scope.searchForm.multi_search_word.push({
                  code: selShop[0],
                  id: selShop[1],
                  shop_name: shop_name
                });
              }

              // 2018-09-09 Jacob 상세검색 멀티검색에서 리로드하도록 수정
              // 2018-09-19 Daniel 검색 눌렀을때만 검색되도록 수정
              // scope.searchFn.searchDo();
              continue;
            }

            if (key === 'multi_sub_sol_no') {
              if (!value) {
                return;
              }
              if (!scope.searchForm.multi_search_word.find(v => v === value)) {
                scope.searchForm.multi_search_word.push(value);
              }

              continue;
            }

            if (key === 'multi_deliver_vendor' && !scope.searchForm.multi_search_word.find(o => o.depot_no === value)) {
              if (scope.searchForm.multi_search_word.length > 9) {
                commonSVC.showMessage('배송처 멀티검색은 최대 10개까지 가능합니다.');
              }
              scope.searchForm.multi_search_word.push({
                depot_no: value,
                warehouse_name: item_list.filter(o => o.code == value)[0].warehouse_name
              });

              return;
            }

            if (key === 'multi_supplier_vendor' && !scope.searchForm.multi_search_word.find(o => o.supp_no === value)) {
              scope.searchForm.multi_search_word.push({
                supp_no: value,
                supp_name: item_list.filter(o => o.supp_no == value)[0].supp_name
              });

              return;
            }

            if (key === 'multi_tag' && !scope.searchForm.multi_search_word.find(o => o.tag_no === value)) {
              if (scope.searchForm.multi_search_word.length > 9) {
                commonSVC.showMessage('사용자태그 멀티검색은 최대 10개까지 가능합니다.');
              }
              scope.searchForm.multi_search_word.push({
                tag_no: value,
                tag_name: item_list.filter(o => o.tag_no == value)[0].tag_name
              });

              return;
            }

            if (key == 'state_name') {
              if (!value) {
                return;
              }
              if (scope.searchForm.multi_search_word.every(v => v != value)) {
                scope.searchForm.multi_search_word.push(value);
              }

              return;
            }

            if (key === 'sale_status') {
              scope.searchForm.sale_status.push(value);
            } else {
              scope.searchForm[key] = value;
            }

            // 기본 multi_search_word 는 string 이므로 값이 없는경우 빈값 처리를 해주어야 함.
            if (typeof scope.searchForm.multi_search_word === 'object' && !scope.searchForm.multi_search_word.length) {
              scope.searchForm.multi_search_word = '';
            }

            // 2018-10-15 chris 검색버튼 눌렀을때 검색되도록 변경
            // if (reSearch !== false ) {
            //   scope.searchFn.searchDo();
            // }

            // 2017-06-16 chris 상세검색 셀렉트로 변경되면서 주석
            // var idx = _.findIndex(scope.searchDetail, ['search_name', key]);console.log(idx);
            // scope.searchDetail[idx].select_value = value;
            // 2017-04-24 chris 선택한 값 텍스트 노출 처리
            // var select_text = $('.detailSearchDiv:eq('+idx+')').find('#'+scope.searchDetail[idx].search_name+value).text().trim() || scope.searchDetail[idx].sub_title;
            // scope.searchDetail[idx].select_text = select_text;
          }
        };

        /**
         * 날짜 부분 눌렀을 때 input trigger
         * @param event
         * @param calssName
         */
        scope.triggerDate = function (event, calssName) {
          $(event.target.parentNode).find(`.${calssName}`).trigger('click');
        };

        /**
         * 날짜 선택 버튼
         * @param event
         * @param calssName
         */
        scope.date_change = function (data) {
          $timeout(() => {
            const isLeapYear = new Date().getFullYear() % 4 === 0 ? 366 : 365;
            scope.searchForm.selectDate = data;
            scope.searchForm.change_sdate = false;
            scope.searchForm.change_edate = false;

            if (['prod_prodlist_grid_searchbar', 'online_product_grid_searchbar', 'sub_online_product_grid_searchbar', 'sub_prod_prodlist_grid_searchbar'].includes(scope.$parent.el_id) && scope.searchForm.search_key === 'all' && data === 'ALL') {
              scope.date_change('1YEARS');
              scope.isDisabled = true;
            }

            if (data == 'TODAY') {
              scope.searchForm.sdate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
              scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            } else if (data == 'PREDAY') {
              scope.searchForm.sdate = commonSVC.getDate(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd');
              scope.searchForm.edate = commonSVC.getDate(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd');
            } else if (data == '3DAYS') {
              scope.searchForm.sdate = commonSVC.getDate(new Date().setDate(new Date().getDate() - 3), 'yyyy-MM-dd');
              scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            } else if (data == 'PREVWEEK') {
              scope.searchForm.sdate = moment().subtract(1, 'weeks').startOf('week').subtract(-1, 'd').format('YYYY-MM-DD');
              scope.searchForm.edate = moment().subtract(1, 'weeks').endOf('week').subtract(-1, 'd').format('YYYY-MM-DD');
            } else if (data == 'CURWEEK') {
              scope.searchForm.sdate = moment().startOf('week').subtract(-1, 'd').format('YYYY-MM-DD');
              scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            } else if (data == 'WEEK') {
              scope.searchForm.sdate = commonSVC.getDate(new Date().setDate(new Date().getDate() - 7), 'yyyy-MM-dd');
              scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            } else if (data == 'PREVMONTH') {
              scope.searchForm.sdate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
              scope.searchForm.edate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            } else if (data == 'CURMONTH') {
              scope.searchForm.sdate = moment().startOf('month').format('YYYY-MM-DD');
              scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            } else if (data == '1MONTH') {
              scope.searchForm.sdate = commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM-dd');
              scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            } else if (data == '3MONTH') {
              scope.searchForm.sdate = commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 3), 'yyyy-MM-dd');
              scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            } else if (data == '6MONTH') {
              scope.searchForm.sdate = commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 6), 'yyyy-MM-dd');
              scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            } else if (data == '1YEARS') {
              scope.searchForm.sdate = commonSVC.getDate(new Date().setDate(new Date().getDate() - isLeapYear), 'yyyy-MM-dd');
              scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            } else if (data == 'ALL') {
              scope.searchForm.sdate = commonSVC.getDate(new Date('2001-01-01'), 'yyyy-MM-dd');
              scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            }
          });
        };

        /**
         * 리스트 출력 함수
         * @param event
         * @param calssName
         */
        scope.showList = function (row, list) {
          // 쇼핑몰 아이디는 별칭과 같이 표기하도록 처리
          return list[row.item_key] + (row.item_key == 'shop_id' && list.seller_nick != '' ? (`(${list.seller_nick})`) : '');
        };

        /**
         * 날짜 변경
         */
        scope.calendarChange = (type) => {
          scope.searchForm.selectDate = '';
          scope.searchForm['change_' + type] = true;
        };

        scope.changeSearchKey = () => {
          $timeout(() => {
            if (['prod_prodlist_grid_searchbar', 'online_product_grid_searchbar', 'sub_online_product_grid_searchbar', 'sub_prod_prodlist_grid_searchbar'].includes(scope.$parent.el_id) && scope.searchForm.search_key === 'all') {
              if (scope.searchForm.selectDate === 'ALL') {
                scope.date_change('1YEARS');
              }
              scope.isDisabled = true;
            } else {
              scope.isDisabled = false;
            }
          });
        };

        /**
         * 검색 태그설정
         */
        function setSearchTags() {
          try {
            $timeout(function () {
              const search_tags = [];
              let tags_msg = '';

              // 검색날짜
              if (scope.searchData.search_date_type && scope.searchForm.sdate) {
                search_tags.push(`${'<span>' + '<span>'}${elem.find('.tags-search-date:eq(0) option:selected').text()}</span>` + ` ${scope.searchForm.sdate} ~ ${scope.searchForm.edate}</span>`);
              } else if (scope.searchData.search_date_type && scope.searchForm.edate) {
                search_tags.push(`<span><span>${elem.find('.tags-search-date:eq(0) option:selected').text()}</span>` + ` ~ ${scope.searchForm.edate}</span>`);
              }

              // 키워드
              if (scope.searchForm.search_word) {
                search_tags.push(`${'<span>' + '<span>' + '키워드('}${elem.find('.tags-search-key:eq(0) option:selected').text()} ${elem.find('.tags-search-key:eq(1) option:selected').text()}) : ` + `</span>${scope.searchForm.search_word} <a href="#" ng-click="searchTagsDel('searchForm', 'search_word')"><i class="icon-cross3"></i></a></span>`);
              }

              // 상세검색
              _.each(scope.searchDetail, function (row, idx) {
                if (typeof scope.searchForm[row.search_name] === 'string' && scope.searchForm[row.search_name] && scope.searchForm[row.search_name] !== '__ALL__' && scope.searchForm[row.search_name] !== 'all') {
                  search_tags.push(`${'<span>' + '<span>'}${row.title} : ` + `</span>${elem.find(`.tags-search-detail:eq(${idx}) option:selected`).text()} <a href="#" ng-click="searchTagsDel('searchDetail', '${row.search_name}')"><i class="icon-cross3"></i></a></span>`);
                }
              });

              tags_msg = `현재 리스트는 ${search_tags.join(' ') || '<span>전체</span>'} 검색조건 입니다.`;
              elem.parent().parent().parent().parent().parent().parent().find('.a-content-search').html(tags_msg);
              $compile($('.a-content-search'))(scope);
            });
          } catch (err) {
            console.log(err);
          }

        }
        setSearchTags();

        // 그리드 검색시 태그 설정값 적용
        if (scope.dtInstance) {
          scope.dtInstance.setSearchTags = function () {
            setSearchTags();
          };
        }
        if (scope.dtInstance2) {
          scope.dtInstance2.setSearchTags = function () {
            setSearchTags();
          };
        }

        // scope.$parent.$parent.setSearchTags = function () {
        //   setSearchTags();
        // };
      }
    };
  });