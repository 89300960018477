import React, { CSSProperties, useEffect, useState } from 'react';
import * as imageHostModel from '../../models/imageHosting';
import { showConfirm, showToaster } from '../../utills/common';
import FTPManager from '../../pages/imageHosting/FtpManager';
import CommonModal from '../common/commonModal';

interface Folder {
  path: string;
  name: string;
  children: Folder[];
}

interface TreeViewProps {
  openFtpManager: (result?: any) => void;
  currentFtpInfo: { ftp_no: number, ftp_name: string, remotePath: string } | null;
  setCurrentFtpInfo: React.Dispatch<React.SetStateAction<{
    ftp_no: number;
    ftp_name: string;
    remotePath: string;
    host: string;
  }>>;
  folderList: any;
  folderPath: string[];
  setFolderPath: React.Dispatch<React.SetStateAction<string[]>>;
  viewPage: 'file' | 'log';
  setViewPage: React.Dispatch<React.SetStateAction<'file' | 'log'>>;
  setFolderList: React.Dispatch<React.SetStateAction<any>>;
  setFileData: React.Dispatch<React.SetStateAction<any>>;
}

const TreeView: React.FC<TreeViewProps> = ({
  openFtpManager, currentFtpInfo, setCurrentFtpInfo, folderList, folderPath, setFolderPath, viewPage, setViewPage, setFolderList, setFileData
}) => {
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
  const [selected, setSelected] = useState<string | null>(null);
  const [ftpManagerIsOpen, setFtpManagerIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (folderPath.length) {
      const path = folderPath.join('/');
      const previousPath = folderPath.slice(0, folderPath.length - 1).join('/');

      handleSelect(path);

      setExpanded((prevExpanded) => ({
        ...prevExpanded,
        [previousPath]: true,
        [path]: true,
      }));
    } else {
      handleSelect('모든 폴더');
    }
  }, [folderPath]);

  /**
   * 더블클릭 시 폴더 펼치기
   */
  const toggleExpand = (path: string) => {
    if (path !== '모든 폴더') {
      setFolderPath(path.split('/'));
    } else {
      setFolderPath([]);
    }
  };

  /**
   * 폴더 선택
   */
  const handleSelect = (path: string) => {
    setSelected(path);
  };

  /**
   * 폴더 렌더링
   */
  const renderFolder = (folder: Folder) => {
    const isSelected = selected === folder.path;
    const isExpanded = folder.path === '모든 폴더' ? true : expanded[folder.path];

    return (
      <li key={folder.path}>
        <div
          onDoubleClick={() => {
            toggleExpand(folder.path);
            setViewPage('file');
          }}
          onClick={() => {
            handleSelect(folder.path);
          }}
          style={{
            cursor: 'pointer',
            fontWeight: isSelected ? 'bold' : 'normal',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: isSelected ? '#e6f7ff' : 'transparent',
            borderRadius: '4px',
            padding: '5px'
          }}
        >
          <span style={{ marginRight: '5px' }}>
            {isExpanded ?
              <i
                className="fa fa-folder-open"
                style={{ color: '#FFAD27' }}
              />
              :
              <i
                className="fa fa-folder"
                style={{ color: '#FFAD27' }}
              />
            }
          </span>
          {folder.name}
        </div>
        {isExpanded && folder.children.length > 0 && (
          <ul style={{ listStyle: 'none', paddingLeft: '15px', marginTop: '5px' }}>
            {folder.children.map((child) => renderFolder(child))}
          </ul>
        )}
      </li>
    );
  };

  /**
   * FTP 연결 끊기
   */
  const disconnectFtp = async () => {
    const isConfirmed = await showConfirm('접속종료', 'FTP 접속을 종료하시겠습니까?');

    if (isConfirmed) {
      const res = await imageHostModel.disconnectFtp(currentFtpInfo?.ftp_no);

      if (res.results === 'success') {
        showToaster('success', '', 'FTP 접속이 종료되었습니다.');
        setCurrentFtpInfo(null);
        setFolderList({});
        setFileData([]);
      } else {
        showToaster('error', '', res.error || (res.messages?.length ? res.messages[0] : 'FTP 접속끊기 중 오류가 발생했습니다.'));
      }
    }
  };

  /**
   * FTP Manager 닫기
   */
  const ftpManagerClose = (result: any) => {
    setFtpManagerIsOpen(false);

    openFtpManager(result);
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.folderListBox}>
          {currentFtpInfo && (
          // <ul style={styles.folderList}>
          //   <li>
            <ul style={styles.folderList}>
              {renderFolder(folderList)}
            </ul>
          //   </li>
          // </ul>
          )}
        </div>
        <div style={styles.btnGroup}>
          {/* 연결없음 일 때 UI */}
          {!currentFtpInfo && (
            <button
              className="btn"
              style={styles.ftpConnectBtn}
              onClick={() => setFtpManagerIsOpen(true)}
            >
          FTP 접속하기
            </button>
          )}

          {/* 연결일 때 UI */}
          {currentFtpInfo && (
            <>
              <div style={styles.ftpConnectLabel}>
                {currentFtpInfo.ftp_name} 접속 중
              </div>
              <button
                className="btn"
                style={styles.ftpDisconnectBtn}
                onClick={disconnectFtp}
              >
              FTP 접속끊기
              </button>
              {viewPage === 'file' && (
                <button
                  style={styles.ftpLogBtn}
                  onClick={() => setViewPage('log')}
                >
              로그 관리
                </button>
              )}
              {viewPage === 'log' && (
                <button
                  style={{ ...styles.ftpLogBtn, color: '#545454' }}
                >
              로그 관리
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <CommonModal
        isOpen={ftpManagerIsOpen}
        size="md"
        backdrop={false}
        scrollable={true}
        escClose={false}
        handleClose={ftpManagerClose}
        component={FTPManager}
        data={{}}
      />
    </>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    // width: '212px',
    // borderRight: '1px solid #ddd',
    height: '100%',
    background: '#fff'
  },
  folderListBox: {
    flex: '1',
    whiteSpace: 'nowrap',     // 줄바꿈 설정
    overflowX: 'auto',       // 좌우 스크롤 가능하도록 설정
    textOverflow: 'ellipsis', // 넘치는 내용 생략 부호로 표시
  },
  folderList: {
    listStyle: 'none',
    margin: '0',
    padding: '10px',
  },
  btnGroup: {
    padding: '13px 16px',
    background: '#E8F0F6',
  },
  ftpConnectBtn: {
    margin: '3px 0',
    padding: '0',
    width: '100%',
    height: '26px',
    lineHeight: '24px',
    fontSize: '12px',
    color: '#fff',
    fontWeight: '400',
    letterSpacing: '-0.36px',
    background: '#1E88E5',
    border: 'none',
    cursor: 'pointer'
  },
  ftpConnectLabel: {
    margin: '3px 0',
    width: '100%',
    height: '26px',
    lineHeight: '24px',
    fontSize: '12px',
    color: '#fff',
    fontWeight: '400',
    letterSpacing: '-0.36px',
    textAlign: 'center',
    background: '#1E88E5',
  },
  ftpDisconnectBtn: {
    margin: '3px 0',
    padding: '0',
    width: '100%',
    height: '26px',
    lineHeight: '24px',
    fontSize: '12px',
    color: '#545454',
    fontWeight: '400',
    letterSpacing: '-0.36px',
    background: '#FFF',
    border: '1px solid #E5E5E5',
    cursor: 'pointer'
  },
  ftpLogBtn: {
    margin: '13px 0 3px 0',
    padding: '0',
    width: '100%',
    height: '14px',
    lineHeight: '12px',
    fontSize: '12px',
    color: '#1E88E5',
    fontWeight: '400',
    letterSpacing: '-0.36px',
    background: 'transparent',
    border: '0',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
};

export default TreeView;