'use strict';

angular.module('gmpApp')
  /**
   * 판매금액 관리 매니저 모달
   * 2020-03-04 Amelia
   */
  .controller('SupplyPriceManagerCtrl', function ($scope, $rootScope, data, commonSVC, gettextCatalog, $uibModalInstance, settings, localStorageService, shipmentModel, $sce, userInfo, addColumnList) {
    $scope.showDesc = true; // 모달 상단 설명 접기 변수
    $scope.ordCnt = data.uniqList.length; // 선택된 주문 건수
    $scope.apply_range = $scope.ordCnt ? 'select' : 'all'; // 공급가 규칙 적용 범위 (select: 선택한주문 / all: 전체주문)

    $scope.showDesc = localStorageService.get('supplyPriceShowDescYn') || 'Y'; // Description 칸

    let isApplied = false; // 공급가 규칙 적용 여부

    const { uniqList } = data; // 선택된 주문 uniq

    /* 검색 및 버튼 관련 변수 */
    const search = {
      searchData: {
        all_checked: false,  // 전체선택 여부
        selectCount: 0,      // 검색전체 건수
        totalCount: 0,       // 검색전체 건수
        viewCount: 1,        // 몇번째 부터 시작인지 보여줌
        showCount: 50,
      }
    };

    $scope.searchData = angular.copy(search.searchData);
    $scope.searchBtn = {
      table_actions: [
        {
          label: gettextCatalog.getString('추가'),
          add_class: 'a-btn-blue',
          action: () => {
            $scope.addRule();
          },
        },
        {
          label: gettextCatalog.getString('수정'),
          add_class: 'a-btn-blue',
          disable: () => {
            return $scope.searchData.selectCount !== 1;
          },
          action: () => {
            $scope.editRule();
          },
        },
        {
          label: gettextCatalog.getString('삭제'),
          add_class: 'a-btn-blue',
          disable: () => {
            return !$scope.searchData.selectCount;
          },
          action: () => {
            $scope.deleteRule();
          },
        },
      ]
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      gridHeight: 450,
      initPagingSize: 50,
      pinningColumns: [],
      emptyText: $sce.trustAsHtml('<p style="font-weight:bold;color:#000;font-size:13px;">[추가] 버튼을 눌러 규칙을 생성할 수 있습니다.</p>'),
      alignCenterColumns: ['supply_price_rule_no', 'rule_type', 'apply_type', 'use_yn', 'ol_shop_cnt', 'formula', 'formula_target', 'wdate', 'last_apply_time', 'shop_ids', 'wname'],
      notResizingColumns: [],
      notMovingColumns: [],
      defaultSortingColumns: ['supply_price_rule_no'],
      notSortingColumns: ['wdate', 'wname', 'shop_ids', 'rule_type', 'fee_rate', 'apply_type', 'formula'],
      alignRightColumns: ['fee_rate'],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/price-rules`,
        requestWillAction: (data) => {
          if (data.orderby.includes('shop_name')) {
            data.orderby = `ss.seq ${data.orderby.split(' ')[1]}, ${data.orderby}`;
          }

          return data;
        },
        requestDidAction: result => {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'supply_price_rule_no',
          title: 'No',
          width: 60
        },
        {
          key: 'shop_name',
          title: '쇼핑몰',
          width: 130,
          template: row => {
            const shop_cd = row.shop_cd || row.ol_shop_cd;

            if (row.shop_name) {
              const img = (!shop_cd.startsWith('U') && shop_cd !== 'A000') ? `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` : '';

              return `${img}${row.shop_name}`;
            } else {
              return '';
            }
          }
        },
        {
          key: 'shop_ids',
          title: '쇼핑몰계정',
          width: 120,
          template: row => {
            return row.shop_cd !== 'A000' ? row.shop_ids : '직접입력';
          }
        },
        {
          key: 'rule_type',
          title: '적용범위',
          width: 80,
        },
        {
          key: 'formula_target',
          title: '적용대상',
          width: 150,
          template: function(row) {
            let target;
            switch (row.formula_target) {
              case 'sales':
                target = '금액';
                break;
              case 'pay_amt':
                target = '실결제금액';
                break;
              case 'shop_supply_price':
                target = '공급가';
                break;
              case 'discount_amt':
                target = '할인금액';
                break;
              default:
                target = row.formula_target.replace('pa_addcol_', '');
            }

            return target;
          }
        },
        {
          key: 'formula',
          title: '수식',
          width: 150,
          template: row => {
            if (row.formula.includes('.') && row.formula_target === 'shop_supply_price') {
              return `수수료율 ${row.formula}%`;
            } else {
              return row.formula;
            }
          }
        },
        {
          key: 'ol_shop_cnt',
          title: '특정상품코드',
          width: 90,
          template: ({ ol_shop_cnt }) => {
            return ol_shop_cnt ? `${ol_shop_cnt}건` : '-';
          }
        },
        {
          key: 'apply_type',
          title: '적용시점',
          width: 60,
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 110,
          filter: 'dateValid'
        },
        {
          key: 'wname',
          title: '등록자',
          width: 80
        },
        {
          key: 'last_apply_time',
          title: '최근적용시간',
          width: 120,
          filter: 'dateValid'
        },
        {
          key: 'use_yn',
          title: '사용여부',
          width: 80,
          template: (row) => {
            row.use_yn = !!row.use_yn;

            return '<pa-ui-switch ng-model="row.entity.use_yn" fn="grid.appScope.changeDo(row.entity)"></pa-ui-switch>';
          }
        },
      ]
    };

    $scope.searchFn = {
      searchDo: () => {
        $scope.searchDo(true, true);
      },
      // 몇 건씩 볼지 결정
      changeLen: () => {
        $scope.grid.methods.length($scope.searchData.showCount);
      }
    };

    $scope.searchDo = (refresh = true, noDelay) => {
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 설명 Div 접기 변경
     */
    $scope.changeShowDesc = () => {
      $scope.showDesc = $scope.showDesc === 'Y' ? 'N' : 'Y';
      localStorageService.set('supplyPriceShowDescYn', $scope.showDesc);
    };

    /**
     * tags-input 디렉티브 미사용 multiselect tag는 백스페이스로 삭제 시 텍스트로 변환되는 문제발생 해결위해 추가
     */
    $.fn.select2.amd.require(['select2/selection/search'], (Search) => {
      const oldRemoveChoice = Search.prototype.searchRemoveChoice;
      Search.prototype.searchRemoveChoice = function () {
        oldRemoveChoice.apply(this, arguments);
        this.$search.val('');
      };
    });
    /**
     * 규칙 추가
     */
    $scope.addRule = () => {
      const resolve = {
        data: {
          type: 'add',
          ...(userInfo.user?.sol_ser.includes('addcol') && addColumnList.data?.results.length && { addColList: addColumnList.data?.results.filter(col => col.input_type === 'int') }),
          shop_id_view_type: data.shop_id_view_type
        },
        warehouseList: warehouseModel => {
          return warehouseModel.warehouseList();
        },
      };

      const modal = commonSVC.openModal('x3g', resolve, 'SupplyPriceRuleAddCtrl', 'views/order/shipment/modals/supply_price_rule_add.html');

      modal.result.then(res => {
        if (res === 'success') {
          $scope.searchDo();
        }
      });
    };

    /**
     * 사용여부 변경 (리스트에서)
     */
    $scope.changeDo = async ({ supply_price_rule_no, use_yn }) => {
      try {
        await shipmentModel.editSupplyPriceRule({ supply_price_rule_no, use_yn, change_use_yn: true });

        commonSVC.showToaster('success', '', '판매금액 관리 규칙 사용여부가 수정되었습니다.');
      } catch (error) {
        commonSVC.showToaster('error', '', '판매금액 관리 규칙 사용여부 수정에 실패하였습니다.');

        return false;
      }
    };

    /**
     * 규칙 삭제
     */
    $scope.deleteRule = () => {
      const selectedRules = $scope.grid.methods.selectedData('supply_price_rule_no');

      if (!selectedRules.length) {
        commonSVC.showMessage('선택된 규칙이 없습니다.');

        return false;
      }
      commonSVC.showConfirmHtml('판매금액 관리 규칙 삭제', `선택하신 규칙 <span style="color:rgb(249,95,62);font-weight:bold;">${selectedRules.length}건</span>을 삭제합니다.<br/><br/>삭제된 규칙 및 주문에 적용된 금액은 복구되지 않습니다.<br/>선택하신 규칙을 삭제하시겠습니까?`)
        .then(async (confirm) => {
          if (confirm) {
            const re = await shipmentModel.deleteSupplyPriceRule({ ruleNoList: selectedRules });

            if (re.data.results === 'success') {
              commonSVC.showToaster('success', '성공', '판매금액 관리 규칙이 삭제되었습니다.');
              $scope.searchFn.searchDo();
            } else {
              commonSVC.showToaster('error', '', '판매금액 관리 규칙 삭제에 실패하였습니다.');
            }
          }
        });
    };

    /**
     * 규칙 수정
     */
    $scope.editRule = () => {
      const selectedRules = $scope.grid.methods.selectedData('supply_price_rule_no');

      if (!selectedRules.length) {
        commonSVC.showMessage('선택된 규칙이 없습니다.');

        return false;
      } else if (selectedRules.length > 1) {
        commonSVC.showMessage('1개의 규칙만 선택해주십시오.');

        return false;
      }

      const resolve = {
        data: {
          supply_price_rule_no: selectedRules[0],
          type: 'edit',
          ...(userInfo.user?.sol_ser.includes('addcol') && addColumnList.data?.results.length && { addColList: addColumnList.data?.results.filter(col => col.input_type === 'int') }),
          shop_id_view_type: data.shop_id_view_type
        },
        warehouseList: warehouseModel => {
          return warehouseModel.warehouseList();
        },
      };

      const modal = commonSVC.openModal('x3g', resolve, 'SupplyPriceRuleAddCtrl', 'views/order/shipment/modals/supply_price_rule_add.html');

      modal.result.then(res => {
        if (res === 'success') {
          $scope.searchDo();
        }
      });
    };

    /**
     * 선택된 규칙 카운트
     */
    $scope.applyRuleCnt = () => {
      const selectList = $scope.grid.methods.selectedData('use_yn');
      $scope.selectedRuleCnt = selectList.filter(use_yn => use_yn).length;

      return selectList.length && selectList?.every(use_yn => use_yn);
    };

    /**
     * 판매금액 관리 규칙 적용
     */
    $scope.apply = async () => {
      const selectedRules = $scope.grid.methods.selectedData(['supply_price_rule_no', 'use_yn']);

      const params = {
        selectedRuleNos: selectedRules.filter(({ use_yn }) => use_yn).map(({ supply_price_rule_no }) => supply_price_rule_no),
        uniqList: $scope.apply_range === 'all' ? [] : uniqList
      };

      try {
        const re = await shipmentModel.applySupplyPriceRule(params);

        if (re.data.results === 'success') {
          commonSVC.showToaster('success', '', '판매금액 규칙이 적용되었습니다.');
          isApplied = true;
        } else {
          commonSVC.showToaster('error', '', '판매금액 규칙 적용에 실패하였습니다.');
        }
      } catch (err) {
        if (err.data.messages?.[0] === 'no matched rule') {
          commonSVC.showToaster('error', '', '적용 가능한 주문건이 없습니다.');
        } else {
          commonSVC.showToaster('error', '', '판매금액 규칙 적용에 실패하였습니다.');
        }
      }
    };

    $scope.cancel = () => {
      $uibModalInstance.close(isApplied ? 'success' : 'cancel');
    };
  });
