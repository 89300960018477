import React from 'react';

const ValidationErrorLabel = ({ message }: { message: string }) => {
  return (
    <div className="validation-error-label">
      {message}
    </div>
  );
};

export default ValidationErrorLabel;