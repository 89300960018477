'use strict';

angular.module('gmpApp')
  .controller('AltalkSendCtrl', function(
    $scope, $rootScope, data, $timeout, commonSVC, settings, gettextCatalog, $uibModalInstance, userInfo, atalkModel
  ) {
    function init () {
      $timeout(async () => {

        $(document).ready(function() {
          $('#direct-tag .bootstrap-tagsinput input').on('input', function() {
            const inputVal = $(this).val();
            const regex = /^[0-9-,]*$/;

            if (!regex.test(inputVal)) {
              // 입력이 패턴과 일치하지 않을 때 처리할 내용
              $(this).val(inputVal.replace(/[^0-9-,]/g, ''));
            }
          });

        });
        $scope.data = angular.copy(data);
        // 잔여 알림톡 카운트
        const atalkCnt = (await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/common/additional-service-cnt`)).data?.atalkCnt;
        $scope.remainCnt = (atalkCnt.remain - atalkCnt.estimated) < 0 ? 0 : (atalkCnt.remain - atalkCnt.estimated);

        // 그룹 리스트 조회
        $scope.groupList = (await commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/atalk/group-list`)).data?.results;

        // 그룹리스트 멀티 셀렉트 처리
        if ($scope.groupList.length) {
          $scope.memList = (await commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/atalk/group-detail`)).data?.result;
        }

        $scope.item = $scope.groupList.map(g => ({ value: g.uniq, title: `${g.group_name}  (${g.cnt}명)`, label: `${g.group_name}  (${g.cnt}명)` }));

        const searchDetailMulti = [
          {
            title: gettextCatalog.getString('그룹 선택'),
            search_name: 'multi_group_no',
            item_list: angular.copy($scope.groupList.sort(function(group) {
              return group.mdate;
            })),
            item_key: 'uniq',
            item_value: 'group_name'
          }
        ];

        $scope.searchDetailMulti = angular.copy(searchDetailMulti);

        // 알림톡 개별 발송 모달 변수 초기화
        $scope.altalk = {
          sms_send_yn: '0',
          selectedMin: '',
          selectedHour: '',
          ampm: '0',
          send_type: (data.open !== 'top' && data.ord?.length) ? 'select_ord' : 'direct',
          send_time: 'immediately',
          altalk_send_date: moment().format('YYYY-MM-DD'),
          send_type_bundle_info: true,
          ord_user_list: 'order',
          receive_list: []
        };
        $scope.template = {};   // 템플릿 정보 변수

        // 주문 선택 후 알림톡 발송 모달 오픈 시 대상 리스트 초기화
        if (data.ord && data.ord?.length) {
          $scope.altalk.send_type = 'select_ord';
          $scope.altalk.ord_user_list = 'order';
          $scope.altalk.receive_list = await $scope.checkTarget();
          await $scope.targetToRepreHtel();
        }
        [$scope.nowHour, $scope.nowMin] = moment().format('HH:mm').split(':'); // 현재 시간(시간 비활성화를 위해)
        if (+$scope.nowHour > 11) {
          $scope.noonCheck = true;
        }

        $scope.HOUR = Array.from({ length: 12 }, (_, index) => index + 1).map(h => ({ hour: h, name: h === 12 ? `${h}(자정)` : h, disabled: (h) < +$scope.nowHour }));
        $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(m => ({ min: `${m === 0 ? '00' : m}`, disabled: true }));

        // 플러스 친구ID 선택 및 비활성화 처리
        if ($scope.data.plusIdList.length) {
          $scope.data.plusIdList = $scope.data.plusIdList.map(account => ({ ...account, name: `@${account.name}` }));
          $scope.plusIdCheck = true;
          $scope.template.plus_friend_id = $scope.data.plusIdList[0].id;
        } else {
          $scope.plusIdCheck = false;
        }

        // 솔루션 상단 바 진입여부
        $scope.isTop = data.open === 'top';
        $scope.reqCheck = false;
        $scope.template_tab = 0;

        $timeout(() => {});
      });
    }

    init();

    /**
     * 예약불가 일시 비활성화 처리 함수
     */
    $scope.timeCheck = () => {
      const now = moment().format('YYYY-MM-DD');
      if (now < $scope.altalk.altalk_send_date) {
        $scope.HOUR = Array.from({ length: 12 }, (_, index) => index + 1).map(h => {
          const name = ($scope.altalk.ampm === '0' && h === 12) ? `${h}(자정)` : ($scope.altalk.ampm === '1' && h === 12) ? `${h}(정오)` : h;

          return { hour: h, name, disabled: false };
        });
        $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(m => ({ min: `${m === 0 ? '00' : m}`, disabled: false }));
      } else {
        if ($scope.altalk.ampm === '0') {
          $scope.HOUR = Array.from({ length: 12 }, (_, index) => index + 1).map(h => ({ hour: h, disabled: h < +$scope.nowHour, name: h === 12 ? `${h}(자정)` : h }));
          if ($scope.altalk.selectedHour) {
            if (+$scope.altalk.selectedHour > +$scope.nowHour) {
              $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(m => ({ min: `${m === 0 ? '00' : m}`, disabled: false }));
            } else if (+$scope.altalk.selectedHour === +$scope.nowHour) {
              $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(m => {

                return { min: `${m === 0 ? '00' : m}`, disabled: m <= +$scope.nowMin };
              });
            } else {
              $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(m => ({ min: `${m === 0 ? '00' : m}`, disabled: true }));
            }
          } else {
            if (+$scope.altalk.selectedHour > +$scope.nowHour) {
              $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(m => ({ min: `${m === 0 ? '00' : m}`, disabled: false }));
            } else if (+$scope.altalk.selectedHour === +$scope.nowHour) {
              $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(m => ({ min: `${m === 0 ? '00' : m}`, disabled: m <= +$scope.nowMin }));
            } else {
              $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(m => ({ min: `${m === 0 ? '00' : m}`, disabled: true }));
            }
          }
        } else {
          $scope.HOUR = Array.from({ length: 12 }, (_, index) => index + 1).map(h => {
            const converHour = (h === 12) ? h : (h + 12);

            return { hour: h, disabled: converHour < +$scope.nowHour, name: converHour === 12 ? `${h}(정오)` : h };
          });

          if ($scope.altalk.selectedHour) {
            if ((+$scope.altalk.selectedHour + 12 > (+$scope.nowHour === 0 ? 12 : +$scope.nowHour))) {
              $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(m => ({ min: `${m === 0 ? '00' : m}`, disabled: false }));
            } else if ((+$scope.altalk.selectedHour === 12 ? 12 : +$scope.altalk.selectedHour + 12) === +$scope.nowHour) {
              $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(m => {
                return { min: `${m === 0 ? '00' : m}`, disabled: m <= +$scope.nowMin };
              });
            } else {
              $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(m => ({ min: `${m === 0 ? '00' : m}`, disabled: true }));
            }
          } else {
            $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(m => ({ min: `${m === 0 ? '00' : m}`, disabled: true }));
          }
        }
      }

      $scope.reqCheckF();
    };

    /**
     * 알림톡 충전하기 버튼 함수
     */
    $scope.chargeAtalkCnt = function() {
      if (!(userInfo.user.auth_type === '총괄관리자' || userInfo.permission?.pay?.use_yn)) {
        return commonSVC.showToaster('error', '앗! 사용 권한이 없네요.', '요청하신 기능은 사용 권한이 필요합니다.\n PLAYAUTO 2.0 관리자에게 문의해보세요!');
      }
      $rootScope.openPayReq();
      $uibModalInstance.dismiss('cancel');
    };

    $scope.addTemplate = () => {
      commonSVC.openModal('x3g', { data: { plusIdList: $scope.data.plusIdList } }, 'AltalkTemplateCtrl', 'views/alimtalk/modals/altalk_template_add.html');
    };

    /**
     * 알림톡 발신프로필 연동 모달로 이동
     */
    $scope.addProfile = () => {
      const modal = commonSVC.openModal('lg', {}, 'AltalkLinkCtrl', 'views/alimtalk/modals/link.html');

      modal.result.then(async (re) => {
        if (re === 'success') {
          $scope.data.plusIdList = (await atalkModel.accountList({ name: $rootScope.user_profile.sol_no })).data?.result;
          $scope.data.plusIdList = $scope.data.plusIdList.map(account => ({ ...account, name: `@${account.name}` }));
          $scope.template.plus_friend_id = $scope.data.plusIdList[0].id;

          $timeout(() => {});
        }
      });
    };

    $scope.close = () => {
      $uibModalInstance.close();
    };

    /**
     * 수정 버튼 활성화 처리
     */
    $scope.reqCheckF = () => {
      if ($scope.template && $scope.altalk.reg_name && $scope.selectTemplate && $scope.altalk.receive_list?.length && (($scope.altalk.send_time === 'scheduled' && $scope.altalk.selectedHour && $scope.altalk.selectedMin) || $scope.altalk.send_time === 'immediately')) {
        $scope.reqCheck = true;
      } else {
        $scope.reqCheck = false;
      }
    };

    /**
     * 템플릿 검색 함수
     */
    $scope.searchAltalkTemplate = async (templateTitle) => {
      const templateList = await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/templateList`, { talk_id: $scope.template.plus_friend_id.toString(), search_key: 'template_name', search_word: templateTitle, status: true, orderby: 'a.created_at desc', sol_no: $rootScope.user_profile.sol_no, exposure_yn: true });
      $scope.templateList = (templateList.data?.results).filter(r => r.inspection_status === 'APR' && r.type !== 'G'); // 개인 템플릿
      $scope.basicTemplateList = (templateList.data?.results).filter(r => r.inspection_status === 'APR' && r.type === 'G');  // 제공 템플릿

      $timeout(() => {});
    };

    /**
     * 템플릿 조회 함수
     */
    $scope.clickTemplate = async () => {
      const templateList = await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/templateList`, { talk_id: $scope.template.plus_friend_id.toString(), status: true, orderby: 'a.created_at desc', sol_no: $rootScope.user_profile.sol_no, exposure_yn: true });
      $scope.basicTemplateList = (templateList.data?.results)?.filter(r => r.inspection_status === 'APR' && r.type === 'G');  // 제공 템플릿
      $scope.templateList = (templateList.data?.results)?.filter(r => r.inspection_status === 'APR' && r.type !== 'G');   // 개인 템플릿

      $scope.isTemplateListOpen = !$scope.isTemplateListOpen;

      $timeout(() => {});
    };

    // 템플릿 선택 함수
    $scope.setTemplate = async (row) => {
      $scope.isTemplateListOpen = false;
      const template = (await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/templateDetail`, { template_no: row.id })).data?.result;
      $scope.selectTemplate = template;
      document.getElementById('preview_add_box').innerHTML = '';

      // 부가정보 여부
      if ($scope.selectTemplate.template_extra && $scope.selectTemplate.template_extra !== 'null') {
        document.getElementById('preview_add_box').innerHTML = $scope.selectTemplate.template_extra?.split('\n').map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('');
      }

      // 불러온 템플릿에 버튼이 있는 경우
      if ($scope.selectTemplate.buttons) {
        $scope.selectTemplate.buttons = JSON.parse(`[${$scope.selectTemplate.buttons}]`);

        // 템플릿 내 채널추가 버튼 여부
        $scope.use_add_channel_yn = $scope.selectTemplate.buttons.some(btn => btn.type === 'AC');
      }
      document.getElementById('preview_box').innerHTML = $scope.selectTemplate.content.split('\n').map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('');
      if ($scope.selectTemplate.template_img_name && $scope.selectTemplate.template_emphasize_type === 'IMAGE') {
        const preview = document.getElementById('emphasis_img');
        const currentStyle = preview.getAttribute('style') || '';
        preview.setAttribute('style', `${currentStyle} background-image: url('${$scope.selectTemplate.template_img_name}'); background-size: 100%;`);
      }

      if ($scope.template && $scope.altalk.reg_name && $scope.selectTemplate && $scope.altalk.receive_list?.length) {
        $scope.reqCheck = true;
      }

      $timeout(() => {});
    };

    /**
     * 발송대상 초기화 함수
     */
    $scope.initReceiveList = () => {
      $scope.altalk.receive_list = [];
      $scope.altalk.send_user_list = '';
    };

    /**
     * 발송 대상 처리 함수
     * 주문선택 시 주문자, 수신자, 주문자/수신자에 따라 대상 처리
     * 발송 대상의 번호는 htel에 담아서 백엔드로 전달 처리
     * 중복 발송 제거(주문자와 수신자 번호 같은 경우)
     */
    $scope.checkTarget = async (isGroup) => {
      if (!isGroup) {
        if (data.ord) {
          $scope.altalk.receive_list = angular.copy(data.ord).map(ord => ({ ...ord, to_htel: ord.to_htel?.replace(/-/g, ''), order_htel: ord.order_htel?.replace(/-/g, '') }));
        }
        // 발송대상 : 주문자/수신자 핸드폰 번호인 경우 처리
        if ($scope.altalk.send_type === 'select_ord' && data.ord.length) {
          if ($scope.altalk.ord_user_list === 'all') {
            const diffHtelOrdUniq = [];
            $scope.altalk.receive_list.forEach(ord => {
              if (ord.order_htel !== ord.to_htel) {
                diffHtelOrdUniq.push(ord.uniq);
                $scope.altalk.receive_list.push({ ...ord, htel: ord.order_htel });
                $scope.altalk.receive_list.push({ ...ord, htel: ord.to_htel });

                const delIdx = $scope.altalk.receive_list.findIndex(v => v.uniq === ord.uniq);
                $scope.altalk.receive_list = $scope.altalk.receive_list.filter((v, idx) => delIdx !== idx);
              }
            });

            $scope.altalk.receive_list = $scope.altalk.receive_list.map(v => {
              if (!v.htel) {
                return { ...v, htel: v.order_htel || v.to_htel };
              }

              return { ...v };
            });
          } else if ($scope.altalk.ord_user_list === 'order') {
            $scope.altalk.receive_list = $scope.altalk.receive_list.map(v => ({ ...v, htel: v.order_htel }));
          } else if ($scope.altalk.ord_user_list === 'to') {
            $scope.altalk.receive_list = $scope.altalk.receive_list.map(v => ({ ...v, htel: v.to_htel }));
          }
        } else {
          $scope.altalk.receive_list = Array.from(new Set($scope.altalk.send_user_list.split(',').map(v => v.replaceAll('-', '')))).map(num => ({ htel: num }));
        }
      } else {
        $scope.altalk.receive_list = Array.from(new Set($scope.memList.map(mem => {
          if ($scope.searchDetailMulti[0].select_value.includes(mem.custgroup_no.toString())) {
            return mem.phone_num;
          }

          return;
        }))).filter(Boolean).map(v => ({ htel: v }));
      }

      $scope.canSend = $scope.remainCnt >= $scope.altalk.receive_list.length;

      if ($scope.template && $scope.altalk.reg_name && $scope.selectTemplate && $scope.altalk.receive_list?.length) {
        $scope.reqCheck = true;
      } else {
        $scope.reqCheck = false;
      }

      return $scope.altalk.receive_list;
    };

    /**
     * 묶음주문 대표정보 발송여부 클릭 시 발송 대상 데이터 변경 함수
     * 합포장의 경우 주문자는 다를 수 있고, 수신자는 다를 수 없음
     * 발송여부 클릭 시
     * 1. bundle_no 기준으로 그룹핑
     * 2. 주문자 클릭 시 - bundle_no 내부에서 주문자 번호로 그룹핑
     * 3. 수신자 클릭 시 - 따로 그룹핑 처리 하지않음(수신자는 다를 수 없음 - '상품명 외 N건' 과 같이 1건만 발송
     * 4. 주문자/수신자 클릭 시 - (1,2) 처리 -> 수령자에게는 각 합포장당 1건, 주문자는 다를 수 있기 때문에 주문자별 1건
     */
    $scope.targetToRepreHtel = async () => {
      $scope.altalk.receive_list = angular.copy(data.ord).map(ord => ({ ...ord, to_htel: ord.to_htel?.replace(/-/g, ''), order_htel: ord.order_htel?.replace(/-/g, '') }));
      if ($scope.altalk.send_type_bundle_info) {
        const receive_list = {};
        $scope.altalk.receive_list.forEach(user => {
          if (receive_list[user.bundle_no]) {
            receive_list[user.bundle_no].push(user);
          } else {
            receive_list[user.bundle_no] = [user];
          }
        });
        for (const [idx, v] of Object.entries(receive_list)) {
          if ($scope.altalk.ord_user_list === 'order') {
            // order_htel로 그룹핑해야함 - 합포장인 경우에도 주문자 다를 수 있음 / 수령자는 다를 수 없음
            const countMap = {};
            receive_list[idx].forEach(obj => {
              const key = `${obj.bundle_no}-${obj.order_htel}`;
              countMap[key] = (countMap[key] || 0) + 1; // 기존 값이 없으면 0으로 초기화 후 1 증가
            });
            receive_list[idx] = _.uniqWith(receive_list[idx], (ord1, ord2) => ord1.bundle_no === ord2.bundle_no && ord1.order_htel === ord2.order_htel).map(v => ({ ...v, htel: v.order_htel, bundle_length: countMap[`${v.bundle_no}-${v.order_htel}`] - 1 }));
          }
          if ($scope.altalk.ord_user_list === 'to') {
            // 합포장인 경우에 수령자는 다를 수 없음
            receive_list[idx] = (receive_list[idx].map(v => ({ ...v, htel: v.to_htel, bundle_length: _.uniqBy(receive_list[idx], 'uniq').length - 1 })))[0];
          }
          if ($scope.altalk.ord_user_list === 'all') {
            // 수령자에게는 각 합포장당 1건, 주문자는 다를 수 있기 때문에 주문자별 1건
            let orderAndTo = [];
            const countMap = {};
            receive_list[idx].forEach(obj => {
              const key = `${obj.bundle_no}-${obj.order_htel}-${obj.to_htel}`;
              countMap[key] = (countMap[key] || 0) + 1; // 기존 값이 없으면 0으로 초기화 후 1 증가
            });
            orderAndTo.push(...(_.uniqWith(receive_list[idx], (ord1, ord2) => ord1.bundle_no === ord2.bundle_no && ord1.order_htel === ord2.order_htel).map(v => ({ ...v, htel: v.order_htel, bundle_length: countMap[`${v.bundle_no}-${v.order_htel}-${v.to_htel}`] - 1 }))));
            orderAndTo.push((receive_list[idx].map(v => ({ ...v, htel: v.to_htel, bundle_length: _.uniqBy(receive_list[idx], 'uniq').length - 1 })))[0]);

            // 합포장 내 주문자, 수령자 번호 같으면 중복 제거 처리
            orderAndTo = orderAndTo.filter((ord, idx, self) => idx === self.findIndex((o) => o.htel === ord.htel && o.bundle_no === ord.bundle_no));
            receive_list[idx] = orderAndTo;
          }
        }
        $scope.altalk.receive_list = Object.values(receive_list).flat().map(v => ({ ...v, isBundleInfo: true }));
      } else {
        $scope.altalk.receive_list = await $scope.checkTarget();
      }

      $scope.canSend = $scope.remainCnt >= $scope.altalk.receive_list.length;
    };

    $scope.onSubmit = (type) => {
      const selectHour = $scope.altalk.ampm === '1' ? (parseInt($scope.altalk.selectedHour) !== 12 ? parseInt($scope.altalk.selectedHour) + 12 : parseInt($scope.altalk.selectedHour)) : (parseInt($scope.altalk.selectedHour) === 12 ? parseInt($scope.altalk.selectedHour) + 12 : $scope.altalk.selectedHour);
      $scope.altalk.book_send_time = moment(new Date(`${$scope.altalk.altalk_send_date} ${selectHour === 24 ? '00' : selectHour}:${$scope.altalk.selectedMin}:00`)).format('YYYY-MM-DD HH:mm:ss');
      const sendInfoMsg = `${$scope.altalk.receive_list.length}명에게 ${$scope.altalk.send_time === 'scheduled' ? `${$scope.altalk.book_send_time.toString()}에` : '즉시'} 발송됩니다.`;
      if (type === 'test') {
        $scope.altalk.send_time = 'immediately';
        commonSVC.openModal('lg', { data: { type: 'test', template: { ...$scope.selectTemplate, plus_friend_id: $scope.template.plus_friend_id }, altalk: $scope.altalk } }, 'AltalkAutoSendRegisterModalCtrl', 'views/alimtalk/modals/altalk_auto_register.html');
      } else {
        const modal = commonSVC.openModal('lg', { data: { type: 'direct', template: { ...$scope.selectTemplate, plus_friend_id: $scope.template.plus_friend_id }, altalk: $scope.altalk, ord: $scope.data.ord, sendInfoMsg } }, 'AltalkAutoSendRegisterModalCtrl', 'views/alimtalk/modals/altalk_auto_register.html');

        modal.result.then((re) => {
          if (re === 'success') {
            $uibModalInstance.close();
          }
        });
      }
    };
  });