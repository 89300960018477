import React from 'react';
import Modal from 'react-modal';

interface CommonModalProps {
  isOpen: boolean;
  size?: 'md' | 'sm' | 'lg' | 'xg' | 'full' | 'xg2' | 'x3g' | 'lm' | 'mini';
  backdrop?: boolean;
  scrollable?: boolean;
  escClose?: boolean;
  handleClose: (result?: any) => void; // 부모 컴포넌트로부터 콜백 함수 전달받기
  component: any;     // 모달에 렌더링할 컴포넌트
  data: any           // 컴포넌트에 전달할 데이터
}

const CommonModal: React.FC<CommonModalProps> = ({
  isOpen,
  size = 'md',
  backdrop = false,       // 배경 클릭 시 닫힘여부
  scrollable = true,      // 스크롤 노출 여부
  escClose = true,        // ESC 키로 닫힘여부
  handleClose,            // 콜백 함수
  component,
  data
}) => {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        overlay: {
          zIndex: 2000,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }
      }}
      ariaHideApp={false}   // 모달이 열렸을때 배경콘텐츠를 숨기지 않도록 설정
      shouldCloseOnOverlayClick={backdrop}
      shouldCloseOnEsc={escClose}
      className={`modal-dialog modal-style modal-${size} ${scrollable ? 'modal-open' : 'modal-open-noscroll'}`}
    >
      <div className="modal-content">
        {React.createElement(component, { ...data, handleClose })}
      </div>
    </Modal>
  );
};

export default CommonModal;