'use strict';

angular.module('gmpApp')
  .directive('leftContentMemo', function ($rootScope, commonSVC, settings, gettextCatalog, $state, $timeout, memoModel, userInfo) {
    return {
      restrict: 'EA',
      templateUrl: 'views/tpls/leftContentMemo.html',
      link: function ($scope) {
        let memo_code = '';
        let memo_type = '';

        const pageToMemoTemplateTypeMap = {
          payment_shipment: 'ord',
          order_shipment: 'ord',
          unstoring_shipment: 'ord',
          delivery_shipment: 'ord',
          fulfillment_shipment: 'ord',
          integrated_shipment: 'ord',
          claim_shipment: 'ord',
          ebaydepot_shipment: 'ord',
          as: 'mend',
          prod_prodlist: 'prod',
          prod_setlist: 'depot_set',
          inventory_list: 'prod',
          online_product: 'ol_shop'
        };

        $scope.allSubmitCSLoading = false;

        $scope.allMemoContent = '';
        $scope.uniqList = [];
        $scope.templateList = $scope.$parent.$parent.memoTemplateList?.filter((template) => template.bookmark_yn) || [];
        $scope.templateContent = {
          all: null,
          edit: {},
          add: {},
        };

        // 이외 사용자는 본인 메모만 수정 삭제 가능
        $scope.user_m_no = userInfo.user.m_no;
        $scope.isMaster = userInfo.user.user_grade === '마스터';

        $scope.page = $scope.$parent.el_id.split('_grid')[0];
        $scope.user_id = $rootScope.user_profile.user_id;
        $scope.$parent.tabMemo = function (initData, data) {
          memo_code = data.memo_code;
          memo_type = data.type;

          const tmp = [];

          initData.accordionList.forEach((memo) => {
            tmp.push({
              ...memo,
              add_open: true,
              edit_list: {},
              edit_open: {}
            });
          });

          $scope.memoList = tmp;
          $scope.uniqList = initData.uniqList;

          getMemo(initData.uniqs, 'all', memo_code, memo_type);
        };

        $scope.$parent.tabMemoInit = function () {
          $scope.uniqList = [];
          $scope.memoList = [];
          $scope.memo_edit_list = {};
          $scope.templateContent = {
            all: null,
            edit: {},
            add: {},
          };
          $scope.allMemoContent = '';
        };

        const resizeAddTextarea = (uniq) => {
          const textarea = $(`#memo-${$scope.page}-add-textarea${uniq}`);

          textarea.height('auto');
          const scrollHeight = textarea.prop('scrollHeight');

          if (scrollHeight + 30 < 92) {
            $(`#memo-${$scope.page}-add-box${uniq}`).height(92);
          } else {
            $(`#memo-${$scope.page}-add-box${uniq}`).height(scrollHeight + 30);
          }

          textarea.height(scrollHeight);
        };

        const resizeEditTextarea = (memo_no) => {
          const textarea = $(`#memo-edit-textarea${memo_no}`);

          textarea.height('auto');
          const scrollHeight = textarea.prop('scrollHeight');

          $(`#memo-edit-box${memo_no}`).height(scrollHeight + 30);
          textarea.height(scrollHeight);
        };

        const afterInit = () => {
          $timeout(function() {
            $scope.memoList.forEach((rows) => {
              rows.list.forEach((row) => {
                const textarea = $(`#memo-edit-textarea${row.memo_no}`);

                textarea.on('input', function() {
                  resizeEditTextarea(row.memo_no);
                });
              });
              resizeAddTextarea(rows.uniq);
              $(`#memo-${$scope.page}-add-textarea${rows.uniq}`).on('input', function() {
                resizeAddTextarea(rows.uniq);
              });
            });

            // select2 추가
            const $selectSearchElems = $('.select-search');

            if ($selectSearchElems.length > 0) {
              $selectSearchElems.attr('data-container-css-class', 'select2-custom input-xs mb-5');

              $selectSearchElems.select2({
                matcher: function (params, data) {
                  if (!params.term?.trim()) {
                    return data;
                  }

                  if (data.text?.toUpperCase().includes(params.term.toUpperCase())) {
                    return data;
                  }

                  var additional = $(data.element).data('additional');

                  if (additional?.includes(params.term)) {
                    return data;
                  }

                  return null;
                },
                dropdownCssClass: function (params) {
                  return 'select2-searchbar-default';
                },
                dropdownAutoWidth: true
              });

              $selectSearchElems.on('select2:select', function (e) {
                const $select_el = $(e.params.data.element);
                const _id = $select_el.parent().attr('id');

                if ($select_el.val() && $select_el.val() != '') {
                  $(`#${_id}-container`).parent().addClass('select-on');
                } else {
                  $(`#${_id}-container`).parent().removeClass('select-on');
                }
              });

              $selectSearchElems.find('.select2-selection__rendered').each(function (idx, el) {
                $(el).parent().attr('data-placement', 'top');
                $(el).parent().attr('data-popup', 'tooltip');
                let _id = $(el).attr('id');

                _id = _id.replace('select2-memo-template-select-', '').replace('-container', '');
                const selectVal = $scope.templateList.find(list => list.template_name === _id);

                if (selectVal && selectVal.select_value) {
                  $(el).parent().addClass('select-on');
                } else {
                  $(el).parent().removeClass('select-on');
                }

                $timeout(function () {
                  $(el).parent().attr('data-original-title', el.title.trim());
                  $(el).attr('title', '');
                });
              });
              $('[data-popup="tooltip"]').tooltip();
            }
          });
        };

        /**
         * 메모 관리 이동
         */
        $scope.goMemoList = () => {
          $state.go('main.memo_list');
        };

        /**
         * 메모 일괄 등록
         */

        $scope.allSubmitCS = async () => {
          if ($scope.allMemoContent === '') {
            commonSVC.showMessage(gettextCatalog.getString('내용을 적어주세요'));

            return false;
          }

          $scope.allSubmitCSLoading = true;

          $scope.allMemoContent = $scope.allMemoContent.replace(/\n/g, '<br>');

          await memoModel.addMemoList({
            content: $scope.allMemoContent,
            memo_code,
            memo_type,
            uniqList: $scope.uniqList,
          }).then(res => {
            commonSVC.showToaster('success', '', gettextCatalog.getString('메모가 일괄 등록 되었습니다.'));

            for (const uniq of $scope.uniqList) {
              $scope.$parent.$parent.setMemoBtnActive(memo_code, uniq);
              $scope.$parent.$parent.setMemoCompleteActive(memo_code, uniq, true);
            }

            for (let idx = 0; idx < $scope.memoList.length; idx++) {
              const { memo_no, uniq } = res.data.results.find(item => item.uniq === $scope.memoList[idx].uniq);

              const memo = {
                content: $scope.allMemoContent,
                email: $rootScope.user_profile.email,
                memo_code: uniq,
                memo_no,
                memo_type: memo_type,
                name: $rootScope.user_profile.user_name,
                memo_status: '등록',
                wdate: moment(res.data.results.wdate).format('YYYY-MM-DD HH:mm:ss'),
                mdate: moment(res.data.results.wdate).format('YYYY-MM-DD HH:mm:ss'), //어짜피 등록은 수정일 == 등록일이라 한개만 가져옴
                content_mdate: null,
                wm_no: $scope.user_id
              };

              memo[memo_code] = uniq;

              $scope.memoList[idx].list.push(memo);

            }

            $scope.templateContent.all = null;
            $scope.allMemoContent = '';

            afterInit();
          }).catch((res) => {
            if (res.data.error) {
              commonSVC.showToaster('error', '실패', res.data.error);
            }
          }).finally(() => {
            $scope.allMemoContent = '';
            $scope.allSubmitCSLoading = false;
          });
        };

        /**
         * 메모 등록
         **/
        $scope.submitCS = function (idx) {
          const uniq = $scope.memoList[idx].uniq;
          const contents = $scope.memoList[idx].contents.replace(/\n/gi, '<br>');
          let firstInsert = false;

          if (!$scope.memoList[idx].list.length) {
            firstInsert = true;
          }

          if (contents == '') {
            commonSVC.showMessage(gettextCatalog.getString('내용을 적어주세요'));

            return false;
          }

          $scope.memoList[idx].contents = '';

          const data = {
            content: contents,
          };

          data.memo_code = memo_code;
          data[memo_code] = uniq;
          data.memo_type = memo_type;

          return memoModel.add(data)
            .then((res) => {
              if (res) {
                commonSVC.showToaster('success', '', gettextCatalog.getString('메모가 등록 되었습니다.'));
                if ($scope.page === 'memo_list') {
                  $scope.searchDo(false);
                  reloadMemoTemplate();
                }
                const memo = {
                  content: contents,
                  email: $rootScope.user_profile.email,
                  memo_code: uniq,
                  memo_no: res.data.results.memo_no,
                  memo_type: memo_type,
                  name: $rootScope.user_profile.user_name,
                  memo_status: '등록',
                  wdate: moment(res.data.results.wdate).format('YYYY-MM-DD HH:mm:ss'),
                  mdate: moment(res.data.results.wdate).format('YYYY-MM-DD HH:mm:ss'), //어짜피 등록은 수정일 == 등록일이라 한개만 가져옴
                  content_mdate: null,
                  wm_no: $scope.user_id
                };

                memo[memo_code] = uniq;

                $scope.memoList[idx].list.push(memo);
                $scope.memoList[idx].contents = '';
                $scope.templateContent.add[idx] = null;

                if (firstInsert) {
                  // 메모 버튼 활성화 redraw방식으로 변경
                  $scope.$parent.$parent.setMemoBtnActive(memo_code, uniq);
                }
                $scope.$parent.$parent.setMemoCompleteActive(memo_code, uniq, true);
                afterInit();
              } else {
                commonSVC.showToaster('error', '실패', res.data.error);
              }
            })
            .catch((res) => {
              if (res.data.error) {
                commonSVC.showToaster('error', '실패', res.data.error);
              }
            });
        };

        // 2020-03-04 Boris
        // 메모 수정 열기
        $scope.editCSOpen = (idx, memo_no, memo_content) => {
          $scope.memoList[idx].add_open = false;
          $scope.memoList[idx].edit_list[memo_no] = {
            text: memo_content.replace(/<br>/gi, '\n'),
            original: memo_content.replace(/<br>/gi, '\n')
          };
          // 텍스트Area 크기 조절
          $timeout(() => {
            resizeEditTextarea(memo_no);
          });
          $scope.memoList[idx].edit_open[memo_no] = true;
        };

        // 2020-03-04 Boris
        // 메모 수정 닫기
        $scope.editCSClose = (idx, memo_no) => {
          $scope.memoList[idx].edit_open[memo_no] = false;
          delete $scope.memoList[idx].edit_list[`${memo_no}`];
          if (Object.values($scope.memoList[idx].edit_open).every((v) => !v)) {
            $scope.memoList[idx].add_open = true;
          }
        };

        // 2020-03-04 Boris
        // 메모 수정 저장하기
        $scope.editCSSave = (idx, memo_no, row_idx) => {
          const contents = $scope.memoList[idx].edit_list[`${memo_no}`].text.replace(/\n/gi, '<br>');

          if (contents == '') {
            commonSVC.showMessage(gettextCatalog.getString('내용을 적어주세요'));

            return false;
          }

          const data = {
            content: contents,
            memo_no: memo_no
          };

          return memoModel.edit(data)
            .then((res) => {
              if (res) {

                $scope.memoList[idx].list[row_idx].content = contents;
                $scope.memoList[idx].list[row_idx].content_mdate = res.data.content_mdate;

                $scope.memoList[idx].edit_open[memo_no] = false;
                delete $scope.memoList[idx].edit_list[memo_no];
                if (Object.values($scope.memoList[idx].edit_open).every((v) => !v)) {
                  $scope.memoList[idx].add_open = true;
                }
                commonSVC.showToaster('success', '', gettextCatalog.getString('메모가 수정 되었습니다.'));
                $rootScope.$broadcast('leftControllMemo_change', { status: 'edit', data: $scope.memoList[idx].list[row_idx] });
                if ($scope.page === 'memo_list') {
                  $scope.searchDo(false);
                  reloadMemoTemplate();
                }
                $scope.templateContent.edit[memo_no] = null;

                return true;// 로딩바 복구 처리
              } else {
                commonSVC.showToaster('error', '실패', res.data.error);

                return false;
              }
            })
            .catch((res) => {
              if (res.data.error) {
                commonSVC.showToaster('error', '실패', res.data.error);
              }

              return false;
            });
        };

        // 2020-03-04 Boris
        // 메모 처리 완료, 완료헤제 버튼
        $scope.endCS = (idx, memo_no, row_idx, memo_status, uniq) => {
          const data = {
            memo_no: [memo_no],
            memo_status
          };

          return memoModel.end(data)
            .then((res) => {
              if (res) {
                $scope.memoList[idx].list[row_idx].memo_status = memo_status;
                $rootScope.$broadcast('leftControllMemo_change', { status: 'end', data: $scope.memoList[idx].list[row_idx] });
                if (memo_status === '완료') {
                  commonSVC.showToaster('success', '', gettextCatalog.getString('메모가 완료처리 되었습니다.'));
                  if ($scope.page !== 'memo_list' && $scope.memoList[idx].list.every(({ memo_status }) => memo_status === '완료')) {
                    $scope.$parent.$parent.setMemoCompleteActive(memo_code, uniq, false);
                  }
                } else if (memo_status === '등록') {
                  commonSVC.showToaster('success', '', gettextCatalog.getString('메모가 완료 해제처리 되었습니다.'));
                  if ($scope.page !== 'memo_list') {
                    $scope.$parent.$parent.setMemoCompleteActive(memo_code, uniq, true);
                  }
                }

                if ($scope.page === 'memo_list') {
                  $scope.searchDo(false);
                }

                return true;// 로딩바 복구 처리
              } else {
                commonSVC.showToaster('error', '실패', res.data.error);

                return false;
              }
            })
            .catch((res) => {
              if (res.data.error) {
                commonSVC.showToaster('error', '실패', res.data.error);
              }

              return false;
            });
        };

        /**
         * 메모 삭제
         **/
        $scope.deleteCS = function (idx, memo_no, row_idx, uniq) {
          const data = {
            memo_no: [memo_no]
          };

          commonSVC.showConfirm(gettextCatalog.getString('삭제하시겠습니까?'), '', function () {
            memoModel.delete(data, function (state, data) {
              if (data.results === 'success') {
                $scope.memoList[idx].list.splice(row_idx, 1);
                $scope.memoList[idx].edit_open[memo_no] = false;
                delete $scope.memoList[idx].edit_list[`${memo_no}`];
                if (Object.values($scope.memoList[idx].edit_open).every((v) => !v)) {
                  $scope.memoList[idx].add_open = true;
                }

                if (!$scope.memoList[idx].list.length) {
                  // 메모가 없으면 버튼 비활성화 처리
                  $scope.$parent.$parent.setMemoBtnActive(memo_code, uniq, true);
                } else if ($scope.memoList[idx].list.every(({ memo_status }) => memo_status === '완료')) {
                  // 메모가 삭제된 뒤에 완료된것들만 남아있으면 초록버튼처리
                  $scope.$parent.$parent.setMemoCompleteActive(memo_code, uniq);
                }

                $rootScope.$broadcast('leftControllMemo_change', { status: 'delete', data: { memo_no } });
                commonSVC.showToaster('success', '', gettextCatalog.getString('메모가 삭제 되었습니다.'));
                if ($scope.page === 'memo_list') {
                  $scope.searchDo(false);
                }
              }
            });
          });
        };

        /**
         * 메모 템플릿 적용
         */
        $scope.setMemoTemplate = (type, idx, memo_no) => {
          if (type === 'all') {
            $scope.allMemoContent = $scope.templateContent.all;
          } else if (type === 'add') {
            $scope.memoList[idx].contents = $scope.templateContent.add[idx];
          } else if (type === 'edit') {
            $scope.memoList[idx].edit_list[memo_no].text = $scope.templateContent.edit[memo_no];
          }
          afterInit();
        };

        /**
         * 메모 불러오기
         **/
        function getMemo(uniq, mode, memo_code, memo_type) {
          memoModel.list({ uniq: uniq, memo_code: memo_code, memo_type: memo_type }, function (state, data) {
            let memoList = [];

            if (state == 'success' && data.results != false) {
              // $rootScope.memoCount = data.results.length;
              memoList = data.results;
              // memoList = _.mapValues(data.results, function (val) {
              //   return _.extend(val, {
              //     checked: false
              //   });
              // });
            }

            if (mode === 'one') {
              const idx = _.findIndex($scope.memoList, { uniq: uniq });

              $scope.memoList[idx].list = [];
            }

            _(memoList)
              .map(function (row) {
                const idx = _.findIndex($scope.memoList, { uniq: row.memo_code });

                // 같은 메모 번호가 있을 때 제외
                if (!_.find($scope.memoList[idx].list, { memo_no: row.memo_no })) {
                  $scope.memoList[idx].list.push(row);
                  $scope.memoList[idx].edit_open[row.memo_no] = false;
                }
              })
              .value();

            afterInit();
            // 선택한 주문의 메모열어줌
            $timeout(function() {
              $(`#memo-${$scope.page}-accordion${$scope.select_uniq}`).collapse();
            });
          });
        }

        // 메모관리탭 하단에 변경사항이 있을때
        $scope.$on('etcMemoListInput_change', function(event, re) {
          $scope.memoList.some(({ list }, idx) => {
            list.some(({ memo_no }, row_idx) => {
              if (memo_no === re.data.memo_no) {
                switch (re.status) {
                  case 'edit':
                  case 'end':
                    $scope.memoList[idx].list[row_idx] = { ...$scope.memoList[idx].list[row_idx], ...re.data };
                    break;
                  case 'delete':
                    $scope.memoList[idx].list.splice(row_idx, 1);
                    break;
                }
              }
            });
          });
        });

        const reloadMemoTemplate = async (type) => {
          const memo_type = type ? type : $scope.page === 'memo_list' ? $scope.$parent.searchForm.memo_type : pageToMemoTemplateTypeMap[$scope.page];
          const res = await memoModel.getTemplateList({ search_key: memo_type === 'total' ? '' : `all:${memo_type}` });
          $scope.templateList = res.data.results.filter((template) => template.bookmark_yn);
        };

        // 메모템플릿 리스트 갱신
        $scope.$on('reloadMemoTemplate', async function (event, type) {
          await reloadMemoTemplate(type);
        });
      }
    };
  });
