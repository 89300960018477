import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPageNumbers = () => {
    const pages = [];
    const maxVisible = 7;  // 최대 표시 페이지 수
    const half = Math.floor(maxVisible / 2);

    let start = Math.max(1, currentPage - half);
    let end = Math.min(totalPages, currentPage + half);

    // Adjust start and end if near boundaries
    if (currentPage <= half) {
      end = Math.min(totalPages, maxVisible);
    } else if (currentPage > totalPages - half) {
      start = Math.max(1, totalPages - maxVisible + 1);
    }

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <div style={styles.container}>
      <button
        style={{
          ...styles.button,
          ...(currentPage === 1 && styles.buttonDisabled),
        }}
        disabled={currentPage === 1}
        onClick={() => onPageChange(1)}
      >
        «
      </button>
      <button
        style={{
          ...styles.button,
          ...(currentPage === 1 && styles.buttonDisabled),
        }}
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        ‹
      </button>
      {getPageNumbers().map((page) => (
        <button
          key={page}
          style={{
            ...styles.button,
            ...(page === currentPage && styles.buttonActive),
          }}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      ))}
      <button
        style={{
          ...styles.button,
          ...(currentPage === totalPages && styles.buttonDisabled),
        }}
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        ›
      </button>
      <button
        style={{
          ...styles.button,
          ...(currentPage === totalPages && styles.buttonDisabled),
        }}
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(totalPages)}
      >
        »
      </button>
    </div>
  );
};

// 스타일 정의
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    padding: '10px',
  },
  button: {
    padding: '5px 10px',
    border: '1px solid #4A657D',
    backgroundColor: '#fff',
    color: '#4A657D',
    cursor: 'pointer',
    fontSize: '10px',
    borderRadius: '4px',
    transition: 'background-color 0.2s, color 0.2s',
  },
  buttonDisabled: {
    backgroundColor: '#f1f1f1',
    color: '#ccc',
    cursor: 'not-allowed',
  },
  buttonActive: {
    backgroundColor: '#4A657D',
    color: '#fff',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
    color: '#fff',
  },
};

export default Pagination;
