'use strict';

angular.module('gmpApp')
  .controller('AutoSoldoutHistoryCtrl', function ($rootScope, $scope, settings, systemList, gettextCatalog, commonSVC, historySVC) {
    // 사용중인 쇼핑몰
    const channelList = $rootScope.useChannelList({ site_action: 'ScrapOrder' });

    const siteList = _.unionBy(channelList, 'shop_cd'),
          siteIdList = channelList;

    /**
     * 서치바 관련 변수
     */
    const search = {
      searchArr: null,
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
        edate: moment().format('YYYY-MM-DD'),
        soldout_type: '',
        sale_status: '',
        site_code: '',
        site_id: '',
      },
      searchData: {
        all_checked: false,   // 전체선택 여부
        totalCount: 0,        // 검색전체 건수
        selectCount: 0,       // 선택한 건수 <- 작업예정
        search_key_items: [
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
          { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name' },
          { label: gettextCatalog.getString('속성명'), value: 'attri' },
          { label: gettextCatalog.getString('쇼핑몰(ID)'), value: 'shop' },
          { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
          { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
          { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
          { label: gettextCatalog.getString('옵션명'), value: 'shop_opt_name' },
          { label: gettextCatalog.getString('작업자'), value: 'wm_name' },
        ],
        search_date_type: [
          { label: gettextCatalog.getString('날짜'), value: 'wdate' }
        ]
      },
      searchDetail: [
        {
          title: '자동품절 구분',
          search_name: 'soldout_type',
          item_list: [{ key: '상품', value: '상품' }, { key: '옵션', value: '옵션' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        },
        {
          // 채널 선택
          title: '쇼핑몰 선택',
          search_name: 'shop_cd',
          item_list: siteList,
          item_key: 'shop_name',
          item_sub_key: 'shop_name_kr',
          item_value: 'shop_cd',
          select_value: '',
          add_class: 'select-search',
          filter: function(option) {
            return !option.pa_shop_cd?.startsWith('X');
          },
        },
        {
          // 채널 계정 선택
          title: '쇼핑몰(ID) 선택',
          search_name: 'shop_id',
          item_list: siteIdList,
          item_key: 'shop_id',
          item_value: 'shop_id',
          select_value: '',
          add_class: 'select-search',
          filter: function (option) {
            // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
            return option.shop_cd === $scope.searchForm.shop_cd && option.shop_id;
          }
        },
        {
          title: '상품상태',
          search_name: 'sale_status',
          item_list: [{ key: '품절', value: '품절' }, { key: '대기', value: '대기' }],
          item_key: 'key',
          item_value: 'value',
          select_value: '',
          isMulti: true
        },
      ]
    };

    // 검색영역 폼값 정리
    $scope.searchForm = angular.copy(search.searchForm);

    // 검색영역 데이터
    $scope.searchData = angular.copy(search.searchData);

    // 검색영역 상세검색 설정
    $scope.searchDetail = angular.copy(search.searchDetail);

    // 검색영역 버튼 설정
    $scope.searchBtn = {
      table_actions: [
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('전체 엑셀 다운로드'),
              action: function () {
                $scope.excelDown('all');
              },
              perm_only: ['online.product']
            }, {
              label: gettextCatalog.getString('선택 엑셀 다운로드'),
              action: function () {
                $scope.excelDown('select');
              },
              perm_only: ['online.product']
            }
          ]
        }
      ],

      // 검색 영역 선택한 데이터 버튼
      actions_left: [
        [
          {
            label: `<i class="icon-file-excel text-success"></i> ${gettextCatalog.getString('전체 엑셀 다운로드')}`,
            btn_type: 'button',
            action: function () {
              $scope.excelDown('all');
            }
          },
          {
            label: `<i class="icon-file-excel text-success"></i> ${gettextCatalog.getString('선택 엑셀 다운로드')}`,
            btn_type: 'button',
            action: function () {
              $scope.excelDown('select');
            }
          }
        ]
      ],

      // 검색 영역 오른쪽 버튼
      actions_right: []
    };

    /**
     * 작업관리 검색 데이터 테이블 관련기능 바인딩
     */
    $scope.searchFn = {
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      resetDo: function () {
        $scope.resetDo();
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      }
    };

    // 검색시 실행
    $scope.searchDo = function (refresh = true, noDelay) {
      // 검색시 새로고침
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchData.search_key_name = '전체';
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.selectCount = 0;

      $scope.searchDo(true, true);
    };

    /**
    * 데이터테이블 pageLength 조절
    */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['prod_name'],
      defaultSortingColumns: ['wdate'],
      alignRightColumns: [],
      notSortingColumns: 'ALL',
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],

      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/history/autoSoldout`,
        requestWillAction: function (d) {
          // request 전, 작업 관리로부터 넘어온 데이터가 있다면 검색 데이터에 반영함
          if ($rootScope.work_search.search_cd) {
            $scope.searchForm.search_word = $rootScope.work_search.search_cd;
          }

          const data = angular.merge({}, d, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'wdate',
          title: '자동품절일',
          width: 140
        },
        {
          key: 'soldout_type',
          title: '자동품절 구분',
          width: 90
        },
        {
          key: 'wm_name',
          title: '작업자',
          width: 90
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 131
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 230
        },
        {
          key: 'attri',
          title: '속성명',
          width: 100
        },
        {
          key: 'shop_id',
          title: '쇼핑몰(계정)',
          width: 144,
          notCompile: true,
          template: function(row) {
            return `${row.shop_name}(${row.shop_id})`;
          }
        },
        {
          key: 'c_sale_cd',
          title: '판매자관리코드',
          width: 114
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰상품코드',
          width: 118
        },
        {
          key: 'shop_sale_name',
          title: '온라인상품명',
          width: 275
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 124
        },
        {
          key: 'sale_status',
          title: '상품상태',
          tooltip: {
            text: '[품절] : 자동품절 시 실제 쇼핑몰에서 품절 작업이 진행되어 상태가 변경되었을 때\n[대기] : 특정 채널에서 옵션 부분수정 기능을 지원하지 않아 솔루션 내에서만 상품 및 옵션 상태가 변경되었을 때',
            placement: 'top-right',
            appendToBody: true
          },
          width: 124
        },
      ]
    };

    // 엑셀다운
    $scope.excelDown = function(type) {
      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: angular.copy(historySVC.autoSoldoutExcelFieldList),
        title: '상품자동품절 내역',
        url: '/app/history/autoSoldout/excelDown',
        searchForm: $scope.pageData,
        page: 'auto_soldout_history'
      };

      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const selectList = $scope.grid.methods.selectedData('all');

        if (!selectList.length) {
          commonSVC.showMessage('선택된 내역이 없습니다.');

          return false;
        } else {
          resolve.data.select_list = selectList;
          resolve.data.count = selectList.length;
          resolve.data.isAll = false;
        }
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };
  });
