import settings from '../config/settings';

interface RequestOptions {
  method: string;
  body?: string;
  headers?: any;
}

export const sendUrl = async (method: string, url: string, params?: any, headers?: any, isDownload?: false) => {
  const token = localStorage.getItem('ls.token');

  const requestOptions: RequestOptions = {
    method,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if (token) {
    requestOptions.headers = {
      'Content-Type': 'application/json',
      'Authorization': token.replace(/"/g, '')
    };
  }

  if (headers) {
    requestOptions.headers = {
      ...requestOptions.headers,
      ...headers
    };
  }

  if (params && Object.keys(params).length) {
    requestOptions.body = JSON.stringify(params);
  }

  const response = await fetch(
    `${settings.PA20_API_URL}/${url}`,
    requestOptions
  );

  if (isDownload) {
    return response.blob();
  } else {
    return response.json();
  }
};

/**
 * 파일 업로드
 */
export const sendUrlFile = async (method: string, url: string, params?: any, headers?: any) => {
  const token = localStorage.getItem('ls.token');
  const requestOptions: any = {
    method,
    headers: {}
  };

  if (token) {
    requestOptions.headers = {
      Authorization: token.replace(/"/g, '')
    };
  }

  if (headers) {
    requestOptions.headers = {
      ...requestOptions.headers,
      ...headers
    };
  }

  const formData = new FormData();

  for (const key in params) {
    if (typeof params[key] === 'object' && params[key].length) {
      for (let i = 0; i < params[key].length; i++) {
        formData.append(key, params[key][i]);
      }
    } else {
      formData.append(key, params[key]);
    }
  }

  const response = await fetch(
    `${settings.PA20_API_URL}/${url}`,
    {
      ...requestOptions,
      body: formData
    }
  );

  return response.json();
};