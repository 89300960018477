'use strict';

angular.module('gmpApp')
  .controller('EtcWorkCtrl', function ($rootScope, $scope, $compile, $filter, $timeout, $state, $stateParams, $q, warehouseList,
    systemList, ebaydepotAccounts, localStorageService, commonSVC, workSVC, gettext, gettextCatalog, settings, userInfo,
    productModel, onlineProductModel, systemModel, commonModel, errorSVC) {

    /** G 마켓 물류 사용여부 */
    const isSmile = $rootScope.userProfileCheck('sol_ser', 'smile', 'like');

    /**
     * 번역 대상 멘트 지정
     */
    gettext('주문동기화');
    gettext('주문수집');
    gettext('송장전송');
    gettext('동기화중');
    gettext('로그인');
    gettext('로그아웃');

    $scope.tabIndex = 0;
    const siteList = commonSVC.getSiteList($rootScope.use_channel_list),
          siteIdList = commonSVC.getSiteIdList($rootScope.use_channel_list),
          work_job_type = [];

    let startIdx,           // 데이터테이블 현재 페이지가 전체 데이터 중 몇번째부터 시작하는지
        len,                // 리스팅 데이터 개수
        multi_search_cd;    // 멀티검색용 코드 2019-02-18 rony

    const state_name_list = [{ key: '대기', value: 'inactive' }, { key: '진행중', value: 'active' }, { key: '완료', value: 'complete' }, { key: '부분실패', value: 'lossless' }, { key: '실패', value: 'loss' }, { key: '오류', value: 'failed' }];

    // G마켓물류 계정연동 존재시 쇼핑몰이랑 계정에 추가
    ebaydepotAccounts = ebaydepotAccounts.data.results;

    let tabType = $stateParams.tabType;

    if ($rootScope.use_channel_list.some(shop => ['A001', 'A006'].includes(shop.shop_cd) && shop.etc7)) {
      const esmShopInfo = Object.values(_.groupBy($rootScope.use_channel_list.filter(shop => ['A001', 'A006'].includes(shop.shop_cd) && shop.etc7), 'etc7'));
      const isEsm = esmShopInfo.some(shopList => shopList.some(shop => shop.shop_cd === 'A001') && shopList.some(shop => shop.shop_cd === 'A006'));

      if (isEsm) {
        siteList.unshift({
          pa_shop_cd: 'ESMM',
          shop_cd: 'ESMM',
          shop_name: 'ESM마스터상품'
        });

        esmShopInfo.forEach(shopList => {
          siteIdList.unshift({
            pa_shop_cd: 'ESMM',
            shop_cd: 'ESMM',
            shop_name: 'ESM마스터상품',
            shop_id: shopList[0].etc7,
            seller_nick: shopList[0].etc7
          });
        });
      }
    }

    if (ebaydepotAccounts.length) {
      siteList.push(
        {
          shop_cd: 'ESMP',
          shop_name: 'G마켓 물류'
        }
      );

      _.each(ebaydepotAccounts, function (ea) {
        siteIdList.push(
          {
            shop_cd: 'ESMP',
            shop_name: 'G마켓 물류',
            shop_id: ea.ebaydepot_id,
            seller_nick: ''
          }
        );
      });
    }

    $scope.isWorkTab = true;
    // 초기 로드시 데이터 바인딩 제대로 안되는 이슈가 있어서 추가함
    $scope.workInit = true;
    $scope.work_solInit = false;

    let work_solInit = false; // 엑셀 or 솔루션 작업 탭 초기화 완료 여부

    // 별칭정보 담기
    const seller_nick_info = [];

    _.forEach($rootScope.use_channel_list, function(chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    /**
     * 서치바 관련 변수
     */
    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
        edate: moment().format('YYYY-MM-DD'),
        job_cate: '',
        state: '',
        site_code: '',
        site_id: '',                                                                 //매칭여부
        multi_search_word: [],                                                       //멀티서치워드
        multi_type: 'state_name',
        pageFrom: 'work'
      },
      searchData: {
        view: 'Y',
        all_checked: false,   // 전체선택 여부
        totalCount: 0,        // 검색전체 건수
        selectCount: 0,       // 선택한 건수 <- 작업예정
        showCount: 25,
        search_key_items: [
          { label: '전체', value: 'all' },
          { label: '작업번호', value: 'id' },
          { label: '사이트아이디', value: 'site_id' },
          { label: '사이트명', value: 'site_name' },
          { label: '작업명', value: 'job_name' },
          { label: '작업자', value: 'sol_user_name' },
          { label: '메세지', value: 'state_msg' }
        ],
        customSearchToolTip: {
          tooltip: '작업이 대기 혹은 진행중 상태에서 멈춰있는 경우 [새로고침]버튼을 클릭해주시기 바랍니다.'
        },
        search_date_type: [
          { label: '등록시간', value: 'wdate' },
          { label: '시작시간', value: 'started_at' },
          { label: '종료시간', value: 'updated_at' }
        ],
        search_multi_items: [
          { label: '상태', value: 'state_name' },
          { label: '작업번호', value: 'worklog_job_no' },
        ]
      },
      work_searchDetail: [
        {
          // 작업유형
          title: '작업유형',
          search_name: 'job_cate',
          item_list: [{ key: '상품전체', value: 'PROD' }, { key: '일반상품', value: 'PROD0' }, { key: '단일상품', value: 'PROD1' }, { key: '주문', value: 'ORDER' }, { key: '문의', value: 'CS' }, { key: '정산', value: 'CALCULATE' }, { key: '시스템', value: 'SYSTEM' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        },
        {
          // 상태
          title: '상태',
          search_name: 'state',
          item_list: state_name_list,
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        },
        {
          // 채널 선택
          title: '쇼핑몰 선택',
          search_name: 'site_code',
          item_list: siteList,
          item_key: 'shop_name',
          item_sub_key: 'shop_name_kr',
          item_value: 'shop_cd',
          select_value: '',
          add_class: 'select-search'
        },
        {
          // 채널 계정 선택
          title: '쇼핑몰(ID) 선택',
          search_name: 'site_id',
          item_list: siteIdList,
          item_key: 'shop_id',
          item_value: 'shop_id',
          select_value: '',
          add_class: 'select-search',
          filter: function (option) {
            // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
            return !option.pa_shop_cd?.startsWith('X') ? option.shop_cd == $scope.searchForm.site_code && option.shop_id : _.intersection([$scope.searchForm.site_code], option.shop_cds).length && option.shop_id;
          }
        }
      ],
      work_sol_searchDetail: [
        {
          // 작업유형
          title: '작업유형',
          search_name: 'job_cate',
          item_list: [{ key: '상품전체', value: 'PROD' }, { key: '일반상품', value: 'PROD0' }, { key: '단일상품', value: 'PROD1' }, { key: '주문', value: 'ORDER' }, { key: '문의', value: 'CS' }, { key: '정산', value: 'CALCULATE' }, { key: '시스템', value: 'SYSTEM' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        },
        {
          // 상태
          title: '상태',
          search_name: 'state',
          item_list: state_name_list,
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        }
      ],
      searchDetailMulti: [
        {
          title: '상태 선택',
          search_name: 'state_name',
          item_list: state_name_list,
          item_key: 'key',
          item_value: 'value',
          search_word: ''
        }
      ],
      searchBtn: {

        // 검색 영역 선택한 데이터 버튼
        table_actions: [
          {
            label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              // 작업 중단
              {
                label: '작업 중단',
                action: async () => {
                  const row_data = $scope.grid.methods.selectedData('all');
                  const workData = $scope.workData[$scope.tabIndex];

                  if (!row_data.length) {
                    commonSVC.showMessage('삭제하실 작업을 선택해 주세요.');

                    return;
                  }

                  const imposDeleteStateWorks = row_data.filter(row => !['대기', '진행중'].includes(workData[row.id].state_name));

                  if (imposDeleteStateWorks.length) {
                    commonSVC.showMessage('작업 중단 실패', '중단이 불가능한 작업이 존재합니다.');

                    return;
                  }

                  const confirm = await commonSVC.showConfirm('작업을 중단하시겠습니까?', '');

                  if (!confirm) {
                    return;
                  }

                  try {
                    await workSVC.stopExcelWork({ jobs: row_data.map(row => ({ id: row.id, progress: row.progress, state: row.state })), type: 'excel' });

                    commonSVC.showToaster('success', '작업 중단 성공', '작업 중단에 성공했습니다.');
                    $scope.grid.methods.reloadData(function () {}, false);
                  } catch (err) {
                    commonSVC.showToaster('error', '작업 중단 실패', '작업 중단에 실패했습니다.');
                  }

                }
              },
              // 작업 삭제
              {
                label: '작업 삭제',
                action: function () {
                  // 작업삭제 권한 확인. 2019-01-04 rony
                  if (commonSVC.checkPermission('work.roles.delete', userInfo.permission) === false) {
                    return false;
                  }

                  const row_data = $scope.grid.methods.selectedData('all');
                  const workData = $scope.workData[$scope.tabIndex];

                  if (!row_data.length) {
                    commonSVC.showMessage('삭제하실 작업을 선택해 주세요.');

                    return;
                  }

                  const imposDeleteStateWorks = row_data.filter(row => ['대기', '완료', '실패', '오류'].indexOf(workData[row.id].state_name) < 0);

                  if (imposDeleteStateWorks.length) {
                    const limitLastUpdate = moment().add(-3, 'minute').valueOf();
                    const limitOverWorks = imposDeleteStateWorks.filter(work => moment(work.updated_at).valueOf() < limitLastUpdate || isNaN(moment(work.updated_at).valueOf()));

                    if (imposDeleteStateWorks.length !== limitOverWorks.length) {
                      commonSVC.showMessage('작업 삭제 실패', '삭제가 불가능한 작업이 존재 합니다.');

                      return;
                    }
                  }

                  if ($scope.tabIndex === 2 && row_data.some(work => ['OnlineToMasterAutoMatch', 'AutoSetting'].includes(work.job_type))) {
                    commonSVC.showMessage('작업 삭제 실패', '삭제가 불가능한 작업이 존재 합니다.');

                    return;
                  }

                  const delWorks = row_data.map(row => ({
                    worklog_job_no: row.worklog_job_no,
                    id: row.id,
                    site_name: row.site_name,
                    site_id: row.site_id,
                    job_name: row.job_name,
                    state_name: row.state_name,
                    del_yn: row.del_yn,
                    work_cnt: row.work_cnt || 0,
                    state_msg: row.state_msg,
                    state: row.state,
                    progress: row.progress,
                  }));

                  $scope.workDelete(delWorks);
                }
              },
              {
                label: '작업 강제삭제',
                action: function () {
                  const row_data = $scope.grid.methods.selectedData('all');
                  const workData = $scope.workData[$scope.tabIndex];

                  // 바인딩된 데이터의 상태를 가져옴
                  _.each(row_data, function (row) {
                    row.state_name = workData[row.id].state_name;
                  });

                  // 공통 함수로 검사
                  const checkValue = commonSVC.checkRulesAndConfirm(
                    'deleteOrders',
                    '작업강제삭제',
                    $scope.grid.methods,
                    row_data,
                    $scope.searchData.totalCount,
                    'state_name',
                    ['대기', '완료', '실패', '오류', '진행중'],
                    [],
                    true
                  );

                  const delWorks = [];

                  if (checkValue.isOpen) {
                    if (checkValue.list_count > 0) {
                      _.forEach(checkValue.list, function (row) {
                        const delParams = {
                          worklog_job_no: row.worklog_job_no,
                          id: row.id,
                          site_name: row.site_name,
                          site_id: row.site_id,
                          job_name: row.job_name,
                          state_name: row.state_name,
                          del_yn: row.del_yn,
                          work_cnt: row.work_cnt || 0,
                          state_msg: row.state_msg,
                          state: row.state,
                          progress: row.progress,
                        };

                        delWorks.push(delParams);
                      });

                      $scope.workDelete(delWorks);
                    } else {
                      commonSVC.showMessage('삭제하실 작업을 선택해 주세요.');
                    }
                  }
                }
              },
              {
                label: '작업 취소',
                action: function () {
                  const row_data = $scope.grid.methods.selectedData('all');
                  const workData = $scope.workData[$scope.tabIndex];

                  // 바인딩된 데이터의 상태를 가져옴
                  _.each(row_data, function (row) {
                    row.state_name = workData[row.id].state_name;
                  });

                  // 공통 함수로 검사
                  const checkValue = commonSVC.checkRulesAndConfirm(
                    'cancelOrders',
                    '작업취소',
                    $scope.grid.methods,
                    row_data,
                    $scope.searchData.totalCount,
                    'state_name',
                    ['진행중'],
                    [],
                    true
                  );

                  if (checkValue.isOpen) {
                    if (checkValue.list_count > 0) {
                      $scope.workCancel(checkValue.list.map(({ id, uuid }) =>
                        ({ id, uuid })
                      ));
                    } else {
                      commonSVC.showMessage('취소하실 작업을 선택해 주세요.');
                    }
                  }

                }
              },
              // 작업 재실행
              {

                label: '작업 재실행',
                action: function () {
                  $scope.workReAdd();
                }
              }
            ]
          }
        ],

        // 검색 영역 오른쪽 버튼
        actions_right: [
          {
            label: '<i class="icon-sync"></i><span>새로고침</span>',
            small_label: '새로고침',
            sub_label: '',
            btn_type: 'button',
            add_class: 'col-xs-6',
            action: function () {
              $scope.searchDo();

              // 대기건이 있는데 실행안되는 작업 확인
              workSVC.refreshAllJobs();
            }
          },
          {
            label: '<i class="icon-cross2"></i><span>전체삭제</span>',
            small_label: '전체삭제',
            sub_label: '',
            btn_type: 'button',
            add_class: 'col-xs-6',
            action: function () {
              $scope.workPurge();
            }
          }
        ]
      }
    };

    if (isSmile) {
      search.work_searchDetail[0].item_list.splice(3, 0, { key: '물류상품', value: 'PROD2' });
    }

    $scope.searchDetailMulti = angular.copy(search.searchDetailMulti);

    // 디버그 접속이 아닌경우 작업 강제삭제 비노출
    if (!$rootScope.adminMode) {
      search.searchBtn.table_actions[0].item_list.splice(1, 1);
    } else {
      // 디버그 접속일때는 작업 아이다로 검색할 수 있게 추가
      search.searchData.search_key_items.push({
        label: '작업 아이디',
        value: 'work_id',
      });
    }

    // 검색영역 폼값 정리
    $scope.searchForm = angular.copy(search.searchForm);

    // 검색영역 데이터
    $scope.searchData = angular.copy(search.searchData);

    // 검색영역 상세검색 설정
    $scope.searchDetail = angular.copy(search.work_searchDetail);

    // 검색영역 버튼 설정
    $scope.searchBtn = angular.copy(search.searchBtn);

    $timeout(() => {
      if ($scope.tabIndex !== 1) {
        $scope.searchBtn.table_actions[0].item_list = $scope.searchBtn.table_actions[0].item_list.filter(({ label }) => !['작업 중단'].includes(label));
      }
    });

    /**
     * 작업관리 검색 데이터 테이블 관련기능 바인딩
     */
    $scope.searchFn = {
      searchDo: function () {
        $scope.searchDo();
      },
      resetDo: function () {
        $scope.resetDo();
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      }
    };

    /**
     * 바인딩용 데이터 초기화 함수
     * 2017-06-02 matthew
     * @constructor
     */
    function InitBindedData() {
      $scope.workData = [{}, {}, {}];
    }
    InitBindedData(); // 1번 호출

    /**
     * 작업관리 새로고침
     */
    $scope.searchDo = function (refresh, noDelay, callback = null) {

      if ($scope.grid.methods.reloadData) {
        $scope.grid.methods.reloadData(callback, true, noDelay);
      }
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      const totalTemp = $scope.searchData.totalCount;

      $scope.searchData.totalCount = totalTemp;
      $scope.searchDetail = $scope.isWorkTab ? angular.copy(search.work_searchDetail) : angular.copy(search.work_sol_searchDetail);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchBtn = angular.copy(search.searchBtn);
      $scope.searchDetailMulti = angular.copy(search.searchDetailMulti);
      $scope.selectCount = 0;

      // 로딩완료후 탭바뀌도록 콜백으로 처리
      $scope.searchDo(true, true, $scope.hideWorkMenu);
    };

    /**
     * 탭별 작업메뉴 숨김처리
     */
    $scope.hideWorkMenu = () => {
      if ($scope.tabIndex !== 0) {
        $scope.searchBtn.table_actions[0].item_list = $scope.searchBtn.table_actions[0].item_list.filter(({ label }) => !['작업 취소', '작업 재실행'].includes(label));
      }

      if ($scope.tabIndex !== 1) {
        $scope.searchBtn.table_actions[0].item_list = $scope.searchBtn.table_actions[0].item_list.filter(({ label }) => !['작업 중단'].includes(label));
      }
    };

    // ROW 클릭시 상세 로그 출력
    $scope.detailLog = {};
    $scope.detailLog.number = 0;
    $scope.detailLog.show = false;

    $scope.grid = {};
    $scope.grid.methods = {};

    /***************************
     * 쇼핑몰 작업 데이터 테이블
     ****************************/
    $scope.grid = $scope.work = {};
    $scope.work.methods = {};
    $scope.work.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'state_name', 'progress'],
      defaultSortingColumns: ['id'],
      alignRightColumns: [],
      notSortingColumns: ['widget', 'site_id', 'job_name', 'sol_user_name', 'wdate', 'started_at', 'updated_at', 'engine_finished_at', 'working_time', 'state_name', 'progress', 'state_msg'],
      notResizingColumns: ['widget'],
      notMovingColumns: 'ALL',
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/work/list`,
        requestWillAction: data => {
          data.solJobYn = false;
          data.jobType = 'shop';
          $scope.hideWorkMenu();

          startIdx = data.start;
          len = data.length;

          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: result => {
          const rows = result.results;

          $scope.searchData.totalCount = result.recordsTotal;

          InitBindedData();

          // 작업 내역 바인딩
          rows.forEach(function (row) {
            // 작업삭제를 위해 추가
            row.state_name = gettextCatalog.getString(workSVC.stateText[row.state]) || '오류';

            // workdata객체 초기화
            $scope.workData[$scope.tabIndex][row.id] = {
              // 실시간 데이터
              state_name: row.state_name,
              state: row.state || 'failed',
              msg: workSVC.getTypeText(row.state_msg),
              msg_detail: row.state_msg_detail,
              ...getWorkFormatTimes(row),

              // 프로그래스 없을시 이미 완료된 작업이므로 100으로 넣음
              progressbar: row.state === 'inactive' ? 0 : (row.progress || 100),

              // 1:1문의용 데이터 저장
              site_id: row.site_id,
              site_name: row.site_name,
              job_name: row.job_name,
              create_date: row.created_at,
              job_type: row.job_type,
              job_file: row.job_file,
              result_file: row.result_file,

              worklog_job_no: row.worklog_job_no,
              uuid: row.uuid,

              isQuick: row.save_job_data?.isQuick || false
            };

            if (row.id === $scope.selectedJobId) {
              $scope.detail(row.id);
            }
          });

          return rows;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 85,
          template: row => {
            let contents = '';
            const isNotComplete = `grid.appScope.workData[${$scope.tabIndex}][${row.id}].state_name !== '완료'`;
            const isNotFailed = `['실패', '오류'].indexOf(grid.appScope.workData[${$scope.tabIndex}][${row.id}].state_name) === -1`;

            if (row.job_type === 'ScrapProd') {
              contents = `
                <button ng-disabled="${isNotComplete}" ng-click="grid.appScope.locationScrapMatchPage('${row.site_id}','${row.site_code}')" class="btn btn-default btn-xxs details-control">
                  매칭
                </button>
              `;
            }

            return (`
              <button ng-disabled="${isNotComplete} && ${isNotFailed}" ng-click="grid.appScope.detail(${row.id})" class="btn btn-default btn-xxs">
                상세
              </button>
              ${contents}`
            );
          }
        },
        {
          key: 'id',
          title: 'NO',
          width: 100,
          notCompile: true,
          template: ({ worklog_job_no, id, del_yn, uuid, result }) => {
            let viewID = worklog_job_no;

            if ($rootScope.adminMode) {
              if (result) {
                viewID = `${viewID} (${id}-${result})`;
              } else {
                viewID = `${viewID} (${uuid || id || ''})`;
              }
            }

            return `<span${del_yn == 1 ? ' style="color:blue"' : ''}>${viewID}</span$>`;
          }
        },
        {
          key: 'site_id',
          title: '쇼핑몰(계정)',
          width: 150,
          template: row => {
            let logo_site_code = row.pa_site_code;

            if (row.site_code === 'A113') {
              logo_site_code = 'A112';
            } else if (row.pa_site_code === 'B118' && row.site_code === 'P059') {
              logo_site_code = 'P059';
            }

            // 11번가 단일이 A113 코드로 처리되어서 이미지, 별칭이 안나오는 문제가 있어 조건 추가.
            const shop_info = commonSVC.getShopIdViewText(
              systemList.data.shop_id_view_type,
              seller_nick_info,
              row.site_code === 'A113' ? 'A112' : row.site_code,
              row.site_id
            );
            const img = `
              <span
                uib-tooltip="${row.site_name}(${shop_info[0] || '없음'})"
                tooltip-placement="right"><img src="/assets/images/sitelogo/${logo_site_code}.png"
                style="width: 50px;"
              >
                ${shop_info[1]}
              </span>`;

            return img;
          }
        },
        {
          key: 'job_name',
          title: '작업명',
          width: 250,
          notCompile: true,
          template: function(row) {
            let job_name = '';
            let prodScrap_type = ''; //상품수집: 기간수집/특정상품수집
            let quickJob = '';
            // 관리자모드이고 pa5 작업인경우 출력
            const workJob_type = ($rootScope.adminMode || [1762, 596].indexOf(row.sol_code) > -1) && row.domain === 'v5.playapi.io' ? '<font color=red><b>[PA5]</b></font> ' : '';

            if ($scope.tabIndex < 1 && row.std_ol_yn != null && row.std_ol_yn != 'null' && row.job_cate === 'PROD') {
              if (row.std_ol_yn === 2) {
                job_name = '[물류] ';
              } else if (row.std_ol_yn === 1) {
                job_name = '[단일] ';
              } else {
                job_name = '[일반] ';
              }
              work_job_type.push({ job_id: row.id, job_type: job_name });
            }

            // 상품수집인 경우 기간수집, 특정상품수집 출력
            if (row.job_type === 'ScrapProd') {
              prodScrap_type = row.save_job_data.codeScrapYn ? ' (특정상품수집)' : ' (기간수집)';
            }

            // job_name 에 일반/단일 여부, 기간상품/특정상품수집 여부 추가
            if ($scope.workData[$scope.tabIndex][row.id]) {
              $scope.workData[$scope.tabIndex][row.id].job_name = job_name + $filter('translate')(row.job_name) + prodScrap_type;
            }

            // 퀵작업 여부 출력. $scope.workData > job_name 은 건들이지 않는다.
            // '주문수집' 등과 같이 작업명 완전일치로 확인하는 처리가 있음.
            if (row.save_job_data?.isQuick) {
              quickJob = ' (퀵수집)';
            }

            const solJob = ($rootScope.adminMode || [1762, 596].indexOf(row.sol_code) > -1) && row.worklog_sol_id ? ` <font color=red>(솔루션 작업번호 : ${row.worklog_sol_id})</font>` : '';

            if (row.save_job_data?.isQuickProdAdd) {
              quickJob += ' (퀵등록)';
            }

            return workJob_type + job_name + $filter('translate')(row.job_name) + `<font color=red><b>${prodScrap_type}</b></font>` + `<span class='text-primary'>${quickJob}</span>` + solJob + (row.del_yn == 1 ? ' (비노출)' : '');
          }
        },
        {
          key: 'sol_user_name',
          title: '작업자',
          width: 120,
          notCompile: true,
          template: row => {
            let sol_user_name = row.sol_user_name;

            switch (row.job_group) {
              case 'CRON':
                sol_user_name = '시스템(자동)';
                break;

              case 'openapi':
                sol_user_name = 'OPEN-API';
                break;

              case 'AUTO':
                sol_user_name = sol_user_name + '(자동)';
                break;
            }

            return sol_user_name;
          }
        },
        {
          key: 'wdate',
          title: '등록시간',
          width: 100,
          notCompile: true,
          template: ({ wdate }) => moment(wdate).format('MM-DD HH:mm:ss')
        },
        {
          key: 'started_at',
          title: '시작시간',
          width: 100,
          template: ({ id }) => `<span class="ng-hide" ng-show="grid.appScope.workData[${$scope.tabIndex}][${id}].start_date">{{grid.appScope.workData[${$scope.tabIndex}][${id}].start_date}}</span>`
        },
        {
          key: 'updated_at',
          title: '종료시간',
          width: 100,
          template: ({ id }) => `<span class="ng-hide" ng-show="grid.appScope.workData[${$scope.tabIndex}][${id}].end_date">{{grid.appScope.workData[${$scope.tabIndex}][${id}].end_date}}</span>`
        },
        ...(($rootScope.adminMode || [1762, 596].indexOf(userInfo.user.sol_no) > -1) ? [{
          key: 'engine_finished_at',
          title: '결과처리 시작시간',
          width: 110,
          template: ({ id }) => `<span class="ng-hide" ng-show="grid.appScope.workData[${$scope.tabIndex}][${id}].engine_finished_at">{{grid.appScope.workData[${$scope.tabIndex}][${id}].engine_finished_at}}</span>`
        }] : []),
        {
          key: 'working_time',
          title: '경과시간',
          width: 90,
          template: ({ id }) => `<span class="ng-hide" ng-show="grid.appScope.workData[${$scope.tabIndex}][${id}].working_time">{{grid.appScope.workData[${$scope.tabIndex}][${id}].working_time}}</span>`
        },
        {
          key: 'state_name',
          title: '상태',
          width: 80,
          template: ({ id, state, state_msg, msg }) => {
            if (state == 'complete' || state == 'failed') {
              const txt = state_msg.trim();

              if (
                (
                  txt.includes('건 실패')
                  && !txt.includes('/ 0건 실패')
                  && (
                    !txt.includes('건 성공')
                    || txt.startsWith('0건 성공')
                  )
                  && !txt.includes('건 취소요청(발송완료)')
                  && (
                    !txt.includes('요청중')
                    || txt.includes('/ 0건 요청중')
                  )
                ) || (
                  txt.includes('')
                  && !txt.includes('/ 0건 처리실패')
                  && txt.startsWith('0건 처리성공')
                )
              ) {
                $scope.workData[$scope.tabIndex][id].state = 'loss';
                $scope.workData[$scope.tabIndex][id].state_name = '실패';
              }
            }

            return `
            <span
              class="ng-hide"
              ng-show="grid.appScope.workData[${$scope.tabIndex}][${id}].state"
              ng-class="'work-status-'+grid.appScope.workData[${$scope.tabIndex}][${id}].state">
                {{grid.appScope.workData[${$scope.tabIndex}][${id}].state_name}}
            </span>`;
          }
        },
        {
          key: 'progress',
          title: '진행률',
          width: 100,
          template: ({ id }) => (`
            <uib-progressbar animate="false" class="progress ng-hide" value="grid.appScope.workData[${$scope.tabIndex}][${id}].progressbar" ng-show="grid.appScope.workData[${$scope.tabIndex}][${id}].progressbar != '대기'">
              <span class="text-white" style="text-shadow: 1px 1px 2px #666;">{{grid.appScope.workData[${$scope.tabIndex}][${id}].progressbar}}%</span>
            </uib-progressbar>`
          )
        },
        {
          key: 'state_msg',
          title: '결과',
          width: 500,
          template: (row) => {
            if ($scope.tabIndex === 2 && row.job_type === 'AutoSetting' && row.state_msg.includes('자동 세팅 완료')) {
              $scope.workData[$scope.tabIndex][row.id].msg = `자동 세팅 완료<font color=red><b>${($rootScope.adminMode || [1762, 596].indexOf(row.sol_code) > -1) ? row.state_msg.split('자동 세팅 완료')[1] : ''}</b></font>`;
            }

            return `<span class="ng-hide" ng-show="grid.appScope.workData[${$scope.tabIndex}][${row.id}].msg" ng-bind-html="grid.appScope.workData[${$scope.tabIndex}][${row.id}].msg | testFilters"></span>`; }
        }
      ]
    };

    /***************************
     * 엑셀 & 솔루션 작업 데이터 테이블
     ***************************/
    $scope.work_sol = {};
    $scope.work_sol.methods = {};
    $scope.work_sol.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'state_name', 'progress'],
      defaultSortingColumns: ['id'],
      alignRightColumns: [],
      notSortingColumns: ['widget', 'job_name', 'sol_user_name', 'wdate', 'started_at', 'updated_at', 'working_time', 'state_name', 'progress', 'state_msg'],
      notResizingColumns: ['widget'],
      notMovingColumns: 'ALL',
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/work/list`,
        requestWillAction: data => {
          if (tabType === 'work_excelTab') {
            tabType = '';
            $scope.tabIndex = 1;
          }

          $scope.hideWorkMenu();

          data.solJobYn = true;
          data.jobType = $scope.tabIndex === 1 ? 'excel' : 'sol';

          startIdx = data.start;
          len = data.length;

          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: result => {
          const rows = result.results;

          $scope.searchData.totalCount = result.recordsTotal;

          InitBindedData();

          // 작업 내역 바인딩
          rows.forEach(function (row) {
            // 작업삭제를 위해 추가
            row.state_name = gettextCatalog.getString(workSVC.stateText[row.state]) || '오류';

            // workdata객체 초기화
            $scope.workData[$scope.tabIndex][row.id] = {
              // 실시간 데이터
              state_name: row.state_name,
              state: row.state || 'failed',
              msg: workSVC.getTypeText(row.state_msg),
              msg_detail: row.state_msg_detail,
              ...getWorkFormatTimes(row),

              // 프로그래스 없을시 이미 완료된 작업이므로 100으로 넣음
              progressbar: row.state === 'inactive' ? 0 : (row.progress || 100),

              // 1:1문의용 데이터 저장
              site_id: row.site_id,
              site_name: row.site_name,
              job_name: row.job_name,
              create_date: row.created_at,
              job_type: row.job_type,
              job_file: row.job_file,
              result_file: row.result_file,

              worklog_job_no: row.worklog_job_no,
              uuid: row.uuid,

              isQuick: row.save_job_data?.isQuick || false
            };

            if (row.id === $scope.selectedJobId) {
              $scope.detail(row.id);
            }
          });

          return rows;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 85,
          template: row => {
            let contents = '';
            const isNotComplete = `grid.appScope.workData[${$scope.tabIndex}][${row.id}].state_name !== '완료'`;
            const isNotFailed = `['실패', '오류'].indexOf(grid.appScope.workData[${$scope.tabIndex}][${row.id}].state_name) === -1`;

            if (row.job_type === 'ScrapProd') {
              contents = `
                <button ng-disabled="${isNotComplete}" ng-click="grid.appScope.locationScrapMatchPage('${row.site_id}','${row.site_code}')" class="btn btn-default btn-xxs details-control">
                  매칭
                </button>
              `;
            }

            return (`
              <button ng-disabled="${isNotComplete} && ${isNotFailed}" ng-click="grid.appScope.detail(${row.id})" class="btn btn-default btn-xxs">
                상세
              </button>
              ${contents}`
            );
          }
        },
        {
          key: 'id',
          title: 'NO',
          width: 100,
          notCompile: true,
          template: ({ worklog_job_no, id, del_yn, uuid, result }) => {
            let viewID = worklog_job_no;

            if ($rootScope.adminMode) {
              if (result) {
                viewID = `${viewID} (${id}-${result})`;
              } else {
                viewID = `${viewID} (${uuid || id || ''})`;
              }
            }

            return `<span${del_yn == 1 ? ' style="color:blue"' : ''}>${viewID}</span$>`;
          }
        },
        {
          key: 'job_name',
          title: '작업명',
          width: 250,
          notCompile: true,
          template: function(row) {
            let job_name = '';
            let prodScrap_type = ''; //상품수집: 기간수집/특정상품수집
            let quickJob = '';

            if (row.job_type === 'RegistOnlineProductExcel' || row.job_type === 'EditOnlineProductExcel') {
              if (row.std_ol_yn === 2) {
                job_name = '[물류] ';
              } else if (row.std_ol_yn === 1) {
                job_name = '[단일] ';
              } else {
                job_name = '[일반] ';
              }
              work_job_type.push({ job_id: row.id, job_type: job_name });
            }

            // 상품수집인 경우 기간수집, 특정상품수집 출력
            if (row.job_type === 'ScrapProd') {
              prodScrap_type = row.save_job_data.codeScrapYn ? ' (특정상품수집)' : ' (기간수집)';
            }

            // job_name 에 일반/단일 여부, 기간상품/특정상품수집 여부 추가
            if ($scope.workData[$scope.tabIndex][row.id]) {
              $scope.workData[$scope.tabIndex][row.id].job_name = job_name + $filter('translate')(row.job_name) + prodScrap_type;
            }

            // 퀵작업 여부 출력. $scope.workData > job_name 은 건들이지 않는다.
            // '주문수집' 등과 같이 작업명 완전일치로 확인하는 처리가 있음.
            if (row.save_job_data?.isQuick) {
              quickJob = ' (퀵수집)';
            }

            const solJob = ($rootScope.adminMode || [1762, 596].indexOf(row.sol_code) > -1) && row.worklog_sol_id ? ` <font color=red>(솔루션 작업번호 : ${row.worklog_sol_id})</font>` : '';

            if (row.save_job_data?.isQuickProdAdd) {
              quickJob += ' (퀵등록)';
            }

            return job_name + $filter('translate')(row.job_name) + `<font color=red><b>${prodScrap_type}</b></font>` + `<span class='text-primary'>${quickJob}</span>` + solJob + (row.del_yn == 1 ? ' (비노출)' : '');
          }
        },
        {
          key: 'sol_user_name',
          title: '작업자',
          width: 120,
          notCompile: true,
          template: row => {
            let sol_user_name = row.sol_user_name;

            switch (row.job_group) {
              case 'CRON':
                sol_user_name = '시스템(자동)';
                break;

              case 'openapi':
                sol_user_name = 'OPEN-API';
                break;

              case 'AUTO':
                sol_user_name = sol_user_name + '(자동)';
                break;
            }

            return sol_user_name;
          }
        },
        {
          key: 'wdate',
          title: '등록시간',
          width: 100,
          notCompile: true,
          template: ({ wdate }) => moment(wdate).format('MM-DD HH:mm:ss')
        },
        {
          key: 'started_at',
          title: '시작시간',
          width: 100,
          template: ({ id }) => `<span class="ng-hide" ng-show="grid.appScope.workData[${$scope.tabIndex}][${id}].start_date">{{grid.appScope.workData[${$scope.tabIndex}][${id}].start_date}}</span>`
        },
        {
          key: 'updated_at',
          title: '종료시간',
          width: 100,
          template: ({ id }) => `<span class="ng-hide" ng-show="grid.appScope.workData[${$scope.tabIndex}][${id}].end_date">{{grid.appScope.workData[${$scope.tabIndex}][${id}].end_date}}</span>`
        },
        {
          key: 'working_time',
          title: '경과시간',
          width: 90,
          template: ({ id }) => `<span class="ng-hide" ng-show="grid.appScope.workData[${$scope.tabIndex}][${id}].working_time">{{grid.appScope.workData[${$scope.tabIndex}][${id}].working_time}}</span>`
        },
        {
          key: 'state_name',
          title: '상태',
          width: 80,
          template: ({ id, state, state_msg, msg }) => {
            if (state == 'complete' || state == 'failed') {
              const txt = state_msg.trim();

              if (
                (
                  txt.includes('건 실패')
                  && !txt.includes('/ 0건 실패')
                  && (
                    !txt.includes('건 성공')
                    || txt.startsWith('0건 성공')
                  )
                  && !txt.includes('건 취소요청(발송완료)')
                  && (
                    !txt.includes('요청중')
                    || txt.includes('/ 0건 요청중')
                  )
                ) || (
                  txt.includes('')
                  && !txt.includes('/ 0건 처리실패')
                  && txt.startsWith('0건 처리성공')
                )
              ) {
                $scope.workData[$scope.tabIndex][id].state = 'loss';
                $scope.workData[$scope.tabIndex][id].state_name = '실패';
              }
            }

            return `
            <span
              class="ng-hide"
              ng-show="grid.appScope.workData[${$scope.tabIndex}][${id}].state"
              ng-class="'work-status-'+grid.appScope.workData[${$scope.tabIndex}][${id}].state">
                {{grid.appScope.workData[${$scope.tabIndex}][${id}].state_name}}
            </span>`;
          }
        },
        {
          key: 'progress',
          title: '진행률',
          width: 100,
          template: ({ id }) => (`
            <uib-progressbar animate="false" class="progress ng-hide" value="grid.appScope.workData[${$scope.tabIndex}][${id}].progressbar" ng-show="grid.appScope.workData[${$scope.tabIndex}][${id}].progressbar != '대기'">
              <span class="text-white" style="text-shadow: 1px 1px 2px #666;">{{grid.appScope.workData[${$scope.tabIndex}][${id}].progressbar}}%</span>
            </uib-progressbar>`
          )
        },
        {
          key: 'state_msg',
          title: '결과',
          width: 500,
          template: (row) => {
            if ($scope.tabIndex === 2 && row.job_type === 'AutoSetting' && row.state_msg.includes('자동 세팅 완료')) {
              $scope.workData[$scope.tabIndex][row.id].msg = `자동 세팅 완료<font color=red><b>${($rootScope.adminMode || [1762, 596].indexOf(row.sol_code) > -1) ? row.state_msg.split('자동 세팅 완료')[1] : ''}</b></font>`;
            }

            return `<span class="ng-hide" ng-show="grid.appScope.workData[${$scope.tabIndex}][${row.id}].msg" ng-bind-html="grid.appScope.workData[${$scope.tabIndex}][${row.id}].msg | testFilters"></span>`; }
        }
      ]
    };

    /**
     * 작업의 전체 상황 정보를 갱신
     * 2018-04-03 MatthewKim
     */
    $rootScope.update_work_view = _.debounce(function () {
      const workData = $scope.workData[$scope.tabIndex];

      const cnt = _.filter(workData, function (o) {
        return o.state == 'active';
      }).length;

      workSVC.info.working_cnt = cnt;
      workSVC.info.is_working = cnt > 0;
      workSVC.info.total_working_cnt = _.keys(workData).length;
      workSVC.info.orderSync = _.some(workData, function (o) {
        return o.job_name == '주문동기화';
      });
      workSVC.info.orderScrap = _.some(workData, function (o) {
        return o.job_name == '주문수집';
      });

      workSVC.updateView(); // jquery 로 처리하는 갱신이므로 $timeout 에 넣지 않음
    }, 300);

    /**
     * 작업관리 리스트 새로고침
     * 여러번 들어와도 3초에 1번만 실행
     * 2018-06-12 chris
     */
    $rootScope.update_work_list = _.debounce($scope.searchDo, 3000);

    /**
     * 작업 실시간 갱신 row 단위로 즉시 갱신
     *
     * 2018-04-03 MatthewKim
     * @param {*} new_data_one
     */
    $rootScope.update_work_row = function (new_data_one) {
      // $timeout 에 넣는 이유는 앵귤러 다이제스트 큐에 넣기 위함 (watch 가 아닌, 갱신 사실을 전달하려면 큐에 넣어야함)
      $timeout(function () {

        // 새 데이터 row 값
        const workData = $scope.workData[$scope.tabIndex];
        const pattern = /[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}/i;
        let _no = new_data_one.id;

        // pa5 작업인경우 worklog_job_no 확인.
        if (pattern.test(new_data_one.id)) {
          for (const workKey in workData) {
            const workInfo = workData[workKey];

            if (workInfo.uuid === new_data_one.id) {
              _no = workKey;
              break;
            }
          }
        }

        const data = workData[_no];

        // 리스트에 없는 작업 => (신규 row 추가 반영 해야 하므로 새로고침 )
        // 리스트에 있는 작업 => (변경)
        if (data) {
          // 엑셀작업은 푸셔로 들어와서 먼저쏜데이터가 나중에 올 때가 있어서 그전게 오면 업데이트 안함
          // pa5 도 동일 증상이 있어 전체 작업 적용. 2019-12-10 rony
          // if ($scope.tabIndex === 1 && data.progressbar > new_data_one.progress) {
          if (data.progressbar > new_data_one.progress) {
            return;
          }

          // var c = floorMs(new_data_one.created_at);
          const s = floorMs(new_data_one.started_at); // 시작시간
          const u = floorMs(new_data_one.updated_at); // 종료시간

          data.msg = workSVC.getTypeText(new_data_one.state_msg);
          data.msg_detail = new_data_one.state_msg_detail;
          data.state_name = gettextCatalog.getString(workSVC.stateText[new_data_one.state]);
          data.state = new_data_one.state;

          // 진행중
          if ((new_data_one.started_at || data.start_date === '대기중') && new_data_one.state == 'active') {
            // 시간이 안넘어오는경우 현재시간으로 설정.
            if (Number.isNaN(s)) {
              data.start_date = moment().format('MM-DD HH:mm:ss');
            } else {
              data.start_date = moment(s).format('MM-DD HH:mm:ss');
            }
            data.end_date = '작업중';
            data.working_time = '작업중'; // 2017-11-29 Daniel 작업시간 추가
          }

          // 작업완료시 종료일 갱신
          else if (new_data_one.state == 'complete' || new_data_one.state == 'failed') {
            data.end_date = moment(parseInt(new_data_one.updated_at)).format('MM-DD HH:mm:ss');
            data.working_time = moment.utc(moment(parseInt(new_data_one.updated_at)).diff(moment(parseInt(new_data_one.started_at)), 'seconds') * 1000).format('HH:mm:ss');
            data.result_file = new_data_one.result_file;
            const txt = new_data_one.state_msg?.trim() || '';

            if (
              (
                txt.includes('건 실패')
                && !txt.includes('/ 0건 실패')
                && (
                  !txt.includes('건 성공')
                  || txt.startsWith('0건 성공')
                )
                && !txt.includes('건 취소요청(발송완료)')
                && (
                  !txt.includes('요청중')
                  || txt.includes('/ 0건 요청중')
                )
              ) || (
                txt.includes('')
                && !txt.includes('/ 0건 처리실패')
                && txt.startsWith('0건 처리성공')
              )
            ) {
              data.state = 'loss';
              data.state_name = '실패';
            }
          }

          data.progressbar = new_data_one.progress;

          // 2017-11-29 Daniel 작업시간으로 변경
          // if(new_data_one.elapsed_time != 0 && new_data_one.elapsed_time != ''){
          //   workData.elapsed_time[_no] = moment(new_data_one.elapsed_time).fromNow();
          // }else{
          //   workData.elapsed_time[_no] = '-';
          // }
        }

        // 전체 공통 사항 갱신 (작업건수, 진행중표시 등등)
        $rootScope.update_work_view();

      });

    };

    /**
     * 밀리세컨드 단위 잘라내기
     */
    function floorMs(ms) {
      return _.floor(ms / 1000) * 1000;
    }

    /**
    * 데이터테이블 pageLength 조절
    */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    // 작업 시간 포매팅
    function getWorkFormatTimes (row) {
      const state = row.state;
      const started_at = row.started_at;
      const updated_at = row.updated_at;
      const engine_finished_at = row.engine_finished_at;

      const data = {};

      // 작업결과 저장이 제대로 안됐을시
      if (!row.state) {
        data.end_date = '알수없음';
        data.working_time = '알수없음';
        data.msg = '알수없음';
      }
      // 대기
      else if (state === 'inactive') {
        data.start_date = '대기중';
        data.end_date = '대기중';
        data.working_time = '대기중';
      }
      // 진행중
      else if (state === 'delayed' || state === 'active') {
        data.start_date = moment(started_at).format('MM-DD HH:mm');
        data.engine_finished_at = moment(engine_finished_at).isValid() ? moment(engine_finished_at).format('MM-DD HH:mm:ss') : '';
        data.end_date = '작업중';
        data.working_time = '작업중'; // 2017-11-29 Daniel 작업시간으로 변경
      // 완료 또는 실패시에만 시간출력
      } else {
        data.start_date = moment(started_at).isValid() ? moment(started_at).format('MM-DD HH:mm:ss') : '';
        data.end_date = moment(updated_at).isValid() ? moment(updated_at).format('MM-DD HH:mm:ss') : '';
        data.engine_finished_at = moment(engine_finished_at).isValid() ? moment(engine_finished_at).format('MM-DD HH:mm:ss') : '';
        data.working_time = data.start_date && data.end_date ? moment.utc(moment(updated_at).diff(moment(started_at), 'seconds') * 1000).format('HH:mm:ss') : '';

        if (!data.start_date || !data.end_date) {
          data.msg = '일시적인 오류로 작업이 중지되었습니다. 작업 재시도 부탁드립니다.';
        }
      }

      return data;
    }

    /**
     * grid 옵션
     */

    /**
     * 탭변경
     */
    $scope.changeTab = function (index) {
      $scope.tabIndex = index;
      $scope.isWorkTab = index === 0;

      const type = index === 0 ? 'work' : 'work_sol';
      $scope.grid = $scope[type];
      // 초기 로드시 데이터 바인딩 제대로 안되는 이슈가 있어서 추가함
      $scope[`${type}Init`] = true;

      if (index > 0) {
        $scope.searchData.search_multi_items = [
          { label: '상태', value: 'state_name' },
          { label: '작업번호', value: 'id' },
        ];
      }

      $scope.resetDo();

      $timeout(() => {
        // 엑셀 작업 탭 이동시 스크롤이 우측으로 이동하는 오류 때문에 리렌더링 1회 추가
        if (!work_solInit && index !== 0) {
          work_solInit = true;
          $scope.grid.methods.reDraw();
        }
      });
    };

    /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    /**
     * 작업취소
     */
    $scope.workCancel = async (job_datas) => {
      const confirm = await commonSVC.showConfirm('작업 취소요청을 하시겠습니까?', '');

      if (confirm) {
        try {
          const restoreArr = []; // 카운트 복구 작업번호 배열
          let success_cnt = 0,
              fail_cnt = 0;

          const cancelPromise = async (job_id, job_uuid) => {
            if (job_uuid) {
              const r = await commonModel.pa5(`/jobs/${job_uuid}`, {}, '', '', 'DELETE');

              if (!r.data.hasOwnProperty('statusCode')) {
                success_cnt++;
                restoreArr.push(job_id);
              } else {
                fail_cnt++;
              }
            } else {
              const r = await workSVC.cancel(job_id);

              if (r.status === 200 && r.data.success) {
                success_cnt++;
                restoreArr.push(job_id);
              } else {
                fail_cnt++;
              }
            }

          };

          $q.all(job_datas.map(({ id, uuid }) => cancelPromise(id, uuid)))
            .then(() => {
              if (!success_cnt) {
                throw 'none canceled';
              } else {
                $scope.searchDo();

                // 작업카운트 복구
                return workSVC.restoreCnt([restoreArr])
                  .then(() => {
                    commonSVC.showToaster('success', '작업취소 완료', `성공: ${success_cnt}, 실패: ${fail_cnt}`);
                  })
                  .catch(() => {
                    commonSVC.showToaster('error', '실패', '작업카운트 복구에 실패하였습니다.');
                  });
              }
            })
            .catch(err => {
              if (err.message && err.message.includes('none canceled')) {
                commonSVC.showToaster('error', '실패', '작업취소 가능한 건이 없습니다.');
              } else {
                throw err;
              }
            });
        } catch (err) {
          commonSVC.showToaster('error', '실패', '작업취소요청에 실패하였습니다.');
        }
      }
    };

    async function workDelete (works) {
      try {
        await workSVC.delete(works.reduce((aur, w) => {
          if (w.del_yn == 1 || !['완료', '실패', '오류'].includes(w.state_name)) {
            aur.push(w);
          }

          return aur;
        }, []));

        const url = `${settings.pa20ApiUrl}/app/work`,
              params = { works: works, jobType: !$scope.isWorkTab ? ($scope.tabIndex === 1 ? 'excel' : 'sol') : 'shop' };

        await commonSVC.sendUrl('DELETE', url, params);

        const total = $scope.searchData.totalCount, // 데이터 총 개수
              currentPage = startIdx / len + 1, // 현재 페이지
              totalPage = total % len ? _.parseInt(total / len) + 1 : _.parseInt(total / len); // 전체 페이지
        let pageResetYn = false;

        // 작업알림 삭제이벤트 전송
        $rootScope.$broadcast('deleteWork');

        commonSVC.showToaster('success', '성공', '작업이 삭제 되었습니다.');

        // 마지막 페이지고 마지막 페이지에 있는 작업 다 삭제시 첫페이지 로드
        if (currentPage === totalPage && works.length === (total % len) && currentPage !== 1) {
          pageResetYn = true;
        }

        $scope.grid.methods.reloadData(function () {}, pageResetYn);

        // 삭제한 작업이 진행중인 자동세팅인 경우 쇼핑몰 상품 리스트 정상 출력하게 수정
        if (works.some(work => work.job_name === '자동세팅' && work.state_name === '진행중')) {
          localStorage.setItem(`isOnlineListLoading_${userInfo.user.sol_no}`, false);
          $rootScope.isOnlineProdLoading = false;
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '작업 삭제에 실패하였습니다.');
      }
    }

    /**
     * 작업삭제
     */
    $scope.workDelete = async (works) => {
      const confirm = await commonSVC.showConfirm('작업을 삭제하시겠습니까?', '');

      if (confirm) {
        // 작업 삭제
        await workDelete(works);
      }
    };

    /**
     * 전체 작업삭제
     * 2018-11-07 rony
     */
    $scope.workPurge = function () {
      commonSVC.showConfirm('전체 작업을 삭제하시겠습니까?', '대기,완료,실패,오류 상태의 작업만 삭제됩니다.', function () {
        // 작업 삭제
        workSVC.purge(function (result) {
          if (result == 'success') {
            commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/work/all`, { jobType: !$scope.isWorkTab ? ($scope.tabIndex === 1 ? 'excel' : 'sol') : 'shop' }, function (state) {
              if (state == 'success') {
                // 작업알림 삭제이벤트 전송
                $rootScope.$broadcast('deleteWork');

                commonSVC.showToaster('success', '성공', '전체 작업이 삭제 되었습니다.');
                $scope.grid.methods.reloadData(function () { }, false);
              } else {
                commonSVC.showToaster('error', '실패', '전체 작업 삭제에 실패하였습니다.');
              }
            });
          } else {
            commonSVC.showToaster('error', '실패', '전체 작업 삭제에 실패하였습니다.');
          }
        });
      });
    };

    /**
     * 2018-07-10 Daniel
     * 작업 재실행
     */
    $scope.workReAdd = function (rows) {

      // 작업 재실행 권한 확인. 2019-01-04 rony
      if (commonSVC.checkPermission('work.roles.restart', userInfo.permission) === false) {
        return false;
      }

      if (!$scope.isWorkTab) {
        commonSVC.showMessage('작업 재실행 실패', '엑셀, 솔루션 작업은 재실행이 불가능합니다.');

        return false;
      }

      const row_data = rows ? [rows] : $scope.grid.methods.selectedData('all');
      const workData = $scope.workData[$scope.tabIndex];

      // 바인딩된 데이터의 상태를 가져옴
      _.each(row_data, function (row) {
        row.state_name = workData[row.id].state_name;
      });

      if (!row_data.length) {
        commonSVC.showMessage('재실행하실 작업을 선택해 주세요.');

        return false;
      }

      // 공통 함수로 검사
      const checkValue = commonSVC.checkRulesAndConfirm(
        'reAdd',
        '작업 재실행',
        $scope.grid.methods,
        row_data,
        $scope.searchData.totalCount,
        'state_name',
        ['완료', '실패', '오류'],
        []
      );

      if (!checkValue.isOpen) {
        return false;
      }

      const works = checkValue.list;

      // 등록, 수정, 전송 작업들은 실패, 오류만 재실행 가능
      // ESMPLUS작업들은 재실행 가능
      let ESMP = false;
      const typeFilter = _.filter(works, function (work) {
        const job_name = work.job_name,
              state = work.state;

        if (job_name.match(/ESMPLUS/)) {
          ESMP = true;

          return false;
        }

        return job_name.match(/(등록|수정|요청)/) && state === 'complete';
      });

      if (typeFilter.length) {
        let msg = '등록, 수정, 요청 작업은 실패, 오류시에만 재실행할수 있습니다.';

        if (ESMP) {
          msg += '\nESMPLUS작업 재실행을 원하실경우 재실행 가능한 작업들과 따로 선택시 재실행 가능합니다.';
        }

        commonSVC.showMessage(msg);

        return false;
      }

      // 재실행 불가 작업
      const imposReAddWorks = [
        'RegistProdRe',     // 상품재판매
        'ExtentProd',       // 판매기간연장
        'RegistGroupProd',  // 그룹상품등록
        'EditGroupProd',    // 그룹상품수정
        'DeleteGroupProd'   // 그룹상품삭제
      ];

      const imposReAddWorkNames = _(works)
        .remove(function(work) {
          return _.includes(imposReAddWorks, work.job_type);
        })
        .map('job_name')
        .uniq()
        .value();

      let reAddConfirm;

      if (imposReAddWorkNames.length) {
        if (!works.length) {
          commonSVC.showMessage('실패', `${imposReAddWorkNames.join(', ')}은(는) 작업재실행이 불가합니다.`);

          return;
        } else {
          reAddConfirm = commonSVC.showConfirm('실패', `${imposReAddWorkNames.join(', ')}은(는) 작업재실행이 불가합니다.\n가능한 작업만 재실행 하시겠습니까?`);
        }
      } else {
        reAddConfirm = commonSVC.showConfirm('주의', '선택한 작업을 재실행하시겠습니까?');
      }

      reAddConfirm
        .then(function (confirm) {
          if (!confirm) {
            return;
          }

          const work_nos = _.map(works, 'id');

          workSVC.workDetail(work_nos)
            .then(function (res) {

              const results = _.groupBy(res.data.logs, 'work_no');

              const reAddWorkProms = [];

              _.each(works, function (work) {
                const worklogs = results[work.id],
                      site_code = work.site_code;
                let param;

                if (site_code === 'ESMP') {
                  const account = _.find(ebaydepotAccounts, { ebaydepot_id: work.site_id }),
                        uniqs = _.map(worklogs, 'uniq');

                  param = {
                    site: {
                      site_code: 'ESMP',
                      site_name: 'G마켓 물류',
                      site_id: account.ebaydepot_id,
                      site_pwd: account.ebaydepot_pwd,
                      playapi_runner_os: 'AWS',
                      uuid: account.uuid
                    },
                    data: {
                      uniqs: uniqs
                    }
                  };

                  reAddWorkProms.push(workSVC.addEbaydepotWork(work.job_type, param));
                } else {
                  param = {
                    site_code: work.site_code,
                    site_id: work.site_id,
                    id: work.id,
                    std_ol_yn: work.std_ol_yn
                  };
                  if (worklogs[0].save_job_data) {
                    // 재작업시에는 퀵작업 플래그 제거
                    delete worklogs[0].save_job_data.isQuick;
                    delete worklogs[0].save_job_data.isQuickProdAdd;

                    param = worklogs[0].save_job_data;
                  }
                  // ol_shop_no를 조회해서 같이 보내야함
                  if (work.job_cate === 'PROD') {
                    // sale_no 0으로 들어오는거 제거
                    param.prod_list = _(worklogs).map('sale_no').compact().value();
                  }

                  if (work.job_type === 'EditProd') {
                    param.edit_col.isEditName = param.edit_col.is_titles;
                    param.edit_col.isEditPrice = param.edit_col.is_price;
                    param.edit_col.isEditOpt = param.edit_col.is_options;
                    param.edit_col.isEditAddopt = param.edit_col.is_addon_options;
                    param.edit_col.isEditContent = param.edit_col.is_contents;
                    param.edit_col.isEditImage = param.edit_col.is_images;

                    if (param.edit_col.hasOwnProperty('is_next_delivery')) {
                      param.edit_col.isEditNextDelivery = param.edit_col.is_next_delivery;
                    }
                  }

                  reAddWorkProms.push(workSVC.addWork(work.job_type, param));
                }
              });

              $q.all(reAddWorkProms)
                .then(async re => {
                  if (re[0]?.status === 200) {
                    commonSVC.showToaster('success', '성공', '작업이 등록 되었습니다.\n상세 내용은 좌측 [작업] 탭에서 반드시 확인해주세요.');
                  }
                  $scope.searchDo();
                });
            });
        });
    };

    /**
     * 작업번호로 재실행
     */
    $scope.workReAddByNo = function (work_no) {
      const rows = $scope.grid.methods.filteredData({ id: String(work_no) });

      $scope.workReAdd(rows);
    };

    /**
     * 상태별 이동
     */
    $scope.stateGo = function () {
      $scope.detailLog.show = false;
      $scope.searchDo();
    };

    $scope.syncedOrderGo = (multi_search_values, shop_cd, shop_id) => {

      const selectShopData = siteIdList.find(shop => shop.shop_cd === shop_cd && shop.shop_id === shop_id);

      $rootScope.work_search = {
        multi_search_cds: multi_search_values.split('|'),
        select_shop_cd: selectShopData.pa_shop_cd.startsWith('X') ? selectShopData.pa_shop_cd : shop_cd,
        select_shop_id: selectShopData.search_shop_id,
        search: true
      };

      $rootScope.work_search.page = 'integrated';
      $state.go('main.order_shipment_integrated_list');
      $rootScope.$broadcast('multiSearchWorkIntegrated');
    };

    $scope.hyperLink = (shop_sale_no, std_ol_yn) => {
      $rootScope.excel_work_search = {};
      $rootScope.excel_work_search.sdate = '2001-01-01';
      $rootScope.excel_work_search.edate = moment().format('YYYY-MM-DD');
      $rootScope.excel_work_search.date_type = 'wdate';
      $rootScope.excel_work_search.search = true;

      // 쇼핑몰 상품코드가 있으면 그걸로 탭 넘기기
      $rootScope.excel_work_search.is_master = false;
      $rootScope.excel_work_search.key = 'shop_sale_no';
      $rootScope.excel_work_search.value = shop_sale_no;

      // 단일상품인지 일반상품인지 판별
      $rootScope.excel_work_search.is_single = !!std_ol_yn;

      $rootScope.excel_work_search.page = 'online';
      $state.go('main.online_product_list');
      $rootScope.$broadcast('excelWorkDetail_online');
    };

    // 작업 상세로그 출력
    $scope.detail_msg = '<p class="text-warning">상세 버튼 클릭 시 출력 됩니다.</p>';
    $scope.detail = id => {
      $scope.selectedJobId = id;

      const work = $scope.workData[$scope.tabIndex][id],
            jobId = `<span class="text-bold"><${id}></span>`;

      let failChk = false,
          result_type = '완료',
          headMsg = '',
          msg = '',
          msgDetail = '',

          jobName = `${jobId} ${work.job_name}`,
          etcBtn = '';

      if (!$scope.tabIndex) {
        etcBtn = `<button type="button" class="label border-primary text-primary bg-white ml-20" ng-click="openQuestion(${id})">작업결과 1:1문의</button>`;
      } else {
        etcBtn = `<button type="button" class="label border-primary text-primary bg-white ml-20" ng-click="openQuestion(${id},'${work.result_file}')">작업결과 1:1문의</button>`;
      }

      workSVC.workDetail([id])
        .then(function (res) {
          const resStatus = res.status,
                logs = res.data.logs,
                cSaleCds = res.data.cSaleCds,
                msgs = _.map(logs, 'msg').join('');

          let typeReg = '';
          const out_stock = [];

          if (resStatus === 200 && logs.length && msgs && ($scope.isWorkTab || ['AutoSoldOut'].includes(work.job_type))) {
            // 로그메세지 정리
            // 재고부족 상품 리스트

            _.each(logs, function (v) {
              if (v.msg) {
                if (v.msg.includes('재고부족 SKU: ')) {
                  out_stock.push((v.msg.split('재고부족 SKU: '))[1]);
                } else if (work.job_type === 'AutoSoldOut') {
                  const log = JSON.parse(v.msg);

                  for (const l of log) {
                    if (l.fail_shop_sale_no) {
                      msg += `<span class="text-danger">실패</span>  - 상품코드: [${l.fail_shop_sale_no}] > 옵션(<a ng-click="goSoldoutHistory('${l.fail_sku_cd}')">${l.fail_sku_cd}</a>) ${l.errMsg ? `사유: ${l.errMsg}` : ''} <br />`;
                    } else {
                      msg += `<span class="text-primary">성공</span> - 상품코드: [${l.shop_sale_no}] > 옵션(<a ng-click="goSoldoutHistory('${l.sku_cd}')">${l.sku_cd}</a>) <br />`;
                    }
                  }
                } else if (v.msg.includes('- 재고부족: ')) {
                  out_stock.push((v.msg.split('- 재고부족: '))[1]);
                  msg += `${v.msg} <br />`;
                } else {
                  msg += `${v.c_sale_cd ? `${v.c_sale_cd} : ` : ''}${v.msg}<br>`;
                }

                typeReg = work.msg.match(/(\d)건 (실패|처리실패)/);

                if (!failChk && typeReg && parseInt(typeReg[1]) > 0) {
                  failChk = true;
                  result_type = '실패';
                  // $scope.detailMsg[vdata.job_id] = msg;
                }
                // 실패, 오류건도 1:1 문의버튼 노출되도록 처리 2018-04-30 rony
                else if (!failChk && (work.state_name == '실패' || work.state_name == '오류')) {
                  failChk = true;
                }
              }
              if (v.save_job_data?.detail) {
                // 작업 결과 상세 처리
                switch (work.job_type) {
                  case 'SyncOrderState':
                    msgDetail = Object.keys(v.save_job_data.detail).map(k => `<b>${k}</b>:<span style="white-space: pre;">&#9;<b style='color:blue;cursor:pointer' ng-click="syncedOrderGo('${v.save_job_data.detail[k].join('|')}','${logs[0].shop_cd}','${work.site_id}')"><u>${v.save_job_data.detail[k].length}</u></b>건`).join('<span style="white-space: pre;">&#9;&#9;</span>');
                    break;
                }
              }

              // 상품수집 작업인경우 상품수집일자 표시
              if (v.save_job_data?.site_action === 'ScrapProd' && !!v.save_job_data?.ProdScrapDate) {
                if (v.save_job_data.ProdScrapDate === 'custom') {
                  const { custom_sdate, custom_edate } = typeof v.save_job_data.ProdScrapCustomDate === 'string' ? JSON.parse(v.save_job_data.ProdScrapCustomDate) : v.save_job_data.ProdScrapCustomDate;
                  msgDetail = `<b>[직접입력]</b> ${custom_sdate} ~ ${custom_edate}`;
                } else {
                  const startYYYYMMDD = moment(v.wdate).format('YYYY-MM-DD');
                  msgDetail = `<b>[${v.save_job_data.ProdScrapDate}년]</b> ${moment(startYYYYMMDD).add(-Number(v.save_job_data.ProdScrapDate), 'years').format('YYYY-MM-DD')} ~ ${startYYYYMMDD}`;
                }
              }
            });

            // 문의 전송 작업의 경우 성공 실패와 내용 같이 표시
            if (['SendCS', 'SendEmergencyCS', 'SendReview'].includes(work.job_type)) {
              msg = `${work.msg}<br />${msg}`;
            }
            // 재고부족 상품이 있는경우 한 줄로 합침
            if (out_stock.length) {
              etcBtn += `<button type="button" class="label border-primary text-primary bg-white ml-10" ng-click="open_prodList('${_.compact(_.uniq(out_stock)).join(',')}')">품절상품 멀티검색</button>`;
            }
          } else if (!$scope.isWorkTab && $scope.tabIndex === 2) {
            msg = work.msg;
            result_type = msg.includes('완료') || msg.includes('성공') || msg.includes('변경사항이 없습니다') ? '완료' : '실패';
            if (['OnlineToMasterAutoMatch', 'ProdToSkuProd'].includes(work.job_type) && work.result_file) {
              etcBtn += `<button type="button" class="label border-primary text-primary bg-white ml-5" onClick="window.open('${work.result_file}')">결과 엑셀 다운로드</button>`;
            }
          }
          // 작업 로그 없으면 메세지 출력
          else {
            msg = work.msg;
            if (work.job_type === 'EditOnlineLinkageExcel') {
              if (work.msg_detail) {
                try {
                  work.msg_detail = JSON.parse(work.msg_detail);

                  const hyperLink = work.msg_detail.shop_sale_no ? `<span style="white-space: pre;"><b style="color:blue;cursor:pointer" ng-click="hyperLink('${work.msg_detail.shop_sale_no}', ${work.msg_detail.std_ol_yn})"><u>${work.msg_detail.shop_sale_no}</u></b></span>` : '';

                  msgDetail = `상품상태 동기화에 실패하였습니다. ${errorSVC.getError('work', work.msg_detail.error, work.msg_detail.message)} ${hyperLink}`;
                } catch (error) {
                  msgDetail = work.msg_detail;
                }
              }
            } else if (work.msg_detail) {
              msgDetail = work.msg_detail.replace(/\n/g, '<br />');
            }
            typeReg = msg.match(/\d건 (성공|처리성공|완료)/);

            if (!failChk && !typeReg && !msg.includes('변경사항이 없습니다')) {
              failChk = true;
              result_type = '실패';
              // $scope.detailMsg[vdata.job_id] = msg;
            } else {
              typeReg = msg.match(/(\d)건 (실패|처리실패)/);

              if (!failChk && typeReg && parseInt(typeReg[1]) > 0) {
                failChk = true;
                result_type = '실패';
                // $scope.detailMsg[vdata.job_id] = msg;
              }
              // 실패, 오류건도 1:1 문의버튼 노출되도록 처리 2018-04-30 rony
              else if (!failChk && (work.state_name == '실패' || work.state_name == '오류')) {
                failChk = true;
              }
            }
          }

          // 작업에러 메세지 추가 2018-04-26 rony
          work.addMsg = msg;
          // 상품부분수정,상품상태동기화 작업인경우 어떤항목을 수정하는지 보여줌 2018-09-13 rony
          let editFieldMsg = '';

          if (work.job_type === 'EditProd') { // 부분수정
            const fieldName = {
              isEditName: '상품명',
              isEditPrice: '판매가',
              isEditOpt: '옵션',
              isEditAddopt: '추가구매옵션',
              isEditContent: '상세설명',
              isEditImage: '이미지',
              isEditNextDelivery: '내일 배송 가능 여부',
              is_titles: '상품명',
              is_price: '판매가',
              is_options: '옵션',
              is_addon_options: '추가구매옵션',
              is_contents: '상세설명',
              is_images: '이미지',
              is_next_delivery: '내일 배송 가능 여부',
            };

            editFieldMsg = ` [${Object.keys(logs[0].save_job_data.edit_col).filter(key => logs[0].save_job_data.edit_col[key]).map(field => fieldName[field])}]`;
          } else if (work.job_type === 'SyncProdState') { // 상품상태 동기화
            const fieldName = {
              site_stock: '판매수량',
              reg_edate: '상품 판매종료일',
              site_sprice: '판매가격',
              state: '상품상태',
              next_delivery: '내일 배송 가능 여부',
              images: '이미지'
            };

            // open-api로 작업 시 sync_data명칭 치환처리
            const sync_data = logs[0].save_job_data.sync_data;
            let syncData;
            if (Array.isArray(sync_data)) {
              syncData = sync_data;
            } else if (typeof sync_data === 'object' && sync_data !== null) {
              syncData = [];
              for (const key in sync_data) {
                if (sync_data[key]) {
                  switch (key) { // 키별로 치환할 값 지정
                    case 'count':
                      syncData.push('site_stock');
                      break;
                    case 'price':
                      syncData.push('site_sprice');
                      break;
                    case 'period':
                      syncData.push('reg_edate');
                      break;
                    case 'status':
                      syncData.push('state');
                      break;
                    default:
                      break;
                  }
                }
              }
            } else {
              syncData = ['state', 'site_stock', 'reg_edate', 'site_sprice'];
            }
            editFieldMsg = ` [${syncData.map(field => fieldName[field])}]`;
          }

          /**
           * 2018-07-09 Daniel
           * ESMPLUS 발송정보 전송/수정 실패시 출력메세지
           * pa5 적용후에는 해당 처리가 필용없기 때문에 삭제 필요함.
           * pa5 전체 적용 후 삭제.
           */
          if (work.job_type === 'SendOrderInfo' && result_type === '실패' && ![1, 15441, 3389, 16044, 596, 2319, 3104].includes($rootScope.user_profile.sol_no)) {
            msg = `<button class="btn text-primary bg-white border-primary mr-10" style="padding:1px 7px; font-size:10px;" ng-click="workReAddByNo(${id})">작업 재실행</button><span class="text-warning display-inline-block mb-10">발송정보 중 1건이 등록 실패한 경우 함께 전송한 모든 발송정보가 등록되지 않습니다. 아래에서 주문번호, 실패사유 확인 후 실패한 주문의 정보를 수정하시고 해당 작업을 재실행 하시기 바랍니다.</span><br />${msg}`;
          }

          // 작업 성공 및 주문수집인 경우
          if (work.state_name === '완료' && work.job_name === '주문수집' && work.msg !== '변경사항이 없습니다' && $rootScope.user_profile.sol_stock > 0) {
            etcBtn += `<button type="button" class="label border-primary text-primary bg-white ml-10" ng-click="openAutoMapping(${id})">SKU상품 자동매칭 내역 보기</button>`;
          }

          // 멀티검색 추가. 2019-02-18 rony
          // 전송데이터중에 일부만 실패되는경우 작업이 오류가 아닌 완료로 처리되기 떄문에 msg 에 실패건수가 있는경우로 조건 변경.
          // if (work.state_name === '오류' && _.indexOf(workSVC.viewAddBtnAction, work.job_name) > 0) {
          const failCntPattern = /([0-9]+)건 실패/g;
          const failCnt = failCntPattern.exec(work.msg);

          if (_.includes(workSVC.viewAddBtnAction, work.job_name.replace(/\[[가-힣]+\]\s?/, '')) && failCnt && failCnt.length > 1 && parseInt(failCnt[1]) > 0) {
            // 온라인상품인경우 판매자관리코드에 온라인상품상세 모달 열리는 링크 심어줘야 함.
            multi_search_cd = [];
            let extractCode = [];
            let updateModalCode = '';
            const location = 'KR';

            msg = '';
            _.forEach(logs, function(o, idx) {
              // if (o.result_type !== '성공') {
              const regex = work.job_name === '송장전송' ? /^[^:]* :/gm : new RegExp((_(cSaleCds).chain().find({ ol_shop_no: o.sale_no }).get('c_sale_cd').value() || '').replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');

              extractCode = o.msg.match(regex);
              if (extractCode && extractCode.length) {
                if (work.job_name === '송장전송') {
                  extractCode[0] = extractCode[0].replace('<br>', '').replace(':', '').trim();

                  updateModalCode = `<a href="javascript:void(0);" ng-click="showDetail('${o.uniq}')">${extractCode[0]}</a>`;
                } else {
                  if (cSaleCds && cSaleCds[idx] && cSaleCds[idx].ol_shop_no) {
                    updateModalCode = `<a href="javascript:void(0);" ng-click="onlineProductEdit('${o.shop_cd}','${o.shop_id}',${cSaleCds[idx].ol_shop_no},'${location}')">${extractCode[0]}</a>`;
                  } else {
                    updateModalCode = `${extractCode[0]}`;
                  }
                }

                multi_search_cd.push(extractCode[0]);
                msg += `${o.msg.replace(extractCode[0], updateModalCode)}<br>`;
              } else {
                msg += `${o.msg}<br>`;
              }
              // }
            });

            etcBtn += `<button type="button" class="label border-primary text-primary bg-white ml-10" ng-click="openMultiSearch('${id}','${work.job_name}','${logs[0].shop_cd}', '${logs[0].shop_id}')">작업항목 멀티검색</button>`;
            etcBtn += `<button type="button" class="label border-primary text-primary bg-white ml-10" ng-click="excelDownload(${id}, '${work.site_name}(${work.site_id})','${work.job_name}', '${work.job_type}')">작업결과 Excel 다운로드</button>`;
          }

          if ($scope.tabIndex === 0) {
            jobName = `${jobId} ${work.site_name} - ${work.job_name}`;
          } else if ($scope.tabIndex === 1) {
            if (work.result_file) {
              headMsg = ' <div class="row" style="padding-bottom: 7px"><div class="col-xs-auto text-warning">결과 엑셀을 다운로드 하여 업로드 실패 건의 실패 사유를 확인하시기 바랍니다.<br>작업 완료 후 상품 리스트 새로고침을 진행하여 확인해주세요.</div></div>';
              etcBtn += `<button type="button" class="label border-primary text-primary bg-white ml-5" onClick="window.open('${work.result_file}')">결과 엑셀 다운로드</button>`;
            }

            etcBtn += `<button type="button" class="label border-primary text-primary bg-white ml-5" onClick="window.open('${work.job_file}')">업로드 엑셀 다운로드</button>`;
          }

          // 카운트 부족으로 실패한 주문건이면 결제페이지 이동 링크 걸어줌
          if (_.indexOf(workSVC.viewAddPayBtnAction, work.job_name) !== -1 && failCnt && failCnt.length && parseInt(failCnt[1]) > 0) {
            msg = msg.replace('(카운트 부족으로 인해 수집 실패된 주문건이 존재합니다.)', '<br><span class="text-danger">카운트 부족으로 인해 수집 실패된 주문건이 존재합니다.</span> <button type="button" class="label border-primary text-primary bg-white ml-10" ng-click="movePayPage()">결제 페이지 이동</button>');
          }

          if (msg === 'Object reference not set to an instance of an object.') {
            msg = '<span class="text-danger">사이트 오류로 인해 작업을 일시중지합니다. 잠시 후 다시 시도해주세요.</span>';
          }

          $scope.detail_msg = `
            ${headMsg}
            <div class="dis-flex mb-5">
              <div class="text-bold text-grey-300 width-60">작업명:</div>
              <div>${jobName}${editFieldMsg}</div>
            </div>
            <div class="dis-flex mb-5">
              <div class="text-bold text-grey-300 width-60">작업결과:</div>
              <div>${result_type}${etcBtn}</div>
            </div>
            <div class="dis-flex">
              <div class="text-bold text-grey-300 width-60">상세내용:</div>
              <div class="text-over-work">
                ${msg}
                <br />
                ${msgDetail}
              </div>
            </div>
          `;

          const work_index_name = 'edit_field_msg';

          $scope.workData[$scope.tabIndex][id][work_index_name] = editFieldMsg;
        });
    };

    // 1:1문의 모달오픈
    $scope.openQuestion = function (job_id, result_file) {
      const workData = $scope.workData[$scope.tabIndex][job_id];

      if (!workData) {
        commonSVC.showMessage('알림', '작업결과를 정상적으로 불러오지 못했습니다.\n잠시 후 재시도해 주십시오.');

        return false;
      }

      // 작업번호
      const worklogNum = `${workData.worklog_job_no} (${workData.uuid ? workData.uuid : job_id})`;

      // 작업명
      const job_name = `${workData.job_name}${workData.isQuick ? ' (퀵수집)' : ''}`;

      // 제목데이터
      const title = `${(workData.site_name ? (`${workData.site_name} `) : '') + job_name} 작업 상세 내용 문의`;

      workData.msg = workData.msg.replace(/(<([^>]+)>)/ig, '');
      // 작업 메세지 중복여부를 없애기 위해 workData.msg를 replace시킴
      workData.addMsg = workData.addMsg?.replace(/<br>/g, '\n').replace(/<\/?[a-z][^>]*>/gi, '').replace(workData.msg, '') || '';

      // 내용 데이터
      const content = [
        '[PLAYAUTO2.0 작업 상세 내용 문의]',
        `버전정보 : ${$rootScope.user_profile.sol_version}`,
        `문의 쇼핑몰 : ${workData.site_name || '없음'}`,
        `쇼핑몰ID : ${workData.site_id || '없음'}`,
        `실행시간 : ${workData.create_date}`,
        `작업번호 : ${worklogNum}`,
        `작업명 : ${job_name}${workData.edit_field_msg || ''}`,
        `작업 메세지 : ${workData.msg}\n${workData.addMsg}`,
      ].join('\n');

      const input = {};

      input.data = {
        from: 'work',
        title: title,
        content: content,
        inquiry_data: result_file || ''
      };

      // 모달이 이미 열려있을 경우
      if ($rootScope.questionModalOpen) {
        $rootScope.draggableModalZindex('question');
        $rootScope.$broadcast('questionFoldOpen');
      } else {
        $scope.openQuestionModal(input);
      }
    };

    $scope.openQuestionModal = function (input) {
      const modal = commonSVC.openModal('lg', input, 'QuestionInquiryCtrl', 'views/question/modals/inquiry.html', '', '', false);

      modal.result.then(function (re) {

        if (re === 'success') {
          //성공하면 페이지 리로드
          $timeout(function () {
            $scope.searchDo();
          }, 400);

        }
      });
    };

    // SKU상품 자동매칭 내역
    $scope.openAutoMapping = function (job_id) {
      $rootScope.work_no = job_id;
      $state.go('main.log_map_history', { work_no: job_id });
    };

    /**
     * 작업항목 멀티검색
     * 2019-01-30 rony
     */
    $scope.openMultiSearch = function (job_id, job_name, shop_cd, shop_id) {

      // string 로 넘어오기 떄문에 int 로 변경해주어야 함.
      const jobID = parseInt(job_id);

      if (multi_search_cd.length > 0) {
        $rootScope.work_search = {
          multi_search_cds: multi_search_cd,
          select_shop_cd: shop_cd,
          select_shop_id: shop_id,
          search: true
        };

        if (job_name === '송장전송') {
          $rootScope.work_search.page = 'integrated';
          $state.go('main.order_shipment_integrated_list');
          $rootScope.$broadcast('multiSearchWorkIntegrated', multi_search_cd);
        } else {
          $rootScope.work_search.page = 'online';
          $rootScope.work_search.mode = _.filter(work_job_type, (o) => { return o.job_id === jobID; })[0].job_type.replace('[', '').replace(']', '').trim();
          $state.go('main.online_product_list', { modal: '' });
          $rootScope.$broadcast('multiSearchWork', multi_search_cd);
        }
      }
    };

    // 2017-03-21 MatthewKim 실제로 탭 뷰 이동 완료시 마다 호출될 내용
    $scope.$on('$stateChangeSuccessForJqueryGlobal', function (evt, originEvt, toState) {
      if (toState.name == 'main.work') {
        const $wrapper = $('#work_grid_wrapper');
        const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

        if ($tableHeader.width() < 1) {
          $timeout(function () {
            $scope.grid.methods.calcLayout('set_width');
          }, 0);
        }
      }
    });

    // SKU상품 리스트로 이동
    $scope.open_prodList = function (skuList) {
      if (!$rootScope.multi_search) {
        $rootScope.multi_search = {};
      }

      $rootScope.multi_search.productList = {
        multi_type: 'sku_cd',
        multi_search_word: skuList.replace(/,/g, '\n'),
        sdate: '2001-01-01',
        edate: moment().format('YYYY-MM-DD'),
        date_type: 'wdate',                                             //검색기간 구분
        search_word: '',                                                //검색어
        search_key: 'all',                                              //검색구분
        search_type: 'partial',                                             //부분일치, 완전일치
        category: 'all',                                                //카테고리
        supplier_vendor: 'all',                                         //매입처
        delivery_vendor: '',                                         //배송처
        linkage_flag: 'all',                                            //연동상품여부
        tax_type: 'all',                                                 //과세여부
        recently_prod: 'all',                                             //신규상품(최근 일주일)
        safe_stock_alert: false,                                         //재고부족
        real_stock_none: false,                                           //판매불가
        group_prod: false,                                                //그룹상품
      };

      $state.go('main.prod_product_list');

      $rootScope.$broadcast('multiSearchWorkGlobal');
    };

    // 상품매칭 페이지 이동
    $scope.locationScrapMatchPage = function(shop_id, shop_cd) {
      const state = 'main.prodScrap_match_list';

      if ($state.current.name != 'main.prod_set_list') {
        $state.go(state, { shop_id, shop_cd });
        $state.reload();
      } else {
        $event.stopPropagation();
      }
    };

    // 주문상세 오픈
    $scope.showDetail = function (uniq) {
      const resolve = {
        data: {
          fromPage: 'order',
          uniq: uniq,
          warehouseList: warehouseList.data.result || [],
          systemList: systemList.data || []
        }
      };

      commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');
    };

    /**
     * 온라인상품상세열기
     * 2019-02-18 rony
     */
    $scope.onlineProductEdit = function (shop_cd, shop_id, ol_shop_no, location) {
      const resolve = {};
      const data = {};

      data.shop_cd = shop_cd;
      data.shop_id = shop_id;
      data.country = location;
      data.ol_shop_no = ol_shop_no;
      data.warehouseList = warehouseList.data.result || [],
      resolve.data = data;

      resolve.prodDetail = function () {
        return onlineProductModel.detail(shop_cd, shop_id, ol_shop_no);
      };
      // 원산지 리스트 조회
      resolve.madeinList = function () {
        return productModel.getMadein();
      };
      //환경설정 셋팃값
      resolve.systemList = function () {
        return systemModel.load();
      };

      commonSVC.openModal('full', resolve, 'OnlineProductEditCtrl', 'views/online/product/edit.html');
    };

    /**
     * 작업결과 엑셀 다운로드
     * 2019-02-19 rony
     * */
    $scope.excelDownload = function(work_no, shop_name, job_name, job_type) {
      commonSVC.showConfirm('작업결과 EXCEL 다운로드', '작업관리 리스트에서 선택한 작업의 결과를 Excel 파일로 다운로드 합니다.\n다운로드 받은 Excel 파일 내 결과메세지를 확인해 주세요.', function (confirm) {
        if (confirm) {
          const params = {
            work_no: work_no,
            down_list: [
              { header: '작업번호', key: 'work_no' },
              { header: '작업시간', key: 'wdate', width: '20' },
              { header: '사이트명', key: 'shop_name' },
              { header: '사이트아이디', key: 'shop_id' },
              { header: '결과메세지', key: 'msg', width: '100' },
              { header: '작업결과', key: 'result_type' }
            ],
            file_ext: 'xlsx',
            sheet_name: `${shop_name}_${job_name}_작업결과`,
            type: job_type
          };

          if (job_type === 'ScrapProd') {
            params.down_list = [
              { header: '매칭타입', key: 'type' },
              { header: '작업시간', key: 'wdate', width: '20' },
              { header: '상품명 (수집상품)', key: 'scrap_shop_sale_name' },
              { header: '판매자관리코드 (수집상품)', key: 'scrap_c_sale_cd' },
              { header: '쇼핑몰상품코드 (수집상품)', key: 'scrap_shop_sale_no' },
              { header: '단일상품여부 (수집상품)', key: 'scrap_std_ol_yn' },
              { header: '제조사 (수집상품)', key: 'scrap_maker' },
              { header: '브랜드 (수집상품)', key: 'scrap_brand' },
              { header: '모델명 (수집상품)', key: 'scrap_model' },
              { header: '판매가 (수집상품)', key: 'scrap_sale_price' },
              { header: '수량 (수집상품)', key: 'scrap_sale_cnt_limit' },

              { header: '상품명 (매칭상품)', key: 'shop_sale_name' },
              { header: '판매자관리코드 (매칭상품)', key: 'c_sale_cd' },
              { header: '제조사 (매칭상품)', key: 'maker' },
              { header: '브랜드 (매칭상품)', key: 'brand' },
              { header: '모델명 (매칭상품)', key: 'model' },
              { header: '판매가 (매칭상품)', key: 'sale_price' },
              { header: '수량 (매칭상품)', key: 'sale_cnt_limit' },
            ];
          }

          commonModel.excelDownload('/app/work/excel-down', params, function (state, result) {
            if (state === 'success') {
              const newWin = window.open(result.results[0]);

              if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                commonSVC.showToaster('error', '실패', '팝업 차단을 해제해주세요.');
              } else {
                commonSVC.showToaster('success', '성공', '엑셀 다운로드에 성공하였습니다.');
              }
            } else {
              commonSVC.showToaster('error', '실패', '엑셀 다운로드에 실패하였습니다.');
            }
          });
        }
      });
    };

    /**
     * 자동품절내역 바로가기
     * 2021-05-28 Lucas
     */
    $scope.goSoldoutHistory = function (sku_cd) {
      $rootScope.work_search = {
        search_cd: sku_cd,
      };
      $state.go('main.log_auto_soldout_history');
      $state.reload();
    };

    /**
     * 결제페이지 바로가기
     * 2019-05-24 Alvin
     * */
    $scope.movePayPage = function () {
      $state.go('main.pay_list');
      $state.reload();
    };
  });
