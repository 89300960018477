/**
 * 2018-08-22 Daniel
 * 머리말/꼬리말 규칙등록
 */

'use strict';

angular.module('gmpApp')
  .controller('OnlineProductAddcontentRuleCtrl', function ($scope, $uibModalInstance, commonSVC, onlineProductSVC, onlineProductModel, data, $rootScope) {
    // 머리말/꼬리말 유형
    $scope.addcontentTypes = onlineProductSVC.addcontentTypes;

    let actionType = 'addcontentAdd';

    $scope.detailType = '등록';

    const checkValues = [
      {
        name: '머리말',
        key: 'headerContent',
        changeYn: false
      },
      {
        name: '꼬리말',
        key: 'footerContent',
        changeYn: false
      },
    ];
    let origData;

    // 등록데이터
    $scope.data = {
      target: data.target || '상세설명',
      type: '',
      name: '',
      headerContent: '',
      footerContent: '',
    };

    // 템플릿 유형필터
    $scope.typeFilter = (item) => {
      return item.view.includes($scope.data.target);
    };

    $scope.onSubmit = function () {
      if ($scope.form1.$valid) {
        const data = $scope.data;
        let applyTargetType;

        if (!data.type) {
          commonSVC.showMessage('머리말/꼬리말 템플릿 저장 실패', '템플릿 유형은 필수값입니다.');

          return false;
        }

        if (!data.name) {
          commonSVC.showMessage('머리말/꼬리말 템플릿 저장 실패', '템플릿명은 필수값입니다.');

          return false;
        }

        if (!data.headerContent && !data.footerContent) {
          commonSVC.showMessage('머리말/꼬리말 템플릿 저장 실패', '머리말 혹은 꼬리말을 입력해주세요.');

          return false;
        }

        // 적용되어있고 수정일시
        if (origData) {
          const changeNames = _(checkValues)
            .map(function (value) {
              const key = value.key;

              if (origData[key] !== data[key]) {
                return value.name;
              }
            })
            .compact()
            .join('/');

          // 머리말내용, 꼬리말내용 변경시
          if (changeNames) {
            let applyTarget;

            // (머|꼬)리말만 수정
            if (changeNames.indexOf('말') >= 0) {
              applyTargetType = 1;
              applyTarget = '전체 상품';
            }

            const resolve = {
              data: {
                type: '수정',
                title: `'${data.target} ${changeNames}' 항목에 변경된 내역이 있습니다.<br>변경된 템플릿을 <span class="text-orange">${applyTarget}</span>에 적용하시겠습니까?`,
                target: data.target
              }
            };

            const modal = commonSVC.openModal('md', resolve, 'OnlineProductAddcontentConfirmCtrl', 'views/online/product/modals/addcontent_confirm.html');

            modal.result
              .then(function ({ editYn }) {
                sendData(editYn, applyTargetType);
              });
          } else {
            sendData(false, applyTargetType);
          }
        } else {
          sendData(false, applyTargetType);
        }
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss();
    };

    function sendData(editYn, applyTargetType) {
      $scope.data.applyTargetType = applyTargetType;
      $scope.data.editYn = editYn;

      onlineProductModel[actionType]($scope.data)
        .then(function (res) {
          if (res.status === 200) {
            commonSVC.showToaster('success', '성공', `머리말/꼬리말 ${$scope.detailType}에 성공했습니다.`);
            $uibModalInstance.close();
          } else {
            commonSVC.showToaster('error', '실패페', `머리말/꼬리말 ${$scope.detailType}에 실패했습니다.`);
          }
        })
        .catch(function (err) {
          if (err.data.error === 'addWorkFailed') {
            commonSVC.showToaster('error', '실패', `머리말/꼬리말 ${$scope.detailType} 성공,\n상품 작업에 실패하였습니다.`);
          } else {
            commonSVC.showToaster('error', '실패', `머리말/꼬리말 ${$scope.detailType}에 실패했습니다.`);
          }
        });
    }

    function init () {
      if (data.addcontentNo) {
        actionType = 'addcontentEdit';
        $scope.detailType = '수정';
        $scope.data.no = data.addcontentNo;

        const params = {
          no: [data.addcontentNo]
        };

        onlineProductModel.addcontentDetail(params)
          .then(function (res) {
            const data = res.data;

            $scope.data.applyYn = data.aply_yn;
            $scope.data.target = data.addcontent_target;
            $scope.data.type = data.addcontent_type;
            $scope.data.name = data.addcontent_name;
            $scope.data.headerContent = data.header_content;
            $scope.data.footerContent = data.footer_content;

            origData = angular.copy($scope.data);
          });
      }
    }

    init();

    /**
     * 이미지호스팅 모달 열기
     */
    $scope.openImageHostingModal = (type) => {
      const modal = $rootScope.openImageHosting('html');

      modal.result.then((hostImages) => {
        if (hostImages) {
          $scope.data[type] = ($scope.data[type] || '') + '\n<br />\n' + hostImages.join('\n<br />\n');
        }
      });
    };
  });
