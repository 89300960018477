'use strict';

angular.module('gmpApp')
  .controller('SubProdListCtrl', function ($scope, $rootScope, $filter, $compile, $timeout, $state,
    commonSVC, settings, productSVC, inventorySVC,
    subUserList,
    productModel, supplierModel, systemModel, warehouseModel, inventoryModel, commonModel, onlineProductModel,
    gettextCatalog, systemList, userInfo) {

    $scope.activeTap = 'sub';

    $scope.prodLogInfo = {};
    $scope.currentTab = 0;

    /* 검색 및 버튼 관련 변수 */
    $scope.searchData = angular.copy(productSVC.searchDataSub);
    $scope.searchForm = angular.copy(productSVC.searchFormSub);

    // 처음 로드 후 super 계정 재고 현황 클릭 시 sub로 선택 되는 경우가 있어서 설정 (에픽카)
    if ($rootScope.side_search.page === 'product' && $rootScope.side_search.search) {
      if ($rootScope.side_search.type === 'super') {
        $scope.activeTap = 'super';
        $scope.currentTab = 1;
      } else {
        $scope.activeTap = 'sub';
        $scope.currentTab = 0;
      }
    }

    // 설정에서 검색 기간 변수 읽어서 강제로 sdate에 넣어줌 - service 수정 전까지는 이렇게 해야 할 듯
    // 값이 있는경우에만 설정기한 사용하도록 함. 기본은 솔루션 신청일. 2018-03-07 rony
    if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
      $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
    }

    const searchDetail = [
      {
        title: $rootScope.affName === '에픽카' ? '부품사 전체' : gettextCatalog.getString('대리점(특약점) 전체'),
        search_name: 'search_sol_no',
        item_list: subUserList?.data || [],
        item_key: 'company_name',
        item_value: 'sol_no',
        select_value: ''
      },
      {
        title: gettextCatalog.getString('상품 상태 전체'),
        search_name: 'stock_status',
        item_list: [
          { code: 'stock_normal', stock_status: '정상' },
          { code: 'safe_stock_alert', stock_status: '재고부족' },
          { code: 'real_stock_none', stock_status: '일시품절' },
        ],
        item_key: 'stock_status',
        item_value: 'code',
        select_value: ''
      },
      ...($rootScope.affName === 'LG전자' && [{
        title: gettextCatalog.getString('상품 기술서 Header/Footer 전체'),
        search_name: 'addcontent_type',
        item_list: [
          { code: '공지사항', stock_status: '공지사항' },
          { code: '배송지연', stock_status: '배송지연' },
          { code: '이벤트', stock_status: '이벤트' },
          { code: '기타', stock_status: '기타' },
        ],
        item_key: 'stock_status',
        item_value: 'code',
        select_value: ''
      }]) || [],
    ];

    const searchDetailSuper = [
      {
        title: gettextCatalog.getString('상품 상태 전체'),
        search_name: 'prod_status',
        item_list: [
          { code: '정상', stock_status: '정상' },
          { code: '재고부족', stock_status: '재고부족' },
          { code: '품절', stock_status: '일시품절' },
        ],
        item_key: 'stock_status',
        item_value: 'code',
        select_value: ''
      },
      ...($rootScope.affName === 'LG전자' && [{
        title: gettextCatalog.getString('상품 기술서 Header/Footer 전체'),
        search_name: 'addcontent_type',
        item_list: [
          { code: '공지사항', stock_status: '공지사항' },
          { code: '배송지연', stock_status: '배송지연' },
          { code: '이벤트', stock_status: '이벤트' },
          { code: '기타', stock_status: '기타' },
        ],
        item_key: 'stock_status',
        item_value: 'code',
        select_value: ''
      }]) || [],
    ];

    const searchDetailMulti = [...($rootScope.affName === '에픽카' && [
      {
        title: gettextCatalog.getString('쇼핑몰(계정) 선택'),
        search_name: 'multi_sub_sol_no',
        item_list: subUserList?.data || [],
        item_key: 'company_name',
        item_value: 'sol_no',
        search_word: ''
      }]) || []
    ];

    // 상세 조건검색 리스트
    $scope.searchDetail = angular.copy(searchDetail);
    $scope.searchDetailMulti = angular.copy(searchDetailMulti);

    $scope.searchBtn = {      // 상세조건 버튼
      actions_right: [
        {
          label: `<i class="icon-plus2"></i><span> ${gettextCatalog.getString('SKU상품 등록')}</span><span class="caret"></span>`,
          small_label: '상품등록',
          sub_label: '',
          btn_type: 'dropdown',
          item_list: [
            {
              label: gettextCatalog.getString('상품 개별 등록'),
              hotkey: '',
              test_id: 'btn-self-add-prod',
              perm_only: ['prod.product'],
              action: function() {
                // SKU상품 등록 권한 확인. 2019-01-04 rony
                if (commonSVC.checkPermission('stock.roles.addProd', userInfo.permission)) {
                  $state.go('main.prod_product_add');
                }
              }
            }, {
              label: gettextCatalog.getString('엑셀 일괄 등록'),
              hotkey: '',
              test_id: 'btn-add-excel',
              perm_only: ['prod.product+write'],
              action: function() {
                // SKU상품등록 권한 확인. 2019-01-04 rony
                if (commonSVC.checkPermission('stock.roles.addProd', userInfo.permission)) {
                  $scope.excelUpload('add');
                }
              }
            }, {
              label: gettextCatalog.getString('엑셀 일괄 수정'),
              hotkey: '',
              test_id: 'btn-update-excel',
              perm_only: ['prod.product+write'],
              action: function() {
                // SKU상품등록 권한 확인. 2019-01-04 rony
                if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission)) {
                  $scope.excelUpload('edit');
                }
              }
            }
          ],
          ngIfFunc: function () {
            return $rootScope.affName === '에픽카' && $scope.activeTap !== 'super' ? 'n' : 'y';
          }
        }
      ],
      table_actions: [
        ...($rootScope.affName === 'LG전자' && [
          {
            label: gettextCatalog.getString('헤더/푸터 적용'),
            add_class: 'a-btn-blue',
            perm_only: ['online.product'],
            action: function () {
              $scope.prodAddcontent();
            }
          },
          {
            label: gettextCatalog.getString('헤더/푸터 해제'),
            add_class: 'a-btn-blue',
            perm_only: ['online.product'],
            action: function () {
              $scope.prodAddcontentRelease();
            },
          }]) || []

      ],
      actions_right_bottom: [],
    };

    $scope.searchBtnSuper = {      // 상세조건 버튼
      table_actions: [
        {
          label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          test_id: 'dropdown-work',
          item_list: [
            {
              label: '상품 복사',
              test_id: 'btn-product-copy',
              action: function() {
                $scope.copyProds();
              },
              perm_only: ['prod.product+write']
            },
            {
              label: '상품 삭제',
              test_id: 'btn-product-del',
              action: function () {
                $scope.deleteProds();
              },
              perm_only: ['prod.product+admin']
            }
          ]
        },
        ...($rootScope.affName === '에픽카' && [{
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          test_id: 'dropdown-excel',
          item_list: [
            {
              label: gettextCatalog.getString('전체 엑셀 다운로드'),
              test_id: 'btn-download-allexcel',
              action: function() {
                $scope.excelDown('all');
              },
              perm_only: ['prod.product']
            },
            {
              label: gettextCatalog.getString('선택 엑셀 다운로드'),
              test_id: 'btn-download-selectexcel',
              action: function () {
                $scope.excelDown('select');
              },
              perm_only: ['prod.product']
            }
          ]
        },
        {
          label: gettextCatalog.getString('정보 일괄 수정'),
          add_class: 'a-btn-blue',
          test_id: 'btn-fix-info',
          action: function () {
            $scope.modifyProdModal();
          },
          perm_only: ['prod.product+write']
        },
        {
          label: gettextCatalog.getString('안전 재고 수정'),
          add_class: 'a-btn-blue',
          test_id: 'btn-fix-safetyproduct',
          action: function() {
            $scope.modifyStockModal('safe');
          },
          perm_only: ['prod.product+write']
        }] || []),
        ...($rootScope.affName === 'LG전자' && [
          {
            label: gettextCatalog.getString('헤더/푸터 적용'),
            add_class: 'a-btn-blue',
            perm_only: ['online.product'],
            action: function () {
              $scope.prodAddcontent();
            }
          },
          {
            label: gettextCatalog.getString('헤더/푸터 해제'),
            add_class: 'a-btn-blue',
            perm_only: ['online.product'],
            action: function () {
              $scope.prodAddcontentRelease();
            },
          }]) || []
        ,
      ]
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    $scope.selectRowUniqList = [];

    $scope.isMemoDisabled = (prod_no) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(prod_no);

    // 데이터 테이블에서 선택한 rowList 정보 받아옴
    $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
      $scope.selectRowUniqList = data.map(({ prod_no }) => prod_no);
      $scope.$apply();
    }, 200));

    /**
     *  데이터 테이블 탭1
     */
    $scope.grid = $scope.productGrid = {};
    $scope.productGrid.methods = {};
    $scope.productGrid.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'wdate', 'company_name', 'mdate', 'stock_status', 'attri', 'model', 'model_no', 'mas_addcontent_no', 'mas_addcontent_name'],
      alignRightColumns: ['sale_price', 'stock_cnt_real', 'stock_cnt_safe', 'stock_cnt'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'stock_cnt_safe', 'mdate'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: ['unavail_out_cnt', 'madein', 'tax_type', 'cost_price'],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/base/sub-list`,
        requestWillAction: function (data) {
          if ($rootScope.memo_etc_search.page === 'product' && $rootScope.memo_etc_search.search) {
            const { date_type, sdate, edate, key: search_key, value: search_word } = $rootScope.memo_etc_search;

            $scope.searchForm = {
              ...$scope.searchForm,
              date_type,
              sdate,
              edate,
              search_key,
              search_word,
              search_type: 'exact'
            };
            $rootScope.memo_etc_search.search = false;
          }

          if ($rootScope.side_search.page === 'product' && $rootScope.side_search.search && !$rootScope.side_search.type) {
            $scope.resetDo(true);

            $scope.searchDetail[0].select_value = $rootScope.side_search.sub_sol_no || '';
            $scope.searchDetail[1].select_value = $rootScope.side_search.status || '';

            $scope.searchForm.search_sol_no = $rootScope.side_search.sub_sol_no || '';
            $scope.searchForm.stock_status = $rootScope.side_search.status || '';

            $scope.searchForm.date_type = $rootScope.side_search.date_type;
            $scope.searchForm.sdate = $rootScope.side_search.sdate;
            $scope.searchForm.edate = $rootScope.side_search.edate;

            $rootScope.side_search.search = false;
          }

          if ($rootScope.multi_search && $rootScope.multi_search.productList) {
            angular.merge($scope.searchForm, $rootScope.multi_search.productList);

            delete $rootScope.multi_search.productList;
          }

          data = angular.merge({}, data, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          data.sol_no = $rootScope.user_profile.sol_no;

          if ($scope.isDepot) {
            data.delivery_vendor = $rootScope.user_profile.depot_no;
          }

          return data;
        },
        requestDidAction: function (result) {
          if ($scope.activeTap === 'sub') {
            $scope.searchData.totalCount = result.recordsTotal;

            return result.results;

          } else {
            return [];
          }

        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: function (row) {
            //row.uniq 교체해야함
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(true,'${row.sku_cd}','${row.prod_no}', ${row.sol_no})">상세</button>`;
          }
        },
        // 등록일
        {
          title: $rootScope.affName === '에픽카' ? '부품사' : '대리점',
          key: 'company_name',
          width: 150,
        },
        // 등록일
        {
          title: '등록일',
          key: 'wdate',
          width: 150,
          filter: 'dateValid',
        },
        //SKU_CODE
        {
          title: 'SKU코드',
          key: 'sku_cd',
          width: 120,
          notCompile: true,
          template: function(row) {
            return `<span class="text-primary" id=${row.prod_no}>${row.sku_cd}</span>`;
          }
        },
        // 재고관리코드
        {
          title: '재고관리코드',
          key: 'stock_cd',
          width: 120
        },
        ...($rootScope.affName === '에픽카' && [
          // 배송처
          {
            title: '배송처',
            key: 'depot_name',
            width: 100,
            notCompile: true,
            template: function (row) {
              return row.depot_name || '없음';
            }
          },
        ] || []),
        //이미지+상품명
        {
          title: 'SKU상품명',
          key: 'prod_name',
          width: 330,
          notCompile: true,
          template: function (row) {
            const img = row.prod_img || '/assets/images/upload.png';

            return `<img src='${img}' width='25' height='25' class='mr-10'>${row.prod_name.replace(/\ /g, '&nbsp')}</span>`;
          }
        },
        //속성
        {
          title: '속성',
          key: 'attri',
          width: 160
        },
        // 판매가
        {
          title: '판매가',
          key: 'sale_price',
          width: 100,
          notCompile: true,
          template: function (row) {
            return $filter('currency')(row.sale_price, '', 0);
          }
        },
        //실재고
        {
          title: '실재고',
          key: 'stock_cnt_real',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.stock_cnt_real < 0 ? 0 : row.stock_cnt_real;
          }
        },
        //안전재고
        ...($rootScope.affName === 'LG전자' && [
          {
            title: '안전재고',
            key: 'stock_cnt_safe',
            width: 100,
            notCompile: true,
            template: function (row) {
              return parseInt(row.stock_cnt_safe) || 0;
            }
          }
        ] || []),
        //판매가능재고
        {
          title: '판매가능재고',
          key: 'stock_cnt',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.stock_cnt < 0 ? 0 : row.stock_cnt;
          }
        },
        //상태
        {
          title: '상태',
          key: 'stock_status',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.stock_status;
          }
        },
        ...($rootScope.affName === 'LG전자' &&
          [{
            title: '모델번호',
            key: 'model_no',
            width: 100
          }]
          || []),
        // 모델명
        {
          title: '모델명',
          key: 'model',
          width: 130
        },
        // 브랜드
        {
          title: '브랜드',
          key: 'brand',
          width: 130
        },
        // 수정일
        {
          title: '수정일',
          key: 'mdate',
          width: 150,
          filter: 'dateValid'
        },
        ...($rootScope.affName === 'LG전자' &&
          [{
            title: '헤더/푸터 템플릿번호',
            key: 'mas_addcontent_no',
            width: 150,
            notCompile: true,
          },
          {
            title: '헤더/푸터 템플릿명',
            key: 'mas_addcontent_name',
            width: 150,
            notCompile: true,
          }]
          || []),
        ...($rootScope.affName === '에픽카' &&
          [
            {
              title: '합포장여부',
              key: 'bundle_avail_yn',
              width: 100,
              template: function (row) {
                return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
              }
            },
            {
              title: '원산지',
              key: 'madein',
              width: 100,
              notCompile: true,
              template: function (row) {
                // 원산지가 기타일 때
                if (!row.madein_group && (row.madein_type === 'null' || row.madein_type === null || row.madein_type === '')) {
                  return row.madein_etc || '';
                } else {
                  return `${row.madein_group || ''} ${row.madein_name || ''}`;
                }
              }
            },
            {
              title: '바코드',
              key: 'barcode',
              width: 100
            },
            // 제조일자
            {
              title: '제조일자',
              key: 'made_date',
              width: 100,
              filter: 'dateValid',
            },
            // 유효일자
            {
              title: '유효일자',
              key: 'expire_date',
              width: 100,
              filter: 'dateValid',
            },
            // 수정일
            {
              title: '수정일',
              key: 'mdate',
              width: 130,
              filter: 'dateValid',
            },
            {
              title: '입고단위',
              key: 'in_unit_info',
              width: 100,
              template: function (row) {
                if (row.in_unit_cnt) {
                  return row.in_unit_cnt + row.in_unit;
                } else {
                  return '';
                }
              }
            }] || [])
      ]
    };

    /**
     *  데이터 테이블 탭2
     */
    $scope.superProductGrid = {};
    $scope.superProductGrid.methods = {};
    $scope.superProductGrid.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'link_yn', 'link_ord_yn', 'unavail_out_cnt', 'cost_price', 'stock_status', 'mas_addcontent_no', 'mas_addcontent_name'],
      alignRightColumns: ['sale_price', 'real_stock', 'stock_cnt', 'stock_cnt_safe', 'madein', 'cost_price', 'supply_price', 'supp_name'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'real_stock', 'stock_cnt_safe'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: ['sale_price', 'real_stock', 'stock_cnt', 'stock_cnt_safe', 'madein', 'cost_price', 'supply_price', 'supp_name'],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/base/list`,
        requestWillAction: function (data) {
          if ($rootScope.side_search.page === 'product' && $rootScope.side_search.search && $rootScope.side_search.type === 'super') {
            $scope.resetDo(true);

            let prodStatus = '';

            switch ($rootScope.side_search.status) {
              case 'stock_normal':
                prodStatus = '정상';
                break;
              case 'safe_stock_alert':
                prodStatus = '재고부족';
                break;
              case 'real_stock_none':
                prodStatus = '품절';
                break;
              default:
                prodStatus = '';
                break;
            }
            $scope.searchDetail[0].select_value = prodStatus || '';

            $scope.searchForm.prod_status = prodStatus || '';

            $scope.searchForm.date_type = $rootScope.side_search.date_type;
            $scope.searchForm.sdate = $rootScope.side_search.sdate;
            $scope.searchForm.edate = $rootScope.side_search.edate;

            $rootScope.side_search.search = false;
          }

          data = angular.merge({}, data, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          data.sol_no = $rootScope.user_profile.sol_no;
          data.delivery_vendor = $scope.isDepot ? $rootScope.user_profile.depot_no : '';

          return data;
        },
        requestDidAction: function (result) {
          if ($scope.activeTap === 'super') {
            $scope.searchData.totalCount = result.recordsTotal;

            return result.results;

          } else {
            return [];
          }
        }
      },
      columns: [
        //도구
        {
          title: '도구',
          key: 'widget',
          width: 100,
          template: function (row) {
            // row.uniq 수정필요
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(true,'${row.sku_cd}','${row.prod_no}')" >상세</button>`;
          }
        },
        // 등록일
        {
          title: '등록일',
          key: 'wdate',
          width: 130,
          filter: 'dateValid',
        },
        ...($rootScope.affName === '에픽카' && [
          // 스타일코드
          {
            title: '스타일코드',
            key: 'style_cd',
            width: 100
          }
        ] || []),
        //SKU_CODE
        {
          title: 'SKU코드',
          key: 'sku_cd',
          width: 120,
          notCompile: true,
          template: function (row) {
            return `<span class="text-primary" id=${row.prod_no}>${row.sku_cd}</span>`;
          }
        },
        // 재고관리코드
        {
          title: '재고관리코드',
          key: 'stock_cd',
          width: 120
        },
        // 모델번호
        ...($rootScope.affName === 'LG전자' && [
          {
            title: '모델번호',
            key: 'model_no',
            width: 120
          }
        ] || []),
        //이미지+상품명
        {
          title: 'SKU상품명',
          key: 'prod_name',
          width: 330,
          notCompile: true,
          template: function (row) {
            const img = row.prod_img || '/assets/images/noimage.png';

            return `<img src='${img}' width='25' height='25' class='mr-10'>${row.prod_name}</span>`;
          }
        },
        //속성
        {
          title: '속성',
          key: 'attri',
          width: 160
        },
        //브랜드
        {
          title: '브랜드',
          key: 'brand',
          width: 100
        },
        // 판매가
        {
          title: '판매가',
          key: 'sale_price',
          width: 100,
          notCompile: true,
          template: function (row) {
            return $filter('currency')(row.sale_price, '', 0);
          }
        },
        //상태
        {
          title: '상태',
          key: 'stock_status',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.stock_status;
          }
        },
        //실재고
        {
          title: '실재고',
          key: 'real_stock',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.real_stock < 0 ? 0 : row.real_stock;
          }
        },
        //판매가능재고
        {
          title: '판매가능재고',
          key: 'stock_cnt',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.stock_cnt < 0 ? 0 : row.stock_cnt;
          }
        },
        //안전재고
        {
          title: '안전재고',
          key: 'stock_cnt_safe',
          width: 100,
          notCompile: true,
          template: function (row) {
            return parseInt(row.stock_cnt_safe) || 0;
          }
        },
        //원산지
        {
          title: '원산지',
          key: 'madein',
          width: 100,
          notCompile: true,
          template: function (row) {
            if (!row.madein_group && (row.madein_type === 'null' || row.madein_type === null || row.madein_type === '')) {
              return row.madein_etc || '';
            } else {
              return `${row.madein_group} ${row.madein_name}`;
            }
          }
        },
        //과세여부
        {
          title: '과세여부',
          key: 'tax_type',
          width: 100 },
        //원가
        {
          title: '원가',
          key: 'cost_price',
          width: 100,
          notCompile: true,
          template: function (row) {
            return $filter('currency')(row.cost_price, '', 0);
          }
        },
        //공급가
        {
          title: '공급가',
          key: 'supply_price',
          width: 100,
          notCompile: true,
          template: function (row) {
            return $filter('currency')(row.supply_price, '', 0);
          }
        },
        // 매입처
        {
          title: '매입처',
          key: 'supp_name',
          width: 100
        },
        // 배송처
        {
          title: '배송처',
          key: 'depot_name',
          width: 100
        },

        // 모델명
        {
          title: '모델명',
          key: 'model',
          width: 100
        },
        // 바코드
        {
          title: '바코드',
          key: 'barcode',
          width: 100
        },
        // 제조일자
        {
          title: '제조일자',
          key: 'made_date',
          width: 100,
          filter: 'dateValid',
        },
        // 유효일자
        {
          title: '유효일자',
          key: 'expire_date',
          width: 100,
          filter: 'dateValid',
        },
        // 수정일
        {
          title: '수정일',
          key: 'mdate',
          width: 130,
          filter: 'dateValid',
        },
        ...($rootScope.affName === 'LG전자' && [
          {
            title: '헤더/푸터 템플릿번호',
            key: 'mas_addcontent_no',
            width: 150,
            notCompile: true,
          },
          {
            title: '헤더/푸터 템플릿명',
            key: 'mas_addcontent_name',
            width: 150,
            notCompile: true,
          }
        ] || [])
        ,
        ...($rootScope.affName === '에픽카' &&
      [
        {
          title: '합포장여부',
          key: 'bundle_avail_yn',
          width: 100,
          template: function (row) {
            return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
          }
        },
        {
          key: 'unavail_out_cnt',
          title: '미출고',
          width: 90,
          template: function(row) {
            return row.unavail_out_cnt;
          }
        },
        {
          key: 'link_yn',
          title: '연동상품',
          width: 100,
          template: function(row) {
            const linkIcon = row.link_yn || row.link_set_yn
              ? `<a ng-click="grid.appScope.linkageModalOpen('${row.sku_cd}', '${row.prod_no}', '${row.depot_no}', '${row.depot_name}', ${!!row.link_yn}, ${!!row.link_set_yn})">보기</a>`
              : '<span class="text-grey-100">보기</span>';

            return linkIcon;
          }
        },
        {
          key: 'link_ord_yn',
          title: '연동주문',
          width: 90,
          template: function(row) {
            const linkIcon = row.link_ord_yn
              ? `<a ng-click="grid.appScope.openOrderList('${row.sku_cd}')">보기</a>`
              : '<span class="text-grey-100">보기</span>';

            return linkIcon;
          }
        },
        {
          title: '원산지',
          key: 'madein',
          width: 100,
          notCompile: true,
          template: function (row) {
            // 원산지가 기타일 때
            if (!row.madein_group && (row.madein_type === 'null' || row.madein_type === null || row.madein_type === '')) {
              return row.madein_etc || '';
            } else {
              return `${row.madein_group || ''} ${row.madein_name || ''}`;
            }
          }
        },
        {
          title: '바코드',
          key: 'barcode',
          width: 100
        },
        // 제조일자
        {
          title: '제조일자',
          key: 'made_date',
          width: 100,
          filter: 'dateValid',
        },
        // 유효일자
        {
          title: '유효일자',
          key: 'expire_date',
          width: 100,
          filter: 'dateValid',
        },
        // 수정일
        {
          title: '수정일',
          key: 'mdate',
          width: 130,
          filter: 'dateValid',
        },
        {
          title: '입고단위',
          key: 'in_unit_info',
          width: 100,
          template: function (row) {
            if (row.in_unit_cnt) {
              return row.in_unit_cnt + row.in_unit;
            } else {
              return '';
            }
          }
        }
      ] || [])
      ]
    };

    /**
     * 검색
     */
    $scope.searchDo = (refresh, noDelay) => {
      $scope[$scope.activeTap === 'super' ? 'superProductGrid' : 'productGrid'].methods.reloadData(null, refresh, noDelay, true);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = (noSearch = false) => {
      $scope.searchForm = angular.copy(productSVC.searchFormSub);
      $scope.searchData.search_key_name = '전체';
      if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
        $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      }

      if ($scope.activeTap === 'super') {
        $scope.searchDetail = angular.copy(searchDetailSuper);
      } else {
        $scope.searchDetail = angular.copy(searchDetail);
      }

      if (!noSearch) {
        $scope.searchDo(true, true);
      }
    };

    /**
     * 데이터테이블 pageLength 조절
     * @param number
     */
    $scope.changeCount = function () {
      $scope.superProductGrid.methods.length($scope.searchData.showCount);
      $scope.productGrid.methods.length($scope.searchData.showCount);
    };

    /**
     * 상세페이지 모달창
     */
    $scope.showDetail = function(show, sku_cd, prod_number, sol_no) {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          sol_no,
          detailType: 'edit',
          nonEdit: !!sol_no && $rootScope.affName === '에픽카'
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true, sol_no });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true, sol_no });
      };

      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      const modal = commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

      modal.result.then(function () {

        //리스트 유지한채 데이터 업데이트
        $scope.grid.methods.reloadData(function() {}, false);

      });
    };

    /**
     * 상품 복사
     */
    $scope.copyProds = function () {
      // SKU상품복사 권한 확인 - 등록
      if (commonSVC.checkPermission('stock.roles.addProd', userInfo.permission) === false) {
        return false;
      }
      const selectList = $scope.grid.methods.selectedData('all');

      if (selectList.length == 0) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      // G마켓 물류 배송처인 SKU상품은 복사불가
      if (_.filter(selectList, { ebaydepot_yn: 1 }).length) {
        commonSVC.showMessage('복사 실패', 'G마켓 물류서비스에서 가져온 SKU는 상품복사가 불가합니다.');

        return false;
      }

      const resolve = {
        data: {
          selectList: selectList,
        }
      };

      const modal = commonSVC.openModal('lg', resolve, 'ProdProductCopyCtrl', 'views/prod/product/modals/copy_modal.html');

      modal.result.then(function (e) {
        if (e === 'success') {
          $scope.searchDo(true);
        }
      });
    };

    /**
     * 상품 삭제
     */
    $scope.deleteProds = function (prods) {

      // SKU상품삭제 권한 확인. 2019-01-04 rony
      if (commonSVC.checkPermission('stock.roles.deleteStock', userInfo.permission) === false) {
        return false;
      }

      const deleteProds = prods || $scope.grid.methods.selectedData('all');

      if (!deleteProds.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      let isLinked = false, isSetLinked = false, isMapping = false;

      deleteProds.every(prod => {
        isMapping = prod.mapping_prod > 0;
        isLinked = prod.link_yn === 1;
        isSetLinked = prod.link_set_yn === 1;

        return !(isLinked || isSetLinked || isMapping);
      });

      if (isLinked || isSetLinked) {
        // 선택한 상품 중 대표상품은 포함 안되었지만 연동상품이 포함되어있는 경우
        commonSVC.showMessageHtml('실패', `
          삭제하려는 SKU상품 중 온라인상품 또는 세트상품에 매칭된 상품이 있습니다. <br/>
          매칭해제 후 삭제하여 주시기 바랍니다. <br/>
          <a href="https://www.plto.com/customer/HelpDesc/?sol=gmp&guide_no=12477" target="_blank">도움말 바로가기 <i class="icon-new-tab"></i><a>
        `);

        return false;
      } else if (isMapping) {
        commonSVC.showMessageHtml('실패', `
        삭제하려는 SKU상품 중 매칭된 주문이 출고 전 상태에 있는 SKU상품이 있어 삭제가 불가합니다. <br/>
        해당 주문이 모두 출고완료된 이후 SKU상품 삭제가 가능합니다. <br/>
        <a href="https://www.plto.com/customer/HelpDesc/?sol=gmp&guide_no=12477" target="_blank">도움말 바로가기 <i class="icon-new-tab"></i><a>
      `);

        return false;
      }

      let text = '';

      // G마켓 물류 배송처인 SKU상품일 경우
      if (_.filter(deleteProds, { ebaydepot_yn: 1 }).length) {
        text = 'G마켓 물류서비스에서 가져온 SKU는 솔루션에서만 삭제됩니다.\nG마켓 물류서비스에서 SKU가 정상, 품절, 출고중지 상태인 경우 SKU 가져오기 작업시 재수집됩니다.\n';
      }

      const params = {
        prod_no: deleteProds.map(prod => prod.prod_no),
        sku_code: deleteProds.map(prod => prod.sku_cd)
      };

      commonSVC.showConfirm('삭제 하시겠습니까?', text, function () {
        productModel.delete(params, function (state) {
          if (state == 'success') {
            commonSVC.showToaster('success', '성공', `상품 삭제 ${deleteProds.length}건에 성공하였습니다`);
            $scope.searchDo(true);
          } else {
            commonSVC.showToaster('error', '실패', '상품 삭제에 실패하였습니다');
          }
        });
      });
    };

    /**
     * 탭 변경
     */
    $scope.selectTab = (type) => {
      if (type === 'super') {
        $scope.currentTab = 1;
        $scope.activeTap = type;
        $scope.grid = $scope.superProductGrid;
        $scope.searchDetail = angular.copy(searchDetailSuper);
      } else {
        $scope.currentTab = 0;
        $scope.activeTap = type;
        $scope.grid = $scope.productGrid;
        $scope.searchDetail = angular.copy(searchDetail);

      }

      if (!$rootScope.side_search.search) {
        $scope.searchDo(true, true);
      }

    };

    /**
     * 엑셀 업로드 모달창
     * */
    $scope.excelUpload = function (mode) {
      const resolve = {
        data: {
          mode: mode,
          prod_auto_set_yn: systemList.data.prod_auto_set_yn
        }
      };
      const modal = commonSVC.openModal('lg', resolve, 'ProdProductExcelUploadCtrl', 'views/prod/product/modals/excel_upload.html');

      modal.result.then(function () {
        // 엑셀 업로드 후 새로고침 추가 2018-01-16 rony
        $scope.searchDo(true);
      });
    };

    $scope.prodAddcontent = () => {
      const select_list = $scope.grid.methods.selectedData('all');

      if (!select_list.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }
      const params = {
        data: {
          type: 'prod',
          result: select_list
        }
      };

      const modal = commonSVC.openModal('full', params, 'MasAddcontentCtrl', 'views/online/product/modals/mas_addcontent.html', false, true, false);

      modal.result.then((result) => {
        if (result === 'refresh') {
          $scope.searchDo(false);
        }
      });
    };

    $scope.prodAddcontentRelease = async () => {
      const select_list = $scope.grid.methods.selectedData('all');

      if (!select_list.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      const confirm = await commonSVC.showConfirm('헤더/푸터 템플릿', `선택한 상품(${select_list.length})의 헤더/푸터 템플릿을 해제하시겠습니까?`);

      if (confirm) {
        const params = {
          select_list,
          type: 'prod'
        };
        const release_res = await onlineProductModel.masAddcontentRelease(params);

        if (release_res.status === 200) {
          commonSVC.showToaster('success', '성공', '헤더/푸터 템플릿 해제에 성공했습니다.');
          $scope.searchDo(false);

        } else {
          commonSVC.showToaster('error', '실패', '헤더/푸터 템플릿 해제에 실패했습니다.');
        }
      }
    };

    /**
     * 연동상품 모달창
     * */
    $scope.linkageModalOpen = function (sku_code, prod_no, link_yn, link_set_yn) {
      const resolve = {};

      resolve.data = {
        code: sku_code,
        prod_no: prod_no,
        link_yn,
        link_set_yn
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      commonSVC.openModal('xxg', resolve, 'ProdProductLinkageCtrl', 'views/prod/product/modals/product_linkage.html');
    };

    // 주문리스트 모달 열기
    $scope.openOrderList = function(sku_code) {
      const templatePath = 'views/stock/inventory/order_list.html';
      const resolve = {
        resolve: {
          sku_code: sku_code
        }
      };

      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      resolve.systemList = function(systemModel) {
        return systemModel.load();
      };

      commonSVC.openModal('full', resolve, 'StockInventoryOrderListCtrl', templatePath);

    };

    /**
      * 엑셀 다운 모달창
      * */
    $scope.excelDown = function (type) {

      // SKU상품 엑셀 권한 확인. 2019-01-04 rony
      if (commonSVC.checkPermission('stock.roles.excelStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: angular.copy(inventorySVC.excelFieldList),
        title: '재고현황',
        url: '/app/stock/excel-down',
        searchForm: $scope.pageData,
        page: 'stock'
      };

      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const selectList = $scope.grid.methods.selectedData('all');

        if (selectList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 상품이 없습니다.'));

          return false;
        } else {
          const newArray = [];

          _.forEach(selectList, function (item) {
            newArray.push(_.pick(item, ['prod_no', 'depot_no']));
          });
          resolve.data.select_list = newArray;
          resolve.data.count = selectList.length;
          resolve.data.isAll = false;
        }
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 상품 정보 일괄 수정
     * */
    $scope.modifyProdModal = function () {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const selected = $scope.grid.methods.selectedData('all');
      const prodNoList = _.map(selected, 'prod_no');
      const depotNoList = _.map(selected, 'depot_no');
      const ebaydepotExist = !!_.filter(selected, { ebaydepot_yn: 1 }).length; // G마켓물류 배송처 SKU상품 존재여부
      const dupCheckList = selected.map(s => ({ prod_no: s.prod_no, depot_no: s.depot_no }));
      if (selected.length == 0) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      const resolve = {
        madeinList: function(productModel) {
          return productModel.getMadein();
        },
        supplierList: function(supplierModel) {
          return supplierModel.ListAll({ use_yn: true });
        },
        warehouseList: function(warehouseModel) {
          return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
        }
      };

      resolve.data = {
        prodNoList: prodNoList,
        depotNoList: depotNoList,
        ebaydepotExist: ebaydepotExist,
        prod_auto_set_yn: systemList.data.prod_auto_set_yn,
        dupCheckList
      };

      if (ebaydepotExist) {
        commonSVC.showMessage('주의', 'G마켓 물류서비스에서 가져온 SKU는 정보 수정 시 솔루션에서만 적용되며 G마켓 물류서비스에 반영되지 않습니다. (배송처 정보는 수정이 불가합니다.)');
      }

      const modal = commonSVC.openModal('xg', resolve, 'ProdProductEditCtrl', 'views/prod/product/modals/modify_prod.html');

      modal.result.then(function () {
        $scope.searchDo(false);
      });
    };

    /**
     * 재고 일괄 수정
     * */
    $scope.modifyStockModal = function (type) {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const prodNoList = $scope.grid.methods.selectedData('prod_no');

      if (prodNoList.length == 0) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      inventoryModel.stockAllocGetType({}, function(state, data) {
        const resolve = {
          prodNoList: $scope.grid.methods.selectedData('prod_no'),
          prodList: $scope.grid.methods.selectedData('all'),
          type: type,
          skuCodeList: $scope.grid.methods.selectedData('sku_cd'),
          alloc_type: data.result[0].allocation_type,
          stock_edit_all: true   // 해당 sku 의 모든 배송처의 재고 수정여부
        };

        const size = type === 'real' ? 'xl' : '';
        const modal = commonSVC.openModal(size, { data: resolve }, 'ProdProductStockCtrl', 'views/prod/product/modals/modify_stock.html');

        modal.result.then(function () {
          $scope.searchDo(false);
        });
      });
    };

    /**
     * 사이드바 집계 클릭시
     */
    $scope.$on('sidebarSearch_product', function() {
      $timeout(() => {
        let tabIdx = 1;

        if ($rootScope.side_search.type === 'super') {
          tabIdx = 2;
        } else {
          tabIdx = 1;
        }

        $(`a[href='#content-tabs-${tabIdx}']`).trigger('click');
        $scope.searchDo(true, true);
      });
    });
  });
