import React, { CSSProperties, useState } from 'react';
import ValidationErrorLabel from './ValidationErrorLabel';
import { validateFtpAddr } from '../../utills/common';

interface FormData {
  ftp_name: string;
  ftp_addr: string;
  ftp_id: string;
  ftp_pw?: string;
  new_ftp_pw?: string;
  anonymous_yn: boolean;
  remote_directory?: string;
  port_no: string;
  passive_mode_yn: boolean;
  protocal_type: string;
}

interface HostingFormComponentProps {
  selectedFtpNo: number;
  registeredFTPNames: string[];
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  isSubmit: boolean;
  duplicateName: boolean;
  setDuplicateName: React.Dispatch<React.SetStateAction<boolean>>;
}

const HostingFormComponent: React.FC<HostingFormComponentProps> = ({ selectedFtpNo, registeredFTPNames, formData, setFormData, isSubmit, duplicateName, setDuplicateName }) => {
  const [isPwdEdit, setIsPwdEdit] = useState(false);    // 비밀번호 수정 여부

  /**
   * 입력값 변경
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault(); // 기본 동작을 막습니다.

    const { name, value, type, checked, files } = e.target;

    if (files && files.length > 0) {
      const filePath = files[0].webkitRelativePath;
      const directoryPath = filePath.substring(0, filePath.lastIndexOf('/'));

      setFormData((prevData) => ({
        ...prevData,
        [name]: directoryPath,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));

      if (name === 'ftp_name') {
        setDuplicateName(registeredFTPNames.some((ftpName: string) => ftpName === value));
      }
    }
  };

  return (
    <div>
      <table className="table table-p">
        <colgroup>
          <col style={{ width: '30%' }} />
          <col style={{ width: '70%' }} />
        </colgroup>
        <tbody>
          <tr>
            <th>
              <div className="ast"></div>
                호스팅 이름
            </th>
            <td>
              <div className="form-group">
                <input
                  type="text"
                  id="ftp_name"
                  name="ftp_name"
                  className="form-control"
                  value={formData.ftp_name}
                  onChange={handleChange}
                  required
                />
              </div>
              { isSubmit && !formData.ftp_name && <ValidationErrorLabel message="호스팅 이름은 필수입니다." /> }
              { isSubmit && duplicateName && formData.ftp_name && <ValidationErrorLabel message="같은 이름의 호스팅이 있습니다." /> }
            </td>
          </tr>
          <tr>
            <th>
              <div className="ast"></div>
                FTP 주소
            </th>
            <td>
              <div className="form-group">
                <input
                  type="text"
                  id="ftp_addr"
                  name="ftp_addr"
                  className="form-control"
                  value={formData.ftp_addr}
                  onChange={handleChange}
                  required
                />
              </div>
              { isSubmit && !validateFtpAddr(formData.ftp_addr) && <ValidationErrorLabel message="유효하지 않은 FTP 주소 입니다." /> }
            </td>
          </tr>
          <tr>
            <th>
              <div className="ast"></div>
                사용자 ID
            </th>
            <td>
              <div style={styles.formGroupBox}>
                <div
                  className="form-group mr-10"
                  style={styles.formWidthAuto}
                >
                  <input
                    type="text"
                    id="ftp_id"
                    name="ftp_id"
                    className="form-control"
                    value={formData.ftp_id}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div style={{ minWidth: '100px' }}>
                  <label className="checkbox-inline">
                    <input
                      type="checkbox"
                      style={{ top: '0' }}
                      id="anonymous_yn"
                      name="anonymous_yn"
                      onChange={handleChange}
                      checked={formData.anonymous_yn}
                    />
                      익명 연결
                  </label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>
                비밀번호
            </th>
            <td>
              <div style={styles.formGroupBox}>
                {!selectedFtpNo && (
                  <input
                    type="password"
                    className="form-control"
                    id="ftp_pw"
                    name="ftp_pw"
                    value={formData.ftp_pw}
                    onChange={handleChange}
                  />
                )}
                {selectedFtpNo && (
                  <>
                    <div
                      className="form-group mr-5"
                      style={styles.formWidthAuto}
                    >
                      <input
                        type="password"
                        className="form-control"
                        id="new_ftp_pw"
                        name="new_ftp_pw"
                        value={formData.new_ftp_pw}
                        placeholder='수정버튼을 눌러 비밀번호 변경'
                        onChange={handleChange}
                        readOnly={!isPwdEdit}
                      />
                    </div>
                    <div style={{ minWidth: '50px' }}>
                      <button type="button" className="btn btn-default" onClick={() => setIsPwdEdit(!isPwdEdit)}>수정</button>
                    </div>
                  </>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <th>
                리모트 디렉토리
              <br />
              <span style={styles.labelSub}>(절대 경로로 입력)</span>
            </th>
            <td>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="remote_directory"
                  name="remote_directory"
                  value={formData.remote_directory}
                  onChange={handleChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div className="ast"></div>
                포트번호
            </th>
            <td>
              <div style={styles.formGroupBox}>
                <div
                  className="form-group mr-10"
                  style={styles.formWidthAuto}
                >
                  <input
                    type="text"
                    className="form-control"
                    id="port_no"
                    name="port_no"
                    value={formData.port_no}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div style={styles.formWidthFix}>
                  <label className="checkbox-inline">
                    <input
                      type="checkbox"
                      style={{ top: '0' }}
                      id="passive_mode_yn"
                      name="passive_mode_yn"
                      checked={formData.passive_mode_yn}
                      onChange={handleChange}
                    />
                      Passive Mode
                  </label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>
                프로토콜
            </th>
            <td>
              <select
                className="form-control"
                style={styles.formWidthFull}
                id="protocal_type"
                name="protocal_type"
                value={formData.protocal_type}
                onChange={handleChange}
              >
                <option value="자동">자동</option>
                <option value="FTP">FTP</option>
                <option value="SFTP">SFTP</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  formGroupBox: {
    display: 'flex',
    alignItems: 'center',
  },
  formWidthAuto: {
    flex: 1,
  },
  formWidthFull: {
    width: '100%',
  },
  formWidthFix: {
    minWidth: '100px',
  },
  labelSub: {
    color: '#777',
  },
  fileAttachBtn: {
    margin: '0 0 0 10px',
    display: 'inline-block',
    width: '36px',
    height: '36px',
    border: '1px solid #ddd',
    cursor: 'pointer',
  },
  fileAttachIcon: {
    display: 'inline-block',
    width: '100%',
    height: '100%',
    background: 'url(../../assets/images/imagehosting/ico_fileattach.svg) no-repeat center',
  },
};

export default HostingFormComponent;