'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentAgencyListCtrl',
    function (// common
      $rootScope, $scope, $timeout, $filter, gettextCatalog, subUserList,
      // info
      settings, userInfo,
      // SVC
      commonSVC, shipmentSVC,
      // List
      warehouseList, subChannelList, useSystemList, gridInfo
    ) {
      $scope.test = window.location.host.includes('localhost') || window.location.host.includes('staging');
      $scope.results = []; // 메모동기화
      $scope.loadDataTable = gridInfo;

      // 모덜번호의 커스텀컬럼 번호
      const modelAddColNo = $rootScope.addColumnList.data.results.find(({ col_name }) => col_name.endsWith('모델번호'))?.addcol_no;

      // 대리점(특약점) 목록
      const agencyList = subUserList.data.map(o => {
        return {
          sub_sol_no: o.sol_no,
          company_name: o.company_name
        };
      });

      const ordStatus = [
        { key: '결제완료', value: '결제완료' },
        { key: '신규주문', value: '신규주문' },
        { key: '주문보류', value: '주문보류' },
        { key: '출고대기', value: '출고대기' },
        { key: '출고보류', value: '출고보류' },
        { key: '운송장출력', value: '운송장출력' },
        { key: '출고완료', value: '출고완료' },
        { key: '배송중', value: '배송중' },
        { key: '배송완료', value: '배송완료' },
        { key: '구매결졍', value: '구매결정' },
        { key: '판매완료', value: '판매완료' },
        { key: '출고지연', value: '출고지연' },
        { key: '취소요청', value: '취소요청' },
        { key: '취소완료', value: '취소완료' },
        { key: '반품요청', value: '반품요청' },
        { key: '반품진행', value: '반품진행' },
        { key: '반품회수완료', value: '반품회수완료' },
        { key: '반품완료', value: '반품완료' },
        { key: '교환요청', value: '교환요청' },
        { key: '교환진행', value: '교환진행' },
        { key: '교환회수완료', value: '교환회수완료' },
        { key: '교환완료', value: '교환완료' },
        { key: '맞교환요청', value: '맞교환요청' },
        { key: '맞교환완료', value: '맞교환완료' },
        { key: '주문재확인', value: '주문재확인' },
      ];

      // 별칭정보 담기
      const seller_nick_info = [];

      _.forEach(subChannelList.data.results, function(chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });

      $scope.totalCount = 0;

      $scope.isManageStock = $rootScope.user_profile.sol_stock > 0; // 재고관리여부
      $scope.countList = { total: 0 };
      $scope.selectCount = 'total';

      $scope.excelFieldList = angular.copy(shipmentSVC.excelFieldList.agency);

      subChannelList.data.results.push({ shop_name: '직접입력', shop_cd: 'A000' });

      /* 검색 및 버튼 관련 변수 */
      const search = {
        searchForm: {
          sub_sol_no: '',
          search_key: '',
          search_word: '',
          search_type: 'partial',
          date_type: 'o.wdate',
          sdate: moment().subtract(useSystemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          shop_id: '',
          shop_cd: '',
          status: '',
          page: 'agency',
          bundle_yn: 1,
          delivery_vendor: '', //배송처
          // to_country_code: '', //수령자 국가
          delay_status: '', //배송지연
          unstore_status: '', //출고가능여부
          map_yn: '', //매칭여부
          multi_search_word: '', //멀티서치워드
          multi_type: 'o.shop_ord_no', //멀티서치 타입
          // excel_down_yn: '',
          memo_yn: '',
          multi_shop_id: ''
        },
        searchData: {
          all_checked: false,  // 전체선택 여부
          selectType: true,    // 셀렉트카운팅시 번들갯수 말고 row별로 처리 여부
          selectCount: 0,      // 검색전체 건수
          selectBundleCnt: 0,  // 선택 묶음건수
          totalCount: 0,       // 검색전체 건수
          showCount: 100,      // 페이지당 보여줄 건수
          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: '' },
            { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'o.shop_ord_no' },
            { label: gettextCatalog.getString('주문자'), value: 'o.order_name' },
            { label: gettextCatalog.getString('수령자'), value: 'o.to_name' },
            { label: gettextCatalog.getString('주문자 전화번호, 주문자 휴대폰번호'), value: 'o.order_tel,o.order_htel' },
            { label: gettextCatalog.getString('SKU코드'), value: 'p.sku_cd' },
            { label: gettextCatalog.getString('판매자관리코드'), value: 'o.c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'o.shop_sale_name' },
            { label: gettextCatalog.getString('SKU상품명'), value: 'p.prod_name' },
            { label: gettextCatalog.getString('모델번호'), value: `addcol_${modelAddColNo}` }
          ],
          search_date_type: [
            { label: gettextCatalog.getString('주문 수집일'), value: 'o.wdate' },
            { label: gettextCatalog.getString('주문일'), value: 'o.ord_time' },
            { label: gettextCatalog.getString('결제 완료일'), value: 'o.pay_time' },
            { label: gettextCatalog.getString('상태 변경일'), value: 'o.ord_status_mdate' },
            { label: gettextCatalog.getString('발송 예정일'), value: 'o.ship_plan_date' },
            { label: gettextCatalog.getString('주문 확인일'), value: 'o.ord_confirm_time' }
          ],
          search_multi_items: [
            { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'o.shop_ord_no' },
            { label: gettextCatalog.getString('모델번호'), value: `addcol_${modelAddColNo}` }
          ]
        },
        searchDetail: [
          {
            title: gettextCatalog.getString('대리점(특약점) 선택'),
            search_name: 'sub_sol_no',
            item_list: angular.copy(agencyList),
            item_key: 'company_name',
            item_value: 'sub_sol_no',
            select_value: ''
          },
          {
            title: gettextCatalog.getString('쇼핑몰 선택'),
            search_name: 'shop_cd',
            item_list: angular.copy(_.unionBy(subChannelList.data.results || [], 'shop_cd')),
            item_key: 'shop_name',
            item_value: 'shop_cd',
            select_value: '',
            add_class: 'select-search'
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
            search_name: 'shop_id',
            item_list: angular.copy(subChannelList.data.results || []),
            item_key: 'shop_id',
            item_value: 'shop_id',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              return option.shop_cd == $scope.searchForm.shop_cd && option.shop_id;
            }
          },
          {
            title: gettextCatalog.getString('주문상태'),
            search_name: 'status',
            item_list: ordStatus,
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 배송지연여부
            title: gettextCatalog.getString('배송지연여부'),
            search_name: 'delay_status',
            item_list: [{ key: 1, value: '배송지연 O' }, { key: 0, value: '배송지연 X' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          }
        ],
        searchDetailMulti: [
          {
            title: gettextCatalog.getString('쇼핑몰(계정) 선택'),
            search_name: 'multi_shop_id',
            item_list: angular.copy(subChannelList.data.results).sort(function(prev, curr) {
              return `{${curr.pa_shop_cd} ${curr.shop_name}` < `{${prev.pa_shop_cd} ${prev.shop_name}` ? 1 : -1;
            }),
            item_key: 'shop_id',
            item_value: 'shop_id',
            search_word: ''
          },
        ]
      };

      $scope.searchData = angular.copy(search.searchData);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchDetailMulti = angular.copy(search.searchDetailMulti);
      $scope.searchDetailMultiSelect = angular.copy(search.searchDetailMultiSelect);
      $scope.searchBtn = {
        table_actions: [
          {
            label: gettextCatalog.getString('엑셀 다운로드'),
            add_class: 'a-btn-blue',
            action: () => {
              $scope.excelDown();
            },
          }
        ]
      };

      $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function() {
          $scope.searchDo(true, true);
        },
        resetDo: function() {
          $scope.resetDo();
        },
        changeLen: function(count) {
          $scope.changeCount(count);
        }
      };

      $scope.selectRowUniqList = [];

      $scope.isMemoDisabled = (uniq) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(uniq);

      // 데이터 테이블에서 선택한 rowList 정보 받아옴
      $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
        $scope.selectRowUniqList = data.map(({ uniq }) => uniq);
        $scope.$apply();
      }, 200));

      /* 데이터 테이블 */
      $scope.grid = {};
      $scope.grid.methods = {};
      $scope.grid.options = {
        pinningColumns: ['widget'],
        defaultSortingColumns: ['wdate'],
        notMovingColumns: [],
        notVisibleColumns: [],
        alignCenterColumns: ['company_name', 'widget'],
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/order/agency/list`,
          requestWillAction: function (data) {
            if ($rootScope.side_search.page === 'order' && $rootScope.side_search.search) {
              $scope.resetDo(true);

              $scope.searchDetail[0].select_value = $rootScope.side_search.sub_sol_no || '';
              $scope.searchDetail[3].select_value = $rootScope.side_search.status[0] || '';
              $scope.searchDetail[4].select_value = $rootScope.side_search.delay_status || '';

              $scope.searchForm.sub_sol_no = $rootScope.side_search.sub_sol_no;
              $scope.searchForm.status = $rootScope.side_search.status[0];
              $scope.searchForm.delay_status = $rootScope.side_search.delay_status;

              $scope.searchForm.date_type = $rootScope.side_search.date_type;
              $scope.searchForm.sdate = $rootScope.side_search.sdate;
              $scope.searchForm.edate = $rootScope.side_search.edate;

              $rootScope.side_search.search = false;
            }

            data.orderby = '';
            data = angular.merge({ orderby: 'wdate desc' }, data, $scope.searchForm);

            //엑셀 모달용 데이터
            $scope.pageData = angular.copy(data);

            return data;
          },
          requestDidAction: function (result) {
            $scope.totalCount = result.recordsTotalCount;
            $scope.searchData.totalCount = result.recordsTotalCount;

            $scope.countList.total = result.recordsTotalCount;

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 50,
            template: function(row) {
              return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('${row.uniq}', '${row.sol_no}', '${row.company_name}')" >상세</button>`;
            }
          },
          {
            key: 'company_name',
            title: '대리점(특약점)',
            width: 100
          },
          {
            key: 'ord_status',
            title: '주문상태',
            width: 100,
            notCompile: true,
            template: function(row) {
              return $filter('statusColor')(row.ord_status);
            }
          },
          {
            key: 'shop_name',
            title: '쇼핑몰(계정)',
            width: 150,
            template: function(row) {
              let img = '직접입력';

              if (row.shop_cd !== 'A000') {
                const shop_info = commonSVC.getShopIdViewText(useSystemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

                img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right"><img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">${shop_info[1]}</span>`;
              }

              return img;
            }
          },
          {
            key: 'wdate',
            title: '주문수집일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'shop_ord_no',
            title: '쇼핑몰 주문번호',
            width: 190,
            filter: 'isNullHyphen'
          },
          {
            key: 'model_no',
            title: '모델번호',
            width: 190
          },
          {
            key: 'shop_sale_name',
            title: '쇼핑몰 상품명',
            width: 300,
            notCompile: true,
            template: function(row) { //이미지+상품명
              const exchangeSpan = row.exchange_ord_yn == 1 || row.exchange_yn == 1 ? '<span class="label label-default bg-blue">교환주문</span> ' : '';
              let giftSpan = '';
              let arrivalGuaranteed = '';
              let shipHopeDate = '';

              // 스마트스토어는 선물하기 주문일 시 선물하기 플래그 추가 및 도착보장 플래그 추가
              if (['A077', 'A000'].includes(row.pa_shop_cd)) {
                giftSpan = row.misc20 === '1' ? '<span class="label label-default bg-green">선물하기</span> ' : '';
                arrivalGuaranteed = ['true', 'is_arrival_guaranteed'].includes(row.misc17) ? '<span><img ng-src="/assets/images/naver_flagX2.png" width="75" height="25" class="mr-5" alt="" src="/assets/images/naver_flagX2.png"></span>' : '';
                shipHopeDate = ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(row.misc19) ? '<span class="label bg-delivery">희망일배송</span> ' : '';
              }

              // [OSSE]클레임 주문에 대한 사본주문은 플래그 노출 && 분할주문은 분할 플래그 && 원주문은 원주문 플래그 노출
              const claimCopyOrderSpan = row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-red">사본</span> ' : '';
              const dividedOrderSpan = $rootScope.osse_sol && row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') && !Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-yellow">분할</span> ' : '';
              const originOrderSpan = $rootScope.osse_sol && !row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') ? '<span class="label label-default bg-black">원주문</span> ' : '';
              const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

              if (row.sale_img) {
                const img = row.sale_img || '/assets/images/upload.png';

                return `<span>${exchangeSpan}${arrivalGuaranteed}${shipHopeDate}${giftSpan}${originOrderSpan}${dividedOrderSpan}${claimCopyOrderSpan}<img src='${img}' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${filteredShopSaleName}</span>`;
              } else {
                return `<span>${exchangeSpan}${arrivalGuaranteed}${shipHopeDate}${giftSpan}${originOrderSpan}${dividedOrderSpan}${claimCopyOrderSpan}${filteredShopSaleName}</span>`;
              }
            }
          },
          {
            key: 'shop_opt_name',
            title: '옵션',
            width: 220
          },
          {
            key: 'sale_cnt',
            title: '주문수량',
            width: 60
          },
          {
            key: 'sales',
            title: '금액',
            width: 100,
            notCompile: true,
            template: function(row) {
              return $filter('currency')(row.sales, '', 0);
            }
          },
          {
            key: 'carr_name',
            title: '택배사',
            width: 120
          },
          {
            key: 'invoice_no',
            title: '운송장번호',
            width: 150,
            template: function(row) {
              return row.carr_no === 956 ? String(row.invoice_no).padStart(12, '0') : row.invoice_no;
            }
          }
        ]
      };

      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, callback) {
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = (noSearch = false) => {
        $scope.searchForm = angular.copy(search.searchForm);
        $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchDetailMulti = angular.copy(search.searchDetailMulti);
        $scope.searchDetailMultiSelect = angular.copy(search.searchDetailMultiSelect);
        $scope.isMultiSearch = false;
        $scope.searchData.search_key_name = '전체';
        $scope.selectCount = 'total';

        if (!noSearch) {
          $timeout(function() {
            $scope.searchDo(true, true);
          });
        }
      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };

      /**
       * 상세페이지 보여주기
       */
      $scope.showDetail = function(uniq, sol_no, company_name) {
        const resolve = {
          data: {
            fromPage: search.searchForm.page,
            sub_sol_no: sol_no,
            uniq,
            warehouseList: warehouseList.data.result || [],
            systemList: useSystemList.data || [],
            agency_name: company_name
          }
        };
        const modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 엑셀 다운 모달창
       * */
      $scope.excelDown = type => {
        // 엑셀권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }

        const resolve = {
          data: {
            type: type,
            excelFieldList: $scope.excelFieldList,
            title: '대리점(특약점) 주문',
            url: '/app/order/excelDown',
            searchForm: $scope.pageData,
            isSuper: true,
            page: 'agency',
            visibleTable: $scope.grid.methods.getColumnsVisible(), // 활성화된 필드 데이터,
            progress: true
          }
        };
        const selectList = $scope.grid.methods.selectedData('all');

        if (!selectList.length) {
          if (!$scope.countList.total) {
            commonSVC.showMessage(gettextCatalog.getString('다운로드 가능한 주문이 없습니다.'));

            return false;
          }

          resolve.data.count = $scope.countList.total;
          resolve.data.isAll = true;
        } else {
          resolve.data.uniq = selectList.map(({ uniq }) => uniq);
          resolve.data.count = selectList.length;
          resolve.data.isAll = false;
        }

        commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
      };

      /**
       * 테이블 컬럼 사이즈 확인
       */
      $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
        if (toState.name == 'main.order_agency_order_list') {
          const $wrapper = $('#order_agency_grid_wrapper');
          const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

          if ($tableHeader.width() < 1) {
            $timeout(function () {
              $scope.grid.methods.calcLayout('set_width');
            }, 0);
          }
        }
      });

      $scope.$on('OnSelectChangeBefore', function(event, data) {
        // 2017-04-14 chris 합포장 주문건인경우 동일하 묶음번호인 주문건 모두 selected 처리
        // 2017-04-27 MatthewKim todo. 이거는 좀더 빠르게 바꿀 필요가 있음
        // 2017-05-10 MatthewKim $timeout 이용하지 않고 다이렉트로 처리함 (속도 개선)
        // 2017-05-10 MatthewKim 선택 발생시 타겟 전체를 클래스로 받아 빠르게 처리함
        $(data.targetClasses).addClass('selected');
      });

      /**
       * 테이블에서 선택해제시
       */
      $scope.$on('OnDeSelected', function(event, data) {
        // 2017-04-14 chris 합포장 주문건인경우 동일하 묶음번호인 주문건 모두 selected 처리
        // 2017-05-10 MatthewKim $timeout 제거 하고 처리 위치 제일 먼저 위로 올림
        // 2017-05-10 MatthewKim 선택 해제 발생시 타겟 전체를 클래스로 받아 처리함
        $(data.targetClasses).removeClass('selected');
      });

      /**
       * 사이드바 집계 클릭시
       */
      $scope.$on('sidebarSearch_order', function() {
        $scope.searchDo(true, true);
      });
    });
