/**
 * 2018-03-30 Daniel
 * 도움말
 */
'use strict';

angular.module('gmpApp')
  .service('helpModel', function (settings, commonSVC) {
    const Url = `${settings.pa20ApiUrl}/app/guide`;

    return {
      /**
       * 추천도움말 조회
       */
      getRcmdHelp: function (data) {
        const url = `${Url}/help`;

        const params = {
          rcmd_yn: true,
          orderBy: 'mdate',
          limit: 5,
          ...data,
        };

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 자주묻는질문 조회
       */
      getPvHelp: function (data, search_word = {}) {
        const url = `${Url}/help`;

        const params = {
          orderBy: 'pv_cnt',
          limit: 5,
          ...data,
          ...search_word,
        };

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 도움말 검색
       */
      getSearchHelp: function (data) {
        const url = `${Url}/help`;

        const params = {
          search_word: data,
        };

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 도움말 상세
       */
      getHelpDetail: function (guideNo) {
        const url = `${Url}/help/${guideNo}`;

        return commonSVC.sendUrl('GET', url, {});
      },
    };
  });
