/**
 * 에러코드 생성 규칙
 * (대메뉴별 구분코드)_ERROR_OOOO
 * OOOO 부분은 관련 기능별로 A~Z까지 순서대로 생성

// 타입별 에러
var errorType = {
  // 주문
  // ORD_ERROR_A~ 주문 등록관련
  ORD_ERROR_A001: '주문 등록에 실패했습니다',
  ORD_ERROR_A002: '주문 수정에 실패했습니다',
  // ORD_ERROR_B~ 발송관련
  ORD_ERROR_B001: '주문 발송처리에 실패했습니다1',
  ORD_ERROR_B002: '주문 발송처리에 실패했습니다2',

  // 상품
  // OL_ERROR_A~ 쇼핑몰 상품 등록관련
  OL_ERROR_A001: '온라인 상품등록에 실패했니다.',
  OL_ERROR_A002: '온라인 상품수정에 실패했니다.',
  OL_ERROR_A003: '쇼핑몰 상품 등록 DB 한도 초과',

  // OL_ERROR_B~ 온라인상품 조회 관련
  OL_ERROR_B001: '온라인 리스트조회 실패하였습니다.',
  OL_ERROR_B002: '온라인 리스트조회 실패하였습니다2.',
  // OL_ERROR_C~ 온라인상품 머릿말 꼬리말 관련
	OL_ERROR_C001: '온라인상품 머릿말 설정에 실패하였습니다.'

  // OL_ERROR_D~ 상품 매칭 관련

  // ST_ERROR_A~ 컬럼 설정 에러
};
*/

'use strict';

angular.module('gmpApp')
  .service('errorSVC', function (commonSVC, questionModel) {
    // 기본 에러 메세지
    const defaultError = '알수없는 오류입니다.\n계속 발생시 1:1 문의부탁드립니다.';

    // 타입별 에러
    const errorType = {
      // 주문
      order: {
        'ordNameManager no ord': '적용가능한 주문건이 없습니다.\n오타 및 공백 수 확인 후 재시도 바랍니다.'
      },

      // 재고
      stock: {
        'duplicated supplier': '이미 등록된 매입처가 있습니다.',
      },

      // 배송처
      depot: {
        'duplicated depot': '이미 등록된 배송처가 있습니다.',
      },

      // 상품
      prod: {
        'Image registration error': '이미지 등록에 실패했습니다.',
        'category add error': '카테고리 등록에 실패했습니다.',
        'duplicated online product': '이미 등록된 온라인 상품이 있습니다.',
        'duplicated shop product': '이미 등록된 쇼핑몰 상품코드가 있습니다.',
        'product does not exist': '매칭된 SKU상품을 찾을 수 없습니다.\n복구 후 다시 진행해주시기 바랍니다.',
        'master does not exist': '매칭된 마스터상품을 찾을 수 없습니다.\n복구 후 다시 진행해주시기 바랍니다.',
        'product is exist that using this pa_category': '선택한 분류 내에 온라인상품으로 등록된 카테고리가 존재합니다.\n등록된 온라인상품을 다른 카테고리로 변경 혹은 상품 삭제 후 분류 삭제가 가능합니다.',
        'product is exist that using this category': '해당 카테고리로 등록된 온라인 상품이 존재합니다. \n해당 상품을 다른 카테고리로 변경 혹은 상품 삭제 후 카테고리 삭제가 가능합니다.',

        'OL_ERROR_A003': '쇼핑몰 상품 등록 DB 한도 초과',
        // OL_ERROR_D~ 상품 매칭 관련
        'OL_ERROR_D001': '매칭된 마스터상품이 없습니다',
        'OL_ERROR_D002': '변경된 사항이 없습니다',
        'OL_ERROR_D003': '판매자관리코드가 수집된 상품이 없습니다.',
        'pinned category': '고정된 카테고리 입니다.\n해제 후 다시 진행해주시기 바랍니다.',
        'duplicate category name': '중복된 이름입니다.',
        'category info does not exist': '카테고리를 다시 선택해주시기 바랍니다.',
        'Template Matching Error': '템플릿 매칭시 오류가 발생되었습니다. 등록하시는 상품의 템플릿 정보를 재매칭 부탁드립니다.'
      },

      // 정산
      calculate: {

      },

      // 작업
      work: {
        'not_supported_act': '지원하지 않는 쇼핑몰입니다.',
        'smartStore_workPause': '스마트스토어의 API 서버 오류로 인하여 작업을 제한합니다.',
        'Duplicate Error': '이미 등록한 작업이 존재하므로 등록이 취소 되었습니다.',
        'Cannot read property \'category_code\' of null': '카테고리정보가 올바르지 않습니다.',
        'none template': '선택하신 상품에 템플릿이 없어 작업이 불가합니다.\n템플릿을 지정하세요.',
        'none category': '선택하신 상품에 카테고리가 없어 작업이 불가합니다.\n템플릿을 지정하세요.',
        'check_charge': '잔여 작업 카운트 부족.\n버전 업그레이드 또는 카운트 충전 요망',
        '11st check': '작업등록에 실패하였습니다.\n11번가 개편작업 진행중 입니다.\n자세한 사항은 공지사항 참조 바랍니다.',
        'workInseption': '현재 해당 작업이 점검중에 있습니다.\n점검후 안내가 나갈 예정이니 참고하시어 작업하시기 바랍니다.',
        'akMall': '현재 ak몰은 점검중에 있어서 작업 진행이 불가합니다.',
        'not_supported_madein': '해당 원산지를 사용할 수 없는 쇼핑몰입니다.',
        'workPause': '해당 작업은 임시 차단된 상태입니다.\n자세한 내용은 공지사항을 확인해주세요.',
        'not_ebay_uuid': 'G마켓 물류 연동에 변경사항이 있습니다. \nG마켓물류 배송처를 수정하여 주신후 작업 재시도 부탁드립니다.',
        'none uuid': '계정 정보가 오래되어 작업 등록에 실패하였습니다. 쇼핑몰 관리 페이지에서 수정 후 작업 재시도 부탁드립니다.',
        'none uuid2': '계정 정보가 오래되어 조회에 실패하였습니다. 쇼핑몰 관리 페이지에서 수정 후 작업 재시도 부탁드립니다.',
        'empty items prod_noti_data': '상품정보제공고시 항목중 빈값이 존재합니다.\n상품정보제공고시 항목은 모두 필수로 입력되어야 합니다.',
        'channel_account not found': '쇼핑몰 관리 페이지에서 계정정보를 확인 후 작업 재시도 부탁드립니다.',
        'status': '판매대기 상태의 상품만 작업이 가능합니다.',
        'none order': '작업 가능한 주문이 없습니다.',
      },

      // 설정
      settings: {
        // ST_ERROR_A~ 컬럼 설정 에러
        ST_ERROR_A001: '중복된 항목이 있어 등록에 실패하였습니다',
      },

      // 무조건 알림여부
      notify: {
        akMall: true
      },
    };

    return {
      /**
			 * 에러 분류와 코드로 에러메세지 반환
			 *
			 * @property {String} type - 에러 대분류
			 * @property {String} code - 에러 코드
			 */
      getError: function(type, code, msg) {
        try {
          if (code?.includes('원본 에러메세지 반환:')) {
            return code.replace('Error: ', '').replace('원본 에러메세지 반환: ', '');
          }

          const error_keys = Object.keys(errorType[type]);
          const found_error_key = error_keys.find(error_key => code?.includes(error_key));

          return found_error_key ? errorType[type][found_error_key] : msg || defaultError;
        } catch (err) {
          return msg || defaultError;
        }
      },

      /**
			 * 실패 에러메시지 반환
			 *
			 * @property {Array} errSite - 에러 쇼핑몰
			 * @property {Array} code - 에러 사유
       * @property {String} type - 에러 대분류
			 */
      getErrorMsg: function(errSite, errReason, type) {
        const checkError = {};
        errSite.forEach((v, idx) => {
          if (errReason[idx] in checkError) {
            checkError[errReason[idx]][1] = ++checkError[errReason[idx]][1];
          }
          else {
            checkError[errReason[idx]] = [v, 1];
          }
        });

        const errMsg = `\n---------- 실패한 사이트 ----------\n${Object.entries(checkError).map(([key, value]) => {
          const error = (type === 'work' && key.startsWith('BLOCK_JOB: ')) ? key.replace('BLOCK_JOB: ', '') : (key.includes('Duplicate Error')) ? '이미 등록한 작업이 존재하므로 등록이 취소 되었습니다.' : (errorType[type][key.replace('Error: ', '')] || defaultError);
          if (value[1] > 1) {
            return `${value[0]} 외 ${value[1] - 1}건 : ${error}`;
          } else {
            return `${value[0]} : ${error}`;
          }
        }).join('\n')}`;

        return errMsg;
      },
      // showMessage 여부
      getNotify: function (type) {
        return errorType.notify[type] || false;
      },

      /**
       * sendErrorReport
       * @description 버그 리포트 전송
       *
       * @param {Object} report - 리포트
       *  @param {String} title - 제목
       *  @param {String} content - 내용
       */
      sendErrorReport: async (report) => {
        const confirm = await commonSVC.showConfirm('에러', '예상치 못한 오류가 발생하였습니다.\n플레이오토측으로 오류 보고서를 전송하시겠습니까?\n\n보고서는 오류가 발생했을 당시의 데이터를 저장하여 자동으로 전송됩니다.');

        if (confirm) {
          await questionModel.frontInsertQuestion({
            board_status: '접수대기',
            board_type: '기타',
            title: report.title || '솔루션 버그 리포트',
            content: report.content || '',
            del_comment: '',
            email: 'testkr',
            file: [],
            file_array: [],
            htel: '010-1234-1234',
            m_no: 1,
            name: 'PLAYAUTO',
            shop_select: 'all',
            tel: '010-1234-1234',
            test_agree_yn: true
          });

          commonSVC.showMessage('전송 완료', '도움주셔서 감사합니다.\n빠른 시일내에 확인 후 수정하도록 하겠습니다.');
        }
      }
    };
  });
