'use strict';
angular.module('gmpApp')
  .controller('CalculateOrderListCtrl', function ($scope, $rootScope, $state, stateSVC, commonSVC, stockExceptionSVC, gettextCatalog, $compile,
    settings, $filter, systemList, userInfo) {
    /**
     * 서치바 관련 변수
     */
    $scope.searchData = angular.copy(stateSVC.searchData);
    $scope.searchForm = angular.copy(stateSVC.searchForm);

    if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
      $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
    }

    // 상세검색 데이터
    const channelListArr = angular.copy($rootScope.useChannelList({ func: shop => ['ORD', 'ORD_M', 'ALL'].includes(shop.shop_type) }));

    const commaCreate = () => {
      const commaRegex = /\B(?=(\d{3})+(?!\d))/g;

      $scope.total_sett_price_comma = ($scope.total_sett_price || 0)?.toString().replace(commaRegex, ',');
      $scope.total_margin_comma = ($scope.total_margin || 0)?.toString().replace(commaRegex, ',');
      $scope.avg_margin_comma = ($scope.avg_margin || 0)?.toString().replace(commaRegex, ',');
    };

    channelListArr.push({ shop_name: '직접입력', shop_cd: 'A000' });
    const searchDetail = [
      {
        title: gettextCatalog.getString('정산 상태'),
        search_name: 'sett_yn',
        item_list: [
          { name: '정산완료', value: 1 },
          { name: '미정산', value: 0 }
        ],
        item_key: 'name',
        item_value: 'value',
        select_value: ''
      },
      {
        title: gettextCatalog.getString('주문 상태'),
        search_name: 'ord_status',
        item_list: [
          { ord_status: '결제완료' },
          { ord_status: '신규주문' },
          { ord_status: '주문보류' },
          { ord_status: '배송지연' },
          { ord_status: '출고대기' },
          { ord_status: '출고보류' },
          { ord_status: '운송장출력' },
          { ord_status: '출고완료' },
          { ord_status: '배송중' },
          { ord_status: '배송완료' },
          { ord_status: '구매결정' },
          { ord_status: '클레임' }
        ],
        item_key: 'ord_status',
        item_value: 'ord_status',
        select_value: ''
      },
      {
        title: gettextCatalog.getString('쇼핑몰 선택'),
        search_name: 'shop_cd',
        item_list: _.unionBy(channelListArr, 'shop_cd'),
        item_key: 'shop_name',
        item_sub_key: 'shop_name_kr',
        item_value: 'shop_cd',
        select_value: '',
        add_class: 'select-search'
      },
      {
        title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
        search_name: 'shop_id',
        item_list: channelListArr,
        item_key: 'shop_id',
        item_value: 'shop_id',
        select_value: '',
        add_class: 'select-search',
        filter: function(option) {
          // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
          return option.shop_cd == $scope.searchForm.shop_cd && option.shop_id;
        }
      },
    ];

    $scope.searchDetail = angular.copy(searchDetail);

    // 데이터 테이블 관련기능
    $scope.searchFn = {
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    // 검색영역 버튼
    $scope.searchBtn = {
      // 검색 영역 오른쪽 버튼
      actions_right: [
        {
          label: `<i class="picon-get"></i><span>${gettextCatalog.getString('정산 수집')}<span class="caret"></span>`,
          small_label: '정산수집',
          sub_label: '',
          btn_type: 'dropdown',
          add_class: 'col-xs-6',
          item_list: [
            {
              label: gettextCatalog.getString('정산 자동 수집'),
              hotkey: '',
              action: function () {
                // 정산수집 권한 확인. 2019-01-04 rony
                if (commonSVC.checkPermission('calculate.roles.scrapCalculate', userInfo.permission)) {
                  $scope.calculateCollect();
                }
              }
            },
            {
              label: `${gettextCatalog.getString('정산 수동 업로드')}`,
              small_label: '정산 수동 업로드',
              hotkey: '',
              action: function () {
                if (commonSVC.checkPermission('calculate.roles.scrapCalculate', userInfo.permission)) {
                  $scope.calculateExcelUpload();
                }
              }
            }
          ]
        },
        {
          label: `<i class="picon-money-calendar"></i><span>${gettextCatalog.getString('정산 캘린더')}`,
          small_label: '정산캘린더',
          sub_label: '',
          btn_type: 'button',
          add_class: 'col-xs-6',
          action: function() {
            $scope.calculateCalenderModal();
          }
        }
      ]
    };

    $scope.grid = {
      methods: {},
      options: {
        pinningColumns: [],
        alignCenterColumns: ['sett_type', 'sale_cnt', 'ord_status', 'sett_yn', 'order_name'],
        alignRightColumns: [
          'sales_unit', 'sales', 'pay_amt', 'ship_cost',
          'ship_cost2', 'refund_ship_cost', 'claim_refund_sales',
          'sales_fee', 'fee_rate', 'sett_plan_price',
          'sett_price', 'deduct_price', 'shop_burden_discount_amt',
          'seller_burden_discount_amt', 'adv_fee', 'cost_price_cal',
          'predict_margin', 'predict_margin_per', 'margin', 'margin_per',
          'margin_err', 'margin_per_err', 'supply_price'
        ],
        defaultSortingColumns: ['sett_com_date'], //3
        notSortingColumns: ['pay_confirm_date', 'refund_date', 'sett_plan_date', 'ori_shop_ord_no', 'ord_pay_no', 'shop_opt_name', 'sale_cnt', 'sales_unit', 'pay_amt', 'ship_cost', 'ship_cost2', 'refund_ship_cost', 'claim_refund_sales', 'sales_fee', 'fee_rate', 'sett_plan_price', 'shop_burden_discount_amt', 'seller_burden_discount_amt', 'adv_fee', 'shop_channel', 'biz_reg_no', 'misc_etc'],
        notResizingColumns: [],
        notMovingColumns: [],
        notVisibleColumns: [
          'pay_confirm_date', 'refund_date',
          'sett_plan_date', 'ori_shop_ord_no', 'ord_pay_no',
          'shop_opt_name', 'sale_cnt', 'sales_unit',
          'pay_amt', 'ship_cost', 'ship_cost2',
          'refund_ship_cost', 'claim_refund_sales', 'sales_fee',
          'fee_rate', 'sett_plan_price', 'shop_burden_discount_amt',
          'seller_burden_discount_amt', 'adv_fee', 'biz_reg_no',
          'misc_etc', 'shop_channel'
        ],
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/calculate/listOfOrder`,
          requestWillAction: function (d) {
            const data = angular.merge({}, d, $scope.searchForm);

            return data;
          },
          requestDidAction: function(result) {
            $scope.searchData.totalCount = result.recordsTotal;

            // 주문관리 정산 페이지 대시보드 데이터
            $scope.total_sett_price = result.totalSettPrice || 0;
            $scope.total_margin = result.sumMargin || 0;
            $scope.avg_margin = result.avgMargin || 0;
            $scope.avg_margin_per = +parseFloat(result.marginPercent).toFixed(1);

            commaCreate();

            return result.results;
          }
        },
        columns: [
          // 쇼핑몰(ID)
          {
            key: 'shop_name',
            title: '쇼핑몰(계정)',
            width: 150,
            notCompile: true,
            template: function(row) {
              let return_value = '';

              if (row.shop_name) {
                return_value += row.shop_name;
              }
              if (row.shop_id) {
                return_value += `(${row.shop_id})`;
              }

              return return_value;
            }
          },
          // 주문번호
          {
            key: 'shop_ord_no',
            title: '주문번호',
            width: 280
          },
          // 주문상태
          {
            key: 'ord_status',
            title: '주문상태',
            width: 100,
            notCompile: true,
            template: function(row) {
              return $filter('statusColor')(row.ord_status);
            }
          },
          // 정산상태
          {
            key: 'sett_yn',
            title: '정산상태',
            width: 100,
            notCompile: true,
            template: function(row) {
              if (row.sett_yn === 1) {
                return '정산완료';
              } else {
                return '미정산';
              }
            }
          },
          // 정산구분
          {
            key: 'sett_type',
            title: '정산구분',
            width: 100
          },
          // 상품명
          {
            key: 'shop_sale_name',
            title: '쇼핑몰 상품명',
            width: 300,
            notCompile: true,
            template: function(row) {
              const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

              return row.ori_uniq && row.exchange_ord_yn
                ? `<span class="label label-default bg-blue">교환주문</span> ${filteredShopSaleName}`
                : filteredShopSaleName;
            }
          },
          // 구매자명
          {
            key: 'order_name',
            title: '구매자명',
            width: 100
          },
          {
            key: 'wdate',
            title: '주문수집일',
            width: 150,
            filter: 'dateValid'
          },
          // 결제완료일
          {
            key: 'pay_time',
            title: '결제완료일',
            width: 150,
            filter: 'dateValid'
          },
          // 정산완료일
          {
            key: 'sett_com_date',
            title: '정산완료일',
            width: 150,
            filter: 'dateValid'
          },
          // 쇼핑몰 상품코드
          {
            key: 'shop_sale_no',
            title: '쇼핑몰 상품코드',
            width: 150
          },
          // 판매자관리코드
          {
            key: 'c_sale_cd',
            title: '판매자관리코드',
            width: 150
          },
          // 판매금액
          {
            key: 'sales',
            title: '판매금액',
            width: 150,
            tooltip: '할인이 적용되지 않은 판매가',
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.sales, '', 0);
            }
          },
          // 공제금액
          {
            key: 'deduct_price',
            title: '공제금액',
            width: 150,
            tooltip: '공제금액으로 매칭된 값 또는 ‘판매금액’ - ‘정산금액’',
            notCompile: true,
            template: function(row) {
              return $filter('currency')(row.deduct_price ? row.deduct_price : row.sales - row.sett_price, '', 0);
            }
          },
          {
            key: 'supply_price',
            title: '공급가',
            width: 100,
            tooltip: '쇼핑몰에서 수집하였거나, 솔루션에서 직접, 또는 자동 입력된 정산금액',
            template: (row) => {
              return $filter('currency')(row.supply_price, '', 0);
            }
          },
          // 정산금액
          {
            key: 'sett_price',
            title: '정산금액',
            width: 150,
            tooltip: '정산이 완료된 금액',
            notCompile: true,
            template: function(row) {
              return $filter('currency')(row.sett_price, '', 0);
            }
          },
          // 매출확정일
          {
            key: 'pay_confirm_date',
            title: '매출확정일',
            width: 120,
            filter: 'dateValid'
          },
          // 환불일
          {
            key: 'refund_date',
            title: '환불일',
            width: 120,
            filter: 'dateValid'
          },
          // 원주문번호
          {
            key: 'ori_shop_ord_no',
            title: '원주문번호',
            width: 120,
          },
          // 옵션명
          {
            key: 'shop_opt_name',
            title: '옵션명',
            width: 120,
          },
          // 수량
          {
            key: 'sale_cnt',
            title: '수량',
            width: 70,
            template: (row) => {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : row.sale_cnt;
            }
          },
          {
            key: 'cost_price_cal',
            title: '원가',
            width: 120,
            tooltip: '해당 주문건의 원가 정보가 노출됩니다.',
            template: (row) => {
              return $filter('currency')(row.cost_price_cal, '', 0);
            }
          },
          {
            key: 'predict_margin',
            title: '예상 마진액',
            width: 120,
            template: (row) => {
              return row.predict_margin ? $filter('currency')(row.predict_margin, '', 0) : '';
            }
          },
          {
            key: 'predict_margin_per',
            title: '예상 마진율',
            width: 120,
            template: (row) => {
              return row.predict_margin_per ? `${row.predict_margin_per}%` : '';
            }
          },
          {
            key: 'margin',
            title: '마진액',
            width: 120,
            template: (row) => {
              return row.margin ? `<span style="font-weight:700; color:${row.margin > 0 ? 'green' : 'red'};">${$filter('currency')(row.margin, '', 0)}</span>` : '';
            }
          },
          {
            key: 'margin_per',
            title: '마진율',
            width: 120,
            template: (row) => {
              return row.margin_per ? `<span style="font-weight:700; color:${row.margin_per > 0 ? 'green' : 'red'};">${row.margin_per}%</span>` : '';
            }
          },
          {
            key: 'margin_err',
            title: '마진액 오차',
            width: 120,
            template: (row) => {
              return $filter('currency')(row.margin_err, '', 0);
            }
          },
          {
            key: 'margin_per_err',
            title: '마진율 오차',
            width: 120,
            template: (row) => {
              return row.margin_per_err || row.margin_per_err === 0 ? `${row.margin_per_err}%` : '';
            }
          },
        ]
      }
    };

    /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    /**
    ********** 함수 목록 **********
    * */
    // 검색
    $scope.searchDo = function(refresh = true, noDelay) {
      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);
    };

    // 검색 초기화
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(stateSVC.searchForm);
      $scope.searchDetail = angular.copy(searchDetail);

      if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
        $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      }

      $scope.searchDo(true, true);
    };

    // 정산 캘린더 모달 열기
    $scope.calculateCalenderModal = function () {
      const templatePath = 'views/calculate/modals/calendar.html',
            resolve = {
            };

      commonSVC.openModal('x3g', resolve, 'CalculateCalendarCtrl', templatePath);
    };

    // 데이터테이블 pageLength 조절
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 정산 수집 선택 쇼핑몰 클릭 시
     */
    $scope.calculateCollect = function () {
      const templatePath = 'views/calculate/modals/collect.html';

      commonSVC.openModal('lm', {}, 'CalculateCollectCtrl', templatePath);
    };

    /**
     * 정산 수동 업로드 클릭 시
     */
    $scope.calculateExcelUpload = function () {
      const templatePath = 'views/calculate/modals/excel_upload.html';

      const modal = commonSVC.openModal('', { data: { shop_id_view_type: systemList.data.shop_id_view_type } }, 'CalculateExcelUploadCtrl', templatePath);
      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(true);
        }
      });
    };

    // 2018-04-06 Daniel
    // 재고관리제외버전 추가
    stockExceptionSVC.scopeExcept(`${_.last($state.current.url.split('/'))}2`, $scope);

  });
