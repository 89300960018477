'use strict';

angular.module('gmpApp')
  .service(
    'systemSVC', function (gettextCatalog) {
      const commonSetting = {
        search_date: '1',             //리스트조회기간
        end_wait_change: 'n',
        inq_collection_searchdate: '30',
        sett_collection_searchdate: '30',
        delivery_delay_YN: 'Y',
        prod_auto_set_yn: 'Y',
        delivery_delay_reason: '주문하신 상품이 입고 지연으로 인해 발송이 지연되고 있습니다. 양해 부탁 드립니다. 감사합니다.',
        departure_delay_standard: '1',
        delivery_delay_criteria: 'pay_time',
        safe_stock_default_YN: 'Y',
        safe_stock_default: '10',
        soldout_stock_standard: '1',
        img_resize: 'N',
        out_stock_notice: 'Y',
        invoice_auto: 'N',
        customer_inquiry_auto: '1',
        answer_auto: 'N',
        inq_ans_send_yn: 'N',
        inq_ans_over: 'split',
        soldout_auto: 'N',
        prod_edit_auto_yn: 'N',
        language: 'Korean',               //언어
        system_lock_time: '3',
        work_complate_alert: 'Y',
        order_count: '365',
        privacy_encrypt: '3',
        work_delete_auto: '7',
        excel_download_passwordYN: 'N',
        ord_auto_collect_ship_yn: 'N',      // 자동 주문확인 여부
        ord_auto_collect_shop: 'N',         // 전체쇼핑몰 자동수집 여부
        auto_map_yn: 'N',
        curr_cd: 'KRW',
        shop_sale_name_cut_yn: 'N',
        shop_id_view_type: 'id',
        prod_scrap_date: '2',                // 상품수집 기간
        ord_cost_price_yn: 'N', // 주문 리스트 원가 입력
        delivery_auto_yn: 'N',
        delivery_auto_delay_reason: '',
        delivery_auto_date: 0,
        use_ord_auto_work: false,
        ord_prod_map_ol_yn: 'N', // 쇼핑몰상품 SKU상품 자동매칭
        delivery_set_yn: 'N',    // 배송방법 일괄설정
        delivery_set: '',
        delivery_price: 0,
        pre_ord_bundle_yn: 1, // 자동 합포장 처리 여부
        prod_bundle_avail_use_yn: 'N', // SKU상품의 '합포장 여부' 사용 유무
        order_collection_searchdate: '30',
        set_type: 'to_name,to_zipcd,to_addr1,to_htel,shop_cd,shop_id,ord_time,ship_method',      //묶음배송기준
        opt_order_date: '1', // 주문 자동 수집
        opt_order_sync: '1', // 주문 자동 동기화
      };

      return {

        /**
       * 기본 data
       */
        defaultSetting: commonSetting,

        system_lock_time_list: [
          { name: gettextCatalog.getString('1시간'), value: '1' },
          { name: gettextCatalog.getString('2시간'), value: '2' },
          { name: gettextCatalog.getString('3시간'), value: '3' },
          { name: gettextCatalog.getString('4시간'), value: '4' }
        ],

        opt_order_date_list: [
          { name: gettextCatalog.getString('1시간'), value: '1' },
          { name: gettextCatalog.getString('3시간'), value: '3' },
          { name: gettextCatalog.getString('6시간'), value: '6' },
          { name: gettextCatalog.getString('솔루션 접속 시'), value: '0' }
        ],

        //상품종료대기 전환일자
        end_wait_change_list: [
          { name: gettextCatalog.getString('1일'), value: '1' },
          { name: gettextCatalog.getString('5일'), value: '5' },
          { name: gettextCatalog.getString('10일'), value: '10' },
          { name: gettextCatalog.getString('15일'), value: '15' },
          { name: gettextCatalog.getString('30일'), value: '30' },
        ],

        //주문수집 검색 기간
        order_collection_searchdate_list: [
          { name: gettextCatalog.getString('1일'), value: '1' },
          { name: gettextCatalog.getString('3일'), value: '3' },
          { name: gettextCatalog.getString('5일'), value: '5' },
          { name: gettextCatalog.getString('7일'), value: '7' },
          { name: gettextCatalog.getString('15일'), value: '15' },
          { name: gettextCatalog.getString('30일'), value: '30' },
          { name: gettextCatalog.getString('60일'), value: '60' },
          { name: gettextCatalog.getString('90일'), value: '90' },
          { name: gettextCatalog.getString('120일'), value: '120' },
          { name: gettextCatalog.getString('150일'), value: '150' },
          { name: gettextCatalog.getString('180일'), value: '180' },
          { name: gettextCatalog.getString('210일'), value: '210' },
          { name: gettextCatalog.getString('240일'), value: '240' },
          { name: gettextCatalog.getString('270일'), value: '270' },
          { name: gettextCatalog.getString('300일'), value: '300' },
          { name: gettextCatalog.getString('365일'), value: '365' }
        ],

        //출고지연 주문 기준
        delivery_delay_criteria_list: [
          { name: gettextCatalog.getString('결제완료일'), value: 'pay_time' },
          { name: gettextCatalog.getString('주문일'), value: 'ord_time' },
          { name: gettextCatalog.getString('주문수집일'), value: 'wdate' }
        ],

        // 문의 수집 검색기간
        inq_collection_searchdate_list: [
          { name: gettextCatalog.getString('15일'), value: '15' },
          { name: gettextCatalog.getString('30일'), value: '30' },
          { name: gettextCatalog.getString('60일'), value: '60' },
          { name: gettextCatalog.getString('90일'), value: '90' },
          { name: gettextCatalog.getString('120일'), value: '120' },
          { name: gettextCatalog.getString('150일'), value: '150' },
          { name: gettextCatalog.getString('180일'), value: '180' },
          { name: gettextCatalog.getString('210일'), value: '210' },
          { name: gettextCatalog.getString('240일'), value: '240' },
          { name: gettextCatalog.getString('270일'), value: '270' },
          { name: gettextCatalog.getString('300일'), value: '300' },
          { name: gettextCatalog.getString('365일'), value: '365' }
        ],

        // 정산 수집 검색기간
        sett_collection_searchdate_list: [
          { name: gettextCatalog.getString('15일'), value: '15' },
          { name: gettextCatalog.getString('30일'), value: '30' },
          { name: gettextCatalog.getString('60일'), value: '60' },
          { name: gettextCatalog.getString('90일'), value: '90' },
          { name: gettextCatalog.getString('120일'), value: '120' },
          { name: gettextCatalog.getString('150일'), value: '150' },
          { name: gettextCatalog.getString('180일'), value: '180' },
          { name: gettextCatalog.getString('210일'), value: '210' },
          { name: gettextCatalog.getString('240일'), value: '240' },
          { name: gettextCatalog.getString('270일'), value: '270' },
          { name: gettextCatalog.getString('300일'), value: '300' },
          { name: gettextCatalog.getString('365일'), value: '365' },
          { name: gettextCatalog.getString('730일'), value: '730' }
        ],

        //자동 주문 동기화
        opt_order_sync_list: [
          { name: gettextCatalog.getString('1시간'), value: '1' },
          { name: gettextCatalog.getString('3시간'), value: '3' },
          { name: gettextCatalog.getString('6시간'), value: '6' },
          { name: gettextCatalog.getString('솔루션 접속시'), value: '0' }
        ],

        //자동 문의 수집
        customer_inquiry_auto_list: [
          { name: gettextCatalog.getString('1시간'), value: '1' },
          { name: gettextCatalog.getString('3시간'), value: '3' },
          { name: gettextCatalog.getString('6시간'), value: '6' },
          { name: gettextCatalog.getString('솔루션 접속시'), value: '0' }
        ],

        //개인정보 암호화 설정
        privacy_encrypt_list: [
          { name: gettextCatalog.getString('1개월'), value: '1' },
          { name: gettextCatalog.getString('2개월'), value: '2' },
          { name: gettextCatalog.getString('3개월'), value: '3' },
        ],

        //누적 작업 자동 삭제
        work_delete_auto_list: [
          { name: gettextCatalog.getString('3일'), value: '3' },
          { name: gettextCatalog.getString('5일'), value: '5' },
          { name: gettextCatalog.getString('7일'), value: '7' },
        ],

        work_count: [
          '500', '400', '300'
        ],

        order_count_list: [
          { name: gettextCatalog.getString('365일'), value: '365' },
          { name: gettextCatalog.getString('180일'), value: '180' },
          { name: gettextCatalog.getString('90일'), value: '90' },
          { name: gettextCatalog.getString('30일'), value: '30' }
        ],

        search_date: [
          { name: gettextCatalog.getString('12개월'), value: '12' },
          { name: gettextCatalog.getString('6개월'), value: '6' },
          { name: gettextCatalog.getString('3개월'), value: '3' },
          { name: gettextCatalog.getString('1개월'), value: '1' }
        ],

        language: [
          'Korean', 'English', 'Japanese', 'Chinese'
        ]

      };
    });
