/**
 * Created by harry on 2017. 6. 27..
 */

'use strict';

angular.module('gmpApp')
  .controller('CalculateStateDetailCtrl', function ($scope, data, $timeout, $compile, $filter, $uibModalInstance, settings, siteInfo) {
    $scope.searchData = {};
    const searchForm = angular.copy(data.searchForm);

    searchForm.detail_value = data.detail_value;
    $scope.modalTitle = data.searchForm.group_by === 'sett_com_date' ? '정산완료일별' : '쇼핑몰별';

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      pinningColumns: [],
      alignCenterColumns: [],
      alignRightColumns: [],
      notSortingColumns: [],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/calculate/state/detail`,
        requestWillAction: function(data) {
          if (data.orderby) {
            data.orderby = typeof data.orderby === 'string' ? data.orderby : data.orderby.map((o) => { return `${o.key} ${o.sort}`; });
          } else {
            data.orderby = `${searchForm.group_by === 'shop_cd' ? 'shop_id' : 'sett_com_date'} desc`;
          }

          return Object.assign(data, searchForm);
        },
        requestDidAction: function(result) {
          return result.results;
        }
      },
      columns: []
    };

    if (data.searchForm.group_by === 'shop_cd') {
      $scope.grid.options.columns = data.columns.map((val) => {
        return {
          key: val,
          title: val,
          width: 120,
          template: function() {
            return `<div class="ui-grid-cell-contents">{{ row.entity["${val}"] }}</div>`;
          }
        };

      });

      $scope.grid.options.defaultSortingColumns = ['shop_id'];
      $scope.grid.options.alignCenterColumns = $scope.grid.options.columns.map((data) => data.key);
      $scope.grid.options.notSortingColumns = $scope.grid.options.columns.map((data) => data.key);
    } else {
      $scope.grid.options.columns = [
        {
          key: 'no',
          title: 'No.',
          width: 40
        },
        {
          key: 'sett_com_date',
          title: '정산일',
          width: 100,
          filter: 'dateValid'
        },
        {
          key: 'shop_cd',
          title: '쇼핑몰',
          width: 60,
          template: function(data) {
            return siteInfo[data.shop_cd].name ? siteInfo[data.shop_cd].name : '';
          }
        },
        {
          key: 'shop_id',
          title: 'ID',
          width: 100
        },
        {
          key: 'shop_ord_no',
          title: '주문번호',
          width: 100
        },
        {
          key: 'sett_type',
          title: '구분',
          width: 60
        },
        {
          key: 'shop_sale_name',
          title: '쇼핑몰 상품명',
          width: 100,
          filter: 'whiteSpace'
        },
        {
          key: 'order_name',
          title: '구매자명',
          width: 60
        },
        {
          key: 'sales',
          title: '판매금액',
          width: 100,
          template: function(data) {
            return $filter('currency')(data.sales, '', 0);
          }
        },
        {
          key: 'deduct_price',
          title: '공제금액',
          width: 100,
          template: function (data) {
            return $filter('currency')(data.deduct_price, '', 0);
          }
        },
        {
          key: 'sett_price',
          title: '정산금액',
          width: 100,
          template: function (data) {
            return $filter('currency')(data.sett_price, '', 0);
          }
        }
      ];

      $scope.grid.options.defaultSortingColumns = ['sett_com_date'];
      $scope.grid.options.alignCenterColumns = $scope.grid.options.columns.map((data) => data.key);
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

  });