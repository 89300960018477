'use strict';

angular.module('gmpApp')
  .controller('CateApplyShopIdCtrl', function ($scope, $rootScope, $uibModalInstance, $state, commonSVC, categoryModel) {
    $scope.channelList = angular.copy($rootScope.use_channel_list);

    $scope.reCheckClass = {};
    $scope.searchForm = {
      pa_shop_cd: null,
      get_shop: null,
      apply_shop: null,
    };

    $scope.useChannelList = angular.copy(_.unionBy($scope.channelList, 'shop_cd'));
    $scope.targetShopCheck = false; // 대상 쇼핑몰 계정 선택 유효성 체크
    $scope.checkShopCheck = false; // 카테고리 설정 쇼핑몰 계정 선택 유효성 체크
    $scope.getShopCheck = false; // 카테고리 설정 기준 쇼핑몰 계정 선택 유효성 체크
    let isFirstRender = true;

    $scope.selectCheck = () => {
      // 우선은 갤러리아몰 처리. 카테고리 일괄설정 불가능
      if (['B394'].includes($scope.searchForm.pa_shop_cd)) {
        commonSVC.showMessage('선택실패', '갤러리아는 출고지에 따라 지정할 수 있는 카테고리가 다르므로\n일괄 설정 기능은 지원되지 않습니다.');
        $scope.searchForm.pa_shop_cd = '';
        $scope.$apply();
      }

      // 카테고리 설정 쇼핑몰 계정 선택 유효성 체크
      $scope.checkShopCheck = !$scope.searchForm.pa_shop_cd;
    };

    // 카테고리 설정 기준 쇼핑몰 계정 선택 시 이벤트 함수
    $scope.selectGetShop = () => {
      // 변경 시 기준 쇼핑몰 초기화
      $scope.searchForm.apply_shop = null;

      // 유효성 체크
      $scope.getShopCheck = !$scope.searchForm.get_shop;
    };

    // 리스트 필터
    $scope.applyShopCdFilter = function (option) {
      if ($scope.searchForm.get_shop) {
        const getShopInfo = JSON.parse($scope.searchForm.get_shop);

        return $scope.searchForm.pa_shop_cd === option.pa_shop_cd && !(getShopInfo.shop_cd === option.shop_cd && getShopInfo.shop_id === option.shop_id);
      }
    };

    $scope.$watchCollection('searchForm.apply_shop', () => {
      if (isFirstRender) {
        isFirstRender = false;

        return;
      }
      $scope.targetShopCheck = !$scope.searchForm.apply_shop?.length;
    });

    $scope.onSubmit = async function () {
      if ($scope.searchForm.get_shop && $scope.searchForm.pa_shop_cd && $scope.searchForm.apply_shop?.length) {
        const { shop_cd: get_shop_cd, shop_id: get_shop_id } = JSON.parse($scope.searchForm.get_shop);

        const params = {
          get_shop_cd,
          get_shop_id,
          apply_shop_list: $scope.searchForm.apply_shop,
        };

        const confirm = await commonSVC.showConfirm('카테고리 일괄 수정', '계정 내 설정된 모든 카테고리를 일괄 변경하는 기능으로 변경을 원치 않는 카테고리는 별도로 설정해 주시기 바랍니다. \n\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.');

        if (confirm) {
          await categoryModel.applyShopCate(params);

          commonSVC.showToaster('success', '성공', '카테고리 수정에 성공하였습니다.');

          $state.go('main.settings_category.categoryDetailPanel');

          $uibModalInstance.dismiss();
        }

      } else {
        $scope.getShopCheck = !$scope.searchForm.get_shop;
        $scope.checkShopCheck = !$scope.searchForm.pa_shop_cd;
        $scope.targetShopCheck = !$scope.searchForm.apply_shop?.length;

        return false;
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });