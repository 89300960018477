'use strict';
angular.module('gmpApp')
  .controller('SettingsDeliveryLinkRegCtrl', function ($scope, $window, $compile, userInfo, carrierInfo, commonSVC, deliverySVC, commonModel, deliveryModel, systemModel, $uibModalInstance, gettextCatalog, data) {

    let sellerCateList = [];

    $scope.sellerCateParents = [];
    $scope.sellerCateChildren = [];

    $scope.carrList = deliverySVC.carrList;
    $scope.carrIdList = [];
    $scope.carrSelect = '';
    $scope.carrIdSelect = '';
    $scope.pageType = 'add';

    loadSellerCate();

    $scope.selectList = function() {
      if ($scope.carrSelect) {
        $scope.carrIdList = data.deliveryList.filter(carr => carr.carr_no === Number($scope.carrSelect) && carr.reg_confirm_yn && carr.template_cnt > 0);
        // data.result 코드
        // 1 : GMP(O), confirm_yn(1) - 이미 연동중인 택배사입니다.
        // 2 : GMP(O), confirm_yn(0) - 연동신청상태입니다. 택배사 측의 연동승인 후 이용가능합니다.
        // 2 : GMP(X), EMP(승인접수)    - 연동신청상태입니다. 택배사 측의 연동승인 후 이용가능합니다.
        // 3 : GMP(X), EMP(승인완료) - 기존 EMP 연동정보 추가
        // 4 : GMP(X), EMP(X)     - 신규 연동신청
        // deliveryModel.checkCarrierLinked(params, function (state, data) {
        //   if(state === 'success') {
        //     if(data.result === '1') {
        //       commonSVC.showMessage("이미 연동중인 택배사입니다.");
        //       $scope.carrSelect = '';
        //     } else if(data.result === '2') {
        //       commonSVC.showMessage("연동신청상태입니다. 택배사 측의 연동승인 후 이용가능합니다.");
        //       $scope.carrSelect = '';
        //     } else if(data.result === '3') {
        //       commonSVC.showMessage("연동신청이 완료된 택배사입니다. 택배사를 추가해주세요.");
        //       $scope.carrSelect = '';
        //     } else {
        //       var url = angular.element("<div data-ng-include='\"views/settings/delivery/detail/delivery_link_"+ $scope.carrSelect +".html\"'></div>");
        //       angular.element("#addItem").html(url);
        //       $compile(url)($scope);

        //       deliveryModel.checkCarrierLinked(params, function (state, data) {
        //         initData();
        //       });
        //     }
        //   } else {
        //     commonSVC.showToaster("error", "실패", "택배사 연동정보 조회 실패");
        //     $scope.carrSelect = '';
        //   }
        // });

        const url = angular.element(`<div data-ng-include='"views/settings/delivery/detail/delivery_link_${$scope.carrSelect}.html"'></div>`);

        angular.element('#addItem').html(url);
        $compile(url)($scope);

        $scope.initData($scope.carrSelect === '956' || $scope.carrSelect === '107' || $scope.carrSelect === '1007' ? '기존' : 'EMP');
      } else {
        $scope.carrIdList = [];
      }
    };

    // 쇼핑몰 추가정보 파일 임시 업로드
    $scope.uploadFile = function (files, name1, name2) {
      // S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function (res) {
          $scope.carr[name1] = res.data.results[0].url;
          $scope.carr[name2] = res.data.results[0].origFileName;
        });
    };

    $scope.fileClear = function (name1, name2) {
      delete $scope.carr[name1];
      delete $scope.carr[name2];
    };

    /**
     * 데이터 초기화
     */
    $scope.initData = function(reg_type) {
      const data = {
        carr_no: $scope.carrSelect,
        carr_id: '',
        carr_id_sub: '',
        carr_name: carrierInfo[$scope.carrSelect].carr_name || '',
        reg_type: reg_type,
        contract_type: 'company',
        cname: userInfo.user.company_name,
        biz_reg_no: userInfo.user.biz_reg_no,
        zipcd: '',
        addr1: '',
        addr2: '',
        email: userInfo.user.email,
        emp_yn: 0,
        contract_yn: 0,
        reg_confirm_yn: 0,
        email_yn: userInfo.user.email_yn,
        sms_yn: userInfo.user.sms_yn,
        idChecked: false,
        copy_carr_id: $scope.carrIdSelect
      };

      if (reg_type === '기존') {
        data.emp_yn = 0;
        data.contract_yn = 1;
        data.reg_confirm_yn = 0;
        if ($scope.carrSelect === '956') {
          data.misc2 = 'N'; // 카카오T당일배송 로고 이미지 사용값
          $scope.deliveryLinkRegForm.$error = {};
        }
      } else if (reg_type === '신규') {
        data.emp_yn = 0;
        data.contract_yn = 0;
        data.reg_confirm_yn = 0;
        data.idChecked = true;
      } else if (reg_type === 'EMP') {
        data.emp_yn = 1;
        data.contract_yn = 1;
        // 해당값이 1로 되어있어야 바로 사용이 가능하므로 수정 2018-05-18 rony
        // data.reg_confirm_yn = 0;
        data.reg_confirm_yn = 1;
      }
      $scope.carr = data;
    };

    /**
     * 필드값 초기화
     */
    $scope.resetPostFields = function() {
      const fields = [
        'carr_id', 'carr_pwd_ori', 'cust_no', 'appr_no',
        'misc1', 'deliv_month_cnt', 'charge_name', 'charge_tel', 'memo'
      ];

      if ($scope.carr.contract_type === 'company') {
        fields.forEach(field => $scope.carr[field] = '');
      } else if ($scope.carr.contract_type === 'general' && $scope.carr.carr_id && $scope.carr.cust_no && $scope.carr.appr_no) {
        fields.filter(data => !['carr_id', 'cust_no', 'appr_no'].includes(data)).forEach(field => $scope.carr[field] = '');
      }
    };

    /**
     * 취급카테고리 로드
     */
    function loadSellerCate() {
      deliveryModel.sellerCategoryListAll('', function (state, data) {
        if (state === 'success') {
          sellerCateList = data.data;
          $scope.sellerCateParents = _.filter(sellerCateList, { cate_depth: 1 });
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('취급 카테고리 로드 실패.'));
        }
      });
    }

    /**
     * 취급카테고리1 선택
     */
    $scope.selectParent = function (cate) {
      if (!cate) {
        $scope.sellerCateChildren = [];
      }

      $scope.carr.cate_parent = cate.carr_regcate_no;
      $scope.carr.cate_parent_name = cate.cate_name;
      $scope.sellerCateChildren = angular.copy(_.filter(sellerCateList, { pa_carr_regcate_no: $scope.carr.cate_parent }));
    };

    /**
     * 취급카테고리2 선택
     */
    $scope.selectChild = function (cate) {
      $scope.carr.carr_regcate_no = cate.carr_regcate_no;
      $scope.carr.cate_child_name = cate.cate_name;
    };

    /**
     * 신청
     */
    $scope.onSubmit = async () => {

      if (!$scope.carr.idChecked && $scope.carr.carr_no !== '107' && $scope.carr.carr_no !== '1007' && $scope.carr.contract_type !== 'general') {
        commonSVC.showMessage('[계정 확인]작업을 진행해주십시오..');
      } else if ($scope.deliveryLinkRegForm.$valid) {
        if ($scope.carr.carr_no === '956') {
          if ($scope.carr.misc2 === 'Y' && !$scope.carr.misc3) {
            commonSVC.showMessage('업체 로고 이미지를 등록해주세요.');

            return false;
          } else if ($scope.carr.misc2 === 'N') {
            $scope.carr.misc3 = '';
            $scope.carr.misc4 = '';
          }
        }

        const checkCarrParams = {
          biz_reg_no: userInfo.user.biz_reg_no,
          carr_no: $scope.carr.carr_no,
          carr_id: $scope.carr.carr_id
        };

        try {
          const { data } = await deliveryModel.checkCarrierLinked(checkCarrParams);

          // EMP 일댸 DB조회 없으면  //기존이나 신규일떄 DB가 있을 경우
          // 대한통운 테스트 아이디는 제외.
          if ($scope.carr.reg_type == 'EMP' && data.data == 'N' && $scope.carr.carr_id !== '30121013') {
            commonSVC.showMessage('입력하신 택배사계정이 EMP연동리스트에서 조회되지 않습니다.\n 택배사계정을 다시 확인해주시기 바랍니다.');

            return false;
          } else if (($scope.carr.reg_type == '기존' || $scope.carr.reg_type == '신규') && data.data == 'Y') {
            commonSVC.showMessage("입력된 택배사ID가 이미 연동되어 있습니다.\n 신청구분을 '기존 EMP솔루션 연동사용자'로 선택해주시기 바랍니다.");

            return false;
          } else {
            const params = $scope.carr;

            // 1:1 문의용 데이터
            params.wdate = moment().format('YYYY-MM-DD HH:mm:ss');
            params.m_email = userInfo.user.email;
            params.m_no = userInfo.user.m_no;
            params.m_name = userInfo.user.user_name;
            params.m_tel = userInfo.user.tel;
            // 로젠, 롯데
            if ($scope.carr.carr_no === '7' || $scope.carr.carr_no === '8' || $scope.carr.carr_no === '956' || $scope.carr.carr_no === '107' || $scope.carr.carr_no === '1007') {
              params.reg_confirm_yn = 1;
            }

            const addResult = await deliveryModel.addDelivery(params);

            if (addResult.data.results === 'success') {
              if ($scope.carr.reg_type == 'EMP') {
                commonSVC.showToaster('success', '성공', '택배사 연동이 완료되었습니다.');
              } else {
                commonSVC.showToaster('success', '성공', '연동신청이 완료되었습니다.\n택배사 측의 연동승인 후 이용가능합니다.');
              }

              $uibModalInstance.close('success');
            } else {
              commonSVC.showToaster('error', '실패', '택배사 연동신청 실패');
            }

            return false;
          }
        } catch (err) {
          if (err.data.message === 'duplicate') {
            commonSVC.showToaster('error', '실패', '이미 등록된 택배사 정보 입니다.');
          } else {
            commonSVC.showToaster('error', '실패', '택배사 연동신청 실패');
          }

          return false;
        }
      } else {
        $('.form-horizontal .form-control').valid();
        commonSVC.showMessage('신청 실패', '필수 항목을 모두 입력해 주시기 바랍니다.');
      }
    };

    /**
     * 아이디 확인
     */
    $scope.checkCarrId = function () {
      if (!$scope.carr.carr_id) {
        commonSVC.showMessage('ID를 입력해주세요.');

        return false;
      }

      if ($scope.carr.carr_no == 7 && !$scope.carr.carr_pwd_ori) {
        commonSVC.showMessage('비밀번호를 입력해주세요.');

        return false;
      }

      const params = {
        carr_no: $scope.carr.carr_no,
        carr_id: $scope.carr.carr_id,
        carr_pwd: $scope.carr.carr_pwd_ori || ''
      };

      deliveryModel.checkCarrierIdExist(params, function (state, data) {
        if (state === 'success') {
          if (data?.data.toUpperCase() === 'Y') {
            commonSVC.showMessage('계정이 확인되었습니다.');
            $scope.carr.idChecked = true;
          } else if (!data?.data.includes('FALSE') && data?.data.includes('Y')) {  //로젠택배
            commonSVC.showMessage('계정이 확인되었습니다.');
            $scope.carr.idChecked = true;
          } else {
            let msg = '입력하신 정보로 계정이 조회되지 않습니다.';

            if (data?.data.includes('비밀번호 오류')) {
              msg = '비밀번호를 다시 확인해주세요.';
            }

            commonSVC.showMessage(msg);
            $scope.carr.idChecked = false;
          }
        } else {
          commonSVC.showToaster('error', '실패', '계정 확인 실패');
          $scope.carr.idChecked = false;
        }
      });
    };

    /**
     * 우체국용 아이디확인
     * */
    $scope.checkCarrIdEpost = function () {
      if (!$scope.carr.carr_id) {
        commonSVC.showMessage('ID를 입력해주세요.');
      } else if (!$scope.carr.carr_pwd_ori) {
        commonSVC.showMessage('PW를 입력해주세요.');
      } else {
        $('#loadingSpinner').removeClass('hidden');
        const params = {
          carr_no: $scope.carr.carr_no,
          carr_id: $scope.carr.carr_id,
          carr_pwd: $scope.carr.carr_pwd_ori || ''
        };

        deliveryModel.checkCarrierIdExist(params, function (state, data) {
          if (data.data?.code === 200 && data.data?.data?.cust_no) {
            commonSVC.showMessage('계정이 확인되었습니다.');
            $scope.carr.idChecked = true;
            $scope.carr.cust_no = data.data?.data?.cust_no;
          } else {
            commonSVC.showMessage('실패', '계정 확인 실패');
            $scope.carr.idChecked = false;
          }
          $('#loadingSpinner').addClass('hidden');
        });
      }
    };

    /**
     * 카카오T당일배송 계정 확인
     * */
    $scope.checkCarrIdTodayPickUp = function () {
      if (!$scope.carr.carr_id) {
        commonSVC.showMessage('ID를 입력해주세요.');
      } else if (!$scope.carr.carr_pwd_ori) {
        commonSVC.showMessage('PW를 입력해주세요.');
      } else {
        $('#loadingSpinner').removeClass('hidden');
        const params = {
          carr_no: $scope.carr.carr_no,
          carr_id: $scope.carr.carr_id,
          carr_pwd: $scope.carr.carr_pwd_ori || ''
        };

        deliveryModel.checkCarrierIdExist(params, function (state, data) {
          if (state === 'success') {
            const result = JSON.parse(data.data?.error || data.data?.body);

            if (result.success) {
              commonSVC.showMessage('계정이 확인되었습니다.');
              $scope.carr.idChecked = true;
              $scope.carr.auth = data.data?.headers?.authorization;
            } else {
              let msg = '아이디, 패스워드를 다시 확인해주세요.';

              if (result.code.toUpperCase() === 'IS_APPROVAL_STATE_NO') {
                msg = '미승인된 계정입니다. 카카오T당일배송에서 승인 여부를 확인해주세요.';
              }

              commonSVC.showMessage(msg);
              $scope.carr.idChecked = false;
            }
          } else {
            commonSVC.showToaster('error', '실패', '계정 확인 실패');
            $scope.carr.idChecked = false;
          }
        });
      }
    };

    /**
     * 취소
     */
    $scope.cancel = function () {
      $uibModalInstance.close('cancel');
    };

    /**
     * 모든 모달 닫기 명령이 들어온경우 처리.
     */
    $scope.$on('openedModalClose', function () {
      $uibModalInstance.close('cancel');
    });
  });
