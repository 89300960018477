'use strict';

/**
 * 주문복사 컨트롤러
 */
angular.module('gmpApp')
  .controller('OrderShipmentOrderCopy', function ($scope, $uibModalInstance, data, settings, $rootScope, commonSVC, shipmentModel, gettextCatalog, $timeout) {
    // 주문복사 옵션 마지막 선택값 가져오기
    $scope.saveCopy = JSON.parse(localStorage.getItem('orderCopySettings'));
    $scope.copy = { type: data.selectedShopType === 'global' };
    $scope.makeBundle = { type: false };

    // 저장된 값 없으면 모두 기본값 선택 처리
    if (!$scope.saveCopy || !$scope.saveCopy.use_yn) {
      $scope.saveCopy = {
        makeBundle_type: false,
        copy_type: false,
        use_yn: false
      };
    } else {
      $scope.copy = { type: JSON.parse($scope.saveCopy.copy_type) };
      $scope.makeBundle = { type: JSON.parse($scope.saveCopy.makeBundle_type) };
    }

    $scope.ebaydepot_yn = data.ebaydepot_yn;
    $scope.selectList = data.selectList;
    $scope.selectedShopType = data.selectedShopType || 'domestic';
    $scope.isResend = data.isResend;

    /** 복사 */
    $scope.ok = async () => {
      data.keepSiteCode = $scope.copy.type;
      data.makeBundle = $scope.makeBundle.type;

      try {
        $scope.saveCopy.makeBundle_type = $scope.makeBundle.type;
        $scope.saveCopy.copy_type = $scope.selectedShopType === 'global' ? true : $scope.copy.type;

        localStorage.setItem('orderCopySettings', JSON.stringify($scope.saveCopy));

        const res = await shipmentModel.copy(data);

        if (res.data && res.data.result == 'success') {
          commonSVC.showToaster('success', '성공', '주문 복사에 성공하였습니다.\n신규주문 메뉴에서 확인하시기 바랍니다.');
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '실패', '주문 복사에 실패하였습니다.');
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', err.data?.messages[0] || '주문 복사에 실패하였습니다.');

        // 스피너 계속 돌게 처리되어 있어서 스피너 해제처리 추가
        $scope.waitBtnDisableFlag = false;
        $timeout(() => {});
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });