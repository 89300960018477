import React, { useState, CSSProperties } from 'react';
import ModalImage from 'react-image-modal';

interface ImagePreviewComponentProps {
  imageSrc: string;
  handleClose: () => void;
}

const ImagePreviewComponent: React.FC<ImagePreviewComponentProps> = ({ imageSrc, handleClose }) => {
  const [zoom, setZoom] = useState(false);

  const zoomIn = () => {
    setZoom(true);
  };

  const zoomOut = () => {
    setZoom(false);
  };

  const [clientXY, setClientXY] = useState({ x: null, y: null });
  const [calc, setCalc] = useState({ x: null, y: null });

  const getOffset = (event: React.MouseEvent<HTMLDivElement>) => {
    if (zoom) {
      setClientXY({ x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY });
      // 500 * 500 박스 영역 기준으로 백분율 계산
      setCalc({ x: clientXY.x / 500 * 100, y: clientXY.y / 500 * 100 });
    }
  };

  return (
    <>
      <div className="modal-header">
        <button type="button" className="close" onClick={handleClose}>×</button>
        <h5 className="modal-title">이미지 미리보기</h5>
      </div>
      {/* <div className="modal-body" style={{ padding: 10, margin: 0, backgroundColor: '#696969' }}> */}
      {/* <center> */}
      {/* ModalImage 사용시 앱 전체의 스크롤이 없어져서 일단 주석  */}
      {/* <ModalImage
            showDownload={false}
            showRotation={true}
            showZoom={true}
            src={imageSrc}
            handleClose={handleClose}
          /> */}
      {/* </center> */}
      {/* </div> */}

      <div
        className="modal-body"
        style={{
          padding: '0',
          position: 'relative',
          width: '500px',
          height: '500px',
          background: '#333',
          overflow: 'hidden'
        }}
      >
        <div
          style={ zoom ? { ...styles.bgImg, backgroundImage: `url(${imageSrc})`, backgroundPosition: `${calc.x}% ${calc.y}%`, cursor: 'move' } : styles.bgImg }
          onMouseMove={(e) => getOffset(e)}
        >
          <img
            src={imageSrc}
            style={ zoom ? { ...styles.img, display: 'none' } : styles.img }
          />
        </div>
        <div
          style={{
            position: 'absolute',
            right: '0',
            bottom: '0',
          }}
        >
          <button
            type="button"
            className="btn"
            onClick={zoomIn}
            disabled={zoom}
            title="확대"
          >
            <i className="fa fa-plus" />
          </button>
          <button
            type="button"
            className="btn"
            onClick={zoomOut}
            disabled={!zoom}
            title="축소"
          >
            <i className="fa fa-minus" />
          </button>
        </div>
      </div>
    </>
  );
};

const styles: { [key: string]: CSSProperties } = {
  bgImg: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url()',
    backgroundSize: '200%',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  }
};

export default ImagePreviewComponent;
