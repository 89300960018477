'use strict';

angular.module('gmpApp')
  .service('giftSVC', function (commonSVC) {
    return {
      /**
       * (신)사은품규칙
       */
      gift_new: {
        gift_no: null,
        gift_rule_name: '',
        rule_type: 'all',
        directinput_ord_apply_yn: 0,
        site_list: [],
        sdate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        total_range: 'between',
        s_sales: null,
        e_sales: null,
        sales_type: 'sales',
        sale_cnt_type: 'none',
        s_sale_cnt: null,
        e_sale_cnt: null,
        add_rule_type: '',
        add_rule_apply_type: 'or',
        depot_no: null,
        prod_depot: [],
        shop_sale: [],
        dist_method: 'all',
        giftProds: [],
        cal_amt_type: 'rule' // 계산수량타입 : rule-규칙일치, bundle-묶음단위 -> 기본값 rule
      }
    };
  });
