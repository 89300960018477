'use strict';

/*
* 수정 & 등록 모달
* stateParams가 있으면 수정 없으면 등록
* */
angular.module('gmpApp')
  .controller('ProdProductDetailCtrl', function ($scope, $rootScope, $state, $stateParams, $timeout, $uibModalInstance,
    commonSVC, productSVC, onlineProductSVC, logHistorySVC, columnSVC,
    productModel, supplierModel, warehouseModel, inventoryModel, systemModel, columnModel,
    data, madeinList, supplierList, warehouseList, systemList, userInfo, $anchorScroll, $location, settings, $filter
  ) {
    $scope.use_addcol_stock = $rootScope.userProfileCheck('sol_ser', 'addcol_stock', 'like');
    // SKU재고 현황 페이지에서 보여지는 상세는 커스텀컬럼 수정되면 안됨
    $scope.addColReadOnly = ['inventory'].findIndex((o) => { return o === data?.fromPage; }) > -1;
    $scope.data = {
      opt_type: 'one', // 속성
      allCheck: false,
      real_stock_type: '+', //실재고 수정 기본 타입
      modify_real_stock: 0 //실재고 수정 기본 수량
    };

    // 포장 형태 데이터
    $scope.pkgBoxData = {
      pkg_no: 0,
      max_ea_cnt: 0
    };

    const costDetailData = ['purchase_cost', 'in_ship_cost', 'ship_cost', 'adv_cost', 'other_cost'];
    // 업체 설정 기본값
    const defaultObj = {
      // 배송처
      default_warehouse: systemList.data.default_depot_no ? String(systemList.data.default_depot_no) : '',
      // 안전재고
      safe_stock_default: systemList.data.safe_stock_default_YN == 'Y' && systemList.data.safe_stock_default > 0 ? systemList.data.safe_stock_default : 0, // 안전재고 기본값
    };

    $scope.invalidTab = [false, false, false, false];
    $scope.common = {
      checkValidTab: tabIdx => {
        const validLabel = $(`#prodform_detail-tabs-${tabIdx}`).find('.validation-error-label');
        const isInValid = validLabel ? _.countBy(validLabel, el => $(el).css('display') !== 'none').true > 0 : false;

        return validLabel && isInValid;
      },
    };
    $scope.detailType = 'add';
    $scope.isModal = false;

    // 엘지 커스텀용 모델번호 노출 여부
    $scope.viewModelNo = $rootScope.affName === 'LG전자' && !!($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like'));

    if (data) {
      $scope.detailType = data.detailType;
      $scope.number = data.number; //상품 번호
      $scope.sol_no = data.prodSolNo || data.sol_no; //솔루션 번호
      $scope.nonEdit = data.nonEdit || false;
      // 상세는 전부 모달형식임
      $scope.isModal = data.openModal || data.detailType === 'edit';
    }
    $scope.tabActive = 0;

    $scope.supplierList = supplierList.data.result || [];              // 매입처 리스트
    $scope.warehouseList = warehouseList.data.result ? warehouseList.data.result.sort() : [];            // 배송처 리스트
    $scope.optArray = [{ type: '', value: '', custom_type: '', name: '' }];       // 속성명 (type : 속성타입, custom_type : 속성타입 직접입력시 들어감, value : 속성)
    $scope.expirationMonths = _.range(1, productSVC.expirationMonth);  //유통기한 개월수 리스트(1~50)
    $scope.barcodeTypes = productSVC.barcodeTypes;                     // 바코드 구분(UPC/EAN/ISBN)
    $scope.taxTypes = productSVC.taxTypes;                             // 과세구분 리스트
    $scope.useAges = productSVC.useAges;                               // 사용연령 리스트
    $scope.optTypes = productSVC.optTypes;                             // 속성 리스트
    $scope.madeinList = madeinList.data.result;                        // 원산지  리스트
    $scope.madeLocalList = [];                                         // 국가
    $scope.madeLocalDetailList = [];                                   // 지역

    /**
     * 일괄입력 모델
     */
    $scope.batch = {
      batch_type: '',
      batch_input: ''
    };

    // 속성구분 중복인지 체크
    $scope.dupOpt = false;
    $scope.dupOpt_multi = false; // 일괄등록일때

    // 로그용 데이터
    let oldData = {};

    // directive(form) 용
    $scope.reCheckClass = {};

    // 등록하기 버튼 클릭했는지 여부 2018-04-10 rony
    $scope.clickSubmit = false;

    // 배송처여부
    $scope.isDepot = userInfo.user.auth_type === '배송처';

    // 일괄등록용 대표배송처
    $scope.optMainDepots = [];

    init();

    //탭 (ng-if로 셋팅이 늦게 되는 것이 있어서 함수로 뺌 )
    $scope.clickTab = function (tabIndex) {
      $scope.tabActive = tabIndex;
    };

    // 속성구분 세팅(1개 등록)
    $scope.setOptName = function (type, idx) {
      $scope.optArray[idx].name = type == '직접입력' ? '' : type;
      // 속성 없음으로 선택시 입력된 속성값 삭제. 2018-03-14 rony
      if (type == '') {
        $scope.optArray[idx].value = '';
        $scope.optArray[idx].custom_type = '';
      }

      $scope.isOptDuplicated();
    };

    // 속성구분 중복검사(1개 등록)
    $scope.isOptDuplicated = function () {
      if ($scope.detailType === 'edit') {
        _.forEach($scope.optArray, function (o) {
          if (o.type == '직접입력') {
            o.name = o.custom_type;
          } else {
            o.name = o.type;
          }
        });
      }
      if (_.uniqBy($scope.optArray, 'name').length != $scope.optArray.length) {
        $scope.dupOpt = true;
      } else {
        $scope.dupOpt = false;
      }
    };

    // 속성구분 세팅(일괄 등록)
    $scope.setOptName2 = function (type, idx) {
      $scope.opts[idx].name = type == '직접입력' ? '' : type;
      $scope.isOptDuplicated2();
    };

    // 속성구분 중복검사(일괄 등록)
    $scope.isOptDuplicated2 = function () {
      if (_.uniqBy($scope.opts, 'name').length != $scope.opts.length) {
        $scope.dupOpt_multi = true;
      } else {
        $scope.dupOpt_multi = false;
      }
    };

    //속성 추가, 삭제 (1개 등록)
    $scope.calculateOPT = function (calMode, idx) {
      if (calMode === 'plus') {
        if ($scope.optArray.length < 3) {
          $scope.optArray.push({ type: '', value: '', custom_type: '', name: '' });
        }
      } else {
        $scope.optArray.splice(idx, 1);
        $scope.isOptDuplicated();
      }
    };

    /**
     * 배송처 추가, 삭제 (1개 등록)
     *
     * @param {string} depot_no 현재 선택된 배송처 번호
     * @param {'plus'|'minus'} calMode 추가인지 삭제인지
     * @param {number} idx 삭제할 인덱스
     */
    $scope.calculateDepot = function (depot_no, calMode, idx = 0) {
      if (calMode === 'plus') {
        $scope.prodData.prodDepotArray.push({ depot_no: '', real_stock: 0, safe_stock: defaultObj.safe_stock_default, depot_name: '', inout_memo: '', link_ord_yn: 'N', link_prod_yn: 'N', real_stock_type: '+', modify_real_stock: 0, out_scheduled: 0 });
      } else {
        if ($scope.prodData.main_depot_no == depot_no) {
          commonSVC.showMessage('배송처 삭제 실패 안내', '대표 배송처는 삭제 불가능합니다.');

          return false;
        }

        const currentProdDepotInfo = $scope.prodData.prodDepotArray.find(o => o.depot_no === depot_no);

        if (currentProdDepotInfo.link_ord_yn === 'Y' || currentProdDepotInfo.link_prod_yn === 'Y') {
          commonSVC.showMessageHtml('배송처 삭제 실패 안내', `
            <br>
            <b>해당 배송처에 연동된 주문 또는 상품이 존재합니다.</b><br><br>
            <ul>
              <li>
                <b>미출고 주문에 매칭된 경우</b><br>
                해당 주문이 출고가 완료되거나<br>
                주문에서 매칭을 해제 하시면 배송처를 삭제할 수 있습니다.
              </li>
              <li>
                <b>온라인 상품 또는 세트 상품에 매칭된 경우</b><br>
                상품 정보에서 매칭을 해제 하시면 배송처를 삭제할 수 있습니다.
              </li>
            </ul>
          `);

          return false;
        }

        $scope.prodData.prodDepotArray.splice(idx, 1);
      }
    };

    /**
     * 배송처추가 (멀티속성)
     *
     * @param {number} optIdx 추가하려는 속성의 인덱스
     * @param {'plus'|'minus'} calMode 추가인지 삭제인지
     * @param {number} idx 삭제할 인덱스
     */
    $scope.calculateDepotMulti = function (optIdx, calMode, idx = 0) {
      if (calMode === 'plus') {
        $scope.addOption(optIdx);
      } else {
        if ($scope.optMainDepots[optIdx] == $scope.options_table[idx].delivery_vendor) {
          commonSVC.showMessage('배송처 삭제 실패 안내', '대표 배송처는 삭제 불가능합니다.');

          return false;
        }

        $scope.options_table.splice(idx, 1);

        // 매칭된 배송처 갯수를 업데이트 해줘야 함. (depotLength 값은 테이블의 rowspan 에 사용됨)
        $scope.options_table.filter(o => o.optIdx === optIdx).map(o => o.depotLength = o.depotLength - 1);
      }

      $timeout(() => {
        (_.uniq($scope.options_table.map(o => o.optIdx))).forEach(optIdx => {
          $(`input:radio[name='madin_depot_no_${optIdx}']:radio[value='${$scope.options_table.find(o => o.optIdx === optIdx).main_depot_no}']`).prop('checked', true);
        });
      });
    };

    /**
     * 이미 선택한 배송처인지 확인 함수.
     *
     * @param {number} depot_no 배송처번호
     * @param {string} currentDepot 현재 select 에서 선택되어있는 배송처 번호
     * @param {number} optIdx 멀티속성인경우 속성 인덱스가 들어옴
     * @returns Boolean
     */
    $scope.optionDisabledCheck = (depot_no, currentDepot, optIdx = null) => {
      let result = false;

      if (_.isNullOrEmpty(optIdx)) {
        result = _.cloneDeep($scope.prodData.prodDepotArray)
          .filter(o => o && o.depot_no !== currentDepot)
          .map(o => o.depot_no)
          .includes(depot_no.toString());
      } else {
        result = _.cloneDeep($scope.options_table)
          .filter(o => o.optIdx === optIdx && o.delivery_vendor !== currentDepot)
          .map(o => o.delivery_vendor)
          .includes(depot_no.toString());
      }

      return result;
    };

    /**
     * 배송처 선택시 배송처이름 갱신
     *
     * @param {string} depot_no 배송처번호
     */
    $scope.changeDepotInfo = (depot_no, idx = null, optIdx = null) => {
      if (!depot_no) {
        return false;
      }

      const selectDepotInfo = $scope.warehouseList.find(o => o.code.toString() === depot_no);

      if (_.isNullOrEmpty(idx)) {
        $scope.prodData.prodDepotArray.find(o => o.depot_no === depot_no).depot_name = selectDepotInfo.warehouse_name;

        // 배송처가 하나인데 변경하는경우 대표배송처도 수정해줘야 함.
        if ($scope.prodData.prodDepotArray.length === 1) {
          $scope.prodData.main_depot_no = depot_no;
        }
      } else {
        if ($scope.options_table.filter(o => o.optIdx === optIdx).length < 2) {
          $scope.options_table[idx].main_depot_no = depot_no;
        }

        $scope.options_table[idx].depot_name = selectDepotInfo.warehouse_name;
      }
    };

    /**
     * 대표 배송처 설정
     *
     * @param {'single', 'multiple'} type 일괄등록 여부
     * @param {string} depot_no 선택한 배송처번호
     * @param {number} optIdx 등록 속성 순번
     */
    $scope.changeMainDepotNo = (type, depot_no, optIdx = 0) => {
      if (!_.isNullOrEmpty(depot_no)) {
        if (type === 'single') {
          $scope.prodData.main_depot_no = depot_no;
        } else {
          $scope.optMainDepots[optIdx] = depot_no;
          $scope.options_table.filter(o => o.optIdx === optIdx).map(o => {
            o.main_depot_no = depot_no;
          });
        }
      } else {
        commonSVC.showMessage('배송처를 선택해주십시오.');
        if (type === 'single') {
          $(`input:radio[name='main_depot_no']:radio[value='${$scope.prodData.main_depot_no}']`).prop('checked', true);
        } else {
          $(`input:radio[name='madin_depot_no_${optIdx}']:radio[value='${$scope.options_table.find(o => o.optIdx === optIdx).main_depot_no}']`).prop('checked', true);
        }
      }
    };

    //속성 추가, 삭제 (일괄 등록)
    $scope.calculateOPT2 = function (calMode, idx) {
      if (calMode === 'plus') {
        if ($scope.opts.length < 3) {
          $scope.opts.push({ name: '', attr: '', type: '직접입력' });
        }
      } else {
        $scope.opts.splice(idx, 1);
        $scope.isOptDuplicated2();
      }
    };

    /**
     * HS코드 검색
     **/
    $scope.searchHscode = function () {
      const resolve = {};

      resolve.data = {
        name: $scope.data.hscd
      };
      const redata = commonSVC.openModal('lg', resolve, 'CategorySearchHscodeCtrl', 'views/settings/category/searchHscode.html');

      redata.result.then(function (re) {
        $scope.prodData.hscd = re;
        $scope.prodData.hscdErrorMsg = '';
      });

    };

    // 수정하기 , 등록하기
    $scope.submit = _.throttle(async () => {
      $scope.clickSubmit = true;
      $scope.invalidTab.forEach((v, idx) => {
        $scope.invalidTab[idx] = $scope.common.checkValidTab(idx);
      });

      if ($scope.prodForm.$valid) {
        const msgs = [];

        settingOptArray('encode');              // 속성 셋팅
        settingMadein('encode');                // 원산지 셋팅
        const chkExpireDate = settingExpire();  //유효날짜 셋팅

        if (chkExpireDate == false) {
          msgs.push('유효일자를 설정하시는 경우 제조일자를 먼저 입력해 주십시오.');
        }

        const price = {
          product_price: '판매가',
          cost_price: '원가',
          supply_price: '공급가'
        };

        // 가격 자릿수 10 초과하면 안됨
        for (const key in price) {
          if ($scope.prodData[key]?.toString().length > 10) {
            msgs.push(`- ${price[key]}는 최대 10자리까지 입력이 가능합니다.`);
          }

          // 일괄 등록일때 판매가 확인
          if ($scope.detailType === 'add' && $scope.data.opt_type === 'multi' && key === 'product_price') {
            for (const row of $scope.options_table) {
              if (row[key]?.toString().length > 10) {
                msgs.push(`- 옵션 ${row.opt1 || ''} ${row.opt2 || ''} ${row.opt3 || ''} ${price[key]}는 최대 10자리까지 입력이 가능합니다.`);
              }
            }
          }
        }

        if (msgs.length > 0) {
          commonSVC.showMessage('상품 등록 실패', msgs.join('\n'));

          return false;
        }

        if ($scope.invalidTab.some(v => v)) {
          commonSVC.showMessage('필수 항목을 모두 입력해주세요.');

          return;
        }

        $scope.prodData.pkg_no = $scope.pkgBoxData.pkg_no || 0;

        if ($scope.detailType === 'add') {
          const { insertColumnList } = columnSVC.setAddColumnValueParams([$scope.prodData], 'product', { data: { results: $scope.prodData.addColumnList } });

          $scope.prodData.insertColumnList = insertColumnList;

          // 1개 등록
          if ($scope.data.opt_type === 'one') {
            if ($scope.dupOpt) {
              commonSVC.showMessage('', '중복된 속성구분이 있습니다.');

              return;
            }

            // SKU코드 자동생성 했을때
            $scope.prodData.opt_multi_reg = false;

            // 안전재고 미입력시 기본값 지정
            $scope.prodData.prodDepotArray.filter(o => !o.safe_stock && o.safe_stock !== 0).map(o => o.safe_stock = defaultObj.safe_stock_default);

            // 실재고 < 안전재고일경우 등록되지 않도록 처리
            if ($scope.prodData.prodDepotArray.find(o => o.safe_stock * 1 > o.real_stock * 1)) {
              commonSVC.showMessage('상품 등록 실패', '안전재고는 실재고를 초과할 수 없습니다');

              return;
            }

            if (!$scope.prodData.prodDepotArray.some(o => o.depot_no === $scope.prodData.main_depot_no.toString())) {
              commonSVC.showMessage('상품 등록 실패', '대표배송처를 선택해주십시오.');

              return;
            }

            productModel.insert($scope.prodData, function (state, data) {
              if (state === 'success') {
                addProdAfterAction(data.data.result);
              } else {
                commonSVC.showToaster('error', '', 'SKU상품 등록에 실패 했습니다.');
              }
            });

          } else if ($scope.data.opt_type === 'multi') {
            if ($scope.options_table.length < 1) {
              commonSVC.showMessage('', '속성을 생성해주세요.');

              return false;
            }

            $scope.options_table.map(item => {
              if (item.prod_img) {
                $scope.prodData[`prod_img_${item.optIdx}`] = item.prod_img;
                item.prod_img = '';
              }

              // 안전재고 미입력시 기본값 지정
              if (!item.safe_stock && item.safe_stock !== 0) {
                item.safe_stock = defaultObj.safe_stock_default;
              }
            });

            // 실재고 < 안전재고일경우 등록되지 않도록 처리
            if ($scope.options_table.some(row => row.safe_stock * 1 > row.real_stock * 1)) {
              commonSVC.showMessage('상품 등록 실패', '안전재고는 실재고를 초과할 수 없습니다');

              return;
            }

            let noneMainDepotNo = false;
            const prodDepotArray = _.groupBy($scope.options_table, (prodDepot) => {
              return prodDepot.optIdx;
            });

            for (const optIdx in prodDepotArray) {
              if (!prodDepotArray[optIdx].some(o => o.delivery_vendor == o.main_depot_no)) {
                noneMainDepotNo = true;
              }
            }

            if (noneMainDepotNo) {
              commonSVC.showMessage('상품 등록 실패', '대표배송처를 선택해주십시오.');

              return;
            }

            $scope.prodData.opt_multi_reg = true;
            $scope.prodData.opt_array = $scope.options_table;

            productModel.insert($scope.prodData, function (state, data) {
              if (state === 'success') {
                addProdAfterAction(data.data.result);
              } else {
                commonSVC.showToaster('error', '', 'SKU상품 등록에 실패 했습니다.');
              }
            });
          }

        } else {
          if ($scope.dupOpt) {
            commonSVC.showMessage('', '중복된 속성구분이 있습니다.');

            return;
          }
          $scope.prodData.changeData = [];

          for (const prodDepot of $scope.prodData.prodDepotArray) {
            if (prodDepot.modify_real_stock > 0) {
              // out_scheduled => 출고예정수량
              if (prodDepot.real_stock_type === '-' && prodDepot.modify_real_stock > prodDepot.real_stock - Math.abs(prodDepot.out_scheduled)) {
                commonSVC.showMessage('', '실재고 차감은 판매가능재고(실재고-출고예정수량)보다 작은 수량만 가능합니다.');

                return false;
              }
            }
          }

          if (!$scope.prodData.prodDepotArray.some(o => o.depot_no === $scope.prodData.main_depot_no.toString())) {
            commonSVC.showMessage('상품 수정 실패', '대표배송처를 선택해주십시오.');

            return;
          }

          $scope.prodData.modify_real_stock = $scope.data.modify_real_stock || 0;
          $scope.prodData.real_stock_type = $scope.data.real_stock_type;
          $scope.prodData.product_price = $scope.prodData.product_price || 0;

          // 수정 추가항목

          const { insertColumnList, logContent } = columnSVC.setAddColumnValueParams([$scope.prodData], 'product', { data: { results: $scope.prodData.addColumnList } });

          $scope.prodData.insertColumnList = insertColumnList;
          $scope.prodData.logContent = logContent;

          const newData = commonSVC.replaceNull(angular.copy($scope.prodData));

          const oldSupp = _.find($scope.supplierList, { supp_no: parseInt(oldData.supp_no) }) || '';
          const newSupp = _.find($scope.supplierList, { supp_no: parseInt(newData.supp_no) }) || '';

          const oldMadein = (oldData.madein_no || oldData.madein_etc) ?
            `${oldData.madein_etc && oldData.madein_etc !== 'undefined' ? (`기타/${oldData.madein_etc}`) : (`${oldData.madein_type}/${oldData.madein_group}/${oldData.madein_name}`)}` : '없음';
          const newMadein = (newData.madein_no || newData.madein_etc) ?
            `${newData.madein_etc && newData.madein_etc !== 'undefined' ? (`기타/${newData.madein_etc}`) : (`${newData.madein_type}/${newData.madein_group}/${newData.madein_name}`)}` : '없음';

          const logField = {
            // 기본 정보
            prod_name: `상품명: ${oldData.prod_name || '없음'}->${newData.prod_name || '없음'}`,
            opt: '속성명: 변경내용 생략',
            barcode: `바코드: ${oldData.barcode || '없음'}->${newData.barcode || '없음'}`,
            stock_cd: `재고관리코드: ${oldData.stock_cd || '없음'}->${newData.stock_cd || '없음'}`,
            supp_no: `매입처(사입처): ${oldSupp ? oldSupp.supp_name : ''}->${newSupp ? newSupp.supp_name : ''}`,
            model: `모델명: ${oldData.model || '없음'}->${newData.model || '없음'}`,
            global_barcode: `UPC / EAN 코드: ${oldData.global_barcode_type || '없음'}:${oldData.global_barcode}->${newData.global_barcode_type || '없음'}:${newData.global_barcode}`,
            hscd: `HS코드: ${oldData.hscd || '없음'}->${newData.hscd || '없음'}`,
            isbn: `ISBN: ${oldData.isbn || '없음'}->${newData.isbn || '없음'}`,
            made_date: `제조일자: ${oldData.made_date || '없음'}->${newData.made_date || '없음'}`,
            expiration_month: `유효일자: ${oldData.expiration_month === '52' || oldData.expiration_month === null ? '없음' : oldData.expire_date}->${newData.expiration_month === '52' ? '없음' : newData.expire_date}`,
            main_depot_no: `대표배송처: ${oldData.prodDepotArray.find(o => o.depot_no == oldData.main_depot_no)?.depot_name || '없음'}->${$scope.prodData.prodDepotArray.find(o => o.depot_no == newData.main_depot_no)?.depot_name || '없음'}`,

            // 규격/가격 정보
            bundle_avail_yn: `합포장여부: ${oldData.bundle_avail_yn ? '가능' : '불가(개별배송)'}->${newData.bundle_avail_yn ? '가능' : '불가(개별배송)'}`,
            prod_standard: `규격: ${oldData.prod_width || '0'}cm/${oldData.prod_height || '0'}cm/${oldData.prod_depth || '0'}cm/${oldData.prod_weight || '0'}kg->${newData.prod_width || '0'}cm/${newData.prod_height || '0'}cm/${newData.prod_depth || '0'}cm/${newData.prod_weight || '0'}kg`,
            in_unit_info: `${!$rootScope.osse_sol ? '입고단위:' : '낱본 수량:'} ${oldData.in_unit_cnt === 0 ? 0 : (oldData.in_unit_cnt || '')}${!$rootScope.osse_sol ? oldData.in_unit : '본'}->${newData.in_unit_cnt === 0 ? 0 : (newData.in_unit_cnt || '')}${!$rootScope.osse_sol ? newData.in_unit : '본' }`,
            cost_price: `원가: ${oldData.cost_price || '0'}원->${newData.cost_price || '0'}원`,
            supply_price: `공급가: ${oldData.supply_price || '0'}원->${newData.supply_price || '0'}원`,
            product_price: `판매가: ${oldData.product_price || '0'}원->${newData.product_price || '0'}원`,
            purchase_cost: `매입원가: ${oldData.purchase_cost || '0'}원->${newData.purchase_cost || '0'}원`,
            in_ship_cost: `매입운송비: ${oldData.in_ship_cost || '0'}원->${newData.in_ship_cost || '0'}원`,
            ship_cost: `택배발송비: ${oldData.ship_cost || '0'}원->${newData.ship_cost || '0'}원`,
            adv_cost: `광고비: ${oldData.adv_cost || '0'}원->${newData.adv_cost || '0'}원`,
            other_cost: `기타비: ${oldData.other_cost || '0'}원->${newData.other_cost || '0'}원`,
            pkg_no: `포장 형태: ${$scope.pkgBoxList.find(pkgBox => pkgBox.pkg_no === oldData.pkg_no)?.pkg_name || '없음'}->${$scope.pkgBoxList.find(pkgBox => pkgBox.pkg_no === newData.pkg_no)?.pkg_name || '없음'}`,

            // 추가정보
            madein: `원산지: ${oldMadein}->${newMadein}`,
            multi_madein_yn: `복수 원산지: ${oldData.multi_madein_yn ? '설정함' : '설정안함'}->${newData.multi_madein_yn ? '설정함' : '설정안함'}`,
            tax_type: `과세여부: ${oldData.tax_type}->${newData.tax_type}`,
            brand: `브랜드: ${oldData.brand || '없음'}->${newData.brand || '없음'}`,
            maker: `제조사: ${oldData.maker || '없음'}->${newData.maker || '없음'}`,
            maker_eng: `영문 제조사: ${oldData.maker_eng || '없음'}->${newData.maker_eng || '없음'}`,
            use_age: `사용 연령: ${oldData.use_age || '없음'}->${newData.use_age || '없음'}`,
            prod_info: '상품정보제공고시: 변경내용 생략',
            prod_img: '대표이미지: 변경내용 생략',
            mas_addcontent_no: '헤더-푸터 템플릿: 변경내용 생략',
            contents: '상세설명: 변경내용 생략'
          };

          let newOpt = '';
          let oldOpt = '';

          for (let i = 1; i <= 3; i++) {
            newOpt += (newData[`opt${i}`] || '') + (newData[`opt${i}_type`] || '');
            oldOpt += (oldData[`opt${i}`] || '') + (oldData[`opt${i}_type`] || '');
          }
          newData.opt = newOpt;
          oldData.opt = oldOpt;

          newData.madein = newData.madein_etc + newData.madein_no + newData.madein_group + newData.madein_name;
          oldData.madein = oldData.madein_etc + oldData.madein_no + oldData.madein_group + oldData.madein_name;

          newData.made_date = newData.made_date === '0000-00-00' ? '' : newData.made_date;
          oldData.made_date = oldData.made_date === '0000-00-00' ? '' : oldData.made_date;

          newData.expiration_month = newData.expiration_month === '0000-00-00' || !newData.expiration_month || newData.expiration_month === '52' ? '없음' : newData.expiration_month;
          oldData.expiration_month = oldData.expiration_month === '0000-00-00' || !oldData.expiration_month || oldData.expiration_month === '52' ? '없음' : oldData.expiration_month;

          newData.prod_standard = newData.prod_width + newData.prod_height + newData.prod_depth + newData.prod_weight;
          oldData.prod_standard = oldData.prod_width + oldData.prod_height + oldData.prod_depth + oldData.prod_weight;

          newData.in_unit_info = `${newData.in_unit_cnt === 0 ? 0 : (newData.in_unit_cnt || '')}${newData.in_unit}`;
          oldData.in_unit_info = `${oldData.in_unit_cnt === 0 ? 0 : (oldData.in_unit_cnt || '')}${oldData.in_unit}`;

          newData.global_barcode = newData.global_barcode_type + newData.global_barcode;
          oldData.global_barcode = oldData.global_barcode_type + oldData.global_barcode;

          newData.prod_info = JSON.stringify(newData.prod_info);
          oldData.prod_info = JSON.stringify(oldData.prod_info);

          newData.contents = newData.contents ? newData.contents.replace(/<script.*<\/script>/gi, '').replace(/(\s|\n|amp;|lt;|br\/&)/g, '').trim() : '';
          oldData.contents = oldData.contents ? oldData.contents.replace(/<script.*<\/script>/gi, '').replace(/(\s|\n|amp;|lt;|br\/&)/g, '').trim() : '';

          $scope.prodData.log_contents = logHistorySVC.compare(logField, oldData, newData);

          // ------------------------------------------------------------------------------------
          // 다중배송처 추가로 배송처 + 재고 로그는 별도로 처리.
          const addLogData = [];

          for (const prodDepot of $scope.prodData.prodDepotArray) {
            const matchProdDepot = oldData.prodDepotArray.find(o => o.depot_no === prodDepot.depot_no);

            // 배송처 추가.
            if (!matchProdDepot) {
              addLogData.push(`배송처(출고처) 추가: ${prodDepot.depot_name}, 실재고: ${prodDepot.real_stock}개, 안전재고: ${prodDepot.safe_stock}개`);
            } else {
              if (prodDepot.safe_stock !== matchProdDepot.safe_stock) {
                addLogData.push(`[${prodDepot.depot_name}]배송처 안전재고: ${matchProdDepot.safe_stock}개->${prodDepot.safe_stock}개`);
              }
              if (prodDepot.modify_real_stock) {
                addLogData.push(`[${prodDepot.depot_name}]배송처 ${prodDepot.real_stock_type === '+' ? '입고' : '출고'}: ${prodDepot.modify_real_stock}개 / 실재고: ${matchProdDepot.real_stock}개->${prodDepot.real_stock_type === '+' ? matchProdDepot.real_stock * 1 + prodDepot.modify_real_stock * 1 : matchProdDepot.real_stock * 1 - prodDepot.modify_real_stock * 1}개 ${prodDepot.inout_memo ? ` / 사유:${prodDepot.inout_memo}` : ''}`);
              }
            }
          }

          // 배송처 삭제
          for (const ordProdDepot of oldData.prodDepotArray) {
            const matchProdDepot = $scope.prodData.prodDepotArray.find(o => o.depot_no === ordProdDepot.depot_no);

            if (!matchProdDepot) {
              addLogData.push(`배송처(출고처) 삭제: ${ordProdDepot.depot_name}`);
            }
          }

          if (addLogData.length) {
            $scope.prodData.log_contents = $scope.prodData.log_contents.split(',').concat(addLogData).filter(o => o).join(',');
          }
          // ------------------------------------------------------------------------------------

          if ($scope.prodData.log_contents[0] == '') { $scope.prodData.log_contents = []; }

          $scope.prodData.compare = oldData;
          $scope.prodData.supp_name = newSupp?.supp_name;

          if ($scope.prodData.ebaydepot_yn && $scope.prodData.log_contents) {
            commonSVC.showConfirm('수정된 정보는 솔루션에서만 적용되며 G마켓 물류서비스에 반영되지 않습니다.\n수정하시겠습니까?', '', update);
          } else {
            // 대표 SKU상품 정보 자동세팅 설정 있는 경우에만 노출
            if (systemList.data.prod_auto_set_yn === 'Y') {
              // 대표 SKU상품 정보 자동세팅에 반영되는 필드들
              const prodAutoFields = ['supply_price', 'cost_price', 'madein', 'multi_madein_yn', 'tax_type', 'barcode', 'global_barcode', 'hscd', 'isbn', 'made_date', 'expiration_month'];
              const reduceLogField = prodAutoFields.reduce((prev, curr) => ({ ...prev, [curr]: logField[curr] }), {});

              let prodAutoSetChk = false;

              for (const key in reduceLogField) {
                if ($scope.prodData.log_contents.includes(reduceLogField[key])) {
                  prodAutoSetChk = true;
                  break;
                }
              }

              // 대표 SKU상품 정보 자동세팅에 반영되는 필드들이 수정됐을 경우에만
              if (prodAutoSetChk) {
                const confirmOpt = {
                  title: '대표 SKU상품 정보 자동 세팅',
                  text: '<div style="font-size: 12px; font-weight: 400;">' +
                    '* 설정 > 환경설정 > 상품 > 대표 SKU상품 정보 자동 세팅 > 설정인 경우에 해당합니다.' +
                    '<ul class="popup-ul"">' +
                    '<li><span class="text-danger">[반영]</span>을 클릭하시면 수정된 정보 중 아래 항목은 연동된 온라인 상품에도 수정 반영합니다.<br>' +
                    '<span style="font-size: 12px">(공급가, 원가, 원산지, 과세여부, 바코드, UPC/EAN코드, HS코드, ISBN코드, 제조일자, 유효일자)</span></li>' +
                    '<li><span class="text-danger">[반영안함]</span>를 클릭하시면 SKU상품만 수정하고 온라인 상품에는 반영하지 않습니다.</li>' +
                    '<li><span class="text-danger">쇼핑몰 상품에서 필수값이나 SKU상품에서 비어있는 항목의 경우, SKU상품 정보로 반영되오니 필수값은 쇼핑몰 상품 관리에서 수정 후 쇼핑몰로 전송해주시기 바랍니다.</span></li>' +
                    '</ul>' +
                    '<div class="mt-25" style="font-size: 15px">연동된 온라인상품에도 반영하시겠습니까?</div>' +
                    '(판매중인 상태는 수정 시 수정대기로 변경됩니다.)' +
                    '</div>',
                  confirmButtonText: '반영',
                  cancelButtonText: '반영안함',
                  html: true
                };

                $scope.prodData.prod_online_set_yn = await commonSVC.showConfirmCustom(confirmOpt);
              }
            }

            update();
          }
        }
      } else {
        commonSVC.showMessage('필수 항목을 모두 입력해주세요.');
      }
    }, 1000);

    $scope.checkFile = function (file) {
      if (!file) {
        commonSVC.showMessage('실패', '정상적인 이미지 파일이 아닙니다.');
        $scope.prodData.prod_img = null;
      } else if (file.size > 3145728) {
        commonSVC.showMessage('실패', '이미지 용량이 3MB를 초과합니다.');
        $scope.prodData.prod_img = null;
      }
    };

    function update() {
      productModel.update($scope.prodData, function (state, data) {
        if (state === 'success') {
          $scope.modalComplete();

          // 재고수정이 일어났으면 채널별 재고할당해서 전송함 (설정이 있을경우)
          // 배송처인경우 진행하지 않음
          if ($scope.prodData.log_contents.indexOf('실재고') > -1 && $rootScope.user_profile.auth_type !== '배송처') {
            // 재고할당 설정이 있으면 재고할당 진행
            inventoryModel.stockAllocGetType({}, function (state, data) {
              if (data.result[0].allocation_type) {
                const newStock = {};
                const alloc_type = data.result[0].allocation_type;
                const prodList = $scope.prodData.prodDepotArray.filter(o => o.modify_real_stock && parseInt(o.modify_real_stock) > 0).map(o => {
                  newStock[`${$scope.prodData.prod_no}_${o.depot_no}`] = o.modify_real_stock;

                  return {
                    prod_no: +$scope.prodData.prod_no,
                    depot_no: +o.depot_no
                  };
                });

                // 유효한 재고할당 규칙이 있을경우
                if (prodList.length) {
                  inventoryModel.stockAllocCheckRuleExists({ prod_list: prodList, alloc_type: alloc_type }, function (state, data) {
                    if (data.result) {
                      const params = {
                        prod_list: prodList,
                        alloc_type: alloc_type,
                        // new_stock: $scope.prodData.modify_real_stock * ($scope.data.real_stock_type === '+' ? 1 : -1),
                        new_stock: newStock
                      };

                      commonSVC.openModal('full', { data: params }, 'StockAllocationChannelCtrl', 'views/stock/inventory/stock_allocation_channel.html');
                    }
                  });
                }

              }
            });
          }
        } else {
          const err_msg = data.data?.error === 'not_allowed_depot_change' ? '아래와 같은 경우 배송처 수정이 제한됩니다.<br />&nbsp; - 매칭된 주문이 있는 경우<br />&nbsp; - 세트상품으로 구성된 경우<br />&nbsp; - 다중 매칭으로 매칭 규칙이 있는 경우<br /> <a href="https://app.playauto.io/#!/notice/system/detail/2221" class="button-a button-a-background">[관련 공지사항 보기]</a>' : data.data?.error;
          commonSVC.showMessageHtml('<b>SKU상품 수정에 실패 했습니다.</b>', err_msg || '');
        }
      });
    }

    // 모달 완료 시 중복 이벤트
    $scope.modalComplete = function () {
      // 2018-01-11 chris 리스트 새로고침을 위한 이벤트 추가
      $rootScope.$broadcast('reloadProdProductList');

      commonSVC.showToaster('success', '', 'SKU상품 수정에 성공 하였습니다.');
      $uibModalInstance.close('success');
    };

    // 모달 닫기
    $scope.cancel = function () {
      if ($scope.detailType === 'edit') {
        $uibModalInstance.dismiss('cancel');
      } else {
        if ($scope.isModal) {
          // 등록 모달 딛기
          $uibModalInstance.close();
        } else {
          // 등록 페이지(탭) 닫기
          $rootScope.$emit('$closeTab', 'tab_prod_product_add');
        }
      }
    };

    // 속성조합
    $scope.joinOpt = function () {
      const opt = _.filter([$scope.prodData.opt1, $scope.prodData.opt2, $scope.prodData.opt3], function (n) { return n; }).join(', ');

      return opt ? `${opt}` : opt;
    };

    // SKU 중복체크
    $scope.overlabCheck = function (opt_array) {
      const regx = new RegExp(/^[a-zA-Z0-9,./_+=()-]*$/);
      const sku_arr = opt_array.map(v => v.sku_cd);

      opt_array.forEach(obj => {
        const sku = obj.sku_cd ? obj.sku_cd.trim() : '';
        if (!sku) {
          return;
        }

        if (obj.skuOverlabChecked) {
          return;
        }

        if (obj.skuAuto) {
          obj.skuOverlabErrorMsg = '';
        } else if (!regx.exec(sku)) {
          obj.skuOverlabErrorMsg = "SKU 코드는 영문/숫자/특수문자('(' ')' '-' '_' '+' '=' '/' '.' ',' )만 입력이 가능합니다.";
        } else if (sku_arr.filter(v => v == sku).length > 1) {
          obj.skuOverlabErrorMsg = '중복된 SKU코드를 동시에 등록할 수 없습니다.';
        } else {
          productModel.skuOverlabCheck({ code: sku }, function (state, result) {
            if (result.data.result) {
              obj.skuOverlabErrorMsg = '해당 코드로 등록된 SKU 또는 세트상품이 있습니다.';
            } else {
              obj.skuOverlabErrorMsg = '';
              obj.skuOverlabChecked = true;
            }
          });
        }
      });

    };

    // HS코드 유효성 검사
    $scope.hscdValidCheck = async function (obj) {
      if (!obj.hscd) {
        return;
      }

      const re = await productModel.hscodeValid({ hscd: obj.hscd });
      if (re.data.results) {
        obj.hscdErrorMsg = '';
      } else {
        obj.hscdErrorMsg = '입력한 코드가 올바르지 않습니다. 코드 검색 후 입력해주세요.';
      }
    };

    /**
     * 모델번호 중복 체크
     */
    $scope.modelnoOverlabCheck = (opt_array) => {
      const regx = new RegExp(/^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9-_.]*$/);
      const model_no_arr = opt_array.map(v => v.model_no);

      opt_array.forEach(obj => {
        const model_no = obj.model_no ? obj.model_no.trim() : '';
        if (!model_no) {
          return;
        }

        if (obj.modelnoOverlabChecked) {
          return;
        }

        if (!regx.exec(model_no)) {
          obj.modelnoOverlabErrorMsg = "모델번호는 한글/영문/숫자/특수문자('-' '_' '.')만 입력이 가능합니다.";
        } else if (model_no_arr.filter(v => v == model_no).length > 1) {
          obj.modelnoOverlabErrorMsg = '중복된 모델번호를 동시에 입력할 수 없습니다.';
        } else {
          productModel.modelnoOverlabCheck({ prod_no: obj.prod_no || 0, model_no: model_no }, function(state, checkResult) {
            if (checkResult.data.result) {
              obj.modelnoOverlabErrorMsg = '이미 등록된 모델번호 입니다.';
            } else {
              obj.modelnoOverlabErrorMsg = '';
              obj.modelnoOverlabChecked = true;
            }
          });
        }
      });
    };

    //매입처 관리
    $scope.setSupplier = function () {
      $state.go('main.settings_supplier_list');
    };

    //매입처 리스트 새로고침
    $scope.loadSupp = function () {
      $scope.supplierList = supplierModel.ListAll({ use_yn: true })
        .then(function (re) {
          $scope.supplierList = re.data.result;
        });
    };

    //배송처 관리
    $scope.setWarehouse = function () {
      $state.go('main.stock_warehouse_list');
    };

    //배송처 리스트 새로고침
    $scope.loadWarehouse = function () {
      warehouseModel.ListAll({ use_yn: true })
        .then(function (re) {
          $scope.warehouseList = re.data.result;
        });
    };

    //원산지 국가 선택
    $scope.madein_type_change = function (isSelec) {
      $scope.madeLocalList = $scope.madeinList[$scope.prodData.madein_type];
      if (isSelec) {
        $scope.prodData.madein_group = '';
        $scope.prodData.madein_name = '';
        $scope.prodData.madein_etc = '';
      }
    };

    //원산지 도시 선택
    $scope.madein_country_change = function (isSelec) {
      if ($scope.prodData.madein_type && $scope.prodData.madein_type !== '기타') {
        $scope.madeLocalDetailList = $scope.madeinList[$scope.prodData.madein_type][$scope.prodData.madein_group];
        if (isSelec) {
          $scope.prodData.madein_etc = '';
        }
      }
    };

    // 상품정보 제공고시 열기
    $scope.open_prod_info = function () {
      const resolve = {
        data: {}
      };

      if (_.size($scope.prodData.prod_info)) {
        resolve.data.value = JSON.stringify($scope.prodData.prod_info);
      }

      // SKU상품 수정 품목정보고시 상세에서 기존 데이터가 노출되지않는 문제 보완. 2018-03-29 rony
      if ((_.isUndefined(resolve.data.value) || resolve.data.value == '') && $scope.prodData.prod_noti_data != '') {
        resolve.data.value = $scope.prodData.prod_noti_data;
      }

      const modal = commonSVC.openModal('lg', resolve, 'ProdProductProdInfoCtrl', 'views/prod/product/modals/prod_info.html');

      modal.result.then(function (re) {
        if (re.code == '') {
          return false;
        }
        const array_result = {};

        $scope.prodData.prod_info_name = re.name;

        array_result.infoName = re.name;
        array_result.infoCode = re.code;
        array_result.infoDetail = {};

        re.value.forEach(list => {
          list.detail.map(v => {
            array_result.infoDetail[v.code] = v.value;
          });
        });
        $scope.prodData.prod_info = array_result;
      });
    };

    $scope.optionCheck = function (opt_type, checked) {
      if (opt_type === '색상') {
        $scope.opts[0].name = checked ? '색상' : '';
        $scope.opts[0].attr = '';
      } else if (opt_type === '사이즈') {
        $scope.opts[1].name = checked ? '사이즈' : '';
        $scope.opts[1].attr = '';
      } else if (opt_type === '직접입력') {
        $scope.opts[2].name = '';
        $scope.opts[2].attr = '';
      }
    };
    /**
     * 옵션 생성
     */
    $scope.optFn = function () {
      // 속성구분에 중복된 값 있는지 체크
      if ($scope.dupOpt_multi) {
        commonSVC.showMessage('', '중복된 속성구분이 있습니다.');

        return;
      }

      if ($scope.opts.some(v => !v.attr || !v.name)) {
        commonSVC.showMessage('', '속성 구분과 속성명을 모두 입력해주세요.');

        return;
      }

      $scope.options_table = productSVC.setOptionsTable($scope.opts, defaultObj);
      $scope.optMainDepots = $scope.options_table.map(o => o.main_depot_no);

      $timeout(() => {
        (_.uniq($scope.options_table.map(o => o.optIdx))).forEach(optIdx => {
          $(`input:radio[name='madin_depot_no_${optIdx}']:radio[value='${$scope.options_table.find(o => o.optIdx === optIdx).main_depot_no}']`).prop('checked', true);
        });
      });

      // 툴팁 그려주기
      $timeout(function () {
        $('[data-popup="tooltip"]').tooltip();
      }, 100);
    };

    /**
     * 일괄입력
     */
    $scope.BatchOpTable = function (ob) {
      let input_value = ob.batch_input;

      if (ob.batch_type === '') {
        commonSVC.showMessage('실패', '일괄입력 할 타입을 선택 해주세요.');

        return;
      }
      if (ob.batch_type === 'product_price') {
        if (isNaN(ob.batch_input)) {
          commonSVC.showMessage('실패', '숫자만 입력이 가능합니다.');

          return;
        } else if (+ob.batch_input < 1) {
          commonSVC.showMessage('실패', '최소 1 이상 입력해주세요.');

          return;
        }
      }
      if (ob.batch_type === 'barcode') {
        const regx = new RegExp(/^[a-zA-Z0-9\s_]*$/);

        if (!regx.exec(ob.batch_input)) {
          commonSVC.showMessage('실패', '영문 + 숫자 + _ + 공백 만 입력가능합니다.');

          return;
        }
      }
      if (ob.batch_type === 'real_stock') {
        if (isNaN(ob.batch_input)) {
          commonSVC.showMessage('실패', '숫자만 입력이 가능합니다.');

          return;
        } else if (+ob.batch_input < 1) {
          commonSVC.showMessage('실패', '최소 1 이상 입력해주세요.');

          return;
        } else {
          input_value = Number(ob.batch_input);
        }
      }
      if (ob.batch_type === 'safe_stock') {
        if (isNaN(ob.batch_input)) {
          commonSVC.showMessage('실패', '숫자만 입력이 가능합니다.');

          return;
        } else if (+ob.batch_input < 0) {
          commonSVC.showMessage('실패', '최소 0 이상 입력해주세요.');

          return;
        } else {
          input_value = Number(ob.batch_input);
        }
      }
      if (ob.batch_type === 'model') {
        const regx = new RegExp(/^.{1,40}$/);

        if (!regx.exec(ob.batch_input)) {
          commonSVC.showMessage('실패', '형식에 맞게 입력해주세요.');

          return;
        }
      }

      let flag = false;

      // 적용항목 선택 후 배송처를 추가하는경우 추가된 배송처에는 체크항목이 적용되어있지않아 관련 처리 추가.
      let setOptTable = [];
      const optIdxGroup = _.groupBy($scope.options_table, 'optIdx');

      for (let groupIdx = 0; groupIdx < Object.keys(optIdxGroup).length; groupIdx++) {
        if (optIdxGroup[groupIdx].some(opt => opt.checked)) {
          optIdxGroup[groupIdx].map(opt => opt.checked = true);
        }

        setOptTable = setOptTable.concat(optIdxGroup[groupIdx]);
      }
      $scope.options_table = setOptTable;

      for (const opt of $scope.options_table) {
        if (opt.checked) {
          flag = true;

          // 해당 옵션에 이미 등록된 값이 있는경우 처리 안함.
          if (ob.batch_type === 'delivery_vendor' && $scope.options_table.find(o => o.delivery_vendor === input_value && o.optIdx === opt.optIdx)) {
            continue;
          }

          opt[ob.batch_type] = input_value;

          // class 에 recheck 로 되어있는 필드 다시 유효성 체크 2018-03-13 rony
          // 값이 있는경우에만 처리.
          if (ob.batch_type == 'real_stock' && input_value != '' && input_value > 0) {
            $scope.reCheckClass.valid('recheck');
          }
        }
      }

      if (!flag) {
        commonSVC.showMessage('실패', '일괄입력 하실 옵션 상품을 선택해주세요.');
      }
    };

    // 속성 추가
    $scope.addOption = function (optIdx = null) {
      const obj = {
        checked: false,
        skuAuto: true,
        sku_cd: '',
        barcode: '',
        real_stock: 0,
        safe_stock: defaultObj.safe_stock_default,
        model: '', // 모델명
        model_no: '',
        prod_img: '',
        stock_cd: '',  // 재고관리코드
        delivery_vendor: _.isNullOrEmpty(optIdx) ? defaultObj.default_warehouse : '',  // 배송처(출고처)
        optIdx: _.isNullOrEmpty(optIdx) ? Math.max.apply(null, $scope.options_table.map(o => o.optIdx)) + 1 : optIdx,
        depotIdx: _.isNullOrEmpty(optIdx) ? 0 : $scope.options_table.filter(o => o.optIdx === optIdx).length,
        depotLength: _.isNullOrEmpty(optIdx) ? 1 : $scope.options_table.filter(o => o.optIdx === optIdx).length + 1,
        main_depot_no: _.isNullOrEmpty(optIdx) ? defaultObj.default_warehouse : $scope.options_table.find(o => o.optIdx === optIdx).main_depot_no
      };
      const opt_obj = angular.copy($scope.options_table[0]);

      if (opt_obj.opt1_type) {
        obj.opt1_type = opt_obj.opt1_type;
        obj.opt1 = '';
      }
      if (opt_obj.opt2_type) {
        obj.opt2_type = opt_obj.opt2_type;
        obj.opt2 = '';
      }
      if (opt_obj.opt3_type) {
        obj.opt3_type = opt_obj.opt3_type;
        obj.opt3 = '';
      }

      if (!_.isNullOrEmpty(optIdx)) {
        const optDepotIdx = $scope.options_table.filter(o => o.optIdx === optIdx).length;

        // 매칭된 배송처 갯수를 업데이트 해줘야 함.
        $scope.options_table.filter(o => o.optIdx === optIdx).map(o => o.depotLength = obj.depotLength);
        $scope.options_table.splice($scope.options_table.findIndex(o => o.optIdx == optIdx) + optDepotIdx, 0, obj);
      } else {
        $scope.optMainDepots.push(obj.main_depot_no);
        $scope.options_table.push(obj);
      }
    };

    /**
     * 속성 삭제
     */
    $scope.subOption = function () {
      const index = [];
      let removeOptIdx;

      _.forEach($scope.options_table, function (item, i) {
        if (item.checked || removeOptIdx === item.optIdx) {
          removeOptIdx = item.optIdx;

          index.push(i);
        }
      });

      // 선택된 값이 없을때 경고 팝업 추가 2018-01-04 서상권
      if (index.length < 1) {
        commonSVC.showMessage('선택된 데이터가 없습니다.');
      } else {
        _.pullAt($scope.options_table, index);

        // optIdx 값 순서대로 재설정
        $scope.options_table = $scope.options_table.map((opt, index) => ({ ...opt, optIdx: index }));
      }
    };

    // 체크 전부 해제
    $scope.allCheckToggle = function () {
      if ($scope.data.allCheck) {
        _.forEach($scope.options_table, function (item) {
          item.checked = false;
        });
      } else {
        _.forEach($scope.options_table, function (item) {
          item.checked = true;
        });
      }
      $scope.data.allCheck = !$scope.data.allCheck;
    };

    /**
     * SKU상품 이미지 삭제
     */
    $scope.delProdImg = () => {
      const confirmOpt = {
        title: '이미지 삭제 확인',
        text: '<div style="font-size: 14px; font-weight: 400;">' +
          '<ul>' +
          '<li>SKU상품이 온라인 상품에 연동된 경우<br>' +
          '<span>쇼핑몰 상품 관리에서도 옵션의 이미지가 삭제되며, 쇼핑몰로 수정 전송 시에도 영향이 있습니다.</span></li>' +
          '<li>SKU상품이 수집된 주문에 연동된 경우<br>' +
          '<span>수집된 주문의 상품명에 이미지가 노출되지 않으며, 쇼핑몰 상품에는 영향이 없습니다.</span></li>' +
          '</ul>' +
          '<div class="mt-25" style="font-size: 15px">등록된 이미지를 삭제하시겠습니까?</div>' +
          '</div>',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        html: true
      };

      commonSVC.showConfirmCustom(confirmOpt, function (confirm) {
        if (confirm) {
          $scope.prodData.prod_img = '';
          $scope.prodData.del_img_yn = true;
          $timeout(() => { });
        } else {
          $scope.prodData.del_img_yn = false;
        }
      });
    };

    // 초기화
    async function init() {
      $scope.clickSubmit = false;
      $scope.prodData = angular.copy(productSVC.product);               //SVC에서 가져오기
      $scope.costPriceSettingActive = $scope.detailType !== 'add'; // 원가 세부 설정 펼치기 플래그
      // 원가 세부 정보 초기값
      costDetailData.forEach(key => { $scope.prodData[key] = 0; });
      $scope.pkgBoxList = (await productModel.prodPkgBoxTypeList()).data.rows;

      // SKU추가항목
      columnModel.addColumnList({ addcol_type: 'product' })
        .then((res) => {
          if (res.data?.results?.length) {
            $scope.prodData.addColumnList = res.data.results.map(add_col => {
              // map으로 돌길래 추가 작업해줌
              $scope.prodData[add_col.col_name] = null;

              return {
                ...add_col,
                custom_col_name: columnSVC.setColumnName(add_col.col_name, 'GET'),
                enum_data: add_col.enum_data ? add_col.enum_data.split('^').map(e => e.split('||')[1].trim()) : null
              };
            });
          }
        });

      $scope.isApplyTemplate = false;
      if ($scope.detailType === 'add') {
        $scope.tabActive = 0;
        /*$scope.categoryName = '';*/

        $scope.prodData.prod_online_set_yn = false;                       //  대표 SKU상품 정보 세팅 여부
        $scope.prodData.del_img_yn = false;
        $scope.prodData.safe_stock = defaultObj.safe_stock_default;
        // SKU상품 등록시 속성 조합 + - 버튼으로 추가/삭제하는걸로 변경 2018-07-03 Amelia
        // $scope.opts = [{ name: '', attr: '' }, { name: '', attr: '' }, { name: '', attr: '' }];
        $scope.opts = [{ name: '', attr: '', type: '직접입력' }];
        $scope.options_table = [];
        $scope.opt = {
          value: ''
        };
        $scope.optArray = [{ type: '', value: '', custom_type: '' }];  // 옵션 초기화
        $scope.prodData.madein_type = '';  // 원산지 설정
        $scope.prodData.made_date = '';    // 제조일자 설정
        $scope.prodData.expire_date = '';  // 유효일자 설정
        $scope.prodData.multi_madein_yn = 0;  // 복수 원산지 여부

        $scope.prodData.delivery_vendor = defaultObj.default_warehouse;   //기본 배송처 넣어주기
        $scope.prodData.prodDepotArray = [{ depot_no: defaultObj.default_warehouse, depot_name: '', real_stock: 0, safe_stock: defaultObj.safe_stock_default }];
        $scope.prodData.main_depot_no = defaultObj.default_warehouse;
      } else {
        productModel.detail({ sku_code: data.sku, sol_no: data.prodSolNo || data.sol_no }, function (state, data) {
          if (state === 'success') {
            oldData = commonSVC.replaceNull(angular.copy(data.results));

            oldData.modify_real_stock = 0;

            $scope.prodData = { ...data.results, addColumnList: $scope.prodData.addColumnList };

            // 추가항목 세팅
            if (data.results.addColumns?.length) {
              data.results.addColumns.forEach(addCol => {
                $scope.prodData[addCol.col_name] = addCol.col_val;
              });
            }

            $scope.prodData.prod_img = $scope.prodData.prod_img ? $scope.prodData.prod_img : ''; //이미지가 null 이면 에러나서 빈 문자열로 대처

            $scope.prodData.prodDepotArray = angular.copy(data.results.prodDepotArray);
            $scope.prodData.ori_prodDepots = $scope.prodData.prodDepotArray.map(o => o.depot_no);

            $scope.changePkgBox($scope.prodData.pkg_no);

            $scope.prodData.stock_status = '정상';
            if ($scope.prodData.prodDepotArray.find(o => o.stock_status === '품절')) {
              $scope.prodData.stock_status = '품절';
            } else if ($scope.prodData.prodDepotArray.find(o => o.stock_status === '재고부족')) {
              $scope.prodData.stock_status = '재고부족';
            }

            // 대표배송처가 설정되어있지않은 기존상품들은 매칭되어있던 배송처를 대표배송처로 설정
            if (!$scope.prodData.main_depot_no && $scope.prodData.prodDepotArray.length === 1) {
              $scope.prodData.main_depot_no = $scope.prodData.prodDepotArray[0].depot_no;
            }

            // G마켓물류 SKU상품아닐시 G마켓물류 배송처 없앰
            if (!$scope.prodData.prodDepotArray.some(o => o.ebaydepot_yn)) {
              $scope.warehouseList = $scope.warehouseList.filter(o => o.ebaydepot_yn === 0);
            }

            if (!$scope.prodData.made_date || $scope.prodData.made_date == '0000-00-00') {
              $scope.prodData.made_date = null;
            }

            if (!$scope.prodData.expire_date || $scope.prodData.expire_date == '0000-00-00') {
              $scope.prodData.expiration_month = '52';
              $scope.prodData.expire_date = null;
            }

            // 상품 정보고시가 저장되어있는 경우
            if ($scope.prodData.prod_noti_data) {
              const prod_info = $scope.prodData.prod_noti_data;

              if (_.size(prod_info)) {
                const filter = _.filter(onlineProductSVC.newTypes, { code: prod_info.infoCode });

                $scope.prodData.prod_info_name = filter[0] ? filter[0].name : '';
              }
            }

            settingOptArray('decode'); // 속성 셋팅
            settingMadein('decode');   // 원산지 셋팅
            $scope.prodData.madein_group = $scope.prodData.madein_group || '';
            $scope.prodData.madein_name = $scope.prodData.madein_name || '';

            $scope.data.real_stock_type = '+'; //실재고 수정 기본 타입
            $scope.data.modify_real_stock = 0; //실재고 수정 기본 수량

            // 옵션
            $scope.opts = [{ name: '', attr: '' }, { name: '', attr: '' }, { name: '', attr: '' }];
            $scope.options_table = [];

            // 재고정보 셋팅.
            $scope.modify_enter_stock = data.results.in_cnt_accum;                  // 누적 입고 수량
            $scope.modify_rel_stock = data.results.out_cnt_accum;                   // 누적 출고 수량
            $scope.modify_will_rel_stock = $scope.prodData.prodDepotArray.reduce((acc, curr) => acc + Math.abs(curr.out_scheduled), 0);    // 출고예정수량
            $scope.modify_buy_stock = data.results.sale_cnt_accum;                  // 누적 구매 수량
            $scope.modify_cancel_stock = data.results.cancel_cnt_accum;             // 누적 취소 수량
            $scope.modify_return_stock = data.results.return_cnt_accum;             // 누적 반품 수량
            $scope.modify_exchange_stock = data.results.exchange_cnt_accum;         // 누적 교환 수량
            $scope.modify_unrel_stock = data.results.unavail_out_cnt;               // 미출고 수량

            // 수정 전 모델번호
            $scope.prodData.origin_model_no = $scope.prodData.model_no;
          } else {
            commonSVC.showToaster('error', '', 'SKU상품 상세조회에 실패 했습니다.');
          }
        });
      }

      // 초기 툴팁 세팅
      $timeout(function () {
        // 템플릿 적용 조건
        $scope.header_template_set = $rootScope.affName === 'LG전자' && $rootScope.userProfileCheck('sol_ser', 'super_user', 'like');     // 슈퍼계정
        // || ($rootScope.user_profile.pa_sol_no && !$scope.prodData.ori_prod_no);                     // 가져온 상품이 아닐때 적용

        // 템플릿 해제 조건
        $scope.header_template_release = $rootScope.affName === 'LG전자' && $rootScope.userProfileCheck('sol_ser', 'super_user', 'like') // 슈퍼계정
          || ($scope.prodData.ori_prod_no && $scope.prodData.sub_off_avail_yn);                        // 가져온 상품 해제여부
        // || ($rootScope.user_profile.pa_sol_no && !$scope.prodData.ori_prod_no);                     // 가져온 상품이 아닐때

        $scope.renderHtml('header_content');
        $scope.renderHtml('footer_content');
        $('[data-popup="tooltip"]').tooltip();

        // 수정 불가 시 전부 비활성화 처리
        if ($scope.nonEdit) {
          $('#prodform_detail input').prop('disabled', true);
          $('#prodform_detail select').attr('disabled', true);
          $('#prodform_detail button').attr('disabled', true);
          $('#prodform_detail textarea').attr('disabled', true);
          $('#prodform_detail #btn-close').attr('disabled', false);
          $('#prodform_detail .close').attr('disabled', false);
        }
      }, 100);
    }

    // 속성 값 셋팅 ( encode : opt1 에 값 넣어주기 , decode : opt1~3 값 prodData.optArray 에 넣어주기 )
    function settingOptArray(mode) {
      if (mode === 'encode') {
        // optArray를 opt1_type, opt2_type ... 으로 변경
        _.each($scope.optArray, function (opt, index) {
          if ($scope.data.opt_type === 'one' && $scope.detailType === 'add' && opt.type == '직접입력') {
            opt.custom_type = opt.name;
          }
          $scope.prodData[`opt${index + 1}_type`] = opt.type === '직접입력' ? opt.custom_type.trim() : opt.type;
          $scope.prodData[`opt${index + 1}`] = opt.value;
        });
      } else {
        // opt1_type, opt2_type ... 를 optAtrray로 변경
        if ($scope.prodData.options.length) {
          for (let index = 0; index < $scope.prodData.options.length; index++) {
            $scope.optArray[index] = {};
            $scope.optArray[index].type = $scope.prodData.options[index].attri_type;
            $scope.optArray[index].value = $scope.prodData.options[index].attri;
            //빽업 데이터에도 추가
            oldData[`opt${index + 1}_type`] = $scope.prodData.options[index].attri_type;
            oldData[`opt${index + 1}`] = $scope.prodData.options[index].attri;

            if ($scope.optTypes.indexOf($scope.prodData.options[index].attri_type) < 0) {
              $scope.optArray[index].type = '직접입력';
              $scope.optArray[index].custom_type = $scope.prodData.options[index].attri_type;
            }
          }
        }
      }
    }

    // 원산지 값 세팅 ( encode : madeIn 에 값 넣어주기 , decode : madeIn에 값 prodData 에 넣어주기 )
    function settingMadein(mode) {
      if (mode === 'encode') {
        if ($scope.prodData.madein_type !== '기타' && $scope.prodData.madein_type !== '') {
          const madein_list = $scope.madeinList[$scope.prodData.madein_type][$scope.prodData.madein_group];

          for (const i in madein_list) {
            if (madein_list[i].detail == $scope.prodData.madein_name) {
              $scope.prodData.madein_no = madein_list[i].number;
              $scope.prodData.madein_etc = null;
            }
          }
        } else {
          $scope.prodData.madein_no = null;
        }
      } else {
        // 조건 수정 2018-01-17 rony
        // if($scope.prodData.madein_etc != 'null'){
        if (!$scope.prodData.madein_group && ($scope.prodData.madein_type === 'null' || $scope.prodData.madein_type === null || $scope.prodData.madein_type === '')) {
          $scope.prodData.madein_type = oldData.madein_type = $scope.prodData.madein_etc ? '기타' : '';
        }
        $scope.madein_type_change();
        $scope.madein_country_change();
      }
    }

    function addProdAfterAction(result) {
      const title = 'SKU상품 등록 성공';
      const contents = `SKU상품 ${result.success}건 등록이 완료되었습니다.`;
      // var count = result.success+'건 성공';
      // if(result.fail > 0) count += ' / '+result.fail + '건 실패';
      // var contents = 'SKU상품 [' + count + ']. 이후 작업을 선택해주세요';
      const buttons = [
        {
          name: '다른 상품 등록',
          next: function () {
            $timeout(function () {
              init();
            });
          }
        },
        {
          name: '닫기',
          next: function () {
            // 모달로 열린경우.
            if ($scope.isModal) {
              $scope.cancel();
            } else {
              $rootScope.$emit('$closeTab', 'tab_prod_product_add');
              $rootScope.$broadcast('prodModifySuccess');
            }
          }
        }
      ];

      commonSVC.showMultiButton(title, contents, buttons);

      // 2018-01-11 chris 리스트 새로고침을 위한 이벤트 추가
      $rootScope.$broadcast('reloadProdProductList');
    }

    /**
     * 유효일자 설정
     */
    function settingExpire() {
      let retValue = true;

      if ($scope.prodData.expiration_month == '52') { $scope.prodData.expire_date = null; }
      else if ($scope.prodData.expiration_month) {

        if ($scope.prodData.made_date === null || $scope.prodData.made_date === '') {
          retValue = false;
        } else {
          const expireDateForm = $scope.prodData.made_date.split('-');
          let expireDate = new Date(expireDateForm[0], expireDateForm[1] - 1, expireDateForm[2]);

          expireDate = expireDate.setMonth(expireDate.getMonth() + Number($scope.prodData.expiration_month));
          $scope.prodData.expire_date = moment(expireDate).format('YYYY-MM-DD');
        }
      }

      return retValue;
    }

    /**
     *  현제화면 제일 아래로 스크롤 이동
     * 2018-03-29 rony
     */
    $scope.viewDown = function () {
      $location.hash('pageBottom');
      $anchorScroll();
    };

    // 헤더푸터 템플릿
    $scope.addcontentRule = () => {
      const size = 'lg-md',
            ctrl = 'MasAddcontentRuleCtrl',
            html = 'views/online/product/modals/mas_addcontent_rule.html',
            resolve = {
              data: {
                addcontentNo: $scope.prodData.mas_addcontent_no
              }
            };

      const modal = commonSVC.openModal(size, resolve, ctrl, html, false);

      modal.result.then((result) => {
        $scope.prodData.mas_addcontent_no = result.no;
        $scope.prodData.addcontent_name = result.name;
        $scope.prodData.header_content = result.header_content;
        $scope.prodData.footer_content = result.footer_content;
        $scope.renderHtml('header_content');
        $scope.renderHtml('footer_content');
        $scope.searchDo(true);
      });
    };

    $scope.addcontentDelete = () => {
      $scope.prodData.mas_addcontent_no = '';
      $scope.prodData.addcontent_name = '';
      $scope.prodData.header_content = '';
      $scope.prodData.footer_content = '';
      $scope.renderHtml('header_content');
      $scope.renderHtml('footer_content');
    };

    $scope.renderHtml = (id) => {
      $(`#${id}`).html($scope.prodData[id]);
    };

    $scope.addcontentApply = function () {
      const params = {
        data: {
          type: 'prod',
          returnYn: true,
        }
      };

      const modal = commonSVC.openModal('full', params, 'MasAddcontentCtrl', 'views/online/product/modals/mas_addcontent.html', false);

      modal.result.then((result) => {
        $scope.prodData.mas_addcontent_no = result.no;
        $scope.prodData.addcontent_name = result.name;
        $scope.prodData.header_content = result.header_content;
        $scope.prodData.footer_content = result.footer_content;
        $scope.prodData.sub_off_avail_yn = result.sub_off_avail_yn;
        $scope.renderHtml('header_content');
        $scope.renderHtml('footer_content');
      });
    };

    /**
     * 원가 세부 설정값 변경 시 원가 변경처리 함수
     */
    $scope.calculateCostPrice = () => {
      $scope.prodData.cost_price = Math.round(costDetailData.reduce((acc, cur) => acc + Number($scope.prodData[cur]), 0) / 10) * 10;
    };

    /**
     * 원가 템플릿 모달 함수
     */
    $scope.openCostTemplateModal = () => {
      const modal = commonSVC.openModal('x3g', { data: { type: '' } }, 'ProdCostTemplateManageCtrl', 'views/prod/product/modals/prod_cost_template.html');
      modal.result.then(async (re) => {
        if (re) {
          $scope.costPriceSettingActive = true;
          try {
            const result = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/stock/prod-cost/template?template_no=${re.data.template_no}`);

            if (result.data?.results) {
              const template_data = result.data?.results?.template_data;

              for (const key in template_data) {
                template_data[key] = Number(template_data[key]);
              }
              $scope.template_name = re?.data.template_name.length > 15 ? re?.data.template_name.slice(0, 15) + '...' : re?.data.template_name;
              delete template_data.template_name;

              costDetailData.forEach(key => {
                $scope.prodData[key] = template_data[key];
              });

              $scope.prodData.cost_price = Math.round(costDetailData.reduce((acc, cur) => acc + Number($scope.prodData[cur]), 0) / 10) * 10;
              $scope.isApplyTemplate = true;

              $timeout(() => {});
            } else {
              throw Error('원가 템플릿 조회에 실패하였습니다.');
            }
          } catch (err) {
            return commonSVC.showToaster('error', '조회 실패', err.message || err.data?.messages?.[0]);
          }
        } else {
          return;
        }

      });
    };

    /**
     * 적용한 템플릿 데이터 초기화
     */
    $scope.resetTemplate = () => {
      return commonSVC.showConfirm('템플릿 해제', '선택한 원가 템플릿을 해제하시겠습니까?\n해제 시 입력된 값이 모두 초기화됩니다.', (confirm) => {
        if (confirm) {
          costDetailData.forEach(key => { $scope.prodData[key] = 0; });

          $scope.prodData.cost_price = 0;

          $scope.isApplyTemplate = false;

          $timeout(() => {});
        }
      });
    };

    // 포장 형태 선택
    $scope.changePkgBox = (pkg_no) => {
      $scope.pkgBoxData = angular.copy($scope.pkgBoxList.find(pkgBox => pkgBox.pkg_no === pkg_no));

      if (!$scope.pkgBoxData) {
        $scope.pkgBoxData = {
          pkg_no: 0,
          max_ea_cnt: 0
        };
      }
    };

  });