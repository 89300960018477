import React, { CSSProperties, useState } from 'react';
import * as imageHostModel from '../../models/imageHosting';
import { showMessage, showToaster, validateFtpAddr } from '../../utills/common';
import HostingFormComponent from '../../components/imageHosting/HostingForm';

interface AddHostingProps {
  registeredFTPNames: string[];
  handleClose: (result?: string) => void;
}

const AddHostingComponent: React.FC<AddHostingProps> = ({ registeredFTPNames, handleClose }) => {
  const [duplicateName, setDuplicateName] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formData, setFormData] = useState({
    ftp_name: '',
    ftp_addr: '',
    ftp_id: '',
    ftp_pw: '',
    remote_directory: '',
    download_directory: '',
    port_no: '21',
    passive_mode_yn: false,
    protocal_type: '자동',
    anonymous_yn: false,
  });

  /**
   * 창닫기
   */
  const handleCancel = () => {
    handleClose();
  };

  // 호스팅 추가
  const handleConfirm = async () => {
    setIsSubmit(true);

    if (formData.ftp_name.trim() && formData.ftp_addr.trim() && validateFtpAddr(formData.ftp_addr) && !duplicateName) {
      try {
        const res = await imageHostModel.addHosting(formData);

        if (res.results === 'success') {
          showToaster('success', '', '호스팅 정보가 추가되었습니다.');
          handleClose('success');
        } else {
          await showMessage('Error', res.error || (res.messages?.length ? res.messages[0] : '호스팅 추가 중 오류가 발생했습니다.'));
        }
      } catch (error: any) {
        await showMessage('Error', error.message || '호스팅 추가 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <>
      <div className="modal-header">
        <button
          type="button"
          className="close" onClick={handleCancel}
        >
          ×
        </button>
        <h5 className="modal-title">호스팅 추가</h5>
      </div>
      <div className="modal-body">
        <p
          className="mb-20"
          style={styles.pageTitle}
        >
          호스팅 추가
        </p>
        {/* 호스팅 정보 입력 폼 */}
        <HostingFormComponent
          registeredFTPNames={registeredFTPNames}
          formData={formData}
          setFormData={setFormData}
          isSubmit={isSubmit}
          duplicateName={duplicateName}
          setDuplicateName={setDuplicateName}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default" onClick={handleCancel}>취소</button>
        <button type="button" className="btn btn-primary" onClick={handleConfirm}>확인</button>
      </div>
    </>
  );
};

const styles: { [key: string]: CSSProperties } = {
  pageTitle: {
    fontSize: '14px',
    color: '#333',
    fontWeight: 600,
  },
  formGroupBox: {
    display: 'flex',
    alignItems: 'center',
  },
  formWidthAuto: {
    flex: 1,
  },
  formWidthFull: {
    width: '100%',
  },
  formWidthFix: {
    minWidth: '100px',
  },
  labelSub: {
    color: '#777',
  },
  fileAttachBtn: {
    margin: '0 0 0 10px',
    display: 'inline-block',
    width: '36px',
    height: '36px',
    border: '1px solid #ddd',
    cursor: 'pointer',
  },
  fileAttachIcon: {
    display: 'inline-block',
    width: '100%',
    height: '100%',
    background: 'url(../../assets/images/imagehosting/ico_fileattach.svg) no-repeat center',
  },
};

export default AddHostingComponent;
