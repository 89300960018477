'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-B378-Ctrl', function ($scope, $rootScope, $timeout, shopAccountModel, onlineProductModel, commonModel, commonSVC, systemList, templateList, data, $uibModalInstance) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.detailType = _.cloneDeep(data.data.detailType);
    $scope.noti_data_phoneFlag = data.data.prod_noti_data_new.filter(data => data && data.infoCode === 'CellPhone2023').length;
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.row.etc_files = $scope.row.etc_files || [];

    // disabled된 항목 있는지 여부
    let hasDisabled = false;

    $timeout(() => {
      hasDisabled = !!$('div[ng-include] input[ng-model][ng-disabled], div[ng-include] select[ng-model][ng-disabled], div[ng-include] button[ng-model][ng-disabled]')
        .filter((idx, selector) => $(selector).attr('disabled') === 'disabled').length;
    }, 500);

    // 쿠팡 추가항목 변경으로 만약 값이 없으면 기본값 설정
    if (!$scope.row.set_info.hasOwnProperty('key_expiry_date_yn')) {
      $scope.row.set_info.key_expiry_date_yn = false;
    }

    // 파일삭제를 위해서는 저장된 파일의 순번이 필요하기 떄문에 순번 추출.
    if ($scope.row.etc_files[0]) {
      _.each($scope.row.etc_files[0], function (file_v, key) {
        if (key) {
          _.each($scope.row.set_info, function(v, k) {
            if (v === file_v.files.name) {
              if (k.split('_').length > 3) {
                file_v.files.idx = k.split('_')[2];
              } else {
                file_v.files.idx = 0;
              }
            }
          });
        }
      });
    }

    $scope.prodInfoDesc = (type) => {
      if ($scope.row.set_info[type] === 'Y') {
        $scope.row.set_info.key_mobile_reg_condition = '[상세설명참조]';
      } else {
        $scope.row.set_info.key_mobile_reg_condition = '';
      }
    };

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      if ($scope.noti_data_phoneFlag && $scope.row.set_info.key_mobile_reg_condition === '') {
        commonSVC.showMessage('이동통신 가입조건을 입력해 주시길 바랍니다.');

        return false;
      }

      if ($scope.row.set_info.name_short_type == 'etc' && !$scope.row.set_info.name_short) {
        commonSVC.showMessage('제품명을 확인하세요.');

        return false;
      }

      if ($scope.row.set_info.key_nchoice == '1' && !$scope.row.set_info.key_nprice) {
        commonSVC.showMessage('할인율 기준가를 확인하세요.');

        return false;
      }
      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    //기타인증서류
    // lucas : 기타인증서류는 계정 안에 있어야 하는 것 같음
    // $scope.data.etc_files = [{url:''}];

    //할인기준가 초기값 확인
    if ($scope.row.set_info.key_nprice) {
      $scope.row.set_info.key_nchoice = '1';
    } else {
      $scope.row.set_info.key_nchoice = '0';
    }

    $scope.npriceEtcFn = function(type) {
      if (type === '0') {
        $scope.row.set_info.key_nprice = '';
      }
    };

    $scope.nameShortTypeFn = function(type) {
      if (type == 'ol_name') {
        $scope.row.set_info.name_short = '';
      }
    };
    //기타인증서류 추가
    $scope.addFile = function(file_type) {
      if ($scope.row.etc_files[file_type].length < 20) {
        $scope.row.etc_files[file_type].push({});
      } else {
        commonSVC.showMessage('최대 20개까지 업로드가 가능합니다.');
      }
    };

    //기타인증서류 삭제
    $scope.deleteFile = function(idx, file_idx, file_type) {
      let num;

      if (file_type === 0) {
        num = '8';
      } else {
        num = '4';
      }
      if (file_idx === 0) {
        delete $scope.row.set_info[`key_safeInfoImg${num}_file`];
        delete $scope.row.set_info[`key_safeInfoImg${num}_filename`];
        delete $scope.data.set_info[`key_safeInfoImg${num}_file`];
        delete $scope.data.set_info[`key_safeInfoImg${num}_filename`];
      } else {
        delete $scope.row.set_info[`key_safeInfoImg${num}_${idx + 1}_file`];
        delete $scope.row.set_info[`key_safeInfoImg${num}_${idx + 1}_filename`];
        if ($scope.data.set_info) {
          delete $scope.data.set_info[`key_safeInfoImg${num}_${idx + 1}_file`];
          delete $scope.data.set_info[`key_safeInfoImg${num}_${idx + 1}_filename`];
        }
      }

      // 해당 코드는 삭제가 정상처리 되지않아서 수정합니다 2019-07-15 ruso
      // if (idx === $scope.row.etc_files[file_type].length - 1) {
      //   $scope.row.etc_files[file_type].pop();
      // } else {
      //   $scope.row.etc_files[file_type][idx] = {};
      // }

      $scope.row.etc_files[_.isUndefined(file_type) ? 1 : 0].splice(idx, 1);
    };

    //기타인증서류 업로드
    $scope.uploadFile = function (file, idx, file_type) {
      const files = [file.files];

      // # S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function (res) {
          let uploaded = false;
          let num, key;

          if (file_type === 0) {
            num = '8';
          } else {
            num = '4';
          }

          if (idx === 0) {
            key = `key_safeInfoImg${num}_file`;
          } else {
            const j = idx + 1;

            key = `key_safeInfoImg${num}_${j}_file`;
          }
          $scope.row.set_info[key] = res.data.results[0].url;
          $scope.row.set_info[`${key}name`] = res.data.results[0].origFileName;
          uploaded = true;
          if (!uploaded) {
            commonSVC.showToaster('error', '', '파일 업로드에 실패 하였습니다.');
          }
        });
    };

    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        if (hasDisabled) {
          $scope.row.set_info_template_no = '';
          commonSVC.showMessage('수정 불가능한 항목이 있는 경우 양식을 사용할 수 없습니다.\n값을 직접 수정해주십시오.');

          return false;
        }

        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        // 인증서류
        const fileKeys = ['safeInfoImg8', 'safeInfoImg4'];

        const etc_files = [[], []];

        for (const idx in fileKeys) {
          const fileKey = fileKeys[idx];

          for (let i = 20; i > 1; i--) {
            const key = `key_${fileKey}_${i}_filename`;

            if (template.template_data[key] == undefined) {
              continue;
            }

            if (template.template_data[key]) {
              etc_files[idx].unshift({ files: { name: template.template_data[key] } });
            } else {
              etc_files[idx].unshift({});
            }

            if (fileKey === 'safeInfoImg4') {
              break;
            }
          }
          if (template.template_data[`key_${fileKey}_file`]) {
            etc_files[idx].unshift({ files: { name: template.template_data[`key_${fileKey}_filename`] } });
          } else {
            etc_files[idx].unshift({});
          }
        }

        $scope.row.etc_files = etc_files;
        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType,
          hasDisabled
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
          hasDisabled
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

  }
  );
