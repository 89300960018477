/**
 *
 *  2016-11-29 김승호
 */
'use strict';
angular.module('gmpApp')
  .controller('StockInventoryListCtrl', function ($scope, $filter, commonSVC, $compile, $state, $q, supplierList,
    warehouseList, inventorySVC, settings, productModel, supplierModel, warehouseModel, categoryModel, systemModel, inventoryModel,
    gettextCatalog, $timeout, $rootScope, systemList, userInfo, memoTemplateList) {
    $scope.memoTemplateList = memoTemplateList.data.results || [];
    $scope.searchForm = angular.copy(inventorySVC.searchForm);
    // 설정에서 검색 기간 변수 읽어서 강제로 sdate에 넣어줌 - service 수정 전까지는 이렇게 해야 할 듯
    $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
    $scope.searchData = angular.copy(inventorySVC.searchData);

    $scope.countList = { real_stock_none: 0, safe_stock_alert: 0 };
    $scope.selectCount = 'all';
    const supplier = angular.copy(supplierList.data.result);
    supplier.unshift({ supp_no: 'null', supp_name: '매입처 없음', use_yn: 1 }); // 매입처 없음 필터 추가

    // 조회된 그리드의 row 정보 담는 변수 2018-01-18 rony
    const prodListRow = [];
    // 재고 일괄 수정에서 조회를 위해 필요한 데이터
    let sendSearchForm = {};

    let searchDetail = [
      {
        title: gettextCatalog.getString('매입처 선택'),
        search_name: 'supplier_vendor',
        item_list: Array.from(supplier) || [],
        item_key: 'supp_name', // 2018-09-09 Jacob 상세검색에서 매입처 선택시 undefined 오류 수정
        item_value: 'supp_no',
        select_value: '',
        add_class: 'select-search'
      },
      {
        title: gettextCatalog.getString('배송처 선택'),
        search_name: 'delivery_vendor',
        item_list: warehouseList.data.result || [],
        item_key: 'warehouse_name',
        item_value: 'code',
        select_value: '',
        add_class: 'select-search'
      },
      {
        title: gettextCatalog.getString('연동상품여부 선택'),
        search_name: 'linkage_flag', item_list: [{
          code: 'Y', name: '연동상품 있음' }, {
          code: 'N', name: '연동상품 없음' }],
        item_key: 'name',
        item_value: 'code',
        select_value: ''
      },
      {
        title: gettextCatalog.getString('연동주문여부 선택'),
        search_name: 'link_ord_yn', item_list: [{
          code: 'Y', name: '연동주문 있음' }, {
          code: 'N', name: '연동주문 없음' }],
        item_key: 'name',
        item_value: 'code',
        select_value: ''
      },
      {
        title: gettextCatalog.getString('다중배송처여부 선택'),
        search_name: 'mutli_depot', item_list: [{
          code: 'N', name: '단일 배송처' }, {
          code: 'Y', name: '다중 배송처' }],
        item_key: 'name',
        item_value: 'code',
        select_value: ''
      },
    ];
    const searchDetailMulti = [
      {
        title: gettextCatalog.getString('배송처 선택'),
        search_name: 'multi_deliver_vendor',
        item_list: warehouseList.data.result || [],
        item_key: 'warehouse_name',
        item_value: 'code',
        search_word: ''
      },
      {
        title: gettextCatalog.getString('매입처 선택'),
        search_name: 'multi_supplier_vendor',
        item_list: supplier || [],
        item_key: 'supp_name',
        item_value: 'supp_no',
        search_word: ''
      }
    ];
    $scope.searchDetailMulti = angular.copy(searchDetailMulti);

    // 배송처일 때, 배송처 검색 조건 제거 #gmpkr-7591 2019-09-18 Jacob
    if ($rootScope.user_profile.auth_type === '배송처') {
      searchDetail = [];
    }

    // 상세 조건검색 리스트
    $scope.searchDetail = angular.copy(searchDetail);
    $scope.searchBtn = {
      actions_right: [
        {
          label: `<i class="icon-cog5"></i>${gettextCatalog.getString('재고할당 규칙')}`,
          small_label: '할당규칙',
          add_class: 'col-xs-6',
          btn_type: 'button',
          test_id: 'btn-stoke-rule',
          action: function() {
            $scope.stockAllocationRule();
          }
        },
        {
          label: `<i class="icon-cog5"></i> ${gettextCatalog.getString('재고 재할당')}`,
          small_label: '재고재할당',
          btn_type: 'button',
          add_class: 'col-xs-6',
          test_id: 'btn-stoke-reassign',
          action: function() {
            $scope.stockReAllocationRule();
          }
        }
      ],
      table_actions: [
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          test_id: 'btn-stock-excel',
          item_list: [
            {
              label: `${gettextCatalog.getString('통합 엑셀 다운')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다.
주문의 모든 메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다." tooltip-placement="right">`,
              menu_tooltip: `원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다. 
주문의 모든 메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다.`,
              test_id: 'btn-excel-intergrate',
              action: function () {
                $scope.downloadIntegratedExcel();
              },
              perm_only: ['prod.product+write']
            }
          ]
        },
        {
          label: gettextCatalog.getString('입고'),
          test_id: 'btn-puton',
          add_class: 'a-btn-blue',
          action: function () {
            $scope.prodRegist('real_in');
          }
        },
        {
          label: gettextCatalog.getString('판매불가'),
          test_id: 'btn-puton',
          add_class: 'a-btn-blue',
          action: function () {
            $scope.prodRegist('real_notsale');
          }
        },
        {
          label: gettextCatalog.getString('출고'),
          test_id: 'btn-puton',
          add_class: 'a-btn-blue',
          action: function () {
            $scope.prodRegist('real_out');
          }
        },
        {
          label: gettextCatalog.getString('재고 일괄 수정'),
          test_id: 'btn-stock-fix',
          add_class: 'a-btn-blue',
          action: function () {
            $scope.prodRegistExcel();
          }
        },
        {
          label: gettextCatalog.getString('재고이동'),
          test_id: 'btn-stock-fix',
          add_class: 'a-btn-blue',
          action: function () {
            prodTransfer();
          }
        }
      ]
    };

    if ($rootScope.user_profile.auth_type === '배송처') {
      $scope.searchBtn.actions_right = [];
    }

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    /**
       * 통합 엑셀 다운로드
       */
    $scope.downloadIntegratedExcel = function () {
      if (commonSVC.checkPermission('stock.roles.excelStock', userInfo.permission) === false) {
        return false;
      }

      const visibleTable = $scope.grid.methods.getColumnsVisible(); // 활성화된 필드 데이터

      const selectedData = $scope.grid.methods.selectedData('all');
      const resolve = {};

      resolve.data = {
        excelFieldList: $scope.excelFieldList,
        title: 'SKU재고 현황',
        url: '/app/stock/base/downIntegrated',
        searchForm: $scope.pageData,
        page: 'sku_depot',
        visibleTable: visibleTable,
        isGlobal: $scope.selectedShopType === 'global',
        progress: true
      };

      if (!selectedData.length) {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
        resolve.data.type = 'all';
      } else {
        const skuCodeList = $scope.grid.methods.selectedData('sku_cd');

        resolve.data.sku_code = skuCodeList;
        resolve.data.count = skuCodeList.length;
        resolve.data.isAll = false;
      }

      commonSVC.openModal('lg', resolve, 'SkuExcelDownIntegratedCtrl', 'views/prod/product/modals/sku_excel_down_integrated.html');
    };

    /**
      * 배송지연
      * */
    $scope.delayedOrder = function () {
      const seleted = $scope.grid.methods.selectedData('number');

      if (seleted.length === 0) {
        commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

        return false;
      }

      const resolve = {};

      resolve.data = {
        selectList: $scope.grid.methods.selectedData('all')
      };

      commonSVC.openModal('', resolve, 'delayDeliveryCtrl', 'views/order/shipment/modals/delay_delivery.html');
    };

    //------------------------
    // 함수
    //------------------------

    /**
      * 연동상품 모달창
      * */
    $scope.linkageModalOpen = function (sku_code, prod_no, depot_no, depot_name, link_yn, link_set_yn) {
      const resolve = {};

      resolve.data = {
        code: sku_code,
        prod_no,
        depot_no,
        depot_name,
        link_yn,
        link_set_yn
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };

      commonSVC.openModal('xxg', resolve, 'ProdProductLinkageCtrl', 'views/prod/product/modals/product_linkage.html');
    };

    // 주문리스트 모달 열기
    $scope.openOrderList = function(sku_code) {
      const templatePath = 'views/stock/inventory/order_list.html';

      const resolve = {
        resolve: {
          sku_code: sku_code
        }
      };

      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      resolve.systemList = function(systemModel) {
        return systemModel.load();
      };

      commonSVC.openModal('full', resolve, 'StockInventoryOrderListCtrl', templatePath);

    };

    /**
      * 재고할당/재고재할당 규칙 모달 열기
      */
    $scope.openAllocRuleList = function(prod_no, depot_no, real_stock, stock_cnt, realloc_yn) {
      const ctrl = realloc_yn ? 'SetStockReAllocChannelRuleCtrl' : 'SetStockAllocationChannelRuleCtrl';
      const template = realloc_yn ? 'views/stock/inventory/set_stock_reallocation_channel_rule.html' : 'views/stock/inventory/set_stock_allocation_channel_rule.html';
      const data = {
        prod_no,
        depot_no,
        real_stock,
        stock_cnt
      };

      commonSVC.openModal('xxg', { data: data }, ctrl, template);
    };

    /**
      * 조회 버튼 클릭시 처리
      */
    $scope.searchDo = function (refresh, noDelay) {
      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);

      // init()이 하는 집계 조회를 다른 컨트롤러처럼 데이터 조회시 같이 불러오게함. init() 필요없어서 주석처리 2018-11-29 Amelia
      // init();
    };

    /**
      * 검색 초기화
      */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(inventorySVC.searchForm);
      $scope.searchData.search_key_name = '전체';
      $scope.selectCount = 'all';
      $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      $scope.searchDetail = angular.copy(searchDetail);
      $scope.searchData.search_word = '';
      $scope.searchDo(true, true);
    };

    /**
      * 데이터테이블 pageLength 조절
      */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
      * 상세페이지 보여주기
      */
    $scope.showDetail = function(show, sku_cd, prod_number) {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          detailType: 'edit',
          fromPage: 'inventory'
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      const modal = commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

      modal.result.then(function () {
        $scope.searchDo(false);
      });
    };
    $scope.notSaleDashboard = function (prod_no, depot_no) {
      const resolve = {
        data: {
          prodData: prodListRow[`${prod_no}_${depot_no}`]
        }
      };

      commonSVC.openModal('', resolve, 'NotSaleDashboardCtrl', 'views/prod/product/modals/notsale_dashboard.html');

    };
    /**
      * 재고 일괄 수정
      * */
    $scope.prodRegist = function (type) {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      $timeout(function() {

        const skuCodeList = $scope.grid.methods.selectedData('sku_cd');

        if (skuCodeList.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 상품이 없습니다.'));

          return false;
        }

        const resolve = {};

        inventoryModel.stockAllocGetType({}, function(state, data) {
          resolve.data = {
            prodList: $scope.grid.methods.selectedData('all'),
            searchForm: sendSearchForm,
            skuCodeList: skuCodeList,
            type: type,
            alloc_type: data.result[0].allocation_type
          };

          const size = ['real_in', 'real_out', 'real_notsale'].includes(type) ? 'xl' : '';
          const modal = commonSVC.openModal(size, resolve, 'ProdProductStockCtrl', 'views/prod/product/modals/modify_stock.html');

          modal.result.then(function () {
            $scope.searchDo(false);
          });
        });

      }, 300);
    };

    /**
      * 재고 일괄 수정
      * */
    $scope.prodRegist2 = function (prod_no, depot_no, sku_code, type) {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      // 배송처인경우 재고 일괄수정 막음.
      if (userInfo.user.auth_type === '배송처') {
        commonSVC.PermissionDenyAlert();

        return false;
      }

      const prod_no_arr = [prod_no];
      const prodList_arr = [prodListRow[`${prod_no}_${depot_no}`]];

      inventoryModel.stockAllocGetType({}, function(state, data) {
        const resolve = {
          data: {
            prodNoList: prod_no_arr,
            prodList: prodList_arr,
            type: type,
            skuCodeList: $scope.grid.methods.selectedData('sku_cd'),
            alloc_type: data.result[0].allocation_type
          }
        };

        const size = type === 'real' ? 'xl' : '';
        const modal = commonSVC.openModal(size, resolve, 'ProdProductStockCtrl', 'views/prod/product/modals/modify_stock.html');

        modal.result.then(function () {
          $timeout(function() {
            $scope.searchDo(false);
          }, 500);
        });
      });
    };

    /**
      * 재고 일괄 수정 (EXCEL)
      * */
    $scope.prodRegistExcel = function () {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const skuCodeList = $scope.grid.methods.selectedData('sku_cd');

      const resolve = {};

      resolve.data = {
        prodNoList: $scope.grid.methods.selectedData('prod_no'),
        prodList: $scope.grid.methods.selectedData('all'),
        skuCodeList: skuCodeList,
      };

      const modal = commonSVC.openModal('', resolve, 'ProdStockExcelUploadCtrl', 'views/prod/product/modals/stock_excel_upload.html');

      modal.result.then(function () {
        $scope.searchDo(true);
      });
    };

    /**
      * 재고할당 규칙 모달창
      */
    $scope.stockAllocationRule = function () {
      inventoryModel.stockAllocGetType({}, function(state, data) {
        const resolve = {
          data: {
            alloc_type: data.result[0].allocation_type,
            alloc_type_last_modify_time: data.result[0].alloc_type_last_modify_time
          }
        };

        commonSVC.openModal('x3g', resolve, 'StockAllocationRuleCtrl', 'views/stock/inventory/stock_allocation_rule.html');
      });
    };

    /**
      * 재고재할당 규칙 모달창
      */
    $scope.stockReAllocationRule = function () {
      inventoryModel.stockAllocGetType({}, function(state, data) {
        const resolve = {
          data: {
            alloc_type: data.result[0].allocation_type,
            alloc_type_last_modify_time: data.result[0].alloc_type_last_modify_time
          }
        };

        commonSVC.openModal('x3g', resolve, 'StockReAllocationRuleCtrl', 'views/stock/inventory/stock_reallocation_rule.html');
      });
    };

    $scope.initSelectRowUniqList = () => {
      $scope.selectRowUniqList = [];
    };

    /**
      * 카운트 검색
      * */
    $scope.countSearch = function(type) {
      $scope.initSelectRowUniqList();
      //  다른 컨트롤러처럼 데이터 0일때도 선택가능하게 처리 2018-11-29 Amelia
      if (type !== 'total') {
        switch (type) {
          case 'all': // 전체
            $scope.searchForm.state = '';
            break;
          case 'ok_stock': // 정상
            $scope.searchForm.state = 1;
            break;
          case 'safe_stock_alert': // 재고부족
            $scope.searchForm.state = 3;
            break;
          case 'real_stock_none': // 품절
            $scope.searchForm.state = 2;
            break;
        }
        $scope.searchDo(true, true);
        $scope.selectCount = type;
      }
    };

    // SKU상품 수정시 리스트에서 바뀐 정보 보여주기 위해 다시 그려줌
    $scope.$on('prodModifySuccess', function() {
      $scope.searchDo();
      $scope.grid.methods.autoSelect(true);
    });

    // 상세보기시 선택한 데이터 보여주기
    $scope.$on('OnSelectChange', function(event, data) {
      if ($scope.isDetailShow) {
        $state.go('main.stock_inventory_list.detail', { detailType: 'edit', rowIndex: data.selectIndex, sku: data.selectData.sku_cd, number: data.selectData.number });
      }
    });

    // 상세보기 닫기 눌렀을 시에
    $scope.$on('OnCloseDetail', function() {
      $scope.showDetail(null, false);
      // 2017-03-07 MatthewKim $state.go 옵션을 추가해서 파라미터 상속 false, 알림 false 로 이동하여 화면 이동을 시키게 함
      $state.go('main.stock_inventory_list', null, { inherit: false, notify: false });
    });

    // 테이블 컬럼 사이즈 확인
    $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
      if (toState.name == 'main.prod_product_list') {
        const $wrapper = $('#prod_prodlist_grid_wrapper');
        const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

        if ($tableHeader.width() < 1) {
          $timeout(function () {
            $scope.grid.methods.calcLayout('set_width');
          }, 0);
        }
      }
    });

    $scope.selectRowUniqList = [];

    $scope.isMemoDisabled = (prod_no) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(prod_no);

    // 데이터 테이블에서 선택한 rowList 정보 받아옴
    $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
      $scope.selectRowUniqList = data.map(({ prod_no }) => prod_no);
      $scope.$apply();
    }, 200));

    /**
      * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
      */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'stock_status', 'link_yn', 'link_ord_yn', 'alloc_rule_yn', 'realloc_rule_yn'],
      defaultSortingColumns: ['sku_cd'],
      alignRightColumns: [
        'stock_cnt',
        'stock_cnt_real',
        'stock_cnt_safe',
        'notsale_cnt',
        'in_cnt_accum',
        'exchange_cnt_accum',
        'out_cnt_accum',
        'out_scheduled',
        'sale_cnt_accum',
        'return_cnt_accum',
        'cancel_cnt_accum',
        'unavail_out_cnt',
        'sale_price',
        'cost_price',
        'supply_price',
        'in_unit_cnt'
      ],
      notSortingColumns: ['widget', 'return_cnt_accum'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: [
        'in_unit',
        'brand',
        'maker',
        'sale_price',
        'cost_price',
        'supply_price',
        'supp_name',
        'barcode',
        'made_date'
      ],

      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/status`,
        requestWillAction: function (data) {
          if ($rootScope.side_search.page?.includes('inventory') && $rootScope.side_search.search) {
            $scope.searchForm.date_type = $rootScope.side_search.date_type;
            $scope.searchForm.sdate = $rootScope.side_search.sdate;
            $scope.searchForm.edate = $rootScope.side_search.edate;
            $scope.selectCount = $rootScope.side_search.selectCount || 'all';
            $scope.searchForm.state = $rootScope.side_search.status;
            $rootScope.side_search.search = false;
          }

          if ($rootScope.noti_search.page === 'inventory' && $rootScope.noti_search.search) {
            $scope.searchForm.edate = $rootScope.noti_search.edate;
            $scope.searchForm.search_word = $rootScope.noti_search.value;
            $scope.searchForm.search_key = $rootScope.noti_search.key;
            $scope.searchData.search_key_name = $rootScope.noti_search.key_name;

            $rootScope.noti_search.search = false;
          }

          data = angular.merge({}, data, $scope.searchForm);

          if ($rootScope.user_profile.auth_type === '배송처') {
            data.delivery_vendor = $rootScope.user_profile.depot_no;
          }

          $scope.pageData = angular.copy(data); //엑셀 모달용 데이터
          sendSearchForm = angular.copy(data);

          return data;
        },
        requestDidAction: function(result) {
          $scope.prodListRow = result.results;
          $scope.searchData.totalCount = result.recordsTotal;

          // 집계카운트
          $scope.countList = {
            all_stock: result.all_stock,
            ok_stock: result.ok_stock,
            real_stock_none: result.real_stock_none,
            safe_stock_alert: result.safe_stock_alert
          };

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          className: 'tool text-center',
          template: function(row) {
            return (
              `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(true,'${row.sku_cd}','${row.prod_no}')" >상세</button>
               <button ng-disabled="grid.appScope.isMemoDisabled(${row.prod_no})" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs dt-select-do" ng-click="grid.appScope.leftTabAction(2)">메모</button>`
            );
          }
        },
        {
          key: 'stock_status',
          title: '상태',
          width: 80
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 120
        },
        {
          key: 'stock_cd',
          title: '재고관리코드',
          width: 120
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 120
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 270,
          notCompile: true,
          template: function(row) {
            const img = row.prod_img || '/assets/images/upload.png';

            return `<img src='${img}' width='25' height='25' class='mr-10'>${row.prod_name}</span>`;
          }
        },
        { key: 'attri', title: '속성', width: 100 },
        {
          key: 'stock_cnt',
          title: '판매가능재고',
          width: 100
        },
        {
          key: 'stock_cnt_real',
          title: '실재고',
          width: 90,
          template: function(row) {
            prodListRow[`${row.prod_no}_${row.depot_no}`] = row;

            return `<a ng-click="grid.appScope.prodRegist2('${row.prod_no}', '${row.depot_no}', '${row.sku_cd}','real_stock_setting')">${row.stock_cnt_real}</a>`;
          }
        },
        {
          key: 'stock_cnt_safe',
          title: '안전재고',
          width: 90,
          template: function(row) {
            return `<a ng-click="grid.appScope.prodRegist2('${row.prod_no}', '${row.depot_no}', '${row.sku_cd}','safe_stock_setting')">${row.stock_cnt_safe || 0}</a>`;
          }
        },
        {
          key: 'notsale_cnt',
          title: '판매불가 재고',
          width: 90,
          template: function(row) {
            return `<a ng-click="grid.appScope.notSaleDashboard('${row.prod_no}', '${row.depot_no}')">${row.notsale_cnt || 0}</a>`;
          }
        },
        {
          key: 'in_cnt_accum',
          title: '누적입고수량',
          width: 90
        },
        {
          key: 'exchange_cnt_accum',
          title: '누적교환수량',
          width: 90
        },
        {
          key: 'out_cnt_accum',
          title: '누적출고수량',
          width: 90
        },
        {
          key: 'out_scheduled',
          title: '출고예정수',
          width: 90,
          notCompile: true,
          template: function(row) {
            return Math.abs(row.out_scheduled);
          }
        },
        {
          key: 'sale_cnt_accum',
          title: '누적구매수량',
          width: 90
        },
        {
          key: 'cancel_cnt_accum',
          title: '누적취소수량',
          width: 90
        },
        {
          key: 'return_cnt_accum',
          title: '누적반품수량',
          width: 90
        },
        {
          key: 'unavail_out_cnt',
          title: '미출고',
          width: 90,
          template: function(row) {
            return row.unavail_out_cnt;
          }
        },
        {
          key: 'link_yn',
          title: '연동상품',
          width: 100,
          template: function(row) {
            const linkIcon = row.link_yn || row.link_set_yn
              ? `<a ng-click="grid.appScope.linkageModalOpen('${row.sku_cd}', '${row.prod_no}', '${row.depot_no}', '${row.depot_name}', ${!!row.link_yn}, ${!!row.link_set_yn})">보기</a>`
              : '<span class="text-grey-100">보기</span>';

            return linkIcon;
          }
        },
        {
          key: 'link_ord_yn',
          title: '연동주문',
          width: 90,
          template: function(row) {
            const linkIcon = row.link_ord_yn
              ? `<a ng-click="grid.appScope.openOrderList('${row.sku_cd}')">보기</a>`
              : '<span class="text-grey-100">보기</span>';

            return linkIcon;
          }
        },
        {
          key: 'bundle_avail_yn',
          title: '합포장 여부',
          width: 100,
          template: function(row) {
            return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
          }
        },
        {
          key: 'in_unit_cnt',
          title: !$rootScope.osse_sol ? '입고단위' : '낱본 수량',
          width: 100,
          template: function(row) {
            if (row.in_unit_cnt) {
              return !$rootScope.osse_sol ? (row.in_unit_cnt + row.in_unit) : row.in_unit_cnt;
            } else {
              return '';
            }
          }
        },
        {
          key: 'brand',
          title: '브랜드',
          width: 100
        },
        {
          key: 'maker',
          title: '제조사',
          width: 100
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 90,
          notCompile: true,
          template: function(row) {
            return $filter('currency')(row.sale_price, '', 0);
          }
        },
        {
          key: 'cost_price',
          title: '원가',
          width: 90,
          notCompile: true,
          template: function(row) {
            return $filter('currency')(row.cost_price, '', 0);
          }
        },
        {
          key: 'supply_price',
          title: '공급가',
          width: 90,
          notCompile: true,
          template: function(row) {
            return $filter('currency')(row.supply_price, '', 0);
          }
        },
        {
          key: 'supp_name',
          title: '매입처',
          width: 90
        },
        {
          key: 'barcode',
          title: '바코드',
          width: 100
        },
        {
          key: 'made_date',
          title: '제조일자',
          width: 100,
          filter: 'dateValid'
        },
        {
          key: 'expire_date',
          title: '유효일자',
          width: 100,
          filter: 'dateValid'
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'alloc_rule_yn',
          title: '재고할당',
          width: 90,
          template: function(row) {
            return row.alloc_rule_yn
              ? `<i class="icon-primitive-dot text-success-600 cursor-pointer" ng-click="grid.appScope.openAllocRuleList(${row.prod_no},${row.depot_no},${row.stock_cnt_real},${row.stock_cnt}, false)"></i>`
              : '<i class="icon-primitive-dot text-grey-100"></i>';
          }
        },
        {
          key: 'realloc_rule_yn',
          title: '재고재할당',
          width: 90,
          template: function(row) {
            return row.realloc_rule_yn
              ? `<i class="icon-primitive-dot text-success-600 cursor-pointer" ng-click="grid.appScope.openAllocRuleList(${row.prod_no},${row.depot_no},${row.stock_cnt_real},${row.stock_cnt}, true)"></i>`
              : '<i class="icon-primitive-dot text-grey-100"></i>';
          }
        }
      ]
    };

    // init()이 하는 집계 조회를 다른 컨트롤러처럼 데이터 조회시 같이 불러오게함. init() 필요없어서 주석처리 2018-11-29 Amelia
    // init();

    // 메모 변경시 메뉴 버튼 색상 변경
    $scope.setMemoBtnActive = function (key, value, off) {
      const index = _.findIndex($scope.prodListRow, (obj) => {
        return obj[key] == value;
      });

      $scope.prodListRow[index].memo_yn = off ? 0 : 1;

      $scope.grid.methods.reDraw();
    };

    // 메모 전부완료시 메뉴 버튼 색상 변경
    $scope.setMemoCompleteActive = function (key, value, off) {
      const index = _.findIndex($scope.prodListRow, (obj) => {
        return obj[key] == value;
      });

      $scope.prodListRow[index].memo_complete_yn = off ? 0 : 1;
      $scope.grid.methods.reDraw();
    };

    // 2018-01-14 chris 상품 등록 & 수정 성공시 리스트 새로고침 추가
    $scope.$on('reloadStockInventoryList', function() {
      $scope.searchDo(true);
    });

    // 알림에서 클릭시 처리 2019-03-25 rony
    $scope.$on('alarmSearch_inventory', function() {
      $scope.searchDo(true, true);
    });

    /**
     * 재고이동
     */
    const prodTransfer = () => {

      // SKU상품수정 권한 확인
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      $timeout(function() {
        const rows = $scope.grid.methods.selectedData('all');

        if (rows.length == 0) {
          commonSVC.showMessage('선택된 상품이 없습니다.');

          return false;
        }

        if (rows.some(o => o.match_depots.split(',').length < 2)) {
          commonSVC.showMessage('다중배송처 상품만 재고이동이 가능합니다.', '상세검색에서 다중배송처 여부를 다중배송처로 검색 후 사용해 주십시오.');

          return false;
        }

        inventoryModel.stockAllocGetType({}, function(state, data) {
          const resolve = {
            data: {
              prod_nos: rows.map(o => {
                return {
                  prod_no: o.prod_no,
                  depot_no: o.depot_no
                };
              }),
              sendSearchForm,
              alloc_type: data.result[0].allocation_type
            },
            warehouseList
          };
          const modal = commonSVC.openModal('xl', resolve, 'StockTransferCtrl', 'views/prod/product/modals/stock_transfer.html');

          modal.result.then(function () {
            $scope.searchDo(false);
          });
        });
      }, 300);
    };
  });