'use strict';

angular.module('gmpApp')
  .controller('AggregationCtrl', function (
    $scope, $rootScope, $timeout, $filter,
    commonSVC, userInfo, addColumnList, settings,
    channelList, warehouseList, supplierList, addColumnAggregationList,
  ) {
    // 권한있는 계정에서 주문집계 북마크 후 권한없는 계정으로 접속 시 탭꺼지도록 수정
    if (!$rootScope.osse_sol) {
      commonSVC.showMessage('권한이 없는 사용자입니다.');
      $rootScope.$emit('$closeTab', 'aggregation');
    }

    const supplier = angular.copy(supplierList.data.result);
    supplier.unshift({ supp_no: 'null', supp_name: '매입처 없음', use_yn: 1 }); // 매입처 없음 필터 추가

    const channelListData = angular.copy(channelList.data.results);
    channelListData.push({ shop_name: '직접입력', shop_cd: 'A000' });

    $scope.maxSpanYears = 1; // 최대 조회 기간 1년
    // 주문집계 초기화
    $scope.totalData = {
      total_pay_amt: 0,
      total_cost_price: 0,
      total_profit: 0,
      total_profit_per: 0,
      total_sale_cnt: 0
    };

    // 조검검색 영역 드랍다운 리스트
    const groupByValue = new Map([
      ['year', '날짜(연)'],
      ['yearmonth', '날짜(연월)'],
      ['yearmonthday', '날짜(연월일)'],
      ['depot_no', '배송처'],
      ['brand', '브랜드'],
      ['shop_cd', '쇼핑몰'],
      ['prod_name', 'SKU상품명']
    ]);
    // 조검검색 필터에 추가항목 있는 경우에는 추가
    if (addColumnList.data.results?.length) {
      addColumnList.data.results.forEach(col => {
        const name = col.col_name?.replace('pa_addcol_', '');

        groupByValue.set(`${col.addcol_no}`, name);
      });
    }

    const getSearchDetailCustomField = () => {
      // 추가항목 리스트 조회
      const addColList = addColumnList.data.results || [];
      // 추가항목 데이터 조회(상세가 아닌 addcol_no에 묶인 전체 데이터)
      const addColDataList = addColumnAggregationList.data;

      if (addColList.length) {
        return addColList.map((col) => ({
          title: col.col_name?.replace('pa_addcol_', ''),
          search_name: `${col.addcol_no}`,
          item_list: _.uniqBy(addColDataList[col.addcol_no], 'col_name').map(c => ({ ...c, use_yn: 1 })).filter(Boolean),
          item_key: `${col.addcol_no}`,
          item_value: 'col_val',
          select_value: '',
          addcol_type: col.input_type,
          add_class: 'select-search'
        }));
      }
    };

    // 서치바 관련 변수
    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(3, 'month').format('YYYY-MM-DD'),
        edate: moment().format('YYYY-MM-DD'),
        pageFrom: 'aggregation'
      },
      conditional_searchDetail: [
        {
          // 집계 구분1 선택
          title: '구분1 필수',
          search_name: 'group_var1',
          item_list: Array.from(groupByValue.keys()).map(key => ({ field: key, title: groupByValue.get(key) })),
          item_key: 'title',
          item_sub_key: 'group_var1',
          item_value: 'field',
          select_value: '',
          add_class: 'select-search'
        },
        {
          // 집계 구분2 선택
          title: '구분2 선택',
          search_name: 'group_var2',
          item_list: Array.from(groupByValue.keys()).map(key => ({ field: key, title: groupByValue.get(key) })),
          item_key: 'title',
          item_sub_key: 'group_var2',
          item_value: 'field',
          select_value: '',
          add_class: 'select-search'
        },
      ],
      searchData: {
        selectCount: 0,      // 검색전체 건수
        totalCount: 0,       // 검색전체 건수
        search_key_items: [  // 검색영역 키값
          { label: '전체', value: 'all' },
          { label: 'SKU코드', value: 'sku_cd' },
          { label: 'SKU상품명', value: 'prod_name' },
          { label: '재고관리코드', value: 'stock_cd' },
          { label: '브랜드', value: 'brand' },
        ],
        search_date_type: [  // 날짜 타입
          { label: '주문 수집일', value: 'wdate' },
          { label: '주문일', value: 'ord_time' },
          { label: '결제 완료일', value: 'pay_time' },
          { label: '상태 변경일', value: 'ord_status_mdate' },
          { label: '발송 예정일', value: 'ship_plan_date' },
          { label: '주문 확인일', value: 'ord_confirm_time' },
          { label: '출고 지시일', value: 'out_order_time' },
          { label: '출고 완료일', value: 'out_time' },
          { label: '송장 전송일', value: 'invoice_send_time' },
          { label: '반품/교환접수요청일', value: 'claim_time' },
          { label: '배송 완료일', value: 'ship_com_time' }
        ]
      },
      aggregation_searchDetail: [
        {
          // 쇼핑몰 선택
          title: '쇼핑몰 선택',
          search_name: 'shop_cd',
          item_list: commonSVC.getSiteList(channelListData),
          item_key: 'shop_name',
          item_sub_key: 'shop_name_kr',
          item_value: 'shop_cd',
          select_value: '',
          add_class: 'select-search'
        },
        {
          // 매입처 선택
          title: '매입처 선택',
          search_name: 'supp_vendor',
          item_list: Array.from(supplier) || [],
          item_key: 'supp_name',
          item_value: 'supp_no',
          select_value: '',
          add_class: 'select-search'
        },
        {
          // 배송처 선택
          title: '배송처 선택',
          search_name: 'delivery_vendor',
          item_list: warehouseList.data.result || [],
          item_key: 'warehouse_name',
          item_value: 'code',
          select_value: '',
          add_class: 'select-search'
        },
      ],
      addColumn_searchDetail: [
      ],
      searchBtn: {
        actions_right: [
          {
            label: '<i class="icon-sync worksvc-spinner-orderSync order"></i><span> 주문 동기화</span>',
            small_label: '동기화',
            test_id: 'btn-async',
            sub_label: '* 건 * 시간전 수집',
            btn_type: 'button',
            add_class: 'col-xs-4 flex-width-1',
            perm_only: ['order.order+write'],
            action: function () {
              $scope.orderSync();
            }
          }
        ],
        table_actions: [
          {
            label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀 다운로드</span>',
            add_class: 'btn-default',
            action: function () {
              $scope.downloadExcel();
            },
            perm_only: ['order.order+write']
          },
        ]
      },
    };

    search.addColumn_searchDetail = getSearchDetailCustomField();

    // 검색영역 폼값 정리
    $scope.searchForm = angular.copy(search.searchForm);

    // 검색영역 데이터
    $scope.searchData = angular.copy(search.searchData);

    // 검색영역 상세검색 설정
    $scope.searchDetail = angular.copy(search.aggregation_searchDetail);

    // 검색영역 버튼 설정
    $scope.searchBtn = angular.copy(search.searchBtn);

    // 조건 검색영역 설정
    $scope.searchDetailConditional = angular.copy(search.conditional_searchDetail);

    // 추가항목 검색영역 설정
    $scope.searchDetailAddcolumn = angular.copy(search.addColumn_searchDetail);

    /**
     * 검색 데이터 테이블 관련기능 바인딩
     */
    $scope.searchFn = {
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      resetDo: function () {
        $scope.resetDo();
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      }
    };

    /**
     * 데이터 테이블 조회
     * @param {*} refresh 새로고침 여부
     * @param {*} noDelay 지연 여부
     * @param {*} resizingHeight 높이 조절
     * @param {*} callback 콜백함수
     * @param {*} isReset 초기화 여부
     */
    $scope.searchDo = function (refresh, noDelay, resizingHeight = null, callback = null, isReset = false) {
      if (!$scope.searchDetailConditional[0].select_value && !isReset) {
        commonSVC.showMessage('조건검색에서 "구분1"을 필수로 선택하세요.');

        return;
      }

      if ($scope.grid.methods.reloadData) {
        resetTotalData();
        $scope.grid.methods.reloadData(callback, refresh, noDelay, resizingHeight);
      }
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      const totalTemp = $scope.searchData.totalCount;

      $scope.searchData.totalCount = totalTemp;
      $scope.searchDetail = angular.copy(search.aggregation_searchDetail);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetailConditional = angular.copy(search.conditional_searchDetail);
      $scope.searchDetailAddcolumn = angular.copy(search.addColumn_searchDetail);
      $scope.searchBtn = angular.copy(search.searchBtn);
      $scope.selectCount = 0;

      resetTotalData();

      $scope.searchDo(true, true, null, null, true);
    };

    /***************************
     * 주문집계 데이터 테이블
     ****************************/
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: ['uid', 'group1', 'group2'],
      alignCenterColumns: ['uid', 'group1', 'group2', 'sale_cnt'],
      defaultSortingColumns: [],
      alignRightColumns: ['pay_amt', 'cost_price', 'profit', 'profit_per'],
      notSortingColumns: ['uid'],
      notResizingColumns: [],
      notMovingColumns: ['uid', 'group1', 'group2'],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/summaries/aggregations`,
        requestWillAction: function(data) {
          data = angular.merge({}, data, $scope.searchForm);

          // 주문집계 엑셀다운로드는 주문 조회 파라미터 그대로 넘겨줘야함
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        },
      },
      columns: [
        {
          key: 'uid',
          title: 'NO',
          width: 50
        },
        {
          key: 'group1',
          title: `${$scope.searchDetailConditional[0].select_value || '구분1'}`,
          width: 100,
          highlight: true
        },
        {
          key: 'group2',
          title: `${$scope.searchDetailConditional[1].select_value || '구분2'}`,
          width: 100,
          highlight: true,
        },
        {
          key: 'pay_amt',
          title: '실결제금액',
          width: 100,
          template: (row) => {
            if (!row.pay_amt) {
              return 0;
            }

            return $filter('currency')(row.pay_amt, '', 0);
          }
        },
        {
          key: 'cost_price',
          title: '원가',
          width: 100,
          template: (row) => {
            if (!row.cost_price) {
              return 0;
            }

            return $filter('currency')(row.cost_price, '', 0);
          }
        },
        {
          key: 'profit',
          title: '매출이익',
          width: 100,
          template: (row) => {
            if (!row.profit) {
              return 0;
            }

            return $filter('currency')(row.profit, '', 0);
          }
        },
        {
          key: 'profit_per',
          title: '매출이익률',
          width: 100,
          template: (row) => {
            return row.profit_per ? customToFixed((row.profit_per).toFixed(4) * 100, 2) : '0.00';
          }
        },
        {
          key: 'sale_cnt',
          title: '매출수량',
          width: 85
        },
      ]
    };

    /**
      * 데이터테이블 pageLength 조절
      */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    $scope.$on('OnSelectChangeBefore', function(event, data) {
      if (data.selectedDatas.length) {
        $scope.totalData = data.selectedDatas.reduce((acc, cur) => {
          acc.total_pay_amt += Math.floor(cur.pay_amt) || 0;
          acc.total_cost_price += Math.floor(cur.cost_price) || 0;
          acc.total_profit += Math.floor(cur.profit) || 0;
          acc.total_sale_cnt += cur.sale_cnt || 0;

          return acc;
        }, {
          total_pay_amt: 0,
          total_cost_price: 0,
          total_profit: 0,
          total_profit_per: 0,
          total_sale_cnt: 0
        });

        const commaRegex = /\B(?=(\d{3})+(?!\d))/g;

        $scope.totalData.total_profit_per = $scope.totalData.total_pay_amt ? customToFixed(parseFloat(($scope.totalData.total_profit / $scope.totalData.total_pay_amt).toFixed(4) * 100), 2) : '0.00';
        $scope.totalData.total_pay_amt = ($scope.totalData.total_pay_amt || 0)?.toString().replace(commaRegex, ',');
        $scope.totalData.total_cost_price = ($scope.totalData.total_cost_price || 0)?.toString().replace(commaRegex, ',');
        $scope.totalData.total_profit = ($scope.totalData.total_profit || 0)?.toString().replace(commaRegex, ',');
      } else {
        $scope.totalData.total_pay_amt = 0;
        $scope.totalData.total_cost_price = 0;
        $scope.totalData.total_profit = 0;
        $scope.totalData.total_profit_per = 0;
        $scope.totalData.total_sale_cnt = 0;
      }
    });

    // 데이터테이블 헤더 업데이트 함수
    function updateDataTableHeader(col_name) {
      let header = '';

      if (col_name === 'group1') {
        header = $scope.searchDetailConditional[0].select_value;
        $scope.grid.methods.changeTitle(
          [
            $scope.grid.options.columns[0].title,
            groupByValue.get(header) || '구분1',
            $scope.grid.options.columns[2].title,
            $scope.grid.options.columns[3].title,
            $scope.grid.options.columns[4].title,
            $scope.grid.options.columns[5].title,
            $scope.grid.options.columns[6].title,
            $scope.grid.options.columns[7].title,
          ]);
      } else {
        header = $scope.searchDetailConditional[1].select_value || '구분2';
        $scope.grid.methods.changeTitle(
          [
            $scope.grid.options.columns[0].title,
            $scope.grid.options.columns[1].title,
            groupByValue.get(header) || '구분2',
            $scope.grid.options.columns[3].title,
            $scope.grid.options.columns[4].title,
            $scope.grid.options.columns[5].title,
            $scope.grid.options.columns[6].title,
            $scope.grid.options.columns[7].title,
          ]);
      }
    }

    // 구분1에서 선택한 값은 구분2 드랍다운에서 비활성화되어야함
    // 반대도 마찬가지!
    $scope.$watch('searchDetailConditional[0].select_value', function(newValue, oldValue) {
      if (newValue !== oldValue) {
        updateDataTableHeader('group1');
      }

      if (newValue) {
        $scope.searchDetailConditional[1].item_list.forEach(item => {
          item.isDisabled = item.field === newValue;
        });
      } else {
        $scope.searchDetailConditional[1].item_list.forEach(item => {
          item.isDisabled = false;
        });
      }
    });

    $scope.$watch('searchDetailConditional[1].select_value', function(newValue, oldValue) {
      if (newValue !== oldValue) {
        updateDataTableHeader('group2');
      }
      if (newValue) {
        $scope.searchDetailConditional[0].item_list.forEach(item => {
          item.isDisabled = item.field === newValue;
        });
      } else {
        $scope.searchDetailConditional[0].item_list.forEach(item => {
          item.isDisabled = false;
        });
      }
    });

    /**
     * 주문집계 엑셀 다운로드
     */
    $scope.downloadExcel = () => {
      const resolve = {};

      if (!$scope.searchData.totalCount) {
        commonSVC.showMessage('다운로드 할 주문이 없습니다.');

        return;
      }
      resolve.data = {
        type: 'order_aggregation',
        excelFieldList: [
          { header: `${groupByValue.get($scope.pageData.group_var1) || '구분1'}`, key: 'group1', width: 40, select: true },
          { header: `${groupByValue.get($scope.pageData.group_var2) || '구분2'}`, key: 'group2', width: 40, select: true },
          { header: '실결제금액', key: 'pay_amt', width: 40, select: true, style: { alignment: { wrapText: false, horizontal: 'right', vertical: 'middle' }, numFmt: '#,##0' } },
          { header: '원가', key: 'cost_price', width: 40, select: true, style: { alignment: { wrapText: false, horizontal: 'right', vertical: 'middle' }, numFmt: '#,##0' } },
          { header: '매출이익', key: 'profit', width: 40, select: true, style: { alignment: { wrapText: false, horizontal: 'right', vertical: 'middle' }, numFmt: '#,##0' } },
          { header: '매출이익률', key: 'profit_per', width: 40, select: true, style: { alignment: { wrapText: false, horizontal: 'right', vertical: 'middle' }, numFmt: '0.00%' } },
          { header: '매출수량', key: 'sale_cnt', width: 40, select: true, style: { alignment: { wrapText: false, horizontal: 'right', vertical: 'middle' }, numFmt: '#,##0' } },
        ],
        description: '',
        title: '주문집계',
        url: '/app/summaries/aggregations/excel-down',
        searchForm: $scope.pageData,
        page: 'order_aggregation',
        header_titles: groupByValue,
        visibleTable: [],
        progress: true
      };

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 주문 동기화
     */
    $scope.orderSync = function() {
      //  sync2: GMP (sync: EBAY)
      commonSVC.openModal('md', { data: { work_type: 'sync2' } }, 'OrderShipmentGetOrderCtrl', 'views/order/shipment/modals/get_order.html');
    };

    /**
     * 소수점 절삭함수(음수, 양수 포함)
     * @param {*} value 소수
     * @param {*} decimals 절삭하고 싶은 소수점 자리
     * @returns
     */
    function customToFixed(value, decimals) {
      // 값을 문자열로 변환하고, 소수점 앞 뒤 분리
      const strValue = value.toString();
      const [integer, decimal] = strValue.split('.');

      // 소수점 이하 2자리만 잘라냄 (반올림 안함)
      const truncatedDecimal = decimal ? decimal.slice(0, decimals) : '';

      // 소수점 붙여서 반환
      return `${integer}.${truncatedDecimal || '00'}`;
    }

    // 집계 데이터 초기화 함수
    function resetTotalData() {
      // 초기화 버튼 클릭 시 상단 합계 영역 값도 초기화 처리
      $scope.totalData.total_pay_amt = 0;
      $scope.totalData.total_cost_price = 0;
      $scope.totalData.total_profit = 0;
      $scope.totalData.total_profit_per = 0;
      $scope.totalData.total_sale_cnt = 0;
    }
  });