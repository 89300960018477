'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-A093-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $timeout, $uibModalInstance, shopAccountModel, onlineProductModel, commonSVC, commonModel) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.data = _.cloneDeep(data.data);
    // 기본 값 설정
    $scope.row.set_info.key_product_unit = $scope.row.set_info.key_product_unit?.toString();

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부
    $scope.pa5_job_data = {};
    $scope.attribute_object = {};
    $scope.pa5_job_data.get_attributes = {};
    $scope.pa5_job_data.get_qualifications = {};
    $scope.qualification_data = {};
    const last_category_code = data.shop_data.match_cate_code ? data.shop_data.match_cate_code.split('_').pop() : (data.data.shop_cate_cd.split('_').pop() || data.data.sol_cate_shop_add_info.category_code.split('_').pop());

    // 제품 단위
    $scope.productUnit = [
      { code: 100000015, name: '건/개 (piece/pieces)' },
      { code: 100000000, name: '가방 (bag/bags)' },
      { code: 100000001, name: '통 (barrel/barrels)' },
      { code: 100000002, name: '부셸 (bushel/bushels)' },
      { code: 100078580, name: '박스 (carton)' },
      { code: 100078581, name: '센티미터 (centimeter)' },
      { code: 100000003, name: '입방미터 (cubic meter)' },
      { code: 100000004, name: '다스 (dozen)' },
      { code: 100078584, name: '피트 (feet)' },
      { code: 100000005, name: '갤런 (gallon)' },
      { code: 100000006, name: '그램 (gram)' },
      { code: 100078587, name: '인치 (inch)' },
      { code: 100000007, name: '킬로그램 (kilogram)' },
      { code: 100078589, name: '킬로리터 (kiloliter)' },
      { code: 100000008, name: '킬로미터 (kilometer)' },
      { code: 100078559, name: '리터 (liter/liters)' },
      { code: 100000009, name: '롱톤 (long ton)' },
      { code: 100000010, name: '미터 (meter)' },
      { code: 100000011, name: '미터톤 (metric ton)' },
      { code: 100078560, name: '밀리그램 (milligram)' },
      { code: 100078596, name: '밀리리터 (milliliter)' },
      { code: 100078597, name: '밀리미터 (millimeter)' },
      { code: 100000012, name: '온스 (ounce)' },
      { code: 100000014, name: '팩 (pack/packs)' },
      { code: 100000013, name: '쌍 (pair)' },
      { code: 100000016, name: '파운드 (pound)' },
      { code: 100078603, name: '쿼트 (quart)' },
      { code: 100000017, name: '세트 (set/sets)' },
      { code: 100000018, name: '쇼트톤 (short ton)' },
      { code: 100078606, name: '제곱 피트 (square feet)' },
      { code: 100078607, name: '제곱 인치 (square inch)' },
      { code: 100000019, name: '제곱 미터 (square meter)' },
      { code: 100078609, name: '제곱 야드 (square yard)' },
      { code: 100000020, name: '톤 (ton)' },
      { code: 100078558, name: '야드 (yard/yards)' },
    ];

    // 속성 개별 호출
    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (re.data.has_error === false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    const init = async () => {
      await run_pa5_jobs('get_attributes', '속성', { code: last_category_code }, (data) => {
        $scope.attribute_list = data.filter(({ sku, required, names }) => !sku && required || names.includes('Brand Name')).map(attribute => {
          const saved_attribute = $scope.row.set_info.key_attribute_list.find(({ key_aliexpress_attribute_name_id }) => key_aliexpress_attribute_name_id?.toString() === attribute.id.toString());
          const type = attribute.attribute_show_type_value;
          $scope.attribute_object[`${attribute.id}`] = {};
          $scope.attribute_object[`${attribute.id}`].type = type;

          if (type === 'list_box') {
            $scope.attribute_object[`${attribute.id}`].value_id = saved_attribute ? (saved_attribute.key_aliexpress_attribute_value_id?.toString() || null) : null;
          }

          if (type === 'input') {
            $scope.attribute_object[`${attribute.id}`].value = saved_attribute ? (saved_attribute.key_attribute_value?.toString() || null) : null;
          }

          if (type === 'interval') {
            $scope.attribute_object[`${attribute.id}`].child_value = {};
            const saved_interval = $scope.row.set_info.key_attribute_list.filter(({ key_aliexpress_attribute_name_id }) => key_aliexpress_attribute_name_id?.toString() === attribute.id.toString());
            const first_attribute = saved_interval.find(({ key_index_interval }) => key_index_interval === '1');
            const second_attribute = saved_interval.find(({ key_index_interval }) => key_index_interval === '2');
            $scope.attribute_object[`${attribute.id}`].child_value = {
              1: { value: first_attribute ? first_attribute.key_attribute_value : null },
              2: { value: second_attribute ? second_attribute.key_attribute_value : null }
            };
          }

          if (type === 'check_box') {
            $scope.attribute_object[`${attribute.id}`].child_value = {};
            attribute.values.aeop_attr_value_dto.map(value => {
              const saved_value = $scope.row.set_info.key_attribute_list
                .filter(({ key_attribute_show_type_value }) => key_attribute_show_type_value === 'check_box')
                .find(attribute => +attribute.key_aliexpress_attribute_value_id === value.id);
              $scope.attribute_object[`${attribute.id}`].child_value[`${value.id}`] = !!saved_value;
            });
          }
          const name = JSON.parse(attribute.names).en;
          let sub_attributes = [];
          const value_list = attribute.values?.aeop_attr_value_dto;
          const values = !value_list ? null : value_list.map(value => {
            const { id, names, has_sub_attr, sub_attribute } = value;
            if (has_sub_attr) {
              const { attribute_show_type_value, id, values, input_type, names } = sub_attribute[0];
              sub_attributes = [...sub_attributes, {
                id: id.toString(),
                attribute_show_type_value: attribute_show_type_value,
                input_type,
                name: JSON.parse(names).en,
                values: values.aeop_attr_value_dto.map(value => ({
                  id: value.id.toString(),
                  name: JSON.parse(value.names).en
                })),
                parent_id: value.id.toString()
              }];
              const saved_sub_attribute = $scope.row.set_info.key_attribute_list.find(({ key_aliexpress_attribute_name_id }) => key_aliexpress_attribute_name_id?.toString() === id.toString());
              $scope.attribute_object[`${id}`] = {};
              $scope.attribute_object[`${id}`].type = attribute_show_type_value;
              if (attribute_show_type_value === 'list_box') {
                $scope.attribute_object[`${id}`].value_id = saved_sub_attribute ? saved_sub_attribute.key_aliexpress_attribute_value_id?.toString() : null;
              }
              if (attribute_show_type_value === 'check_box') {
                $scope.attribute_object[`${id}`].child_value = {};
                values.aeop_attr_value_dto.map(value => {
                  const saved_value = $scope.row.set_info.key_attribute_list
                    .filter(({ key_attribute_show_type_value }) => key_attribute_show_type_value === 'check_box')
                    .find(attribute => +attribute.key_aliexpress_attribute_value_id === value.id);
                  $scope.attribute_object[`${id}`].child_value[`${value.id}`] = !!saved_value;
                });
              }
            }

            return {
              id: `${id}`,
              name: JSON.parse(names).en,
              has_sub_attr,
              ...(has_sub_attr && {
                sub_attribute: {
                  id: `${sub_attribute[0].id}`,
                  attribute_show_type_value: sub_attribute[0].attribute_show_type_value,
                  input_type: sub_attribute[0].input_type,
                  name: JSON.parse(sub_attribute[0].names).en,
                  values: sub_attribute[0].values.aeop_attr_value_dto.map(value => ({
                    id: value.id.toString(),
                    names: JSON.parse(value.names).en
                  }))
                }
              })
            };
          });

          return {
            id: `${attribute.id}`,
            attribute_show_type_value: type,
            input_type: attribute.input_type,
            name,
            values,
            sub_attributes,
          };
        });

        return data;
      });

      await run_pa5_jobs('get_qualifications', '자격', { code: last_category_code }, (data) => {
        $scope.qualifications = data.map(qualification => {
          $scope.qualification_data[`${qualification.key}`] = $scope.row.set_info.key_qualification_list?.find(({ key }) => key === qualification.key)?.value || '';

          return qualification;
        });
      });
    };

    $scope.imgModal = function (key) {
      const img = {
        code: 'set_info_img',
        files: $scope.qualification_data[key],
        name: '추가항목이미지',
      };
      const resolve = {
        data: {
          file: img
        }
      };
      commonSVC.openModal('lg', resolve, 'olImgModal', 'views/online/product/olProdImg.html');
    };

    // 쇼핑몰 추가정보 파일 임시 업로드
    $scope.uploadFile = function (files, name) {
      // S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function (res) {
          const results = res.data.results;

          _.forEach(results, function (v) {
            if (v) {
              $scope.qualification_data[name] = v.url;
            }
          });
        });
    };

    $scope.delShopImg = function (name) {
      $scope.qualification_data[name] = '';
    };

    init();

    $scope.findSubAttributes = (attribute_id, sub_attribute_id) => {
      const attribute = $scope.attribute_list.find(({ id }) => id === attribute_id);

      return attribute.sub_attributes.find(({ parent_id }) => parent_id === sub_attribute_id);
    };

    $scope.ok = async type => {
      const attribute_list = Object.entries($scope.attribute_object).flatMap(([ key, value ]) => {
        const key_attribute_show_type_value = value.type;
        const key_aliexpress_attribute_name_id = key;

        if (value.type === 'check_box') {
          return Object.entries(value.child_value).filter(([, value]) => value).map(([key]) => ({
            key_attribute_show_type_value,
            key_aliexpress_attribute_name_id,
            key_aliexpress_attribute_value_id: key,
          }));
        }

        if (value.type === 'interval') {
          return [{
            key_attribute_show_type_value,
            key_aliexpress_attribute_name_id,
            key_attribute_value: value.child_value['1'].value,
            key_index_interval: '1'
          }, {
            key_attribute_show_type_value,
            key_aliexpress_attribute_name_id,
            key_attribute_value: value.child_value['2'].value,
            key_index_interval: '2'
          }];
        }

        return {
          key_attribute_show_type_value,
          key_aliexpress_attribute_name_id,
          ...(value.value_id && {
            key_aliexpress_attribute_value_id: value.value_id
          }),
          ...(value.value && {
            key_attribute_value: value.value
          })
        };
      });
      $scope.row.set_info.key_attribute_list = attribute_list;
      const required_qualification = $scope.qualifications.filter(({ required }) => required);
      const check_required_qualification = required_qualification.every(({ key }) => $scope.qualification_data[key]);
      if (!check_required_qualification) {
        commonSVC.showMessage('실패', `필수인 자격 정보를 입력해 주세요. 필수 자격정보: ${required_qualification.map(({ label }) => label).join(', ')}`);

        return false;
      }
      $scope.row.set_info.key_qualification_list = $scope.qualifications.map(({ key, type }) => ({
        key,
        type,
        value: type === 'image' ? encodeURI($scope.qualification_data[key]) : $scope.qualification_data[key]
      }));

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      init();
      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });
