import React from 'react';
import { FadeLoader } from 'react-spinners';

interface LoaderProps {
  isLoading: boolean;
}

const Loader: React.FC<LoaderProps> = ({ isLoading }) => (
  <div style={{
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 4000,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  }}>
    <FadeLoader
      color='#878787'
      loading={isLoading}
      cssOverride={{
        left: '50%',
        top: '50%',
      }}
      width={7}
      height={20}
      margin={10}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  </div>
);

export default Loader;