import React, { useEffect, useRef } from 'react';

interface ContextMenuProps {
  x: number;
  y: number;
  menuItems: { line?: boolean; label: string; action: any; icon: string }[];
  onClose: () => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ x, y, menuItems, onClose }) => {
  const contextMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(e.target)) {
        onClose(); // 메뉴 닫기
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={contextMenuRef}
      style={{
        top: y,
        left: x,
        position: 'fixed',
        zIndex: 1000,
        backgroundColor: '#fff',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        width: '200px',
      }}
    >
      <ul
        style={{
          listStyle: 'none',
          margin: 0,
          padding: '10px 0',
        }}
      >
        {menuItems.map((item, index) => (
          <>
            {(item.line && index > 0) && (
              <li key={`divider-${index}`}>
                <hr
                  style={{
                    margin: '4px 0',
                    border: 0,
                    borderTop: '1px solid #ccc',
                  }}
                />
              </li>
            )}
            <li
              key={index}
              onClick={item.action}
              style={{
                padding: '8px 20px',
                cursor: 'pointer',
              }}
              onMouseEnter={(e: any) =>
                (e.target.style.backgroundColor = '#f0f0f0')
              }
              onMouseLeave={(e: any) =>
                (e.target.style.backgroundColor = 'transparent')
              }
            >
              <i
                className={item.icon}
                style={{ marginRight: '6px', fontSize: '14px', color: '#555' }}
              />
              {item.label}
            </li>
          </>
        ))}
      </ul>
    </div>
  );
};

export default ContextMenu;
