'use strict';
angular.module('gmpApp')
  .controller('CalculateStateListCtrl', function ($scope, $rootScope, commonSVC, gettextCatalog, $compile,
    settings, $filter, siteInfo, stateSVC, stateModel, systemList, userInfo) {
    // 사용중인 쇼핑몰
    const channelListArr = $rootScope.useChannelList({ site_action: 'ScrapPayBack' });

    $scope.tabActive = 0;
    $scope.excelData = {};
    let searchValue = '';  // 조건별 value
    $scope.sol_ser = userInfo.user.sol_ser;
    /**
     * 서치바 관련 변수
     */
    // 검색영역 폼
    const searchForm = {
      search_key: 'all',
      search_word: '',
      search_type: 'partial',
      date_type: 'sett_com_date',
      group_by: 'sett_com_date',
      sdate: '2001-01-01',
      edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd')
    };

    $scope.searchForm = angular.copy(searchForm);

    if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
      $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
    }

    // 검색영역 데이터
    const searchData = {
      showSearchArea: true,
      showDetailSearchArea: true,
      all_checked: false,  // 전체선택 여부
      totalCount: 0,       // 검색전체 건수
      showCount: 50,
      selectCount: 0,      // 선택한 건수
      search_key_items: [  // 검색영역 키값
        { label: gettextCatalog.getString('전체'), value: 'all' },
        { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
        { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
        { label: gettextCatalog.getString('구매자명'), value: 'order_name' }
      ],
      customSearchBtn: {
        title: '통합 정산내역',
        tooltip: '조회기간 동안의 각 쇼핑몰 상세 정산 내역 을 다운로드합니다.',
        class: 'btn btn-primary dropdown-toggle text-semibold ml-5',
        action: function () {
          $scope.excelDownSelect();
        }
      },
      search_date_type: [
        { label: gettextCatalog.getString('정산완료일'), value: 'sett_com_date' }
      ]
    };

    $scope.searchData = angular.copy(searchData);

    //상세검색 데이터
    const searchDetail = [
      {
        // 쇼핑몰 선택
        title: gettextCatalog.getString('쇼핑몰 선택'),
        search_name: 'shop_cd',
        item_list: _.unionBy(channelListArr, 'shop_cd'),
        item_key: 'shop_name',
        item_sub_key: 'shop_name_kr',
        item_value: 'shop_cd',
        select_value: '',
        add_class: 'select-search'

      },
      {
        // 계정 선택
        title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
        search_name: 'shop_id',
        item_list: channelListArr,
        item_key: 'shop_id',
        item_value: 'shop_id',
        select_value: '',
        add_class: 'select-search',
        filter: function (option) {
          // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
          return option.shop_cd == $scope.searchForm.shop_cd && option.shop_id;
        }
      }
    ];

    $scope.searchDetail = angular.copy(searchDetail);

    // 데이터 테이블 관련기능
    $scope.searchFn = {
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      resetDo: function () {
        $scope.resetDo();
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      }
    };

    // 검색영역 버튼
    $scope.searchBtn = {
      // 검색 영역 오른쪽 버튼
      actions_right: [
        {
          label: `<i class="picon-get"></i><span>${gettextCatalog.getString('정산 수집')}<span class="caret"></span>`,
          small_label: '정산수집',
          sub_label: '',
          btn_type: 'dropdown',
          add_class: 'col-xs-4',
          item_list: [
            {
              label: gettextCatalog.getString('정산 자동 수집'),
              hotkey: '',
              action: function () {
                // 정산수집 권한 확인. 2019-01-04 rony
                if (commonSVC.checkPermission('calculate.roles.scrapCalculate', userInfo.permission)) {
                  $scope.calculateCollect();
                }
              }
            },
            {
              label: `${gettextCatalog.getString('정산 수동 업로드')}`,
              small_label: '정산 수동 업로드',
              hotkey: '',
              action: function () {
                if (commonSVC.checkPermission('calculate.roles.scrapCalculate', userInfo.permission)) {
                  $scope.calculateExcelUpload();
                }
              }
            }
          ]
        },
        {
          label: `<i class="picon-money-calendar"></i><span>${gettextCatalog.getString('정산 캘린더')}`,
          small_label: '정산캘린더',
          sub_label: '',
          btn_type: 'button',
          add_class: 'col-xs-4',
          action: function () {
            $scope.calculateCalenderModal();
          }
        },
        {
          label: `<i class="picon-calculation"></i><span>${gettextCatalog.getString('통합 정산내역')}`,
          small_label: '정산내역',
          sub_label: '',
          btn_type: 'button',
          add_class: 'col-xs-4',
          action: function () {
            $scope.excelDownSelect();
          }
        }
      ]
    };

    $scope.grid = {
      methods: {},
      options: {
        pinningColumns: [],
        alignCenterColumns: ['cnt'],
        alignRightColumns: ['total_sales', 'total_deduct_price', 'total_sett_price'],
        defaultSortingColumns: ['sett_com_date'], //3
        notSortingColumns: ['total_sales', 'total_deduct_price', 'total_sett_price', 'cnt'],
        notResizingColumns: [],
        notMovingColumns: [],
        notVisibleColumns: [],
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/calculate/state/list`,
          requestWillAction: function (d) {
            const data = angular.merge({}, d, $scope.searchForm);
            //엑셀 모달용 데이터

            $scope.pageData = angular.copy(data);

            return data;
          },
          requestDidAction: function (result) {
            $scope.searchData.totalCount = result.recordsTotal;

            return result.results;
          }
        },
        columns: [
          {
            key: 'sett_com_date',
            title: '정산일',
            notCompile: true,
            template: function (row) {
              if (row.sett_com_date === '합계') {
                return row.sett_com_date;
              }
              if ($scope.searchForm.group_by === 'sett_com_date') {
                const date = moment(row.sett_com_date).format('YYYY-MM-DD');

                searchValue = (date != 'Invalid date') ? date : '-';

                return searchValue ? searchValue : '';
              } else if ($scope.searchForm.group_by === 'shop_cd') {
                searchValue = row.shop_cd;

                return siteInfo[searchValue] ? siteInfo[searchValue].name : '';
              } else if ($scope.searchForm.group_by === 'sett_type') {
                searchValue = row.state;

                return searchValue ? searchValue : '';
              }
            }
          },
          {
            key: 'total_sales',
            title: '판매금액',
            notCompile: true,
            template: function (row) {
              return $filter('currency')(row.total_sales, '', 0);
            }
          },
          {
            key: 'total_deduct_price',
            title: '공제금액',
            notCompile: true,
            template: function (row) {
              return $filter('currency')(row.total_deduct_price, '', 0);
            }
          },
          {
            key: 'total_sett_price',
            title: '정산금액',
            notCompile: true,
            template: function (row) {
              return $filter('currency')(row.total_sett_price, '', 0);
            }
          },
          {
            key: 'cnt',
            title: '정산건수',
            template: function (row) {
              return `<button class="btn btn-primary btn-xxs full-width" type="button" ng-click="grid.appScope.detailModal('${searchValue}')">${row.cnt}건</button>`;
            }
          }
        ]
        ,
        fnFooterCallback: function (row, data, start, end, display) {
          const total = {
            total_sales: 0,
            total_deduct_price: 0,
            total_sett_price: 0,
            cnt: 0
          };

          _.forEach(display, function (i) {
            total.total_sales += data[i].total_sales * 1;
            total.total_deduct_price += data[i].total_deduct_price * 1;
            total.total_sett_price += data[i].total_sett_price * 1;
            total.cnt += data[i].cnt * 1;
          });

          // 공제금액과 판매금액은 필드 드래그로 바뀔때 같이 바뀌어야 함. 2018-03-30 rony
          const cells = row.getElementsByTagName('th');

          cells[1].innerHTML = cells[1].outerHTML.indexOf('style="text-align:right"') > -1 ? $filter('currency')(total.total_sales, '', 0) : $filter('currency')(total.total_deduct_price, '', 0);
          cells[2].innerHTML = cells[2].outerHTML.indexOf('style="text-align:right"') > -1 ? $filter('currency')(total.total_sales, '', 0) : $filter('currency')(total.total_deduct_price, '', 0);
          cells[3].innerHTML = $filter('currency')(total.total_sett_price, '', 0);
          cells[4].innerHTML = `${total.cnt}건`;
        }
      }
    };

    /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
    $scope.$on('OnColumnChange', function (event, data) {
      _.each(data, function (row) {
        $compile(row)($scope);
      });
    });

    /**
    ********** 함수 목록 **********
    * */

    // 탭 변경
    $scope.selectTab = function (tabNumber) {
      $scope.tabActive = tabNumber;
      if (tabNumber === 0) {
        $scope.searchForm.group_by = 'sett_com_date';
        $scope.searchData.search_date_type = angular.copy(searchData.search_date_type);
        $scope.grid.methods.changeTitle(['정산일', '판매금액', '공제금액', '정산금액', '정산건수']);
      } else if (tabNumber === 1) {
        $scope.searchForm.group_by = 'shop_cd';
        delete $scope.searchData.search_date_type;
        $scope.grid.methods.changeTitle(['쇼핑몰별', '판매금액', '공제금액', '정산금액', '정산건수']);
      }

      $scope.searchDo(true, true);
    };

    // 검색
    $scope.searchDo = function (refresh = true, noDelay) {
      $scope.grid.methods.reloadData(function () { }, refresh, noDelay);
    };

    // 검색 초기화
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(searchForm);
      $scope.searchDetail = angular.copy(searchDetail);

      if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
        $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      }

      $scope.selectTab($scope.tabActive);
      $scope.searchDo(true, true);
    };

    // 건수 변경
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    // 상세 리스트 모달 열기
    $scope.detailModal = async function (detail_value) {
      const templatePath = 'views/calculate/modals/detail.html',
            resolve = {
              data: {
                detail_value: detail_value,
                searchForm: $scope.searchForm
              }
            };

      if (resolve.data.searchForm.group_by === 'shop_cd') {
        try {
          const result = await stateModel.stateExcelFieldList({ shop_cd: resolve.data.detail_value });
          resolve.data.columns = result.data.results[0];
          commonSVC.openModal('full', resolve, 'CalculateStateDetailCtrl', templatePath);
        } catch (error) {
          commonSVC.showToaster('error', '실패', '쇼핑몰별 엑셀 필드 조회가 실패 되었습니다.');
        }
      } else {
        commonSVC.openModal('full', resolve, 'CalculateStateDetailCtrl', templatePath);
      }
    };

    // 정산 캘린더 모달 열기
    $scope.calculateCalenderModal = function () {
      const templatePath = 'views/calculate/modals/calendar.html',
            resolve = {
            };

      commonSVC.openModal('x3g', resolve, 'CalculateCalendarCtrl', templatePath);
    };

    // 검색 내용 초기화 버튼
    $scope.selectSearchForm = function (key, value, text, reSearch) {
      $scope.searchForm[key] = value;
      if (reSearch !== false) {
        $scope.searchDo(true, true);
      }
    };

    /**
     * 엑셀 다운
     */
    $scope.excelDownSelect = function () {

      // 정산 엑셀 다운로드 권한 확인. 2019-01-04 rony
      if (commonSVC.checkPermission('calculate.roles.excelCalculate', userInfo.permission) === false) {
        return false;
      }

      //excelData.down_list = angular.copy(stateSVC.excelDownList);

      const title = '통합 정산내역 다운로드';
      const contents = `정산완료일 ${$scope.searchForm.sdate} ~ ${$scope.searchForm.edate} 의 모든 정산 내역을 다운로드 합니다.`;
      const buttons = [
        {
          name: 'xlsx',
          next: function () {
            $scope.excelData.file_ext = 'xlsx';
            excelDown();
          }
        },
        {
          name: '취소',
          next: function () {
            return false;
          }
        }
      ];

      commonSVC.showMultiButton(title, contents, buttons);
    };

    function excelDown() {
      $scope.excelData.is_all = true;
      $scope.excelData.down_list = angular.copy(stateSVC.excelDownList);
      $scope.excelData.sheet_name = '정산내역';
      $scope.excelData = _.merge($scope.excelData, $scope.pageData);

      if ($scope.grid.methods.getVisibleRows().length) {
        stateModel.downExcel($scope.excelData, function (state, result) {
          if (result.results?.length) {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('엑셀 다운로드에 성공하였습니다.'));
            window.open(result.results[0], '');
          } else {
            commonSVC.showMessage('통합 정산내역 다운로드', result.messages[0]);
          }
        });
      } else {
        setTimeout(() => {
          commonSVC.showMessage('통합 정산내역 다운로드', '다운로드 가능한 정산 내역이 없습니다.');
        }, 50);

      }
    }
    /**
     * 정산 수동 업로드 클릭 시
     */
    $scope.calculateExcelUpload = function () {
      const templatePath = 'views/calculate/modals/excel_upload.html';

      const modal = commonSVC.openModal('', { data: { shop_id_view_type: systemList.data.shop_id_view_type } }, 'CalculateExcelUploadCtrl', templatePath);
      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(true);
        }
      });
    };

    /**
     * 정산 자동 수집 클릭 시
     */
    $scope.calculateCollect = function () {
      const templatePath = 'views/calculate/modals/collect.html';

      commonSVC.openModal('lm', {}, 'CalculateCollectCtrl', templatePath);
    };

  });