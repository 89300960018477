(function () {
  'use strict';

  angular.module('gmpApp')
    .controller('OrderCustomerInquiryListCtrl', OrderCustomerInquiryListCtrl);

  function OrderCustomerInquiryListCtrl ($scope, $filter, csSVC, $timeout, customerInquiryModel, systemList, onlineProductSVC, commonSVC, settings, gettextCatalog, $state, $rootScope, systemModel, workSVC, $q, userInfo) {
    // 사용중인 쇼핑몰
    const channelListArr = $rootScope.useChannelList({ site_action: 'ScrapCS' });

    // 엘지 커스텀 여부
    const lgCustom = $rootScope.affName === 'LG전자' && !!$rootScope.user_profile.pa_sol_no;

    // 별칭정보 담기
    const seller_nick_info = [];
    let inquiryList = [];

    _.forEach(channelListArr, function(chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });
    $scope.templateList = [];
    $scope.selectedTemplate = '0';
    $scope.countList = { total: 0, cs: 0, emergency: 0, review: 0 }; // 우측 아이콘 카운트
    $scope.selectCount = 'total';
    $scope.selectedInq = {};
    $scope.noReply = false;
    $scope.contentPlaceholder = '답변내용';
    let originInq = {};
    let selectedAllRowList = []; // 테이블에서 선택된 리스트
    const searchForm = {
      search_key: 'all',
      search_word: '',
      search_type: 'partial',
      date_type: 'wdate',
      sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
      edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
      multi_search_word: '', 		//멀티서치워드
      multi_type: 'shop_sale_no',  //멀티서치 타입
      multi_shop_id: ''
    };

    const searchData = {
      all_checked: false,  // 전체선택 여부
      totalCount: 0,       // 검색전체 건수
      selectCount: 0,      // 선택한 건수 <- 작업예정
      search_key_items: [  // 검색영역 키값
        { label: gettextCatalog.getString('전체'), value: 'all' },
        { label: gettextCatalog.getString('문의자ID'), value: 'inq_id' },
        { label: gettextCatalog.getString('제목'), value: 'inq_title' },
        { label: gettextCatalog.getString('내용'), value: 'inq_content' },
        { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
        // {label:gettextCatalog.getString('판매자관리코드'), value:'c_sale_cd'},
        { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' }

      ],
      search_date_type: [
        { label: gettextCatalog.getString('수집일'), value: 'wdate' },
        { label: gettextCatalog.getString('전송일'), value: 'send_time' }
      ],
      search_multi_items: [
        // {label:gettextCatalog.getString('판매자관리코드'), value:'c_sale_cd'},
        { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' },
        { label: gettextCatalog.getString('쇼핑몰(계정)'), value: 'shop' }
      ]
    };

    /**
     * 서치바 관련 변수
     */
    $scope.searchData = angular.copy(searchData);
    $scope.searchForm = angular.copy(searchForm);

    // 상세검색 데이터
    const searchDetail = [
      {
        // 쇼핑몰 선택
        title: gettextCatalog.getString('쇼핑몰 선택'),
        search_name: 'shop_cd',
        item_list: _.unionBy(channelListArr, 'shop_cd'),
        item_key: 'shop_name',
        item_sub_key: 'shop_name_kr',
        item_value: 'shop_cd',
        select_value: '',
        add_class: 'select-search'
      },
      {
        // 계정 선택
        title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
        search_name: 'shop_id',
        item_list: channelListArr,
        item_key: 'shop_id',
        item_value: 'shop_id',
        select_value: '',
        add_class: 'select-search',
        filter: function(option) {
          // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
          return option.shop_cd == $scope.searchForm.shop_cd;
        }
      },
      // {
      //   title: gettextCatalog.getString('문의 유형 선택'),
      //   search_name: 'inq_type',
      //   item_list: [
      //     { inq_type: '상품문의' },
      //     { inq_type: '긴급메세지' },
      //     { inq_type: '상품평' }
      //   ],
      //   item_key: 'inq_type',
      //   item_value: 'inq_type',
      //   select_value: ''
      // },
      {
        title: gettextCatalog.getString('문의상태 선택'),
        search_name: 'inq_status',
        item_list: [
          { inq_status: '신규문의' },
          { inq_status: '답변작성완료' },
          { inq_status: '답변전송완료' },
          { inq_status: '답변전송실패' },
          { inq_status: '확인완료' }
        ],
        item_key: 'inq_status',
        item_value: 'inq_status',
        select_value: ''
      }
    ];

    const searchDetailMulti = [
      {
        title: gettextCatalog.getString('쇼핑몰(계정) 선택'),
        search_name: 'multi_shop_id',
        item_list: angular.copy(channelListArr).sort(function(prev, curr) {
          return `{${curr.pa_shop_cd} ${curr.shop_name}` < `{${prev.pa_shop_cd} ${prev.shop_name}` ? 1 : -1;
        }),
        item_key: 'shop_id',
        item_value: 'shop_id',
        search_word: ''
      }
    ];

    $scope.searchDetail = angular.copy(searchDetail);
    $scope.searchDetailMulti = angular.copy(searchDetailMulti);

    // 데이터 테이블 관련기능 바인딩
    $scope.searchFn = {
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      },
      getInquiryList: function() {
        return inquiryList;
      }
    };

    // 검색영역 버튼 설정
    $scope.searchBtn = {
      // 검색 영역 왼쪽 상위 버튼
      actions_left_top: [
        {
          label: `<i class="icon-upload4"></i> ${gettextCatalog.getString('답변 전송')}`,
          action: function() {
            $scope.answerWork('send');
          }
        }
      ],
      // 검색 영역 왼쪽 버튼
      actions_left: [
        [{
          label: `<i class="icon-file-excel text-success"></i> ${gettextCatalog.getString('전체 엑셀 다운로드')}`,
          action: function() {
            $scope.excelDown('all');
          }
        },
        {
          label: `<i class="icon-file-excel text-success"></i> ${gettextCatalog.getString('선택 엑셀 다운로드')}`,
          action: function() {
            $scope.excelDown('select');
          }
        }]
      ],
      // 검색 영역 오른쪽 버튼
      actions_right: [
        {
          label: `<i class="picon-get"></i>${gettextCatalog.getString('문의수집')}`,
          small_label: '문의수집',
          test_id: 'btn-collect-qa',
          // label: gettextCatalog.getString('문의수집') +  '<i class="caret position-left"></i>',
          sub_label: '',
          add_class: 'col-xs-6',
          btn_type: 'button',
          action: function() {
            $scope.csCollect();
          }
        },
        {
          label: `<i class="icon-cog5"></i> ${gettextCatalog.getString('답변 템플릿 설정')}`,
          small_label: '템플릿설정',
          test_id: 'btn-setting-answer',
          btn_type: 'button',
          add_class: 'col-xs-6',
          perm_only: ['order.customer+write'],
          action: function() {
            $scope.answerTemplate();
          }
        }
      ],
      table_actions: [
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          test_id: 'drop-excel',
          id: 'drop-excel',
          item_list: [{
            label: gettextCatalog.getString('전체엑셀 다운로드'),
            action: function () {
              $scope.excelDown('all');
            },
            test_id: 'btn-all-excel',
            perm_only: ['order.customer+write']
          }, {
            label: gettextCatalog.getString('선택엑셀 다운로드'),
            action: function () {
              $scope.excelDown('select');
            },
            test_id: 'btn-select-excel',
            perm_only: ['order.customer+write']
          }]
        },
        {
          label: gettextCatalog.getString('확인 처리(일괄)'),
          add_class: 'a-btn-blue',
          test_id: 'btn-confirm',
          style: 'width: 100px',
          id: 'btn-confirm',
          action: function () {
            $scope.answerWork('confirm');
          },
          perm_only: ['order.customer+write']
        },
        {
          label: gettextCatalog.getString('답변 작성(일괄)'),
          add_class: 'a-btn-blue',
          test_id: 'btn-answer',
          style: 'width: 100px',
          id: 'btn-answer',
          action: function () {
            $scope.answerWork('answer');
          },
          perm_only: ['order.customer+write']
        },
        {
          label: gettextCatalog.getString('답변 전송(일괄)'),
          add_class: 'a-btn-blue',
          test_id: 'btn-send',
          style: 'width: 100px',
          id: 'btn-send',
          action: function () {
            $scope.answerWork('send');
          },
          perm_only: ['order.customer+write']
        },
        {
          label: gettextCatalog.getString('삭제'),
          add_class: 'a-btn-red',
          test_id: 'btn-delete',
          style: 'width: 80px',
          id: 'btn-delete',
          action: function () {
            $scope.answerWork('delete');
          },
          perm_only: ['order.customer+write']
        }
      ]
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'inq_name', 'inq_type', 'inq_status'],
      alignRightColumns: [],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: [
        'inq_id',
        'shop_sale_name',
        'c_sale_cd',
        'inq_time'
      ],
      selectOptions: {
        multiSelect: true,
        modifierKeysToMultiSelect: lgCustom
      },
      gridHeight: 420,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/inquiries`,
        requestWillAction: function(data) {
          if (
            $rootScope.side_search.page === 'inquiry' &&
            $rootScope.side_search.search
          ) {
            $scope.searchForm.date_type = $rootScope.side_search.date_type;
            $scope.searchForm.sdate = $rootScope.side_search.sdate;
            $scope.searchForm.edate = $rootScope.side_search.edate;
            $scope.searchForm.inq_type = $rootScope.side_search.inq_type;
            $scope.searchForm.inq_status = $rootScope.side_search.inq_status;
            $scope.selectCount = $rootScope.side_search.selectCount;
            $rootScope.side_search.search = false;

            const idx = _.findIndex($scope.searchDetail, {
              search_name: 'inq_status'
            });

            $scope.searchDetail[idx].select_value =
              $rootScope.side_search.inq_status;
          }

          if (data.orderby.includes('shop_name')) {
            data.orderby = `e.seq ${data.orderby.split(' ')[1]}, ${data.orderby}`;
          }
          init();
          data = angular.merge({}, data, $scope.searchForm);

          // 엘지 계정인 경우 개인정보 마스킹처리
          if (lgCustom) {
            data.contentMasking = true;
          }

          $scope.pageData = angular.copy(data); //엑셀 모달용 데이터

          return data;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;
          inquiryList = result.results;

          for (const inq of result.results) {
            if (!inq.ans_content) {
              inq.ans_content = '';
            }
          }

          return result.results;
        }
      },
      columns: [
        {
          key: 'wdate',
          title: '문의수집일',
          width: 100,
          filter: 'dateValid'
        },
        {
          key: 'send_time',
          title: '답변전송일',
          width: 100,
          filter: 'dateValid'
        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 150,
          template: function(row) {
            let img = '<div class="text-center">직접입력</div>';

            if (row.shop_cd !== 'A000') {
              const shop_info = commonSVC.getShopIdViewText(
                systemList.data.shop_id_view_type,
                seller_nick_info,
                row.shop_cd,
                row.shop_id
              );

              img =
                `<span uib-tooltip='${row.shop_name}(${shop_info[0]})` +
                `' tooltip-placement='right'><img src='/assets/images/sitelogo/${
                  row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd
                }.png' style='width: 50px;'>${shop_info[1]}</span>`;
            }

            return img;
          }
        },
        {
          key: 'inq_name',
          title: '문의자명',
          width: 120,
          template: function(row) {
            return row.inq_name || row.inq_id;
          }
        },
        {
          key: 'inq_type',
          title: '문의 유형',
          width: 90
        },
        {
          key: 'inq_status',
          title: '문의 상태',
          width: 90,
          template: function(row) {
            return $filter('statusColorInq')(row.inq_status);
          }
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 120,
          template: function(row) {
            const sale_no = row.shop_sale_no || '';
            const shop_cd = row.shop_cd;
            const shop_id = row.shop_id;
            let sale_no_link = '';

            if (
              shop_cd == 'B378' &&
              row.shop_sale_no_sub &&
              row.shop_sale_no_sub.length === 10
            ) {
              sale_no_link = `${sale_no}?vendorItemId=${
                row.shop_sale_no_sub
              }`;
            } else {
              sale_no_link = sale_no;
            }

            if (
              sale_no &&
              !_.includes(onlineProductSVC.impossibeDetailView, shop_cd) &&
              sale_no != '-'
            ) {
              return `<span>${sale_no}<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${shop_cd}','${shop_id}','${sale_no_link}')"></i></span>`;
            } else {
              return sale_no;
            }
          }
        },
        // 쇼핑몰 주문번호
        {
          key: 'shop_ord_no',
          title: '주문번호',
          width: 120,
          template: function(row) {
            const ord_no =
              row.shop_ord_no === '-' || row.shop_ord_no === '-1'
                ? ''
                : `<a ng-click="grid.appScope.goAnotherMenu('${row.shop_ord_no}')">${row.shop_ord_no}</a>`;

            return ord_no;
          }
        },
        {
          key: 'inq_title',
          title: '문의제목',
          width: 220
        },
        {
          key: 'inq_id',
          title: '문의자ID',
          width: 120
        },
        {
          key: 'shop_sale_name',
          title: '쇼핑몰 상품명',
          width: 100,
          filter: 'whiteSpace'
        },
        {
          key: 'c_sale_cd',
          title: '판매자상품코드',
          width: 120
        },
        {
          key: 'inq_time',
          title: '문의접수일',
          width: 130,
          filter: 'dateValid'
        }
      ]
    };

    $scope.customContext = [{
      isSubMenu: true,
      line: true,
      subMemuName: '상태 수동변경',
      contextList: [
        {
          label: '전송완료로 상태변경',
          action: function() { userChangeStatus('답변전송완료'); },
          filter: function(row) {
            return ['신규문의', '답변작성완료'].includes(row.inq_status);
          }
        }, {
          label: '신규문의로 상태변경',
          action: function() { userChangeStatus('신규문의'); },
          filter: function(row) {
            return ['답변전송완료'].includes(row.inq_status);
          }
        }, {
          label: '작성완료로 상태변경',
          action: function() { userChangeStatus('답변작성완료'); },
          filter: function(row) {
            return ['답변전송실패'].includes(row.inq_status);
          }
        }
      ]
    }];

    /*********** 함수 목록 ***********/

    /**
     * 일반 버튼 초기화
     */
    function doResetBtn() {
      $('#btn-confirm').attr('disabled', false).html('확인 처리(일괄)');
      $('#btn-answer').attr('disabled', false).html('답변 작성(일괄)');
      $('#btn-send').attr('disabled', false).html('답변 전송(일괄)');
      $('#btn-delete').attr('disabled', true).html('삭제');
    }

    /**
    * 집계 카운트
    */
    function init() {
      $scope.testList = angular.extend($scope.grid.methods.selectedData('all'));
      doResetBtn();
      // 선택된 문의 초기화
      $scope.selectedInq = {};
      customerInquiryModel.inquiryCount({ ...$scope.searchForm, inq_type: '' }, function (state, data) {
        if (state === 'success') {
          $scope.countList = data.results;
        }
      });

      customerInquiryModel.loadAnswerTemplate({}, function (state, data) {
        if (state === 'success') {
          $scope.templateList = data.results;
        }
      });
    }
    // 답변 저장 함수
    const registerAnswer = async function(inq) {
      try {
        const inquiryData = await customerInquiryModel.answer(inq);
        if (inquiryData.data?.message === 'success') {
          commonSVC.showToaster('success', '성공', '답변을 성공적으로 저장하였습니다.');
          $scope.searchDo(false, false);

          return true;
        }
        else {
          commonSVC.showToaster('error', '실패', '답변 저장에 실패하였습니다.');

          return false;
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '답변 저장에 실패하였습니다.');
      }
    };

    // 저장하기 버튼 동작
    $scope.save = function (inq) {
      //선택한 문의가 없을 때 안내문 출력 20-04-13 Tim
      if (!Object.keys(inq).length) {
        commonSVC.showMessage('선택된 문의가 없습니다.');

        return false;
      }

      //답변제목 또는 답변내용에 입력한 값이 없을 때 안내문 출력 20-04-13 Tim
      const errMsg = [];

      if (!inq.ans_title) {
        errMsg.push('답변제목');
      }
      if (!inq.ans_content) {
        errMsg.push('답변내용');
      }
      if (errMsg.length) {
        commonSVC.showMessage('실패', `${errMsg.join(',')}이 없습니다.`);

        return false;
      }

      //원본에 $$hashKey가 있어 변경사항 비교하는 조건문이 정상작동 하지 않아 delete함 20-04-13 Tim
      if (originInq.$$hashKey) {
        delete originInq.$$hashKey;
      }

      if (_.isEqual(inq, originInq)) {
        commonSVC.showMessage('실패', '변경사항이 없습니다.');

        return false;
      }

      const newInq = { ...inq, inq_uniq: [inq.inq_uniq] };

      systemModel.load()
        .then(function(result) {
          if (result.data.inq_ans_send_yn === 'N') {
            commonSVC.showConfirm('저장', '답변내용을 저장하시겠습니까?', function (confirm) {
              if (confirm) {
                registerAnswer(newInq);
              }
            });
          }
          else if (result.data.inq_ans_send_yn === 'Y') {
            commonSVC.showConfirm('저장', '답변 내용을 저장하고 쇼핑몰에 전송하시겠습니까?', async function (confirm) {
              if (confirm) {
                const resultAnswer = await registerAnswer(newInq);

                if (resultAnswer) {
                  try {
                    const params = {
                      numbers: newInq.inq_uniq
                    };

                    const anHttpPromise = await workSVC.addWorkSelect('SEND_CS', params);

                    await $q.all(anHttpPromise.result);

                  } catch (err) {
                    commonSVC.showToaster('error', '실패', '답변 전송 실패');
                  }
                }
              }
            });
          }
        });
    };

    /**
     * 조회
     */
    $scope.searchDo = function (refresh, noDelay) {
      if ($scope.searchForm.inq_type) {
        switch ($scope.searchForm.inq_type) {
          case '상품문의':
            $scope.selectCount = 'cs';
            break;
          case '긴급메세지':
            $scope.selectCount = 'emergency';
            break;
          case '상품평':
            $scope.selectCount = 'review';
            break;
          default:
            $scope.selectCount = 'total';
            break;
        }
      }
      else {
        $scope.selectCount = 'total';
      }

      doResetBtn();

      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);

    };

    /**
     * 집계 조회
     */
    $scope.countSearch = function (type) {
      switch (type) {
        case 'cs':
          $scope.searchForm.inq_type = '상품문의';
          break;
        case 'emergency':
          $scope.searchForm.inq_type = '긴급메세지';
          break;
        case 'review':
          $scope.searchForm.inq_type = '상품평';
          break;
        default:
          $scope.searchForm.inq_type = '';
          break;
      }

      // searchDo 에서 처리하고 있으므로 주석 2018-11-12 rony
      // $scope.selectCount = type;
      $scope.searchDo(true, true);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(searchForm);
      $scope.searchData.search_key_name = '전체';
      $scope.searchDetail = angular.copy(searchDetail);
      $scope.selectCount = 'total';
      $scope.searchDo(true, true);
    };

    /**
     * 데이터테이블 pageLength 조절
     * @param number
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 개별 문의 확인하기
     */
    $scope.inqConfirm = function () {

      customerInquiryModel.apply({ inq_uniq: [$scope.selectedInq.inq_uniq] })
        .then(function (result) {
          if (result?.data === 'success') {
            commonSVC.showToaster('success', '성공', 'CS 확인에 성공하였습니다.');
            $scope.searchDo(false, false);
          } else {
            commonSVC.showToaster('error', '실패', 'CS 확인에 실패하였습니다.');
          }

        })
        .catch(function () {
          commonSVC.showToaster('error', '실패', 'CS 확인에 실패하였습니다.');
        });
    };

    /**
     * 상세보기시 선택한 데이터 보여주기
     * */
    $scope.$on('OnSelectChangeBefore', function(event, data) {
      $scope.selectedInq = angular.copy(data.selectData);
      $scope.selectedInq.inq_content = $scope.selectedInq.inq_content.replace(/\n/g, '<br>');
      originInq = data.selectData;

      //답변내용 문구
      $scope.contentPlaceholder = csSVC.csContentPlaceholderFn(data, $scope.selectedInq.inq_type);

      switch ($scope.selectedInq.inq_type) {
        case '상품문의':
          $scope.noReply = !$scope.selectedInq.en_sendCS_yn;
          break;
        case '상품평':
          $scope.noReply = !$scope.selectedInq.en_sendRCS_yn;
          break;
        case '긴급메세지':
          $scope.noReply = !$scope.selectedInq.en_sendECS_yn;
          break;
        default:
          break;
      }

      // 답변제목이 없는경우 "re : 문의제목" 으로 노출
      if (!$scope.selectedInq.ans_title) {
        $scope.selectedInq.ans_title = `Re: ${$scope.selectedInq.inq_title}`;
      }

      $scope.selectedTemplate = '0';
    });

    // 버튼 타입에 따른 작업
    $scope.answerWork = async (type) => {
      let list = $scope.grid.methods.selectedData('all');

      try {
        // 삭제는 무조건 선택된 것만. 선택안했을 경우 라스트 전체 선택
        if (!list.length && type !== 'delete' && $scope.countList.total) {
          const res = await customerInquiryModel.list({ ...$scope.pageData, only_rows: true });
          list = res.data.results;
        }

        if (!list.length) {
          switch (type) {
            // 확인 처리
            case 'confirm':
              commonSVC.showMessage('확인 처리 가능한 문의가 없습니다.');
              break;
            // 답변 작성
            case 'answer':
              commonSVC.showMessage('답변 작성 가능한 문의가 없습니다.');
              break;
            // 답변 전송
            case 'send':
              commonSVC.showMessage('답변 전송 가능한 문의가 없습니다.');
              break;
            default:
              commonSVC.showMessage('문의를 선택해주세요.');
              break;
          }

          return false;
        }

        let title = '';
        if (type === 'answer') {
          list = list.filter(inq => !['답변전송완료', '확인완료', '답변작성완료', '답변전송실패'].includes(inq.inq_status)
          && !((inq.shop_cd == 'A112' || inq.shop_cd == 'A027') && inq.inq_type == '상품평')
          && ((inq.inq_type === '상품문의' && inq.en_sendCS_yn)
          || (inq.inq_type === '상품평' && inq.en_sendRCS_yn)
          || (inq.inq_type === '긴급메세지' && inq.en_sendECS_yn)))
            .map(inq => inq.inq_uniq);

          title = '답변 작성';
        } else if (type === 'delete') { // 문의 삭제 시 로그 작성 위해 전체를 보내줌
          title = '문의 삭제';
        } else if (type === 'confirm') {
          list = list.filter(inq => inq.inq_status !== '확인완료'
              && ((inq.inq_type === '상품문의' && !inq.en_sendCS_yn)
                || (inq.inq_type === '상품평' && !inq.en_sendRCS_yn)
                || (inq.inq_type === '긴급메세지' && !inq.en_sendECS_yn)))
            .map(inq => inq.inq_uniq);
          title = '확인 처리';
        } else if (type === 'send') {
          list = list.filter(inq => inq.inq_status === '답변작성완료' || inq.inq_status === '답변전송실패')
            .map(inq => inq.inq_uniq);

          title = '답변 전송';
        }

        if (!list.length) {
          await commonSVC.showMessage(`${title === 'delete' ? '삭제' : title} 가능한 문의가 없습니다.`);

          return false;
        }

        const today = commonSVC.getDate(new Date(), 'yyyy-MM-dd');

        // 엘지 대리점인 경우 당일 수집 문의는 삭제 불가처리
        if (lgCustom && type === 'delete' && list.some(({ wdate }) => commonSVC.getDate(wdate, 'yyyy-MM-dd') === today)) {
          await commonSVC.showConfirmCustom({
            title: '문의 삭제',
            text: ('당일 수집된 문의 데이터는 삭제가 불가능합니다.'),
            html: true,
            showCancelButton: true,
            confirmButtonColor: '#5c90d2',
            confirmButtonText: '삭제 가능한 문의만 선택해두기',
            cancelButtonText: '취소',
            animation: false,
          }, () => {
            const reselected_indexes = $scope.grid.methods.doSelectByFilter(({ wdate }) => today > commonSVC.getDate(wdate, 'yyyy-MM-dd'), true);

            if (!reselected_indexes.length) {
              commonSVC.showToaster('error', '자동 선택 불가', '삭제 가능한 건이 없습니다.');

            }
          });

          return false;
        } else {
          const templatePath = 'views/order/customer_inquiry/answer.html';
          const resolve = {
            data: { inq_uniq: list, type, title },
            template: type === 'answer' ? async function () {
              try {
                return await customerInquiryModel.loadAnswerTemplate();
              } catch (err) {
                commonSVC.showToaster('error', '실패', '문의 답변 템플릿 조회에 실패하였습니다.');
              }
            } : null
          };

          let size = 'lg';

          if (type !== 'answer') {
            size = 's';
          }
          const modalInstance = commonSVC.openModal(size, resolve, 'OrderCustomerInquiryAnswerCtrl', templatePath);

          // modal 결과 처리
          modalInstance.result.then(function() {
            $scope.searchDo(false);
          });
        }
      } catch (err) {
        commonSVC.showMessage('문의 확인 실패');
      }
    };

    /**
     * 문의 수집 선택 쇼핑몰 클릭 시
     */
    $scope.csCollect = function () {

      // 문의 수집 권한 확인
      if (commonSVC.checkPermission('inquiry.roles.scrapCs', userInfo.permission) === false) {
        return false;
      }

      const templatePath = 'views/order/customer_inquiry/collect.html';
      const resolve = {
        data: function() {
          return channelListArr;
        }
      };
      const modalInstance = commonSVC.openModal('lm', resolve, 'OrderCustomerInquiryCollectCtrl', templatePath);

      // modal 결과 처리
      modalInstance.result.then(function () {
        $scope.searchDo(true);
      });
    };

    /**
     * 답변 템플릿 설정 버튼 클릭시
     * */
    $scope.answerTemplate = function () {
      const resolve = {};

      commonSVC.openModal('lg', resolve, 'OrderCustomerInquiryAnswerTempListCtrl', 'views/order/customer_inquiry/answer_template_list.html');
    };

    // 이전 문의 내역 모달창
    $scope.previousInquiryModalOpen = function (inq) {
      commonSVC.openModal('xg', { inq }, 'OrderSameCustomerInquiryListCtrl', 'views/order/customer_inquiry/same_customer_inquiry_list.html');
    };

    /**
     * 엑셀 다운 모달창
     * */
    $scope.excelDown = function (type) {

      // 엑셀권한 확인
      if (commonSVC.checkPermission('inquiry.roles.excelCs', userInfo.permission) === false) {
        return false;
      }

      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: angular.copy(csSVC.excelFieldList),
        title: '문의&상품평',
        url: '/app/inquiries/excelDown',
        searchForm: $scope.pageData,
        page: 'cs'
      };

      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;

        if (resolve.data.count === 0) {
          commonSVC.showMessage(gettextCatalog.getString('다운로드 가능한 문의가 없습니다.'));

          return false;
        }
      } else {
        const uniqList = $scope.grid.methods.selectedData('inq_uniq');

        if (uniqList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 문의가 없습니다.'));

          return false;
        } else {
          resolve.data.uniq = uniqList;
          resolve.data.count = uniqList.length;
          resolve.data.isAll = false;
        }
      }

      // 엘지 계정인 경우 개인정보 마스킹처리
      if (lgCustom) {
        resolve.data.contentMasking = true;
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 테이블 컬럼 사이즈 확인
     * */
    $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
      if (toState.name == 'main.order_customer_inquiry_list') {
        const $wrapper = $('#customer_inquiry_grid_wrapper');
        const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

        if ($tableHeader.width() < 1) {
          $timeout(function () {
            $scope.grid.methods.calcLayout('set_width');
          }, 0);
        }
      }
    });

    // 해당 버튼에 리스트 카운트 출력
    function drawBtn (list) {
      const confirmCnt = list.filter(inq => inq.inq_status !== '확인완료'
      && ((inq.inq_type === '상품문의' && !inq.en_sendCS_yn)
        || (inq.inq_type === '상품평' && !inq.en_sendRCS_yn)
        || (inq.inq_type === '긴급메세지' && !inq.en_sendECS_yn))).length;

      const answerCnt = list.filter(inq => !['답변전송완료', '확인완료', '답변작성완료', '답변전송실패'].includes(inq.inq_status)
        && !((inq.shop_cd == 'A112' || inq.shop_cd == 'A027') && inq.inq_type == '상품평')
        && ((inq.inq_type === '상품문의' && inq.en_sendCS_yn)
        || (inq.inq_type === '상품평' && inq.en_sendRCS_yn)
        || (inq.inq_type === '긴급메세지' && inq.en_sendECS_yn))).length;

      const sendCnt = list.filter(inq => inq.inq_status === '답변작성완료' || inq.inq_status === '답변전송실패').length;
      const deleteCnt = list.length;

      if (!list.length) {
        doResetBtn();
      } else {
        !confirmCnt ? $('#btn-confirm').attr('disabled', true).html('확인 처리 (0)') : $('#btn-confirm').attr('disabled', false).html(`확인 처리 (${confirmCnt})`);
        !answerCnt ? $('#btn-answer').attr('disabled', true).html('답변 작성 (0)') : $('#btn-answer').attr('disabled', false).html(`답변 작성 (${answerCnt})`);
        !sendCnt ? $('#btn-send').attr('disabled', true).html('답변 전송 (0)') : $('#btn-send').attr('disabled', false).html(`답변 전송 (${sendCnt})`);
        $('#btn-delete').attr('disabled', false).html(`삭제(${deleteCnt})`);
      }
    }

    // 데이터 테이블에서 선택한 rowList 정보 받아옴
    $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
      selectedAllRowList = data;

      drawBtn(selectedAllRowList);
    }, 200));

    /**
     * 사이드바 집계 클릭시
     */
    $scope.$on('sidebarSearch_inquiry', function() {
      $scope.searchDo(true, true);
    });

    /**
     * 주문번호 클릭시
     * */
    $scope.goAnotherMenu = function(order_code) {
      $rootScope.order_search.type = 'order_code';
      $rootScope.order_search.key = 'shop_ord_no';
      $rootScope.order_search.input = order_code;
      $rootScope.order_search.name = '주문번호';
      $rootScope.order_search.search = true;
      $rootScope.order_search.page = 'integrated';
      $state.go('main.order_shipment_integrated_list');
      $rootScope.$broadcast('customerSearch_integrated');
    };

    $scope.changeTemplate = function (template_no) {
      const template = (_.filter($scope.templateList, function (t) {
        return t.template_no === template_no;
      }))[0];
      $scope.selectedInq.ans_title = template?.template_name || '';
      $scope.selectedInq.ans_content = template?.template_data || '';
    };

    /**
     * 문의 상태 강제변경
     * */
    function userChangeStatus (status) {
      const uniqList = $scope.grid.methods.selectedData('inq_uniq');

      if (!uniqList.length) {
        commonSVC.showMessage('선택된 문의가 없습니다.');

        return false;
      }

      const [ ori_status ] = $scope.grid.methods.selectedData('inq_status');
      let viewText = '';

      if (ori_status === '답변작성완료' && status === '답변전송완료') {
        viewText = `답변전송완료 상태로 변경하시면 플토2.0에서만 변경되며<br/>
          쇼핑몰에는 답변이 전송되지 않습니다.<br/><br/>
          
          쇼핑몰로 답변을 전송하시려면 상단의 [답변전송] 버튼을 이용해주세요.`;
      } else if (ori_status === '답변전송완료' && status === '신규문의') {
        viewText = `신규문의 상태로 변경하시면 플토2.0에서만 변경되며<br/>
          작성하신 답변 내용은 유지됩니다.<br/>
          쇼핑몰에서 처리가 완료된 문의인 경우<br/>
          내용 수정 후 [답변전송]시 작업이 실패될 수 있습니다.<br/><br/>
          
          이미 처리된 답변의 내용 수정은 쇼핑몰 판매자센터에서 수정해주세요.`;
      } else if (ori_status === '신규문의' && status === '답변전송완료') {
        viewText = `답변전송완료 상태로 변경하시면 플토2.0에서만 변경되며<br/>
          쇼핑몰에는 답변이 전송되지 않습니다.<br/><br/>
          
          쇼핑몰로 답변을 전송하시려면 답변 내용 저장 후<br/>
          상단의 [답변전송] 버튼을 이용해주세요.`;
      } else if (ori_status === '답변전송실패' && status === '답변작성완료') {
        viewText = `답변작성완료 상태로 변경하시면 플토2.0에서만 변경됩니다.<br/><br/>
        변경된 후에는 상단의 [답변전송] 버튼을 이용해주세요.`;
      }

      const confirmText = `
      <table width='100%' height='100%' style="border: 1px solid; border-color:silver;">
        <tr>
          <td style="padding: 20px;">
            <b>상태 수동 변경 안내</b>
            <div style="padding-left: 20px; padding-top: 10px; padding-bottom: 10px;">
              ${viewText}
            </div>
          </td>
        </tr>
      </table>
      <br/>
      <b>상태를 변경하시겠습니까?</b>
      `;

      commonSVC.showConfirmHtml(`문의 상태를 ${status}로 변경하시겠습니까?`, confirmText, function (re) {
        if (re) {
          customerInquiryModel.setStatus({ status, uniqList })
            .then(function(result) {
              if (result?.data === 'success') {
                commonSVC.showToaster('success', '성공', '문의 상태변경에 성공하였습니다');
                $scope.searchDo(false);
              } else {
                throw new Error('문의 상태변경에 실패하였습니다.');
              }
            })
            .catch(function(err) {
              commonSVC.showToaster('error', '실패', err.message);
            });
        }
      });
    }
  } // END function
})();
