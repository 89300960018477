/**
 * 2017-12-19 Roy
 * 모델명/브랜드/제조사 검색
 */
'use strict';

angular.module('gmpApp')
  .controller('modelSearchCtrl', function ($scope, commonSVC, commonModel, shopAccountModel, $uibModalInstance, data, onlineProductModel, siteInfo, $timeout) {
    $scope.tabActive = 0;
    $scope.scanning = false;
    $scope.searchKey = data.key || '';

    let esmChk = false;

    //5대 마켓 리스트
    const site_list = _.filter(data.site_list, function(row) {

      // 모델, 브랜드, 제조사 검색 가능 여부가 모두 false인 경우 탭으로 생성되지 않도록 처리
      if (!siteInfo[row.shop_cd].model_search_avail_yn && !siteInfo[row.shop_cd].brand_search_avail_yn && !siteInfo[row.shop_cd].maker_search_avail_yn) {
        return false;
      }

      const shop_code = row.pa_shop_cd;

      row.scanning = false;
      if ((shop_code == 'A001' || shop_code == 'A006') && !esmChk) {
        row.shop_cd = shop_code;
        row.shop_name = 'ESM+';
        esmChk = true
        ;

        return true;
      } else if (shop_code == 'B616') {
        row.shop_name = '카페24';

        return true;
      } else {
        return !!row.shop_name;
      }
    });

    $scope.site_list = _.uniqBy(site_list, 'shop_cd');

    /**
     * 2017-01-03 Daniel
     * userInfo
     */
    const shop_cd = $scope.site_list[$scope.tabActive].shop_cd;
    const shop_id = $scope.site_list[$scope.tabActive].shop_id;
    let param = { shop_cd: shop_cd, shop_id: shop_id };
    let userInfo = null;

    shopAccountModel.load(param)
      .then(function (res) {
        userInfo = res.data.results;

        //2018-03-21 roy 상품 수정시 검색 키워드 있는 경우 바로 검색추가
        if (data.key) { $scope.scan(); }
      });

    // 갤러리아몰은 정보가 간단해 처음부터 불러와서 미리 저장해둠
    const loadGalleriaModels = (param, idx) => {
      $scope.site_list[idx].scanning = true;
      $scope.site_list[idx].result = [];
      commonSVC.requestPA(param, param.domain, 'etc.get_brand', { pumbun_no: param.cate_info.pumbun_no }, commonModel, shopAccountModel)
        .then(function (re) {
          const data = re.data.data.message;

          data.forEach(function (v) {
            const item = {
              selected: false,
              brand: v.brand_nm,
              brand_key: v.brand_no
            };

            $scope.site_list[idx].result.push(item);
          });

          makeModelGridData($scope.site_list[idx]);

          // 검색용 변수
          $scope.site_list[idx].origin_result = angular.copy($scope.site_list[idx].result);
          $scope.site_list[idx].scanning = false;
        })
        .catch(function (err) {
          // 검색용 변수
          $scope.site_list[idx].origin_result = [];
          $scope.site_list[idx].scanning = false;
        });
    };

    $scope.pa5_job_data = {
      get_brands: { load: false, data: [] },
      get_manufacturers: { load: false, data: [] },
      get_models: { load: false, data: [] }
    };

    const run_pa5_jobs = async (job_name, push_data, idx, temp_saver) => {
      const matchJobNames = { get_brands: '브랜드', get_manufacturers: '제조사', get_models: '모델' };
      const job_kor_name = matchJobNames[job_name];

      try {
        const accountRes = await shopAccountModel.load({ shop_cd: $scope.site_list[idx].shop_cd, shop_id: $scope.site_list[idx].shop_id });

        userInfo = accountRes.data.results;

        $scope.site_list[idx].uuid = userInfo.uuid;

        const re = await commonModel.pa5(`/channel_accounts/${userInfo.uuid}/etc/${job_name}/sync`, push_data, $scope.site_list[idx].shop_cd, $scope.site_list[idx].shop_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver($scope.site_list[idx].pa_shop_cd === 'A077' ? re.data.results[0].data.data : re.data.results[0].data) :
            ($scope.site_list[idx].pa_shop_cd === 'A077' ? re.data.results[0].data.data : re.data.results[0].data);
          $scope.pa5_job_data[job_name].load = true;
          $scope.site_list[idx].origin_result = [];

          let loopCnt = 0;

          $scope.pa5_job_data[job_name].data.forEach(function (v) {

            let item = {
              selected: false,
              pa_shop_cd: $scope.site_list[idx].pa_shop_cd
            };

            if ($scope.site_list[idx].result[loopCnt]) {
              item = $scope.site_list[idx].result[loopCnt];
            }

            if (job_name === 'get_brands') {
              // ESM 특별처리 추가
              if (['A001', 'A006'].includes(item.pa_shop_cd)) {
                item.brand = v.brand_name || '';
                item.BrandSeq = v.brand_code || '';
                item.maker = v.manufacturer_name || '';
                item.MakerSeq = v.manufacturer_code || '';
                item.ProductBrandName = v.product_brand_name || '';
                item.ProductBrandSeq = v.product_brand_code || '';
                $scope.site_list[idx].brandResult.push(item);
              } else {
                item.brand = v.brand_name || v.name || '';
                item.brand_code = v.code || '';
                item.brand_key = v.partner_brand_no || v.code || '';
                item.brand_kor = v.front_brand_name || v.name || '';
              }
            } else if (job_name === 'get_manufacturers') {
              item.maker = v.name || '';
              item.maker_code = v.code || '';
            } else if (job_name === 'get_models') {
              if ($scope.site_list[idx].pa_shop_cd === 'A077') {
                item.brand = v.brand || '';
                item.brand_code = '';
                item.model = v.productName || '';
                item.model_code = v.productId || '';
                item.maker = v.maker || '';
                item.maker_code = '';
              } else {
                item.model = v.name || '';
                item.model_code = v.code || '';
              }
            }

            $scope.site_list[idx].result[loopCnt] = item;

            loopCnt++;
          });

          // 검색용 변수
          if ($scope.searchKey && !Object.keys(push_data).length) {
            $scope.site_list[idx].result = $scope.site_list[idx].result.filter(cur => Object.values(cur).includes($scope.searchKey));
          }

          if ($scope.site_list[idx].pa_shop_cd === 'A077') {
            $scope.site_list[idx].totalPage = Math.floor(re.data.results[0].data.total_count / 100);
          }

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    $scope.site_list.forEach((shop_, idx) => {
      if (shop_.shop_cd === 'B394') {
        loadGalleriaModels(shop_, idx);

        return false;
      }
    });

    //모델명, 브랜드, 제조사 검색
    //auto == true 검색버튼 클릭
    $scope.scan = async function(auto, isInitial = true) {
      let action_types = [];

      const tabInfo = $scope.tabInfo = $scope.site_list[$scope.tabActive];

      //if($scope.searchKey == '') return false; //주석이유 : 빈문자열 검색시 전체 리스트 나오도록 함

      if (tabInfo.pa_shop_cd == 'A112') { //11번가
        action_types = ['search_product_model'];
      } else if (['A001', 'A006'].includes(tabInfo.pa_shop_cd)) { //esm
        action_types = ['get_brands'];
      } else if (['A027'].includes(tabInfo.pa_shop_cd)) { // 인터파크
        action_types = ['search_brand'];
      } else if (['A004'].includes(tabInfo.pa_shop_cd)) { // cj
        action_types = ['etc.search_brand', 'etc.search_manufacturer'];
      } else if (['B603'].includes(tabInfo.pa_shop_cd)) { // 이제너두
        action_types = ['etc.search_brand', 'etc.search_manufacturer'];
      } else if (['B616'].includes(tabInfo.pa_shop_cd)) { // 카페
        action_types = ['product.makerinfo.scrap'];
      } else if (['B719'].includes(tabInfo.pa_shop_cd)) { // 위메프
        action_types = ['product.brand.scrap', 'product.maker.scrap'];
      } else if (['B917', 'B687'].includes(tabInfo.pa_shop_cd)) { //고도몰, 우먼스톡(고도몰 어드민)
        action_types = ['etc.get_brand'];
      } else if (['B878'].includes(tabInfo.pa_shop_cd)) { // 신세계TV쇼핑
        action_types = ['etc.brand'];
      } else if (tabInfo.pa_shop_cd == 'A064') { // SK스토아
        action_types = ['etc.brand', 'etc.model'];
      } else if (['A524'].includes(tabInfo.pa_shop_cd)) { // 롯데ON
        action_types = ['etc.get_brand'];
      } else if (['B394'].includes(tabInfo.pa_shop_cd)) { // 갤러리아몰
        tabInfo.scanning = true;
        if (!$scope.searchKey || $scope.searchKey == '') {
          tabInfo.result = angular.copy(tabInfo.origin_result);
        } else {
          tabInfo.result = tabInfo.origin_result.reduce((aur, cur) => {
            if (cur.brand.includes($scope.searchKey)) {
              aur.push(cur);
            }

            return aur;
          }, []);
        }
        tabInfo.scanning = false;

        return;
      } else if (['B720'].includes(tabInfo.pa_shop_cd)) { // 스포츠매니아
        action_types = ['etc.get_brands'];
      }
      // SSG오픈마켓, 롯데슈퍼
      else if (['A032', 'A076', 'A085', 'P020', 'B696', 'P028', 'B959', 'B957', 'P018', 'A124', 'P044', 'P046', 'A100', 'P048', 'B688', 'P050', 'A017', 'A536', 'B877', 'A537', 'A176', 'A526', 'P016'].includes(tabInfo.pa_shop_cd)) {
        action_types = ['get_brands'];
      }
      // 홈플러스
      else if (['A133', 'B502', 'B325', 'B690', 'B614', 'B594', 'A004', 'A175', 'A178', 'A179'].includes(tabInfo.pa_shop_cd)) {
        action_types = ['get_brands', 'get_manufacturers'];
      }
      // 컴퓨존
      else if (['P037'].includes(tabInfo.pa_shop_cd)) {
        action_types = ['get_manufacturers'];
      }
      // 브랜드, 모델명 조회만 사용하는 경우 (멸치쇼핑, 카카오 선물하기)
      else if (['P045', 'P047', 'B700'].includes(tabInfo.pa_shop_cd)) {
        action_types = ['get_brands', 'get_models'];
      }
      // 모델명 조회만 사용 (스마트스토어)
      else if (['A077'].includes(tabInfo.pa_shop_cd)) {
        action_types = ['get_models'];
      }
      else {
        action_types = ['search_product_model'];
      }

      let scan_count = 0;

      const scanPromises = [];

      _.map(action_types, async function(action) {

        let userInfo1 = {};
        let addInfo = {};

        // 검색란이 공백이 아닐 경우, 이전에 검색한 기록이 없으면 검색
        if (((tabInfo.isSearch != $scope.searchKey) && $scope.searchKey) || auto) {
          if (isInitial) {
            $scope.scanning = true;
            tabInfo.scanning = true;
            tabInfo.result = [];
            tabInfo.brandResult = [];
            tabInfo.page = 0; // 검색입력 조회시 엔진에서 조회한 리스트 인덱스 시작점
            tabInfo.size = 100; // 검색입력 조회시 최대 제한되는 리스트 갯수
          }

          const data = {
            action_type: action,
            account: {
              seller_id: tabInfo.shop_id,
              seller_pw: tabInfo.shop_pwd,
              etc1: tabInfo.etc1,
              etc2: tabInfo.etc2,
              etc3: tabInfo.etc3,
              etc4: tabInfo.etc4,
              etc5: tabInfo.etc5,
              etc6: tabInfo.etc6,
              etc7: tabInfo.etc7,
            },
            shop_cd: tabInfo.shop_cd,
            pa_shop_cd: tabInfo.pa_shop_cd,
            keyword: $scope.searchKey,
            page: tabInfo.page,
            size: tabInfo.size,
          };

          /**
           * 2017-01-03 Daniel
           * GS SHOP 브랜드조회 추가
           */
          if (data.pa_shop_cd === 'A524') {
            const param = {
              brand: data.keyword
            };

            commonSVC.requestPA(userInfo, 'lotteon.com', action, param, commonModel, shopAccountModel)
              .then((result) => {
                const data = result.data.data.items;

                _.each(data, function(v, k) {
                  const item = { selected: false };

                  item.brand = v.data.brnd_nm;
                  item.brand_key = v.data.brnd_id;
                  tabInfo.result.push(item);
                });

                makeModelGridData(tabInfo);

                tabInfo.scanning = false;
                $scope.scanning = false;
                $timeout(() => {});
              });
          } else if (data.pa_shop_cd === 'A011') {
            if (userInfo) {
              userInfo1 = {
                shop_id: tabInfo.shop_id,
                shop_pwd: tabInfo.shop_pwd,
                etc2: userInfo.etc2, // 2차비밀번호
                pa_shop_cd: tabInfo.pa_shop_cd || ''
              };

              addInfo = {
                brand_name: $scope.searchKey
              };

              commonSVC.requestPA(userInfo1, 'gsshop.com', 'get_brand_code', addInfo, commonModel, shopAccountModel)
                .then(function(res) {
                  if (res.data.data.status == 200) {
                    const data = res.data.data.items;

                    _.each(data, function(v, k) {
                      const item = { selected: false };

                      item.brand = v;
                      item.brand_key = k;
                      tabInfo.result.push(item);
                    });

                    makeModelGridData(tabInfo);

                    tabInfo.scanning = false;
                    $scope.scanning = false;
                    $timeout(() => {});
                  }
                });
            }
          } else if (data.pa_shop_cd === 'B917') {
            if (userInfo) {

              // 쇼핑몰 계정 정보 가져오기
              param = { shop_cd: data.shop_cd, shop_id: tabInfo.shop_id };

              // 콜백함수를 프로미스로 넘김
              shopAccountModel.load(param, function (state, data) {
                const userInfo1 = {
                  shop_id: tabInfo.shop_id,
                  shop_pwd: tabInfo.shop_pwd,
                  etc1: data.results.etc1,
                  etc2: data.results.etc2,
                  etc3: data.results.etc3,
                  pa_shop_cd: tabInfo.pa_shop_cd || ''
                };

                const addInfo = {
                  brand_search: $scope.searchKey
                };

                commonSVC.requestPA(userInfo1, 'godo.co.kr', 'etc.get_brand', addInfo, commonModel, shopAccountModel)
                  .then(function(res) {
                    if (res.data.data.status == 200) {
                      const data = res.data.data.items;

                      _.each(data, function(v, k) {
                        const item = { selected: false };

                        item.brand = v;
                        item.brand_key = k;
                        tabInfo.result.push(item);
                      });

                      makeModelGridData(tabInfo);
                      tabInfo.scanning = false;
                      $scope.scanning = false;
                    }
                  });
              });
            }
          } else if (data.pa_shop_cd === 'B687') {
            if (userInfo) {

              // 쇼핑몰 계정 정보 가져오기
              param = { shop_cd: data.shop_cd, shop_id: tabInfo.shop_id };

              // 콜백함수를 프로미스로 넘김
              shopAccountModel.load(param, function (state, data) {
                const userInfo1 = {
                  shop_id: tabInfo.shop_id,
                  shop_pwd: tabInfo.shop_pwd,
                  etc1: data.results.etc1,
                  pa_shop_cd: tabInfo.pa_shop_cd || ''
                };

                const addInfo = {
                  word: $scope.searchKey
                };

                commonSVC.requestPA(userInfo1, 'womanstalk.co.kr', 'etc.get_brand', addInfo, commonModel, shopAccountModel)
                  .then(function(res) {
                    if (res.data.data.status == 200) {
                      const data = res.data.data.items;

                      data.forEach(function({ cd, name }) {
                        const item = { selected: false };

                        item.brand = name;
                        item.brand_code = cd;
                        tabInfo.result.push(item);
                      });

                      makeModelGridData(tabInfo);

                      tabInfo.scanning = false;
                      $scope.scanning = false;
                    }
                  });
              });
            }
          } else if (data.pa_shop_cd === 'B616') {
            if (userInfo) {
              // 쇼핑몰 계정 정보 가져오기
              param = { shop_cd: data.shop_cd, shop_id: tabInfo.shop_id };

              // 콜백함수를 프로미스로 넘김
              shopAccountModel.load(param, function (state, data) {
                // api 프로미스 반환
                commonSVC.requestPA(data.results, 'cafe24.com', action, '', commonModel, shopAccountModel)
                  .then(function(res) {
                    _.each(res.data.data.sale_brands, function(v) {
                      if ($scope.searchKey == '' || v.brand_name.indexOf($scope.searchKey) != -1) {
                        const item = { selected: false };

                        item.brand = v.brand_name;
                        item.brand_code = v.brand_code;

                        tabInfo.result.push(item);
                      }
                    });

                    _.each(res.data.data.sale_manufacturers, function(v) {
                      if ($scope.searchKey == '' || v.manufacturer_name.indexOf($scope.searchKey) != -1) {
                        const item = { selected: false };

                        item.maker = v.manufacturer_name;
                        item.maker_code = v.manufacturer_code;

                        tabInfo.brandResult.push(item);
                      }
                    });

                    makeModelGridData(tabInfo);

                    tabInfo.scanning = false;

                    $scope.scanning = false;
                  });
              });
            }
          } else if (data.pa_shop_cd === 'B691') {
            if (userInfo) {
              // 쇼핑몰 계정 정보 가져오기
              param = { shop_cd: data.shop_cd, shop_id: tabInfo.shop_id };

              // 콜백함수를 프로미스로 넘김
              shopAccountModel.load(param)
                .then(function(res) {
                  const userInfo = res.data.results;
                  const addInfo = {
                    word: $scope.searchKey
                  };

                  return commonSVC.requestPA(userInfo, 'godomall.com', 'etc.get_brand', addInfo, commonModel, shopAccountModel);
                })
                .then(function (res) {
                  if (res.data.data.status == 200) {
                    const data = res.data.data.items;

                    _.each(data, function (v) {
                      const item = { selected: false };

                      item.brand = v.name;
                      item.brand_key = v.cd;
                      tabInfo.result.push(item);
                    });

                    makeModelGridData(tabInfo);

                    tabInfo.scanning = false;
                    $scope.scanning = false;
                  }
                })
                .catch(function() {
                  tabInfo.scanning = false;
                  $scope.scanning = false;
                });
            }
          } else if (data.pa_shop_cd === 'B720') {
            // 쇼핑몰 계정 정보 가져오기
            param = { shop_cd: data.shop_cd, shop_id: tabInfo.shop_id };

            // 콜백함수를 프로미스로 넘김
            shopAccountModel.load(param, function (state, data) {
              const userInfo1 = {
                shop_id: tabInfo.shop_id,
                shop_pwd: tabInfo.shop_pwd,
                etc1: data.results.etc1,
                pa_shop_cd: tabInfo.pa_shop_cd || ''
              };

              const addInfo = {
                word: $scope.searchKey
              };

              commonSVC.requestPA(userInfo1, 'kscm.co.kr', 'etc.get_brands', addInfo, commonModel, shopAccountModel)
                .then(function(res) {
                  if (res.data.data.status == 200) {
                    const data = res.data.data.brands;

                    data.forEach(function({ code, name }) {
                      const item = { selected: false };
                      item.brand = name;
                      item.brand_key = code;
                      tabInfo.result.push(item);
                    });

                    makeModelGridData(tabInfo);

                    tabInfo.scanning = false;
                    $scope.scanning = false;
                  }
                })
                .catch(function() {
                  tabInfo.scanning = false;
                  $scope.scanning = false;
                });
            });
          } else if (data.pa_shop_cd === 'A017') { // AK몰
            if (userInfo) {
              userInfo1 = {
                shop_id: tabInfo.shop_id,
                shop_pwd: tabInfo.shop_pwd,
                pa_shop_cd: tabInfo.pa_shop_cd || ''
              };

              addInfo = {
                brand: $scope.searchKey
              };

              commonSVC.requestPA(userInfo1, 'akmall.com', 'etc.get_brands', addInfo, commonModel, shopAccountModel)
                .then(function(res) {
                  if (res.data.data.status == 200) {
                    const items = res.data.data.items;

                    _.each(items, function(item) {
                      const result = { selected: false };

                      result.brand = item.brand_name;
                      result.brand_code = item.brand_id;
                      tabInfo.result.push(result);
                    });

                    makeModelGridData(tabInfo);
                  }
                }).finally(() => {
                  tabInfo.scanning = false;
                  $scope.scanning = false;
                  $timeout(() => {});
                });
            }
          } else if (data.pa_shop_cd === 'P015') { // 29CM

            const idx = $scope.site_list.findIndex((shop) => shop.shop_cd === tabInfo.shop_cd);

            if (tabInfo.result.length) {
              if ($scope.searchKey) {
                tabInfo.result = tabInfo.origin_result.filter(cur => Object.values(cur).includes($scope.searchKey));
              }

              tabInfo.scanning = false;
              $scope.scanning = false;

            } else { // 초기값 설정
              scanPromises.push(run_pa5_jobs('get_brands', {}, idx));
            }

            return;
          }
          // 29CM 제외 검색입력을 사용하는 PA5 쇼핑몰
          else if (['A133', 'B502', 'B325', 'B690', 'A076', 'A032', 'A085', 'P020', 'B696', 'P028', 'B959', 'B957', 'P018', 'A124', 'P037', 'B614', 'P044', 'P045', 'P046', 'P047', 'P048', 'A100', 'B688', 'P050', 'B700', 'A001', 'A006', 'A017', 'A536', 'A537', 'A077', 'B594', 'B877', 'A176', 'A175', 'A178', 'A179', 'A526'].includes(data.pa_shop_cd)) {
            const idx = $scope.site_list.findIndex((shop) => shop.shop_cd === tabInfo.shop_cd);

            if (tabInfo.result.length) {
              tabInfo.scanning = false;
              $scope.scanning = false;

            } else { // 초기값 설정
              // page: 페이지 별로 조회 스마트스토어는 모델명으로 전체 검색을 하므로 너무 많을 경우 브라우저가 죽는 경우가 있어서 사용
              // (나머지 쇼핑몰은 전체 조회하므로 사용 안함)
              scanPromises.push(run_pa5_jobs(action, { name: $scope.searchKey, page: 1 }, idx));
            }

            return;
          }
          else {
            onlineProductModel.getMadeInfo(data, function (state, re) {
              let idx = 0;

              for (let i2 = 0; i2 < $scope.site_list.length; i2++) {
                if ($scope.site_list[i2].pa_shop_cd == data.pa_shop_cd) {
                  idx = i2;
                  break;
                }
              }
              $scope.site_list[idx].isSearch = angular.copy($scope.searchKey);
              for (const i in re.results) {
                let item = { selected: false };

                if ($scope.site_list[idx].pa_shop_cd == 'A112') { //11번가 검색 결과
                  item.model = re.results[i].dispModelNm;
                  item.brand = re.results[i].brandNm;
                  item.maker = re.results[i].mnfcNm;
                  item.ctlgNo = Number(re.results[i].ctlgNo);
                  $scope.site_list[idx].result.push(item);
                } else if ($scope.site_list[idx].pa_shop_cd == 'A027') { //인터파크 검색 결과
                  item.model = '';
                  item.brand = re.results[i].name;
                  item.maker = '';
                  $scope.site_list[idx].result.push(item);
                } else if ($scope.site_list[idx].pa_shop_cd == 'A004') { //cj 검색결과
                  item = tabInfo.result[i] || {};
                  item.model = '';
                  if (data.action_type == 'etc.search_brand') { //브랜드
                    item.brand = re.results[i].name;
                    item.brand_code = re.results[i].code;
                  }
                  else {
                    item.maker = re.results[i].name;
                    item.maker_code = re.results[i].code;
                  }
                  tabInfo.result[i] = item;
                } else if ($scope.site_list[idx].pa_shop_cd == 'A011') {
                  item.model = '';
                  item.brand = re.results[i];
                  item.maker = '';
                } else if ($scope.site_list[idx].pa_shop_cd == 'B719') {
                  item = tabInfo.result[i] || {};

                  item.model = '';
                  if (data.action_type == 'product.brand.scrap') { //브랜드
                    item.brand = re.results[i].brandName;
                    item.brand_code = re.results[i].brandNo;
                  }
                  else {
                    item.maker = re.results[i].makerName;
                    item.maker_code = re.results[i].makerNo;
                  }
                  tabInfo.result[i] = item;
                } else if ($scope.site_list[idx].pa_shop_cd == 'B603') {
                  item = tabInfo.result[i] || {};

                  item.model = '';
                  if (data.action_type == 'etc.search_brand') { //브랜드
                    item.brand = re.results[i].brndNm;
                    item.brand_code = re.results[i].brndNo;
                  }
                  else {
                    item.maker = re.results[i].mfcoNm;
                    item.maker_code = re.results[i].mfcoNo;
                  }
                  tabInfo.result[i] = item;
                } else if (['B878'].includes($scope.site_list[idx].pa_shop_cd)) { // 신세계TV쇼핑
                  item.brand = re.results[i].brandName;
                  item.brand_code = re.results[i].brandCode;
                  tabInfo.result[i] = item;
                } else if ($scope.site_list[idx].pa_shop_cd == 'A064') { // SK스토아
                  item = tabInfo.result[i] || {};

                  item.model = '';
                  if (data.action_type == 'etc.brand') { //브랜드
                    item.brand = re.results[i].brandName;
                    item.brand_code = re.results[i].brandCode;
                  }
                  else {
                    item.model = re.results[i].modelName;
                    item.model_code = re.results[i].modelCode;
                  }
                  tabInfo.result[i] = item;
                } else { //스마트스토어 검색 결과
                  item.model = re.results[i].productName;
                  item.brand = re.results[i].brand;
                  item.maker = re.results[i].maker;
                  $scope.site_list[idx].result.push(item);
                }
              }

              scan_count += 1;
              if (scan_count == action_types.length) {
                makeModelGridData(tabInfo);

                $scope.site_list[idx].scanning = false;
                $scope.scanning = false;
              }

            });
          }
        }
      });

      if (scanPromises.length) {
        await Promise.all(scanPromises)
          .then(() => {
            makeModelGridData(tabInfo);

            tabInfo.scanning = false;
            $scope.scanning = false;
            $timeout(() => {});
          })
          .catch(() => {
            tabInfo.scanning = false;
            $scope.scanning = false;
          });
      }
    };

    // 모델명, 브랜드, 제조사 각각입력 사용하는 경우
    const eachSelectedList = {};

    $scope.eachSelectedItem = function(type, list, item) {
      const shop_cd = list.pa_shop_cd;

      if (eachSelectedList[shop_cd] === undefined) { eachSelectedList[shop_cd] = {}; }
      const matchData = list.result.find(row =>
        row.model === item.model && row.model_code === item.model_code &&
        row.brand === item.brand && row.brand_code === item.brand_code &&
        row.maker === item.maker && row.maker_code === item.maker_code
      );

      if (type == 'model') {
        list.result.forEach(function(row) { row.model_selected = false; });
        list.dispData.forEach(function(row) { row.model_selected = false; });

        item.model_selected = true;
        matchData.model_selected = true;
        eachSelectedList[shop_cd].model = matchData.model;
        eachSelectedList[shop_cd].model_code = matchData.model_code;
        if (shop_cd === 'B700') {
          eachSelectedList[shop_cd].maker = matchData.maker;
        }
      }
      else if (type == 'brand') {
        list.result.forEach(function(row) { row.brand_selected = false; });
        list.dispData.forEach(function(row) { row.brand_selected = false; });

        item.brand_selected = true;
        matchData.brand_selected = true;
        eachSelectedList[shop_cd].brand = matchData.brand;
        eachSelectedList[shop_cd].brand_code = matchData.brand_code;
      }
      else if (type == 'maker') {
        list.result.forEach(function(row) { row.maker_selected = false; });
        list.dispData.forEach(function(row) { row.maker_selected = false; });

        item.maker_selected = true;
        matchData.maker_selected = true;
        eachSelectedList[shop_cd].maker = matchData.maker;
        eachSelectedList[shop_cd].maker_code = matchData.maker_code;
      }

      matchData.shop_name = list.shop_name;
      matchData.shop_cd = list.shop_cd;
      matchData.pa_shop_cd = list.pa_shop_cd;
    };

    // 모델명, 브랜드, 제조사 공통입력 사용하는 경우
    $scope.selectedItem = function(list, item_idx, brand, item) {
      let reset = true;

      // 카페24는 제조사, 브랜드 각각 설정할 수 있도록 처리
      if (list.pa_shop_cd === 'B616') { reset = false; }
      const matchData = list.result.find(row => (row.model === item.model && row.model_code === item.model_code) && (row.brand === item.brand && row.brand_code === item.brand_code) && (row.maker === item.maker && row.maker_code === item.maker_code));

      _.forEach(list.result, function(v, k) {
        list.result[k].shop_name = list.shop_name;
        list.result[k].shop_cd = list.shop_cd;
        list.result[k].pa_shop_cd = list.pa_shop_cd;
        if (reset || !brand) { list.result[k].selected = false; item.selected = false; }
      });
      _.forEach(list.brandResult, function(v, k) {
        list.brandResult[k].shop_name = list.shop_name;
        list.brandResult[k].shop_cd = list.shop_cd;
        list.brandResult[k].pa_shop_cd = list.pa_shop_cd;
        if (reset || brand) { list.brandResult[k].selected = false; }
      });
      if (brand) { list.brandResult[item_idx].selected = true; }
      else {
        matchData.selected = true;
        item.selected = true;
      }
    };

    // $scope.model_shop_list = ['A077', 'A112', 'B700', 'A064'];
    $scope.model_shop_list = Object.keys(siteInfo).filter(shop_cd => siteInfo[shop_cd].model_search_avail_yn && shop_cd === siteInfo[shop_cd].pa_shop_cd).map(shop_cd => shop_cd);
    // $scope.brand_shop_list = ['A112', 'A077', 'A027', 'A011', 'A004', 'B917', 'B687', 'B603', 'B614', 'B616', 'B719', 'B394', 'A524', 'B594', 'A064', 'B878', 'B700', 'P015', 'A032', 'A076', 'A085', 'B502', 'B957', 'A133', 'P020', 'B959', 'B325', 'B696', 'P028', 'B690', 'P018', 'A124'];
    $scope.brand_shop_list = Object.keys(siteInfo).filter(shop_cd => siteInfo[shop_cd].brand_search_avail_yn && shop_cd === siteInfo[shop_cd].pa_shop_cd).map(shop_cd => shop_cd);
    // $scope.maker_shop_list = ['A077', 'A112', 'A004', 'B603', 'B614', 'B719', 'B502', 'A133', 'B325', 'P037'];
    $scope.maker_shop_list = Object.keys(siteInfo).filter(shop_cd => siteInfo[shop_cd].maker_search_avail_yn && shop_cd === siteInfo[shop_cd].pa_shop_cd).map(shop_cd => shop_cd);

    /**
     * 2018-05-03 roy 모델명, 브랜드, 제조사 사용 유무 추가
     */
    $scope.isUse = function(type, shop_cd) {
      if (type == 'model') { return $scope.model_shop_list.indexOf(shop_cd) > -1; }
      else if (type == 'brand') { return $scope.brand_shop_list.indexOf(shop_cd) > -1; }
      else { return $scope.maker_shop_list.indexOf(shop_cd) > -1; }
    };

    //탭 선택
    $scope.clickTab = function(index) {
      $scope.tabActive = index;
      $scope.scan();
    };

    /**
     * 취소
     */
    $scope.close = function() {
      $uibModalInstance.dismiss();
    };

    /**
     * 2018-05-03 roy 모델명, 브랜드, 제조사 개별, 공통 적용 유무
     */
    $scope.isEach = function(shop_cd) {
      //개별 적용 되어야하는 쇼핑몰인 경우 쇼핑몰 코드 추가 (옥션, CJ몰, 지마켓, 더현대, 위메프2.0, 홈앤쇼핑, 카카오선물하기, SK스토아, 홈플러스, 이지웰몰, K쇼핑, 멸치쇼핑, W쇼핑, 티몬월드, 인팍쇼핑, 위메프플러스)
      const each_list = ['A001', 'A004', 'A006', 'A133', 'B719', 'B614', 'B700', 'A064', 'B502', 'B325', 'B690', 'P045', 'P047', 'B594', 'A175', 'A178', 'A179'];

      return each_list.indexOf(shop_cd) > -1;
    };

    /**
     * 적용
     */
    $scope.apply = function() {
      const select_flag = selectedItemList();

      if (select_flag.length) {
        commonSVC.showConfirm('선택한 정보를 적용하시겠습니까?', '', function (confirm) {
          if (confirm) {
            $uibModalInstance.close(select_flag);
          }
        });
      } else {
        commonSVC.showMessage('실패', '선택된 값이 없습니다.');

        return false;
      }
    };

    function selectedItemList()
    {
      const models_list = [];

      _.map($scope.site_list, function(list) {
        // 카페24는 제조사, 브랜드 각각 설정받고 저장 시 한번에 저장하도록 처리
        if (list.pa_shop_cd == 'B616') {

          let brand = '';
          let maker = '';

          if (list.result) {
            brand = list.result.filter(function(o) { return o.selected; })[0];
          }

          if (list.brandResult) {
            maker = list.brandResult.filter(function (o) { return o.selected; })[0];
          }

          if (brand && maker) {
            brand.maker = maker.maker;
            brand.maker_code = maker.maker_code;

          } else if (!brand && maker) {
            brand = maker;

          }

          if (brand || maker) { models_list.push(brand); }

        // esm은 result와 brandResult가 중복으로 들어와서 헌쪽만 처리
        } else if (['A001', 'A006'].includes(list.pa_shop_cd)) {
          const model = list.result?.find(function(m) { return m.selected; });
          if (model) { models_list.push(model); }
        } else {
          for (const i in list.result) {
            const isEach = list.result[i].model_selected || list.result[i].brand_selected || list.result[i].maker_selected;
            if (list.result[i].selected || isEach) {
              //개별입력인 경우 개별입력 데이터로 적용
              if (isEach) {
                list.result[i].model = eachSelectedList[list.pa_shop_cd].model || '';
                list.result[i].brand = eachSelectedList[list.pa_shop_cd].brand || '';
                list.result[i].maker = eachSelectedList[list.pa_shop_cd].maker || '';
                list.result[i].brand_code = eachSelectedList[list.pa_shop_cd].brand_code || '';
                list.result[i].maker_code = eachSelectedList[list.pa_shop_cd].maker_code || '';
              }
              models_list.push(list.result[i]);
              break;
            }
          }
          for (const i2 in list.brandResult) {
            if (list.brandResult[i2].selected) {
              models_list.push(list.brandResult[i2]);
              break;
            }
          }
        }
      });

      return models_list;
    }

    $('.searchKey').ready(function() {
      $('.searchKey').keyup(function(e) {
        if (e.keyCode == 13) { $scope.scan(true); }
      });
    });

    // 모델 결과 처리 함수
    function makeModelGridData (tabInfo) {
      if (tabInfo.result.length) {
        const index = $scope.tabActive;

        for (let i = 0; i < $scope.site_list[index].result.length; i++) {
          const item = $scope.site_list[index].result[i];
          const pageIdx = Math.floor(i / 100);

          if ($scope.site_list[index].totalData) {
            if (!$scope.site_list[index].totalData[pageIdx]) {
              $scope.site_list[index].totalData[pageIdx] = [];
            }

            $scope.site_list[index].totalData[pageIdx][i % 100] = item;
          } else {
            $scope.site_list[index].totalData = [[]];
            $scope.site_list[index].totalData[pageIdx][i % 100] = item;
          }
        }

        $scope.site_list[index].currentPage = 1;
        $scope.site_list[index].dispData = $scope.site_list[index].totalData[0];
        $scope.site_list[index].totalPage = $scope.site_list[index].totalPage || $scope.site_list[index].totalData.length;
      }

      $scope.site_list[$scope.tabActive] = tabInfo;
    }

    /**
     * 페이지네이션 관련 함수 (데이터 테이블의 페이지네이션과 동일한 기능하도록 커스텀)
     */
    $scope.visiblePageCount = 10; // 페이지 네이션에 표시할 페이지 수 설정

    // 페이지 번호 목록을 반환하는 함수
    $scope.getVisiblePages = function(row) {
      row.currentPage = row.currentPage || 0;
      row.totalPage = row.totalPage || 0;

      const visiblePages = [];
      const middlePage = Math.ceil($scope.visiblePageCount / 2);
      const startPage = Math.max(row.currentPage - middlePage, 1);

      const endPage = Math.min(startPage + $scope.visiblePageCount - 1, row.totalPage);

      for (let i = startPage; i <= endPage; i++) {
        visiblePages.push(i);
      }

      return visiblePages;
    };

    // 페이지 번호를 설정하여 특정 페이지로 이동
    $scope.setPage = async function(row, pageNumber) {

      if (pageNumber >= 1 && pageNumber <= row.totalPage) {
        row.scanning = true;
        row.currentPage = pageNumber;

        if (row.pa_shop_cd === 'A077' && !row.totalData[pageNumber - 1]?.length) {
          row.dispData = [];

          const re = await commonModel.pa5(`/channel_accounts/${row.uuid}/etc/get_models/sync`, { name: $scope.searchKey, page: pageNumber }, row.shop_cd, row.shop_id);
          const resultData = re.data.results[0].data.data.map(v => {
            const data = {
              brand: v.brand || '',
              brand_code: '',
              model: v.productName || '',
              model_code: v.productId || '',
              maker: v.maker || '',
              maker_code: '',
            };

            row.result.push(data);

            return data;
          });

          row.totalData[pageNumber - 1] = resultData;
        }

        row.dispData = angular.copy(row.totalData[pageNumber - 1]);
        row.scanning = false;
      }
    };
  });
