/**
 * 운송장 출력
 * Created by harry on 2017. 7. 31..
 */
'use strict';

import QRCode from 'qrcode';

angular.module('gmpApp')
  .controller('printInvoiceCtrl', function ($scope, $rootScope, $timeout, userInfo, settings, data, selectList, totalCount, commonSVC, shipmentModel, $uibModalInstance, deliveryModel, commonModel, localStorageService, invoiceTemplateSVC) {

    // clarity custom-page-id 설정
    $rootScope.setClarityCustomPageId('printInvoiceCtrl');
    $scope.custom = false;
    $scope.html = '';
    // 실제 운송장 출력 가능여부
    $scope.refusePrint = true;
    $scope.isReprint = !!data.carr_no; // 재출력 여부

    // 승인완료된 택배사 리스트
    const approveCarrierLists = [];

    $scope.type = 'preview';
    $scope.isGlobal = selectList.data.isGlobal;

    $scope.canCustom = false;
    $scope.adminMode = $rootScope.adminMode;

    // 소소한돈가 잦은 출력 오류로 테스트출력 미지원
    $scope.testPrint = userInfo.user.sol_no !== 30697;

    // 선택한 템플릿 타입코드
    let selectTemplateTypeCode = '';

    // 마스킹 처리 실패 주문 건
    let cantMaskingBundleNo = [];

    if ((selectList.data.results[0].carr_no === 107 || selectList.data.results[0].carr_no === 1007) && selectList.data.results[0].invoice_no) {
      const selectedCarrNo = selectList.data.results[0].carr_no;
      $scope.deliveryAllList = angular.copy(data.solCarrList.filter(item => item.carr_no === selectedCarrNo));
    } else {
      $scope.deliveryAllList = angular.copy(data.solCarrList.filter(o => {
        if (o.carr_id !== '' && parseInt(o.contract_yn) === 1 && parseInt(o.reg_confirm_yn) === 1) {
          if ($scope.isReprint) {
            if (o.carr_no === data.carr_no) {
              approveCarrierLists.push(`${o.carr_no}+${o.carr_id}`);
            }
          } else {
            approveCarrierLists.push(`${o.carr_no}+${o.carr_id}`);
          }
        }

        if ($scope.isReprint) {
          return o.carr_no === data.carr_no;
        } else {
          return true;
        }
      })) || [];  // 택배사 리스트
    }

    $scope.templateList = []; // 양식 리스트
    $scope.selectedDeli = {}; // 선택된 택배사 정보
    $scope.selectedTemp = {}; // 선택된 양식 정보
    $scope.modalData = data;  // 모달 정보

    $scope.selectList = selectList.data.results || [];
    $scope.selectListCount = selectList.data.recordsTotal;

    $scope.bundleListCount = Object.keys(_.groupBy($scope.selectList, 'bundle_no')).length;

    $scope.today = new Date();

    $scope.view = ''; // 프린터기 마다 출력할 html 다름
    $scope.wait = false;
    $scope.totalProdCount = 0;
    $scope.print_set_yn = 0;

    let originList;

    // 2018-08-21 chris 대한통운 송장 출력시 다이아몬드 표시 추가(대한통운 요청)
    $scope.show_diams = true;
    // if (moment().unix() > moment('2018-08-22 05:00:00').unix()) {
    //   $scope.show_diams = true;
    // }

    let isPrint = false;

    // 주문데이터 가공
    orderDataInit();

    /**
    * 택배사 선택
    */
    $scope.deliverySelect = deli => {
      $scope.canCustom = [7].indexOf(deli.carr_no) === -1;
      $(`#delivery_${deli.carr_no}_${deli.carr_id}_${deli.carr_id_sub.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`).prop('checked', true);
      $scope.selectedDeli = deli;
      const now = moment();
      $scope.TEMP_EPOST_PRINT_INVOICE_BLOCK = (now.isBetween(moment('2024-04-27 22:00', 'YYYY-MM-DD HH:mm'), moment('2024-04-28 00:00', 'YYYY-MM-DD HH:mm')) && deli.carr_no === 6 && !$scope.isReprint);
      $scope.getCarrLabel = function() {
        return $scope.selectedDeli.carr_no === 107 || $scope.selectedDeli.carr_no === 1007 ? '대표품목명' : '양식 종류';
      };
      loadTemplate();
    };

    // print_set_yn 값에 따른 세트상품 출력방식 설정
    $scope.printSetYnConversion = function(value) {
      $scope.print_set_yn = value;
      if (!$scope.selectedTemp) {
        return;
      }
      if (!$scope.selectedTemp.custom_data?.length && ['3', '5', '7', '10'].includes(value)) { // sku상품명을 한 줄로 이어서 출력합니다.
        $scope.setProdDivideYn = 0;
      } else {
        if ($scope.selectedTemp.custom_data?.length && $scope.selectedTemp.custom_data.some(data => ['3', '5', '8', '10', '11'].includes(data.print_set_yn))) {
          $scope.setProdDivideYn = 0;
        } else {
          $scope.setProdDivideYn = 1;
        }
      }
    };

    // 기존 운송장 양식 모달의 상품명 표기방식 선택값을 개선된 양식의 선택값으로 매핑
    $scope.mapOriPrintSetYn = function(temp) {
      if (temp.sale_name_sub === '' && temp.sale_name_type === 'SKU상품명' && temp.print_set_yn == '1') {
        temp.print_set_yn = '2';
      } else if (temp.sale_name_sub === '판매자관리코드' && temp.sale_name_type === 'SKU상품명' && temp.print_set_yn == '1') {
        temp.print_set_yn = '4';
      } else if (temp.sale_name_sub === 'SKU코드' && temp.sale_name_type === 'SKU상품명' && temp.print_set_yn == '1') {
        temp.print_set_yn = '6';
      } else if (temp.sale_name_sub === '재고관리코드' && temp.sale_name_type === 'SKU상품명' && temp.print_set_yn == '1') {
        temp.print_set_yn = '9';
      }
    };

    /**
    * 양식 선택
    */
    $scope.templateSelect = async (temp, isEdit, reRender) => {
      selectTemplateTypeCode = temp.template_type_cd;

      if ($scope.selectedTemp === temp && !isEdit && !reRender) {
        // 동일 양식 클릭시 송장 여러번 그려짐
        // 커스텀 수정일 경우 다시 그려서 바코드 보이도록 처리

        return;
      }

      // custom_data의 sku_print_type 해당 필드가 새로 생겼는데(2023/4/26) 해당 필드가 있는것처럼 출력이 되어야함
      if (temp?.custom_data?.length) {
        temp.custom_data.forEach(custom => {
          if (!custom.sku_print_type) {
            custom.sku_print_type = custom.print_set_yn && ['1', '2', '4', '6', '7', '9'].includes(custom.print_set_yn) ? 'opt_sale_cnt' : 'all';
          }
        });
      }

      $(`#template${temp.template_no}`).prop('checked', true);  // 라디오박스 체크

      if ($scope.selectedDeli.carr_no != 8 && $scope.selectedDeli.carr_no != 7) {
        $('#spinner').removeClass('hidden');
      }

      // 임시로 템플릿 바꿔주는 처리(같은 타입의 템플릿 클릭시)
      $scope.view = 'views/order/shipment/invoice_type/temp.html';
      $scope.mapOriPrintSetYn(temp);

      $scope.selectList = angular.copy(originList);
      $scope.selectedTemp = angular.copy(temp);
      $scope.selectedTemp.print_set_yn = String($scope.selectedTemp.print_set_yn);
      $scope.printSetYnConversion($scope.selectedTemp?.print_set_yn);

      const tempData = [];
      let itemDetail = [];
      const total_page_cnt = $scope.selectList.length;

      let requiredMaskingColumns;

      // 한진택배는 배달표 주소 제외 모두 마스킹.
      if ($scope.selectedDeli.carr_no === 5) {
        requiredMaskingColumns = ['upper_to_name', 'lower_to_name', 'upper_to_htel', 'lower_to_htel', 'upper_to_tel', 'lower_to_tel'];

        // 양식에 따라 배달표 위치가 다름
        if ($scope.selectedTemp.template_type_cd === 'T022') {
          requiredMaskingColumns.push('lower_to_addr1');
        } else {
          requiredMaskingColumns.push('upper_to_addr1');
        }
      } else {
        // 개인정보 보호정책에 따라 수령자명, 수령자전화번호, 수령자 헨드폰번호는 무조건 암호화 되도록 수정.
        requiredMaskingColumns = ['upper_to_name', 'lower_to_name', 'upper_to_htel', 'lower_to_htel', 'upper_to_tel', 'lower_to_tel'];

        if ($scope.selectedTemp.maskingFields) {
          requiredMaskingColumns = requiredMaskingColumns.concat($scope.selectedTemp.maskingFields.split(','));
        }
      }

      // 대한통운 주문자명 마스킹.
      if ($scope.selectedDeli.carr_no === 4) {
        requiredMaskingColumns.push('order_name');
      }

      const maskingColumns = [ ...new Set(requiredMaskingColumns) ];
      const maskingFields = maskingColumns.map(masking => {
        if (masking === 'order_name') {
          return { key: masking };
        }
        const splitVal = masking.split('_');

        return { position: splitVal[0], key: `${splitVal[1]}_${splitVal[2]}` };
      });

      // 양식선택시 선택된 양식에 있는 묶음배송방법 적용. 2018-10-12 rony
      _.forEach($scope.selectList, (o, idx) => {
        o.upper_to_name = o.lower_to_name = o.to_name;
        o.upper_to_htel = o.lower_to_htel = o.to_htel;
        o.upper_to_tel = o.lower_to_tel = o.to_tel;
        o.upper_to_addr1 = o.lower_to_addr1 = o.to_addr1;

        // 수령자정보 마스킹 처리
        if (maskingFields.length) {
          maskOrdInfo(maskingFields, o, $scope.selectedDeli.carr_no);
        }

        // 마스킹 처리 된 주문 건에 대해서 가공 처리 진행
        if (!cantMaskingBundleNo.includes(o.bundle_no)) {
          // 사은품 적용위해 prod_list 복사
          o.prod_list_temp = angular.copy(o.prod_list);

          if (o.bundle_ship_method) {
            if (o.bundle_ship_method == '무료배송' || o.bundle_ship_method == '선결제' || o.bundle_ship_method == '선불' || o.bundle_ship_method == '신용') {
              o.bundle_ship_method = $scope.selectedTemp.sett_ship_free_txt;
            } else {
              o.bundle_ship_method = '착불';
            }
          }

          if (!$scope.selectedTemp.custom_data?.length) {
            // 사은품 출력 여부에 따른 상품명 변경
            if ($scope.selectedTemp.gift_yn === 1) {
              // 사은품은 각 주문에 사은품이 있을경우 출력
              _.forEach(o.prod_list_temp, prod => {
                if (prod.gift_name && prod.gift_name.trim() !== '') {
                  prod.opt_name += ` [${prod.gift_name}]`;
                  prod.prod_without_prop += ` [${prod.gift_name}]`;
                  prod.opt_name_with_prod_name += ` [${prod.gift_name}]`;
                  prod.prod_name += ` [${prod.gift_name}]`;
                  prod.sku_name += ` [${prod.gift_name}]`;
                  prod.sku_name_with_saleCnt += ` [${prod.gift_name}]`;
                  prod.sku_name_with_totalCnt += ` [${prod.gift_name}]`;
                  prod.sku_without_prop += ` [${prod.gift_name}]`;
                }
              });
            } else if ($scope.selectedTemp.gift_yn === 2) {
              let gift_prod_name = '';

              if (o.gift_pack) {
                const giftList = o.gift_pack.split('|');

                gift_prod_name = giftList.map(gift => {
                  const vals = gift.split('#'); // [0]: 사은품명, [1]: SKU코드, [3]: 사은품출고개수

                  return `${vals[0]} - ${vals[3]}개`;
                }).join(', ');
              }
              // 규칙적용사은품은 한 묶음당 하나의 옵션에만 출력
              if (gift_prod_name && gift_prod_name.trim() != '') {
                o.prod_list_temp[o.prod_list_temp.length - 1].gift_prod_name = `[★${gift_prod_name}]`;
              }
            }
          }
          // 총 출력 페이지
          o.total_page_cnt = total_page_cnt;
          // 현제 출력 페이지
          o.current_page_cnt = idx + 1;
          // 총 품목 갯수
          o.total_prod_list_cnt = o.prod_list.length;
          // 우체국 택배의 경우 묶음주문에 조건부 배송이 포함되어있는경우 '신용' 으로 전송해야 함
          if ($scope.selectedTemp.carr_no === 6 && o.ship_method_etc !== '') {
            o.bundle_ship_method = '신용';
          }

          const sku_print_type_opt_sale_cnt = ['2', '4', '6', '8', '9'].includes($scope.selectedTemp.print_set_yn);
          // 출력 데이터 분할
          // 2018-11-20 rony
          if ($scope.selectedTemp.bundle_division_cnt > 0) {
            let prodListDetails = [];
            itemDetail = [];

            if ($scope.setProdDivideYn) {
              const prod_list_temp = [];

              o.prod_list_temp.forEach((item, index) => {
                const prodListTemp = {
                  sku_cd: (item.sku_cd ? item.sku_cd.split(';') : []),
                  stock_cd: (item.stock_cd ? item.stock_cd.split(';') : []),
                  sku_name: item.sku_name.replace(/\|/g, '<br />').split('<br />'),
                  sku_without_prop: item.sku_without_prop.replace(/\|/g, '<br />').split('<br />'),
                  sku_name_with_saleCnt: item.sku_name_with_saleCnt.replace(/\|/g, '<br />').split('<br />'),
                  sku_name_with_totalCnt: item.sku_name_with_totalCnt.replace(/\|/g, '<br />').split('<br />'),
                  sku_without_prop_with_saleCnt: item.sku_without_prop_with_saleCnt.replace(/\|/g, '<br />').split('<br />'),
                  sku_without_prop_with_totalCnt: item.sku_without_prop_with_totalCnt.replace(/\|/g, '<br />').split('<br />')
                };

                // sku 출력하지 않거나
                // 세트상품이고 세트상품명으로 출력하는 경우
                // 주문을 분할하지 않고 한번만 출력
                const isDivideOrder = (
                  $scope.selectedTemp.custom_data?.length ? $scope.selectedTemp.custom_data.some(data => (data.columnType === 'variable' && data.val !== 'gift_prod_name' && invoiceTemplateSVC.column.find(col => col.val === data.val)?.stock_yn) && (!['1', '2', '4', '6', '7', '9'].includes(data.print_set_yn) || !item.set_no))
                    : ($scope.selectedTemp.sale_name_type === 'SKU상품명' && (!sku_print_type_opt_sale_cnt || !item.set_no))
                );

                prod_list_temp.push(...prodListTemp.sku_name.filter((sku_name, idx) => !isDivideOrder ? !idx : 1).map((sku_name, idx) => ({
                  index: !idx ? index + 1 : '',
                  prod_without_prop: item.prod_without_prop,
                  opt_name_with_prod_name: item.opt_name_with_prod_name,
                  prod_name: item.prod_name,
                  opt_name: item.opt_name,
                  sku_name,
                  sku_cd: (item.isAddOpt ? item.sku_cd : prodListTemp.sku_cd[idx]) || '', // 추가항목 출력 시 sku코드는 출력하지 않으나 재고 확인 조건에 sku코드를 사용하여 추가
                  sku_without_prop: prodListTemp.sku_without_prop[idx],
                  sku_name_with_saleCnt: prodListTemp.sku_name_with_saleCnt[idx],
                  sku_name_with_totalCnt: prodListTemp.sku_name_with_totalCnt[idx],
                  sku_without_prop_with_saleCnt: prodListTemp.sku_without_prop_with_saleCnt[idx],
                  sku_without_prop_with_totalCnt: prodListTemp.sku_without_prop_with_totalCnt[idx],
                  count: item.count,
                  set_pack_unit: item.set_pack_unit,
                  stock_cd: (!isDivideOrder ? item.stock_cd : prodListTemp.stock_cd[idx]) || '',
                  set_name: item.set_name,
                  set_no: sku_print_type_opt_sale_cnt ? item.set_no : (item.set_no || item.is_multi),
                  set_cd: item.set_cd,
                  c_sale_cd: item.c_sale_cd || '',
                  main_prod_stock_cd: item.main_prod_stock_cd || '',
                  gift_name: (!isDivideOrder || prodListTemp.sku_name.length === idx + 1 ? item.gift_name : '') || '',
                  gift_prod_name: (!isDivideOrder || prodListTemp.sku_name.length === idx + 1 ? item.gift_prod_name : '') || '',
                  is_multi: item.is_multi,
                  isAddOpt: item.isAddOpt
                })));
              });

              o.prod_list_temp = prod_list_temp;
            } else {
              o.prod_list_temp = o.prod_list_temp.map((item, index) => ({
                index: index + 1,
                prod_without_prop: item.prod_without_prop,
                opt_name_with_prod_name: item.opt_name_with_prod_name,
                prod_name: item.prod_name,
                opt_name: item.opt_name,
                sku_name: item.sku_name.replace(/<br \/>/g, '|'),
                sku_cd: item.sku_cd,
                sku_without_prop: item.sku_without_prop.replace(/<br \/>/g, '|'),
                sku_name_with_saleCnt: item.sku_name_with_saleCnt.replace(/<br \/>/g, '|'),
                sku_name_with_totalCnt: item.sku_name_with_totalCnt.replace(/<br \/>/g, '|'),
                sku_without_prop_with_saleCnt: item.sku_without_prop_with_saleCnt.replace(/<br \/>/g, '|'),
                sku_without_prop_with_totalCnt: item.sku_without_prop_with_totalCnt.replace(/<br \/>/g, '|'),
                count: item.count,
                set_pack_unit: item.set_pack_unit,
                stock_cd: item.stock_cd || '',
                set_name: item.set_name,
                set_no: sku_print_type_opt_sale_cnt ? item.set_no : (item.set_no || item.is_multi),
                set_cd: item.set_cd,
                c_sale_cd: item.c_sale_cd || '',
                main_prod_stock_cd: item.main_prod_stock_cd || '',
                gift_name: item.gift_name || '',
                gift_prod_name: item.gift_prod_name || '',
                is_multi: item.is_multi,
                isAddOpt: item.isAddOpt
              }));
            }

            prodListDetails = _.chunk(o.prod_list_temp, $scope.selectedTemp.bundle_division_cnt);

            _.forEach(prodListDetails, (prodListDetail, k) => {
              itemDetail = angular.copy(o);
              itemDetail.prod_list_temp = prodListDetail;
              itemDetail.addPage = k === 0 ? 'none' : 'view';
              tempData.push(itemDetail);
            });
          } else {
            if (!$scope.setProdDivideYn) {
              o.prod_list_temp = o.prod_list_temp.map((item, index) => ({
                index: index + 1,
                prod_without_prop: item.prod_without_prop,
                opt_name_with_prod_name: item.opt_name_with_prod_name,
                prod_name: item.prod_name,
                opt_name: item.opt_name,
                sku_name: item.sku_name.replace(/<br \/>/g, '|'),
                sku_cd: item.sku_cd,
                sku_without_prop: item.sku_without_prop.replace(/<br \/>/g, '|'),
                sku_name_with_saleCnt: item.sku_name_with_saleCnt.replace(/<br \/>/g, '|'),
                sku_name_with_totalCnt: item.sku_name_with_totalCnt.replace(/<br \/>/g, '|'),
                sku_without_prop_with_saleCnt: item.sku_without_prop_with_saleCnt.replace(/<br \/>/g, '|'),
                sku_without_prop_with_totalCnt: item.sku_without_prop_with_totalCnt.replace(/<br \/>/g, '|'),
                count: item.count,
                set_pack_unit: item.set_pack_unit,
                stock_cd: item.stock_cd || '',
                set_name: item.set_name,
                set_no: sku_print_type_opt_sale_cnt ? item.set_no : (item.set_no || item.is_multi),
                set_cd: item.set_cd,
                c_sale_cd: item.c_sale_cd || '',
                main_prod_stock_cd: item.main_prod_stock_cd || '',
                gift_name: item.gift_name || '',
                gift_prod_name: item.gift_prod_name || '',
                is_multi: item.is_multi,
                isAddOpt: item.isAddOpt
              }));
            } else {
              // orderInit시점에 <br /> 태그로 리스트가 조인되므로 <br />을 기준으로 리스트를 쪼개야함
              const invalidDataPattern = /^;{1,}$/;

              o.prod_list_temp = o.prod_list_temp.map((item, index) => ({
                index: index + 1,
                prod_without_prop: item.prod_without_prop,
                prod_name: item.prod_name,
                opt_name: item.opt_name,
                opt_name_with_prod_name: item.opt_name_with_prod_name,
                sku_name: item.sku_name.replace(/\|/g, '<br />'),
                sku_cd: item.sku_cd,
                sku_without_prop: item.sku_without_prop.replace(/\|/g, '<br />'),
                sku_name_with_saleCnt: item.sku_name_with_saleCnt.replace(/\|/g, '<br />'),
                sku_name_with_totalCnt: item.sku_name_with_totalCnt.replace(/\|/g, '<br />'),
                sku_without_prop_with_saleCnt: item.sku_without_prop_with_saleCnt.replace(/\|/g, '<br />'),
                sku_without_prop_with_totalCnt: item.sku_without_prop_with_totalCnt.replace(/\|/g, '<br />'),
                count: item.count,
                set_pack_unit: item.set_pack_unit,
                stock_cd: invalidDataPattern.test(item.stock_cd) ? '' : (item.stock_cd || ''),
                set_name: item.set_name,
                set_no: sku_print_type_opt_sale_cnt ? item.set_no : (item.set_no || item.is_multi),
                set_cd: item.set_cd,
                c_sale_cd: item.c_sale_cd || '',
                main_prod_stock_cd: item.main_prod_stock_cd || '',
                gift_name: item.gift_name || '',
                gift_prod_name: item.gift_prod_name || '',
                is_multi: item.is_multi,
                isAddOpt: item.isAddOpt
              }));
            }

            tempData.push(o);
          }
        }
      });

      if (cantMaskingBundleNo.length) {

        const confirm = await commonSVC.showConfirmCustom({
          title: '운송장 출력 실패 안내',
          text: `주소 마스킹 처리 실패: 주소 수정 후 다시 진행해 주세요.\n(영문 주소이거나 확인 불가한 특수문자가 입력된 경우 등)\n묶음번호:\n${cantMaskingBundleNo.join('\n')}`,
          confirmButtonText: '수정 필요한 주문 검색',
          cancelButtonText: '취소'
        });

        if (confirm) {
          // 마스킹 실패 주문 건들 검색 처리
          selectList.data.results = selectList.data.results.filter(o => cantMaskingBundleNo.includes(o.bundle_no));
          $scope.selectList = $scope.selectList.filter(o => cantMaskingBundleNo.includes(o.bundle_no));
          cantMaskingBundleNo = [];
          $('#spinner').addClass('hidden');

          $uibModalInstance.close($scope.selectList);
        } else {
          cantMaskingBundleNo = [];
          $scope.wait = true; // 양식 로드 실패 시 출력 버튼 비활성화

          $('#spinner').addClass('hidden');
        }

        return false;
      }

      $scope.selectList = tempData;

      if ($scope.selectList.length) {
        $scope.totalProdCount = $scope.selectList.map(item => item.prod_list_temp.length).reduce((acc, cur) => acc + cur);
      }

      // 3단용 데이터 셋팅.
      divideOrder();

      $('#spinner').addClass('hidden');
    };

    const selectedTemplateLoad = async () => {
      await new Promise(resolve => {
        $timeout(() => {
          $scope.view = `views/order/shipment/invoice_type/${selectTemplateTypeCode}.html`;
          resolve();
        });
      });
    };

    /**
    * 양식 추가하기
    */
    $scope.addTemplate = () => {
      if ($scope.selectedDeli?.carr_no) {
        const resolve = {};

        resolve.data = {
          delivery: angular.copy($scope.selectedDeli),
          pageType: 'add',
          parent: $scope
        };
        const modal = commonSVC.openModal('xxg', resolve, 'invoiceTemplateCtrl', 'views/order/shipment/modals/invoice_template.html', null, false);

        modal.result.then(() => {
          $timeout(() => {
            $scope.reset();
          }, 500);
        });
      } else {
        commonSVC.showMessage('택배사를 선택하세요.');

        return false;
      }
    };

    /**
    * 양식 수정하기
    */
    $scope.editTemplate = () => {
      if ($scope.selectedTemp?.template_no) {
        const resolve = {};
        resolve.data = {
          delivery: angular.copy($scope.selectedDeli),
          template: angular.copy($scope.selectedTemp),
          pageType: 'edit',
          parent: $scope
        };
        const modal = commonSVC.openModal('xxg', resolve, 'invoiceTemplateCtrl', 'views/order/shipment/modals/invoice_template.html', null, false);

        modal.result.then(() => {
          $timeout(() => {
            $scope.reset();
          }, 500);
        });
      } else {
        commonSVC.showMessage('수정할 양식을 선택하세요.');

        return false;
      }
    };

    /**
    * 양식 삭제
    */
    $scope.deleteTemplate = () => {
      if ($scope.selectedTemp?.template_no) {
        commonSVC.showConfirm('양식을 삭제 하시겠습니까?', '', () => {
          deliveryModel.DeleteDeliveryTemplate({ template_no: $scope.selectedTemp.template_no }, state => {
            if (state === 'success') {
              commonSVC.showToaster('success', '성공', '택배사 양식 삭제 성공');
              setTimeout(() => {
                loadTemplate();
              }, 500);
            } else {
              commonSVC.showToaster('error', '실패', '택배사 양식 삭제 실패');
            }
          });

        });
      } else {
        commonSVC.showMessage('삭제할 양식을 선택하세요.');

        return false;
      }
    };

    /**
    * 하단 버튼 클릭 (출력, 테스트출력, 미리보기)
    */
    $scope.print = async (type, selectedTemp) => {
      $scope.type = type;
      // 선택 템플릿 로드
      $scope.view = '';
      await selectedTemplateLoad();

      // 소소한돈가 테스트출력 미지원
      if (userInfo.user.sol_no === 30697 && type === 'test_print') {
        return false;
      }

      // 선택한 주문건들 중에서 주소 띄어쓰기 안된 주문들 묶음번호로 그룹핑
      // {묶음번호_1: [{주문정보}, {}], 묶음번호_2: [{}, {}, ...], ...}
      const toNameRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
      const korRegex = /^[가-힣]{2,8}(?:\s+[^ ]*){2,}$/,
            engRegex = /^[a-zA-Z-]{4,17}(?:\s+[^ ]*){2,}$/;
      const unableAddrOrds = _.groupBy(selectList.data.results.filter(ord => {
        const addr = ord.to_addr1.trim().replace(/\s{2,}/g, ' '),
              isKorAddr = /[가-힣]/.test(addr[0]),
              regex = isKorAddr ? korRegex : engRegex;

        return !regex.test(addr);
      }), 'bundle_no');
      const unableAddToNames = _(selectList.data.results).filter(ord => !ord.to_name.replace(toNameRegex, '').trim()).groupBy('bundle_no').value();

      // 대한통운, 한진, 로젠, 롯데 송장 출력 시 수령자명에 특수문자 1개만 입력된 주문 있을 경우 제외하고 발행하거나 일괄 취소
      if (Object.keys(unableAddToNames).length && [4, 5, 7, 8].includes($scope.selectedDeli.carr_no)) {
        const confirm = await commonSVC.showConfirmCustom({
          title: '송장번호 발급 에러: 수령자명 확인 불가',
          text: `수령자명이 정상적으로 저장되어 있지 않습니다.\n 주문 [상세] > 수령자명을 수정하신 후 출력 작업을 재시도하여 주십시오.\n\n해당 주문은 제외 후 진행하시겠습니까?\n\n묶음번호:\n${Object.keys(unableAddToNames).map((bundle_no) => {
            return bundle_no;
          }).join('\n')}`,
          type: 'warning',
          confirmButtonText: '예(출력 가능한 주문만 선택)',
          cancelButtonText: '취소'
        });

        if (confirm) {
          // 특수문자 절삭 후 수령자명 빈값 인 주문 제거
          selectList.data.results = selectList.data.results.filter(o => !Object.keys(unableAddToNames).includes(o.bundle_no));
          $scope.selectList = $scope.selectList.filter(o => !Object.keys(unableAddToNames).includes(o.bundle_no));
          $uibModalInstance.close($scope.selectList);

          return true;
        } else {
          $('#spinner').addClass('hidden');
        }

        return false;
      }

      // 선택 주문건에 주소 띄어쓰기 안된 주문 있을 경우 제외하고 발행하거나 일괄취소
      if (Object.keys(unableAddrOrds).length > 0) {
        const confirm = await commonSVC.showConfirmCustom({
          title: '운송장 출력 에러: 주소 행정구역 단위 띄어쓰기 필요',
          text: `주소 입력 시 행정구역 단위로 띄어쓰기를 해야합니다.\n 주문의 [상세]를 눌러 주소 수정을 할 수 있습니다.\n\n해당 주문은 제외 후 진행하시겠습니까?\n\n묶음번호:\n${Object.keys(unableAddrOrds).map((bundle_no) => {
            return `${bundle_no} (총 ${unableAddrOrds[bundle_no].length}건)`;
          }).join('\n')}`,
          type: 'warning',
          confirmButtonText: '예(출력 가능한 주문만 선택)',
          cancelButtonText: '취소'
        });

        if (confirm) {
          // 주소 띄어쓰기 안된 주문 제거
          selectList.data.results = selectList.data.results.filter(o => !Object.keys(unableAddrOrds).includes(o.bundle_no));
          $scope.selectList = $scope.selectList.filter(o => !Object.keys(unableAddrOrds).includes(o.bundle_no));
          $uibModalInstance.close($scope.selectList);

          return true;
        } else {
          $('#spinner').addClass('hidden');
        }

        return false;
      }

      if (!selectedTemp) {
        selectedTemp = $scope.selectedTemp;
      }

      // 한진택배 구양식 지원종료.
      if (selectedTemp?.carr_no === 5 && ['T010', 'T021'].includes(selectedTemp?.template_type_cd)) {
        commonSVC.showMessage('운송장 출력불가', '- 한진택배 구양식은 지원이 종료되었습니다..\n- 양식을 새로 설정하여 주신 후 운송장 출력 부탁드립니다.');

        return false;
      }

      $scope.wait = true;
      $timeout();

      runInvoicePrint(type, selectedTemp);
    };

    /**
     * 운송장출력
     */
    const runInvoicePrint = _.debounce(async (type, selectedTemp) => {
      $scope.delivery = $scope.selectedDeli;

      if ($scope.selectedDeli.carr_no && selectedTemp?.carr_no) {
        $timeout(async () => {
          //실제 출력일 경우 운송장 번호 발급 후 출력
          if (type === 'print') {
            // 출력 택배사가 미승인인경우 버튼비활성화 처리했지만 개발자도구로 속성을 지우고 출력하는 양아치짓을 할수있어 여기서도 막음.
            if ($scope.refusePrint) {
              commonSVC.showMessage('미승인 택배사 입니다.', '승인완료된 택배사만 운송장 출력이 가능합니다.');
              $scope.wait = false;

              return false;
            }

            // 대한통운 발행인경우 처리
            if (selectedTemp.carr_no === 4) {
              if (moment().unix() >= moment('2024-08-07 03:00:00').unix() && moment().unix() <= moment('2024-08-07 05:59:59').unix()) {
                const contents = 'CJ대한통운 택배사 시스템 자체 점검시간입니다. (08월 07일 03:00 ~ 06:00)<br>공지사항을 참고해 주시기 바랍니다. <a href="https://app.playauto.io/#!/notice/system/detail/3033" class="button-a button-a-background">[링크 클릭]</a>';
                commonSVC.showMessageHtml('', contents);
                $scope.wait = false;

                return false;
              }

              // 박스타입 특대(이형)은 대한통운 측의 요청으로 사용 불가능하므로 에러처리함. 2018-11-29. rony
              // 아이온업체는 이형 출력 가능 (대한통운 확인) 2018-12-03 rony
              // 금강산업(sol_no : 36160) 문의를 통한 요청으로 선택 가능하도록 열어줌 2022-04-05 Aiden
              // 주식회사 코스모스 상사(sol_no: 13322) 문의를 통한 요청으로 선택 가능하도록 열어줌 2022-04-05 Kevin
              if (![1, 812, 4804, 4521, 13322, 36160, 74097].includes(userInfo.user.sol_no)) {
                if (selectedTemp.box_type === '이형' || selectedTemp.box_type === '특대') {
                  commonSVC.showMessage('박스타입 이형(특대)는 사용이 불가능합니다.', '선택하신 양식의 박스타입을 변경하신 후 재시도해주십시오.\n\n박스 크기 이형의 경우 추가수수료 관련 이슈로 해당 주문건의 경우 CJ대한통운 자체프로그램(CN PLUS)을 통하여 출력을 진행해 주시기 바랍니다.');
                  $scope.wait = false;

                  return false;
                }
              }

              const senderTelNumber = selectedTemp.sender_tel?.replace(/[^0-9]/g, '');

              // 대한통운은 발송자 전화번호가 필수이기 때문에 없는경우 에러.
              if (!senderTelNumber || senderTelNumber.length < 8) {
                commonSVC.showMessage('발송인 전화번호 에러!', '보내는분 전화번호는 필수 입니다.\n올바른 전화번호로 입력해주십시오.');
                $scope.wait = false;

                return false;
              }

              // 설정한 운임정보가 대한통운 시스템에 등록되어있는지 확인로직 추가 2018-11-29 rony
              deliveryModel.checkDHParams(selectedTemp, (state, data) => {
                if (state === 'success') {
                  if (data.results.check1 !== 'N' && data.results.check2 !== 'N') {
                    isPrint = true;
                    printInvoice();
                  } else {

                    const noRegistType = [];

                    if (data.results.check1 === 'N') {
                      noRegistType.push(`▶ 접수구분:일반, 운임구분:${selectedTemp.sett_ship_free_txt}, 박스타입:${selectedTemp.box_type}`);
                    }
                    if (data.results.check2 === 'N') {
                      noRegistType.push(`▶ 접수구분:일반, 운임구분:착불, 박스타입:${selectedTemp.box_type}`);
                    }

                    const title = '송장발행 주의사항 안내';
                    const contents = `대한통운 시스템에 설정하신 운임정보가 없습니다.<br><br>미등록 운임정보: <br>${noRegistType.join('<br>')}<br><br>송장발행을 계속 진행하실 경우, 반드시 대한통운 지점에 연락하시어<br>발행된 데이터의 운임정보를 수정하셔야 합니다.<br><br>위 내용을 따르지 않아 발생한 문제는 당사에서 책임지지 않습니다.<br><br>※ 해당 내용은 CJ대한통운의 권고 사항으로 운송장 양식에 설정된 사항이<br>&nbsp;&nbsp;&nbsp;계약 내용과 다르거나 필수인 착불 운임이 계약되지 않은 경우 보여집니다.<br>&nbsp;&nbsp;&nbsp;계약 사항은 일치 하나 착불 운임이 없는 경우, 박스 크기별로<br>&nbsp;&nbsp;&nbsp;모든 운임에 착불 운임을 추가 계약 해주시기 바랍니다.<br><br>ex.&nbsp;&nbsp;&nbsp;팝업 됨.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ex.&nbsp;&nbsp;&nbsp;팝업 안됨.<br>박스크기-극소(신용O,착불X)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;박스크기-극소(신용O,착불O)<br>박스크기-소 (신용O, 착불O)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;박스크기-소 (신용O, 착불O)`;
                    const buttons = [
                      {
                        name: '확인완료 및 송장발행 계속 진행',
                        next () {
                          isPrint = true;
                          printInvoice();
                        }
                      },
                      {
                        name: '송장발행 취소',
                        next () {
                          return false;
                        }
                      }
                    ];

                    commonSVC.showMultiButton(title, contents, buttons);
                  }
                } else {
                  commonSVC.showMessage('운임정보 조회실패!', '송장발행을 재시도해주십시오.');
                  $scope.wait = false;

                  return false;
                }
              });
            } else if (selectedTemp.carr_no === 956) {
              try {
                $('#spinner').removeClass('hidden');

                const res = await deliveryModel.checkDelivRegion({ carrId: $scope.selectedDeli.carr_id, carrPw: $scope.selectedDeli.carr_pwd, orders: selectList.data.results });

                if (Object.keys(res.data.results).length) {
                  const unableOrds = $scope.selectList.filter(o => !Object.keys(res.data.results).includes(o.bundle_no)).map(o => o.bundle_no);

                  if (unableOrds.length) {
                    const confirm = await commonSVC.showConfirmCustom({
                      title: '운송장 출력 에러: 배송 불가 지역',
                      text: `* 카카오T당일배송 배송 가능 지역이 아닐 경우 출력이 불가능합니다.\n 주문의 주소를 다시 한 번 확인해주세요.\n\n해당 주문은 제외 후 진행하시겠습니까?\n\n묶음번호\n${_.uniq(unableOrds).join('\n')}`,
                      type: 'warning',
                      confirmButtonText: '예(출력 가능한 주문만 선택)',
                      cancelButtonText: '취소'
                    });

                    if (confirm) {
                      // 배송불가 지역 주문 제거
                      selectList.data.results = selectList.data.results.filter(o => Object.keys(res.data.results).includes(o.bundle_no));
                      $scope.selectList = $scope.selectList.filter(o => Object.keys(res.data.results).includes(o.bundle_no));

                      // 총 출력 건수에서 배송불가주문 제외
                      let idx = 0;
                      $scope.selectList.forEach(o => {
                        o.current_page_cnt = o.addPage === 'view' ? idx : ++idx;
                        o.total_page_cnt -= _.uniq(unableOrds).length;
                      });
                    } else {
                      $('#spinner').addClass('hidden');

                      return false;
                    }
                  }

                  $scope.hubInfo = res.data.results;
                } else {
                  // 선택한 모든 주문이 배송 불가능 지역일 경우
                  commonSVC.showMessage('운송장 출력이 불가능한 주문입니다.', '카카오T당일배송이 지원하지 않는 배송지역입니다.');
                  $('#spinner').addClass('hidden');
                  $scope.wait = false;

                  return false;
                }

                isPrint = true;
                printInvoice();
              } catch {
                commonSVC.showMessage('카카오T당일배송 배송가능지역 조회 실패', '송장발행을 재시도해주십시오.');

                return false;
              } finally {
                $('#spinner').addClass('hidden');
                $scope.wait = false;
              }
            } else {
              if (selectedTemp.carr_no === 8 && !selectedTemp.box_type) {
                commonSVC.showMessage('롯데택배 송장 출력 실패', '박스 정보 > 박스 크기를 설정해주십시오.');

                return false;
              }

              if (selectedTemp.carr_no === 5) {
                // 한진택배 수령자 우편번호 유효성 검사 추가.
                const noZipOrds = selectList.data.results.filter(o => !o.to_zipcd?.replace(/-+/g, '').trim());

                if (noZipOrds.length) {
                  commonSVC.showMessage('송장 출력 실패', `수령자 우편번호가 없는 주문건이 있습니다.\n우편번호 입력 후 출력작업을 재시도 해 주십시오.\n\n묶음번호:\n${noZipOrds.map((ord) => ord.bundle_no).join('\n')}`);

                  return false;
                }
              }

              isPrint = true;
              printInvoice();
            }
          } else {
            //테스트는 가라 데이터로 출력 이라 바로 출력
            labelPrint(type);
          }
        }, 100);
      } else {
        commonSVC.showMessage('택배사와 양식을 선택하세요.');
        $scope.wait = false;

        $timeout(() => {});

        return false;
      }
    }, 1500);

    /**
    * 하단 접수하기, 동기화 버튼 (CU, 이마트 전용)
    */
    $scope.issue = async (type, selectedTemp) => {
      // 선택한 주문건들 중에서 주소 띄어쓰기 안된 주문들 묶음번호로 그룹핑
      // {묶음번호_1: [{주문정보}, {}], 묶음번호_2: [{}, {}, ...], ...}
      const korRegex = /^[가-힣]{2,8}(?:\s+[^ ]*){2,}$/,
            engRegex = /^[a-zA-Z-]{4,17}(?:\s+[^ ]*){2,}$/;
      const unableAddrOrds = _.groupBy(selectList.data.results.filter(ord => {
        const addr = ord.to_addr1.trim().replace(/\s{2,}/g, ' '),
              isKorAddr = /[가-힣]/.test(addr[0]),
              regex = isKorAddr ? korRegex : engRegex;

        return !regex.test(addr);
      }), 'bundle_no');

      // 선택 주문건에 주소 띄어쓰기 안된 주문 있을 경우 제외하고 발행하거나 일괄취소
      if (Object.keys(unableAddrOrds).length > 0) {
        const confirm = await commonSVC.showConfirmCustom({
          title: '운송장 출력 에러: 주소 행정구역 단위 띄어쓰기 필요',
          text: `주소 입력 시 행정구역 단위로 띄어쓰기를 해야합니다.\n 주문의 [상세]를 눌러 주소 수정을 할 수 있습니다.\n\n해당 주문은 제외 후 진행하시겠습니까?\n\n묶음번호:\n${Object.keys(unableAddrOrds).map((bundle_no) => {
            return `${bundle_no} (총 ${unableAddrOrds[bundle_no].length}건)`;
          }).join('\n')}`,
          type: 'warning',
          confirmButtonText: '예(출력 가능한 주문만 선택)',
          cancelButtonText: '취소'
        });

        if (confirm) {
          // 주소 띄어쓰기 안된 주문 제거
          selectList.data.results = selectList.data.results.filter(o => !Object.keys(unableAddrOrds).includes(o.bundle_no));
          $scope.selectList = $scope.selectList.filter(o => !Object.keys(unableAddrOrds).includes(o.bundle_no));
          $uibModalInstance.close($scope.selectList);
        } else {
          $('#spinner').addClass('hidden');
        }

        return false;
      }

      if (!selectedTemp) {
        selectedTemp = $scope.selectedTemp;
      }

      custOrdNoIssue(type, selectedTemp);
    };

    /**
    * 승인번호 발급 (CU편의점택배, 이마트 전용)
    */
    const custOrdNoIssue = _.debounce(async (type, selectedTemp) => {
      $scope.type = type;
      $scope.delivery = $scope.selectedDeli;

      const carrNo = $scope.selectedDeli.carr_no;
      const selectedCarrNo = selectedTemp?.carr_no;

      if (!carrNo || !selectedCarrNo) {
        commonSVC.showMessage('택배사와 양식을 선택하세요.');

        return false;
      }

      if (type === 'receive' || type === 'sync' || type === 'cancel') {
        printCustOrdNo(type);
      }
    });

    /**
    * 취소
    */
    $scope.cancel = () => {
      if (isPrint) {
        $uibModalInstance.close('success');
      } else {
        $uibModalInstance.dismiss('cancel');
      }
    };

    /**
    * 출력양식 view 변경 시 load된 후 타는 곳
    */
    $scope.load = async () => {
      await $scope.setBarcode();

      if ($scope.selectedTemp.custom_data?.length) {
        $timeout(() => {
          $scope.setCustom();

          $('#spinner').addClass('hidden');
        });
      } else {
        $('#spinner').addClass('hidden');
      }
    };

    // 바코드 생성
    $scope.setBarcode = () => {
      return new Promise(resolve => {
        $timeout(() => {
          if ($('.barcode').length) {
            JsBarcode('.barcode', '0000000000', { format: 'CODE128' });
          }
          if ($('.barcode_barcode_only').length) {
            JsBarcode('.barcode_barcode_only', '0000000000', { format: 'CODE128', displayValue: false });
          }
          if ($scope.selectedDeli.carr_no == 6) {
            // barcode_zipcd 이 없는 양식이 있기 때문에 있는 지 확인
            if ($('.barcode_zipcd').length) {
              JsBarcode('.barcode_zipcd', '12345', {
                format: 'CODE128'
              });
            }
          }
          if ($scope.selectedDeli.carr_no === 8) {
            $scope.selectList.forEach((sel, idx) => JsBarcode(`.barcode.rcvcode_${idx}_barcode_only`, `${idx}`, { format: 'CODE39', displayValue: false, marginTop: 0, marginRight: 30 }));
          }
          if ($('.qrcode').length) {
            const elm = document.getElementsByClassName('qrcode');
            const opt = {
              errorCorrectionLevel: 'H',
              type: 'image/jpeg',
              quality: 0.3,
              margin: 1 };

            Array.from(elm).forEach(e => {
              QRCode.toDataURL('0000000000', opt, function (err, url) {
                e.src = url;
              });
            });
          }

          resolve();
        }, 500);
      });
    };

    /**
     * 커스텀 양식 적용
     */
    $scope.setCustom = () => {
      const list = ['T008', 'T017'].indexOf($scope.selectedTemp.template_type_cd) > -1 ? _.flatten($scope.shipmentList) : $scope.selectList;

      // 기존에 생성된 커스텀 박스 제거
      document.querySelectorAll('.print-box-custom').forEach(elm => elm.remove());

      document.querySelectorAll('#invoice_html .print-wrapper').forEach((elm, idx) => {

        $scope.selectedTemp.custom_data.forEach(custom => {
          if (custom.val) {
            const div = document.createElement('div');

            // 클래스 추가
            div.classList.add('print-box');
            div.classList.add('print-box-custom');
            div.innerHTML = '';
            // 데이터 보이는 문제 수정
            div.classList.add('ng-hide');

            // 고정 텍스트
            if (custom.columnType === 'text') {
              div.innerHTML = custom.val;
            } else {
              // 반복들어가는 키
              if (custom.repeatKey) {
                div.innerHTML = (list[idx][custom.repeatKey] || [])
                  // 판매자관리코드에 '수량' 출력유무 옵션 추가
                  .map((repeatItem) => {
                    const sku_print_type_opt_sale_cnt = ['1', '2', '4', '6', '7', '9'].includes(custom.print_set_yn);
                    if (sku_print_type_opt_sale_cnt && repeatItem.is_multi) {
                      repeatItem.set_no = null;
                    }
                    const custom_val_split = custom.val.split('+');
                    const custom_value = custom_val_split[custom_val_split.length - 1];
                    // '+'로 구분된 문자열 배열에 대해 'SKU상품명(속성)|sku_name' 또는 'SKU상품명|sku_without_prop'을 포함하는지 확인
                    const hasSKUName = custom_val_split.some(str => str.includes('sku_name') || str.includes('sku_without_prop'));

                    let content = '';

                    // sku_print_type별 상품명 출력 방식
                    const nameBySkuPrintType = (variable_content_value) => {
                      let return_string;
                      switch (custom.sku_print_type) {
                        case 'opt_sale_cnt':
                          return_string = repeatItem[`${variable_content_value}_with_saleCnt`] || repeatItem.prod_name;
                          break;
                        case 'pack_unit':
                        case 'set_pack_unit':
                          // pack_unit (총 출고 수량만 출력) 이면서 sku 매칭이 안되어 있고 custom.repeatKey === 'prod_list_temp' 때 상품명 + 갯수 까지 해줘야함
                          return_string = repeatItem[`${variable_content_value}_with_totalCnt`] || (custom.repeatKey === 'prod_list_temp' ? repeatItem.prod_name + ` - ${repeatItem.count} 개` : repeatItem.prod_name);
                          break;
                        default:
                          return_string = repeatItem[variable_content_value] || repeatItem.prod_name;
                          break;
                      }

                      return return_string;
                    };

                    if (repeatItem.set_no) {
                      const setName = custom.sku_print_type === 'set_all' ? ('(' + repeatItem.set_name + ' X ' + repeatItem.set_pack_unit + '개)') : repeatItem.set_name;
                      if (custom.print_set_yn == '1' && custom.val === 'stock_cd') {
                        content = repeatItem.main_prod_stock_cd;
                      } else if (custom.print_set_yn == '1' && hasSKUName) {
                        content = setName + (custom.sku_print_type == 'pack_unit' ? ` - ${repeatItem.count} 개` : '');
                      } else if (custom.print_set_yn == '2') {
                        content = (!repeatItem.isAddOpt ? (repeatItem.c_sale_cd + ' ') : '') + setName + (custom.sku_print_type == 'pack_unit' ? ` - ${repeatItem.count} 개` : '');
                      } else if (custom.print_set_yn == '3') {
                        content = (!repeatItem.isAddOpt ? (repeatItem.c_sale_cd + ' ') : '') + nameBySkuPrintType(custom_value).replace(/<br \/>/g, '|');
                      } else if (custom.print_set_yn == '4') {
                        content = (!repeatItem.isAddOpt ? (repeatItem.set_cd + ' ') : '') + setName + (custom.sku_print_type == 'pack_unit' ? ` - ${repeatItem.count} 개` : '');
                      } else if (custom.print_set_yn == '5') {
                        content = (!repeatItem.isAddOpt ? (repeatItem.sku_cd + ' ' || '') : '') + nameBySkuPrintType(custom_value).replace(/<br \/>/g, '|');
                      } else if (custom.print_set_yn == '6') {
                        content = (!repeatItem.isAddOpt ? (repeatItem.stock_cd + ' ') : '') + setName + (custom.sku_print_type == 'pack_unit' ? ` - ${repeatItem.count} 개` : '');
                      } else if (custom.print_set_yn == '7') {
                        content = (!repeatItem.isAddOpt ? (repeatItem.main_prod_stock_cd + ' ') : '') + setName + (custom.sku_print_type == 'pack_unit' ? ` - ${repeatItem.count} 개` : '');
                      } else if (custom.print_set_yn == '8') {
                        content = (!repeatItem.isAddOpt ? (repeatItem.stock_cd + ' ') : '') + nameBySkuPrintType(custom_value).replace(/<br \/>/g, '|');
                      } else if (custom.print_set_yn == '9') {
                        content = list[idx].shop_ord_no + ' ' + setName + (custom.sku_print_type == 'pack_unit' ? ` - ${repeatItem.count} 개` : '');
                      } else if (custom.print_set_yn == '10') {
                        content = list[idx].shop_ord_no + ' ' + nameBySkuPrintType(custom_value).replace(/<br \/>/g, '|');
                      } else if (custom.print_set_yn == '11') {
                        content = nameBySkuPrintType(custom_value).replace(/<br \/>/g, '|');
                      }
                    }

                    let return_string = `<div style="display: grid; grid-template-columns: max-content 1fr;"><span style="white-space: pre" >${repeatItem.index ? `${repeatItem.index}.` : '  '} </span> <div>`; // 상품 넘버링 출력
                    if (content) { // 변수의 드롭다운 옵션 선택 시
                      return_string += content;
                    } else {
                      if (repeatItem.isAddOpt) {
                        repeatItem.sku_cd = '';
                        repeatItem.stock_cd = '';
                        repeatItem.set_cd = '';
                        repeatItem.c_sale_cd = '';
                      }

                      if (custom_val_split.length === 2) { // 조합형 변수일 때
                        const custom_sale_name_sub = custom_val_split[0];
                        return_string += `${((['sku_cd', 'stock_cd'].includes(custom_sale_name_sub)) && custom.print_set_yn == '1' && repeatItem.set_name ? repeatItem.set_cd :
                          (['shop_ord_no'].includes(custom_sale_name_sub)) ? (list[idx].shop_ord_no) : repeatItem[custom_sale_name_sub]) || ''}`; // 조합형 변수의 첫번째 항목은 존재하지 않을 때 상품명을 출력해주지 않음
                      }

                      if (['sku_name', 'sku_without_prop'].includes(custom_value)) {
                        return_string += `${nameBySkuPrintType(custom_value)}`;
                      } else {
                        return_string += `${((['sku_cd', 'stock_cd'].includes(custom_value)) && custom.print_set_yn == '1' && repeatItem.set_name ? repeatItem.set_cd :
                          (['shop_ord_no'].includes(custom_value)) ? (list[idx].shop_ord_no) : repeatItem[custom_value]) || repeatItem.prod_name}`;
                      }
                    }
                    // SKU상품명 || SKU상품명(속성) 이면서 sku_print_type이 pack_unit(총 출고 수량만 출력)일때는 주문 수량 출력 안함
                    if (!(['sku_name', 'sku_without_prop'].includes(custom_value) && custom.sku_print_type == 'pack_unit')) {
                      return_string += `${(custom_val_split.includes('c_sale_cd') && custom.print_set_yn == true) || custom.not_print_salecnt == true || (custom.sku_print_type === 'set_pack_unit' && !repeatItem.set_no) ? '' : (custom.repeatKey === 'prod_list_temp' ? ` - ${custom.sku_print_type === 'set_pack_unit' ? repeatItem.count * repeatItem.set_pack_unit : repeatItem.count} 개` : '')}`; // 수량 출력
                    }

                    return return_string += ' </div></div>';
                  })
                  .join('');
              } else {
                // 일반 변수(맞춤설정 - 타입 - 변수 선택 후)
                if (list[idx]) {
                  div.innerHTML = list[idx][custom.val];

                  if (custom.val == 'order_name') {
                    div.innerHTML = `주문자[${list[idx].order_name}]`;
                  }

                  if (custom.val == 'sku_cd' && custom.print_set_yn == '1' && list[idx].set_no) {
                    div.innerHTML = list[idx].set_cd;
                  }

                  if (custom.val == 'stock_cd' && custom.print_set_yn == '1' && list[idx].main_prod_stock_cd) {
                    div.innerHTML = list[idx].main_prod_stock_cd;
                  }
                }
              }
            }

            // 스타일 처리
            Object.assign(div.style, custom.style);
            if (div.style.fontSize) {
              div.style.setProperty('font-size', div.style.fontSize, 'important');
            } else {
              div.style.setProperty('font-size', '9pt', 'important');
            }
            if (!div.style.fontFamily) {
              div.style.setProperty('font-family', 'HYGothic, Noto Sans, Noto Sans KR, sans-serif');
            }
            elm.appendChild(div);
          }
        });
      });

      $scope.selectedTemp.custom_data.forEach(custom => {
        if (custom.dataIndex) {
          document.querySelectorAll(`div[data-index='${custom.dataIndex}']`).forEach(elm => {
            Object.assign(elm.style, custom.style);
          });
        }
      });
    };

    /**
    * 주문데이터 가공
    */
    function orderDataInit() {
      shipmentModel.load({ uniq: _.map($scope.selectList, 'uniq') }, (status, re) => {
        if (status === 'success') {

          // 국내 송장 출력 시 보내는 곳 (집하지) 주소를 해당 모달 내 기본설정 > 주소관리에서의 집하지 주소로 변경해주어야 함 (글로벌)
          if ($scope.isGlobal) {
            re.order_data.forEach(ord => {
              const addrInfo = $scope.selectList.find(temp => ord.uniq === temp.uniq);
              ord.to_zipcd = addrInfo.to_zipcd;
              ord.to_addr1 = addrInfo.to_addr1;
              ord.to_addr2 = addrInfo.to_addr2;
              ord.to_htel = addrInfo.to_htel;
              ord.to_tel = addrInfo.to_tel;
              ord.ctry_cd = addrInfo.to_ctry_cd;
              ord.ship_method = addrInfo.ship_method;
            });
          }

          const bundleData = _.groupBy(re.order_data, 'bundle_no'); //묶음주문 당 운송장 1개니까 번들코드로 묶음
          const bundleOptData = _.groupBy(re.prod_list, 'bundle_no'); //묶음주문 당 운송장 1개니까 번들코드로 묶음
          const tempData = [];

          _.forEach(bundleData, (list, key) => {
            let item = {};
            const prod_list = [],
                  ship_method_list = [],
                  ship_cost_list = [],
                  ship_msg_list = [],
                  giftNames = [];

            // 묶음 출고수량 처리 2018-07-04 rony
            let attris = [];

            const bundle_pack_unit = {};
            const bundle_pack_unit_without_props = {};
            const bundle_pack_unit_without_props_with_saleCnt = {};
            const bundle_pack_unit_without_props_with_totalCnt = {};
            const bundle_pack_unit_saleCnt = {};
            const bundle_pack_unit_totalCnt = {};

            const set_total_cnt = {};
            _.forEach(list, o => {
              const set_sku_names = [];
              if (_.isUndefined(bundle_pack_unit[o.uniq])) {
                bundle_pack_unit[o.uniq] = [];
                bundle_pack_unit_without_props[o.uniq] = [];
                bundle_pack_unit_without_props_with_saleCnt[o.uniq] = []; // [sku상품명] 주문 수량만 표기
                bundle_pack_unit_without_props_with_totalCnt[o.uniq] = []; // [sku상품명] 출고 수량(주문 수 * 건별 출고수량)만 표기
                bundle_pack_unit_saleCnt[o.uniq] = [];  // [sku상품명(속성)] 주문 수량만 표기
                bundle_pack_unit_totalCnt[o.uniq] = []; // [sku상품명(속성)] 출고 수량(주문 수 * 건별 출고수량)만 표기
                set_total_cnt[o.uniq] = [];
              }
              // 매칭된 SKU상품에 옵션이 있는경우 옵션도 같이 노출되도록 처리 2018-11-15 rony
              if (o.attri) {
                attris = o.attri.split(',');
                _.forEach(attris, v => {
                  const [key, value] = v.split(/=(.+)/); // 속성값에 '=' 포함된경우있어서 첫 번째 '=' 기준으로 나누어 2개로만 스플릿함

                  set_sku_names.push(`${value}(${key})`);
                });

                bundle_pack_unit[o.uniq].push(`(${o.prod_name}_${set_sku_names.join('_')} X ${o.pack_unit}개)`);
                bundle_pack_unit_saleCnt[o.uniq].push(`(${o.prod_name}_${set_sku_names.join('_')})`);
                bundle_pack_unit_totalCnt[o.uniq].push(`${o.prod_name}_${set_sku_names.join('_')} - ${o.pack_unit * o.sale_cnt}개`);
              } else {
                bundle_pack_unit[o.uniq].push(`(${o.prod_name} X ${o.pack_unit}개)`);
                bundle_pack_unit_saleCnt[o.uniq].push(`(${o.prod_name})`);
                bundle_pack_unit_totalCnt[o.uniq].push(`${o.prod_name} - ${o.pack_unit * o.sale_cnt}개`);
              }
              // <>로 시작하는 옵션명 html tag로 변환 막기 위해 수정
              if (o.shop_opt_name) {
                o.shop_opt_name = o.shop_opt_name.replace(/</g, '&lt;');
              }
              bundle_pack_unit_without_props_with_saleCnt[o.uniq].push(`${o.prod_name}`);
              bundle_pack_unit_without_props_with_totalCnt[o.uniq].push(`${o.prod_name} - ${o.pack_unit * o.sale_cnt}개`);
              bundle_pack_unit_without_props[o.uniq].push(`${o.prod_name} X ${o.pack_unit}개`);
              set_total_cnt[o.uniq].push(o.pack_unit * o.sale_cnt || o.sale_cnt);
            });

            _.each(_.uniqBy(list, 'uniq'), (order, idx) => {
              // SKU상품명에 출고수량 같이 출력되도록 처리 2018-06-07 rony
              let set_sku_name = order.prod_name_pack;
              let set_sku_name_without_props = order.prod_name;
              let set_sku_without_prop_with_saleCnt = order.prod_name;
              let set_sku_without_prop_with_totalCnt = order.prod_name_pack;
              let set_sku_name_with_saleCnt = order.prod_name_pack;
              let set_sku_name_with_totalCnt = order.prod_name_pack;

              if (order.gift_name) {
                giftNames.push(order.gift_name);
              }

              if (order.pack_unit) {
                set_sku_name = bundle_pack_unit[order.uniq].join('<br />');
                set_sku_name_without_props = bundle_pack_unit_without_props[order.uniq].join('<br />');
                set_sku_without_prop_with_saleCnt = bundle_pack_unit_without_props_with_saleCnt[order.uniq].join('<br />');
                set_sku_without_prop_with_totalCnt = bundle_pack_unit_without_props_with_totalCnt[order.uniq].join('<br />');
                set_sku_name_with_saleCnt = bundle_pack_unit_saleCnt[order.uniq].join('<br />');
                set_sku_name_with_totalCnt = bundle_pack_unit_totalCnt[order.uniq].join('<br />');
              }

              const orderData = {
                prod_without_prop: order.shop_sale_name,
                prod_name: order.shop_opt_name != '' ? `${order.shop_sale_name} / ${order.shop_opt_name}` : order.shop_sale_name,
                opt_name: order.shop_opt_name != '' ? order.shop_opt_name : order.shop_sale_name,
                opt_name_with_prod_name: order.shop_opt_name != '' ? `${order.shop_opt_name}(${order.shop_sale_name})` : `(${order.shop_sale_name})`,
                sku_name: set_sku_name || '',
                sku_cd: order.sku_cd,
                sku_without_prop: set_sku_name_without_props || order.shop_sale_name,
                sku_name_with_saleCnt: set_sku_name_with_saleCnt || '',
                sku_name_with_totalCnt: set_sku_name_with_totalCnt || '',
                sku_without_prop_with_saleCnt: set_sku_without_prop_with_saleCnt || '',
                sku_without_prop_with_totalCnt: set_sku_without_prop_with_totalCnt || '',
                count: order.sale_cnt,
                set_pack_unit: order.set_pack_unit,
                gift_name: order.gift_name || '',
                stock_cd: order.stock_cd || '',
                set_name: order.set_name || '',
                set_no: order.set_no || '',
                set_cd: order.set_cd || '',
                c_sale_cd: order.c_sale_cd || '',
                main_prod_stock_cd: order.main_prod_stock_cd || '',
                is_multi: !order.set_no && re.order_data.filter(re => re.uniq === order.uniq).length > 1,
                isAddOpt: 0
              };
              // main_bundle_yn 가 삭제되었으므로 조건 삭제 함 2018-06-20 rony
              // if(order.main_bundle_yn){

              // 묶음주문 내 첫번째 주문 데이터로 송장 출력
              if (idx === 0) {
                item = order;
                item.deli_invoice = '000-0000-0000';
                // 조건부 배송여부 추가.
                // 우체국 택배의 경우 묶음주문에 조건부 배송이 포함되어있는경우 '신용' 으로 전송해야 함. 우체국 택배 여부는 주문정보 가공후 양식 선택시 알수 있고 현시점에서는 알수 없으므로 부득이하게 별도의 변수에 해당 값 할당.
                // 2018-11-29 rony
                item.ship_method_etc = '';
              }

              // }
              ship_method_list.push(order.ship_method);
              ship_cost_list.push(order.ship_cost);
              ship_msg_list.push(order.ship_msg);
              prod_list.push(orderData);
            });

            // 추가구매옵션
            // 추가구매 옵션에 opt_name 을 쏘고있으나 실제로 쓰지는 않음.. 해당값은 옵션에만 사용하는데.. view 에서 같은 object 를 쓰기 때문에 넣어줌. 2018-08-14 rony
            _.each(_.groupBy(bundleOptData[key], o => {
              return o.ord_opt_seq + o.uniq;
            }), opt => {
              const orderData = {};
              const set_sku_name = [];
              const set_sku_name_without_props = [];
              const set_sku_name_without_props_with_saleCnt = [];
              const set_sku_name_without_props_with_totalCnt = [];
              const set_sku_name_with_saleCnt = [];
              const set_sku_name_with_totalCnt = [];

              _.each(opt, (order, idx) => {
                let addOptAttri = '';

                if (order.attri_pack) {
                  addOptAttri = order.attri_pack.split(',').map(attri => `${attri.split(':')[1]}(${attri.split(':')[0]})`).join('_');
                }

                set_sku_name.push(`(${order.prod_name || order.ord_opt_name}${addOptAttri ? `_${addOptAttri}` : ''}${order.pack_unit ? ` X ${order.pack_unit}개` : ''})`);
                set_sku_name_without_props.push(`(${order.prod_name || order.ord_opt_name}${order.pack_unit ? ` X ${order.pack_unit}개` : ''})`);
                set_sku_name_without_props_with_saleCnt.push(`(${order.prod_name || order.ord_opt_name})`);
                set_sku_name_without_props_with_totalCnt.push(`${order.prod_name || order.ord_opt_name} - ${order.pack_unit * order.opt_sale_cnt}개`);
                set_sku_name_with_saleCnt.push(`(${order.prod_name || order.ord_opt_name}${addOptAttri ? `_${addOptAttri}` : ''})`);
                set_sku_name_with_totalCnt.push(`${order.prod_name || order.ord_opt_name}${addOptAttri ? `_${addOptAttri}` : ''} - ${order.pack_unit * order.opt_sale_cnt}개`);
                set_total_cnt[order.uniq].push(order.pack_unit * order.opt_sale_cnt || order.opt_sale_cnt);
                if (idx === 0) {
                  orderData.prod_name = order.ord_opt_name;
                  orderData.opt_name = order.ord_opt_name;
                  // SKU상품명에 출고수량 같이 출력되도록 처리 2018-06-07 rony
                  orderData.set_name = order.set_name;
                  orderData.set_no = order.set_no;
                  orderData.set_cd = order.set_cd;
                  orderData.sku_cd = order.sku_cd;
                  orderData.atti_name = order.attri;
                  orderData.count = order.opt_sale_cnt;
                  orderData.set_pack_unit = order.set_pack_unit;
                  orderData.main_prod_stock_cd = order.main_prod_stock_cd || '';
                }
              });

              orderData.isAddOpt = 1;
              orderData.sku_name = set_sku_name.length ? `${set_sku_name.join('<br />')}` : '';
              orderData.sku_without_prop = set_sku_name_without_props.join('<br />');
              orderData.sku_without_prop_with_saleCnt = set_sku_name_without_props_with_saleCnt.join('<br />');
              orderData.sku_without_prop_with_totalCnt = set_sku_name_without_props_with_totalCnt.join('<br />');
              orderData.sku_name_with_saleCnt = set_sku_name_with_saleCnt.join('<br />');
              orderData.sku_name_with_totalCnt = set_sku_name_with_totalCnt.join('<br />');
              orderData.is_multi = !orderData.set_no && set_sku_name.length > 1;

              prod_list.push(orderData);
            });
            // 총 품목 갯수
            item.prod_list_cnt = _.sum(Object.keys(set_total_cnt).map(key => {
              return _.sum(set_total_cnt[key]);
            }));
            const giftData = [{ prod_name: `[사은품]${list[0].gift}`, count: 1 }, { prod_name: `[사은품]${list[0].gift_prod_name}`, count: 1 }];

            item.prod_list = prod_list;

            // 규칙 적용 사은품 임시처리 2020-07-01 Boris
            item.gift_prod_name = (() => {
              const gift_pack = list[0].gift_pack;

              return gift_pack ? gift_pack.split('|').map(g => {
                const arr = g.split('#');

                return `${arr[0]}${arr[2] ? `(${arr[2]})` : ''} X ${arr[3]}개`;
              }).join('\n') : '';
            })();

            // 별칭
            item.seller_nick = (() => selectList.data.results.filter(lis => lis.bundle_no === list[0].bundle_no).find(lis => lis.seller_nick)?.seller_nick || '')();

            _.concat(item.prod_list, giftData);
            /**
            * 묶음 주문일 경우
            * 무료배송 + 착불 : 무료배송
            * 착불 + 착불 : 착불
            * 선결제 + 무료배송( or 착불) : 선결제
            * 기타 + 기타 : 착불
            * 선불이 있는 경우 무료배송(신용) 출력
            */
            if (_.uniqBy(list, 'uniq').length > 1) {
              item.bundle_ship_cost = {};
              if (ship_method_list.includes('선결제')) {
                item.bundle_ship_method = '선결제';
                item.bundle_ship_cost['최소배송비'] = ship_cost_list.sort((a, b) => a - b)[0];
                item.bundle_ship_cost['최대배송비'] = ship_cost_list.sort((a, b) => a - b)[ship_cost_list.length - 1];
                item.bundle_ship_cost['배송비합'] = _.sum(ship_cost_list);
              } else if (ship_method_list.includes('무료배송')) {
                item.bundle_ship_method = '무료배송';
              } else {
                item.bundle_ship_method = '착불';
                item.bundle_ship_cost['최소배송비'] = ship_cost_list.sort((a, b) => a - b)[0];
                item.bundle_ship_cost['최대배송비'] = ship_cost_list.sort((a, b) => a - b)[ship_cost_list.length - 1];
                item.bundle_ship_cost['배송비합'] = _.sum(ship_cost_list);
              }

              // 우체국 택배의 경우 묶음주문건에 조건부배송이 포함되어있는경우 신용으로 전송필요.
              if (ship_method_list.includes('조건부배송')) {
                item.ship_method_etc = '신용';
                item.bundle_ship_method = '선결제';
              }
            } else if (item.ship_method === '기타') {
              item.bundle_ship_method = '착불';
            }

            item.ship_msg = _.uniq(_.filter(ship_msg_list)).join(',');
            item.gift_name = giftNames.join();

            tempData.push(item);
          });
          $scope.selectList = tempData;

          originList = angular.copy(tempData);
          if ($scope.isGlobal) {
            setDomesticPrintSetting();
          }

        } else {
          commonSVC.showMessage('데이터 로드 실패');
        }
      });
    }

    // 3단용 데이터
    function divideOrder() {
      $scope.shipmentList = [];
      let tmp = [];

      _.forEach($scope.selectList, (ord, idx) => {
        ord.idx = idx;
        tmp.push(ord);
        if (idx % 3 === 2 || idx === $scope.selectList.length - 1) {
          $scope.shipmentList.push(tmp);
          tmp = [];
        }
      });
    }

    $scope.reset = () => {
      // 선택초기화.
      $scope.selectedTemp = null;
      $scope.templateList = [];
      $scope.view = '';
      loadTemplate();
    };

    // carr_no, carr_id로 템플릿 리스트 로드
    function loadTemplate() {
      $('#spinner').removeClass('hidden');
      const data = {
        carr_no: $scope.selectedDeli.carr_no,
        carr_id: $scope.selectedDeli.carr_id,
        carr_id_sub: $scope.selectedDeli.carr_id_sub,
      };

      deliveryModel.DeliveryTemplateList(data, (state, data) => {
        if (state === 'success') {
          $scope.templateList = data.results || [];
          $('#spinner').addClass('hidden');
        } else {
          commonSVC.showToaster('error', '실패', '양식 조회 실패');
          $('#spinner').addClass('hidden');
        }
      });

      // 선택 양식이 승인완료된 양식인경우 출력버튼 활성화.
      $scope.refusePrint = !_.includes(approveCarrierLists, `${$scope.selectedDeli.carr_no}+${$scope.selectedDeli.carr_id}`);
      $scope.selectedTemp = null;
    }

    /**
    * 주문정보 마스킹
    */
    function maskOrdInfo(maskingFields, ord, carr_no) {
      maskingFields.forEach(field => {
        switch (field.key) {
          case 'to_name': {
            // 롯데택배인 경우 수령자명의 두 번째 글자 마스킹 처리
            ord[`${field.position}_to_name`] = ord.to_name.replace(/^(.)(.).*/, `$1*${ord.to_name.substring(2)}`);
            // 대한통운이 아니고 마스킹 상단 영역 체크 시 주문자명 정보도 같이 마스킹 처리
            if ($scope.selectedDeli.carr_no != 4 && field.position === 'upper') {
              ord.order_name = ord.order_name.replace(/^(.)(.).*/, `$1*${ord.order_name.substring(2)}`);
            }
            break;
          }
          case 'to_addr1': {
            // 한진택배는 읍명동 이후 주소 일괄 마스킹
            if (carr_no === 5) {
              // 동 뒤에 공백이 없는경우 주소 추출이 정상적으로 되지않아 조건 수정.
              // const re = new RegExp(/(([가-힣]+(\d{1,5}|\d{1,5}(,|.)\d{1,5}|)+(읍|면|동|가|리)))(?=$|[\s,)])|([가-힣]|(\d{1,5}(~|-)\d{1,5})|\d{1,5})+(로|길)(?=$|[\s])/, 'g');
              const re = new RegExp(/(([가-힣]+(\d{1,5}|\d{1,5}(,|.)\d{1,5}|)+(읍|면|동|가|리)))|([가-힣]|(\d{1,5}(~|-)\d{1,5})|\d{1,5})+(로|길)(?=$|[\s]|[0-9]|,)/, 'g');
              const addrMatch = ord.to_addr1.match(re);
              if (!addrMatch?.length) {
                cantMaskingBundleNo.push(ord.bundle_no);
              } else {
                const addIdx = ord.to_addr1.indexOf(addrMatch[0]) + addrMatch[0].length;
                const mainAddr = ord.to_addr1.substring(0, addIdx);
                const detailAddr = ord.to_addr1.substring(addIdx);
                ord[`${field.position}_to_addr1`] = `${mainAddr}${detailAddr.replace(/[^\s]/g, '*')}`;
              }
            } else {
              ord[`${field.position}_to_addr1`] = ord.to_addr1.replace(/(([가-힣]+(\d{1,5}|\d{1,5}(,|.)\d{1,5}|)+(읍|면|동|가|리)))(?=$|[\s,)])|([가-힣]|(\d{1,5}(~|-)\d{1,5})|\d{1,5})+(로|길)(?=$|[\s])/g, '***');
            }

            break;
          }
          case 'to_htel': {
            if (ord.to_htel) {
              const replacedHtel = ord.to_htel.replace(/-/g, '').replace(/^(.{3})(.{3,4}).{4}$/, '$1-$2-****');
              ord[`${field.position}_to_htel`] = replacedHtel;
              if (field.position === 'upper') {
                if (ord.order_htel) {
                  ord.order_htel = replacedHtel;
                }
              }
            }
            break;
          }
          case 'to_tel': {
            const tel = (ord.to_tel || '').replace(/-/g, '');
            let prefixVal, middleVal;

            if (tel.length === 10 || tel.length === 11) {
              prefixVal = tel.startsWith('02') ? 2 : 3;
              middleVal = tel.startsWith('02') ? (tel.length === 10 ? 4 : 3) : 4;
            }

            const pattern = new RegExp(`^(\\d{${prefixVal}})(\\d{${middleVal}})(\\d+)$`);
            const replacedTel = tel.replace(pattern, (match, prefix, middle, suffix) => {
              return `${prefix}-${middle}-****`;
            });

            if (tel.length >= 9) {
              ord[`${field.position}_to_tel`] = replacedTel;
            }
            if (field.position === 'upper') {
              const tel = (ord.order_tel || '').replace(/-/g, '');
              if (tel.length >= 9) {
                ord.order_tel = replacedTel;
              }
            }
            break;
          }
          case 'order_name': {
            ord.order_name = ord.order_name.replace(/^(.)(.).*/, `$1*${ord.order_name.substring(2)}`);
          }
        }
      });
    }
    // CU편의점택배/이마트 승인번호 발급 - GMP.Carrier.API
    async function printCustOrdNo(type) {
      const carrName = $scope.selectedDeli.carr_name,
            carrStoreName = $scope.selectedDeli.carr_name.replace('택배', ''),
            workName = $scope.type === 'receive' ? '접수하기' : ($scope.type === 'sync' ? '동기화' : '접수취소');

      // 선택된 묶음번호별 주문건수 리스트
      const selectBndList = selectList.data.results.reduce((bnd, ord) =>
        ({ ...bnd, [ord.bundle_no]: (bnd[ord.bundle_no] || 0) + 1 }),
      {});

      if (Object.keys(selectBndList).length > 1) {
        await commonSVC.showMessage(`${workName} 실패`, `${carrName}는 한건 이상 작업이 불가능합니다.`);

        return;
      }

      const param = {
        bundleList: _.uniqBy(_.map(selectList.data.results, 'bundle_no')),
        uniqList: _.map(selectList.data.results, 'uniq'),
        templateData: $scope.selectedTemp,
        isGlobal: $scope.isGlobal
      };

      if (type === 'receive') {
        const { data: result } = await deliveryModel.issueInvoice(param);

        if (!result.result && result.error && result.error.toString().includes('이미 접수 완료된 주문입니다.')) {
          await commonSVC.showMessage(
            '접수하기 실패',
            `이미 접수하기 완료된 주문입니다. \n ${carrStoreName}에 방문하여 POSTBOX에서 직접 접수해주세요. \n\n 접수 후 [동기화]를 통해 접수한 택배 정보를 가져올 수 있습니다.`
          );
        }

        if (result.error && (result.error.toString().includes('물품 가액 초과') || result.error.toString().includes('유효하지 않은 업체코드'))) {
          const opt = {
            title: `${carrName} 접수 에러`,
            text: '* 접수가 정상적으로 진행되지 않았습니다. \n 업체코드 혹은 주문의 물품가액 등을 다시 확인해주세요. \n\n * 주문의 물품가액은 최대 500,000원을 넘을 수 없습니다.',
            type: 'warning',
          };

          await commonSVC.showConfirmCustom(opt);

        } else {
          await commonSVC.showMessage(
            `${carrName} 접수 성공`,
            `* 선택한 [출고대기]주문 ${result.result.length}건의 접수 요청이 성공하였습니다. \n  ${carrStoreName}의 POSTBOX에서 \n  아래의 승인번호를 입력하여 택배를 접수 할 수 있습니다. \n * 접수 성공된 주문은 3개월간 유효 합니다. \n  승인번호 :  \n ${result.result[0].invoiceNo}`
          );

          $uibModalInstance.close('success');
        }
      } else if (type === 'sync') {

        const syncConfirm = await commonSVC.showConfirm(
          `${carrName} 안내`,
          `* [동기화]를 클릭하여 ${carrStoreName} POSTBOX에서 접수한 택배 정보를 가져옵니다. \n * 택배 정보를 가져오면 주문의 상태가 [출고완료] 상태로 변경됩니다. \n * 택배 정보를 불러오는 데에 시간이 다소 소요될 수 있으니 이용에 참고해주세요. \n * POSTBOX에서 접수 하지 않은 내용은 불러올 수 없습니다.`
        );

        if (syncConfirm) {
          const selected = selectList.data.results;
          const dataNone = _.filter(selected, function(d) {
            return d.ord_status != '운송장출력' || d.ship_avail_yn == 0 || !d.carr_no || !d.invoice_no;
          });

          if (dataNone.length) {
            const prod_info = $rootScope.user_profile.sol_stock > 0 ? '- 출고불가인 주문은 매칭된 SKU상품의 재고를 확인하시고 추가 입고 혹은 매칭 삭제 후 출고완료처리 하시기 바랍니다.' : '';

            const opt = {
              title: '주문의 상태가 운송장출력이거나, 택배사와 송장번호가 입력되어있는 출고가능한 주문건만 출고완료가 가능합니다.',
              text: `- 운송장출력 상태가 아닌 주문은 운송장번호를 업로드하여 운송장출력 상태로 변경 후 출고완료처리 하시기 바랍니다.\n- 택배사 또는 운송장번호가 비어있는 주문은 해당정보를 입력하신 후 출고완료처리 하시기 바랍니다.\n${prod_info}`,
              type: 'warning',
              confirmButtonText: '확인'
            };

            commonSVC.showConfirmCustom(opt);

            return false;
          }

          const { data: result } = await deliveryModel.syncByIssue(param);

          if (result.status === 'success') {
            $uibModalInstance.close('success');
          } else if (result.message === '승인번호가 존재하지 않습니다.') {
            commonSVC.showMessage(
              `${carrName} 동기화 실패`,
              `승인번호가 존재하지 않습니다. \n [접수하기]를 통해 승인번호를 발급 받은 뒤에 \n ${carrStoreName} POSTBOX에서 접수 완료 후 재시도해주십시오.`
            );
          } else if (result.message === '동기화 실패') {
            commonSVC.showMessage(`${carrStoreName} POSTBOX에서 접수 완료 후 재시도해주십시오.`);
          }
        }

      } else if (type === 'cancel') {
        const confirm = await commonSVC.showConfirmCustom({
          title: `${carrName} 접수 요청 취소`,
          text: '* [접수하기]를 통해 접수한 주문을 취소할 수 있습니다. \n * 취소한 주문을 다시 취소할 수 없습니다. [접수하기]를 눌러 다시 접수해주세요.\n * POSTBOX에서 접수 완료된 주문은 취소가 불가능합니다 \n 배송 전이라면 편의점 카운터에 문의해주세요.',
          confirmButtonText: '접수취소',
          cancelButtonText: '닫기'
        });

        if (confirm) {
          const { data: result } = await deliveryModel.cancelInvoice(param);
          if (result.status === 'success') {
            $uibModalInstance.close('success');
          } else {
            commonSVC.showMessage('실패: 접수하지 않은 주문이거나 이미 배송이 시작된 주문입니다.');
          }
        }
      }
    }

    // 운송장 번호 발급 - GMP.Carrier.API
    async function printInvoice() {
      $scope.wait = true;
      // 운송장 출력시 로딩 추가.
      $('#spinner').removeClass('hidden');

      // 선택된 묶음번호별 주문건수 리스트
      const selectBndList = selectList.data.results.reduce((bnd, ord) =>
        ({ ...bnd, [ord.bundle_no]: (bnd[ord.bundle_no] || 0) + 1 }),
      {});

      const param = {
        bundleList: _.uniqBy(_.map(selectList.data.results, 'bundle_no')),
        uniqList: _.map(selectList.data.results, 'uniq'),
        templateData: $scope.selectedTemp,
        bundles: selectBndList,
        isGlobal: $scope.isGlobal
      };

      if ($scope.isReprint) {
        param.templateData.carr_name = $scope.selectedDeli.carr_name;
        param.url = 'reissue';
      }

      try {
        const { data: result } = await deliveryModel.issueInvoice(param);

        if (result.status === 'success') {
          // 바코드 렌더링 옵션
          const barcodeOpt = {
            default: { format: 'CODE128', font: 'Arial' },
            noValue: { format: 'CODE128', displayValue: false, font: 'Arial' }, // 바코드 하단 문구 제거 옵션 추가
            newFormat: { format: 'CODE128A', font: 'Arial' },
            newFormatNoValue: { format: 'CODE128A', displayValue: false, font: 'Arial' }, // 바코드 하단 문구 제거 옵션 추가
            itfFormat: { format: 'ITF', displayValue: false, margin: 0, font: 'Arial' }, // 바코드 하단 문구 제거 옵션 추가
            code39Format: { format: 'CODE39', displayValue: false, marginTop: 0, marginRight: 30, font: 'Arial' }, // 바코드 하단 문구 제거 옵션 추가
          };

          // 출력 에러 운송장 idx배열
          let errIndexes = [];
          const barcodeList = [];
          // 롯데택배, 로젠택배, 카카오T당일배송의 경우 택배사프로그램에 송장정보 전송
          if ([7, 8, 956].includes($scope.selectedDeli.carr_no)) {

            if (!result.result) {
              commonSVC.showMessage('택배 API 호출 에러');
              $scope.wait = false;
            } else {
              const errOrds = result.result.filter(o => o.publishErrMsg);

              const addMsg = errOrds.length ? errOrds.reduce((prev, curr) => prev + `\n묶음번호 - ${curr.bundleNo}: ${curr.publishErrMsg}`, '아래의 주문건은 에러발생으로 운송장발급이 실패되었으니 확인해주십시오.\n') : '';
              // 롯데택배, 카카오T당일배송은 그대로 송장 출력 진행하기 위해 송장번호 발급되지 않은 주문 인덱스 저장
              if ([8, 956].includes($scope.selectedDeli.carr_no)) {
                errIndexes = errOrds.map(({ bundleNo }) => $scope.selectList.findIndex(({ bundle_no }) => bundle_no === bundleNo));

                if (!$scope.selectList.length) {
                  $scope.wait = false;
                }

                if (!$scope.isReprint) {
                  const isAllFail = !($scope.selectList.length - errOrds.length);

                  // 전부 실패한 경우 실패 문구 출력
                  await commonSVC.showMessage(`운송장 발급에 ${isAllFail ? '실패' : '성공'}하였습니다.`, addMsg);

                  if (isAllFail) {
                    $uibModalInstance.close('cancel');

                    return false;
                  }
                }
              } else {
                commonSVC.showMessage('운송장 번호 자동 발급에 성공하였습니다.', addMsg);
                $uibModalInstance.close('success');
              }
            }
          }

          for (const item of result.result) {
            // 롯데택배, 카카오T당일배송 에러 송장은 출력하지 않으므로 바코드 처리 지나감
            if ([8, 956].includes($scope.selectedDeli.carr_no) && item.publishErrMsg) {
              continue;
            }

            // 대한통운의 경우 정렬인쇄 기능이 있어서 백엔드에서 받은 순서대로 라벨 출력처리
            // 정렬이 택배분류코드 인 경우에만 적용
            // TODO: 택배사 정렬인쇄 기능 임시 롤백 확인 후 재적용 예정
            // if ($scope.selectedDeli.carr_no === 4 && $scope.selectedTemp.print_sort_criteria === '택배분류코드') {

            //   const orderedInvoices = result.result.map(item => item.bundleNo);

            //   // selectList 재정렬
            //   $scope.selectList.sort((a, b) => {
            //     return orderedInvoices.indexOf(a.bundle_no) - orderedInvoices.indexOf(b.bundle_no);
            //   });
            // }

            if (![7].includes($scope.selectedDeli.carr_no)) {
              const idx = _.findIndex($scope.selectList, { bundle_no: item.bundleNo });

              $scope.selectList[idx].deli_invoice = item.invoiceNo.toString();
              Object.assign($scope.selectList[idx], item.colDelCd);

              // 집배코드 바코드
              // 3단양식의 경우 내부 루프 로직대문에 강제로 jsbarcode 호출시 에러나는 경우가 있어 조건 분기
              if (
                ['T020'].includes($scope.selectedTemp.template_type_cd) ||
                  ($scope.selectedTemp.template_type_cd === 'T008' && $(`.barcode.dcode_${idx}`).length)
              ) {
                barcodeList.push(generateBarcode(`.barcode.dcode_${idx}`, $scope.selectList[idx].dcode, barcodeOpt.newFormat));
              }

              // 집배코드 바코드 - 하단 문구 제외 버전
              // 3단양식의 경우 내부 루프 로직대문에 강제로 jsbarcode 호출시 에러나는 경우가 있어 조건 분기
              if (
                ['T007', 'T009', 'T014'].includes($scope.selectedTemp.template_type_cd) ||
                  ($scope.selectedTemp.template_type_cd === 'T008' && $(`.barcode.dcode_${idx}_barcode_only`).length)
              ) {
                barcodeList.push(generateBarcode(`.barcode.dcode_${idx}_barcode_only`, $scope.selectList[idx].dcode, barcodeOpt.newFormatNoValue));
              }

              // 도착지터미널 바코드
              if (['T010_new', 'T021_new', 'T022', 'T028', 'T030'].includes($scope.selectedTemp.template_type_cd)) {
                barcodeList.push(generateBarcode(`.barcode.tmlcode_${idx}`, $scope.selectList[idx].TML_COD, barcodeOpt.default));
              }

              // 롯데택배 처리
              if ($scope.selectedDeli.carr_no === 8) {
                // 도착지코드 바코드
                barcodeList.push(generateBarcode(`.barcode.rcvcode_${idx}_barcode_only`, $scope.selectList[idx].filt_cd, barcodeOpt.code39Format));

                $scope.selectList[idx].pickUpNm = $scope.selectedDeli.misc1;
                $scope.selectList[idx].pickUpTel = $scope.selectedDeli.misc2;

                const ship_type = $scope.selectList[idx].bundle_ship_method && $scope.selectList[idx].bundle_ship_method !== 'null' ? ($scope.selectList[idx].bundle_ship_method === '착불' ?
                  '(착)' : ($scope.selectList[idx].bundle_ship_method === '신용' ? '(신)' : '(현)')) : ($scope.selectList[idx].ship_method === '착불' ?
                  '(착)' : ($scope.selectedTemp.sett_ship_free_txt === '신용' ? '(신)' : '(현)'));

                const ship_cost = $scope.selectList[idx].bundle_ship_method && $scope.selectList[idx].bundle_ship_method !== 'null' ?
                  (ship_type === '(착)' ? ($scope.selectedTemp.bundle_ship_cost_type === '직접입력' ? $scope.selectedTemp.bundle_ship_cost + '원' :
                    ($scope.selectList[idx].ship_method !== '기타' ? $scope.selectList[idx].bundle_ship_cost[$scope.selectedTemp.bundle_ship_cost_type] + '원' : '')) : '') : (ship_type === '(착)' ? $scope.selectedTemp.ship_cod_cost + '원' : '');

                $scope.selectList[idx].lotte_ship_cost = `${ship_type} ${ship_cost}`;
              }

              // 운송장 바코드
              // 카카오T당일배송은 html그려진 후에 주문 필터링 하기 때문에 생성시의 html인덱스랑 현재 인덱스랑 달라 바코드 생성에 문제가 생겨 새로 할당
              const htmlIdx = $scope.selectedDeli.carr_no === 956 ? $scope.selectList.find(o => o.bundle_no === item.bundleNo).idx : idx;

              if ($(`.barcode.key_${htmlIdx}`).length) {
                barcodeList.push(generateBarcode(`.barcode.key_${htmlIdx}`, $scope.selectList[idx].deli_invoice.replace(/-/g, ''), barcodeOpt.default));
              }

              // 운송장 바코드 - 하단 문구 제외 버전
              if ($(`.barcode.key_${idx}_barcode_only`).length) {
                const opt = $scope.selectedDeli.carr_no === 8 ? barcodeOpt.itfFormat : barcodeOpt.noValue;

                barcodeList.push(generateBarcode(`.barcode.key_${idx}_barcode_only`, $scope.selectList[idx].deli_invoice.replace(/-/g, ''), opt));

                // 롯데택배 바코드 svg 크기 조정
                if ($scope.selectedDeli.carr_no === 8) {
                  $timeout(() => {
                    document.querySelectorAll(`svg.barcode.key_${idx}_barcode_only`).forEach(el => {
                      let viewBoxVal = '0 0 234 120';
                      el.style.width = '189px';

                      // 하단 바코드가 길이때문에 송장 넘어가서 위치/크기 다시 조정
                      if (el.classList.contains('barcode2')) {
                        el.style.position = 'absolute';
                        el.style.top = 0;
                        el.style.left = '16px';
                        el.style.width = '173px';
                        el.style.height = '71px';
                        viewBoxVal = '0 0 234 130';
                      }

                      el.setAttribute('viewBox', viewBoxVal);
                      el.setAttribute('preserveAspectRatio', 'none');
                    });
                    document.querySelector(`svg.barcode.rcvcode_${idx}_barcode_only`).setAttribute('viewBox', '40 0 200 160');
                  }, 100);
                }
              }

              if ($scope.selectedDeli.carr_no == 6) {
                // barcode_zipcd 을 안쓰는 양식이 있어 있는 지 확인
                if ($('.barcode_zipcd').length) {
                  barcodeList.push(generateBarcode(`.barcode_zipcd.key_${idx}`, $scope.selectList[idx].to_zipcd.replace(/-/g, ''), barcodeOpt.default));
                }

                // 우체국 택배 집배코드에 코스정보 추가
                $scope.selectList[idx].courseNo = item.colDelCd.courseNo;
                //delivAreaCd 파싱
                $scope.selectList[idx].delivAreaCd = item.colDelCd.delivAreaCd.split(/(.{2})(.{3})(.{2})(.{2})/);
                $scope.isCapital = ['A', 'B', 'C'].includes($scope.selectList[idx].delivAreaCd[1].charAt(0));
              }

              if ($scope.selectedDeli.carr_no == 956) {
                // 운송장 큐알코드 추가
                if ($(`.qrcode.key_${htmlIdx}`).length) {
                  const elm = document.getElementsByClassName(`qrcode key_${htmlIdx}`);
                  const opt = {
                    errorCorrectionLevel: 'H',
                    type: 'image/jpeg',
                    quality: 0.3,
                    margin: 1 };

                  Array.from(elm).forEach(e => {
                    QRCode.toDataURL($scope.selectList[idx].deli_invoice.replace(/-/g, ''), opt, function (err, url) {
                      e.src = url;
                    });
                  });
                }

                // 카카오T당일배송 담당센터, 지역구 추가
                $scope.selectList[idx].delivHub = $scope.hubInfo[item.bundleNo].delivHub;
                $scope.selectList[idx].delivArea = $scope.hubInfo[item.bundleNo].delivArea;

                // 운송장 출력시 변경 데이터 dom요소에 반영되지 않는 오류로 dom요소 직접 수정
                document.getElementById(`topInvoiceNo_${idx}`).textContent = `${$scope.selectList[idx].deli_invoice} (${$scope.selectList[idx].current_page_cnt}/${$scope.selectList[idx].total_page_cnt})`;
                document.getElementById(`delivHub_${idx}`).textContent = $scope.selectList[idx].delivHub;
                document.getElementById(`delivArea_${idx}`).textContent = $scope.selectList[idx].delivArea;
              }
            }
          }

          // 송장 정보가 반영되지 않는 경우가 있어 추가
          $timeout();
          // 출력 할 운송장이 없는 경우 출력하지 않음
          if ($scope.selectList.length) {
            // 바코드 생성되는 것 때문에 타임아웃 추가
            $timeout(() => {
              if ($scope.selectedDeli.carr_no != 7) {
                if (barcodeList.length) {
                  Promise.all(barcodeList)
                    .then(() => labelPrint('print', errIndexes))
                    .catch((err) => {
                      if (err.isBarcode) {
                        commonSVC.showToaster('error', '실패', `${err.data} 바코드 생성 중 오류가 발생하였습니다.`);
                      } else {
                        commonSVC.showToaster('error', '실패', `송장출력 중 오류가 발생하였습니다:\n${err}`);
                      }
                    });
                } else {
                  labelPrint('print', errIndexes);
                }
              }
            }, 1500);
          }
        } else if (result.status === 'error') {
          $timeout(() => {
            if (result?.error === 'unAvailOrder') {
              commonSVC.showMessage('운송장 출력이 불가능한 주문건이 포함되어 있습니다.', '[출력 가능 조건]\n주문상태: 출고대기 / 배송방법: 일반 택배발송\n(배송방법: 방문수령, 퀵배송, 일반우편, 설치배송 출력 불가)');
            } else if (result?.error.includes('비밀번호 오류')) {
              commonSVC.showMessage('실패', '택배사 계정의 비밀번호가 틀렸습니다.\n비밀번호 재설정 후 출력을 진행해 주세요.');
            } else {
              commonSVC.showMessage('실패', result?.error);
            }
          }, 500);
          $scope.wait = false;
        }
        $('#spinner').addClass('hidden');
      } catch (err) {
        $timeout(() => {
          commonSVC.showMessage('실패', err.xhrStatus === 'timeout' ? '택배사 서버에서 응답을 받지 못해 운송장발행에 실패하였습니다.\n잠시 후 재시도 부탁드립니다.' : '운송장 발행에 실패하였습니다.\n잠시 후 재시도 부탁드립니다.');
        }, 500);
        $scope.wait = false;
        $('#spinner').addClass('hidden');
      }
    }

    const generateBarcode = (id, data, opt) => {
      return new Promise(function(resolve, reject) {
        JsBarcode(id, data, {
          ...opt,
          valid: (valid) => {
            valid ? resolve() : reject({ data, isBarcode: true });
          },

        });
      });
    };

    // 출력
    const labelPrint = _.throttle(async (type, errIndexes = []) => {
      let style = document.getElementById('invoice-print-style');
      const features = 'menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes,width=1500,height=800,left=150,top=150';
      let printPage = window.open('about:blank', 'shipmentPrint', features); // 새창을 열어줌

      const script = '';
      // 프린트 시 보여줄 정보들
      const selectGroup = [...document.querySelectorAll('#invoice_html .print-content-example')]
        .filter((e, idx) => !errIndexes.includes(idx)) // 에러송장 제외
        .map(div => div.innerHTML);

      let retryCount = 0;
      // printPage 안열린 경우 재시도 2회
      while (retryCount < 2 && (!printPage || printPage.closed || typeof printPage.closed == 'undefined')) {
        commonSVC.showToaster('error', '실패', '팝업 차단을 해제해주세요.');
        const confirm = await commonSVC.showConfirmCustom({
          title: '인쇄 페이지 열기 실패',
          confirmButtonText: '다시 시도하기',
          showCancelButton: false,
          allowEscapeKey: true,
        });

        if (confirm) {
          printPage = window.open('about:blank', 'shipmentPrint', features);
        }

        retryCount++;
      }

      // style이 null인 경우 다시 할당(2회까지 재시도)
      for (let i = 0; i < 2 && !style; i++) {
        style = document.getElementById('invoice-print-style');
      }

      printPage.onload = function() {
        printPage.document.open();

        printPage.document.write(`
            <html>
            <head>
            <style>${style.innerText}</style>
            ${script}
            </head>
            <body>
            ${(($scope.selectedTemp.carr_no === 5 && $scope.selectedTemp.template_type_cd === 'T030') ? _.chunk(selectGroup, 3) : selectGroup).join('<div class="breakhere"></div>')}
            </body>
            </html>
          `);

        printPage.document.close();
      };

      printPage.onload();

      // 미리보기
      if (type !== 'preview') {

        // 0.5 초 딜레이
        await new Promise(((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, 500);
        }));

        printPage.focus();
        printPage.print();
        printPage.close();
      }

      // 프린트시 주문상태변경함수 호출.
      if (type === 'print') {
        // 운송장전체 출력시에 불가능한 주문건이 존재할 경우 안내모달 한번 나가도록 수정
        if ($scope.selectList.length !== totalCount) {
          commonSVC.showMessage('배송방법이 방문수령, 퀵배송, 일반우편, 설치배송인 주문 제외 후 운송장출력이 완료되었습니다.');
        }

        $uibModalInstance.close('success'); // 출력후 창 닫고 새로고침
      }

      $scope.wait = false;
    }, 1000, { trailing: false });

    /**
     * 해외주문 국내운송장 출력 시 해외주문 국내운송장 출력 > 기본설정에서 설정한 받는사람 이름을 사용하도록 처리
     */
    function setDomesticPrintSetting() {
      $('#spinner').removeClass('hidden');
      shipmentModel.getDomesticAddressSetting().then(result => {
        const shop_setting = result.data.find(setting => setting.pa_shop_cd === $scope.selectList[0].pa_shop_cd);
        if (shop_setting) {
          $scope.selectList.forEach(order => order.to_name = shop_setting.to_name);
          originList.forEach(order => order.to_name = shop_setting.to_name);
        }
        $('#spinner').addClass('hidden');
      }).catch(() => {
        commonSVC.showToaster('error', '실패', '국내 송장 출력 기본 정보 조회에 실패했습니다.');
      });
    }

    $scope.init = async (isLast) => {

      if (isLast) {
        await $scope.load();
      }
    };
  });