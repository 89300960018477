'use strict';

angular.module('gmpApp')
  .controller('LogBarcodeHistoryCtrl', function (
    $rootScope, $scope, gettextCatalog, settings, commonSVC, warehouseList, systemList
  ) {
    /* 검색 및 버튼 관련 변수 */
    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
        edate: moment().format('YYYY-MM-DD'),
        shop_cd: '',
        shop_id: '',
      },
      searchData: {
        all_checked: false,  // 전체선택 여부
        selectCount: 0,       // 검색전체 건수
        totalCount: 0,       // 검색전체 건수
        showCount: 50,
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
          { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
          { label: gettextCatalog.getString('주문자'), value: 'order_name' },
          { label: gettextCatalog.getString('수령자'), value: 'to_name' },
          { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
          { label: gettextCatalog.getString('바코드'), value: 'barcode' },
          { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name' },
          { label: gettextCatalog.getString('속셩명'), value: 'shop_opt_name' },
          { label: gettextCatalog.getString('작업자'), value: 'wm_name' }
        ],
        search_date_type: [
          { label: gettextCatalog.getString('출고완료일'), value: 'wdate' }
        ],
      },
      searchDetail: [
        {
          // 채널 선택
          title: gettextCatalog.getString('쇼핑몰 선택'),
          search_name: 'shop_cd',
          item_list: _.unionBy($rootScope.use_channel_list, 'shop_cd'),
          item_key: 'shop_name',
          item_sub_key: 'shop_name_kr',
          item_value: 'shop_cd',
          select_value: '',
          filter: function(option) {
            return !option.pa_shop_cd?.startsWith('X');
          },
          add_class: 'select-search'
        },
        {
          // 채널 계정 선택
          title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
          search_name: 'shop_id',
          item_list: $rootScope.use_channel_list,
          item_key: 'shop_id',
          item_value: 'shop_id',
          select_value: '',
          add_class: 'select-search',
          filter: function(option) {
            // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
            return option.shop_cd == $scope.searchForm.shop_cd && option.shop_id;
          }
        },
        {
          // 배송처 선택
          title: gettextCatalog.getString('배송처 선택'),
          search_name: 'delivery_vendor',
          item_list: warehouseList.data.result || [],
          item_key: 'warehouse_name',
          item_value: 'code',
          select_value: '',
          add_class: 'select-search'

        }
      ]
    };

    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);
    $scope.searchDetail = angular.copy(search.searchDetail);

    // 검색영역 버튼 설정
    $scope.searchBtn = {
      table_actions: [
        // 엑셀다운로드
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('전체엑셀 다운로드'),
              action: function () {
                excelDown('all');
              },
              perm_only: ['order.order']
            }, {
              label: gettextCatalog.getString('선택엑셀 다운로드'),
              action: function () {
                excelDown('select');
              },
              perm_only: ['order.order']
            }
          ]
        }
      ],
      actions_right: []
    };

    // 데이터 테이블 관련기능 바인딩
    $scope.searchFn = {
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    /* 데이터 테이블 */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['wdate', 'bundle_no', 'shop_ord_no', 'order_name', 'to_name', 'carr_name', 'sale_cnt', 'pack_unit', 'wm_name'],
      alignRightColumns: [],
      defaultSortingColumns: ['wdate'], //3
      notSortingColumns: [],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/history/barcode`,
        requestWillAction: function (d) {
          const data = angular.merge({}, d, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'wdate',
          title: '출고완료일',
          width: 130
        },
        {
          key: 'bundle_no',
          title: '묶음번호',
          width: 300
        },
        {
          key: 'shop_ord_no',
          title: '주문번호',
          width: 190
        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 250,
          notCompile: true,
          template: function(row) {
            return `${row.shop_name}${row.shop_cd !== 'A000' ? `(${row.shop_id})` : ''}`;
          }
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 150
        },
        {
          key: 'order_name',
          title: '주문자',
          width: 150
        },
        {
          key: 'to_name',
          title: '수령자',
          width: 100
        },
        {
          key: 'barcode',
          title: '바코드',
          width: 100
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 120
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 220
        },
        {
          key: 'shop_opt_name',
          title: '속성',
          width: 220
        },
        {
          key: 'carr_name',
          title: '배송사',
          width: 120
        },
        {
          key: 'invoice_no',
          title: '운송장번호',
          width: 150,
          template: function(row) {
            return row.carr_no === 956 ? String(row.invoice_no).padStart(12, '0') : row.invoice_no;
          }
        },
        {
          key: 'sale_cnt',
          title: '주문수량',
          width: 60
        },
        {
          key: 'pack_unit',
          title: '출고수량',
          width: 60
        },
        {
          key: 'wm_name',
          title: '작업자',
          width: 10
        }
      ]
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay, callback) {
      $rootScope.work_no = null;
      $scope.grid.methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function() {
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchData.search_key_name = '전체';
      $scope.selectCount = 'total';
      $rootScope.work_no = null;
      $scope.searchDo(true, true);
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 엑셀다운
     */
    function excelDown(type) {
      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: [
          { header: '출고완료일.', key: 'wdate', select: true },
          { header: '묶음번호', key: 'bundle_no', select: true },
          { header: '주문번호', key: 'shop_ord_no', select: true },
          { header: '쇼핑몰(계정)', key: 'shop_name', select: true },
          { header: '배송처', key: 'depot_name', select: true },
          { header: '주문자', key: 'order_name', select: true },
          { header: '수령자', key: 'to_name', select: true },
          { header: '바코드', key: 'barcode', select: true },
          { header: 'SKU코드', key: 'sku_cd', select: true },
          { header: 'SKU상품명', key: 'prod_name', select: true },
          { header: '속성', key: 'shop_opt_name', select: true },
          { header: '배송사', key: 'carr_name', select: true },
          { header: '운송장번호', key: 'invoice_no', select: true },
          { header: '주문수량', key: 'sale_cnt', select: true },
          { header: '출고수량', key: 'pack_unit', select: true },
          { header: '작업자', key: 'wm_name', select: true }
        ],
        title: '바코드 출고 내역',
        url: '/app/history/barcode/excelDown',
        searchForm: $scope.pageData,
        page: 'excel_history'
      };

      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const selectList = $scope.grid.methods.selectedData('all');

        if (!selectList.length) {
          commonSVC.showMessage('선택된 내역이 없습니다.');

          return false;
        } else {
          resolve.data.select_list = selectList;
          resolve.data.count = selectList.length;
          resolve.data.isAll = false;
        }
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    }
  });
