'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentAsListCtrl',
    function (
      // common
      $state, $rootScope, $scope, $timeout, gettextCatalog, $filter,
      // info
      settings, userInfo,
      // SVC
      commonSVC, shipmentSVC, asSVC,
      // Model
      asModel,
      // List
      warehouseList, solCarrList, systemList, gridInfo, memoTemplateList
    ) {
      $scope.deliveryList = $rootScope.deliveryInfoList;
      $scope.memoTemplateList = memoTemplateList.data.results || [];
      $scope.list_name = 'as';
      $scope.loadDataTable = gridInfo;

      const channelList = angular.copy($rootScope.use_channel_list);

      // 별칭정보 담기
      const seller_nick_info = [];

      _.forEach(channelList, function(chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });

      channelList.push({ shop_name: '직접입력', shop_cd: 'A000' });

      let bundle_group = null; // 묶음 코드
      const deliMethodList = [];
      let orderList = {};

      $scope.totalCount = 0;
      $scope.selectCount = 'total';
      $scope.excelFieldList = angular.copy(shipmentSVC.excelFieldList.unstoring);

      if (!$rootScope.user_profile.sol_stock) {
        $scope.excelFieldList = _.filter($scope.excelFieldList, function (f) {
          return ['건별출고수량', 'SKU코드', 'SKU상품명', '매입처', '바코드', '총출고수량'].indexOf(f.header) === -1;
        });
      }

      shipmentSVC.deliMethodList.forEach(function(val) { deliMethodList.push({ key: val, value: val }); });

      /* 검색 및 버튼 관련 변수 */
      const search = {
        searchForm: {
          search_key: 'all',
          search_word: '',
          search_type: 'partial',
          date_type: 'wdate',
          sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          site_id: '',
          site_code: '',
          status: ['AS요청', 'AS접수', 'AS가능', 'AS불가', 'AS완료', 'AS검수', 'AS출고'],
          recent_month: 1,
          page: 'as',
          bundle_yn: true,
          delivery_vendor: '',         //배송처
          memo_yn: '', // 메모여부
        },
        searchData: {
          all_checked: false,  // 전체선택 여부
          selectType: true,    // 셀렉트카운팅시 번들갯수 말고 row별로 처리 여부
          selectCount: 0,      // 검색전체 건수
          selectBundleCnt: 0,  // 선택 묶음건수
          totalCount: 0,       // 검색전체 건수
          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: 'all' },
            { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
            { label: gettextCatalog.getString('요청자명'), value: 'req_name' },
            { label: gettextCatalog.getString('수령자명'), value: 'to_name' },
            { label: gettextCatalog.getString('A/S담당자'), value: 'mender_name' },
            { label: gettextCatalog.getString('메모'), value: 'memo' },
          ],
          search_date_type: [
            { label: gettextCatalog.getString('A/S 등록일'), value: 'wdate' },
            { label: gettextCatalog.getString('A/S 요청일'), value: 'req_time' },
            { label: gettextCatalog.getString('A/S 접수일'), value: 'acpt_time' },
            { label: gettextCatalog.getString('A/S 심의일'), value: 'review_time' },
            { label: gettextCatalog.getString('A/S 완료일'), value: 'as_com_time' },
            { label: gettextCatalog.getString('A/S 검수일'), value: 'chk_time' },
            { label: gettextCatalog.getString('A/S 출고일'), value: 'as_out_time' }
          ],
        },
        searchDetail: [
          {
            title: gettextCatalog.getString('쇼핑몰 선택'),
            search_name: 'shop_cd', // 2018-09-09 Jacob 상세검색에서 쇼핑몰 선택시 정상적으로 리로드 되지 않는 문제 수정
            item_list: commonSVC.getSiteList(channelList),
            item_key: 'shop_name',
            item_value: 'shop_cd',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
              return !option.pa_shop_cd?.startsWith('X');
            },
          },
          {
            title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
            search_name: 'shop_id',
            item_list: commonSVC.getSiteIdList(channelList),
            item_key: 'shop_id',
            item_value: 'shop_id',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
              return option.shop_cd == $scope.searchForm.shop_cd && option.shop_id;
            }
          },
          {
            title: gettextCatalog.getString('A/S 비용'),
            search_name: 'as_cost_yn',
            item_list: [{ key: 1, value: '유료' }, { key: 0, value: '무료' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            title: gettextCatalog.getString('메모여부'),
            search_name: 'memo_yn',
            item_list: [{ key: 1, value: '메모 있음' }, { key: 0, value: '메모 없음' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          }
        ],
      };

      // 배송처일 때, 배송처 검색 조건 제거 #gmpkr-7591 2019-09-18 Jacob
      if ($rootScope.user_profile.auth_type === '배송처') {
        const searchIndex = _.findIndex(search.searchDetail, { search_name: 'delivery_vendor' });

        if (searchIndex > -1) {
          search.searchDetail.splice(searchIndex, 1);
        }
      }

      $scope.searchData = angular.copy(search.searchData);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchBtn = {
        actions_right: [
          {
            label: `<i class="icon-plus2"></i><span> ${gettextCatalog.getString('직접 입력')}</span>`,
            small_label: '직접입력',
            test_id: 'btn-event-rule',
            btn_type: 'button',
            add_class: 'col-xs-12',
            perm_only: ['order.shipment'],
            action: function() {
              $scope.showAddModal(null);
            }
          }
        ],
        table_actions: [
          {
            label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
            test_id: 'drop-work',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: gettextCatalog.getString('주문복사'),
                test_id: 'btn-order-copy',
                action: function () {
                  $scope.asCopy();
                },
              },
              {
                label: gettextCatalog.getString('주문삭제'),
                test_id: 'btn-order-del',
                action: function () {
                  $scope.asDelete();
                },
              },
              {
                label: gettextCatalog.getString('주문분할'),
                test_id: 'btn-order-split',
                action: function () {
                  $scope.asDivideBundle();
                },
              }, {
                label: gettextCatalog.getString('합포장'),
                test_id: 'btn-order-allpack',
                action: function () {
                  $scope.combine();
                },
              }, {
                label: gettextCatalog.getString('SMS 발송'),
                test_id: 'btn-order-send-sms',
                action: function () {
                  $scope.sendModal('sms');
                },
              },
              // {
              //   label: gettextCatalog.getString('알림톡 발송'),
              //   action: function () {
              //     $scope.altalkSend();
              //   }
              // }, {
              //   label: gettextCatalog.getString('알림톡 그룹 회원 추가'),
              //   action: function () {
              //     $scope.altalkGroupMemAdd();
              //   }
              // },
              // {
              //   label: gettextCatalog.getString('SMS 발송'),
              //   test_id: 'btn-send-sms',
              //   action: function () {
              //     $scope.sendModal('sms');
              //   },
              //   perm_only: ['order.order+admin']
              // }
            ]
          }, {
            label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
            test_id: 'drop-excel',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: gettextCatalog.getString('엑셀 다운'),
                test_id: 'btn-excel-download',
                action: function () {
                  $scope.excelDown();
                },
              }, {
                label: gettextCatalog.getString('배송정보 업로드'),
                test_id: 'btn-delivery-upload',

                action: function () {
                  $scope.uploadInvoiceExcel();
                },
              }
            ]
          },
          {
            label: gettextCatalog.getString('A/S요청'),
            test_id: 'btn-product-match',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.asSetState('AS요청');
            },
            ngIfFunc: function () {
              return asSVC.activBtn.req.indexOf($scope.selectCount) !== -1 ? 'y' : 'n';
            },
          },
          {
            label: gettextCatalog.getString('A/S접수'),
            test_id: 'btn-product-match',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.asSetState('AS접수');
            },
            ngIfFunc: function () {
              return asSVC.activBtn.acpt.indexOf($scope.selectCount) !== -1 ? 'y' : 'n';
            },
          },
          {
            label: gettextCatalog.getString('A/S가능'),
            test_id: 'btn-product-match',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.asSetState('AS가능');
            },
            ngIfFunc: function () {
              return asSVC.activBtn.possible.indexOf($scope.selectCount) !== -1 ? 'y' : 'n';
            },
          },
          {
            label: gettextCatalog.getString('A/S불가'),
            test_id: 'btn-product-match',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.asSetState('AS불가');
            },
            ngIfFunc: function () {
              return asSVC.activBtn.impossible.indexOf($scope.selectCount) !== -1 ? 'y' : 'n';
            },
          },
          {
            label: gettextCatalog.getString('A/S완료'),
            test_id: 'btn-product-match',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.asSetState('AS완료');
            },
            ngIfFunc: function () {
              return asSVC.activBtn.complete.indexOf($scope.selectCount) !== -1 ? 'y' : 'n';
            },
          },
          {
            label: gettextCatalog.getString('A/S검수'),
            test_id: 'btn-product-match',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.asSetState('AS검수');
            },
            ngIfFunc: function () {
              return asSVC.activBtn.check.indexOf($scope.selectCount) !== -1 ? 'y' : 'n';
            },
          },
          {
            label: gettextCatalog.getString('운송장출력'),
            test_id: 'btn-print-delivery',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.printInvoice('print');
            },
            ngIfFunc: function () {
              return asSVC.activBtn.out.indexOf($scope.selectCount) !== -1 ? 'y' : 'n';
            },
          },
          {
            label: gettextCatalog.getString('A/S출고'),
            test_id: 'btn-product-match',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.asSetState('AS출고');
            },
            ngIfFunc: function () {
              return asSVC.activBtn.out.indexOf($scope.selectCount) !== -1 ? 'y' : 'n';
            },
          },

        ]
      };

      $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function() {
          $scope.searchDo(true, true);
        },
        resetDo: function() {
          $scope.resetDo();
        },
        changeLen: function(count) {
          $scope.changeCount(count);
        },
      };

      $scope.customContext = [{
        isSubMenu: true,
        line: true,
        subMemuName: '상태 수동변경',
        contextList: [
          {
            label: gettextCatalog.getString('A/S요청으로 상태변경'),
            action: function () {
              $scope.asSetState('AS요청');
            },
            filter: function(row) {
              return asSVC.activBtn.req.indexOf(row.as_status) !== -1;
            },
          }, {
            label: gettextCatalog.getString('A/S접수로 상태변경'),
            action: function () {
              $scope.asSetState('AS접수');
            },
            filter: function(row) {
              return asSVC.activBtn.acpt.indexOf(row.as_status) !== -1;
            },
          }, {
            label: gettextCatalog.getString('A/S가능으로 상태변경'),
            action: function () {
              $scope.asSetState('AS가능');
            },
            filter: function(row) {
              return asSVC.activBtn.possible.indexOf(row.as_status) !== -1;
            },
          }, {
            label: gettextCatalog.getString('A/S불가로 상태변경'),
            action: function () {
              $scope.asSetState('AS불가');
            },
            filter: function(row) {
              return asSVC.activBtn.impossible.indexOf(row.as_status) !== -1;
            },
          }, {
            label: gettextCatalog.getString('A/S완료로 상태변경'),
            action: function () {
              $scope.asSetState('AS완료');
            },
            filter: function(row) {
              return asSVC.activBtn.complete.indexOf(row.as_status) !== -1;
            },
          }, {
            label: gettextCatalog.getString('A/S검수로 상태변경'),
            action: function () {
              $scope.asSetState('AS검수');
            },
            filter: function(row) {
              return asSVC.activBtn.check.indexOf(row.as_status) !== -1;
            },
          }, {
            label: gettextCatalog.getString('A/S출고로 상태변경'),
            action: function () {
              $scope.asSetState('AS출고');
            },
            filter: function(row) {
              return asSVC.activBtn.out.indexOf(row.as_status) !== -1;
            },
          }
        ]
      }];

      $scope.selectRowUniqList = [];

      $scope.isMemoDisabled = (mend_no) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(mend_no);

      // 데이터 테이블에서 선택한 rowList 정보 받아옴
      $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
        $scope.selectRowUniqList = data.map(({ mend_no }) => mend_no);
        $scope.$apply();
      }, 200));

      // // 묶음순번 확인변수
      // var preBundleNo = '';
      // var viewRowNum = 1;

      /* 데이터 테이블 */
      $scope.grid = {};
      $scope.grid.methods = {};
      $scope.grid.options = {
        pinningColumns: ['widget'],
        defaultSortingColumns: ['wdate'],
        notMovingColumns: [],
        notVisibleColumns: ['prod_name', 'acpt_time', 'review_time', 'as_com_time', 'chk_time', 'as_out_time'],
        bundleOptions: {
          bundleCountKey: 'selectBundleCnt',
          bundleDataKey: 'bundle_no',
          bundleUniqKey: 'mend_no'
        },
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/as/list`,
          requestWillAction: function(data) {
            if ($rootScope.memo_etc_search.page === 'as_list' && $rootScope.memo_etc_search.search) {
              const { date_type, sdate, edate, key: search_key, value: search_word } = $rootScope.memo_etc_search;

              $scope.searchForm = {
                ...$scope.searchForm,
                date_type,
                sdate,
                edate,
                search_key,
                search_word,
                search_type: 'exact'
              };
              $rootScope.memo_etc_search.search = false;
            }
            data = angular.merge({}, data, $scope.searchForm);

            //엑셀 모달용 데이터
            $scope.pageData = angular.copy(data);

            return data;
          },
          requestDidAction: function(result) {
            $scope.totalCount = result.recordsTotalCount;
            $scope.searchData.totalCount = result.recordsTotalCount;
            // 합포장 갯수
            $scope.bundleCnt = result.recordsTotal;

            // 합포장 분리시 필요
            bundle_group = _.groupBy(result.results, 'bundle_no');

            orderList = result.results;

            init(result.statusCount);

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 180,
            template: function(row) {
              const divide = row.as_status !== 'AS출고' && bundle_group[row.bundle_no].length > 1
                ? `<button class="btn btn-default btn-xxs" ng-click="grid.appScope.asDivideBundle('${row.bundle_no}','${row.mend_no}')">주문분할</button>`
                : '<button class="btn btn-default btn-xxs btn-default-no" disabled>주문분할</button>';

              // 메모가 있는경우 primary 컬러로 변경

              return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('${row.mend_no}')" >상세</button>
                     <button ng-disabled="grid.appScope.isMemoDisabled(${row.mend_no})" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="grid.appScope.leftTabAction(2)">메모</button>
                     ${divide}`;
            }
          },
          {
            key: 'as_status',
            title: 'A/S상태',
            width: 90,
            notCompile: true,
            template: function(row) {
              return $filter('statusColorAs')(row.as_status);
            }
          },
          {
            key: 'bundle_no',
            title: '묶음번호',
            name: 'bundle_no',
            width: 150
          },
          {
            key: 'shop_name',
            title: '쇼핑몰(계정)',
            width: 120,
            template: function(row) {
              let img = '직접입력';

              if (row.shop_cd && row.shop_cd !== 'A000') {
                const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

                img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right"><img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">${shop_info[1]}</span>`;
              }

              return img;
            }
          },
          {
            key: 'shop_ord_no',
            title: '쇼핑몰 주문번호',
            width: 120,
            filter: 'isNullHyphen'
          },
          {
            key: 'req_name',
            title: '요청자명',
            width: 70
          },
          {
            key: 'req_htel',
            title: '요청자 휴대폰번호',
            width: 120
          },
          {
            key: 'to_name',
            title: '수령자명',
            width: 70
          },
          {
            key: 'to_htel',
            title: '수령자 휴대폰번호',
            width: 120
          },
          {
            key: 'to_addr1',
            title: '수령자 주소',
            width: 200,
            notCompile: true,
            template: function(row) {
              //속성
              let return_value = row.to_addr1;

              if (return_value && return_value.charAt(0) != '[' && row.to_zipcd) {
                return_value = `[${row.to_zipcd}]${return_value}`;
              }
              if (row.to_addr2) {
                return_value += row.to_addr2;
              }

              return return_value;
            }
          },
          {
            key: 'ship_msg',
            title: '배송메세지',
            width: 150
          },
          {
            key: 'as_cost',
            title: 'A/S비용',
            width: 100,
            notCompile: true,
            template: function(row) {
              const as_cost = row.as_cost ? row.as_cost : '무료';

              return as_cost;
            }
          },
          {
            key: 'as_sale_name',
            title: 'A/S상품명',
            width: 150
          },
          {
            key: 'as_opt_name',
            title: 'A/S옵션명',
            width: 120
          },
          {
            key: 'sale_cnt',
            title: '수량',
            width: 70,
            template: (row) => {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : row.sale_cnt;
            }
          },
          {
            key: 'mend_content',
            title: 'A/S내용',
            width: 170
          },
          {
            key: 'mender_name',
            title: 'A/S담당자',
            width: 90
          },
          {
            key: 'ship_cost',
            title: '배송비',
            width: 70,
            template: (row) => {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.ship_cost, '', 0);
            }
          },
          {
            key: 'carr_name',
            title: '택배사',
            width: 90
          },
          {
            key: 'invoice_no',
            title: '운송장번호',
            width: 150,
            template: function(row) {
              return row.carr_no === 956 ? String(row.invoice_no).padStart(12, '0') : row.invoice_no;
            }
          },
          {
            key: 'wdate',
            title: '등록일',
            width: 120
          },
          {
            key: 'mdate',
            title: '최종 수정일',
            width: 120
          },
          {
            key: 'req_time',
            title: 'A/S요청일',
            width: 120
          },
          {
            key: 'acpt_time',
            title: 'A/S접수일',
            width: 120
          },
          {
            key: 'review_time',
            title: 'A/S심의일',
            width: 120
          },
          {
            key: 'as_com_time',
            title: 'A/S완료일',
            width: 120
          },
          {
            key: 'chk_time',
            title: 'A/S검수일',
            width: 120
          },
          {
            key: 'as_out_time',
            title: 'A/S출고일',
            width: 120
          },
        ]
      };

      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, callback) {
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = function() {
        $scope.searchForm = angular.copy(search.searchForm);
        $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchData.search_key_name = 'all';
        $scope.selectCount = 'total';
        // 일괄입력 값 초기화
        $scope.setAllData = {
          type: '',
          ship_method: '',
          ship_cost: 0,
          carr_no: '',
          carr_name: ''
        };

        $scope.searchDo(true, true);
      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };

      /**
       * 상태 변경
       */
      $scope.asSetState = async(type, mend) => {
        const mendList = mend ? [mend] : $scope.grid.methods.selectedData('mend_no');

        if (!mendList.length) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }
        const confrirm = await commonSVC.showConfirm('상태를 변경하시겠습니까?');

        if (!confrirm) {
          return false;
        }
        asModel.setStatus({ mendList, as_status: type })
          .then((re) => {
            if (re.data.results === 'success') {
              commonSVC.showToaster('success', '상태 변경', '정상적으로 변경되었습니다.');
              $scope.searchDo(false);
            } else {
              throw new Error();
            }
          })
          .catch(() => {
            commonSVC.showToaster('error', 'A/S', '상태 변경에 실패했습니다.');
          });
      };

      /**
       * 삭제
       */
      $scope.asDelete = async() => {
        const mendList = $scope.grid.methods.selectedData('all');

        if (!mendList.length) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const confrirm = await commonSVC.showConfirm('선택한 주문을 삭제하시겠습니까?');

        if (!confrirm) {
          return false;
        }

        asModel.delete({ mendList })
          .then((re) => {
            if (re.data.results === 'success') {
              commonSVC.showToaster('success', '주문 삭제', '정상적으로 삭제되었습니다.');
              $scope.searchDo(false);
            } else {
              throw new Error();
            }
          })
          .catch(() => {
            commonSVC.showToaster('error', 'A/S', '삭제에 실패했습니다.');
          });
      };

      /**
       * 상태 변경
       */
      $scope.asCopy = async () => {
        const mendList = $scope.grid.methods.selectedData('all');

        if (!mendList.length) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }
        const confrirm = await commonSVC.showConfirm('선택한 주문을 복사하시겠습니까?', '주문 복사 시, 요청 상태로 복사됩니다.\n주문 복사 시, 복사된 주문은 택배사와 운송장번호가 삭제됩니다.');

        if (!confrirm) {
          return false;
        }

        asModel.copy({ mendList: mendList.map(item => item.mend_no) })
          .then((re) => {
            if (re.data.results === 'success') {
              commonSVC.showToaster('success', '주문 복사', '정상적으로 복사되었습니다.');
              $scope.searchDo(false);
            } else {
              throw new Error();
            }
          })
          .catch(() => {
            commonSVC.showToaster('error', '주문 복사', '복사에 실패했습니다.');
          });
      };

      // SMS, EMAIL 모달
      $scope.sendModal = function(type) {
        let controller = '';

        switch (type) {
          case 'sms':
            controller = 'ConfigsSms';
            break;

          case 'email':
            controller = 'ConfigsEmail';
            break;
        }

        const rows = $scope.grid.methods.selectedData('all');
        const totalRows = $scope.grid.methods.getResultDatas();

        const resolve = {
          data: {
            rows: rows,
            totalRows: totalRows,
            totalCount: $scope.searchData.totalCount,
            searchForm: $scope.pageData,
            isAs: true
          }
        };

        commonSVC.openModal('lg', resolve, controller, `views/alimtalk/modals/${type}.html`);
      };

      /**
       * 등록페이지 보여주기
       */
      $scope.showAddModal = function(mend_no) {
        const resolve = {
          data: {
            mend_no: mend_no,
            warehouseList: warehouseList.data.result || [],
            systemList: systemList.data || []
          }
        };
        const modal = commonSVC.openModal('full', resolve, 'asAddCtrl', 'views/order/shipment/modals/as_add_modal.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      $scope.showDetail = (mend_no) => {
        const resolve = {
          data: {
            mend_no: mend_no,
            warehouseList: warehouseList.data.result || [],
            systemList: systemList.data || []
          }
        };
        const modal = commonSVC.openModal('full', resolve, 'asEditCtrl', 'views/order/shipment/modals/as_edit_modal.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 카운팅에 맞는 검색 결과
       */
      $scope.countSearch = function(type) {
        const sdate = $scope.searchForm.sdate, edate = $scope.searchForm.edate;

        // 카운트 검색시 검색값 초기화 되지않도록 해당 값 주석 2018-11-08 rony
        $scope.searchForm.sdate = sdate;
        $scope.searchForm.edate = edate;
        switch (type) {
          case 'total':
            $scope.searchForm.status = ['AS요청', 'AS접수', 'AS가능', 'AS불가', 'AS완료', 'AS검수', 'AS출고'];
            break;
          case 'AS요청':
            $scope.searchForm.status = ['AS요청'];
            break;
          case 'AS접수':
            $scope.searchForm.status = ['AS접수'];
            break;
          case 'AS가능':
            $scope.searchForm.status = ['AS가능'];
            break;
          case 'AS불가':
            $scope.searchForm.status = ['AS불가'];
            break;
          case 'AS완료':
            $scope.searchForm.status = ['AS완료'];
            break;
          case 'AS검수':
            $scope.searchForm.status = ['AS검수'];
            break;
          case 'AS출고':
            $scope.searchForm.status = ['AS출고'];
            break;
        }
        $scope.searchDo(true, true);
        $scope.selectCount = type;
        // }
      };

      // 운송장 출력
      $scope.printInvoice = () => {
        if (commonSVC.checkPermission('shipping.roles.printInvoice', userInfo.permission) === false) {
          return false;
        }

        if (solCarrList.data.results.length === 0) {
          commonSVC.showConfirm('택배사 연동정보가 없습니다.', '운송장 출력기능은 택배사 연동 후 이용할 수 있습니다. [확인] 버튼을 누르면 택배사 관리 페이지로 이동합니다.', function () {
            $state.go('main.settings_delivery_kr');
          });

          return;
        }

        const resolve = {};

        resolve.data = {
          solCarrList: solCarrList.data.results,
          listType: 'as'
        };

        const selected = $scope.grid.methods.selectedData('all');

        if (!selected.length) {
          commonSVC.showMessage('실패', '주문을 선택해주세요.');

          return false;
        }

        resolve.data.type = 'select';
        resolve.data.solCarrList = solCarrList.data.results;

        resolve.selectList = function () {
          return { data: { results: selected, recordsTotal: selected.length } };
        };

        const modal = commonSVC.openModal('lg', resolve, 'asPrintInvoice', 'views/order/shipment/modals/as_print_invoice.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 합포장
       * **/
      $scope.combine = () => {
        const selectList = $scope.grid.methods.selectedData('all');

        if (selectList.length < 2) {
          commonSVC.showMessage(gettextCatalog.getString('두개 이상의 주문을 선택해주세요.'));

          return false;
        }

        if (_.filter(selectList, (o) => { return o.as_status !== 'AS출고'; }).length !== selectList.length) {
          commonSVC.showMessage(gettextCatalog.getString('A/S출고 상태인 주문건들은 합포장이 불가능 합니다.'));

          return false;
        }

        const params = {
          mendList: _.map(selectList, 'mend_no'),
          selectList: selectList
        };

        const grouping = _.groupBy(selectList, (o) => { return o.to_name + o.to_zipcd + o.to_addr1 + o.to_htel; });

        commonSVC.showConfirm('주문 합포장을 하시겠습니까?')
          .then((confrirm) => {
            if (confrirm) {
              if (_.size(grouping) === 1) {
                // 합포장 시 수령지 정보 같은 경우 수령지 정보 선택은 미출력되어야함.
                params.standardMendNo = selectList[0].mend_no;

                asModel.combine(params)
                  .then((re) => {
                    if (re.data.results === 'success') {
                      commonSVC.showToaster('success', '주문 합포장', '주문 합포장 성공');
                      $scope.searchDo(false);
                    }
                  }).catch(() => {
                    commonSVC.showToaster('error', 'A/S주문', 'A/S주문 합포장이 실패되었습니다.');
                  });
              } else {
                const modal = commonSVC.openModal('', { data: params }, 'asModifyBundle', 'views/order/shipment/modals/as_modify_bundle.html');

                modal.result.then(function (re) {
                  if (re === 'success') {
                    $scope.searchDo(false);
                  }
                });
              }
            }
          });
      };

      /**
       * 주문분리
       * */
      $scope.asDivideBundle = function(bundle_no, mend_no) {
        const divideList = [];

        if (!bundle_no) { //주문 분할 버튼 눌렀을 때
          const selectList = $scope.grid.methods.selectedData('all');

          if (selectList.length < 1) {
            commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

            return false;
          }

          if (_.filter(selectList, (o) => { return o.as_status !== 'AS출고'; }).length !== selectList.length) {
            commonSVC.showMessage(gettextCatalog.getString('A/S출고 상태인 주문건들은 주문분할이 불가능 합니다.'));

            return false;
          }

          _.each(selectList, function (as) {
            if (bundle_group[as.bundle_no].length > 1) {
              divideList.push({ bundle_no: as.bundle_no, mend_no: as.mend_no });
            }
          });
        } else { //(개별)분할 아이콘 눌를때
          if (bundle_group[bundle_no].length === 2) { // 합이 2개면 둘다 분할 처리해야하니까
            _.each(bundle_group[bundle_no], function(v) {
              divideList.push({ bundle_no: bundle_no, mend_no: v.mend_no });
            });
          } else {
            divideList.push({ bundle_no: bundle_no, mend_no: mend_no });
          }
        }

        if (!divideList.length) {
          commonSVC.showMessage('주문 분할', '묶음 해제 할 묶음 주문건이 없습니다');

          return false;
        }

        commonSVC.showConfirmHtml('주문 분할을 하시겠습니까?', `선택한 ${divideList.length}건의 주문을 현재 묶음 배송 주문에서 개별 배송 주문으로 분리합니다.<br> - 주문 분할 시 묶음번호가 변경되니 주의바랍니다.`, function () {
          const send_data = {
            divideList: divideList
          };

          asModel.divideBundle(send_data)
            .then((re) => {
              if (re.data.results === 'success') {
                commonSVC.showToaster('success', '', '합포장 분할해제에 성공하였습니다.');
                $timeout(function() {
                  $scope.searchDo(false);
                }, 500);
              } else {
                throw new Error();
              }
            })
            .catch(() => {
              commonSVC.showToaster('error', '', '합포장 분할해제에 실패 했습니다.');
            });
        });
      };

      /**
       * 엑셀 다운 모달창
       * */
      $scope.excelDown = function () {
        // 엑셀권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }

        const mendList = $scope.grid.methods.selectedData('mend_no');
        const type = mendList.length ? 'select' : 'all';
        const resolve = {};

        resolve.data = {
          type,
          excelFieldList: asSVC.excelFieldList,
          title: 'A/S 관리',
          url: '/app/as/excel-down',
          searchForm: $scope.pageData,
          page: 'as',
          progress: true,
          select_list: mendList

        };

        if (type === 'all') {
          resolve.data.count = $scope.totalCount;
          resolve.data.isAll = true;

          if (resolve.data.count === 0) {
            commonSVC.showMessage(gettextCatalog.getString('다운로드 가능한 주문이 없습니다.'));

            return false;
          }
        } else {
          resolve.data.count = mendList.length;
          resolve.data.isAll = false;
        }

        const modal = commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            // 엑셀 다운로드 후 새로고침 안함 (https://playautogmpproject.atlassian.net/browse/GMPKR-5462)
            // 2018-10-24 rony
            // $scope.searchDo(false);
          }
        });
      };

      /**
       * 엑셀 업로드 모달창
       * */
      $scope.uploadInvoiceExcel = function () {

        // 엑셀권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('shipping.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }

        const modal = commonSVC.openModal('lg', { data: { type: 'as' } }, 'invoiceExcelUploadCtrl', 'views/order/shipment/modals/invoice_excel_upload.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
          }
        });
      };

      /**
       * 알림톡 발송
       */
      // $scope.altalkSend = async () => {
      //   const plusIdList = await atalkModel.accountList({ name: $rootScope.user_profile.sol_no });
      //   const resolve = {
      //     data: {
      //       open: 'ord',
      //       type: 'direct',
      //       plusIdList: plusIdList.data?.result,
      //       ord: $scope.grid.methods.selectedData() || []
      //     }
      //   };
      //   commonSVC.openModal('x3g', resolve, 'AltalkSendCtrl', 'views/alimtalk/modals/altalk_send.html');
      // };

      /**
       * 알림톡 그룹 회원 추가
       */
      // $scope.altalkGroupMemAdd = () => {
      //   if (!$scope.grid.methods.selectedData().length) {
      //     return commonSVC.showMessage('추가할 회원이 없습니다.', '주문리스트에서 주문을 먼저 선택해 주세요.');
      //   }
      //   commonSVC.openModal('lg', { data: { type: 'add_mem', ord: $scope.grid.methods.selectedData() || [] } }, 'AltalkSendGroupCtrl', 'views/alimtalk/modals/altalk_send_group.html');
      // };

      // 메모 첫 변경시 메뉴 버튼 색상 변경
      $scope.setMemoBtnActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj.mend_no == value;
        });

        orderList[index].memo_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      // 메모 전부완료시 메뉴 버튼 색상 변경
      $scope.setMemoCompleteActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj.mend_no == value;
        });

        orderList[index].memo_complete_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      /**
       * 리스트와 별개로 초기에 세팅되어야하는 값
       * @param {*} data
       */
      function init(data) {
        $scope.selectRowUniqList = [];
        $scope.countList = {
          total: data.total || 0,
          req: data.req || 0,
          acpt: data.acpt || 0,
          possible: data.possible || 0,
          impossible: data.impossible || 0,
          complete: data.complete || 0,
          check: data.check || 0,
          out: data.out || 0,
        };
      }

      $scope.$on('memoEtcLink_as_list', function() {
        const { date_type, sdate, edate, key: search_key, value: search_word } = $rootScope.memo_etc_search;

        $scope.searchForm = {
          ...search.searchForm,
          date_type,
          sdate,
          edate,
          search_key,
          search_word,
          search_type: 'exact'
        };
        $rootScope.memo_etc_search.search = false;
        $scope.searchDo(true, true);
      });

    });
