/**
 * Created by Ally on 2016-11-29.
 */
'use strict';

angular.module('gmpApp').controller('OrderShipmentManualSetCtrl', function ($scope, $uibModalInstance,
  shipmentSVC, commonSVC, columnSVC, shipmentModel, warehouseModel, columnModel,
  data, gettextCatalog, $rootScope,
  supplierModel, productModel, systemModel, userInfo, $timeout) {
  // 재고관리 여부
  $scope.isManageStock = $rootScope.user_profile.sol_stock > 0;
  // 기본정보
  $scope.shipment = angular.copy(shipmentSVC.shipment);

  // 주문일 결제일
  $scope.shipment.ord_time = commonSVC.getDate(new Date(), 'yyyy-MM-dd HH:mm:ss');
  $scope.shipment.pay_time = commonSVC.getDate(new Date(), 'yyyy-MM-dd HH:mm:ss');

  // 국가
  $scope.countryList = angular.copy($rootScope.countryList);
  // 배송처
  $scope.warehouseList = data.warehouseList;
  // 카테고리
  $scope.categoryList = data.categoryList;
  // 결제통화
  $scope.currencyList = angular.copy(shipmentSVC.currencyList);
  // 배송방법
  $scope.deliMethodList = angular.copy(shipmentSVC.deliMethodList);
  // 대리점 계정일 경우 내일배송 관련 값 추가
  if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
    $scope.deliMethodList.splice(-1, 0, ...['빠른 가전 배송', '내일 배송']);
  }
  // 직접입력 쇼핑몰
  $scope.customShopList = userInfo.user.manualOrderShops.filter(({ shop_cd }) => shop_cd.startsWith('U'));

  $scope.autoOrdNo = true;
  // 사은품 리스트
  $scope.giftList = [];
  $scope.selectGiftType = '';

  // 주문자 정보와 동일 체크박스
  $scope.autoWriteCheck = false;
  // directive(form) 용
  $scope.reCheckClass = {};

  // 저장 버튼 flag
  let isClickSaveBtn = false;

  // 매칭 한 상품들의 배송처
  $scope.matchDepotNo = { prod: null, opt: null };

  //등록 기본탭
  $scope.tabActive = 0;

  // 모달 닫음
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

  // 옵션 초기화 정보
  const initOpt = {
    // 기본옵션
    prod: {
      prod_img: null,
      prod_name: null,
      prod_name_pack: null,
      prod_no: null,
      prod_pack: '',
      set_name: null,
      set_no: null,
      set_cd: null,
      set_pack_unit: null,
      sku_cd: null,
      sku_pack: null,
      pack_unit: '',
      total_cnt: ''
    },

    // 추가구매옵션
    opt: {
      pack_unit: '',
      set_no: null,
      set_cd: '',
      set_pack_uniq: null,
      sku_cd: '',
      sku_list: [
        {
          prod_no: null,
          depot_no: null,
          pal_no: null,
          pack_unit: null,
          bundle_avail_yn: true
        }
      ],
      total_cnt: ''
    }
  };

  //추가 컬럼 확목 조회
  columnModel.addColumnList({ addcol_type: 'all' })
    .then((re) => {
      if (re.status === 200) {
        re.data.results.forEach((row) => {
          if (row.enum_data) {
            row.enumList = columnSVC.enumDataSet(row.enum_data);
            const findColVal = _.find(row.enumList, (o) => {
              return o[0] === row.col_val;
            });

            if (findColVal) {
              row.col_val = findColVal[1];
            }
          }

        });
        $scope.shipment.addColData = _.groupBy(re.data.results, 'addcol_type');
      }
    });

  warehouseModel.getDefaultWareHouse('')
    .then(function(re) {
      if (re.data.results[0].depot_no === null) {
        commonSVC.showMessage('필수값인 배송지 리스트가 존재하지 않습니다.', '재고 > 배송처관리 에서 배송처를 등록하신 후 이용해 주시기 바랍니다.');
      } else {
        $scope.shipment.depot_no = angular.copy(re.data.results[0].depot_no).toString();
        $scope.shipment.depot_name = angular.copy(re.data.results[0].depot_name);
      }
    })
    .catch(function() {
      commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('기본 배송지 가져오기 오류'));
    });

  // 주문자 정보와 동일함
  $scope.autoWrite = function () {
    if ($scope.autoWriteCheck) {
      $scope.shipment.to_name = $scope.shipment.order_name;
      $scope.shipment.to_tel = $scope.shipment.order_tel;
      $scope.shipment.to_htel = $scope.shipment.order_htel;
    } else {
      $scope.shipment.to_name = '';
      $scope.shipment.to_tel = '';
      $scope.shipment.to_htel = '';
    }
  };

  //배송방법 변경시
  $scope.changeDeliMethod = function() {
    if ($scope.shipment.ship_method === '무료배송') {
      $scope.shipment.ship_cost = 0;
    }
  };

  //SKU상품 매칭
  $scope.mappingProd = function(loca, idx) {
    const resolve = {};

    resolve.data = {
      from: '선택한 주문',
      pack_unit: true,
      warehouseList: $scope.warehouseList,
      isMulti: !$scope.user_profile.pa_sol_no,
      isMultiProd: true,
    };
    const redata = commonSVC.openModal('xxg', resolve, 'prodProductSearch', 'views/online/product/modals/prod_search.html');

    redata.result.then(function (re) {
      if (re.length > 1 && re.some(re => !re.bundle_avail_yn)) {
        commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

        return false;
      }

      if (loca === 'ord') {
        stockMatch(re, idx);
      } else {
        addStockMatch(re, idx);
      }
    });
  };

  //속성 추가, 삭제
  $scope.calculateOPT = function (calMode, idx) {
    if (calMode === 'plus') {
      const maxCount = 10;

      if ($scope.shipment.prod_add_opt_list.length < maxCount) {
        const new_prod = angular.copy(shipmentSVC.shipment.prod_add_opt_list[0]);

        $scope.shipment.prod_add_opt_list.push(new_prod);
      }
    } else {
      $scope.shipment.prod_add_opt_list.splice(idx, 1);
    }
  };

  // 등록
  $scope.onSubmit = async function () {
    isClickSaveBtn = true;
    $scope.reCheckClass.valid('recheck');

    if ($scope.orderForm.$valid) {
      const addr = $scope.shipment.to_addr1.trim().replace(/\s{2,}/g, ' '),
            isKorAddr = /[가-힣]/.test(addr[0]),
            regex = isKorAddr ? /^[가-힣]{2,8}(?:\s+[^ ]*){2,}$/ : /^[a-zA-Z-]{4,17}(?:\s+[^ ]*){2,}$/;
      // 주소 띄어쓰기 유효성 검사
      if (!regex.test(addr)) {
        commonSVC.showMessage(gettextCatalog.getString('주소 입력 시 행정구역 단위로 띄어쓰기를 해야합니다.'));
        $timeout(function () {
          $scope.waitBtnDisableFlag = false;
        }, 1000);

        return false;
      }

      if ($scope.autoOrdNo) {
        $scope.shipment.prod_list[0].shop_ord_no = `${Math.floor((Math.random() * 9999999999999999) + 1000000000000000)}`; // 자동생성시 16자리 쇼핑몰 주문번호 생성
      }

      // 직접입력 쇼핑몰이 있는 경우
      if ($scope.customShopList.length) {
        $scope.shipment.shop_cd = $scope.shipment.shop.split('|')[0];
        $scope.shipment.shop_id = $scope.shipment.shop.split('|')[1];
      }

      $scope.shipment.shop_add_opt_name = _.uniqBy(_.map($scope.shipment.prod_add_opt_list, 'ord_opt_name')).join(',');

      try {
        const result = await shipmentModel.setManualOrder($scope.shipment);

        if (result?.data.results === 'success') {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('주문 등록에 성공하였습니다.'));
          // 2018-01-11 chris 리스트 새로고침을 위한 이벤트 추가
          $rootScope.$broadcast('reloadShipmentOrderList');
          $uibModalInstance.close('success');
        } else {
          $timeout(function () {
            $scope.waitBtnDisableFlag = false;
          }, 500);

          commonSVC.showToaster('error', gettextCatalog.getString('실패'), '주문 등록에 실패하였습니다.');
        }
      } catch (err) {
        $timeout(function () {
          $scope.waitBtnDisableFlag = false;
        }, 500);

        commonSVC.showToaster('error', gettextCatalog.getString('실패'), err.data.messages[0] || '주문 등록에 실패하였습니다.');
      }
    } else {
      commonSVC.showMessage('필수값을 모두 입력하여 주십시오.');
      $timeout(function () {
        $scope.waitBtnDisableFlag = false;
      }, 1000);
    }
  };

  /**
     * SKU상품 상세페이지 열기
     */
  $scope.showProdDetail = function(sku_cd, prod_number) {

    // SKU상품수정 권한 확인. 2019-01-03 rony
    if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
      return false;
    }

    if (!sku_cd) {
      commonSVC.showMessage('매칭된 SKU상품이 없습니다.');

      return;
    }

    sku_cd = sku_cd.replace(/=\d+/, '');

    const resolve = {
      data: {
        sku: sku_cd,
        number: prod_number,
        detailType: 'edit'
      }
    };

    resolve.supplierList = function() {
      return supplierModel.ListAll({ use_yn: true });
    };
    resolve.warehouseList = function() {
      return warehouseModel.ListAll({ use_yn: true });
    };
    resolve.madeinList = function() {
      return productModel.getMadein();
    };
    resolve.systemList = function() {
      return systemModel.load();
    };
    const modal = commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

    modal.result.then(function () {
    });
  };

  /**
     * 세트 상세보기
     */
  $scope.setShowDetail = function(set_no) {
    const resolve = {
      data: { from: '수정', set_no: set_no }
    };
    const modal = commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');

    modal.result.then(function() {
    });
  };

  /**
     * 2018-07-03 Daniel
     * 수정 버튼 누를시 삭제되도록 변경
     * 매칭 삭제
     */
  $scope.deleteMatching = function(ord_opt_seq, uniq, idx) {
    let opt,
        optType;

    if (idx === undefined) {
      // 기본옵션
      opt = $scope.shipment;
      opt.sku_list = [
        { prod_no: null }
      ];
      optType = 'prod';

      $scope.matchDepotNo.prod = null;
    } else {
      $scope.shipment.prod_add_opt_list[idx].set_info = { set_no: null, set_cd: null, set_pack_unit: null };
      $scope.shipment.prod_add_opt_list[idx].sku_list = [];

      // 추가구매옵션
      opt = $scope.shipment.prod_add_opt_list[idx];
      optType = 'opt';
    }

    opt = _.merge(opt, initOpt[optType]);

    if (!_($scope.shipment.prod_add_opt_list).map('sku_list').flatten().filter('depot_no').value().length) {
      $scope.matchDepotNo.opt = null;
    }
  };

  //SKU코드 매칭
  function stockMatch(re, idx) {
    data = re.isSet ? re.data.prod : re;
    const depot_nos = _.uniqBy(_.map(data, 'depot_no'));

    // 한주문에 합포장 불가인 상품 포함 금지
    // 기본옵션은 기본옵션 하나로 합포장불가인 주문으로 존재가능하기 때문에 혼자있을때 변경가능
    // G마켓물류주문은 패스
    if (!$scope.shipment.ebaydepot_yn) {
      const opts = $scope.shipment.prod_add_opt_list.filter(o => {
        // 세트상품에는 bundle_avail_yn 없으므로 세트상품인지 체크 (세트상품엔 합포장불가상품 추가안됨 & 합포장불가로 변경되면 세트상품 사용불가됨) 2018-11-13 Amelia
        if (!re.isSet && re.find(r => !r.bundle_avail_yn)) {
          // 매칭하려는 재고가 합포장 불가인 경우 추가구매옵션에서 매칭된 재고가 있는 경우 찾음
          return o.bundle_avail_yn !== null && o.sku_cd;
        } else {
          // 매칭하려는 재고가 합포장 가능인 경우 추가구매옵션에서 매칭된 합포장 불가 재고 찾음
          return o.bundle_avail_yn === 0 && o.sku_cd;
        }
      });

      if (($scope.matchDepotNo.prod || $scope.matchDepotNo.opt) && opts.length) {
        commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

        return false;
      }
    }

    if (depot_nos.length === 1 && ($scope.matchDepotNo.opt === null || $scope.matchDepotNo.opt == depot_nos[0])) {
      //선택한 상품들의 배송처가 같으면서 기존에 선택한 창고랑 같을때
      $scope.shipment.prod_list[idx].sku_cd = re.isSet ? `${re.data.set.set_cd}(${re.data.set.pack_unit}개)` : `${data[0].sku_cd} (${data[0].pack_unit}개)${data.length > 1 ? ` 외 ${data.length - 1}건` : ''}`;
      $scope.shipment.prod_list[idx].shop_sale_name = re.isSet ? re.data.set.set_name : data[0].prod_name + (data.length > 1 ? ` 외 ${data.length - 1}건` : '');

      $scope.shipment.prod_list[idx].sale_price = re.isSet ? re.data.set.sale_price : _.sum(_.map(data, 'sale_price'));

      $scope.shipment.prod_list[idx].sku_list = data;
      $scope.shipment.prod_list[idx].set_info = re.isSet ? { set_no: re.data.set.set_no, set_cd: re.data.set.set_cd, set_pack_unit: re.data.set.pack_unit } : { set_no: null, set_cd: null, set_pack_unit: null };
      $scope.shipment.bundle_avail_yn = data.find(r => !r.bundle_avail_yn) ? 0 : 1;

      // $scope.shipment.prod_list[idx].sale_cnt = data[0].pack_unit;  // 수량 추가 2018-04-24 rony
      $scope.shipment.prod_list[idx].sale_cnt = $scope.shipment.prod_list[idx].sale_cnt > 0 ? $scope.shipment.prod_list[idx].sale_cnt : 1;
      let total_pack_unit = 0;

      if (re.isSet) {
        _.each(re.data.prod, function(order) {
          order.pack_unit *= re.data.set.set_pack_unit;
          total_pack_unit += order.pack_unit;
        });
      }
      const pack_unit = re.isSet ? total_pack_unit : data.reduce((prev, curr) => prev + +curr.pack_unit, 0);

      $scope.shipment.prod_list[idx].pack_unit = pack_unit;
      $scope.shipment.depot_name = data[0].depot_name;
      $scope.shipment.depot_no = data[0].depot_no;
      $scope.matchDepotNo.prod = data[0].depot_no;
    } else {
      commonSVC.showMessage('옵션, 추가구매옵션은 배송처가 동일한 SKU상품만 매칭이 가능합니다.', '매칭할 SKU상품의 배송처를 확인하시기 바랍니다.');
    }
  }

  //추가구매 옵션 SKU코드 매칭
  function addStockMatch(re, idx) {
    data = re.isSet ? re.data.prod : re;
    const depot_nos = _.uniqBy(_.map(data, 'depot_no'));

    // 한주문에 합포장 불가인 상품 포함 금지
    // G마켓물류 주문은 매칭이 필수라 추가구매옵션혼자 합포장불가매칭으로 존재할수없어서 추가구매옵션에는 절대못들어가게 막음
    if (!$scope.shipment.ebaydepot_yn) {
      const opts = [{ bundle_avail_yn: $scope.shipment.bundle_avail_yn, sku_cd: $scope.shipment.prod_list[0]?.sku_cd }]
        .concat($scope.shipment.prod_add_opt_list)
        .filter(function (o, k) {
          // 세트상품에는 bundle_avail_yn 없으므로 세트상품인지 체크 (세트상품엔 합포장불가상품 추가안됨 & 합포장불가로 변경되면 세트상품 사용불가됨) 2018-11-13 Amelia
          if (!re.isSet && re.find(r => !r.bundle_avail_yn)) {
            // 매칭하려는 재고가 합포장 불가인 경우 옵션 중에서 매칭된 재고가 있는 경우 찾음
            return o.bundle_avail_yn !== null && o.sku_cd && idx !== (k - 1);
          } else {
            // 매칭하려는 재고가 합포장 가능인 경우 옵션 중에서 매칭된 합포장 불가 재고 찾음
            return o.bundle_avail_yn === 0 && o.sku_cd && idx !== (k - 1);
          }
        });

      if (($scope.matchDepotNo.prod || $scope.matchDepotNo.opt) && opts.length) {
        commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

        return false;
      }
    }

    // 재고가 매칭된 옵션이 하나이고 그 옵션의 배송처를 바꾸는 경우 처리
    const depot_no = $scope.matchDepotNo.prod || $scope.matchDepotNo.opt;
    let depot_cnt = $scope.matchDepotNo.prod === depot_no ? 1 : 0;

    if (depot_no) {
      depot_cnt += $scope.shipment.prod_add_opt_list.filter((prod, i) => prod.sku_list && prod.sku_list[0]?.depot_no === depot_no && i !== idx).length;
    }

    if (depot_nos.length > 1 || (depot_no && ($scope.matchDepotNo.prod != depot_nos[0] && $scope.matchDepotNo.opt != depot_nos[0] && depot_cnt > 0))) {
      commonSVC.showMessage('매칭실패', '묶음주문(합배송) 상품은 배송처가 동일한 SKU상품만 매칭이 가능합니다.\n매칭할 SKU상품의 배송처를 확인해주세요.');

      return false;
    }

    if (depot_nos.length === 1 && (($scope.matchDepotNo.prod === null && $scope.matchDepotNo.opt === null) || ($scope.matchDepotNo.prod == depot_nos[0] || $scope.matchDepotNo.opt == depot_nos[0] || depot_cnt === 0))) {
      $scope.shipment.prod_add_opt_list[idx].sku_cd = re.isSet ? `${re.data.set.set_cd}(${re.data.set.pack_unit}개)` : `${data[0].sku_cd}${data.length > 1 ? ` 외 ${data.length - 1}건` : ''}`;
      $scope.shipment.prod_add_opt_list[idx].set_cd = re.isSet ? re.data.set.set_cd : '';
      $scope.shipment.prod_add_opt_list[idx].ord_opt_name = re.isSet ? re.data.set.set_name : data[0].prod_name + (data.length > 1 ? ` 외 ${data.length - 1}건` : '');
      $scope.shipment.prod_add_opt_list[idx].sku_list = data;
      $scope.shipment.prod_add_opt_list[idx].set_info = re.isSet ? { set_no: re.data.set.set_no, set_cd: re.data.set.set_cd, set_pack_unit: re.data.set.set_pack_unit } : { set_no: null, set_cd: null, set_pack_unit: null };
      $scope.shipment.depot_name = data[0].depot_name;
      $scope.shipment.prod_add_opt_list[idx].set_pack_unit = re.isSet ? re.data.set.set_pack_unit : null;
      $scope.shipment.prod_add_opt_list[idx].bundle_avail_yn = data.find(r => !r.bundle_avail_yn) ? 0 : 1;
      let total_pack_unit = 0;

      if (re.isSet) {
        _.each(re.data.prod, function(order) {
          order.pack_unit *= re.data.set.set_pack_unit;
          total_pack_unit += order.pack_unit;
        });
      }

      const pack_unit = re.isSet ? total_pack_unit : data.reduce((prev, curr) => prev + +curr.pack_unit, 0);

      $scope.shipment.prod_add_opt_list[idx].pack_unit = pack_unit;
      $scope.shipment.depot_no = data[0].depot_no?.toString();
      $scope.matchDepotNo.opt = data[0].depot_no;
    } else {
      commonSVC.showMessage('옵션, 추가구매옵션은 배송처가 동일한 SKU상품만 매칭이 가능합니다.', '매칭할 SKU상품의 배송처를 확인하시기 바랍니다.');
    }
  }

  // 다중 매칭된 SKU 조회 모달
  $scope.showMultiSKUDetail = function (opt, index = null) {
    const prodList = opt === 'opt' ? $scope.sku_list : $scope.shipment.prod_add_opt_list[index].sku_list;

    const resolve = {
      data: {
        prodList: prodList.map(prod => {
          // 주문 수량 변수명이 달라 변경 처리
          if (opt === 'opt') {
            prod.opt_sale_cnt = $scope.shipment.sale_cnt;
          } else {
            // 주문 수량이 첫 항목에만 들어있어 모든 항목에 추가
            prod.opt_sale_cnt = $scope.shipment.prod_add_opt_list[index].opt_sale_cnt;
            // 재고 매칭 시 상품명을 opt_prod_name 변수에 저장하고 있어 변경 처리
            if (prod.opt_prod_name)
            { prod.prod_name = prod.opt_prod_name; }
          }

          return prod;
        })
      }

    };

    commonSVC.openModal('lm', resolve, 'multiSkuOrderProdList', 'views/prod/product/modals/multi_sku_order_prod_list.html');
  };

  //미매칭 출고처리
  // function noneMapping(idx) {
  //   // $scope.shipment.prod_list[idx].sku_cd = '미매칭 출고';
  //   $scope.shipment.prod_list[idx].sale_price = '-';
  //   $scope.shipment.prod_list[idx].sku_list = [];
  //   $scope.shipment.depot_name = '';
  //   $scope.matchDepotNo.prod = null;

  //   _.forEach($scope.shipment.prod_add_opt_list, function(opt) {
  //     // opt.sku_cd = '미매칭 출고';
  //     opt.sku_list = [];
  //   });

  //   $scope.shipment.use_map_yn = 0;
  // }
});
