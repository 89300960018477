'use strict';

angular.module('gmpApp').controller('OrderSendEmailCtrl', function (settings, userInfo, $timeout, $rootScope, $scope, $state, data, $q, $uibModalInstance, shipmentModel, commonModel, commonSVC, securityModel) {

  const defaultSearch = {
    searchData: {
      all_checked: false,  // 전체선택 여부
      totalCount: 0,       // 검색전체 건수
      selectCount: 0,      // 선택한 건수 <- 작업예정
      showCount: 10,
      haveBoxContent: false,
      showDetailSearchArea: true,
      search_key_items: [                                                         // 검색영역 키값
        { label: '전체', value: 'all' },
        { label: '보낸 사람', value: 'from_m' },
        { label: '메일 제목', value: 'title' }
      ]
    },
    searchForm: {
      search_key: 'all',
      search_word: '',
      search_type: 'partial',
      date_type: 'wdate',
      length: 10,
      sdate: '2001-01-01',
      edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
      send_type: 'email',
    },
  };

  $scope.warehouseList = data.warehouseList;
  $scope.tabActive = 0;
  $scope.orderSearchForm = data.searchForm; // 엑셀 다운로드 주문조회 용
  $scope.templateData = {
    senderEmail: '',
    title: '',
    content: ''
  };
  $scope.sendData = [];

  $scope.defaultMailValue = {
    title: '(제목없음)',
    content: `안녕하세요 ${userInfo.user.company_name} 입니다.
    금일 발주 요청서를 송부드립니다.
    회신 시, 운송장번호와 택배사 기입을 부탁드립니다.
    감사합니다.`
  };

  $scope.clickTab = function (tabIndex) {
    $scope.tabActive = tabIndex;
  };

  async function init() {
    const emailCnt = (await commonModel.getServiceCnt()).data.emailCnt;
    $scope.emailCnt = {
      avail: emailCnt.avail || 0,
      benefit: emailCnt.benefit || 0
    };

    const sendMailList = await securityModel.checkAuth();
    $scope.send_mails = sendMailList.data?.results.filter(v => v.verify).map(info => info.email);

    const mailTemplate = await shipmentModel.loadOrderMailTemplate();
    $scope.templateData = mailTemplate.data?.results[0] || $scope.templateData;

    $timeout(() => {});

    $scope.excelTemplate = data.excelTemplateList;
    $scope.linkage_depot_list = data.usedDepotNoList;
    $scope.orderList = data.orderList.length ? data.orderList : (await shipmentModel.list($scope.orderSearchForm)).data.results;

    if (data?.excludeBundleNos?.length) {
      $scope.orderList = $scope.orderList.filter(ord => !data.excludeBundleNos?.some(o => o.bundle_no === ord.bundle_no));
    }

    const orderDepotList = [];
    $scope.orderList.forEach((ord) => {
      const depot = $scope.warehouseList.find((depot) => depot.code === ord.depot_no);
      if (depot) {
        orderDepotList.push(depot);
      }
    });

    $scope.orderDepotList = [...new Set(orderDepotList.map(JSON.stringify))].map(JSON.parse);
  }

  init();

  // 최근 보낸 메일 양식 저장
  const saveTemplate = async function () {
    try {
      const result = await shipmentModel.saveOrderMailTemplate($scope.templateData);

      if (result.data.results[0] === 'success') {
        commonSVC.showToaster('success', '', '메일 템플릿 설정에 성공하였습니다.');
      } else {
        commonSVC.showToaster('error', '', '메일 템플릿 설정에 실패하였습니다.');
      }
    } catch (e) {
      commonSVC.showToaster('error', '', '메일 템플릿 설정에 실패하였습니다.');
    }
  };

  // 검색영역 폼
  $scope.searchData = angular.copy(defaultSearch.searchData);
  $scope.searchForm = angular.copy(defaultSearch.searchForm);

  // 데이터 테이블 관련기능
  $scope.searchFn = {
    searchDo: function () {
      $scope.searchDo(true, true);
    },
    resetDo: function() {
      $scope.resetDo();
    }
  };

  /**
     * 부가서비스 사용내역 DataTable 설정
     */
  $scope.grid = {};
  $scope.grid.methods = {};
  $scope.grid.options = {
    gridHeight: 560,
    initPagingSize: 10,
    pinningColumns: [],
    alignCenterColumns: ['wdate', 'from_m', 'from_tel', 'count', 'send_type', 'contents', 'read_time', 'file_result_url', 'title', 'to_m'],
    alignRightColumns: [],
    defaultSortingColumns: ['wdate'],
    notSortingColumns: ['read_time', 'file_result_url', 'recv_users', 'recv_check'],
    notResizingColumns: [],
    notMovingColumns: [],
    notVisibleColumns: [],
    externalRequestOptions: {
      requestUrl: `${settings.pa20ApiUrl}/app/order/manage/mail-logs`,
      requestWillAction: function (data) {
        Object.assign(data, $scope.searchForm);

        return data;
      },
      requestDidAction: function(result) {
        $scope.searchData.totalCount = result.recordsTotal;
        $scope.result = result.results;

        return result.results;
      }
    },
    columns: [
      {
        key: 'from_m',
        title: '보낸 사람',
        width: 160
      },
      {
        key: 'title',
        title: '메일 제목',
        width: 200,
      },
      {
        key: 'wdate',
        title: '보낸 일시',
        width: 140
      },
      {
        key: 'read_time',
        title: '수신 확인',
        width: 140,
        tooltip: {
          text: '- 수신확인 일시\n수신자가 메일 내 [발주서 확인] 클릭 후 비밀번호 입력하여 정상적으로\n솔루션 접속이 확인 되었을 경우 업데이트 됩니다.\n단순 메일 내용만 확인했을 경우 미확인으로 노출됩니다.',
          tooltipStyle: 'left:-30px;top:1px;'
        },
        template: function(row) {
          return row.read_time || '미확인';
        }
      },
      {
        key: 'file_result_url',
        title: '결과 확인',
        width: 185,
        template: function(row) {
          return row.read_time ? `<a class="btn btn-primary btn-xxs" style="border-radius: 5px; width: 130px" href="${row.file_result_url || '#'}" ng-disabled="${!row.file_result_url}">결과 엑셀 다운로드</a>` : '';
        }
      },
      {
        key: 'to_m',
        title: '받는사람',
        width: 160,
        mouseOverTooltip: 'depot_name'
      }
    ]
  };

  /**
   * 검색 초기화
   */
  $scope.resetDo = function() {
    $scope.searchForm = angular.copy(defaultSearch.searchForm);
    $scope.searchData = angular.copy(defaultSearch.searchData);

    $scope.searchDo(true, true);
  };

  /**
   * 검색
   */
  $scope.searchDo = function (refresh = true, noDelay) {
    $scope.viewPayStatus = ['환불신청', '처리중', '환불완료', '환불거부'];
    $scope.grid.methods.reloadData(null, refresh, noDelay);
  };

  const errMessage = '발주서 발송에 실패하였습니다. 주문을 다시 한번 확인해주세요.\n해당 배송처에 발주 엑셀 양식이 등록되어 있지 않다면 발주서 발송을 할 수 없습니다.\n* 발주 엑셀 등록 경로 : 통합엑셀다운 > 배송처 분류 선택\n* CSV 형식 발송 불가';

  /**
   * 발주서 전송
   * 1. 해당 주문들 출고 지시 처리
   * 2. 출고 지시 처리한 상태로 배송처 양식에 맞게 엑셀 다운로드
   * 3. 해당 엑셀로 이메일 전송
   */
  $scope.sendOrder = async function () {
    let tempOrderList = []; // 발송 실패된 주문 담을 변수

    if (commonSVC.checkPermission('order.roles.excelOrder', userInfo.permission) === false) {
      $timeout(() => { $scope.waitBtnDisableFlag = false; });

      return false;
    }

    if (!$scope.templateData.senderEmail) {
      commonSVC.showMessage('보내는 메일 주소를 선택해주세요.');
      $timeout(() => { $scope.waitBtnDisableFlag = false; });

      return false;
    }

    if (!$scope.orderList?.length) {
      commonSVC.showMessage('발주서 발송', ' 발송 가능한 주문이 없습니다.');
      $timeout(() => { $scope.waitBtnDisableFlag = false; });

      return false;
    }

    // 양식 저장
    saveTemplate();

    // 전송 가능한 주문(신규주문 && 발주양식이 등록된 배송처 주문), 불가능 주문 나눠서 유효성 검사
    const canSendOrders = {};
    let cantSendOrders = [];
    const checkDuplicateDepotNo = []; // 출고 불가로인한 주문건과 배송처 양식 없는 주문 건 중복으로 카운트 처리 안되도록 배송처 변수 선언
    const noneExcelDepots = []; // 발주서 엑셀 양식 미생성 배송처
    let confirm = false,
        canSendOrderCnt = 0,
        cantSendOrderCnt = 0,
        noneExcelDepotCnt = 0;

    for (const [depot_no, orders] of Object.entries(_.groupBy($scope.orderList, 'depot_no'))) {

      const matchDepot = $scope.warehouseList.find(depot => depot.code === +depot_no);

      if (!$scope.linkage_depot_list.includes(+depot_no)) {
        noneExcelDepotCnt += orders.length;
        noneExcelDepots.push(matchDepot.warehouse_name);
        checkDuplicateDepotNo.push(matchDepot.depot_no);
        tempOrderList = tempOrderList.concat(orders);
        cantSendOrders = cantSendOrders.concat(orders.filter(ord => ord.ord_status !== '신규주문' || !ord.ship_avail_yn).map(ord => ord.bundle_no));
      } else if (matchDepot?.charge_email && matchDepot.use_yn && $scope.excelTemplate.data.linkageDepotList?.includes(+depot_no) && $scope.excelTemplate.data.results.some(tmp => tmp.file_type === 'xlsx')) {
        for (const [bundle_no, ords] of Object.entries(_.groupBy(orders, 'bundle_no'))) {
          if (ords.filter(ord => ord.ord_status === '신규주문' && ord.ship_avail_yn && !checkDuplicateDepotNo.includes(ord.depot_no)).map(ord => ord.uniq).length === ords.length) {
            if (canSendOrders[depot_no]) {
              canSendOrders[depot_no].push(...ords);
            } else {
              canSendOrders[depot_no] = ords;
            }
            canSendOrderCnt += ords.length;
          } else {
            cantSendOrders.push(bundle_no);
            tempOrderList = tempOrderList.concat(ords);
          }
        }
        cantSendOrderCnt = new Set(cantSendOrders).size;
      } else {
        cantSendOrderCnt += orders.length;
      }
    }
    cantSendOrders = Array.from(new Set(cantSendOrders));

    const opt = {
      html: true,
      title: '발주서 전송',
      text: `
        <div>
          출고 가능 여부 정상인 주문 ${canSendOrderCnt}건<br><br>
          정상적으로 출고가 가능한 주문만 발주서를 발송하시겠습니까?<br><br>
          <ul style="font-size: 13px;">
            <li>[확인] 선택 시 발주양식이 등록된 주문은 발주서 발송과 함께 [출고대기] 상태로 변경됩니다.</li>
            <li>메일 발송 성공 시 보낸 발주서는 발주서 발송 > 보낸메일함에서 확인할 수 있습니다.</li>
            <li>재고 및 품절 여부, 다중 배송처 등의 경우 출고 가능 여부가 불가일 수 있습니다.</li>
            <li>발송된 메일은 3주 동안 재발송 가능하며 이후에는 재발송이 불가능합니다.</li>
          </ul>
        </div>
        `
    };

    if (noneExcelDepotCnt || cantSendOrderCnt) {
      // 발주서 발송 실패 케이스 추가되면 아래 객체에 추가(그래도 분기처리는 따로 추가해야합니다 ㅠ.ㅠ)
      if (canSendOrderCnt) {
        opt.text = `
        <div>
          <b>전송가능: ${canSendOrderCnt}건 / 전송불가: ${cantSendOrderCnt}건</b><br><br>
          <div>전송이 가능한 주문만 발송하시겠습니까?</div><br>
          <ul style="font-size: 13px;">
            <li>[확인] 선택 시 발주양식이 등록된 주문은 발주서 발송과 함께 [출고대기] 상태로 변경되며,</li>
            <li style="list-style-type:none;">다음 단계에서 발송이 불가한 주문의 실패 사유를 확인할 수 있습니다.</li>
            <li>메일 발송 성공 시 보낸 발주서는 발주서 발송 > 보낸메일함에서 확인할 수 있습니다.</li>
            <li>재고 및 품절 여부, 다중 배송처 등의 경우 출고 가능 여부가 불가일 수 있습니다.</li>
            <li>발송된 메일은 3주 동안 재발송 가능하며 이후에는 재발송이 불가능합니다.</li>
          </ul>
        </div>`;
        opt.title = '발주서 전송 안내';

        confirm = await commonSVC.showConfirmCustom(opt);

        if (confirm) {
          await showFailMessage(noneExcelDepots, noneExcelDepotCnt, cantSendOrderCnt, cantSendOrders);
        }
      } else {
        await showFailMessage(noneExcelDepots, noneExcelDepotCnt, cantSendOrderCnt, cantSendOrders);
      }

    } else {
      confirm = await commonSVC.showConfirmCustom(opt);
    }

    if (!confirm) {
      $timeout(() => { $scope.waitBtnDisableFlag = false; });

      return false;
    }

    $scope.emailCnt = (await commonModel.getServiceCnt()).data.emailCnt;

    // 보유 이메일 카운트보다 보내는 메일 수가 많을 시 모달 출력 & 결제탭 열림
    if (($scope.emailCnt.avail + $scope.emailCnt.benefit) < Object.keys(canSendOrders).length) {
      await commonSVC.showMessage('발송 가능한 카운트가 없습니다.', '[이메일 충전하기]에서 이메일 카운트 충전 후 이용해주세요.');
      $timeout(() => { $scope.waitBtnDisableFlag = false; });

      $uibModalInstance.close();
      $rootScope.openPayReq();

      return false;
    }

    $scope.reCnt = {
      success: 0,
      error: 0,
      errReason: []
    };

    const getEmailFormatResult = await getOrderEmailFormat(canSendOrders);

    if ($scope.reCnt.success !== Object.keys(canSendOrders).length) {
      commonSVC.showToaster('error', '실패', `${getEmailFormatResult.message || '메일 발송 포멧 생성에 실패했습니다.'}`);
      $timeout(() => { $scope.waitBtnDisableFlag = false; });

      return false;
    }

    const params = {
      count: canSendOrderCnt,
      all: false,
      uniqList: Object.values(canSendOrders).flat().map(ord => ord.uniq),
      selectedShopType: data.selectedShopType,
      auto_bundle: false,
      sendOrder: true,
    };

    shipmentModel.deliveryInstruction(params, async function (state, data) {
      if (data.results === 'success' || data.data?.error?.includes('출고지시 할 주문이 없습니다.')) {
        commonSVC.showToaster('success', '성공', `발송에 성공한 주문은 [출고대기]로 변경됩니다.
          출고관리 메뉴에서 출고처리를 진행해 주세요.

          *이메일 발송 내역은 결제 > 부가서비스 발송내역에서 확인할 수 있습니다.`);

        $scope.orderList = tempOrderList;

        await sendOrderEmail();

        $timeout(() => { $scope.waitBtnDisableFlag = false; });
      } else {
        commonSVC.showToaster('error', '실패', '출고 지시에 실패하였습니다.');
        $timeout(() => { $scope.waitBtnDisableFlag = false; });
      }
    });
  };

  /**
   * 부분실패 또는 전체실패 알럿 모달 함수
   */
  const showFailMessage = async (noneExcelDepots, noneExcelDepotCnt, cantSendOrderCnt, cantSendOrders) => {
    const optionTextObj = {
      excel: `
      <div><b>해당 배송처의 엑셀 양식이 존재하지 않습니다.</b></div>
      <div>엑셀 > 통합엑셀다운 > 양식[추가] > 분류: "배송처" 선택 후 전용 양식을 생성해 주세요.</div><br>
      <li>배송처명: ${noneExcelDepots.join(', ')}</li><br>
      `,
      order: `
      <div><b>출고가 불가능한 주문 ${cantSendOrderCnt}건은 발주서 발송이 불가능합니다.</b></div>
      <div>주문리스트 > 출고가능여부에서 사유를 확인해 주세요.</div>
      <div>(출고가 가능한 주문만 발주서 발송 가능)</div><br>
      <ul>
        <li>묶음번호:</li>
        ${cantSendOrders.join(',<br>')}
      </ul>
      `
    };
    let modalContent = '';

    if (noneExcelDepotCnt && cantSendOrderCnt) {
      for (const textKey in optionTextObj) {
        modalContent = modalContent.concat(`${optionTextObj[textKey]}`);
      }
    } else if (!cantSendOrderCnt && noneExcelDepotCnt) {
      modalContent = optionTextObj.excel;
    } else if (cantSendOrderCnt && !noneExcelDepotCnt) {
      modalContent = optionTextObj.order;
    }

    await commonSVC.showMessageHtml('발주서 발송 실패', `
    <div>
    ${modalContent}
    </div>
    `);

    return false;
  };

  $scope.resendOrder = async () => {
    if (commonSVC.checkPermission('order.roles.excelOrder', userInfo.permission) === false) {
      return false;
    }

    const selectList = $scope.grid.methods.selectedData('all');

    if (!selectList.length) {

      commonSVC.showMessage('메일 내역을 선택해주세요.', '');

      return false;
    }

    for (const log of selectList) {
      if (!$scope.send_mails.some(sender => sender === log.from_m)) {
        commonSVC.showMessage('메일 다시보내기', '존재하지 않는 담당자 이메일 입니다.');

        return false;
      }
    }

    // 배송처 사용여부 N이거나 해당 배송처에 담당자 메일이 다른 경우
    for (const list of selectList) {
      const matchDepot = $scope.warehouseList.find(depot => list.depot_no === depot.code);

      if (!matchDepot || matchDepot.charge_email !== list.to_m) {
        commonSVC.showMessage('메일 다시보내기', '선택한 메일을 재발송 할 수 없습니다.\n* 최초 발송일 부터 3주간 재발송 가능하며 이후에는 불가능합니다.');

        return false;

      }
    }

    if (selectList.some(list => moment().diff(moment(list.wdate).format('YYYY-MM-DD'), 'days') > 21)
    || selectList.some(list => !list.file_url)
    ) {
      commonSVC.showMessage('메일 다시보내기', '선택한 메일을 재발송 할 수 없습니다.\n* 최초 발송일 부터 3주간 재발송 가능하며 이후에는 불가능합니다.');

      return false;
    }

    const confirm = await commonSVC.showConfirmCustom({ title: '메일 다시보내기', text: '선택한 메일을 재발송 하시겠습니까?\n* 최초 발송일 부터 3주간 재발송 가능하며 이후에는 불가능합니다.' });

    if (!confirm) {
      return false;
    }

    $scope.emailCnt = (await commonModel.getServiceCnt()).data.emailCnt;

    // 보유 이메일 카운트보다 보내는 메일 수가 많을 시 모달 출력
    if (($scope.emailCnt.avail + $scope.emailCnt.benefit) < selectList.length) {
      return commonSVC.showMessage('발송 가능한 카운트가 없습니다.', '[이메일 충전하기]에서 이메일 카운트 충전 후 이용해주세요.');
    }

    $scope.sendData = selectList.map(list => ({
      senderEmail: list.from_m,
      receiverEmail: list.to_m,
      title: list.title,
      content: list.contents,
      file_url: list.file_url,
    }));

    const params = {
      type: 'resend',
      sendData: $scope.sendData
    };

    shipmentModel.sendOrderEmail(params, (state, data) => {
      if (state === 'success') {
        commonSVC.showMessage('메일 다시보내기', '발주서 재발송을 성공하였습니다.');
        $scope.searchDo();

        return;
      } else {

        return commonSVC.showMessage('메일 다시 보내기', '발주서 재발송을 실패하였습니다.');
      }
    });
  };

  $scope.delLogFile = async () => {
    const selectList = $scope.grid.methods.selectedData('all');

    if (!selectList.length) {

      commonSVC.showMessage('메일 내역을 선택해주세요.', '');

      return false;
    }
    const confirm = await commonSVC.showConfirmCustom({ title: '삭제', text: '선택한 메일을 삭제하시겠습니까?\n* 메일 보낸 내역은 삭제 되지 않고 첨부된 파일만 삭제 됩니다.' });

    if (!confirm) {
      return false;
    }

    shipmentModel.deleteOrderFileLogs({ logList: selectList.map(list => list.tran_pr) }, (state, data) => {
      if (state === 'success') {
        commonSVC.showMessage('삭제', '첨부 파일 삭제에 성공하였습니다.');
        $scope.searchDo();

        return;
      } else {

        return commonSVC.showMessage('삭제', '첨부 파일 삭제에 성공하였습니다.');
      }
    });
  };

  $scope.cancel = function () {
    $uibModalInstance.close();
  };

  // 엑셀 다운로드 하여 해당 url로 배송처 전송 메일 포맷 생성
  async function getOrderEmailFormat(orderDatas) {
    const promiseArr = [];
    $scope.cnt = 0;

    for (const [key, uniqs] of Object.entries(orderDatas)) {
      const downExcel = $scope.excelTemplate.data.results.find(tmp => +tmp.depot_no?.includes(+key));

      let excelParams = {
        template_no: downExcel.template_no,
        is_all: false,
        uniq: uniqs?.map(v => v.uniq),
        down_carr_cd: true,
        page: 'ordManage',
        depot_no: key,
        bundle_yn: 1,
        status: ['신규주문'],
        ord_status_stat: ['신규주문'],
        shopType: 'domestic',
        fileNameFlag: true
      };

      if ($scope.orderSearchForm) {
        $scope.orderSearchForm = {
          bundle_yn: 1,
          status: ['신규주문'],
          ord_status_stat: ['신규주문'],
          shopType: 'domestic'
        };
        excelParams = angular.merge(excelParams, { ...$scope.orderSearchForm, shopType: data.selectedShopType });

      }

      // excelParams.timeout = Math.ceil(parseInt(uniqs.length) / 2000) * 60 * 1000; // 2000 개당 1분으로 타임아웃 보냄
      const anHttpPromise = commonModel.excelDownload('/app/order/excel/downIntegrated', excelParams)
        .then(function(result) {
          if (result.status === 200) {
            $scope.sendData.push({
              depot_no: +key,
              file_url: result.data.url.result,
              senderEmail: $scope.templateData.senderEmail,
              title: $scope.templateData.title || $scope.defaultMailValue.title,
              content: $scope.templateData.content || $scope.defaultMailValue.content,
            });
            $scope.cnt += uniqs.length;
            $scope.reCnt.success++;
          } else {
            $scope.reCnt.error++;
          }

          return result;
        })
        .catch(function(err) {
          $scope.reCnt.error++;

          return err;
        });

      promiseArr.push(anHttpPromise);
    }

    return $q.all(promiseArr);
  }

  /**
   * 발주서 메일 발송
   */
  async function sendOrderEmail() {
    const params = {
      type: 'send',
      sendData: $scope.sendData
    };

    shipmentModel.sendOrderEmail(params, (state, data) => {
      if (state === 'success') {
        $scope.searchDo();
      }

      return state === 'success' ? '' : commonSVC.showMessage('발주서 발송 실패', errMessage);
    });
  }
});
