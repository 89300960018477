/**
 * Created by Ally on 2016-11-23.
 */
'use strict';

angular.module('gmpApp')
  .service('deliverySVC', function (commonSVC, gettextCatalog, userInfo) {

    const form = {
      searchForm: {
        search_key: 'all',
        search_word: '',
        date_type: 'wdate',
        sdate: commonSVC.getDate(new Date().setDate(new Date().getDate() - 7), 'yyyy-MM-dd'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        use_yn: '',
        type: ''
      },

      searchData: {
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('구분'), value: 'type' },
          { label: gettextCatalog.getString('택배사'), value: 'name' },
          { label: gettextCatalog.getString('택배사ID'), value: 'delivery_id' },
          { label: gettextCatalog.getString('형식'), value: 'template_type' },
          { label: gettextCatalog.getString('양식명'), value: 'template_name' }
        ],
        search_date_type: [
          { label: gettextCatalog.getString('등록일'), value: 'wdate' },
          { label: gettextCatalog.getString('수정일'), value: 'mdate' }
        ]
      },

      carrList: [
        { carr_name: 'CJ대한통운', carr_no: 4 },
        { carr_name: '한진택배', carr_no: 5 },
        { carr_name: '우체국택배', carr_no: 6 },
        { carr_name: '로젠택배', carr_no: 7 },
        { carr_name: '롯데택배', carr_no: 8 },
        { carr_name: '카카오T당일배송', carr_no: 956 },
        { carr_name: 'CU편의점택배', carr_no: 107 },
        { carr_name: '이마트24편의점택배', carr_no: 1007 }
      ],

      invoiceTemplate: {
        // CJ 대한통운
        4: {
          invoiceType: [
            { name: 'BIXOLON 가로표준', value: 'T007' },
            { name: 'BIXOLON 가로표준 3단', value: 'T008' },
            { name: 'BIXOLON 가로표준 소형', value: 'T009' },
            { name: 'TSC 가로표준', value: 'T014' },
            { name: '가로표준 5인치', value: 'T020' }
          ],
          boxInfo: true,
          // 대한통운측의 요청으로 특대(이형) 박스타입은 비노출 함. (대한통운 영업담당자와 본사 담당자 확인이 완료된경우 열어줄 수 있음 ) 2018-11-29 rony
          // ------------------------------------------------------------------------------
          // 현재 플레이오토 접수건중에 다수가 박스타입 '5'(이형)으로 접수가 되는 사례가 발생하여
          // 추가 수수료가 택배기사에게 지급되는 문제가 발생하고 있어
          // 플레이오토는 박스타입을 '5'로 등록하지 못하도록 화면에서 수정해 주시기 바랍니다.
          // ------------------------------------------------------------------------------
          // 특정 업체 대한 통운 박스타입 대2 타입 선택 가능하며,
          // 대2의 경우 계약에 등록이 되어 있어야 하여, 계약지점에 확인 필요(대한통운 확인) 2022-02-14 Denver
          boxType: [
            // { name: '이형', value: '이형' },
            { name: '대', value: '대' },
            { name: '중', value: '중' },
            { name: '소', value: '소' },
            { name: '극소', value: '극소' }
          ],
          defaultData: {
            template_type_cd: 'T007',
            box_type: '극소',
            box_count: 1,
            cod_use_yn: 0,
            mountain_noti_yn: 1,
            ship_jeju_type: '원주문',
            ship_cod_cost: 2500,
            sale_name_type: '상품명',
            bundle_ship_cost_type: '최대배송비',
            bundle_ship_cost: 0,
            sett_ship_free_txt: '신용',
            sett_ship_cod_txt: '',
            // print_sort_criteria: '사용안함'
          },
          maskingAllType: ['T020']
        },
        // 한진택배
        5: {
          invoiceType: [
            { name: 'BIXOLON 가로표준(NS형)', value: 'T010_new' },
            { name: 'BIXOLON 가로표준(NS_2P형, IS형)', value: 'T021_new' },
            { name: 'BIXOLON 가로표준(NL형)', value: 'T022' },
            { name: 'BIXOLON 가로표준(FS형)', value: 'T028' },
            { name: 'BIXOLON 가로표준(SF형)', value: 'T030' }
          ],
          boxInfo: true,
          boxType: [
            { name: 'S (60cm 이하)', value: 'S' },
            { name: 'A (80cm 이하)', value: 'A' },
            { name: 'B (100cm 이하)', value: 'B' },
            { name: 'C (120cm 이하)', value: 'C' },
            { name: 'D (140cm 이하)', value: 'D' },
            { name: 'E (160cm 이하)', value: 'E' }
          ],
          defaultData: {
            template_type_cd: 'T010_new',
            box_type: 'A',
            box_count: 1,
            ship_jeju_type: '원주문',
            ship_cod_cost: 2500,
            sale_name_type: '상품명',
            bundle_ship_cost_type: '최대배송비',
            bundle_ship_cost: 0,
            sett_ship_free_txt: '신용'
          }
        },
        // 우체국택배
        6: {
          invoiceType: [
            { name: 'A4 신형 3면', value: 'T017' },
            { name: '신형 B형', value: 'T018' },
            { name: '신형 C형', value: 'T019' }
          ],
          boxInfo: true,
          defaultData: {
            template_type_cd: 'T019',
            box_weight: 2000,
            box_volume: 60,
            box_count: 1,
            ship_jeju_type: '원주문',
            ship_cod_cost: 2500,
            sale_name_type: '상품명',
            bundle_ship_cost_type: '최대배송비',
            bundle_ship_cost: 0,
            sett_ship_free_txt: '신용'
          },
          maskingAllType: ['T018', 'T019']
        },
        // 로젠택배
        7: {
          boxInfo: false,
          defaultData: {
            ship_jeju_type: '원주문',
            sett_ship_method: '원주문',
            sale_name_type: '상품명',
            ship_cod_cost: 2500
          }
        },
        // 롯데택배
        8: {
          invoiceType: [
            { name: '표준라벨 D', value: 'T025' },
            { name: '표준라벨 E', value: 'T026' },
            { name: '표준라벨 E(품목다수)', value: 'T027' },
            { name: '표준라벨 E(출고용)', value: 'T033' },
            // { name: '표준라벨 E(회수용)', value: 'T034' },
            { name: '의류표준 B', value: 'T035' },
          ],
          boxType: [
            { name: 'A (80cm)', value: 'A' },
            { name: 'B (100cm)', value: 'B' },
            { name: 'C (120cm)', value: 'C' },
            { name: 'D (140cm)', value: 'D' },
            { name: 'E (160cm)', value: 'E' },
            { name: 'F (180cm)', value: 'F' },
          ],
          boxInfo: true,
          defaultData: {
            template_type_cd: 'T025',
            box_type: 'A',
            box_count: 1,
            mountain_noti_yn: 1,
            ship_jeju_type: '원주문',
            ship_cod_cost: 2500,
            sale_name_type: '상품명',
            bundle_ship_cost_type: '최대배송비',
            bundle_ship_cost: 0,
            sett_ship_free_txt: '신용',
            sett_ship_cod_txt: '',
          }
        },
        // 카카오T당일배송
        956: {
          invoiceType: [
            { name: '구 송장지', value: 'T023' },
            { name: '신 송장지', value: 'T032' }
          ],
          defaultData: {
            template_type_cd: 'T023',
            sale_name_type: '상품명',
            bundle_ship_cost_type: '최대배송비'
          }
        },
        // CU편의점택배
        107: {
          boxInfo: false,
          defaultData: {
            template_type_cd: 'T026',
            sale_name_type: '상품명',
            main_item_name: ''
          }
        },
        // 이마트24편의점택배
        1007: {
          boxInfo: false,
          defaultData: {
            template_type_cd: 'T026',
            sale_name_type: '상품명',
            main_item_name: ''
          }
        },
      },

      // 해외배송사 관련
      global: (() => {
        const carrList = [
          { carr_name: '우체국', carr_name_eng: 'KoreaPost', carr_no: 97 },
          { carr_name: 'FedEx', carr_name_eng: 'FedEx', carr_no: 12 },
          { carr_name: 'DHL', carr_name_eng: 'DHL', carr_no: 10 },
          { carr_name: 'UPS', carr_name_eng: 'UPS', carr_no: 96 },
          { carr_name: 'Cello Square', carr_name_eng: 'Cello Square', carr_no: 952 },
        ];

        /**
         * 배송사별 정보
         */
        const companies = {
          97: {
            carr_no: 97,
            name: '우체국',
            controller: 'printInvoiceKoreaPostGridController',
            requiredCol: ['bundle_weight'],
          },
          12: {
            carr_no: 12,
            name: 'Fedex',
            controller: 'printInvoiceFedexGridController',
            requiredCol: ['misc16', 'bundle_width', 'bundle_height', 'bundle_depth', 'bundle_weight', 'misc15'],
          },
          10: {
            carr_no: 10,
            name: 'DHL',
            controller: 'printInvoiceDHLGridController',
            requiredCol: ['misc16', 'bundle_width', 'bundle_height', 'bundle_depth', 'bundle_weight', 'misc15'],
          },
          96: {
            carr_no: 96,
            name: 'UPS',
            controller: 'printInvoiceUPSGridController',
            requiredCol: ['misc16', 'bundle_width', 'bundle_height', 'bundle_depth', 'bundle_weight', 'misc15'],
          },
          543: {
            carr_no: 543,
            name: 'Doora Logistics',
            controller: 'printInvoiceDooraGridController',
          },
          944: {
            carr_no: 944,
            name: 'Shopee(SLS)',
            controller: 'printInvoiceShopeeGridController',
            isShopInvoice: true,
          },
          977: {
            carr_no: 977,
            name: 'Shopee Pickup',
            controller: 'printPickupShopeeGridController',
            isShopInvoice: true,
            type: '라벨',
            typeName: 'Pickup 코드',
          },
          951: {
            carr_no: 951,
            name: 'Lazada(LGS)',
            controller: 'printInvoiceLazadaGridController',
            isShopInvoice: true,
          },
          952: {
            carr_no: 952,
            name: 'Cello Square',
            controller: 'printInvoiceCelloGridController',
            requiredCol: ['misc16', 'bundle_width', 'bundle_height', 'bundle_depth', 'bundle_weight', 'misc14', 'misc15'],
          },
        };

        /**
         * 자체물류 배송사 정의
         * pa_shop_cd : 각 페이지에서 유효성검사 시, 해당 key 여부로 배송사 사용가능 쇼핑몰을 체크한다. 쇼핑몰 자체물류의 경우엔 반드시 설정
         * shop_cd : 출력가능한 쇼핑몰이 한정되어 있을경우 설정
         *
         * Shopee 의 경우 현재는 4개국가 모두 Shopee(SLS)를 사용하므로 shop_cd 설정을 하지 않아도 되지만..
         * 추후 지원되는 국가는 Shopee(두라?) 등 다른 배송사를 사용하므로 미리 shop_cd 로 분리해둠 - 현재는 모두 지원
         */
        const logisticList = [
          {
            carr_name: 'Shopee(SLS)',
            carr_name_eng: 'Shopee(SLS)',
            carr_no: 944,
            pa_shop_cd: ['X099'],
          },
          { carr_name: 'Qoo10(Qxpress)', carr_name_eng: 'Qxpress', carr_no: 947, pa_shop_cd: [] },
          { carr_name: 'Lazada(LGS)', carr_name_eng: 'Lazada(LGS)', carr_no: 951, pa_shop_cd: [] },
        ];

        const channel = {
          // 합포장불가 쇼핑몰
          noModifyBundle: ['X096'],
          // 주문분할불가 쇼핑몰
          noDivideBundle: ['X096'],
          // 동일주문번호안에서만 합포장 가능한 쇼핑몰
          singleShopOrdNo: ['X095', 'X099'],
          // 일괄합포장만 가능한 쇼핑몰
          allCombineBundle: ['X099'],
          // 주문분할 / 합포장 연동 쇼핑몰
          shopBundleAct: ['X099'],
        };

        /**
         * 채널별 패키징 설정
         * pa_shop_cd: 동일 pa_shop_cd끼리만 합포장 가능
         * shop_cd:    동일 shop_cd끼리만 합포장 가능
         * shop_id:    동일 shop_id끼리만 합포장 가능
         */
        const channelPackage = {
          A000: ['pa_shop_cd'],
          Z002: ['pa_shop_cd', 'shop_cd', 'shop_id'],
          Z004: ['pa_shop_cd'],
          X096: ['pa_shop_cd', 'shop_cd', 'shop_id'],
          X097: ['pa_shop_cd', 'shop_cd', 'shop_id'],
          X098: ['pa_shop_cd', 'shop_cd', 'shop_id'],
          X095: ['pa_shop_cd', 'shop_cd', 'shop_id'],
          Z010: ['pa_shop_cd', 'shop_cd', 'shop_id'],
          Z013: ['pa_shop_cd', 'shop_cd', 'shop_id'],
          Z015: ['pa_shop_cd', 'shop_cd', 'shop_id'],
          Z016: ['pa_shop_cd'],
        };

        /*
          두라:543
          1. 일반배송사 이지만 shippo API 를 사용하지 않는 배송사
          2. pa5 와 연동하기 위해 배송사 등록시점에 uuid 생성하는 배송사
        */
        const nonCarrierAPIArray = [543, 952, 977];

        /* 국내택배사 */
        const domesticCarrierArray = [977];

        return {
          // 기본노출 배송사
          carrList: carrList,

          // 배송사별 정보
          companies: companies,

          // 배송사 이지만 shippo API 를 사용하지 않는 배송사
          nonCarrierAPIArray: nonCarrierAPIArray,

          // 국내택배사
          domesticCarrierArray: domesticCarrierArray,

          // 자체물류 배송사
          logisticList: logisticList,

          // 추가노출 배송사
          getCarrList: function (carrList, channelList) {
            let shops;
            logisticList.map((logistic) => {
              if (!carrList.some(o => o.carr_no == logistic.carr_no)) {
                // 채널에 따른 자체물류 배송사 추가처리
                if (logistic.pa_shop_cd && logistic.pa_shop_cd.length) {
                  shops = channelList.filter(o => logistic.pa_shop_cd.includes(o.pa_shop_cd));
                  if (shops.length && logistic.shop_cd) {
                    if (shops.some(o => logistic.shop_cd.includes(o.shop_cd))) {
                      carrList.push(logistic);
                    }
                  } else if (shops.length) {
                    carrList.push(logistic);
                  }
                } else {
                  // 채널에 영향받지 않는 배송사
                  carrList.push(logistic);
                }
              }
            });

            return carrList;
          },

          // 기타값 추가
          carrAllListWithEtc: function (carrList) {
            if (carrList.find(v => v.carr_no == 394)) { return carrList; }

            const carrEtcList = angular.copy(carrList);
            carrEtcList.push({
              carr_no: 394,
              pa_carr_no: null,
              carr_name: '기타',
              carr_view_name: '기타',
            });

            return carrEtcList;
          },

          // 채널별 배송설정
          channel: channel,

          // 채널별 패키징 설정
          channelPackage: channelPackage,

          // 배송사 분류
          deliveryInfo: [
            // { bundleDisplayNm: '물류 통합서비스', deliveryNm: ['Cello Square'] },
            // { bundleDisplayNm: '국제특송', deliveryNm: ['우체국', 'FedEx', 'DHL', 'UPS'] },
            {
              bundleDisplayNm: '쇼핑몰 자체물류',
              // deliveryNm: ['Shopee(SLS)', 'Shopee Pickup 라벨', 'Lazada(LGS)', 'Qoo10(Qxpress)'],
              deliveryNm: ['Shopee(SLS)']
            },
          ],

          // 자체물류만 사용가능한 쇼핑몰
          deliveryShopOnly: ['X099'], // 샤피

          // 상품유형
          sendProdTypeList: [
            { name: '서류(Document)', value: 'documents' },
            { name: '상품(Merchandise)', value: 'merchandise' },
            { name: '선물(Gift)', value: 'gift' },
            { name: '상품 견본(Sample)', value: 'sample' },
          ],

          // 양식설정
          invoiceTemplate: {
            carr_no: '',
            carr_id: '',
            template_name: '',
            template_type_cd: '',
            sender_name: '',
            sender_tel: '',
            from_zipcd: '',
            from_addr1: '',
            from_addr2: '',
            from_state: '',
            from_city: '',
            from_ctry_cd: 'KR',
            manage_name: '',
            etc: {
              send_type: '',
              box_type: '',
              insur: 'N',
              insur_curr: 'USD',
              insur_price: '',
              send_prod_type: 'merchandise',
              print_type: 'label',
              print_opt: 'fail',
              billing_type: '',
              is_document: 'N',
              non_delivery_option: '',
              certify_signer: '',
              customsOffer: 'N',
              businessNumber: '',
              exportShipper: '',
              exportShipperAddr: '',
              exportSubmission: 'N',
              reservation: 'N',
              reservation_hour: '00',
              reservation_minute: '00',
              send_inco_terms: 'DDP',
            },
          },

          // 배송추적 가능한 배송사
          trackingDelivery: [12, 952],

          // KoreaPost
          97: {
            serviceTypes: ['EMS', 'EMS프리미엄', '등기소형포장물', 'K-Packet'],
            invoiceTypeList: [
              {
                name: 'EMS 라벨기표지 A형 비서류(Invoice형)',
                value: 'T011',
                serviceTypes: ['EMS'],
                sendProdType: ['merchandise', 'gift', 'sample'],
                barcodeTypes: ['toptext'],
              },
              {
                name: 'EMS 라벨기표지 A형 비서류(Invoice형)',
                value: 'T016',
                serviceTypes: ['EMS프리미엄'],
                sendProdType: ['merchandise', 'gift', 'sample'],
                barcodeTypes: ['toptext'],
              },
              {
                name: 'EMS 라벨기표지 A형 비서류(영수증형)',
                value: 'T012',
                serviceTypes: ['EMS', 'EMS프리미엄'],
                sendProdType: ['merchandise', 'gift', 'sample'],
                barcodeTypes: ['toptext', 'notext'],
              },
              {
                name: 'EMS 라벨기표지 소형 서류(Invoice형)',
                value: 'T014',
                serviceTypes: ['EMS'],
                sendProdType: ['documents'],
                barcodeTypes: ['toptext'],
              },
              {
                name: 'EMS 라벨기표지 소형 서류(Invoice형)',
                value: 'T017',
                serviceTypes: ['EMS프리미엄'],
                sendProdType: ['documents'],
                barcodeTypes: ['toptext'],
              },
              {
                name: 'EMS 라벨기표지 소형 서류(영수증형)',
                value: 'T015',
                serviceTypes: ['EMS', 'EMS프리미엄'],
                sendProdType: ['documents'],
                barcodeTypes: ['toptext', 'notext'],
              },
              { name: 'K-Packet 기본', value: 'T013', serviceTypes: ['등기소형포장물'], barcodeTypes: [] },
              { name: 'K-Packet Light 미국', value: 'T021', serviceTypes: ['K-Packet'], barcodeTypes: ['notext'] },
              { name: 'K-Packet Light APP ePacket', value: 'T022', serviceTypes: ['K-Packet'], barcodeTypes: [] },
              { name: 'K-Packet Light Prime Express ', value: 'T023', serviceTypes: ['K-Packet'], barcodeTypes: [] },
            ],
            printTypeList: [
              { name: '라벨용지', value: 'label' },
              { name: 'A4', value: 'a4' },
            ],
            printOptList: [
              { name: '운송장 출력 작업을 실패처리합니다.', value: 'fail' },
              { name: '출력가능한 최대길이로 상품명을 절삭합니다.', value: 'cut' },
            ],
            reservation: [
              { name: '운송장번호 발급 즉시 예약신청', value: 'N' },
              { name: '운송장번호 발급 익일(다음날 평일) 오전 예약신청', value: 'Y' },
            ],
          },

          // FEDEX
          12: {
            serviceTypes: [
              { token: 'fedex_international_economy', name: 'International Economy' },
              { token: 'fedex_international_priority', name: 'International Priority' },
              { token: 'fedex_international_first', name: 'International First' },
            ],
            paidTypes: ['shipper', 'third_party'],
          },

          // DHL
          10: {
            serviceTypes: [{ token: 'dhl_express_worldwide', name: 'Express Worldwide' }],
          },

          // UPS
          96: {
            serviceTypes: [
              { token: 'ups_saver', name: 'UPS Worldwide Express Saver' },
              { token: 'ups_express', name: 'UPS Worldwide Express' },
              { token: 'ups_express_plus', name: 'UPS Worldwide Express Plus' },
              { token: 'ups_expedited', name: 'UPS Worldwide Expedited' },
            ],
          },

          // Cello Square
          952: {
            serviceTypes: ['Express', 'Economy'],
          },
        };
      })()
    };

    return form;
  });
