'use strict';

angular.module('gmpApp')
  .controller('UnLockCtrl', function ($scope, $http, settings, $uibModalInstance, gettextCatalog, commonSVC, commonModel, data, detectBrowser) {
    $scope.success = '';
    $scope.fail = '';
    $scope.submit_chk = false;

    const INIT_LIMIT_TIME_SMS = 60 * 5;
    const INIT_LIMIT_TIME_EMAIL = 60 * 15;

    let timer;
    let isFirstSend = true;

    $scope.sendData = {
      authType: 'login',
      htel: data.htel,
      email: data.email,
      sendType: data.htel && data.twofactor_type === 'sms' ? 'sms' : 'email',
      to: data.htel || data.email
    };

    $scope.isNotExistHtel = !$scope.sendData.htel;

    if (!$scope.isNotExistHtel) {
      const htelUnit = $scope.sendData.htel.replace(/-/g, '').match(/(\d{3})(\d{2})(\d{2})(\d{2})(\d{2})/);
      $scope.maskedHtel = htelUnit ? `${htelUnit[1]}-${htelUnit[2]}**-**${htelUnit[5]}` : '***';
    }

    function init() {
      const limitTime = $scope.sendData.sendType === 'sms' ? INIT_LIMIT_TIME_SMS : INIT_LIMIT_TIME_EMAIL;
      $scope.subAuthData = {
        key: '',
        isVerify: false,
        limitTime,
        limitString: moment(limitTime * 1000).format('mm:ss'),
        error: $scope.isNotExistHtel ? '저장된 휴대폰 번호가 없어 이메일로 전송되었습니다.' : ''
      };
      $scope.submit_chk = false;
      $scope.emailButtonText = "인증 메일 받기";
      $scope.buttonText = "인증번호 발송";
      $scope.success = '';
      $scope.fail = '';
      clearInterval(timer);
    }

    $scope.sendTypeValuer = (smsData, emailData) => $scope.sendData.sendType === 'sms' ? smsData : emailData;

    const setBodyPointerEvents = (disable) => {
      document.body.style.pointerEvents = disable ? 'none' : 'auto';
    };

    async function handleSendSubAuth() {
      $scope.submit_chk = true;
      setBodyPointerEvents(true);

      try {
        await commonModel.sendSubAuth($scope.sendData);
        await commonSVC.showMessage('', `계정 잠금 해제 인증에 필요한 인증번호가 ${$scope.sendTypeValuer('SMS', '이메일')}로 발송되었습니다.`, () => {
          $scope.subAuthData.isVerify = true;
          $scope.buttonText = "재전송";
          $scope.subAuthData.key = '';
        }, false, false);

        if ($scope.buttonText === "재전송") {
          clearInterval(timer);
          init();
        }

        setTimeout(() => {
          setBodyPointerEvents(false);
        }, 1000);

        setTimeout(() => {
          $scope.$apply(() => {
            $scope.submit_chk = false;
          });
        }, 3000);

        timer = setInterval(() => {
          $scope.subAuthData.limitString = moment(1000 * --$scope.subAuthData.limitTime).format('mm:ss');

          if ($scope.subAuthData.limitTime <= 0) {
            clearInterval(timer);
            $scope.buttonText = "재전송";
            $scope.subAuthData.error = '인증 시간이 만료되었습니다. 재전송 버튼을 눌러주세요.';
          }

          $scope.$apply();
        }, 1000);
      } catch {
        $scope.subAuthData.error = '인증 번호 재전송에 실패했습니다.';
        $scope.submit_chk = false;
        setBodyPointerEvents(false);
      }
    }

    $scope.sendSubAuth = handleSendSubAuth;

    $scope.sendOtherTypeSubAuth = () => {
      $scope.sendData = {
        ...$scope.sendData,
        sendType: $scope.sendTypeValuer('email', 'sms'),
        to: $scope.sendTypeValuer($scope.sendData.email, $scope.sendData.htel)
      };
      init();
    };

    $scope.submitForm = async function() {
      if ($scope.sendData.sendType === 'sms') {
        await $scope.verifySubAuth();
      }
    };

    async function handleVerifySubAuth() {
      const params = {
        ...$scope.sendData,
        key: $scope.subAuthData.key,
        ...detectBrowser
      };

      if (params.key.length !== 6) {
        $scope.subAuthData.error = '6자리 숫자로 입력하세요.';
        return false;
      }

      try {
        const result = await commonModel.verifySubAuth(params);
        $scope.subAuthData.isVerify = true;
        clearInterval(timer);
        $uibModalInstance.close('success');
        location.replace(`${window.location.origin}/password.html?token=${result.data}`);
        return true;
      } catch {
        $scope.subAuthData.error = '유효하지 않은 인증번호입니다.';
        return false;
      }
    }

    $scope.verifySubAuth = handleVerifySubAuth;

    async function handleOk() {
      const url = `${settings.pa20ApiUrl}/app/users/find`;
      const resolve = {
        user_id: $scope.sendData.email,
      };

      try {
        const response = await $http.post(url, resolve, { headers: { 'Content-Type': 'application/json' } });
        const data = response.data;

        if (data.result === 'not exist') {
          $scope.success = '';
          $scope.fail = gettextCatalog.getString('가입된 정보가 없습니다. 이메일 주소를 확인해 주시기 바랍니다.');
        } else if (response.status === 200) {
          $scope.submit_chk = true;
          $scope.fail = '';
          $scope.success = isFirstSend ? gettextCatalog.getString('인증 메일이 전송되었습니다.') : gettextCatalog.getString('인증 메일이 재전송되었습니다.');
          isFirstSend = false;
          $scope.emailButtonText = "재전송";

          setTimeout(() => {
            $scope.submit_chk = false;
            $scope.$apply();
          }, 3000);
        }
        $scope.$apply();
      } catch {
        $scope.success = '';
        $scope.fail = gettextCatalog.getString('인증 메일 전송이 실패하였습니다.');
        $scope.$apply();
      }
    }

    $scope.ok = handleOk;

    $scope.cancel = () => {
      $uibModalInstance.dismiss();
    };

    init();
  });