/**
 *  채널 상품관리 서비스
 *  2016-04-28 남형진
 */

'use strict';

angular.module('gmpApp')
  .factory('onlineProductSVC', function (commonSVC, $rootScope, userInfo, siteInfo, gettextCatalog) {
    const commonData = {

      /**
       * 폼 영역 변수 설정
       */
      searchForm: {
        date_type: 'wdate',
        search_key: 'all',
        search_word: '',
        sdate: commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 3), 'yyyy-MM-dd'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        brand_code: '',
        catrgory_code: '',
        template_yn: '',
        memo_yn: '',
        opt_yn: '',
        wid: '',
        sale_status: '',
        multi_type: 'c_sale_cd', //멀티서치 타입
        std_ol_type: 'normal',  // 온라인 리스트
        multi_shop_id: '',
        search_type: 'partial',
        prod_type: ''
      },

      searchFormSub: {
        //sdate: commonSVC.getDate(userInfo.user.user_wdate,'yyyy-MM-dd'), // 상품쪽은 user가 가입한 날짜가 처음 default
        //2017-09-07 roy IE, safari 호환을 위해 변경
        sdate: moment(userInfo.user.sol_sdate).format('YYYY-MM-DD'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        date_type: 'wdate',                                             //검색기간 구분
        search_word: '',                                                //검색어
        search_key: 'all',                                              //검색구분
        search_type: 'partial',                                         //부분일치, 완전일치
        multi_search_word: '', //멀티서치워드,
        multi_type: 'model_no', //멀티서치 타입
        search_sol_no: '',
        stock_status: '',
        linkage_flag: '',
      },

      searchDataSub: {
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
          { label: gettextCatalog.getString('판매자 관리코드'), value: 'c_sale_cd' },
          { label: gettextCatalog.getString('상품명'), value: 'prod_name' },
          { label: gettextCatalog.getString('모델번호'), value: 'model_no' },
          { label: gettextCatalog.getString('브랜드'), value: 'brand' },
        ],

        search_date_type: [
          { label: gettextCatalog.getString('등록일'), value: 'wdate' },
          { label: gettextCatalog.getString('수정일'), value: 'mdate' }
        ],
        search_multi_items: [
          { label: gettextCatalog.getString('모델번호'), value: 'model_no' },
        ]
      },

      /**
       * 기본 Vdata
       */
      defaultVdata: {
        adminAuto: true,
        sol_cate_no: '',              // 카테고리
        prod_code: '',              // 상품 코드
        c_sale_cd: '',             // 판매자 관리 코드
        gift_name: '',              // 사은품
        depot_no: '',              // 배송처 번호
        main_prod_no: '',          // 옵션 대표 상품 prod번호
        esm_main_prod_no: '',      // esm 옵션 대표상품 prod번호
        cpang_main_prod_no: '',     // 쿠팡 옵션 대표상품 prod번호
        wmp_main_prod_no: '',        // 위메프 옵션 대표상품 prod번호
        hplus_main_prod_no: '',     // 홈플러스 옵션 대표상품 prod번호
        // lotte_on_prod_no: '',       // 롯데ON 옵션 대표상품 prod번호
        naver_prod_no: '',          // 스마트스토어 간편옵션 대표상품 prod번호
        hscd: '',                   //hs코드
        madein_no: '',              //원산지 번호
        madein_etc: '',             //원산지 직접입력
        madein_group: '',
        prod_noti_data_new: [],
        img_objects: [              // 기본 이미지
          { name: '기본이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가이미지10', code: 'img_file10', files: '', old_files: '' }
        ],
        site_list: [],              // 사용가능 한 쇼핑몰
        market_list: [],            // 5대마켓 모델명, 브랜드, 제조사
        singleProductList: [],      // 단일상품 리스트
        cert_info: {},              // 인증 정보
        certDataList: {},              // 인증 정보
        product_list: [],           // 등록될 상품 리스트
        insert_opt: false,
        esm_insert_opt: false,
        std_insert_opt: false,
        cpang_insert_opt: false,
        wmp_insert_opt: false,
        hplus_insert_opt: false,
        insert_opt_arr: [{ insert_opt: '' }],        // 입력형 옵션 정보 배열
        insert_opt_info: '',       //입력형 옵션 정보 문자열
        ship_price_type: '',             // 배송비 종류
        ship_price_free_criteria: '',       // 배송비 종류 조건부 무료 제한금액
        ship_price: '',             // 배송비 금액
        img_del_obj: [],            // 삭제 이미지 Object
        detail_desc: '',            // 기본 상세설명
        detail_desc_ad: '',         // 광고/홍보
        detail_desc_add: '',        // 추가 상세설명
        detail_desc_eng: '',         // 영문 상세설명
        detail_desc_chn: '',         // 중문 상세설명
        detail_desc_jpn: '',         // 일문 상세설명
        addcontent_no: null,        // 머리말/꼬리말 템플릿번호 - 상세설명
        shop_sale_name_addcontent_no: null, // 머리말/꼬리말 템플릿번호 - 상품명
        tax_type: '과세',            //과세여부
        barcode: '',                //바코드
        isbn: '',                   //ISBN코드
        global_barcode_type: '',    //UPC, EAN 타입
        global_barcode: '',         //UPC, EAN 코드
        minor: 0,                   //미성년자 구매
        over_delivery: 'N',         //해외배송 여부
        made_date: '',              //제조일자
        prod_weight: '',            //해외배송 상품무게
        match_opt_type: '매칭',      //SKU상품 매칭 여부
        opt_type: '옵션없음',        //옵션 타입
        esm_opt_type: '옵션없음',     //ESM 옵션 타입
        std_opt_type: '옵션없음',     //std 옵션 타입
        cpang_opt_type: '옵션없음',   //쿠팡 옵션 타입
        rocket_opt_type: '옵션없음',   //쿠팡 옵션 타입
        wmp_opt_type: '옵션없음',     //위메프2.0 옵션타입
        hplus_opt_type: '옵션없음', // 홈플러스 옵션 타입
        // lotte_on_opt_type: '옵션없음', // 롯데ON 옵션 타입
        naver_opt_type: '옵션없음', // 스마트스토어 간편옵션 타입
        aliexpress_opt_type: '옵션없음', // 알리 익스프레스 옵션 타입
        alibaba_opt_type: '옵션없음',  // 알리바바 옵션 타입
        opt_name1: '',              //옵션구분명1
        opt_name2: '',              //옵션구분명2
        opt_name3: '',              //옵션구분명3
        opt_desc1: '',              //옵션내용1
        opt_desc2: '',              //옵션내용2
        opt_desc3: '',              //옵션내용3
        expiration_month: '52',     //유효일자
        prod_info: '',
        prod_info_opt: '',
        add_opt_type: 'N',           //추가구매 옵션
        std_ol_type: 'normal',      //일반상품 , 단일상품 ENUM('','normal','single_all','single_group_y','single_group_n')
        prodall: {                  //공통값
          name: '',                 //상품명
          promotion: '',            //홍보문구
          cost_price: '',           //원가
          street_price: '',         //시중가
          cnt: null,                //판매수량  ( '' 인경우 0 이 기본값으로 입력되어 null 로 수정. 2018-03-02 rony )
          sale_price: null,              //판매가격  ( '' 인경우 0 이 기본값으로 입력되어 null 로 수정. 2018-03-02 rony )
          supply_price: '',         //공급가
          market: {									//모델명, 브랜드, 제조사
            model_name: '',
            brand_name: '',
            maker_name: ''
          }
        },
        matchImg: '',              //대표상품으로 매칭된 이미지
        prodAllName: true,
        prodAllPrice: true,
        prodAllCnt: true,
        supply_price: true,
        esm: {                     //ESM 상품명
          name: '',
          promotion: ''
        },
        keyword: '',
        defaultOpTab: true,
        expire_date: '',
        nextday_ship_ol_yn: null,
        nextday_ship_avail_yn: null,
        esmDirectOptionEnable: true,  // ESM 직접옵션 등록가능여부
        aliexpressDirectOptionEnable: true,  // 알리 익스프레스 직접옵션 등록가능여부
        alibabaDirectOptionEnable: true,  // 알리바바 직접옵션 등록가능여부
      },

      // 롯데ON 등과 같이 쇼핑몰상에서 온라인상품명의 byte체크가 UTF-8 Byte로 처리 될 경우 shop code를 추가하여
      // byte체크 시 한글이 3byte씩 처리될 수 있도록 처리해 줌
      utf8ShopCodes: [
        'A524'
      ],

      // 상품 수정시 쇼핑몰별 추가 이미지 설정
      sub_img_box: {
        //GSSHOP
        A011: function (data) {
          const mobile_img = data.mobile_img ? data.sale_img_url + data.mobile_img : '';

          return [
            { name: '모바일이미지', en_name: 'mobile_img', files: mobile_img, old_files: mobile_img, size: '3MB', del_list: [], url: '' }
          ];
        },
        //롯데닷컴
        A012: function (data) {
          const exists_prod_img = data.exists_prod_img ? data.sale_img_url + data.exists_prod_img : '';
          const width_img = data.width_img ? data.sale_img_url + data.width_img : '';

          return [
            { name: '실품표시사항이미지', en_name: 'exists_prod_img', files: exists_prod_img, old_files: exists_prod_img, size: '3MB', del_list: [], url: '' },
            { name: '가로형상품이미지', en_name: 'width_img', files: width_img, old_files: width_img, size: '3MB', del_list: [], url: '' },
          ];
        },
        // 하프클럽
        A109: function (data) {
          const width_img = data.width_img ? data.sale_img_url + data.width_img : '';

          return [
            { name: '가로이미지', en_name: 'width_img', files: width_img, old_files: width_img, size: '3MB', del_list: [], url: '' },
          ];
        },
        //11번가
        A112: function(data) {
          const cardview_img = data.cardview_img ? data.sale_img_url + data.cardview_img : '';
          const list_img = data.list_img ? data.sale_img_url + data.list_img : '';
          const mobile_detail_img = data.mobile_detail_img ? data.sale_img_url + data.mobile_detail_img : '';

          return [
            { name: '카드뷰이미지', en_name: 'cardview_img', files: cardview_img, old_files: cardview_img, size: '3MB', del_list: [], url: '' },
            { name: '목록이미지', en_name: 'list_img', files: list_img, old_files: list_img, size: '3MB', del_list: [], url: '' },
            { name: '모바일상세설명이미지', is_detail: true, en_name: 'mobile_detail_img', files: mobile_detail_img, old_files: mobile_detail_img, size: '3MB', del_list: [], url: '' }
          ];
        },
        //패션플러스
        B007: function (data) {
          const list_img = data.list_img ? data.sale_img_url + data.list_img : '';

          return [
            { name: '목록이미지', en_name: 'list_img', files: list_img, old_files: list_img, size: '3MB', del_list: [], url: '' }
          ];
        },
        // 홈앤쇼핑
        B614: function (data) {
          const expand_img1 = data.expand_img1 ? data.sale_img_url + data.expand_img1 : '';

          return [
            { name: '와이드 이미지', en_name: 'expand_img1', files: expand_img1, old_files: expand_img1, size: '3MB', del_list: [], url: '' }
          ];
        },
        //고도몰5
        B691: function (data) {
          const list_img = data.list_img ? data.sale_img_url + data.list_img : '';
          const expand_img1 = data.expand_img1 ? data.sale_img_url + data.expand_img1 : '';
          const thumbnail_img = data.thumbnail_img ? data.sale_img_url + data.thumbnail_img : '';

          return [
            { name: '목록이미지', en_name: 'list_img', files: list_img, old_files: list_img, size: '3MB', del_list: [], url: '' },
            { name: '확대이미지', en_name: 'expand_img1', files: expand_img1, old_files: expand_img1, size: '3MB', del_list: [], url: '' },
            { name: '썸네일이미지', en_name: 'thumbnail_img', files: thumbnail_img, old_files: thumbnail_img, size: '3MB', del_list: [], url: '' }
          ];
        },
        //위메프2.0
        B719: function (data) {
          const list_img = data.list_img ? data.sale_img_url + data.list_img : '';

          return [
            { name: '리스팅이미지', en_name: 'list_img', files: list_img, old_files: list_img, size: '3MB', del_list: [], url: '', tooltip: '- 권장 이미지 사이즈: 580*320 / 최소: 200*100 / 용량: 2MB 이하 / 파일 : JPG, JPEG, PNG\n- 미등록시, 대표이미지가 자동으로 전송됩니다.' }
          ];
        },
        //고도몰
        B917: function(data) {
          const mobile_img = data.mobile_img ? data.sale_img_url + data.mobile_img : '';
          const list_img = data.list_img ? data.sale_img_url + data.list_img : '';
          const expand_img1 = data.expand_img1 ? data.sale_img_url + data.expand_img1 : '';
          const expand_img2 = data.expand_img2 ? data.sale_img_url + data.expand_img2 : '';

          return [
            { name: '목록이미지', en_name: 'list_img', files: list_img, old_files: list_img, size: '3MB', del_list: [], url: '' },
            { name: '모바일이미지', en_name: 'mobile_img', files: mobile_img, old_files: mobile_img, size: '3MB', del_list: [], url: '' },
            { name: '확대이미지1', en_name: 'expand_img1', files: expand_img1, old_files: expand_img1, size: '3MB', del_list: [], url: '' },
            { name: '확대이미지2', en_name: 'expand_img2', files: expand_img2, old_files: expand_img2, size: '3MB', del_list: [], url: '' }
          ];
        },
        //티몬-특가
        B851: function (data) {
          const list_img = data.list_img ? data.sale_img_url + data.list_img : '';
          const mobile_img = data.mobile_img ? data.sale_img_url + data.mobile_img : '';
          const thumbnail_img = data.thumbnail_img ? data.sale_img_url + data.thumbnail_img : '';

          return [
            { name: '카테고리<br>목록이미지', en_name: 'list_img', files: list_img, old_files: list_img, size: '3MB', del_list: [], url: '', tooltip: '- 권장 이미지 사이즈: 322*330 / 용량: 100KB 이하 / 파일 : JPG, JPEG, GIF\n- 미등록시, 대표이미지가 자동으로 전송됩니다.' },
            { name: '모바일이미지', en_name: 'mobile_img', files: mobile_img, old_files: mobile_img, size: '3MB', del_list: [], url: '', tooltip: '- 권장 이미지 사이즈: 756*383 / 용량: 200KB 이하 / 파일 : JPG, JPEG, GIF\n- 미등록시, 대표이미지가 자동으로 전송됩니다.' },
            { name: '주변상품<br>이미지', en_name: 'thumbnail_img', files: thumbnail_img, old_files: thumbnail_img, size: '3MB', del_list: [], url: '', tooltip: '- 권장 이미지 사이즈: 233*156 / 용량: 20KB 이하 / 파일 : JPG, JPEG, GIF\n- 미등록시, 대표이미지가 자동으로 전송됩니다.' }
          ];
        },
        //티몬-오픈마켓
        B956: function (data) {
          const mobile_img = data.mobile_img ? data.sale_img_url + data.mobile_img : '';

          return [
            { name: '모바일이미지', en_name: 'mobile_img', files: mobile_img, old_files: mobile_img, size: '3MB', del_list: [], url: '', tooltip: '- 권장 이미지 사이즈: 756*383 / 용량: 200KB 이하 / 파일 : JPG, JPEG, GIF\n- 미등록시, 대표이미지가 자동으로 전송됩니다.' }
          ];
        },
        //우먼스톡
        B687: function (data) {
          const list_img = data.list_img ? data.sale_img_url + data.list_img : '';
          const expand_img1 = data.expand_img1 ? data.sale_img_url + data.expand_img1 : '';
          const thumbnail_img = data.thumbnail_img ? data.sale_img_url + data.thumbnail_img : '';

          return [
            { name: '리스트이미지', en_name: 'list_img', files: list_img, old_files: list_img, size: '3MB', del_list: [], url: '' },
            { name: '확대이미지', en_name: 'expand_img1', files: expand_img1, old_files: expand_img1, size: '3MB', del_list: [], url: '' },
            { name: '썸네일이미지', en_name: 'thumbnail_img', files: thumbnail_img, old_files: thumbnail_img, size: '3MB', del_list: [], url: '' }
          ];
        },
        // 보리보리
        P014: function (data) {
          const width_img = data.width_img ? data.sale_img_url + data.width_img : '';

          return [
            { name: '가로이미지', en_name: 'width_img', files: width_img, old_files: width_img, size: '3MB', del_list: [], url: '' },
          ];
        },
        // 롯데슈퍼
        P020: function (data) {
          const width_img = data.width_img ? data.sale_img_url + data.width_img : '';

          return [
            { name: '가로이미지', en_name: 'width_img', files: width_img, old_files: width_img, size: '3MB', del_list: [], url: '' },
          ];
        },
        // 이지웰몰
        B325: function (data) {
          const mobile_img = data.mobile_img ? data.sale_img_url + data.mobile_img : '';

          return [
            { name: '모바일와이드이미지', en_name: 'mobile_img', files: mobile_img, old_files: mobile_img, size: '3MB', del_list: [], url: '' },
          ];
        },
        // 전자랜드
        A150: function (data) {
          const mobile_detail_img = data.mobile_detail_img ? data.sale_img_url + data.mobile_detail_img : '';

          return [
            { name: '종합몰 이미지', en_name: 'mobile_detail_img', files: mobile_detail_img, old_files: mobile_detail_img, size: '3MB', del_list: [], url: '' },
          ];
        },
        // 컴퓨존
        P037: function (data) {
          const list_img = data.list_img ? data.sale_img_url + data.list_img : '';

          return [
            { name: '목록 이미지', en_name: 'list_img', files: list_img, old_files: list_img, size: '3MB', del_list: [], url: '' },
          ];
        },
        // 롯데묘미
        P039: function (data) {
          const width_img = data.width_img ? data.sale_img_url + data.width_img : '';

          return [
            { name: '가로형이미지', en_name: 'width_img', files: width_img, old_files: width_img, size: '3MB', del_list: [], url: '' },
          ];
        },
        // 공구마켓/할인중독/심쿵할인
        A530: function (data) {
          const list_img = data.list_img ? data.sale_img_url + data.list_img : '';

          return [
            { name: '목록 이미지', en_name: 'list_img', files: list_img, old_files: list_img, size: '3MB', del_list: [], url: '', tooltip: '- 권장 이미지 사이즈: 720*400 / 용량: 70 KB 이하 / 파일 : JPG\n- 목록이미지를 필수로 입력해주세요.' },
          ];
        },
        // 알렛츠몰
        P050: function (data) {
          const width_img = data.width_img ? data.sale_img_url + data.width_img : '';
          const mobile_img = data.mobile_img ? data.sale_img_url + data.mobile_img : '';

          return [
            { name: '가로형이미지(pc)', en_name: 'width_img', files: width_img, old_files: width_img, size: '3MB', del_list: [], url: '', tooltip: '※ 가로형이미지를 등록할 경우 메인페이지에서 대표이미지가 아닌 가로형이미지를 보여줍니다.' },
            { name: '가로형이미지(mobile)', en_name: 'mobile_img', files: mobile_img, old_files: mobile_img, size: '3MB', del_list: [], url: '' },
          ];
        },
        // 에픽카
        P051: function (data) {
          const thumbnail_img = data.thumbnail_img ? data.sale_img_url + data.thumbnail_img : '';

          return [
            { name: '썸네일이미지', en_name: 'thumbnail_img', files: thumbnail_img, old_files: thumbnail_img, size: '3MB', del_list: [], url: '' }
          ];
        },
        // 샵바이(파트너)
        A536: function (data) {
          const list_img = data.list_img ? data.sale_img_url + data.list_img : '';

          return [
            { name: '리스트이미지', en_name: 'list_img', files: list_img, old_files: list_img, size: '3MB', del_list: [], url: '' },
          ];
        },
        // 샵바이(캐시딜)
        A176: function (data) {
          const list_img = data.list_img ? data.sale_img_url + data.list_img : '';

          return [
            { name: '리스트이미지', en_name: 'list_img', files: list_img, old_files: list_img, size: '3MB', del_list: [], url: '' },
          ];
        },
        // 샵바이(본사)
        A537: function (data) {
          const list_img = data.list_img ? data.sale_img_url + data.list_img : '';

          return [
            { name: '리스트이미지', en_name: 'list_img', files: list_img, old_files: list_img, size: '3MB', del_list: [], url: '' },
          ];
        },
      },

      // 개별 쇼핑몰 오브젝트 설정
      sub_img_objects: {

        //인터파크 MD
        A008: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' }
        ],

        A012: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가이미지10', code: 'img_file10', files: '', old_files: '' },
          { name: '추가이미지11', code: 'img_file11', files: '', old_files: '' },
          { name: '추가이미지12', code: 'img_file12', files: '', old_files: '' }
        ],

        A027: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' }
        ],

        A011: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' }
        ],

        A032: [
          { name: '이미지1', code: 'img_file0', files: '', old_files: '' },
          { name: '이미지2', code: 'img_file1', files: '', old_files: '' },
          { name: '이미지3', code: 'img_file2', files: '', old_files: '' },
          { name: '이미지4', code: 'img_file3', files: '', old_files: '' },
          { name: '이미지5', code: 'img_file4', files: '', old_files: '' },
          { name: '이미지6', code: 'img_file5', files: '', old_files: '' },
          { name: '이미지7', code: 'img_file6', files: '', old_files: '' },
          { name: '이미지8', code: 'img_file7', files: '', old_files: '' },
          { name: '이미지9', code: 'img_file8', files: '', old_files: '' },
          { name: '이미지10', code: 'img_file9', files: '', old_files: '' }
        ],

        A083: [
          { name: '이미지1', code: 'img_file0', files: '', old_files: '' },
          { name: '이미지2', code: 'img_file1', files: '', old_files: '' },
          { name: '이미지3', code: 'img_file2', files: '', old_files: '' },
          { name: '이미지4', code: 'img_file3', files: '', old_files: '' },
          { name: '이미지5', code: 'img_file4', files: '', old_files: '' },
          { name: '이미지6', code: 'img_file5', files: '', old_files: '' },
          { name: '이미지7', code: 'img_file6', files: '', old_files: '' },
          { name: '이미지8', code: 'img_file7', files: '', old_files: '' },
          { name: '이미지9', code: 'img_file8', files: '', old_files: '' },
          { name: '이미지10', code: 'img_file9', files: '', old_files: '' }
        ],

        A084: [
          { name: '이미지1', code: 'img_file0', files: '', old_files: '' },
          { name: '이미지2', code: 'img_file1', files: '', old_files: '' },
          { name: '이미지3', code: 'img_file2', files: '', old_files: '' },
          { name: '이미지4', code: 'img_file3', files: '', old_files: '' },
          { name: '이미지5', code: 'img_file4', files: '', old_files: '' },
          { name: '이미지6', code: 'img_file5', files: '', old_files: '' },
          { name: '이미지7', code: 'img_file6', files: '', old_files: '' },
          { name: '이미지8', code: 'img_file7', files: '', old_files: '' },
          { name: '이미지9', code: 'img_file8', files: '', old_files: '' },
          { name: '이미지10', code: 'img_file9', files: '', old_files: '' }
        ],

        A085: [
          { name: '이미지1', code: 'img_file0', files: '', old_files: '' },
          { name: '이미지2', code: 'img_file1', files: '', old_files: '' },
          { name: '이미지3', code: 'img_file2', files: '', old_files: '' },
          { name: '이미지4', code: 'img_file3', files: '', old_files: '' },
          { name: '이미지5', code: 'img_file4', files: '', old_files: '' },
          { name: '이미지6', code: 'img_file5', files: '', old_files: '' },
          { name: '이미지7', code: 'img_file6', files: '', old_files: '' },
          { name: '이미지8', code: 'img_file7', files: '', old_files: '' },
          { name: '이미지9', code: 'img_file8', files: '', old_files: '' },
          { name: '이미지10', code: 'img_file9', files: '', old_files: '' }
        ],

        A017: [
          { name: '메인이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가이미지10', code: 'img_file10', files: '', old_files: '' }
        ],

        // SK스토아
        A064: [
          { name: '메인 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
        ],

        A077: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가이미지9', code: 'img_file9', files: '', old_files: '' }
        ],

        A001: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가이미지10', code: 'img_file10', files: '', old_files: '' },
          { name: '추가이미지11', code: 'img_file11', files: '', old_files: '' },
          { name: '추가이미지12', code: 'img_file12', files: '', old_files: '' },
          { name: '추가이미지13', code: 'img_file13', files: '', old_files: '' },
          { name: '추가이미지14', code: 'img_file14', files: '', old_files: '' }
        ],

        A006: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가이미지10', code: 'img_file10', files: '', old_files: '' },
          { name: '추가이미지11', code: 'img_file11', files: '', old_files: '' },
          { name: '추가이미지12', code: 'img_file12', files: '', old_files: '' },
          { name: '추가이미지13', code: 'img_file13', files: '', old_files: '' },
          { name: '추가이미지14', code: 'img_file14', files: '', old_files: '' }
        ],

        //롯데아이몰
        A100: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가이미지10', code: 'img_file10', files: '', old_files: '' }
        ],

        A112: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' }
        ],

        A140: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가이미지9', code: 'img_file9', files: '', old_files: '' },
        ],

        A133: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가이미지9', code: 'img_file9', files: '', old_files: '' },
        ],

        B005: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가이미지9', code: 'img_file9', files: '', old_files: '' }
        ],

        //패션플러스
        B007: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' }
        ],

        //1300K
        B048: [
          { name: '메인 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' }
        ],

        B378: [
          { name: '정사각형 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '기타이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '기타이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '기타이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '기타이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '기타이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '기타이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '기타이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '기타이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '기타이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '직사각형 이미지', code: 'img_file10', files: '', old_files: '' }
        ],

        // 홈플러스
        B502: [
          { name: '메인 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '기타이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '기타이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '리스트이미지', code: 'img_file3', files: '', old_files: '' }
        ],

        B579: [
          { name: '큰이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '다른이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '다른이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '다른이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '다른이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '다른이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '다른이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '다른이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '다른이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '다른이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '다른이미지10', code: 'img_file10', files: '', old_files: '' }
        ],

        // 홈앤쇼핑
        B614: [
          { name: '메인이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' }
        ],

        // 위메프
        B719: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '', tooltip: '- 권장 이미지 사이즈: 460*460 / 최소: 200*200 / 용량: 2MB 이하 / 파일 : JPG, JPEG, PNG' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          // {name:'리스팅이미지',code:'img_file3',files:'',old_files:'', tooltip:'- 권장 이미지 사이즈: 580*320 / 최소: 200*100 / 용량: 2MB 이하 / 파일 : JPG, JPEG, PNG'}
        ],

        // 스포츠 매니아
        B720: [
          { name: '메인이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '확대(원본)이미지', code: 'img_file1', files: '', old_files: '' },
          { name: '리스트이미지', code: 'img_file2', files: '', old_files: '' },
          { name: '(구)모바일이미지', code: 'img_file3', files: '', old_files: '' },
          { name: '상세이미지1', code: 'img_file4', files: '', old_files: '' },
          { name: '상세이미지2', code: 'img_file5', files: '', old_files: '' },
          { name: '상세이미지3', code: 'img_file6', files: '', old_files: '' },
          { name: '상세이미지4', code: 'img_file7', files: '', old_files: '' },
          { name: '상세이미지5', code: 'img_file8', files: '', old_files: '' },
          { name: '상세이미지6', code: 'img_file9', files: '', old_files: '' },
        ],

        // 신세계TV쇼핑
        B878: [
          { name: '메인 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '모바일 이미지', code: 'img_file5', files: '', old_files: '' },
        ],

        //고도몰
        B917: [
          { name: '메인 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '상세이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '상세이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '상세이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '상세이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '상세이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '상세이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '상세이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '상세이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '상세이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '상세이미지10', code: 'img_file10', files: '', old_files: '' }
        ],

        // 바보사랑
        B051: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가이미지9', code: 'img_file9', files: '', old_files: '' }
        ],

        // W쇼핑
        B594: [
          { name: '메인 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: 'TV 이미지', code: 'img_file5', files: '', old_files: '' },
        ],

        // 카페24
        B616: [
          { name: '메인 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '상세이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '상세이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '상세이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '상세이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '상세이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '상세이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '상세이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '상세이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '상세이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '상세이미지10', code: 'img_file10', files: '', old_files: '' }
        ],

        // 무신사
        B666: [
          { name: '상품목록 이미지1', code: 'img_file0', files: '', old_files: '' },
          { name: '상품목록 이미지2', code: 'img_file1', files: '', old_files: '' },
          { name: '상품목록 이미지3', code: 'img_file2', files: '', old_files: '' },
          { name: '상품목록 이미지4', code: 'img_file3', files: '', old_files: '' },
          { name: '상품목록 이미지5', code: 'img_file4', files: '', old_files: '' },
          { name: '상품상세 이미지1', code: 'img_file5', files: '', old_files: '' },
          { name: '상품상세 이미지2', code: 'img_file6', files: '', old_files: '' },
          { name: '상품상세 이미지3', code: 'img_file7', files: '', old_files: '' },
          { name: '상품상세 이미지4', code: 'img_file8', files: '', old_files: '' },
          { name: '상품상세 이미지5', code: 'img_file9', files: '', old_files: '' }
        ],

        // 카카오톡 스토어
        B688: [
          { name: '메인 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '상세이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '상세이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '상세이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '상세이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '상세이미지5', code: 'img_file5', files: '', old_files: '' }
        ],

        // 카카오톡 선물하기
        B700: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' }
        ],

        // 고도몰5
        B691: [
          { name: '메인 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '상세이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '상세이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '상세이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '상세이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '상세이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '상세이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '상세이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '상세이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '상세이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '상세이미지10', code: 'img_file10', files: '', old_files: '' }
        ],

        // 티몬-특가
        B851: [
          { name: '메인 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '서브이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '서브이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '서브이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '서브이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '서브이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '인트로 이미지1', code: 'img_file6', files: '', old_files: '' },
          { name: '인트로 이미지2', code: 'img_file7', files: '', old_files: '' },
          { name: '인트로 이미지3', code: 'img_file8', files: '', old_files: '' },
          { name: '인트로 이미지4', code: 'img_file9', files: '', old_files: '' },
          { name: '인트로 이미지5', code: 'img_file10', files: '', old_files: '' }
        ],

        // 쇼핑엔티
        B877: [
          { name: '메인 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '모바일이미지', code: 'img_file5', files: '', old_files: '' },
        ],

        // 티몬-오픈마켓
        B956: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
        ],

        // 오늘의집
        B959: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
        ],

        // 갤러리아몰
        B394: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
        ],

        // 롯데ON
        A524: [
          { name: '기본 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '기타이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '기타이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '기타이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '기타이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '기타이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '기타이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '기타이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '기타이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '기타이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '상태 이미지', code: 'img_file10', files: '', old_files: '' },
        ],

        // 브랜디
        P012: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
        ],

        // 하이버
        P013: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
        ],

        // 플레어
        P017: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
        ],

        // 패션플러스
        A528: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가이미지9', code: 'img_file9', files: '', old_files: '' }
        ],

        // 하프클럽
        A109: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],

        // 보리보리
        P014: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],

        // 농수산쇼핑몰
        A118: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '서브 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '서브 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '서브 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '와이드 이미지', code: 'img_file4', files: '', old_files: '' }
        ],

        // 우먼스톡
        B687: [
          { name: '상세이미지1', code: 'img_file0', files: '', old_files: '' },
          { name: '상세이미지2', code: 'img_file1', files: '', old_files: '' },
          { name: '상세이미지3', code: 'img_file2', files: '', old_files: '' },
          { name: '상세이미지4', code: 'img_file3', files: '', old_files: '' },
          { name: '상세이미지5', code: 'img_file4', files: '', old_files: '' },
          { name: '상세이미지6', code: 'img_file5', files: '', old_files: '' },
          { name: '상세이미지7', code: 'img_file6', files: '', old_files: '' },
          { name: '상세이미지8', code: 'img_file7', files: '', old_files: '' },
          { name: '상세이미지9', code: 'img_file8', files: '', old_files: '' },
          { name: '상세이미지10', code: 'img_file9', files: '', old_files: '' },
          { name: '상세이미지11', code: 'img_file10', files: '', old_files: '' }
        ],

        // 29CM
        P015: [
          { name: '상품이미지1', code: 'img_file0', files: '', old_files: '' },
          { name: '상품이미지2', code: 'img_file1', files: '', old_files: '' },
          { name: '상품이미지3', code: 'img_file2', files: '', old_files: '' },
          { name: '상품이미지4', code: 'img_file3', files: '', old_files: '' },
          { name: '상품이미지5', code: 'img_file4', files: '', old_files: '' },
          { name: '상품이미지6', code: 'img_file5', files: '', old_files: '' },
          { name: '부가이미지(화이트)', code: 'img_file6', files: '', old_files: '' },
          { name: '부가이미지(그레이)', code: 'img_file7', files: '', old_files: '' },
        ],

        // 에이블리
        P016: [
          { name: '대표이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '커버이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '커버이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '커버이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '커버이미지4', code: 'img_file4', files: '', old_files: '' },
        ],

        // 지그재그
        P018: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가 이미지10', code: 'img_file10', files: '', old_files: '' }
        ],

        // SSG오픈마켓
        A076: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' }
        ],
        // W컨셉
        B957: [
          { name: '기본 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '정사각형 이미지 ', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지 1', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지 2', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지 3', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지 4', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지 5', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지 6', code: 'img_file7', files: '', old_files: '' }
        ],
        // 롯데슈퍼
        P020: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
        ],
        // 머스트잇
        B669: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // 이지웰몰
        B325: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' }
        ],
        // 전자랜드
        A150: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
        ],
        // 하이마트
        B696: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
        ],
        // GS프레시
        P028: [
          { name: '기본 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '기타 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '기타 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '기타 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '기타 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '기타 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '기타 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '기타 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '기타 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '기타 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // K쇼핑
        B690: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' }
        ],
        // 컴퓨존
        P037: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' }
        ],
        // 한샘
        A124: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가 이미지10', code: 'img_file10', files: '', old_files: '' },
        ],
        // 발란
        P046: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' }
        ],
        // 롯데묘미
        P039: [
          { name: '대표썸네일', code: 'img_file0', files: '', old_files: '' },
          { name: '썸네일이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '썸네일이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '썸네일이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '썸네일이미지4', code: 'img_file4', files: '', old_files: '' },
        ],
        // 공구마켓/할인중독/심쿵할인
        A530: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
        ],
        // 한섬EQL
        P044: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가 이미지10', code: 'img_file10', files: '', old_files: '' },
        ],
        // 메이크샵
        B118: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가 이미지10', code: 'img_file10', files: '', old_files: '' },
        ],
        // 뚝딱
        P048: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // 알렛츠몰
        P050: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가 이미지10', code: 'img_file10', files: '', old_files: '' },
        ],
        // 배민상회
        A170: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' }
        ],
        // 에픽카
        P051: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' }
        ],
        // 마켓컬리
        A171: [
          { name: '썸네일 이미지', code: 'img_file0', files: '', old_files: '' },
        ],
        // 샵바이 파트너
        A536: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // 샵바이(캐시딜)
        A176: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // 샵바이
        A537: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // CJ 온스타일
        A004: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가 이미지10', code: 'img_file10', files: '', old_files: '' }
        ],
        // 더우리샵
        P053: [
          { name: '대표 큰 이미지', code: 'img_file0', files: '', old_files: '', tooltip: '1,000×1,000px, 1 MB이하, jpg, png, gif 형식만 가능' },
          { name: '다른 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '다른 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '다른 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '다른 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '다른 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '다른 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '다른 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '다른 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '다른 이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '다른 이미지10', code: 'img_file10', files: '', old_files: '' }
        ],
        // 티몬월드
        A175: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가 이미지10', code: 'img_file10', files: '', old_files: '' },
          { name: '추가 이미지11', code: 'img_file11', files: '', old_files: '' },
        ],
        // 위메프플러스
        A178: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가 이미지10', code: 'img_file10', files: '', old_files: '' },
          { name: '추가 이미지11', code: 'img_file11', files: '', old_files: '' },
        ],
        // 인팍쇼핑
        A179: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가 이미지10', code: 'img_file10', files: '', old_files: '' },
          { name: '추가 이미지11', code: 'img_file11', files: '', old_files: '' },
        ],
        // 올웨이즈
        P042: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '', tooltip: ' 400px*400px 이상, 1,200px*1,200px 권장, 5MB 이하, 파일 형식 JPG, JPEG, PNG' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // 퀸잇
        P055: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // 토스
        A538: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '', tooltip: '1:1 비율, jpg, png, gif 형식만 가능' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '', tooltip: '1:25 비율, jpg, png, gif 형식만 가능' },
        ],
        A093: [
          { name: '앞면', code: 'img_file0', files: '', old_files: '', tooltip: '앞면부터 순서대로 입력해 주세요.' },
          { name: '뒷면', code: 'img_file1', files: '', old_files: '' },
          { name: '실사', code: 'img_file2', files: '', old_files: '' },
          { name: '측면', code: 'img_file3', files: '', old_files: '' },
          { name: '디테일 사진', code: 'img_file4', files: '', old_files: '' },
          { name: '디테일 사진2', code: 'img_file5', files: '', old_files: '' },
        ],
        A092: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
        ],
        P056: [
          { name: '상품 사진1', code: 'img_file0', files: '', old_files: '' },
          { name: '상품 사진2', code: 'img_file1', files: '', old_files: '' },
          { name: '상품 사진3', code: 'img_file2', files: '', old_files: '' },
          { name: '상품 사진4', code: 'img_file3', files: '', old_files: '' },
          { name: '상품 사진5', code: 'img_file4', files: '', old_files: '' },
          { name: '상품 사진6', code: 'img_file5', files: '', old_files: '' },
          { name: '상품 사진7', code: 'img_file6', files: '', old_files: '' },
          { name: '상품 사진8', code: 'img_file7', files: '', old_files: '' },
          { name: '상품 사진9', code: 'img_file8', files: '', old_files: '' },
          { name: '상품 사진10', code: 'img_file9', files: '', old_files: '' },
        ],
        // 도매창고
        B668: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // 도매의신
        A527: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // 식스샵프로
        P058: [
          { name: '이미지1', code: 'img_file0', files: '', old_files: '' },
          { name: '이미지2', code: 'img_file1', files: '', old_files: '' },
          { name: '이미지3', code: 'img_file2', files: '', old_files: '' },
          { name: '이미지4', code: 'img_file3', files: '', old_files: '' },
          { name: '이미지5', code: 'img_file4', files: '', old_files: '' },
          { name: '이미지6', code: 'img_file5', files: '', old_files: '' },
          { name: '이미지7', code: 'img_file6', files: '', old_files: '' },
          { name: '이미지8', code: 'img_file7', files: '', old_files: '' },
          { name: '이미지9', code: 'img_file8', files: '', old_files: '' },
          { name: '이미지10', code: 'img_file9', files: '', old_files: '' },
          { name: '이미지11', code: 'img_file10', files: '', old_files: '' },
        ],
        // SSF
        P054: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가 이미지10', code: 'img_file10', files: '', old_files: '' },
        ],
        // 오너클랜
        B730: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // 셀러허브
        A526: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
        ],
        // 텐바이텐
        B041: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
          { name: '추가 이미지10', code: 'img_file10', files: '', old_files: '' },
        ],
        // 멸치쇼핑(일반)
        P045: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // 멸치쇼핑(공급가)
        P047: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
        // 도매꾹
        B693: [
          { name: '대표 이미지', code: 'img_file0', files: '', old_files: '' },
          { name: '추가 이미지1', code: 'img_file1', files: '', old_files: '' },
          { name: '추가 이미지2', code: 'img_file2', files: '', old_files: '' },
          { name: '추가 이미지3', code: 'img_file3', files: '', old_files: '' },
          { name: '추가 이미지4', code: 'img_file4', files: '', old_files: '' },
          { name: '추가 이미지5', code: 'img_file5', files: '', old_files: '' },
          { name: '추가 이미지6', code: 'img_file6', files: '', old_files: '' },
          { name: '추가 이미지7', code: 'img_file7', files: '', old_files: '' },
          { name: '추가 이미지8', code: 'img_file8', files: '', old_files: '' },
          { name: '추가 이미지9', code: 'img_file9', files: '', old_files: '' },
        ],
      },

      // 체크플래그
      flagFiled: {
        esmMatch: false,         // ESM 상품등록 매칭 전체선택
        esmAdd: false,           // ESM 상품등록 신규 전체선택
        esmOpt: false,           // ESM 옵션 테이블 전체선택
        aliexpressMatch: false,  // ali express 상품등록 매칭 전체선택
        aliexpressAdd: false,    // ali express 상품등록 신규 전체선택
        aliexpressOpt: false,    // ali express 옵션 테이블 전체선택
        alibabaMatch: false,     // alibaba 상품등록 매칭 전체선택
        alibabaAdd: false,       // alibaba 상품등록 신규 전체선택
        alibabaOpt: false,       // alibaba 옵션 테이블 전체선택
        cpangMatch: false,       // 쿠팡 상품등록 매칭 전체선택
        cpangOpt: false,         // 쿠팡옵션 테이블전체 선택
        wmpMatch: false,         // 위메프 상품등록 매칭 전체선택
        wmpOpt: false,           // 위메프 테이블전체 선택
        hplusMatch: false,       // 홈플러스 상품등록 매칭 전체선택
        hplusOpt: false,         // 홈플러스 테이블전체 선택
        addOpt: false,           // 추가구매 옵션 전체선택
        addTable: false,         // 추가구매 옵션 테이블 전체선택
        addOptTable: false,      // 추가구매 옵션 테이블
        submit: false,           // 저장 버튼 활성화 여부
        categorySearch: false    // 카테고리 자동매칭 검색중 표시
      },

      // 옵션설정 매칭된 SKU상품 리스트
      prodOpt: {
        name: '' // 상품명
        , promotion: '' // 홍보문구
        , matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' }]       // 옵션없음 SKU상품 리스트
        , esm_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' }]    // 옵션없음 ESM SKU상품 리스트
        , std_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' }]    // 옵션없음 std SKU상품 리스트
        , cpang_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' }]   // 옵션없음 쿠팡 SKU상품 리스트
        , rocket_matchStockList: [{ prod_img: '/assets/images/upload.png', coupang: { sku_cd: '', prod_status: '정상' }, rocket: { cnt_limit: 0, sku_cd: '', prod_status: '정상' } }]   // 옵션없음 로켓그로스 SKU상품 리스트
        , lotte_on_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' }]   // 옵션없음 롯데온 SKU상품 리스트
        , wmp_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' }]     // 옵션없음 위메프 SKU상품 리스트
        , hplus_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' }]   // 옵션없음 홈플러스 SKU상품 리스트
        , galleria_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' }]   // 옵션없음 갤러리아 SKU상품 리스트
        , naver_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' }]      // 옵션없음 스마트스토어 간편옵션 SKU상품 리스트
        , aliexpress_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' }] // 옵션없음 알리 익스프레스 SKU상품 리스트
        , alibaba_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' }]    // 옵션없음 알리바바 SKU상품 리스트
        , addStockList: [{ skuAuto: true, skuCheck: false, sku_cd: '' }]                 // 신규생성 SKU상품 리스트
        , options_table: []                                                          // 조합형, 독립형 생성 테이블
        , esm_options_table: []                                                      // esm 조합형 테이블
        , std_options_table: []                                                      // std 조합형 테이블
        , cpang_options_table: []                                                    // 쿠팡 조합형 테이블
        , rocket_options_table: []                                                   // 로켓그로스 조합형 테이블
        // , lotte_on_options_table: []                                                    // 롯데온 조합형 테이블
        , wmp_options_table: []                                                      // 위메프 조합형 테이블
        , hplus_options_table: []                                                    // 홈플러스 조합형 테이블
        , naver_options_table: []                                                    // 스마트스토어 간편옵션 조합형 테이블
        , esm_input_name: []                                                         // esm 입력형 옵션 데이터
        , esm_input_list: []
        , std_input_name: [{ insert_opt: '' }]                                                         // esm 입력형 옵션 리스트
        , cpang_insert_opt_arr: [{ insert_opt: '' }]                                   // 위메프 입력형 옵션 정보 배열
        , lotte_on_insert_opt_arr: [{ insert_opt: '' }]                                   // 롯데온 입력형 옵션 정보 배열
        , wmp_insert_opt_arr: [{ insert_opt: '' }]                                   // 위메프 입력형 옵션 정보 배열
        , hplus_insert_opt_arr: [{ insert_opt: '' }]                                 // 홈플러스 입력형 옵션 정보 배열
        , max_name_length: 50
        , max_promotion_length: 50
        , cnt_limit_all: ''
      },

      // 일반 옵션명, 옵션내용
      default_opt: [
        { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [], custom_able: false },
        { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [], custom_able: false },
        { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [], custom_able: false }
      ],

      //쇼핑몰 상품 등록 속성
      checkedField: {
        A112: false,            // 11번가 단일 상품 등록 체크여부
        ESM: false,             // ESM 등록 체크 여부
        WMP: false,             // 위메프2.0 옵션 사용 여부
        HPLUS: true,
        GALLERIA: true,
        // LOTTE_ON: false,
        NAVER: false,
        esm_opt_size: false,
        esm_opt_color: false,
        esm_etc_size: false,
        esm_etc_color: false,
        esm_opt_etc: false,
        batchType: 'sale_cnt',
        esmBatchType: 'sale_cnt',
        cpangBatchType: 'sale_cnt',
        wmpBatchType: 'sale_cnt',
        hplusBatchType: 'sale_cnt',
        galleriaBatchType: 'sale_cnt',
        naverBatchType: 'sale_cnt',
        alibabaBatchType: 'sale_cnt',
        aliexpressBatchType: 'sale_cnt',
        rocketBatchType: 'sale_cnt',
        match_type: '매칭',       // SKU상품 매칭 기본값 설정 2018-03-27 rony
        match_type2: '매칭',      // 추가구매옵션 SKU상품 매칭 기본값 설정 2018-03-27 rony
        smileProd: false,        // 스마일배송 상품 여부
        ALIEXPRESS: false,
        ALIBABA: false,
      },

      // 활성화 여부
      activatedField: {
        A112: false,            // 11번가 단일 상품 활성화 여부
        ESM: false,             // ESM 등록 활성화 여부
        CPANG: false,           // 쿠팡 활성화 여부
        ROCKET: false,          // 로켓그로스 활성화 여부
        WMP: false,             // 위메프2.0 활성화 여부
        HPLUS: false,           // 홈플러스 활성화 여부
        // LOTTE_ON: false,     // 롯데ON 활성화 여부
        NAVER: false,           // 롯데ON 활성화 여부
        esm_opt_size: false,		// ESM 사이즈 지원 여부
        esm_opt_color: false,		// ESM 색상 지원 여부
        std_opt_size: false,		// 11번가 사이즈 지원 여부
        std_opt_color: false,		// 11번가 색상 지원 여부
        esm_opt: false,				  // ESM 옵션 지원 안하면 true
        aliexpress_opt: false,           // 알리 익스프레스 옵션 지원 안하면 true
        aliexpress_opt_not_color1: true, // 알리 익스프레스 색상 제외 지원 여부 (색상은 지원하고 나머지는 수정지원 안함)
        aliexpress_opt_not_color2: true, // 알리 익스프레스 색상 제외 지원 여부 (색상은 지원하고 나머지는 수정지원 안함)
        aliexpress_opt_not_color3: true, // 알리 익스프레스 색상 제외 지원 여부 (색상은 지원하고 나머지는 수정지원 안함)
        alibaba_opt: false,           // 알리바바 옵션 지원 안하면 true
        alibaba_opt_not_color1: true, // 알리바바 색상 제외 지원 여부 (색상은 지원하고 나머지는 수정지원 안함)
        alibaba_opt_not_color2: true, // 알리바바 색상 제외 지원 여부 (색상은 지원하고 나머지는 수정지원 안함)
        alibaba_opt_not_color3: true, // 알리바바 색상 제외 지원 여부 (색상은 지원하고 나머지는 수정지원 안함)
        std_opt: false,				  // ESM 옵션 지원 안하면 true
        opt_size: false,				// 단일상품 사이즈 옵션 활성화 여부
        opt_color: false,			  // 단일상품 색상 옵션 활성화 여부
        smileProd: false,       // 스마일배송 상품 활성화 여부
        ALIEXPRESS: false,      // 알리 익스프레스 활성화 여부
        ALIBABA: false          // 알리바바 활성화 여부
      },

      // 기타상세설명
      detailDescEtc: [
        { name: '광고/홍보', model: 'detail_desc_ad' },
        { name: '추가 상세설명', model: 'detail_desc_add' },
        { name: '영문 상세설명', model: 'detail_desc_eng' },
        { name: '중문 상세설명', model: 'detail_desc_chn' },
        { name: '일문 상세설명', model: 'detail_desc_jpn' },
      ],

      naverColorOptStyle: [ // 스마트스토어 간편옵션 css 값
        { AttributeValueId: '10031739', styleId: 'c-red' },
        { AttributeValueId: '10671461', styleId: 'c-burgundy' },
        { AttributeValueId: '10031736', styleId: 'c-orange' },
        { AttributeValueId: '10197443', styleId: 'c-gold' },
        { AttributeValueId: '10031742', styleId: 'c-yellow' },
        { AttributeValueId: '10669310', styleId: 'c-lime' },
        { AttributeValueId: '10533144', styleId: 'c-green' },
        { AttributeValueId: '10031743', styleId: 'c-khaki' },
        { AttributeValueId: '10531885', styleId: 'c-mint' },
        { AttributeValueId: '10669048', styleId: 'c-sky' },
        { AttributeValueId: '10031731', styleId: 'c-blue' },
        { AttributeValueId: '10031732', styleId: 'c-navy' },
        { AttributeValueId: '10031738', styleId: 'c-purple' },
        { AttributeValueId: '10545495', styleId: 'c-violet' },
        { AttributeValueId: '10241290', styleId: 'c-pink' },
        { AttributeValueId: '10411187', styleId: 'c-hotpink' },
        { AttributeValueId: '10241293', styleId: 'c-beige' },
        { AttributeValueId: '10696336', styleId: 'c-kamel' },
        { AttributeValueId: '10531874', styleId: 'c-brown' },
        { AttributeValueId: '10531869', styleId: 'c-white' },
        { AttributeValueId: '10531875', styleId: 'c-ivory' },
        { AttributeValueId: '10031729', styleId: 'c-silver' },
        { AttributeValueId: '10040013', styleId: 'c-gray' },
        { AttributeValueId: '10679194', styleId: 'c-charcoal' },
        { AttributeValueId: '10031727', styleId: 'c-black' },
        { AttributeValueId: '10674946', styleId: 'c-multi' },
        { AttributeValueId: '10199545', styleId: 'c-transparent' },
      ],

      templateExcelFieldList: [
        { header: '템플릿코드', key: 'template_no', select: true },
        { header: '쇼핑몰계정', key: 'shop', select: true },
        { header: '템플릿명', key: 'template_name', select: true },
      ],

      // 온라인 상품명 체크
      // 2020-01-23 Boris
      getSaleNameLimiter: function (shop_cd) {
        return siteInfo[shop_cd].limiter;
      },

      //프로모션 상품명 입력규칙(신)
      promotionProductName: {
        A112: { name: '프로모션용 상품명', str: 28 },
        A112_single: { name: '프로모션용 상품명', str: 28 },
        A001_single: { name: '프로모션용 상품명', byte: 100 },
        A006_single: { name: '프로모션용 상품명', byte: 100 },
        A076: { name: '프로모션용 상품명', str: 50 },
        P051: { name: 'OEM 부품번호', byte: 100 },
        A536: { name: '홍보문구', str: 100 },
        A176: { name: '홍보문구', str: 100 },
        A537: { name: '홍보문구', str: 100 },
      },

      trnslFileds: {
        shop_sale_name: '상품명',
        options: '옵션',
        model: '모델명',
        brand: '브랜드',
        made: '제조사',
        detail_desc: '상세설명'
      },

      /**
     * 프로모션명 타이틀
     */
      getPromotionTitle: (shop_cd, checkESM = null) => {
        if (checkESM == null ? ['A001', 'A006'].includes(shop_cd) : checkESM) {
          return '프로모션 상품명';
        } else {
          switch (shop_cd) {
            case 'A077':
              return '이벤트';
            case 'A112':
            case 'A536':
            case 'A537':
            case 'A176':
              return '홍보문구';
            case 'B579':
              return '서브 상품명';
            case 'P051':
              return 'OEM 부품 번호';
            default:
              return '프로모션 상품명';
          }
        }
      },

      /**
       * 입력형 옵션 길이 제한
       */
      inputOptLength: function (shop_cd, std_ol_yn) {
        let max = { type: 'byte', length: 40 };

        switch (shop_cd) {
          case 'P016': max = { type: 'str', length: 100 }; break;
          case 'B719': max = std_ol_yn ? { type: 'str', length: 15 } : { type: 'byte', length: 40 }; break;
          case 'A112': max = std_ol_yn ? { type: 'byte', length: 20 } : { type: 'byte', length: 40 }; break;
        }

        return max;

      },

      /**
       * 홍보문구 입력 규칙(구)
       */
      promotionNameRole: function (shop_cd) {
        let role = 40;

        switch (shop_cd) {
          case 'A001':
          case 'A006': role = 50; break;
          case 'A027': role = 80; break;
          case 'A077': role = 100; break;
        }

        return role;

      },

      /**
       * 새로운 쇼핑몰 추가
       */
      new_shop: function(idx, row, sub_img_objects, sub_img_box, shop_promotion_name_role) {
        const new_site_info = {
          idx: idx,
          shop_name: row.shop_name,
          shop_id: row.shop_id,
          shop_pwd: row.shop_pwd,
          seller_nick: row.seller_nick,
          etc1: row.etc1,
          etc2: row.etc2,
          etc3: row.etc3,
          etc4: row.etc4,
          etc5: row.etc5,
          etc6: row.etc6,
          etc7: row.etc7,
          sub_img_name: [],
          sub_img_objects: sub_img_objects,
          sub_img_box: sub_img_box,
          esm_img_objects: [],
          esm_img_name: [],
          html_editor: '',
          channel_info: {},
          shop_cd: row.shop_cd,
          pa_shop_cd: row.pa_shop_cd,
          domain: row.domain,
          selected: true,
          shop_sale_name: '',
          shop_promotion_name: '',
          shop_promotion_name_role: shop_promotion_name_role,
          prod_stock: '',
          sale_price: null,
          supply_price: null,
          consumer_price: '',
          cost_price: '',
          street_price: '',
          set_info: {},
          set_info_add_img: {},
          template_no: '',
          template_name: '',
          country: row.country,
          match_cate_name: '',
          match_cate_code: '',
          model_name: '',
          brand_name: '',
          maker_name: '',
          search_model_yn: 0,
          search_brand_yn: 0,
          search_maker_yn: 0,
          detail_desc_img_yn: 0,
          desc_img_url: '',
          desc_img_limit: solData.descImgShopMap[row.pa_shop_cd],
          options: {
            optionList: [],
            prodList: [],
            addOptList: [],
            singleProdList: []
          },
          std_ol_yn: 0,
          CatalogId: 'null',
          ctlgNo: 'null'
        }
        ;

        if (row.pa_shop_cd === 'A112') {
          new_site_info.cardview_img = {
            name: '카드뷰이미지', code: 'cardview_img_files0', files: '', old_files: ''
          };
          new_site_info.list_img = {
            name: '리스트이미지', code: 'list_img_files0', files: '', old_files: ''
          };
          new_site_info.mobile_detail_img = {
            name: '모바일상세설명이미지', code: 'mobile_detail_img0', files: '', old_files: ''
          };
        }
        if (row.pa_shop_cd === 'A011') {
          new_site_info.mobile_img = {
            name: '모바일', code: 'mobile_img_files0', files: '', old_files: ''
          };
        }
        if (row.pa_shop_cd === 'A012') {
          new_site_info.width_img = {
            name: '가로이미지', code: 'width_img_files0', files: '', old_files: ''
          };
          new_site_info.exist_img = {
            name: '실품표시사항', code: 'exist_img_files0', files: '', old_files: ''
          };
        }

        // 단일상품 : 옥션, 지마켓, 홈플러스, 위메프
        if (['A001', 'A006', 'B502', 'B719'].includes(row.pa_shop_cd)) {
          new_site_info.std_ol_yn = 1;
        }

        return new_site_info;
      },

      /**
       * 옵션 순서 변경 함수
       * 2019-02-22 lucas
       *
       * [0 ~ 7]까지의 인덱스가 있는 옵션에서 [0, 1, 3, 5] 인덱스를 체크 후 옵션을 위로 올린다고 하자.
       * 끝으로 올리는 경우 체크한 인덱스 [0, 1, 3, 5] 체크하지 않은 인덱스를 [2, 4, 6, 7] 이어붙이면 된다. -> newIdx = [0, 1, 3, 5, 2, 4, 6, 7]
       * 한 칸 올리는 경우 체크한 인덱스에 빈 칸에 -1(움직일 수 있는 칸이라는 의미)을 채운다. checkedIdx = [0, 1, -1, 3, -1, 5, -1, -1]
       * 새로운 리스트에 변경된 리스트를 채워넣는다. newIdx = []
       * checkedIdx의 첫 번째 원소부터 순서대로 확인을 하는데 -1이 아닌 숫자라면 움직일 수 없는 숫자라는 뜻이라서 newIdx에 그대로 넣는다.
       * newIdx = [0, 1]
       * 다음 원소인 checkedIdx[2] = -1을 체크하는데 다음 원소인 checkedIdx[3] = 3(-1이 아닌 값)를 같이 체크한다. 3이 왼쪽으로 한 칸 옮겨져야 하므로
       * newIdx에 checkedIdx[3]을 push하고 checkedIdx[3]은 체크박스가 옮겨져 빈 칸을 의미하는 -1로 바꿔준다. -> checkedIdx = [0, 1, -1, -1, -1, 5, -1, -1]
       * checkedIdx[3] = -1, checkedIdx[4] = -1 -> 한 칸 옮길 인덱스가 없다는 뜻이므로 uncheckedIdx에서 첫 번째 원소를 빼서 넣어준다.
       * -> newIdx = [0, 1, 3, 2], checkedIdx = [0, 1, -1, -1, -1, 5, -1, -1]
       * 이와 같은 방식으로 옮기면 newIdx = [0, 1, 3, 2, 5, 4, 6, 7]과 같이 정렬이 된다.
       *
       * upward : 옵션을 아래로 내릴 때는 순서를 변경하는 인덱스들을 reverse한 후 위로 올릴 때와 같은 로직을 돌린 후 결과 인덱스를 reverse해주면 된다.
       * @param {*} obj
       */
      optMoveSet: function (obj) {
        const opt_table = angular.copy(obj.opt_table);
        const upward = obj.upward;
        const position = obj.position;
        const result = { state: true, data: [] };
        let newIdx = [];
        const checkedIdx = [];
        const uncheckedIdx = [];
        const opt_length = opt_table.length;
        let changed = false;

        _.forEach(opt_table, function (opt, idx) {
          if (opt.checked) {
            checkedIdx.push(idx);
          } else {
            uncheckedIdx.push(idx);
            if (position !== 'end') {
              checkedIdx.push(-1);
            }
          }
        });

        if (uncheckedIdx.length === opt_length) {
          commonSVC.showMessage('실패', '선택항목이 없습니다.');

          return { state: false };
        }

        if (!upward) {
          checkedIdx.reverse();
          uncheckedIdx.reverse();
        }

        if (position === 'end') {
          newIdx = _.concat(checkedIdx, uncheckedIdx);
        } else {
          uncheckedIdx.reverse();
          for (let i = 0; i < opt_length; i++) {
            if (checkedIdx[i] !== -1) {
              newIdx.push(checkedIdx[i]);
            } else {
              if (checkedIdx[i + 1] === -1 || i === opt_length - 1) {
                newIdx.push(uncheckedIdx.pop());
              } else {
                newIdx.push(checkedIdx[i + 1]);
                checkedIdx[i + 1] = -1;
              }
            }
          }
        }

        if (!upward) {
          newIdx.reverse();
        }

        _.forEach(newIdx, function (value, idx) {
          if (value !== idx) {
            changed = true;

            return false;
          }
        });

        if (!changed) {
          commonSVC.showMessage('실패', '이동할 수 있는 항목이 없습니다.');

          return { state: false };
        }

        _.forEach(newIdx, function (value) {
          result.data.push(opt_table[value]);
        });

        return result;
      },

      /**
       * options_table 옵션값 코드 세팅
       * @param {options_table}
       * @param {opt}
       * @param {pa_shop_cd}
       */
      setOptionCode: function(options_table, opt, pa_shop_cd) {
        // 알리바바 직접입력 옵션 저장용 배열
        const alibabaDirectOpts = [];

        _.forEach(options_table, function(option) {
          for (var i = 1; i < 3 + +(['A001', 'A006', 'A112', 'A092'].includes(pa_shop_cd)); i++) {
            if (option[`name${i}`] && option[`attr${i}`] && (['A001', 'A006', 'A112', 'A092'].includes(pa_shop_cd) || !option[`attr${i}_cd`])) {
              const find_opt = _.find(opt, function(opt) {
                if (pa_shop_cd === 'A112') {
                  return opt.name == option[`name${i}`];
                } else {
                  return opt.name == option[`name${i}`] && opt.name_cd == option[`name${i}_cd`];
                }
              });

              if (find_opt) {
                // 11번가의 경우  N형 옵션의 수정 처리를 지원하기 위해 별도 처리함
                const cateOptAttrs = find_opt.cateOpAttrList || find_opt.cateOpAttr;
                let find_opt_name;

                if (Array.isArray(cateOptAttrs)) {
                  find_opt_name = cateOptAttrs.find(function(cateOpt) {
                    if (pa_shop_cd === 'A112') {
                      return (cateOpt.stdOptValNm) === (option[`attr${i}_title`] || option[`attr${i}`]).replace(/[0-9]/g, '');
                    } else if (['A001', 'A006'].includes(pa_shop_cd)) {
                      return (cateOpt.ObjOptEssenName || cateOpt.stdOptValNm)?.trim() == (option[`attr${i}_title`] || option[`attr${i}`]);
                    } else {
                      return (cateOpt.ObjOptEssenName || cateOpt.stdOptValNm)?.trim() == option[`attr${i}`];
                    }
                  });
                }

                if (find_opt_name) {
                  option[`attr${i}_cd`] = find_opt_name.ObjOptEssenNo || find_opt_name.ObjOptClaseEssenNo || find_opt_name.stdOptValNo;
                } else if (pa_shop_cd === 'A092') {
                  if (alibabaDirectOpts.includes(option[`attr${i}`])) {
                    option[`attr${i}_cd`] = `-${alibabaDirectOpts.indexOf(option[`attr${i}`]) + 1}`;
                  } else {
                    alibabaDirectOpts.push(option[`attr${i}`]);
                    option[`attr${i}_cd`] = `-${alibabaDirectOpts.length}`;
                  }
                } else {
                  option[`attr${i}_cd`] = 0;
                }
              }
            }
          }
        });
      },

      /**
       * 독립형 옵션인경우 options_table 옵션값 코드 세팅
       */
      setIndependentOptionCode: function (options_table, opt) {
        options_table.map(option => {
          const find_opt = opt.find(o => o.name === option.name);

          if (find_opt) {
            option.attr1_cd = find_opt.name_cd;

            const find_opt_attr = find_opt.cateOpAttr.find(o => o.ObjOptEssenName?.trim() === option.attr);

            if (find_opt_attr) {
              option.attr2_cd = find_opt_attr.ObjOptEssenNo;
            } else {
              option.attr2_cd = 0;
            }
          }
        });
      },

      /**
       * 옵션 테이블을 조합을 태우지 않고 직접 출력
       *
       */
      initOptionsTable: function (data, type, opt_sub_table) {
        const op_table = [];

        for (let k = 0; k < opt_sub_table.length; k++) {
          const pushData = {
            name1: data.opt[0] ? data.opt[0].name || data.opt[0].attributeTypeName : '',
            name2: data.opt[1] ? data.opt[1].name || data.opt[1].attributeTypeName : '',
            name3: data.opt[2] ? data.opt[2].name || data.opt[2].attributeTypeName : '',
            name1_cd: data.opt[0] ? data.opt[0].name_cd : '',
            name2_cd: data.opt[1] ? data.opt[1].name_cd : '',
            name3_cd: data.opt[2] ? data.opt[2].name_cd : '',
            attr1_cd: 0,
            attr2_cd: 0,
            model_no: opt_sub_table[k].model_no || null,
            skuCheck: true,
            prod_img: opt_sub_table[k].prod_img || '/assets/images/upload.png',
            opt_img: opt_sub_table[k].opt_img,
            prod_no: opt_sub_table[k].prod_no,
            sku_cd: opt_sub_table[k].sku_cd,
            opt_add_price: opt_sub_table[k].opt_add_price || 0,
            pack_unit: opt_sub_table[k].pack_unit,
            cnt_limit: data.std_ol_yn === 2 ? 99999 : opt_sub_table[k].sale_opt_cnt_limit,
            A001_cnt_limit: opt_sub_table[k].A001_cnt_limit || 0,
            A006_cnt_limit: opt_sub_table[k].A006_cnt_limit || 0,
            stock_cnt: opt_sub_table[k].stock_cnt,
            safe_stock: opt_sub_table[k].stock_cnt_safe || data.safe_stock_default,
            depot_no: opt_sub_table[k].depot_no,
            depot_name: opt_sub_table[k].depot_name,
            sale_opt_seq: opt_sub_table[k].sale_opt_seq,
            prod_weight: opt_sub_table[k].opt_weight,
            prod_status: opt_sub_table[k].opt_status || '정상',
            set_no: opt_sub_table[k].set_no,
            set_cd: opt_sub_table[k].set_cd,
            set_prod_list: opt_sub_table[k].set_prod_list,
            prod_name: opt_sub_table[k].prod_name,
            stock_cd: opt_sub_table[k].stock_cd,
            opt_sale_cd: opt_sub_table[k].opt_sale_cd,
            barcode: opt_sub_table[k].barcode,
          };

          opt_sub_table[k].opt_name1_cd = (data.opt[0]?.shop_cd === 'A112' && data.opt[0]?.typeCd !== 'N') ? '0' : opt_sub_table[k]?.opt_name1_cd;
          opt_sub_table[k].opt_name2_cd = (data.opt[1]?.shop_cd === 'A112' && data.opt[1]?.typeCd !== 'N') ? '0' : opt_sub_table[k]?.opt_name2_cd;
          opt_sub_table[k].opt_name3_cd = (data.opt[2]?.shop_cd === 'A112' && data.opt[2]?.typeCd !== 'N') ? '0' : opt_sub_table[k]?.opt_name3_cd;

          if (type == '조합형' || type == '간편옵션') {
            pushData.attr1_cd = opt_sub_table[k].opt_name1_cd || findNameCd(data.opt, pushData.name1, opt_sub_table[k].opt_name1);
            pushData.attr2_cd = opt_sub_table[k].opt_name2_cd || findNameCd(data.opt, pushData.name2, opt_sub_table[k].opt_name2);
            pushData.attr3_cd = opt_sub_table[k].opt_name3_cd || findNameCd(data.opt, pushData.name3, opt_sub_table[k].opt_name3);

            const attr_text1 = data.opt.find(opt => opt.shop_cd === 'A112' && opt.name === pushData.name1);
            const attr_text2 = data.opt.find(opt => opt.shop_cd === 'A112' && opt.name === pushData.name2);
            const attr_text3 = data.opt.find(opt => opt.shop_cd === 'A112' && opt.name === pushData.name3);

            const attr1_unit_sub = (attr_text1 && attr_text1?.typeCd !== 'F') ? attr_text1.cateOpAttrList.find(opt => opt.stdOptValNo === opt_sub_table[k].opt_name1_cd || opt.stdOptValNo === findNameCd(data.opt, pushData.name1, opt_sub_table[k].opt_name1)) : '';
            const attr2_unit_sub = (attr_text2 && attr_text2?.typeCd !== 'F') ? attr_text2.cateOpAttrList.find(opt => opt.stdOptValNo === opt_sub_table[k].opt_name2_cd || opt.stdOptValNo === findNameCd(data.opt, pushData.name2, opt_sub_table[k].opt_name2)) : '';
            const attr3_unit_sub = (attr_text3 && attr_text3?.typeCd !== 'F') ? attr_text3.cateOpAttrList.find(opt => opt.stdOptValNo === opt_sub_table[k].opt_name3_cd || opt.stdOptValNo === findNameCd(data.opt, pushData.name3, opt_sub_table[k].opt_name3)) : '';

            const attr1_unit = attr_text1 && attr_text1.attr_text && attr_text1.attr_text.split(',')[0].replace(new RegExp(`${attr_text1.attr.split(',').join('|')}`), '') || '';
            const attr2_unit = attr_text2 && attr_text2.attr_text && attr_text2.attr_text.split(',')[0].replace(new RegExp(`${attr_text2.attr.split(',').join('|')}`), '') || '';
            const attr3_unit = attr_text3 && attr_text3.attr_text && attr_text3.attr_text.split(',')[0].replace(new RegExp(`${attr_text3.attr.split(',').join('|')}`), '') || '';

            // 조합형&독립형 옵션명 출력할때 ''값으로만 ng-if 걸려있어서 조합형 옵션명 null이면 '' 넣어줌
            // 온라인상품 그리드의 옵션명은 옵션테이블에 저장된 정보로 불어오도록 수정함 2018-06-27 rony
            pushData.attr1 = opt_sub_table[k].opt_name1 ? opt_sub_table[k].opt_name1.replace(attr1_unit, '').replace(attr1_unit_sub ? attr1_unit_sub.stdOptValNm : '', '') : '';
            pushData.attr2 = opt_sub_table[k].opt_name2 ? opt_sub_table[k].opt_name2.replace(attr2_unit, '').replace(attr2_unit_sub ? attr2_unit_sub.stdOptValNm : '', '') : '';
            pushData.attr3 = opt_sub_table[k].opt_name3 ? opt_sub_table[k].opt_name3.replace(attr3_unit, '').replace(attr3_unit_sub ? attr3_unit_sub.stdOptValNm : '', '') : '';

            pushData.attr1_title = `${pushData.attr1}${attr1_unit_sub && data.opt[0].typeCd === 'N' ? attr1_unit_sub.stdOptValNm : ''}`;
            pushData.attr2_title = `${pushData.attr2}${attr2_unit_sub && data.opt[1].typeCd === 'N' ? attr2_unit_sub.stdOptValNm : ''}`;
            pushData.attr3_title = `${pushData.attr3}${attr3_unit_sub && data.opt[2].typeCd === 'N' ? attr3_unit_sub.stdOptValNm : ''}`;

            // 수정 시 set_prod_list가 빈값으로 들어가서 처리 2018-06-19 Gargamel
            if (!pushData.set_prod_list && opt_sub_table[k].set_prod_list) {
              pushData.set_prod_list = opt_sub_table[k].set_prod_list;
            }

          } else {
            pushData.name = opt_sub_table[k].opt_name1 ? opt_sub_table[k].opt_name1 : '';
            pushData.attr = opt_sub_table[k].opt_name2 ? opt_sub_table[k].opt_name2 : '';

            pushData.attr1_cd = opt_sub_table[k].opt_name1_cd;
            pushData.attr2_cd = opt_sub_table[k].opt_name2_cd;
          }

          op_table.push(pushData);
        }

        return op_table;
      },

      /**
       * 2017-11-07 roy 새로 추가
       * 옵션 조합
       */
      setOptionsTable: function (data, type, opt_sub_table, edit) {
        const op_table = [];

        // 상품수정 시 옵션재조합을 했을 경우 데이터가 꼬이는 경우가 발생하여 수정떄는 재조합 처리 뺌 2018-05-23 Gargamel
        // 사유:옵션을 중간에 삭제했을 경우 재조합하면 삭제된 옵션이 다시 보여지는 문제가 있음
        // 해당 처리로 인해 독립형 옵션 이름이 안보여서 일단 다시 원복(다른방안 찾아서 해결해야함..) 2018-05-25 Gargamel
        //if(!edit){
        if (type == '조합형' || type == '간편옵션') {
          const opt_attr1 = data.opt[0] && data.opt[0].attr ? data.opt[0].attr.split(',') : [''];
          const opt_attr2 = data.opt[1] && data.opt[1].attr ? data.opt[1].attr.split(',') : [''];
          const opt_attr3 = data.opt[2] && data.opt[2].attr ? data.opt[2].attr.split(',') : [''];

          const opt_attr1_text = data.opt[0] && data.opt[0].attr_text ? data.opt[0].attr_text.split(',') : [''];
          const opt_attr2_text = data.opt[1] && data.opt[1].attr_text ? data.opt[1].attr_text.split(',') : [''];
          const opt_attr3_text = data.opt[2] && data.opt[2].attr_text ? data.opt[2].attr_text.split(',') : [''];

          const opt_attr1_cd = data.opt[0] && (data.opt[0].attr_cd ? data.opt[0].attr_cd.split(',') : ['']);
          const opt_attr2_cd = data.opt[1] && (data.opt[1].attr_cd ? data.opt[1].attr_cd.split(',') : ['']);
          const opt_attr3_cd = data.opt[2] && (data.opt[2].attr_cd ? data.opt[2].attr_cd.split(',') : ['']);

          const sortDatas = [
            opt_attr1.map((v, index) => {
              return {
                name: v,
                title: opt_attr1_text[index] || '',
                code: opt_attr1_cd && opt_attr1_cd[index] || ''
              };
            }),
            opt_attr2.map((v, index) => {
              return {
                name: v,
                title: opt_attr2_text[index] || '',
                code: opt_attr2_cd && opt_attr2_cd[index] || ''
              };
            }),
            opt_attr3.map((v, index) => {
              return {
                name: v,
                title: opt_attr3_text[index] || '',
                code: opt_attr3_cd && opt_attr3_cd[index] || ''
              };
            })
          ];

          var opt_name1 = '';
          var opt_name2 = '';
          var opt_name3 = '';

          data.options_table = [];
          var k = 0;

          for (var i = 0; i < sortDatas[0].length; i++) {
            for (var j = 0; j < sortDatas[1].length; j++) {
              for (let l = 0; l < sortDatas[2].length; l++) {
                if (!(sortDatas[0][i].name == '' && sortDatas[1][i].name == '' && sortDatas[2][i].name == ''
									&& data.opt[0].name == '' && data.opt[1].name == '' && data.opt[2].name == '')) {
                  // --------------------------
                  // 데이터가 순차적이 아니고 섞여 있는경우 엉뚱한 데이터가 매칭되어 노출될수 있으므로 조건으로 데이터 필터링 함. 2018-03-30 rony
                  // matchAttr1 = opt_attr1[i];
                  // matchAttr2 = _.isUndefined(opt_attr2[j]) ? '' : opt_attr2[j];
                  // matchAttr3 = _.isUndefined(opt_attr3[l]) ? '' : opt_attr3[l];

                  // var checkOlShopOptProd = _.filter(opt_sub_table, function(o){
                  //   return o.opt_name1 == matchAttr1 && o.opt_name2 == matchAttr2 && o.opt_name3 == matchAttr3;
                  // });
                  // if(checkOlShopOptProd.length < 2) continue;
                  // -------------------------

                  const pushData = {
                    name1: data.opt[0] ? data.opt[0].name || data.opt[0].attributeTypeName : '',
                    name2: data.opt[1] ? data.opt[1].name || data.opt[1].attributeTypeName : '',
                    name3: data.opt[2] ? data.opt[2].name || data.opt[2].attributeTypeName : '',
                    name1_cd: data.opt[0] ? data.opt[0].name_cd : '',
                    name2_cd: data.opt[1] ? data.opt[1].name_cd : '',
                    name3_cd: data.opt[2] ? data.opt[2].name_cd : '',
                    attr1: sortDatas[0][i].name,
                    attr2: sortDatas[1][j].name,
                    attr3: sortDatas[2][l].name,
                    attr1_title: sortDatas[0][i].title,
                    attr2_title: sortDatas[1][j].title,
                    attr3_title: sortDatas[2][l].title,
                    pack_unit: 1,
                    opt_add_price: 0,
                    attr1_cd: sortDatas[0][i] && sortDatas[0][i].code || 0,
                    attr2_cd: sortDatas[1][j] && sortDatas[1][j].code || 0,
                    attr3_cd: sortDatas[2][l] && sortDatas[2][l].code || 0,
                    prod_status: '정상',
                    shop_cd: data.opt[0].shop_cd,
                  };

                  if (l === 0) {
                    opt_name1 = pushData.name1;
                    opt_name2 = pushData.name2;
                    opt_name3 = pushData.name3;
                  }

                  //ESM 추천옵션인 경우 코드 기재를 위해 추가
                  //2017-12-01 roy
                  //추천 옵션은 최대 2개까지만 지원
                  if (data.opt[0] && data.opt[0].checked && !['A524', 'A112'].includes(data.opt[0].shop_cd)) {
                    //직접 입력한 경우를 위해 0으로 초기화
                    pushData.attr1_cd = 0;
                    _.each(data.opt[0].cateOpAttr, function (attr) {
                      if (pushData.attr1 == attr.ObjOptEssenName || pushData.attr1 == attr.ObjOptEssenNameEn || pushData.attr1 == attr.ObjOptEssenNameEngKor) {
                        pushData.attr1_cd = attr.ObjOptEssenNo;
                      }
                    });
                  }
                  if (data.opt[1] && data.opt[1].checked && !['A524', 'A112'].includes(data.opt[0].shop_cd)) {
                    //직접 입력한 경우를 위해 0으로 초기화
                    pushData.attr2_cd = 0;
                    _.each(data.opt[1].cateOpAttr, function (attr) {
                      if (pushData.attr2 == attr.ObjOptEssenName) {
                        pushData.attr2_cd = attr.ObjOptEssenNo;
                      }
                    });
                  }

                  for (let i = 0; i < 3; i++) {
                    if (data.opt[i] && data.opt[i].checked && data.opt[i].shop_cd === 'A112') {
                      pushData[`attr${i + 1}_cd`] = 0;

                      // 11번가 > N형 옵션 처리
                      if (data.opt[i].typeCd === 'N') {
                        pushData[`attr${i + 1}_cd`] = data.opt[i].cateOpAttrList.find(({ stdOptValNm }) => {
                          // 옵션값이 여러개 있는경우 빨강,파랑과 같이 콤마가 붙어 들어오기 때문에 관련 처리 필요.
                          const [ attrText ] = data.opt[i].attr_text.split(',');
                          const [ attr ] = data.opt[i].attr.split(',');

                          return stdOptValNm === attrText.trim().replace(attr.trim(), '');
                        })?.stdOptValNo;
                      }
                    }
                  }

                  // 추가 끝
                  if (opt_sub_table && opt_sub_table[k]) {
                    pushData.skuCheck = true;
                    pushData.prod_img = opt_sub_table[k].prod_img || '/assets/images/upload.png';
                    pushData.opt_img = opt_sub_table[k].opt_img;
                    pushData.prod_no = opt_sub_table[k].prod_no;
                    pushData.sku_cd = opt_sub_table[k].sku_cd;
                    pushData.opt_add_price = opt_sub_table[k].opt_add_price;
                    pushData.pack_unit = opt_sub_table[k].pack_unit;
                    pushData.cnt_limit = opt_sub_table[k].sale_opt_cnt_limit;
                    pushData.A006_cnt_limit = opt_sub_table[k].A006_cnt_limit;
                    pushData.A001_cnt_limit = opt_sub_table[k].A001_cnt_limit;
                    pushData.stock_cnt = opt_sub_table[k].stock_cnt;
                    pushData.safe_stock = opt_sub_table[k].stock_cnt_safe || data.safe_stock_default;
                    pushData.depot_no = opt_sub_table[k].depot_no;
                    pushData.sale_opt_seq = opt_sub_table[k].sale_opt_seq;
                    pushData.prod_weight = opt_sub_table[k].opt_weight;
                    pushData.prod_status = opt_sub_table[k].opt_status;
                    pushData.set_no = opt_sub_table[k].set_no;
                    pushData.set_cd = opt_sub_table[k].set_cd;
                    pushData.prod_name = opt_sub_table[k].prod_name;

                    // 조합형&독립형 옵션명 출력할때 ''값으로만 ng-if 걸려있어서 조합형 옵션명 null이면 '' 넣어줌
                    // 온라인상품 그리드의 옵션명은 옵션테이블에 저장된 정보로 불어오도록 수정함 2018-06-27 rony
                    // pushData.attr1 = opt_sub_table[k].opt_name1 || '';
                    // pushData.attr2 = opt_sub_table[k].opt_name2 || '';
                    // pushData.attr3 = opt_sub_table[k].opt_name3 || '';

                    // 수정 시 set_prod_list가 빈값으로 들어가서 처리 2018-06-19 Gargamel
                    if (!pushData.set_prod_list && opt_sub_table[k].set_prod_list) {
                      pushData.set_prod_list = opt_sub_table[k].set_prod_list;
                    }

                  } else {
                    pushData.prod_img = '/assets/images/upload.png';
                    pushData.sku_cd = '';
                    pushData.skuAuto = true;
                    pushData.skuCheck = false;
                    // 등록 & 수정페이지 변수명이 틀려 safe_stock & stock_cnt_safe 모두 추가
                    pushData.safe_stock = data.options_list ? data.options_list[k].safe_stock : data.safe_stock_default;
                    pushData.stock_cnt_safe = data.options_list ? data.options_list[k].safe_stock : '';
                  }

                  // 물류상품인경우 재고 고정.
                  if (data.std_ol_yn === 2) {
                    pushData.cnt_limit = 99999;
                  }

                  k++;
                  if (pushData.prod_no || $rootScope.user_profile.sol_stock < 1) {
                    if (!edit || pushData.prod_no) {
                      op_table.push(pushData);
                    }
                  } else if (!opt_sub_table) {
                    op_table.push(pushData);
                  }
                }
              }
            }
          }
        } else { //독립
          k = 0;

          for (i in data.opt) {
            const attr = data.opt[i].attr.split(',');

            for (j in attr) {
              if (data.opt[i].name != '' && attr[j] != '') {
                const pushData = {
                  name: data.opt[i].name,
                  // attr : attr[j],
                  pack_unit: 1,
                  prod_status: '정상'
                };

                // 독립형 옵션일때 attr1, attr2, attr3에 값 넣어주기 위해 추가
                if (type == '독립형') {
                  pushData[`attr${i * 1 + 1}`] = attr[j];
                }
                // pushData.attr을 사용해서 출력하기 때문에 값 pushData.attr에도 넣어줌
                pushData.attr = attr[j];

                //ESM 추천옵션인 경우 코드 기재를 위해 추가
                if (data.opt[i].flag) {
                  //직접 입력한 경우를 위해 0으로 초기화
                  pushData.attr_cd = 0;
                  _.each(data.opt[i].cateOpAttr, function (attr) {
                    if (pushData.attr == attr.ObjOptEssenName) {
                      pushData.attr_cd = attr.ObjOptEssenNo;
                    }
                  });
                }
                if (opt_sub_table && opt_sub_table[k]) {
                  pushData.skuCheck = true;
                  pushData.prod_img = opt_sub_table[k].prod_img || '/assets/images/upload.png';
                  pushData.opt_img = opt_sub_table[k].opt_img;
                  pushData.prod_no = opt_sub_table[k].prod_no;
                  pushData.sku_cd = opt_sub_table[k].sku_cd;
                  pushData.opt_add_price = opt_sub_table[k].opt_add_price;
                  pushData.pack_unit = opt_sub_table[k].pack_unit;
                  pushData.cnt_limit = opt_sub_table[k].sale_opt_cnt_limit;
                  pushData.A006_cnt_limit = opt_sub_table[k].A006_cnt_limit;
                  pushData.A001_cnt_limit = opt_sub_table[k].A001_cnt_limit;
                  pushData.stock_cnt = opt_sub_table[k].stock_cnt;
                  pushData.safe_stock = opt_sub_table[k].stock_cnt_safe || data.safe_stock_default;
                  pushData.depot_no = opt_sub_table[k].depot_no;
                  pushData.sale_opt_seq = opt_sub_table[k].sale_opt_seq;
                  pushData.prod_weight = opt_sub_table[k].opt_weight;
                  pushData.prod_status = opt_sub_table[k].opt_status;
                  pushData.set_no = opt_sub_table[k].set_no;
                  pushData.set_cd = opt_sub_table[k].set_cd;
                  pushData.prod_name = opt_sub_table[k].prod_name;

                  //독립형 옵션 옵션명 옵션값 추가
                  pushData.name = opt_sub_table[k].opt_name1 || '';
                  pushData.attr = opt_sub_table[k].opt_name2 || '';
                  pushData.set_prod_list = opt_sub_table[k].set_prod_list;
                } else {
                  pushData.prod_img = '/assets/images/upload.png';
                  pushData.sku_cd = '';
                  pushData.skuAuto = true;
                  pushData.skuCheck = false;
                  pushData.safe_stock = data.safe_stock_default;
                }
                k++;

                // 물류상품인경우 재고 고정
                if (data.std_ol_yn === 2) {
                  pushData.cnt_limit = 99999;
                }

                if (pushData.prod_no || $rootScope.user_profile.sol_stock < 1) {
                  op_table.push(pushData);
                } else if (!opt_sub_table) {
                  op_table.push(pushData);
                }
              }
            }
          }
        }
        //   }

        //등록일 경우 세트상품 결합 필요 없음으로 조합된 테이블 반환
        if (!edit) {
          return op_table;
        }
        //수정일 경우 등록 되었던 세트상품으로 재조합
        else {
          /*
           for(var i in op_table){

           var row = op_table[i];

           _.map(_.filter(opt_sub_table,function(subRow){ return (row.sale_opt_seq==subRow.sale_opt_seq) && (row.sku_cd != subRow.sku_cd)}),
           function(child){
           //세트상품 추가매칭을 위한 로우 복사
           var newRow = angular.copy(row);
           newRow.sku_cd = child.sku_cd;
           newRow.prod_no = child.prod_no;
           newRow.depot_no = child.depot_no;
           newRow.prod_img = child.prod_img;
           newRow.safe_stock = child.stock_cnt_safe;
           newRow.real_stock = child.stock_cnt;
           newRow.parent = i;
           newRow.parent_idx = '';
           newRow.copy = false;
           row.parent_idx = i;
           //원본 로우 밑으로 복사된 로우 삽입
           op_table.splice(i+1,0,newRow)
           })
           }
           */

          //조합된 상품 이외에 옵션추가로 등록된 상품 추가
          for (i = op_table.length; i < opt_sub_table.length; i++) {
            const pushData = {
              name1: opt_name1,
              name2: opt_name2,
              name3: opt_name3,
              prod_img: opt_sub_table[i].prod_img,
              opt_img: opt_sub_table[i].opt_img,
              prod_no: opt_sub_table[i].prod_no,
              sku_cd: opt_sub_table[i].sku_cd,
              opt_add_price: opt_sub_table[i].opt_add_price,
              pack_unit: opt_sub_table[i].pack_unit,
              cnt_limit: opt_sub_table[i].sale_opt_cnt_limit,
              A006_cnt_limit: opt_sub_table[k].A006_cnt_limit,
              A001_cnt_limit: opt_sub_table[k].A001_cnt_limit,
              stock_cnt: opt_sub_table[i].stock_cnt,
              safe_stock: opt_sub_table[i].stock_cnt_safe,
              depot_no: opt_sub_table[i].depot_no,
              sale_opt_seq: opt_sub_table[i].sale_opt_seq,
              prod_weight: opt_sub_table[i].opt_weight,
              prod_status: opt_sub_table[i].opt_status,
              attr1: opt_sub_table[i].opt_name1,
              attr2: opt_sub_table[i].opt_name2,
              attr3: opt_sub_table[i].opt_name3,
              attr1_cd: opt_sub_table[i].opt_name1_cd,
              attr2_cd: opt_sub_table[i].opt_name2_cd,
              set_no: opt_sub_table[i].set_no,
              set_cd: opt_sub_table[i].set_cd,
              prod_name: opt_sub_table[i].prod_name,
              set_prod_list: opt_sub_table[i].set_prod_list
            };

            //독립형 옵션 옵션명 옵션값 추가
            if (type == '독립형') {
              pushData.name = opt_sub_table[i].opt_name1;
              pushData.attr = opt_sub_table[i].opt_name2;
            }

            //추천옵션 코드가 없을 경우 수정에서 삽입
            if (!pushData.attr1_cd) {
              if (data.opt[0] && data.opt[0].flag) {
                //직접 입력한 경우를 위해 0으로 초기화
                pushData.attr1_cd = 0;
                _.each(data.opt[0].cateOpAttr, function (attr) {
                  if (pushData.attr1 == attr.ObjOptEssenName || pushData.attr1 == attr.ObjOptEssenNameEn || pushData.attr1 == attr.ObjOptEssenNameEngKor) {
                    pushData.attr1_cd = attr.ObjOptEssenNo;
                  }
                });
              }
            }

            if (!pushData.attr2_cd) {
              if (data.opt[1] && data.opt[1].flag) {
                //직접 입력한 경우를 위해 0으로 초기화
                pushData.attr2_cd = 0;
                _.each(data.opt[1].cateOpAttr, function (attr) {
                  if (pushData.attr2 == attr.ObjOptEssenName) {
                    pushData.attr2_cd = attr.ObjOptEssenNo;
                  }
                });
              }
            }

            // 물류상품인경우 재고 고정
            if (data.std_ol_yn === 2) {
              pushData.cnt_limit = 99999;
            }

            op_table.push(pushData);
          }

          return op_table;
        }
      },

      initOpt: function (re, prodOpt, currentOpt) {
        // 세트상품 아닌 옵션 추출
        const opt_list = _.filter(re.optData, { set_no: null });

        // 세트상품 옵션 추출
        // 세트넘버로 묶어서 메인상품에 나머지 옵션상품 정보모아서 합침
        const set_opt_list = _(re.optData)
          .filter('set_no')
          .groupBy('sale_opt_seq')
          .map(function (setOptList) {
            //세트상품의 대표상품이 변경될 경우 오류
            let mainOpt = _.find(setOptList, function (opt) { return opt.prod_no === opt.main_prod_no; });

            // 세트상품 잘못매칭된 케이스 온라인 상품 상세 열리기는 해야하므로 임시처리
            if (!mainOpt) {
              mainOpt = setOptList[0];
            }

            mainOpt.prod_name = mainOpt.set_name || mainOpt.prod_name;
            // $scope.data.options.prodList의 원소에서 set_prod_list 순환참조 에러 발생하여 임시 주석함.
            // 백엔드에서 사용하지 않는 것으로 보이지만 다니엘이 수정하신 내용이라 추후 확인 필요할 듯.
            mainOpt.set_prod_list = angular.copy(setOptList);
            mainOpt.sku_cd = mainOpt.set_cd;

            return mainOpt;
          })
          .value();

        //상품 옵션 리스트 정리
        //위의 세트상품 옵션 추출~상품 옵션 리스트 정리 처리때문에 순서가 바뀜 -> sale_opt_seq 기준으로 오름차순 정렬
        re.optData = _(opt_list)
          .concat(set_opt_list)
          .sortBy('sale_opt_seq')
          .value();

        if (re.opt_type == '옵션없음') {
          for (const i in re.optData) {
            const opt_sub_table = re.optData[i];

            prodOpt.matchStockList[i] = opt_sub_table;
            prodOpt.matchStockList[i].prod_img = opt_sub_table.prod_img || '/assets/images/upload.png';
            prodOpt.matchStockList[i].stock_cnt = opt_sub_table.stock_cnt;
            prodOpt.matchStockList[i].safe_stock = opt_sub_table.stock_cnt_safe;
            prodOpt.matchStockList[i].cnt_limit = opt_sub_table.sale_opt_cnt_limit;
            prodOpt.matchStockList[i].prod_weight = opt_sub_table.opt_weight;
            prodOpt.matchStockList[i].prod_status = opt_sub_table.opt_status;
            prodOpt.matchStockList[i].prod_name = opt_sub_table.prod_name;
            if (opt_sub_table.set_no) {
              // 세트상품가격이 있는경우 세트상품가격 출력 2019-04-29 rony
              prodOpt.matchStockList[i].sale_price = opt_sub_table.set_sale_price || '';
            } else {
              prodOpt.matchStockList[i].sale_price = opt_sub_table.sale_price || '';
            }
          }
        } else {
          prodOpt[`${currentOpt}opt`][0].name = re.opt_sort1 || '';
          prodOpt[`${currentOpt}opt`][1].name = re.opt_sort2 || '';
          prodOpt[`${currentOpt}opt`][2].name = re.opt_sort3 || '';
          prodOpt[`${currentOpt}opt`][0].attr = re.opt_sort1_desc || '';
          prodOpt[`${currentOpt}opt`][1].attr = re.opt_sort2_desc || '';
          prodOpt[`${currentOpt}opt`][2].attr = re.opt_sort3_desc || '';
        }

        return {
          opt: prodOpt[`${currentOpt}opt`],
          matchStockList: prodOpt.matchStockList,
          optData: re.optData
        };
      },

      initAddOpt: function (addOptData) {
        const add_opt_table = [];
        const add_opt = [];

        const add_opt_list = addOptData.filter(opt => !opt.set_no);
        const check_set_add_opt = addOptData.filter(opt => opt.set_no);

        // 세트상품 추가구매옵션 추출
        // 세트넘버로 묶어서 메인상품에 나머지 옵션상품 정보모아서 합침
        const set_add_opt_list = _(check_set_add_opt)
          .groupBy('sale_opt_seq')
          .map(function (setOptList, seq) {
            //세트상품은 sku 대신 set_no 사용
            const uniq_add_set = _.find(setOptList, { sale_opt_seq: parseInt(seq) });

            uniq_add_set.set_prod_list = angular.copy(setOptList);
            uniq_add_set.sku_cd = uniq_add_set.set_cd;

            return uniq_add_set;
          }).value();

        //상품 추가구매옵션 리스트 정리
        const combineAddOpt = add_opt_list.concat(set_add_opt_list);

        // 위의 세트상품 옵션 추출~상품 옵션 리스트 정리 처리때문에 순서가 바뀜 -> sale_opt_seq 기준으로 오름차순 정렬
        if (set_add_opt_list.length) {
          combineAddOpt.sort(function (a, b) {
            return b.sale_opt_seq < a.sale_opt_seq ? 1 : -1;
          });
        }

        for (var k in combineAddOpt) {
          const add_opt_data = {};
          const add_options = {};
          // eslint-disable-next-line no-var
          var over_flag = -1;

          //추가 항목명, 추가 옵션명, 추가 금액 테이블 정리
          angular.forEach(add_opt, function (row, key) {
            if (row.name == combineAddOpt[k].add_item_name) {
              over_flag = key;

              return false;
            }
          });

          if (over_flag > -1) {
            add_opt[over_flag].opPrice += `,${combineAddOpt[k].opt_add_price}`;
            add_opt[over_flag].opName += `,${combineAddOpt[k].add_item_opt_name}`;
          } else {
            add_options.name = combineAddOpt[k].add_item_name;
            add_options.opName = combineAddOpt[k].add_item_opt_name;
            add_options.opPrice = String(combineAddOpt[k].opt_add_price) || '0';
          }
          //추가 항목명, 추가 옵션명, 추가 금액 테이블 정리 끝

          add_opt_data.prod_no = combineAddOpt[k].prod_no;
          add_opt_data.name = combineAddOpt[k].add_item_name;
          add_opt_data.opName = combineAddOpt[k].add_item_opt_name;
          add_opt_data.sku_cd = combineAddOpt[k].sku_cd;
          add_opt_data.opPrice = combineAddOpt[k].opt_add_price || 0;
          add_opt_data.pack_unit = combineAddOpt[k].pack_unit;
          add_opt_data.stock_cnt = combineAddOpt[k].stock_cnt;
          add_opt_data.safe_stock = combineAddOpt[k].stock_cnt_safe;
          add_opt_data.depot_no = combineAddOpt[k].depot_no;
          add_opt_data.cnt_limit = combineAddOpt[k].sale_opt_cnt_limit;
          add_opt_data.A006_cnt_limit = combineAddOpt[k].A006_cnt_limit;
          add_opt_data.A001_cnt_limit = combineAddOpt[k].A001_cnt_limit;
          add_opt_data.prod_weight = combineAddOpt[k].opt_weight;
          add_opt_data.prod_status = combineAddOpt[k].opt_status || '정상';
          add_opt_data.set_no = combineAddOpt[k].set_no;
          add_opt_data.set_cd = combineAddOpt[k].set_cd;
          add_opt_data.set_prod_list = combineAddOpt[k].set_prod_list;
          add_opt_data.sale_opt_seq = combineAddOpt[k].sale_opt_seq;

          add_opt_table.push(add_opt_data);
          if (add_options.name) {
            add_opt.push(add_options);
          }
        }

        return { add_opt_table: add_opt_table, add_opt: add_opt };
      },

      /**
       * 온라인 템플릿 상세모달
       */
      onlineTemplateDetail: function(template_no, siteCode, siteId, shopName, openTemplate, next) {
        let modal = '';
        const resolve = {};
        let shop_cd = siteCode;

        if (typeof siteCode !== 'string') {
          shop_cd = siteCode.shop_cd;
          siteCode = siteCode.pa_shop_cd;
        }

        resolve.data = {
          type: !template_no ? 'olProd_add' : 'olProd',
          template_no: template_no,
          shop_cd,
          shop_name: shopName,
          shop_id: siteId,
          pa_shop_cd: siteCode
        };

        modal = commonSVC.openModal('full', resolve, 'setFormTemplateCtrl', 'views/online/setForm/setform_detail.html');
        if (modal !== '') {
          modal.result.then(function (param) {
            if (param != 'success') {
              // commonSVC.showToaster('error', '실패', type + ' 처리 실패');
              commonSVC.showToaster('error', '실패', '처리 실패');
            } else {
              //수정 완료시 데이터 새로고침
              next(openTemplate, 'false');
            }
          });
        }
      },

      sortFunction: function(a, b) {
        return (isNaN(a) || isNaN(b)) ? (a < b) ? -1 : 1 : (Number(a) - Number(b));
      },
    };

    //옵션 코드가 누락되어있는 경우 검색해준다
    function findNameCd(opt, opt_name, name) {
      const find = _.find(opt, o => o.name === opt_name);

      if (find && (find.cateOpAttr?.length || find.cateOpAttrList)) {
        const cateOpAttrList = find.cateOpAttr?.length ? find.cateOpAttr : find.cateOpAttrList;
        const findCateOpAttr = cateOpAttrList.find(f => (f.ObjOptEssenName || f.stdOptValNm) === name);

        if (findCateOpAttr) {
          return (findCateOpAttr.ObjOptEssenNo || findCateOpAttr.stdOptNo) || '';
        } else if (cateOpAttrList.length && find.stdOpt) {
          const findCateOpAttrByStdOpt = cateOpAttrList.find(unit => unit.stdOptValNo === find.stdOpt);

          return findCateOpAttrByStdOpt ? findCateOpAttrByStdOpt.stdOptValNo || '' : '';
        }
      }

      return '';
    }

    const solData = {
      /**
       * 엑셀 모달창 필드 리스트
       */
      excelFieldList: [
        { header: '등록일', key: 'wdate', select: true },
        { header: '카테고리', key: 'sol_cate_name', select: true },
        { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true },
        { header: '상품명', key: 'shop_sale_name', select: true },
        { header: '상품상태', key: 'sale_status', select: true },
        { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true },
        { header: '옵션', key: 'opt_type', select: true },
        { header: '판매가', key: 'sale_price', select: true },
        { header: '적용 템플릿', key: 'template_name', select: true },
        { header: '판매기간', key: 'sale_etime', select: true },
        { header: '수정일', key: 'mtime', select: true }
      ],

      /**
       * 폼 영역 변수 설정
       */
      types: [
        { code: '01', name: '의류' },
        { code: '02', name: '구두/신발' },
        { code: '03', name: '가방' },
        { code: '04', name: '패션잡화(모자/벨트/액세서리)' },
        { code: '05', name: '침구류/커튼' },
        { code: '06', name: '가구(침대/소파/싱크대/DIY제품)' },
        { code: '07', name: '영상가전(TV류)' },
        { code: '08', name: '가정용 전기제품 (냉장고/세탁기/식기세척기/전자레인지)' },
        { code: '09', name: '계절가전(에어컨/온풍기)' },
        { code: '10', name: '사무용기기(컴퓨터/노트북/프린터)' },
        { code: '11', name: '광학기기(디지털카메라/캠코더)' },
        { code: '12', name: '소형가전(MP3/전자사전 등)' },
        { code: '13', name: '휴대폰' },
        { code: '14', name: '내비게이션' },
        { code: '15', name: '자동차용품(자동차부품/기타 자동차용품)' },
        { code: '16', name: '의료기기' },
        { code: '17', name: '주방용품' },
        { code: '18', name: '화장품' },
        { code: '19', name: '귀금속/보석/시계류' },
        { code: '20A', name: '식품(농산물)' },
        { code: '20B', name: '식품(축산물)' },
        { code: '20C', name: '식품(수산물)' },
        { code: '21', name: '가공식품' },
        { code: '22', name: '건강기능식품' },
        { code: '23', name: '영유아용품' },
        { code: '24', name: '악기' },
        { code: '25', name: '스포츠용품' },
        { code: '26', name: '서적' },
        { code: '27', name: '호텔 / 펜션 예약' },
        { code: '28', name: '여행 패키지' },
        { code: '29', name: '항공권' },
        { code: '30', name: '자동차 대여 서비스 (렌터카)' },
        { code: '31', name: '물품대여 서비스 (정수기/비데/공기청정기 등)' },
        { code: '32', name: '물품대여 서비스 (서적/유아용품/행사용품 등)' },
        { code: '33', name: '디지털 콘텐츠 (음원/게임/인터넷강의 등)' },
        { code: '34', name: '상품권 / 쿠폰' },
        { code: '35', name: '기타재화' },
        { code: '36', name: '모바일쿠폰' },
        { code: '37', name: '영화/공연' },
        { code: '38', name: '기타용역' },
        { code: '39', name: '생활화학제품' },
        { code: '40', name: '살생물제품' },
      ],
      /** 신규 정보고시 타입 */
      newTypes: [
        { code: 'Wear2023', name: '의류' },
        { code: 'Shoes2023', name: '구두/신발' },
        { code: 'Bag2023', name: '가방' },
        { code: 'FashionItems2023', name: '패션잡화(모자/벨트/액세서리 등)' },
        { code: 'SleepingGear2023', name: '침구류/커튼' },
        { code: 'Furniture2023', name: '가구(침대/소파/싱크대/DIY제품 등)' },
        { code: 'ImageAppliances2023', name: '영상가전(TV류)' },
        { code: 'HomeAppliances2023', name: '가정용 전기제품 (냉장고/세탁기/식기세척기/전자레인지 등)' },
        { code: 'SeasonAppliances2023', name: '계절가전(에어컨/온풍기 등)' },
        { code: 'OfficeAppliances2023', name: '사무용기기(컴퓨터/노트북/프린터 등)' },
        { code: 'OpticsAppliances2023', name: '광학기기(디지털카메라/캠코더 등)' },
        { code: 'MicroElectronics2023', name: '소형전자(MP3/전자사전 등)' },
        { code: 'CellPhone2023', name: '휴대형 통신기기(휴대폰/태블릿 등)' },
        { code: 'Navigation2023', name: '내비게이션' },
        { code: 'CarArticles2023', name: '자동차용품(자동차부품/기타 자동차용품 등)' },
        { code: 'MedicalAppliances2023', name: '의료기기' },
        { code: 'KitchenUtensils2023', name: '주방용품' },
        { code: 'Cosmetic2023', name: '화장품' },
        { code: 'Jewelry2023', name: '귀금속/보석/시계류' },
        { code: 'Food2023', name: '농수축산물' },
        { code: 'ProcessedFood2023', name: '가공식품' },
        { code: 'HealthFunctionalFood2023', name: '건강기능식품' },
        { code: 'Kids2023', name: '어린이제품' },
        { code: 'MusicalInstrument2023', name: '악기' },
        { code: 'SportsEquipment2023', name: '스포츠용품' },
        { code: 'Books2023', name: '서적' },
        { code: 'LodgmentReservation2023', name: '호텔 / 펜션 예약' },
        { code: 'TravelPackage2023', name: '여행 패키지' },
        { code: 'AirlineTicket2023', name: '항공권' },
        { code: 'RentCar2023', name: '자동차 대여 서비스 (렌터카)' },
        { code: 'RentalHa2023', name: '물품대여 서비스 (정수기/비데/공기청정기 등)' },
        { code: 'RentalEtc2023', name: '물품대여 서비스 (서적/유아용품/행사용품 등)' },
        { code: 'DigitalContents2023', name: '디지털 콘텐츠 (음원/게임/인터넷강의 등)' },
        { code: 'GiftCard2023', name: '상품권 / 쿠폰' },
        { code: 'Etc2023', name: '기타재화' },
        { code: 'MobileCoupon2023', name: '모바일쿠폰' },
        { code: 'MovieShow2023', name: '영화/공연' },
        { code: 'EtcService2023', name: '기타용역' },
        { code: 'HouseHoldChemical2023', name: '생활화학제품' },
        { code: 'Biodical2023', name: '살생물제품' }
      ],

      // 개별 쇼핑몰 상세설명 이미지 지원 쇼핑몰 리스트
      descImgShopMap: {
        B719: {
          minSize: {
            width: 300,
            height: 300
          },
          maxSize: {
            width: 758,
            height: 3000
          },
          maxMegaByte: 2
        }
      },

      /**
     * 면제유형
     */
      ExemptionList: [
        { text: '안전기준 준수대상', value: 'kc_01' },
        { text: '구매대행', value: 'kc_02' },
        { text: '병행수입', value: 'kc_03' }
      ],

      //일반템플릿 용량/규격 단위
      measure_list: [
        { name: 'm', value: '1' },
        { name: 'g', value: '2' },
        { name: 'kg', value: '3' },
        { name: '개', value: '4' },
        { name: '리터', value: '5' },
        { name: 'ml', value: '6' },
        { name: 'mm', value: '7' },
        { name: '마리', value: '8' },
        { name: '봉', value: '9' },
        { name: 'p', value: '10' },
        { name: 'cm', value: '11' },
        { name: 'box', value: '12' },
        { name: 'mg', value: '13' },
        { name: '팩', value: '14' },
        { name: '단', value: '15' },
        { name: '캅셀', value: '16' },
        { name: '캡슐', value: '17' },
        { name: '정', value: '18' },
        { name: '병', value: '19' },
        { name: '포', value: '20' },
        { name: '100g', value: '21' }
      ],
      /**
       * 단일 상품 데이터
       */
      singleProduct: {
        options: {},
        esm: {
          name: '',
          promotion: '',
          model: '',
          brand: '',
          maker: ''
        },
        prodInfo: {}
      },

      /**
       * 모델명 브랜드 제조사
       */
      models: {
        model: '',
        brand: '',
        maker: '',
        product_brand: '',
        flag: false
      },

      /**
       * 안전인증 기관
       */
      certifyList: [
        { value: '해당없음', text: '해당없음' },
        { value: '국가통합인증(KC마크)', text: '국가통합인증(KC마크)' },
        { value: '공산품 안전인증(KPS)', text: '공산품 안전인증(KPS)' },
        { value: '전기용품안전인증(KCC)', text: '전기용품안전인증(KCC)' },
        { value: '공산품 자율안전확인(KPS)', text: '공산품 자율안전확인(KPS)' },
        { value: '전기용품자율안전확인(KCC)', text: '전기용품자율안전확인(KCC)' },
        { value: '방송통신기기 인증(MIC)', text: '방송통신기기 인증(MIC)' },
        { value: '친환경인증-유기농산물', text: '친환경인증-유기농산물' },
        { value: '친환경인증-무농약농산물', text: '친환경인증-무농약농산물' },
        { value: '친환경인증-저농약농산물', text: '친환경인증-저농약농산물' },
        { value: '우수농산물(GAP인증))', text: '우수농산물(GAP인증))' },
        { value: '친환경인증-유기축산물인증', text: '친환경인증-유기축산물인증' },
        { value: '친환경인증-무항생제축산물인증', text: '친환경인증-무항생제축산물인증' },
        { value: '도축증명서', text: '도축증명서' },
        { value: 'HACCP(위해요소중점관리우수축산물)', text: 'HACCP(위해요소중점관리우수축산물)' },
        { value: '농산물이력추적', text: '농산물이력추적' },
        { value: '친환경수산물', text: '친환경수산물' },
        { value: '수산물 품질인증', text: '수산물 품질인증' },
        { value: '수산특산물 품질인증', text: '수산특산물 품질인증' },
        { value: '가공식품 표준화인증 (KS)', text: '가공식품 표준화인증 (KS)' },
        { value: '유기가공식품인증', text: '유기가공식품인증' },
        { value: '등급판정확인서', text: '등급판정확인서' },
        { value: '우수건강식품제조기준(GMP)인증', text: '우수건강식품제조기준(GMP)인증' },
        { value: '건강기능식품인증', text: '건강기능식품인증' },
        { value: '의료기기 광고사전심의', text: '의료기기 광고사전심의' },
        { value: '건강기능식품 광고사전심의', text: '건강기능식품 광고사전심의' },
        { value: '원산지증명', text: '원산지증명' },
        { value: '해외안전인증서류', text: '해외안전인증서류' },
        { value: '수입신고필증', text: '수입신고필증' },
        { value: '형식승인', text: '형식승인' },
        { value: '형식검정 / 형식등록', text: '형식검정 / 형식등록' },
        { value: '전자파적합등록', text: '전자파적합등록' },
        { value: '공산품 어린이 보호포장(KPS)', text: '공산품 어린이 보호포장(KPS)' },
        { value: '어린이제품 안전인증', text: '어린이제품 안전인증' },
        { value: '어린이제품 안전확인', text: '어린이제품 안전확인' },
        { value: '어린이제품 공급자적합성확인', text: '어린이제품 공급자적합성확인' },
        { value: '전통식품품질 인증', text: '전통식품품질 인증' }
      ],

      /**
       * 안전인증유형
       */
      safeCertifyList: [
        { text: '생활용품 안전인증', value: 'cert_01' },
        { text: '생활용품 안전확인', value: 'cert_02' },
        { text: '생활용품 어린이보호포장', value: 'cert_03' },
        { text: '생활용품 안전품질표시', value: 'cert_04' },
        { text: '생활용품 공급자적합성확인', value: 'cert_05' },
        { text: '전기용품 안전인증', value: 'cert_06' },
        { text: '전기용품 안전확인', value: 'cert_07' },
        { text: '전기용품 공급자적합성확인', value: 'cert_08' },
        { text: '어린이제품 안전인증', value: 'cert_10' },
        { text: '어린이제품 안전확인', value: 'cert_11' },
        { text: '어린이제품 공급자적합성확인', value: 'cert_12' },
        { text: '위해우려제품 자가검사', value: 'cert_13' },
        { text: '방송통신기자재 잠정인증', value: 'cert_14' },
        { text: '방송통신기자재 적합등록', value: 'cert_15' },
        { text: '방송통신용품 적합성인증확인', value: 'cert_09' },
        { text: '원산지증명', value: 'cert_16' },
        { text: '해외안전인증', value: 'cert_17' },
        { text: '수입신고필증', value: 'cert_18' },
        { text: '친환경인증-무농약농산물', value: 'cert_19' },
        { text: '친환경인증-유기축산물', value: 'cert_20' },
        { text: '친환경인증-유기농산물', value: 'cert_21' },
        { text: '친환경인증-저농약농산물', value: 'cert_22' },
        { text: '친환경인증-무항생제축산물', value: 'cert_23' },
        { text: 'HACCP(위해요소 중점관리우수식품)', value: 'cert_24' },
        { text: 'GMP(우수건강기능식품 제조기준)', value: 'cert_25' },
        { text: 'GAP(농산물우수관리인증)', value: 'cert_26' },
        { text: '농산물이력추적관리', value: 'cert_27' },
        { text: '수산물품질인증', value: 'cert_28' },
        { text: '수산특산물품질인증', value: 'cert_29' },
        { text: '수산전통식품품질인증', value: 'cert_30' },
        { text: '친환경수산물품질인증', value: 'cert_31' },
        { text: '친환경수산가공품품질인증', value: 'cert_32' },
        { text: '전통식품 품질인증', value: 'cert_33' },
        { text: '유기가공식품 인증', value: 'cert_34' },
        { text: '가공식품 산업표준 KS인증', value: 'cert_35' },
        { text: '건강기능식품 광고사전심의', value: 'cert_36' },
        { text: '의료기기 광고사전심의', value: 'cert_37' },
        { text: '축산물 등급판정 확인서', value: 'cert_38' },
        { text: '도축검사증명서', value: 'cert_39' },
        { text: '식품명인(Grand master) 인증', value: 'cert_40' },
        { text: '사회적기업 인증', value: 'cert_41' },
        { text: '6차산업 인증', value: 'cert_42' },
        { text: '동물복지 인증', value: 'cert_43' },
        { text: '마을기업 인증', value: 'cert_44' },
        { text: '건강기능식품인증', value: 'cert_45' },
        { text: '형식승인', value: 'cert_46' },
        { text: '형식검정 / 형식등록', value: 'cert_47' },
        { text: '전자파적합등록', value: 'cert_48' },
        { text: '생활화학제품', value: 'cert_49' },
      ],

      /**
       * 면제유형
       */
      // ExemptionList : [
      //   {text : '안전기준 준수대상', value : '안전기준준수대상'},
      //   {text : '병행수입', value : '병행수입' },
      //   {text : '구매대행', value : '구매대행' }
      // ],

      //그룹불가 카테고리
      impossibleGroup: {
        A001: ['86000000', '33000000', '65000000', '41000000', '57000000', '63000000', '97000000']
      },

      //상품바로보기 불가 쇼핑몰코드
      impossibeDetailView: ['A000', 'U', 'X097'],

      /**
       * 상품등록 필수값 확인
       */
      checkValue: function (data, checked, mode, prodOpt, form) {
        const inValidTab = [true, true, true, true, true, true, true, true];
        const optInValidTab = [true, true, true, true, true, true, true, true, true, true, true];       //옵션탭 각가확인 일반, 단일, 쿠팡, 위메프
        const stieTap = ['', 'esm_', 'std_', 'cpang_', 'wmp_', 'hplus_', 'galleria_', 'lotte_on_', 'naver_', 'rocket_', 'aliexpress_', 'alibaba_'];
        const checkOptValid = ['cnt_limit', 'opt_name', 'opt_attr']; 	//옵션탭 유효성검사 name
        let opTabActive = checked.ESM ? 1 : 0;
        let opTabActiveFlag = false;
        const shop = [];
        let msg = '';
        const msgs = [];

        // 쇼핑몰별 유효성 체크 배열 초기화
        for (let i = 0, len = data.site_list.length; i < len; i++) {
          shop[data.site_list[i].shop_cd] = true;
        }
        //유효일자 체크
        if (data.expiration_month == '52') {
          data.expire_date = null;
        } else if (data.expiration_month) {
          if (data.made_date === null || data.made_date === '') {
            msgs.push('제조일자를 먼저 입력해 주십시오.');
            inValidTab[1] = false;
          } else {
            const expireDateForm = data.made_date.split('-');
            let expireDate = new Date(expireDateForm[0], expireDateForm[1] - 1, expireDateForm[2]);

            expireDate = expireDate.setMonth(expireDate.getMonth() + Number(data.expiration_month));
            data.expire_date = moment(expireDate).format('YYYY-MM-DD');
          }
        }

        if (data.site_list.some(shop => shop.pa_shop_cd === 'P051')) {
          const efcProdData = data.site_list.find(shop => shop.pa_shop_cd === 'P051');

          if (mode === 'add') {
            if (!efcProdData.shop_promotion_name) {
              msgs.push('- 에픽카인 경우 OEM 부품번호는 필수로 입력하셔야 합니다.');
              inValidTab[1] = false;

            } else if (!efcProdData.model_no) {
              msgs.push('- 에픽카인 경우 차대번호는 필수로 입력하셔야 합니다.');
              inValidTab[1] = false;
            }
          } else {
            if (!data.shop_promotion_name) {
              msgs.push('- 에픽카인 경우 OEM 부품번호는 필수로 입력하셔야 합니다.');
              inValidTab[1] = false;

            } else if (!data.model_no) {
              msgs.push('- 에픽카인 경우 차대번호는 필수로 입력하셔야 합니다.');
              inValidTab[1] = false;
            }
          }
        }

        //ISBN 코드
        if (data.isbn && data.isbn.toString().length != 13) {
          inValidTab[1] = false;
          msgs.push('- ISBN 코드는 13자리 숫자로 입력해 주세요.');
        }

        // 해외배송일때는 무게값이 0이면 안됨 하지만 해외배송이 아닐때는 무게값이 0 이여도 상관 없음
        if ((data.over_delivery === 'Y' && Number(data.prod_weight) < 0.01) || (Number(data.prod_weight) && Number(data.prod_weight) < 0.01)) {
          inValidTab[1] = false;
          msgs.push("무게는 '0.01' 이상 입력해주세요.");
        }

        if (mode == 'add') {

          if (!data.prodall.name) {
            inValidTab[1] = false;
            msgs.push('- 공통상품명은 필수로 입력하셔야 합니다.');
          }
          if (!data.prodall.cnt) {
            inValidTab[1] = false;
            msgs.push('- 공통 판매수량은 필수로 입력하셔야 합니다.');
          } else if (+data.prodall.cnt <= 0) {
            inValidTab[1] = false;
            msgs.push('- 공통 판매수량은 1개 이상이어야 합니다.');
          }
          if (!data.prodall.sale_price) {
            inValidTab[1] = false;
            msgs.push('- 공통 판매가는 필수로 입력하셔야 합니다.');
          }

          // ESM2.0 ,11번가 입력값 확인
          if (checked.ESM || checked.A112) {
            // 과세여부 ESM2.0, 11번가 영세인 경우 면세로 적용
            _.each(data.site_list, function(row) {
              if ((checked.ESM && checked.A112) &&
                (row.pa_shop_cd == 'A112' || row.pa_shop_cd == 'A001' || row.pa_shop_cd == 'A006')) {
                if (data.tax_type == '영세') {
                  row.tax_type = '면세';
                }
              } else if ((checked.ESM && !checked.A112) &&
                (row.pa_shop_cd == 'A001' || row.pa_shop_cd == 'A006')) {
                if (data.tax_type == '영세') {
                  row.tax_type = '면세';
                }
              } else if ((!checked.ESM && checked.A112) &&
                (row.pa_shop_cd == 'A112')) {
                if (data.tax_type == '영세') {
                  row.tax_type = '면세';
                }
              }
              if (row.pa_shop_cd == 'B719') {
                row.tax_type = '면세';
              }
            });
          }

          // // 옵션에 sku 매칭이 되어있지 않은 항목이 있는경우 에러. 2018-04-25 rony ( 재고관리 버전인경우에만 체크 되도록 조건 추가 2018-08-06 rony)
          // if (data.defaultOpTab && $rootScope.user_profile.sol_stock > 0) {
          //   var emptySku = _.filter(prodOpt.options_table, function (o) {
          //     return o.sku_cd == '';
          //   });

          //   if (emptySku.length > 0) {
          //     inValidTab[1] = false;
          //     msgs.push('SKU상품 매칭이 되지않은 옵션이 있습니다. 옵션의 SKU상품 매칭은 필수입니다.');
          //   }
          // }
          //옵션없음이 아닐경우 기본옵션 테이블 확인
          if (data.defaultOpTab && data.opt_type !== '옵션없음' && !prodOpt.options_table.length) {
            inValidTab[1] = false;
            optInValidTab[0] = false;
            opTabActive = 0;
            opTabActiveFlag = true;
          }

          // 판매자관리코드 중복체크 안했을 때 에러
          if (data.c_sale_cd && data.overlapResult != 'y') {
            inValidTab[1] = false;
          }
          /**
           * 공통 상품명 적용
           * 해당 적용은 백엔드에서 처리하도록 수정
           * 2018-04-20 roy
           */
          // _.each(data.site_list,function(row){
          //   if(!row.shop_sale_name) row.shop_sale_name = data.prodall.name;
          // });

          // 공통 판매수량 적용
          // _.each(data.site_list,function(row){
          //   if(!row.prod_stock) row.prod_stock = data.prodall.cnt;
          // });

          // 공통 판매가, 공급가 적용
          // _.each(data.site_list,function(row){
          //   if(!row.sale_price || row.sale_price == '0' || row.sale_price%10) row.sale_price = data.prodall.price;
          // });
          // _.each(data.site_list,function(row){
          //   if(!row.supply_price  || row.supply_price%10) row.supply_price = data.prodall.supply_price;
          // });

          if (data.prodall.sale_price && data.prodall.sale_price.toString().length > 10) {
            inValidTab[1] = false;
            msgs.push('- 판매가는 최대 10자리까지 입력이 가능합니다.');
          }

          for (const row of data.site_list) {
            if (row.sale_price && row.sale_price.toString().length > 10) {
              inValidTab[1] = false;
              msgs.push(`- ${row.shop_name} 판매가는 최대 10자리까지 입력이 가능합니다.`);
            }
          }

          // var priceFlag = _.filter(data.site_list, function(row) {
          //   // 위메프 2.0은 원단위 입력 가능
          //   return row.pa_shop_cd !== 'B719' && row.sale_price % 10;
          // });
          // var priceFlag2 = _.filter(data.site_list, function() {
          //   return data.prodall.price % 10;
          // });

          // if (priceFlag.length || priceFlag2.length) {
          //   inValidTab[1] = false;
          //   // msgs.push('- 판매가는 10원 단위로 입력해 주세요.');
          // }

          // var supplyFlag = _.filter(data.site_list, function(row) {
          //   return row.supply_price % 10;
          // });

          // if (supplyFlag.length) {
          //   inValidTab[1] = false;
          //   msgs.push('- 공급가는 10원 단위로 입력해 주세요.');
          // }

          // 인증정보
          var certFlag = _.filter(data.certDataList, function(row) {
            return row.cert_expire_date && (row.cert_date > row.cert_expire_date);
          });

          if (certFlag.length) {
            inValidTab[1] = false;
            msgs.push('- 인증정보의 인증만료일은 인증일보다 크게 입력해 주세요.');
          }

          // 인증정보2
          var certFlag2 = _.filter(data.certDataList, function(row) {
            return !row.cert_date && row.cert_expire_date;
          });

          if (certFlag2.length) {
            inValidTab[1] = false;
            msgs.push('- 인증정보의 인증만료일이 있는 경우 인증일은 필수입니다.');
          }

          // 모델명, 브랜드, 제조사 입력안된 쇼핑몰은 공용 모델명, 브랜드, 제조사 입력
          _.each(data.site_list, function(row) {
            if (!row.selectedModels) {
              row.model_name = data.prodall.market.model_name; row.brand_name = data.prodall.market.brand_name; row.maker_name = data.prodall.market.maker_name;
            }
          });

          // 템플릿 설정 여부
          // 템플릿 필수갑 제거
          // var templateFlag = _.filter(data.site_list, function (row) {
          //   if (row.template_no == '') {
          //     shop[row.pa_shop_cd] = false;
          //   }

          //   return row.template_no == '';
          // });

          // if (templateFlag.length) {
          //   inValidTab[4] = false;
          // }

          // 상품이미지
          var img_flag = _.filter(data.img_objects, function(row) {
            return row.files != '';
          });

          if (!img_flag.length && !data.matchImg) {
            // 대표이미지 등록이 되어있지 않은경우 사이트별 이미지가 등록되어있는지 확인하여 사이트마다 이미지가 개별 등록되어있는경우 에러 체크 안함. 2018-03-19 rony
            let noImage = false;

            _.forEach(data.site_list, function(siteInfos) {
              img_flag = _.filter(siteInfos.sub_img_objects, function(row) {
                return row.files != '';
              });
              if (!img_flag.length && !data.matchImg) {
                noImage = true;
              }
            });

            if (noImage) {
              inValidTab[2] = false;
            }
          }

          // 상세설명
          // 29cm 은 상세설명이 필수가 아니기 때문에 유효성 검수 제외.
          if (!(data.site_list.length === 1 && data.site_list[0].pa_shop_cd === 'P015')) {
            if (data.detail_desc == '' || _.isNull(data.detail_desc)) {
              const descFlag = _.filter(data.site_list, function(row) {
                return row.html_editor == '';
              });

              if (descFlag.length) {
                inValidTab[3] = false;
              }
              msgs.push('- 상세설명을 입력해주세요.');
            }
          }

          // 상세설명 이미지 등록 체크
          const detail_image_flag = _.filter(data.site_list, function(row) {
            return row.detail_desc_img_yn == '1' && !row.desc_img_url;
          });

          if (detail_image_flag.length) {
            inValidTab[3] = false;
            msgs.push('- 상세설명 이미지를 첨부해 주세요.');
          }

          // 탭별 라디오버튼 통합으로 인한 주석
          // //ESM2.0여부
          // if(!checked.ESM){
          //   // 대표상품 선택 여부
          //   if($rootScope.user_profile.sol_stock > 0 && (( data.defaultOpTab && !data.main_prod_no) || (data.defaultOpTab && data.main_prod_no == '-1'))){
          //     inValidTab[1] = false;
          //     msgs.push('- 일반옵션의 대표상품을 선택해 주세요.');
          //   }
          // }else{
          //   // 대표상품 선택 여부
          //   if($rootScope.user_profile.sol_stock > 0 && (( !checked.ESM && data.defaultOpTab && !data.main_prod_no) || (!checked.ESM && data.defaultOpTab && data.main_prod_no == '-1'))){
          //     inValidTab[1] = false;
          //     msgs.push('- 일반옵션의 대표상품을 선택해 주세요.');
          //   }
          // }
          // // 대표상품 선택 여부
          // if ($rootScope.user_profile.sol_stock > 0 && (((checked.ESM || checked.A112) && !data.esm_main_prod_no) || ((checked.ESM || checked.A112) && data.esm_main_prod_no == '-1'))){
          //   inValidTab[1] = false;
          //   msgs.push('- ESM2.0 옵션의 대표상품을 선택해 주세요.');
          // }
        }
        //수정시
        else {
          //카테고리 체크
          if (data.cateFlag) {
            inValidTab[0] = false;
            msgs.push('카테고리가 정상적으로 설정되어있지 않습니다.');
          }
          //판매가 단위 체크
          //위메프 2.0은 판매가에 원단위 허용
          // if (data.sale_price % 10 && data.pa_shop_cd !== 'B719') {
          //   inValidTab[1] = false;
          //   // msgs.push('판매가는 10원 단위로 입력해 주세요.');
          // }
          // //공급가 단위 체크
          // if (data.supply_price % 10) {
          //   inValidTab[1] = false;
          //   msgs.push('공급가는 10원 단위로 입력해 주세요.');
          // }

          // //대표상품 체크
          // if ($rootScope.user_profile.sol_stock > 0 && (data.main_prod_no == '-1' || data.main_prod_no == '')) {
          //   inValidTab[1] = false;
          //   msgs.push('- 대표상품을 선택해 주세요.');
          // }

          //옵션없음이 아닐경우 기본옵션 테이블 확인
          if (data.defaultOpTab && data.opt_type !== '옵션없음' && !prodOpt.options_table.length) {
            inValidTab[1] = false;
            optInValidTab[0] = false;
            opTabActive = 0;
            opTabActiveFlag = true;
          }

          // 상품이미지
          img_flag = _.filter(data.img_objects, function(row) {
            return row.files != '';
          });

          if (!img_flag.length) {
            inValidTab[2] = false;
          }
          //기본이미지 확인
          if (!data.img_objects[0].files) {
            inValidTab[2] = false;
          }

          // 상세설명
          // 29cm 은 상세설명이 필수가 아니기 때문에 유효성 검수 제외.
          if (data.pa_shop_cd !== 'P015' && !data.detail_desc && !data.detail_desc_img_yn) {
            inValidTab[3] = false;
            msgs.push('- 상세설명을 입력해 주십시오.');
          }

          if (data.detail_desc_img_yn == '1' && !data.desc_img_url) {
            inValidTab[3] = false;
            msgs.push('- 상세설명을 이미지를 첨부해 주세요.');
          }

          if (data.sale_cnt_limit < 1) {
            inValidTab[1] = false;
            msgs.push('- 쇼핑몰판매수량은 0 보다 커야 합니다.');
          }

          // 인증정보
          certFlag = _.filter(data.certDataList, function(row) {
            return (row.cert_date > row.cert_expire_date) && (row.cert_date && row.cert_expire_date);
          });

          if (certFlag.length) {
            inValidTab[1] = false;
            msgs.push('- 인증정보의 인증만료일은 인증일보다 크게 입력해 주세요.');
          }

          // 인증정보2
          certFlag2 = _.filter(data.certDataList, function(row) {
            return !row.cert_date && row.cert_expire_date;
          });

          if (certFlag2.length) {
            inValidTab[1] = false;
            msgs.push('- 인증정보의 인증만료일이 있는 경우 인증일은 필수입니다.');
          }

          //모델명, 브랜드, 제조사
          const copy_models = data.typing_model;

          _.forEach(['model', 'brand', 'maker'], function (type) {
            if (data[`search_${type}_yn`]) {
              copy_models[type] = data[`search_${type}`];
            } else {
              data.site_list[0].set_info[type === 'maker' ? 'manufacturer' : type] = { code: '', name: '' };
            }
          });

          data.model = copy_models.model;
          data.brand = copy_models.brand;
          data.maker = copy_models.maker;
          data.product_brand = copy_models.product_brand;
        }

        if (!form.$valid) {
          const formErrors = [...(form.$error.required || []), ...(form.$error.pattern || [])];

          const templateRequired = formErrors.filter(row => {
            // 추가항목 필수항목칸 검사
            let shop_cd = '';

            if (row.$$attr.ngModel.indexOf('row.set_info') != -1) {
              shop_cd = $(row.$$element[0]).attr('name').split('_')[0];
              shop[shop_cd] = false;
            }

            if (mode !== 'edit' && $rootScope.user_profile.sol_stock > 0 && row.$$attr.name) {
              //옵션탭 유효성 검사
              _.forEach(stieTap, function(value, key) {
                const tempCheckOptValid = [...checkOptValid];

                // 조합형인 경우 옵션값 확인할때 옵션값도 추가확인
                if (data[`${value}opt_type`] === '조합형') {
                  tempCheckOptValid.push('attr');
                }

                const nameFind = _.find(tempCheckOptValid, function(name) {
                  return row.$$attr.name.startsWith(value + name) && !row.$$attr.id?.startsWith('add');
                });

                if (nameFind) {
                  optInValidTab[key] = false;
                  inValidTab[1] = false;
                  opTabActiveFlag = true;
                  opTabActive = key;

                  return false;
                }
              });
            }
            if (row.$$attr.name && row.$$attr.name.startsWith('unit')) {
              optInValidTab[3] = false;
              inValidTab[1] = false;
              opTabActiveFlag = true;

              return false;
            }

            //템플릿 필수 입력 검사
            return row.$$attr.ngModel == 'row.template_name' || row.$$attr.ngModel.indexOf('row.set_info') != -1;
          }).length;

          if (templateRequired > 0) {
            inValidTab[4] = false;
          }

          if (((form.$error.required && form.$error.required.length - templateRequired > 0) || form.$error.pattern)
            && !(form.$error.required?.every(e => e.$$attr.id?.startsWith('esma_select_box') || e.$$attr.id?.startsWith('esmg_select_box')))) {
            inValidTab[1] = false;
          }

          if ((form.$error.required?.every(e => e.$$attr.id?.startsWith('esma_select_box') || e.$$attr.id?.startsWith('esmg_select_box')))) {
            msgs.push('ESM마스터 쇼핑몰 계정이 정상적으로 설정되어있지 않습니다.');
            inValidTab[0] = false;
          }
        }

        msg = msgs.length > 0 ? msgs.join('\n') : '';
        if (_.filter(inValidTab, function(row) {
          return row;
        }).length == 8 && msg == '') {
          return { result: true, inValidTab, optInValidTab };
        } else {
          return { result: false, inValidTab, msg: msg, optInValidTab, opTabActive, shop, opTabActiveFlag };
        }
      },

      /**
       * 온라인 상품명 바이트 체크
       */
      byteCheck: function(str, isUtf8) {
        if (!str) {
          return 0;
        }

        const strLen = str.length;
        let byte = 0;

        for (let i = 0, c; i < strLen; i++) {
          if (isUtf8) {
            c = str.charCodeAt(i);
            byte += c >> 11 ? 3 : c >> 7 ? 2 : 1;
          } else {
            byte += (str.charCodeAt(i) >> 7 ? 2 : 1);
          }
        }

        return byte;
      },
      /**
       * 2020-01-08 Boris
       *
       * 온라인 상품명 글자수 체크(위메프 2.0용)
       */
      characterCountCheck: function(str) {
        if (!str) {
          return 0;
        }

        return str.length;
      },

      /**
       * 모델명, 브랜드, 제조사 노출 필터
       */
      marketFilterFunction: function(array) {

        // const shop_list = [
        //   'A001', 'A004', 'A006', 'A011', 'A027', 'A100', 'A112', 'A077', 'A524', 'A133',
        //   'B603', 'B614', 'B616', 'B688', 'B691', 'B719', 'B877', 'B917', 'B394',
        //   'B594', 'A064', 'B878', 'B687', 'B700', 'P015', 'B720', 'A076', 'B502',
        //   'A032', 'A085', 'B957', 'P020', 'B959', 'B325', 'B696', 'P028', 'P037',
        //   'P018', 'A124'
        // ];
        const shop_list = Object.keys(siteInfo)
          .filter(shop_cd => {
            return (siteInfo[shop_cd].model_search_avail_yn || siteInfo[shop_cd].brand_search_avail_yn || siteInfo[shop_cd].maker_search_avail_yn) && shop_cd === siteInfo[shop_cd].pa_shop_cd && shop_cd !== 'A112';
          })
          .map(shop_cd => shop_cd);

        const flag = _.filter(array, function (row) {
          return shop_list.indexOf(row.pa_shop_cd) > -1;
        });

        if (flag.length) {
          return true;
        } else {
          return false;
        }
      },

      esm_opt: [
        { name: '', name_cd: '', attr: '', attr_cd: '', flag: true, cateOpAttr: [] },
        { name: '', name_cd: '', attr: '', attr_cd: '', flag: true, cateOpAttr: [] },
        { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [] }
      ],

      // 위메프2.0 조합형 옵션개수 체크
      // 3개일시 위메프 옵션탭을 생성하기위함
      checkOpt: function(opt) {
        let flag = 0;

        _.each(opt, function(o) {
          if (o.name && o.attr) {
            flag++;
          }
        });

        if (flag === 3) {
          return true;
        } else {
          return false;
        }
      },

      // 머리말/꼬리말 용

      // 템플릿 구분값
      addcontentTypes: [
        { name: '공지사항', view: ['상세설명'] },
        { name: '배송지연', view: ['상세설명'] },
        { name: '이벤트', view: ['상품명', '상세설명'] },
        { name: '기타', view: ['상품명', '상세설명'] }
      ],

      // 노출위치 값
      addcontentPositions: [
        { name: '상품명 전체', view: '상품명' },
        { name: '상품명 머리말', view: '상품명' },
        { name: '상품명 꼬리말', view: '상품명' },
        { name: '상세설명 전체', view: '상세설명' },
        { name: '상세설명 머리말', view: '상세설명' },
        { name: '상세설명 꼬리말', view: '상세설명' },
      ],

      // 적용대상 값
      addcontentTargets: [
        { name: '특정 상품' },
        { name: '특정 쇼핑몰' },
        { name: '미지정' }
      ]
    };
    const returnData = _.assignIn({}, commonData, solData);

    return returnData;
  });
