import { sendUrl, sendUrlFile } from '../utills/request';

/**
 * 이미지 호스팅 FTP 목록 조회
 */
export const getFtpList = () => {
  return sendUrl('GET', 'app/image-hosting/ftps', {});
};

/**
 * 호스팅 정보 등록
 */
export const addHosting = (params: any): Promise<any> => {
  const sendParams = {
    ...params,
    ftp_id: !params.ftp_id.trim() || params.anonymous_yn ? 'anonymous' : params.ftp_id,
    ftp_pw: params.ftp_pw,
    port_no: params.port_no || '21',
    protocal_type: params.protocal_type || '자동',
    passive_mode_yn: !!params.passive_mode_yn
  };

  if (params.ftp_no) {
    if (params.new_ftp_pw) {
      sendParams.ftp_pw = params.new_ftp_pw;
    } else {
      delete sendParams.ftp_pw;
      delete sendParams.new_ftp_pw;
    }

    return sendUrl('PUT', 'app/image-hosting/hosting', sendParams);
  } else {
    return sendUrl('POST', 'app/image-hosting/hosting', sendParams);
  }

};

/**
 * 호스팅 정보 삭제
 */
export const deleteFtpInfo = (ftp_no: number): Promise<any> => {
  return sendUrl('DELETE', `app/image-hosting/hosting/${ftp_no}`, {});
};

/**
 * 이미지 호스팅 접속
 */
export const connectToFtpServer = (ftp_no: number): Promise<any> => {
  return sendUrl('POST', 'app/image-hosting/connect', { ftp_no });
};

/**
 * 이미지 호스팅 접속 해제
 */
export const disconnectFtp = (ftp_no: number): Promise<any> => {
  return sendUrl('POST', 'app/image-hosting/disconnect', { ftp_no });
};

/**
 * 이미지 호스팅 파일 목록 조회
 */
export const getFtpFiles = (ftp_no: number, remotePath: string): Promise<any> => {
  return sendUrl('GET', `app/image-hosting/files?ftp_no=${ftp_no}&remotePath=${encodeURIComponent(remotePath || '/')}`);
};

/**
 * 모든 폴더 목록 조회
 */
export const getAllFtpFolders = (ftp_no: number, remotePath: string): Promise<any> => {
  return sendUrl('GET', `app/image-hosting/all-ftp-folders?ftp_no=${ftp_no}&remotePath=${encodeURIComponent(remotePath || '/')}`);
};

/**
 * 접속된 FTP 서버 정보 조회
 */
export const checkFtpConnection = (): Promise<any> => {
  return sendUrl('GET', 'app/image-hosting/check-ftp-connection', {});
};

/**
 * 새 폴더 생성
 */
export const createFtpFolder = (ftp_no: number, remotePath: string): Promise<any> => {
  return sendUrl('POST', 'app/image-hosting/create-ftp-folder', {
    ftp_no: ftp_no,
    newFolderPath: remotePath
  });
};

/**
 * 파일 또는 폴더 이름 변경
 */
export const renameFtpFile = (params: { ftp_no: number, oldPath: string, newPath: string }): Promise<any> => {
  return sendUrl('PATCH', 'app/image-hosting/rename', params);
};

/**
 * 파일 업로드
 */
export const uploadFtpFiles = (params: any): Promise<any> => {
  return sendUrlFile('POST', 'app/image-hosting/upload', params);
};

/**
 * 파일/폴더 삭제
 */
export const deleteFtpFiles = (params: any): Promise<any> => {
  return sendUrl('DELETE', 'app/image-hosting/delete', params);
};

/**
 * 이미지 호스팅 파일 다운로드
 */
export const downloadFtpFile = (sendParams: any): Promise<any> => {
  return sendUrl('POST', 'app/image-hosting/download', sendParams, null, true);
};

/**
 * 로그 조회
 */
export const getFtpLogs = (params: any): Promise<any> => {
  return sendUrl('POST', 'app/image-hosting/logs', params);
};

/**
 * 엑셀 다운로드
 */
export const downloadExcel = (params: any): Promise<any> => {
  return sendUrl('POST', 'app/image-hosting/excel-down', params);
};
