'use strict';
angular.module('gmpApp')
  .controller('OnlineProductMatchCtrl', function (
    $rootScope,
    $scope,
    settings,
    $filter,
    commonSVC,
    onlineProductModel,
    productModel,
    systemModel,
    supplierModel,
    warehouseModel,
    warehouseList,
    supplierList,
    systemList,
    madeinList,
    $state,
    userInfo,
    workSVC,
    localStorageService,
    $timeout) {
    // 사용중인 쇼핑몰
    const channelList = $rootScope.useChannelList({ site_action: 'SyncProdState' });
    const supplier = angular.copy(supplierList.data.result);
    supplier.unshift({ supp_no: 'null', supp_name: '매입처 없음', use_yn: 1 }); // 매입처 없음 필터 추가

    $scope.optType = 'online_mas_opt_grid';
    $scope.ol_sku_map_type = systemList.data.ol_sku_map_type; // 연동상품 매칭설정
    $scope.not_show_ol_sku_map_setting = !!localStorage.getItem('not_show_ol_sku_map_setting'); // 연동상품 매칭설정 다시보지않기
    $scope.isMatch = false;

    const onlineOptGrid_searchData = {
      totalCount: 0, // 검색전체 건수
      showCount: 25, // 한 페이지에 보이는 건수
      haveBoxContent: false,
      showDetailSearchArea: true,
      searchDateShow: false,
      search_key_items: [  // 검색영역 키값
        { label: '전체', value: '' },
        { label: '상품명', value: ['shop_sale_name'] },
        { label: '판매자 관리코드', value: ['c_sale_cd'] },
        { label: '옵션', value: ['opt_name'] },
        { label: 'SKU코드', value: ['sku_cd'] }
      ],
      search_date_type: [
        { label: '등록일', value: 'wdate' },
        { label: '수정일', value: 'mdate' }
      ],
    };

    /** ************************************************************** 마스터 상품(옵션별) ************************************************************** */
    const onlineMasOptGrid_searchForm = {
      search_key: '',
      search_word: '',
      date_type: 'wdate',
      sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
      edate: moment().format('YYYY-MM-DD'),
      depot_no: '',
      only_mas: true,
      pageFrom: 'prodMatch'
    };

    const onlineMasOptGrid_searchDetail = [
      // 배송처 선택
      {
        title: '배송처 선택',
        search_name: 'depot_no',
        item_list: warehouseList.data.result || [],
        item_key: 'warehouse_name',
        item_value: 'code',
        select_value: '',
        add_class: 'select-search'

      }
    ];

    /**
     * 가져온 마스터 상품 그리드
     */
    $scope.online_mas_opt_grid = {
      searchData: angular.copy(onlineOptGrid_searchData),
      searchForm: angular.copy(onlineMasOptGrid_searchForm),
      searchDetail: angular.copy(onlineMasOptGrid_searchDetail),
      searchFn: {
        searchDo: function () {
          // 미리보기 초기화
          $scope.waitBtnDisableFlag = true;
          $scope.online_mas_opt_grid.methods.reloadData(null, true, true);
        },
        changeLen: function (count) {
          $scope.online_mas_opt_grid.searchData.showCount = count;
          $scope.online_mas_opt_grid.methods.length(count);
        },
        resetDo: function () {
          $scope.online_mas_opt_grid.searchForm = angular.copy(onlineMasOptGrid_searchForm);
          $scope.online_mas_opt_grid.searchDetail = angular.copy(onlineMasOptGrid_searchDetail);
          $scope.online_mas_opt_grid.searchData.search_key_name = '전체';
          $scope.online_mas_opt_grid.searchFn.searchDo();
        },
        /**
         * @param {('map_y'|'map_n'|'total')} type 매칭타입
         */
        changeTab: function (type) {
          $scope.online_mas_opt_grid.optTab = type;
          $scope.online_mas_opt_grid.searchFn.searchDo();
        }
      },
      optTab: 'map_n',
      countList: {
        map_n: 0, // 미매칭
        map_y: 0, // 매칭완료
        total: 0, // 전체
      },
      methods: {},
      options: {
        pinningColumns: [],
        alignCenterColumns: ['link_product', 'img'],
        alignRightColumns: [],
        defaultSortingColumns: [],
        notSortingColumns: ['link_product', 'img', 'opt', 'sale_opt_cnt', 'sku_cd_group', 'prod_name_group', 'attri_group', 'std_ol_yn', 'depot_name', 'opt_custom_cd'],
        notResizingColumns: [],
        notMovingColumns: [],
        notVisibleColumns: ['opt_custom_cd'],
        initPagingSize: 25,
        familyOptions: {
          familyCountKey: 'masterCnt',
          familyDataKey: 'sale_no',
          parentDataKey: 'group_row_yn',
          parentDataValue: 1,
          parentRowTemplate: () => {}
        },
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/product/matching/ol-opts`,
          requestMethod: 'GET',
          requestWillAction: function (d) {
            const data = angular.merge({}, d, $scope.online_mas_opt_grid.searchForm);
            const optTab = $scope.online_mas_opt_grid.optTab;
            data.map_yn = ((optTab) => {
              switch (optTab) {
                case 'map_y': return 1;
                case 'map_n': return 0;
                case 'total': return '';
                default: return '';
              } })(optTab);

            return JSON.stringify(data);
          },
          requestDidAction: function (json) {
            $scope.online_mas_opt_grid.countList = json.countList;
            $scope.online_mas_opt_grid.total = json.total;

            return json.results;
          }
        },
        columns: [
          {
            title: '연동상품',
            key: 'link_product',
            width: 70,
            template: function (row) {
              // 원가
              return `<button class="btn btn-default btn-xxs" ng-click="grid.appScope.searchCsaleCd('${row.c_sale_cd}')">조회</button>`;
            }
          },
          {
            title: '이미지',
            key: 'img',
            width: 50,
            template: (row) => {
              return `<img src='${row.img}' width='30' height='30'>`;
            }
          },
          {
            title: '판매자 관리코드',
            key: 'c_sale_cd',
            width: 140,
          },
          {
            title: '상품명',
            key: 'shop_sale_name',
            width: 220,
            template: (row) => {
              const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

              return row.group_row_yn === 1
                ? `<a ng-click="grid.appScope.openOlDetail('${row.ol_shop_no}')">${filteredShopSaleName}</a>`
                : filteredShopSaleName;
            }
          },
          // 옵션
          {
            title: '옵션',
            key: 'opt',
            width: 150,
            template: (row) => {
              let opt_name;
              if (row.deal_type === '추가구매') {
                // 추가구매옵션
                opt_name = `[추가구매] ${row.add_item_opt_name}`;
              } else {
                // 일반옵션
                opt_name = `${row.opt_name1 || ''}${row.opt_name2 ? `,${row.opt_name2}` : ''}${row.opt_name3 ? `,${row.opt_name3}` : ''}`;
              }

              return row.group_row_yn === 1 ? '' : opt_name;
            }
          },
          // 수량
          {
            title: '수량',
            key: 'sale_opt_cnt',
            width: 70,
            template: (row) => {
              return row.group_row_yn === 1 ? '' : row.sale_opt_cnt || '';
            }
          },
          // SKU(세트)코드
          {
            title: 'SKU(세트)코드',
            key: 'sku_cd_group',
            width: 100,
            template: (row) => {
              const sku_cd_list = row.sku_cd_group ? row.sku_cd_group.split(',') : [];

              return row.group_row_yn === 1 && sku_cd_list.length ? `
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.searchProd('${sku_cd_list}')" uib-tooltip="마스터상품에 매칭된 SKU상품을 보여줍니다.\n마스터상품에 SKU가 매칭된 경우에만 노출합니다.">매칭됨</button>
              ` : sku_cd_list.join(',');

            }
          },
          // SKU(세트)상품명
          {
            title: 'SKU(세트)상품명',
            key: 'prod_name_group',
            width: 150,
            template: (row) => {
              return row.group_row_yn === 1 ? '' : row.prod_name_group;
            }
          },
          // 속성
          {
            title: '속성',
            key: 'attri_group',
            width: 100,
            template: (row) => {
              return row.group_row_yn === 1 ? '' : row.attri_group;
            }
          },
          // 배송처
          {
            title: '배송처',
            key: 'depot_name',
            width: 100,
          },
          // 옵션관리코드
          {
            title: '옵션관리코드',
            key: 'opt_custom_cd',
            width: 100,
            template: (row) => {
              return row.opt_custom_cd || '';
            }
          }
        ],
      },
    };

    /**
     * 마스터상품탭 연동상품 조회
     */
    $scope.searchCsaleCd = function (c_sale_cd) {
      $state.go('main.online_product_list', { search_word: c_sale_cd, action: 'searchCsaleCd' });
    };

    /** ************************************************************** 쇼핑몰 상품(옵션별) ************************************************************** */

    const onlineOptGrid_searchForm = {
      search_key: '',
      search_word: '',
      date_type: 'wdate',
      sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
      edate: moment().format('YYYY-MM-DD'),
      shop_id: '',
      shop_cd: '',
      depot_no: '',
      sale_status: '',
      pageFrom: 'prodMatch'
    };

    const onlineOptGrid_searchDetail = [
      // 쇼핑몰 선택
      {
        title: '쇼핑몰 선택',
        search_name: 'shop_cd',
        item_list: _.unionBy(channelList, 'shop_cd'),
        item_key: 'shop_name',
        item_value: 'shop_cd',
        select_value: '',
        add_class: 'select-search'
      },
      // 계정 선택
      {
        title: '쇼핑몰(ID) 선택',
        search_name: 'shop_id',
        item_list: channelList,
        item_key: 'shop_id',
        item_value: 'shop_id',
        select_value: '',
        add_class: 'select-search',
        filter: function(option) {
          return option.shop_cd == $scope.online_shop_opt_grid.searchForm.shop_cd && option.shop_id;
        }
      },
      // 상품상태 선택
      {
        title: '상품상태 선택',
        search_name: 'sale_status',
        item_list: [{ sale_status: '판매대기' }, { sale_status: '승인대기' }, { sale_status: '반려' }, { sale_status: '판매중' }, { sale_status: '종료대기' }, { sale_status: '수정대기' }, { sale_status: '판매중지' }, { sale_status: '일시품절' }],
        item_key: 'sale_status',
        item_value: 'sale_status',
        select_value: ''
      },
      // 배송처 선택
      {
        title: '배송처 선택',
        search_name: 'depot_no',
        item_list: warehouseList.data.result || [],
        item_key: 'warehouse_name',
        item_value: 'code',
        select_value: '',
        add_class: 'select-search'

      }
    ];
    /**
     * 가져온 쇼핑몰 상품 그리드
     */
    $scope.online_shop_opt_grid = {
      searchData: angular.copy(onlineOptGrid_searchData),
      searchForm: angular.copy(onlineOptGrid_searchForm),
      searchDetail: angular.copy(onlineOptGrid_searchDetail),
      searchFn: {
        searchDo: function () {
          // 미리보기 초기화
          $scope.waitBtnDisableFlag = true;
          $scope.online_shop_opt_grid.methods.reloadData(null, true, true);
        },
        changeLen: function (count) {
          $scope.online_shop_opt_grid.searchData.showCount = count;
          $scope.online_shop_opt_grid.methods.length(count);
        },
        resetDo: function () {
          $scope.online_shop_opt_grid.searchForm = angular.copy(onlineOptGrid_searchForm);
          $scope.online_shop_opt_grid.searchDetail = angular.copy(onlineOptGrid_searchDetail);
          $scope.online_shop_opt_grid.searchData.search_key_name = '전체';
          $scope.online_shop_opt_grid.searchFn.searchDo();
        },
        /**
         * @param {('map_y'|'map_n'|'total')} type 매칭타입
         */
        changeTab: function (type) {
          $scope.online_shop_opt_grid.optTab = type;
          $scope.online_shop_opt_grid.searchFn.searchDo();
        }
      },
      optTab: 'map_n',
      countList: {
        map_n: 0, // 미매칭
        map_y: 0, // 매칭완료
        total: 0, // 전체
      },
      methods: {},
      options: {
        pinningColumns: [],
        alignCenterColumns: ['img'],
        alignRightColumns: [],
        defaultSortingColumns: [],
        notSortingColumns: ['shop_name', 'img', 'opt', 'sale_opt_cnt', 'sku_cd_group', 'prod_name_group', 'attri_group', 'std_ol_yn', 'sale_status', 'depot_name', 'opt_custom_cd' ],
        notResizingColumns: [],
        notMovingColumns: [],
        notVisibleColumns: ['opt_custom_cd'],
        initPagingSize: 25,
        selectOptions: {
          notClickableOption: (row) => {
            if (['A001', 'A006'].includes(row.pa_shop_cd) && row.ebay_shop_mas_no) {
              if (!channelList.some(ch => ch.shop_cd === (row.pa_shop_cd === 'A001' ? 'A006' : 'A001') && ch.shop_id === row.ebay_shop_mas_other_id)) {
                return true;
              }
            }

            return false;
          }
        },
        familyOptions: {
          familyCountKey: 'masterCnt',
          familyDataKey: 'sale_no',
          parentDataKey: 'group_row_yn',
          parentDataValue: 1,
          parentRowTemplate: () => {}
        },
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/product/matching/ol-opts`,
          requestMethod: 'GET',
          requestWillAction: function (d) {
            const data = angular.merge({}, d, $scope.online_shop_opt_grid.searchForm);
            const optTab = $scope.online_shop_opt_grid.optTab;
            data.map_yn = ((optTab) => {
              switch (optTab) {
                case 'map_y': return 1;
                case 'map_n': return 0;
                case 'total': return '';
                default: return '';
              } })(optTab);

            return JSON.stringify(data);
          },
          requestDidAction: function (json) {
            $scope.online_shop_opt_grid.countList = json.countList;

            return json.results;
          }
        },
        columns: [
          {
            title: '쇼핑몰(계정)',
            key: 'shop_name',
            width: 130,
            template: (row) => {
              return row.group_row_yn === 1
                ? '마스터상품'
                : `<span uib-tooltip="${row.shop_name}(${row.shop_id})" tooltip-append-to-body="true" tooltip-placement="right">
                     <img src="/assets/images/sitelogo/${
  ['A001', 'A006'].includes(row.shop_cd) && row.ebay_shop_mas_no ? (row.shop_cd === 'A001' ? 'ESMA' : 'ESMG') : (row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd)
}.png" style="width: 50px;">${row.shop_id}
                   </span>`;
            }
          },
          {
            title: '이미지',
            key: 'img',
            width: 50,
            template: (row) => {
              return `<img src='${row.img}' width='30' height='30'>`;
            }
          },
          {
            title: '판매자 관리코드',
            key: 'c_sale_cd',
            width: 140,
          },
          {
            title: '상품명',
            key: 'shop_sale_name',
            width: 220,
            template: (row) => {
              const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

              return row.group_row_yn === 1
                ? `<a ng-click="grid.appScope.openOlDetail('${row.ol_shop_no}')">${filteredShopSaleName}</a>`
                : row.shop_sale_name;
            }
          },
          // 옵션
          {
            title: '옵션',
            key: 'opt',
            width: 150,
            template: (row) => {
              let opt_name;
              if (row.deal_type === '추가구매') {
                // 추가구매옵션
                opt_name = `[추가구매] ${row.add_item_opt_name}`;
              } else {
                // 일반옵션
                opt_name = `${row.opt_name1 || ''}${row.opt_name2 ? `,${row.opt_name2}` : ''}${row.opt_name3 ? `,${row.opt_name3}` : ''}`;
              }

              return row.group_row_yn === 1 ? '' : opt_name;
            }
          },
          // 수량
          {
            title: '수량',
            key: 'sale_opt_cnt',
            width: 70,
            template: (row) => {
              return row.group_row_yn === 1 ? '' : row.sale_opt_cnt || '';
            }
          },
          // SKU(세트)코드
          {
            title: 'SKU(세트)코드',
            key: 'sku_cd_group',
            width: 100,
            template: (row) => {
              const sku_cd_list = row.sku_cd_group ? row.sku_cd_group.split(',') : [];

              return row.group_row_yn === 1 && sku_cd_list.length ? `
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.searchProd('${sku_cd_list}')" uib-tooltip="마스터상품에 매칭된 SKU상품을 보여줍니다.\n마스터상품에 SKU가 매칭된 경우에만 노출합니다.">매칭됨</button>
              ` : sku_cd_list.join(',');

            }
          },
          // SKU(세트)상품명
          {
            title: 'SKU(세트)상품명',
            key: 'prod_name_group',
            width: 150,
            template: (row) => {
              return row.group_row_yn === 1 ? '' : row.prod_name_group;
            }
          },
          // 속성
          {
            title: '속성',
            key: 'attri_group',
            width: 100,
            template: (row) => {
              return row.group_row_yn === 1 ? '' : row.attri_group;
            }
          },
          // 상품유형
          {
            title: '상품 유형',
            key: 'std_ol_yn',
            width: 100,
            template: (row) => {
              return row.group_row_yn === 1 ? '' : (row.std_ol_yn === 2 ? '물류상품' : (row.std_ol_yn === 1 ? '단일상품' : '일반상품'));
            }
          },
          // 상품상태
          {
            title: '상품상태',
            key: 'sale_status',
            width: 100,
            template: (row) => {
              return row.sale_status || '';
            }
          },
          // 배송처
          {
            title: '배송처',
            key: 'depot_name',
            width: 100,
          },
          // 옵션관리코드
          {
            title: '옵션관리코드',
            key: 'opt_custom_cd',
            width: 100,
            template: (row) => {
              return row.opt_custom_cd || '';
            }
          }
        ],
      },
    };

    /**
     * 마스터상품에 매칭된 SKU상품/세트상품 검색
     * @param {object} sku_cd_list
     */
    $scope.searchProd = (sku_cd_list) => {
      // 매칭됨 선택시, 검색조건 초기화 후 SKU(세트) 검색되게함(조이, 제니 요청)
      $scope.sku_grid.searchData = angular.copy(skuGrid_searchData);
      $scope.sku_grid.searchForm = angular.copy(skuGrid_searchForm);
      $scope.sku_grid.searchDetail = angular.copy(skuGrid_searchDetail);
      $scope.set_grid.searchData = angular.copy(setGrid_searchData);
      $scope.set_grid.searchForm = angular.copy(setGrid_searchForm);
      $scope.set_grid.searchDetail = angular.copy(setGrid_searchDetail);

      $scope.sku_grid.searchForm.multi_type = 'sku_cd';
      $scope.set_grid.searchForm.multi_type = 'set_cd';

      $scope.sku_grid.searchForm.multi_search_word = sku_cd_list.split(',').join('\n');
      $scope.set_grid.searchForm.multi_search_word = sku_cd_list.split(',').join('\n');

      $scope[$scope.prod.prod_tab].searchFn.searchDo();
    };

    /**
     * 마스터상품 상세 열기
     */
    $scope.openOlDetail = (ol_shop_no) => {
      const shop_cd = 'Z000';
      const resolve = {
        data: {
          ol_shop_no,
          shop_cd,
          warehouseList: warehouseList.data.result || []
        }
      };

      resolve.prodDetail = () => {
        return onlineProductModel.detail(shop_cd, '', ol_shop_no);
      };
      resolve.madeinList = () => {
        return productModel.getMadein();
      };
      resolve.systemList = () => {
        return systemModel.load();
      };

      commonSVC.openModal('full', resolve, 'OnlineProductEditCtrl', 'views/online/product/edit.html');
    };

    // 로딩스피너 보이기
    function showSpinner(id, text) {
      $(`#${id} > div`).html(`<i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i><br />${text}`);
      $(`#${id}`).removeClass('hidden');
    }

    // 로딩스피너 숨기기
    function hideSpinner(id) {
      $(`#${id}`).addClass('hidden');
    }

    /**
     * SKU상품 신규 등록
     */
    $scope.addSkuProd = async () => {
      const selectedRows = $scope[$scope.optType].methods.selectedData('all');
      const shop_type = $scope.optType === 'online_mas_opt_grid' ? '마스터' : '쇼핑몰';

      if (!selectedRows.length) {
        commonSVC.showMessage(`선택한 ${shop_type} 상품이 없습니다.`);

        return false;
      }

      const unavailList = selectedRows.filter(v => v.sku_cd_group);
      if (unavailList.length === selectedRows.length) {
        commonSVC.showMessage(`이미 매칭된 ${shop_type} 상품입니다.`);

        return false;
      }

      if (unavailList.length) {
        const opt = {
          title: `이미 매칭된 ${shop_type} 상품이 포함되어 있습니다.`,
          text: `매칭할 ${shop_type} 상품 중 SKU(세트)상품과 매칭된 상품이 있습니다.`,
          type: 'warning',
          confirmButtonText: 'SKU상품 신규 등록이 가능한 상품만 선택'
        };

        commonSVC.showConfirmCustom(opt, function () {
          $scope[$scope.optType].methods.doSelectByFilter(function (row) {
            return !row.sku_cd_group;
          }, true);
        });

        return false;
      }
      if ($scope.optType === 'online_mas_opt_grid' && !$scope.not_show_ol_sku_map_setting) {
        const re = await $scope.setOnlineMasMatch();
        if (!re) {
          return;
        }
      }

      const params = {
        data: {
          length: selectedRows.length,
          opt_custom_cd: selectedRows.map(row => row.opt_custom_cd || null),
          isSmileProd: $scope.optType === 'online_shop_opt_grid' && selectedRows.filter(o => o.std_ol_yn === 2).length
        },
        madeinList: madeinList,
        warehouseList: warehouseList,
      };

      try {
        const modal = commonSVC.openModal('', params, 'ProdSimpleAddCtrl', 'views/online/product/modals/simple_prod_add.html');
        modal.result.then(async function (data) {
          showSpinner('skuMatchSpinner', 'SKU 상품 매칭 작업중');
          $scope.isMatch = true;
          const ol_prod_opt_list = await Promise.all(selectedRows.map(async v => ({
            ol_shop_no: v.ol_shop_no,
            sale_opt_seq: v.sale_opt_seq,
            depot_name: v.depot_name,
            sku_cd_group: v.sku_cd_group,
            opt: v.deal_type === '기초상품'
              ? (v.opt_type === '옵션없음' ? '옵션없음' : `옵션(${v.opt_name1 || ''}${v.opt_name2 ? `,${v.opt_name2}` : ''}${v.opt_name3 ? `,${v.opt_name3}` : ''})`)
              : v.add_item_opt_name,
            ebay_shop_mas_no: v.ebay_shop_mas_no,
            img: v.img,
            shop_cd: v.shop_cd,
            opt_custom_cd: v.opt_custom_cd,
            isDupOrNull: v.opt_custom_cd ? await $scope.overlabCheck(v.opt_custom_cd) : true
          })));

          const params = {
            ol_prod_opt_list: data.autoCreate === 'auto' ? ol_prod_opt_list : ol_prod_opt_list.filter(opt => data.sku_cd.includes(opt.opt_custom_cd)),
            searchParams: $scope[$scope.optType].searchForm,
            sku_cd: data.sku_cd, // SKU코드
            depot_no: data.delivery_vendor, // 배송처(출고처)
            depot_name: data.depot_name, // 배송처(출고처)
            save_type: data.save_type,
            optType: $scope.optType === 'online_mas_opt_grid' ? 'master' : 'shop',
            ol_sku_map_type: $scope.ol_sku_map_type,
            prodInfo: null,
            autoCreate: ol_prod_opt_list.length === 1 ? data.skuAuto : data.autoCreate === 'auto'
          };

          if (data.save_type === 'direct') {
            params.prodInfo = {
              prod_name: data.prod_name, // 상품명
              madein_no: data.madein_no, // 원산지
              madein_etc: data.madein_etc, // 원산지(기타)
              tax_type: data.tax_type, // 과세여부
              sale_price: data.sale_price, // 판매가
              stock_cnt_real: data.modify_real_stock, // 실재고
              contents: data.contents, // 상세설명
            };
          }

          try {
            if (params.sku_cd.length) {
              const re = await onlineProductModel.matchBase(params);

              if (re?.data?.results?.success) {
                commonSVC.showToaster('success', 'SKU상품 신규추가 성공', `${re?.data?.results?.success}건 성공 / ${re?.data?.results?.failed}건 실패`);
              } else {
                throw re;
              }
            } else {
              commonSVC.showToaster('error', '실패', '등록 가능한 SKU 상품이 없습니다.');
            }
            $timeout(function () {
              $scope[$scope.optType].searchFn.searchDo();
              hideSpinner('skuMatchSpinner');
            }, 700);
          } catch (err) {
            const msg = err?.message || err?.data?.error || err?.data?.result?.failed_reason?.join('\n');
            commonSVC.showToaster('error', 'SKU상품 신규추가 실패', msg);
            hideSpinner('skuMatchSpinner');
          }

          localStorageService.set('autoCreate', data.autoCreate);
        });

      } catch (err) {
        commonSVC.showToaster('error', '실패', err?.data?.error);

      }
    };

    $scope.overlabCheck = async function (optCustomCd) {
      const re = await productModel.skuOverlabCheck({ code: optCustomCd });
      const regx = new RegExp(/^[a-zA-Z0-9,./_+=()-]*$/);

      return await (re.data?.result || !optCustomCd || !regx.test(optCustomCd));
    };

    /**
     * 자동매칭
     */
    $scope.autoMatch = async () => {
      const base_match_list = {
        // 옵션 관리 코드
        opt_custom_cd: {
          checked: true
        },
        // 상품명(+옵션명)
        prod_name: {
          checked: true,
          percent: 75
        },
        // 브랜드
        brand: {
          checked: true,
          percent: 75
        },
        // 제조사
        maker: {
          checked: true,
          percent: 75
        },
        // 모델명
        model: {
          checked: true,
          percent: 75
        },
        // 원산지
        madein: {
          checked: true,
          percent: 75
        }
      };
      // 'online_mas_opt_grid' || 'online_shop_opt_grid'
      const opt_type = $scope.optType;
      const params = {
        prod_list: $scope[opt_type].methods.selectedData('all'),
        search_params: $scope[opt_type].searchForm,
        base_match_list,
        opt_type,
        // 'mas_and_slave' || 'mas_only'
        ol_sku_map_type: $scope.ol_sku_map_type
      };

      const LOCALSTORAGE_KEY = 'sku_autoMatch';

      const opt_option = {
        title: 'SKU상품 자동 매칭',
        text: 'SKU(세트) 미매칭 상품 중 상품명, 브랜드명, 제조사명, 모델명 항목이 유사한 상품을 자동으로 매칭합니다.',
      };

      const checkbox_opt = { show: true, checkbox_name: '직접 설정', key: LOCALSTORAGE_KEY,
        m_no: userInfo.user.m_no, checked: localStorage.getItem(`custom_checkbox_${LOCALSTORAGE_KEY}_${userInfo.user.m_no}`) === 'true',
        tooltip: '상품명(+옵션명), 모델명, 원산지, 브랜드, 제조사 항목의 유사도를 직접 입력하여 SKU상품 자동 매칭을 설정할 수 있습니다.',
      };

      await commonSVC.showConfirmCheckboxCustom(opt_option, checkbox_opt, async function (confirm) {
        const checked = localStorage.getItem(`custom_checkbox_${LOCALSTORAGE_KEY}_${userInfo.user.m_no}`);
        if (confirm) {
          if (checked === 'true') {
            const resolve = {};
            const data = {};

            data.type = 'sku_auto_match';
            data.base_match_list = base_match_list;
            resolve.data = data;
            const modal = commonSVC.openModal('lm', resolve, 'AutoMatchCtrl', 'views/online/product/modals/prod_match.html');

            modal.result.then(async function (result) {
              try {
                if ($scope.optType === 'online_mas_opt_grid' && !$scope.not_show_ol_sku_map_setting) {
                  const re = await $scope.setOnlineMasMatch();
                  if (!re) {
                    return;
                  }
                }
                await workSVC.addSolWork('ProdToSkuProd', { ...params, base_match_list: result, ol_sku_map_type: $scope.ol_sku_map_type });

              } catch (err) {
                const errMsg = err?.message || err?.data?.error || '매칭된 SKU상품이 없습니다.';
                commonSVC.showToaster('error', '상품 자동 매칭 실패', errMsg);
              }
            });

          } else {
            try {
              if ($scope.optType === 'online_mas_opt_grid' && !$scope.not_show_ol_sku_map_setting) {
                const re = await $scope.setOnlineMasMatch();
                if (!re) {
                  return;
                }
              }
              await workSVC.addSolWork('ProdToSkuProd', { ...params, ol_sku_map_type: $scope.ol_sku_map_type });
            } catch (err) {
              const errMsg = err?.message || err?.data?.error || '매칭된 SKU상품이 없습니다.';
              commonSVC.showToaster('error', '상품 자동 매칭 실패', errMsg);
            }
          }
        }
        if (!confirm) {
          return;
        }
      });
    };

    /**
     * 매칭취소
     */
    $scope.cancelMatch = async () => {
      const selectedRows = $scope[$scope.optType].methods.selectedData('all');
      if (!selectedRows.length) {
        commonSVC.showMessage('선택한 쇼핑몰 상품이 없습니다.');

        return false;
      }

      const confirm = await commonSVC.showConfirmHtml(
        `선택한 <span class="text-primary">${selectedRows.length}건</span>의 매칭을 취소하시겠습니까?`
      );

      if (!confirm) {
        return;
      }

      if ($scope.optType === 'online_mas_opt_grid' && !$scope.not_show_ol_sku_map_setting) {
        const re = await $scope.setOnlineMasMatch();
        if (!re) {
          return;
        }
      }

      const ol_prod_opt_list = selectedRows.map(v => ({
        ol_shop_no: v.ol_shop_no,
        sale_opt_seq: v.sale_opt_seq,
        depot_name: v.depot_name,
        sku_cd_group: v.sku_cd_group,
        ebay_shop_mas_no: v.ebay_shop_mas_no,
        opt: v.deal_type === '기초상품'
          ? (v.opt_type === '옵션없음' ? '옵션없음' : `옵션(${v.opt_name1 || ''}${v.opt_name2 ? `,${v.opt_name2}` : ''}${v.opt_name3 ? `,${v.opt_name3}` : ''})`)
          : v.add_item_opt_name,
      }));

      const params = {
        ol_prod_opt_list: ol_prod_opt_list,
        optType: $scope.optType === 'online_mas_opt_grid' ? 'master' : 'shop',
        ol_sku_map_type: $scope.ol_sku_map_type
      };

      try {
        const re = await onlineProductModel.cancelMatch(params);
        if (re?.data?.result?.success) {
          setTimeout(() => {
            commonSVC.showToaster('success', '매칭 취소', `${re?.data?.result?.success}건 성공 / ${re?.data?.result?.failed}건 실패`);
          }, 500);
        } else {
          throw re;
        }

        $timeout(function () {
          $scope[$scope.optType].searchFn.searchDo();
        }, 700);

      } catch (err) {
        const msg = err?.message || err?.data?.error || err?.data?.result?.failed_reason?.join('\n');
        commonSVC.showToaster('error', '매칭 취소 실패', msg);
      }
    };

    /**
     * 연동상품 매칭설정 모달 열기
     */
    $scope.setOnlineMasMatch = async function () {
      const resolve = {
        data: {
          ol_sku_map_type: $scope.ol_sku_map_type,
          not_show: $scope.not_show_ol_sku_map_setting,
        }
      };

      const re = await commonSVC.openModal('md', resolve, 'OnlineSkuMapTypeCtrl', 'views/online/product/modals/ol_sku_map_type.html').result;
      if (!re) {
        return false;
      }

      // 환경설정 전달받은 값과 기존값 비교하여 안내 alert (비교값: mas_only, mas_and_slave)
      if (re.ol_sku_map_type !== $scope.ol_sku_map_type || $scope.not_show_ol_sku_map_setting !== re.not_show) {
        commonSVC.showToaster('success', '설정 완료', '연동상품 매칭설정 변경 완료');
        // 환경설정 세팅값 업데이트 하기 - 마스터 옵션 SKU 맵 타입
        if (re.ol_sku_map_type !== $scope.ol_sku_map_type) {
          const param = {
            ol_sku_map_type: re.ol_sku_map_type,
          };
          await systemModel.set({ change_val: param });
          $scope.ol_sku_map_type = re.ol_sku_map_type;
        }
      }

      // 다시보지 않기
      if (re.not_show) {
        // 토큰 설정
        localStorage.setItem('not_show_ol_sku_map_setting', JSON.stringify(re.not_show));
      } else {
        // 토큰값 삭제
        localStorage.removeItem('not_show_ol_sku_map_setting');
      }

      $scope.not_show_ol_sku_map_setting = re.not_show;

      return true;
    };

    /** ************************************************************** 솔루션 재고관리 상품 > SKU ************************************************************** */

    $scope.pack_unit = { sku: {}, set: {} }; // 매칭 수량 입력
    $scope.prod = {
      prod_tab: 'sku_grid',
      /**
         * @param {('map_y'|'map_n'|'total')} type 매칭타입
         */
      changeTab: function (prod_tab) {
        $scope.prod.prod_tab = prod_tab;
        $scope[prod_tab].searchFn.searchDo();
      }
    };

    const skuGrid_searchData = {
      totalCount: 0,       // 검색전체 건수
      showCount: 25, // 한 페이지에 보이는 건수
      haveBoxContent: false,
      showDetailSearchArea: true,
      showDateSearch: false,
      search_key_items: [  // 검색영역 키값
        { label: '전체', value: 'all' },
        { label: 'SKU코드', value: ['sku_cd'] },
        { label: '재고관리코드', value: ['stock_cd'] },
        { label: 'SKU상품명', value: ['prod_name'] },
        { label: '속성명', value: ['attri'] },
        { label: '바코드', value: ['barcode'] },
        { label: '모델명', value: ['model'] }
      ],
      search_multi_items: [
        { label: 'SKU코드', value: 'sku_cd' },
        { label: '재고관리코드', value: 'stock_cd' }
      ]
    };

    const skuGrid_searchForm = {
      search_key: 'all', //검색구분
      search_word: '', //검색어
      delivery_vendor: '',
      supp_no: '',
      stock_status: '',
      bundle_avail_yn: '',
      multi_type: 'sku_cd',
      multi_search_word: '',
    };

    const skuGrid_searchDetail = [
      // 배송처 선택
      {
        title: '배송처 선택',
        search_name: 'delivery_vendor',
        item_list: warehouseList.data.result || [],
        item_key: 'warehouse_name',
        item_value: 'code',
        select_value: '',
        add_class: 'select-search'

      },
      // 매입처 선택
      {
        title: '매입처 선택',
        search_name: 'supp_no',
        item_list: Array.from(supplier) || [],
        item_key: 'supp_name',
        item_value: 'supp_no',
        select_value: '',
        add_class: 'select-search'

      },
      // 상태 검색
      {
        title: '상태 선택',
        search_name: 'stock_status',
        item_list: [
          { stock_status: '정상', },
          { stock_status: '품절' },
          { stock_status: '재고부족' }
        ],
        item_key: 'stock_status',
        item_value: 'stock_status',
        select_value: ''
      },
      // 합포장여부 선택
      {
        title: '합포장여부 선택',
        search_name: 'bundle_avail_yn',
        item_list: [
          { code: 1, name: '가능' },
          { code: 0, name: '불가(개별배송)' }
        ],
        item_key: 'name',
        item_value: 'code',
        select_value: ''
      }
    ];

    $scope.sku_grid = {
      searchData: angular.copy(skuGrid_searchData),
      searchForm: angular.copy(skuGrid_searchForm),
      searchDetail: angular.copy(skuGrid_searchDetail),
      searchFn: {
        searchDo: function () {
          $scope.sku_grid.methods.reloadData(null, true, true);
        },
        changeLen: function (count) {
          $scope.sku_grid.searchData.showCount = count;
          $scope.sku_grid.methods.length(count);
        },
        // 검색 초기화
        resetDo: function () {
          $scope.sku_grid.searchForm = angular.copy(skuGrid_searchForm);
          $scope.sku_grid.searchDetail = angular.copy(skuGrid_searchDetail);
          $scope.sku_grid.searchData.search_key_name = '전체';
          $scope.sku_grid.searchFn.searchDo();
        }
      },
      methods: {},
      options: {
        pinningColumns: [],
        alignCenterColumns: ['widget', 'prod_img', 'sale_price', 'sale_cnt_limit'],
        alignRightColumns: [],
        defaultSortingColumns: [],
        notSortingColumns: [ 'widget', 'prod_img', 'output_cnt', 'sale_price', 'real_stock', 'depot_name', 'supp_name', 'bundle_avail_yn', 'stock_status', 'model', 'brand'],
        notResizingColumns: ['widget', 'prod_img'],
        notMovingColumns: [],
        notVisibleColumns: [],
        initPagingSize: 25,
        selectOptions: {
          multiSelect: false
        },
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/stock/matching-list-of-base`,
          requestWillAction: function (data) {
            data = angular.merge({}, data, $scope.sku_grid.searchForm);

            return JSON.stringify(data);
          },
          requestDidAction: function (result) {
            $scope.sku_grid.searchData.totalCount = result.recordsTotal;
            result.results.forEach(data => {
              $scope.pack_unit.sku['' + data.prod_no + data.depot_no] = 1;
            });

            return result.results;
          }
        },
        columns: [
          // 도구
          {
            title: '도구',
            key: 'widget',
            width: 70,
            template: function (row) {
              return `<button class="btn btn-primary btn-xxs" ng-click="grid.appScope.matchProd('sku', '${row.prod_no}', '${row.sku_cd}', '${row.depot_no}', '${row.depot_name}', '${row.ebaydepot_yn}');">매칭</button>`;
            }
          },
          // 이미지
          {
            title: '이미지',
            key: 'prod_img',
            width: 50,
            template: function (row) {
              return `<img src='${row.prod_img}' width='30' height='30'>`;
            }
          },
          // SKU코드
          {
            title: 'SKU코드',
            key: 'sku_cd',
            width: 110
          },
          // 상품명
          {
            title: '상품명',
            key: 'prod_name',
            width: 220,
            template: (row) => {
              return `<a ng-click="grid.appScope.openSkuDetail(${row.prod_no}, '${row.sku_cd}')">${row.prod_name}</a>`;
            }
          },
          // 속성
          {
            title: '속성',
            key: 'attri',
            width: 80
          },
          // 출고수량
          {
            title: '출고수량',
            key: 'output_cnt',
            width: 80,
            template: function (row) {
              return `<input type="text" comma maxlength="6" class="form-control input-xs2" name="sku_pack_unit_${row.prod_no}" ng-model="grid.appScope.pack_unit.sku[${'' + row.prod_no + row.depot_no }]">`;
            }
          },
          // 판매가
          {
            title: '판매가',
            key: 'sale_price',
            width: 75,
            template: function (row) {
              return `${$filter('currency')(row.sale_price, '', 0)}원`;
            }
          },
          // 실재고
          {
            title: '실재고',
            key: 'real_stock',
            width: 75,
          },
          // 배송처
          {
            title: '배송처',
            key: 'depot_name',
            width: 75,
          },
          // 매입처
          {
            title: '매입처',
            key: 'supp_name',
            width: 75,
          },
          // 합포장 가능여부
          {
            title: '합포장 가능여부',
            key: 'bundle_avail_yn',
            width: 75,
            template: function (row) {
              return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
            }
          },
          // 상태
          {
            title: '상태',
            key: 'stock_status',
            width: 75,
          },
          // 모델명
          {
            title: '모델명',
            key: 'model',
            width: 75,
          },
          // 브랜드
          {
            title: '브랜드',
            key: 'brand',
            width: 75,
          },
        ]
      },
    };

    /**
     * SKU 상세 열기
     */
    $scope.openSkuDetail = (prod_no, sku_cd) => {
      const resolve = {
        data: {
          number: prod_no,
          sku: sku_cd,
          detailType: 'edit'
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };

      commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

    };

    /** ************************************************************** 솔루션 재고관리 상품 > SET ************************************************************** */

    const setGrid_searchData = {
      totalCount: 0,       // 검색전체 건수
      showCount: 25, // 한 페이지에 보이는 건수
      haveBoxContent: false,
      showDetailSearchArea: true,
      showDateSearch: false,
      search_key_items: [  // 검색영역 키값
        { label: '전체', value: 'all' },
        { label: '세트코드', value: ['set_cd'] },
        { label: '세트상품명', value: ['set_name'] },
        { label: 'SKU코드', value: ['sku_cd'] },
        { label: 'SKU상품명', value: ['prod_name'] },
        { label: '메모', value: ['memo'] }
      ],
      search_multi_items: [
        { label: '세트코드', value: 'set_cd' },
      ]
    };

    const setGrid_searchForm = {
      search_key: 'all', //검색구분
      search_word: '', //검색어
      delivery_vendor: '',
      stock_status: '',
      multi_type: 'set_cd',
      multi_search_word: '',
    };

    const setGrid_searchDetail = [
      {
        title: '배송처 선택',
        search_name: 'delivery_vendor',
        item_list: warehouseList.data.result || [],
        item_key: 'warehouse_name',
        item_value: 'code',
        select_value: '',
        add_class: 'select-search'

      },
      // 상태 검색
      {
        title: '상태 선택',
        search_name: 'set_status',
        item_list: [
          { set_status: '정상', },
          { set_status: '재고부족' },
          { set_status: '사용불가' },
          { set_status: '품절' },
        ],
        item_key: 'set_status',
        item_value: 'set_status',
        select_value: ''
      },
    ];

    $scope.set_grid = {
      searchData: angular.copy(setGrid_searchData),
      searchForm: angular.copy(setGrid_searchForm),
      searchDetail: angular.copy(setGrid_searchDetail),
      searchFn: {
        searchDo: function () {
          $scope.set_grid.methods.reloadData(null, true, true);
        },
        changeLen: function (count) {
          $scope.set_grid.searchData.showCount = count;
          $scope.set_grid.methods.length(count);
        },
        // 검색 초기화
        resetDo: function () {
          $scope.set_grid.searchForm = angular.copy(setGrid_searchForm);
          $scope.set_grid.searchDetail = angular.copy(setGrid_searchDetail);
          $scope.set_grid.searchData.search_key_name = '전체';
          $scope.set_grid.searchFn.searchDo();
        }
      },
      methods: {},
      options: {
        pinningColumns: [],
        alignCenterColumns: ['widget', 'sale_price'],
        alignRightColumns: [],
        defaultSortingColumns: [],
        notSortingColumns: ['widget', 'make_info', 'output_cnt'],
        notResizingColumns: ['widget'],
        notMovingColumns: [],
        notVisibleColumns: [],
        initPagingSize: 25,
        selectOptions: {
          multiSelect: false
        },
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/stock/set/list`,
          requestWillAction: function (data) {
            data = angular.merge({}, data, $scope.set_grid.searchForm);

            return JSON.stringify(data);
          },
          requestDidAction: function (result) {
            $scope.set_grid.searchData.totalCount = result.recordsTotal;

            result.results.forEach(data => {
              $scope.pack_unit.set[data.set_no] = 1;
            });

            return result.results;
          }
        },
        columns: [
          // 도구
          {
            title: '도구',
            key: 'widget',
            width: 70,
            template: function (row) {
              return `<button class="btn btn-primary btn-xxs" ng-click="grid.appScope.matchProd('set', '${row.set_no}', '${row.set_cd}', '${row.depot_no}', '${row.delivery_vendor_name}', '${row.ebaydepot_yn}', '${row.main_prod_no}');">매칭</button>`;
            }
          },
          // 세트코드
          {
            title: '세트코드',
            key: 'set_cd',
            width: 110
          },
          // 상품명
          {
            title: '상품명',
            key: 'set_name',
            width: 220,
            template: (row) => {
              return `<a ng-click="grid.appScope.openSetDetail(${row.set_no})">${row.set_name}</a>`;
            }
          },
          // 세트구성 SKU상품 (개수 출력)
          {
            title: '세트구성 SKU상품',
            key: 'set_prod_cnt',
            width: 80,
          },
          // 배송처
          {
            key: 'delivery_vendor_name',
            title: '배송처',
            width: 150
          },
          // 출고수량
          {
            title: '출고수량',
            key: 'output_cnt',
            width: 80,
            template: function (row) {
              return `<input type="text" comma maxlength="6" class="form-control input-xs2" name="set_pack_unit_${row.set_no}" ng-model="grid.appScope.pack_unit.set[${row.set_no}]">`;
            }
          },
          // 판매가
          {
            title: '판매가',
            key: 'sale_price',
            width: 75,
            template: function (row) {
              return `${$filter('currency')(row.sale_price, '', 0)}원`;
            }
          },
          // 세트판매수량
          {
            title: '세트 판매수량',
            key: 'sale_cnt_limit',
            width: 100,
            template: function (row) {
              return row.use_sale_cnt_limit_yn ? row.sale_cnt_limit : '미사용';
            }
          },
        ]
      },
    };

    /**
     * 세트 상세보기
     */
    $scope.openSetDetail = function(set_no) {
      const resolve = {
        data: { from: '수정', set_no: set_no }
      };
      commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
    };

    /**
     * 솔루션 상품 [매칭] 버튼 클릭 이벤트
     */
    $scope.matchProd = async (type, prod_no, prod_cd, depot_no, depot_name, ebaydepot_yn, main_prod_no) => {
      // 선택된 쇼핑몰 상품이 있는지 체크
      const selectedRows = $scope[$scope.optType].methods.selectedData('all');

      if (!selectedRows.length) {
        commonSVC.showMessage('선택한 쇼핑몰 상품이 없습니다.');

        return false;
      }

      if (selectedRows.some(o => o.std_ol_yn === 2) && !parseInt(ebaydepot_yn)) {
        commonSVC.showToaster('error', '매칭 실패', 'SKU배송처를 확인해주세요.\nG마켓물류배송처 SKU만 매칭 가능합니다.');

        return false;
      }

      if ($scope.optType === 'online_mas_opt_grid' && !$scope.not_show_ol_sku_map_setting) {
        const re = await $scope.setOnlineMasMatch();
        if (!re) {
          return;
        }
      }

      showSpinner('skuMatchSpinner', 'SKU 상품 매칭 작업중');
      $scope.isMatch = true;

      const pack_unit = $scope.pack_unit[type][type === 'set' ? prod_no : '' + prod_no + depot_no];
      const ol_prod_opt_list = selectedRows.map(v => ({
        ol_shop_no: v.ol_shop_no,
        sale_opt_seq: v.sale_opt_seq,
        depot_name: v.depot_name,
        sku_cd_group: v.sku_cd_group,
        pack_unit: pack_unit,
        opt: v.deal_type === '기초상품'
          ? (v.opt_type === '옵션없음' ? '옵션없음' : `옵션(${v.opt_name1 || ''}${v.opt_name2 ? `,${v.opt_name2}` : ''}${v.opt_name3 ? `,${v.opt_name3}` : ''})`)
          : v.add_item_opt_name,
        ebay_shop_mas_no: v.ebay_shop_mas_no,
        std_ol_yn: v.std_ol_yn
      }));

      const params = {
        type: type,
        ol_prod_opt_list: ol_prod_opt_list,
        prod_no: prod_no,
        prod_cd: prod_cd,
        depot_no: depot_no,
        depot_name: depot_name,
        main_prod_no: main_prod_no,
        optType: $scope.optType === 'online_mas_opt_grid' ? 'master' : 'shop',
        ol_sku_map_type: $scope.ol_sku_map_type
      };

      try {
        const re = await onlineProductModel.matchProd(params);
        if (re?.data?.result?.success) {
          setTimeout(() => {
            commonSVC.showToaster('success', '매칭 성공', `${re?.data?.result?.success}건 성공 / ${re?.data?.result?.failed}건 실패`);
          }, 500);
        } else {
          throw re;
        }

        $timeout(function () {
          $scope[$scope.optType].searchFn.searchDo();
          hideSpinner('skuMatchSpinner');
        }, 700);

      } catch (err) {
        const msg = err?.message || err?.data?.error || err?.data?.result?.failed_reason?.join('\n');
        commonSVC.showToaster('error', '매칭 실패', msg);
      }
    };

  });