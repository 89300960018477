'use strict';

angular.module('gmpApp')

  .directive('leftContent', function ($rootScope) {

    return {
      restrict: 'EA',
      scope: {
        searchFn: '=',
        searchData: '=',
        searchForm: '=',
        searchDetail: '=',
        searchDetailMulti: '=', // 2018-08-29 gargamel 쇼핑몰 멀티검색을 위해 추가
        // 2019-03-05 멀티검색 제외시킴 (단일검색에서 겹쳐서). Amelia
        // searchDetailMultiSelect: '=', // 상세검색 필드 중 멀티검색으로 검색어 입력되는 목록 (가능항목: 쇼핑몰, 배송처, 주문상태)
        searchDetailConditional: '=',    // 주문집계 페이지 내 조건 검색영역
        searchDetailAsync: '=',          // 비동기 검색영역 -> input영역에 텍스트 입력 후 엔터 시 api 호출해서 데이터 조회
        searchDetailAddcolumn: '=',      // 주문집계 페이지 내 추가항목 검색영역
        searchBtn: '=',
        dtInstance: '=', // 2017-12-29 chris 데이터테이블 이벤트 처리위해 추가
        dtInstance2: '=', // 2017-12-29 chris 데이터테이블 이벤트 처리위해 추가
        isDetailShow: '=?', // 2017-03-10 MatthewKim 상세 보기 모드 여부
        detailSearchFlag: '=?', // 상세검색열기 인자로 받음 2019-02-14 rony
        maxSpanMonths: '=?', // 최대 조회 기간 (월)
        maxSpanYears: '=?', // 최대 조회 기간 (년)
      },
      templateUrl: 'views/tpls/leftContent.html',
      link: function ($scope, elem) {
        $scope.tabIndex = 0; // 0:검색, 1:요약, 2:메모, 3:로그, 4:상품집계
        $scope.el_id = elem.attr('id');
        $scope.select_uniq = '';
        $scope.show = {};
        $scope.maxSpanMonths = +($scope.maxSpanMonths || 0);
        $scope.maxSpanYears = +($scope.maxSpanYears || 0);
        $scope.isDisabled = false; // 쇼핑몰 상품, SKU 상품 조회 시 검색 키값 전체인 경우 날짜검색 전체 비활성화
        // 주문관리
        $scope.location = 'ord';
        // SKU상품
        if (_.includes(['prod_prodlist_grid_searchbar', 'inventory_list_grid_0_searchbar', 'inventory_inout_list_searchbar', 'sub_prod_prodlist_grid_searchbar'], $scope.el_id)) {
          $scope.location = 'prod';
        }
        // SKU상품 세트
        else if ($scope.el_id == 'prod_setlist_grid_searchbar') {
          $scope.location = 'prod_set';
        }
        // 온라인 상품
        else if ($scope.el_id == 'online_product_grid_searchbar' || $scope.el_id == 'sub_online_product_grid_searchbar') {
          $scope.location = 'ol';
        }
        // 통계
        else if ($scope.el_id == 'summary_grid_searchbar') {
          $scope.location = 'summary';
        }
        // 문의
        else if ($scope.el_id == 'customer_inquiry_grid_searchbar') {
          $scope.location = 'cs';
        }
        // 베송처관리
        else if ($scope.el_id == 'warehouse_grid_0_searchbar') {
          $scope.location = 'depot';
        }
        // 매입처관리
        else if ($scope.el_id == 'supplier_list_grid_0_searchbar') {
          $scope.location = 'supp';
        }
        // 매칭규칙
        else if ($scope.el_id == 'mapping_rule_grid_searchbar') {
          $scope.location = 'map';
        }
        // 수선 as
        else if ($scope.el_id == 'as_grid_searchbar') {
          $scope.location = 'as';
        } else if ($scope.el_id === 'config_user_grid_searchbar') { // 사용자 관리
          $scope.location = 'user';
        }
        // 메모관리
        else if ($scope.el_id === 'memo_list_grid_searchbar') {
          $scope.location = 'memo_etc';
        }
        // 사은품관리
        else if ($scope.el_id == 'gift_grid_searchbar') {
          $scope.location = 'gift';
        }
        // 정산관리
        else if (_.includes(['cal_detail_grid_searchbar'], $scope.el_id)) {
          $scope.location = 'sett';
        }
        // 알림톡
        else if ($scope.el_id === 'sendTotal') {
          $scope.location = 'atalk';
        }
        else if ($scope.el_id === 'autoSend') {
          $scope.location = 'atalk_auto_reg';
        }
        // 위탁주문관리
        else if ($scope.el_id.includes('consignment')) {
          $scope.location = 'consignment';
        }

        // 쇼핑몰 상품 ,sku상품은 검색키 전체일 경우 기간 1년 제한처리(조회 속도 이슈)
        if (['prod_prodlist_grid_searchbar', 'online_product_grid_searchbar', 'sub_online_product_grid_searchbar', 'sub_prod_prodlist_grid_searchbar'].includes($scope.ed_id) && $scope.searchForm.search_key === 'all') {
          $scope.isDisabled = true;
        }

        $scope.show[$scope.el_id] = localStorage.getItem(`${$scope.el_id}_leftContent`) === 'true';
        // if ($scope.contentHide) {
        //   $('.left-content-toggle').removeClass('hide');
        // }

        let rowData = {};
        let selectedAllRowList = [];
        // 검색 인풋 탭 확인

        const memo_arr = ['customer_inquiry_grid_searchbar', 'summary_grid_searchbar', 'warehouse_grid_0_searchbar', 'supplier_list_grid_0_searchbar', 'template_grid_searchbar',
          'cal_state_grid_searchbar', 'cal_detail_grid_searchbar', 'cal_order_grid_searchbar', 'work_grid_searchbar', 'mapping_log_grid_searchbar', 'del_log_grid_searchbar',
          'excel_log_grid_searchbar', 'pay_grid_searchbar', 'user_grid_searchbar', 'question_grid_searchbar', 'mapping_rule_grid_searchbar', 'notice_grid_searchbar', 'sub_notice_grid_searchbar', 'sub_online_product_grid_searchbar',
          'shop_grid_searchbar', 'stock_alloc_log_grid_searchbar', 'auto_soldout_log_grid_searchbar', 'configs_log_grid_searchbar',
          'config_user_grid_searchbar', 'config_sub_user_grid_searchbar', 'order_agency_grid_searchbar', 'inventory_inout_list_searchbar', 'gift_grid_searchbar', 'sendTotal', 'autoSend', 'user_connect_history_grid_searchbar', 'summary_aggregation_grid_searchbar'
        ];

        // 로그 탭 확인
        const log_arr = ['pay_grid_searchbar', 'work_grid_searchbar', 'del_log_grid_searchbar', 'mapping_log_grid_searchba', 'excel_log_grid_searchbar', 'cal_state_grid_searchbar',
          'cal_order_grid_searchbar', 'template_grid_searchbar', 'question_grid_searchbar', 'notice_grid_searchbar', 'shop_grid_searchbar', 'sub_notice_grid_searchbar',
          'stock_alloc_log_grid_searchbar', 'auto_soldout_log_grid_searchbar', 'configs_log_grid_searchbar', 'memo_list_grid_searchbar', 'summary_grid_searchbar', 'config_sub_user_grid_searchbar', 'sendTotal', 'user_connect_history_grid_searchbar', 'summary_aggregation_grid_searchbar'
        ];

        // 탭노출 조건
        $scope.tabShow = {
          search: true,
          summary: $scope.el_id.search('shipment_grid') >= 0 || $scope.el_id === 'customer_inquiry_grid_searchbar', // 주문, 문의에서만 출력
          oltree: $scope.el_id === 'online_product_grid_searchbar', // 쇼핑몰 상품 관리에서만 출력
          memo: !_.includes(memo_arr, $scope.el_id),
          log: !_.includes(log_arr, $scope.el_id)
        };

        $scope.$on('leftContentShow', function () {
          if ($scope.show[$scope.el_id]) {
            $scope.contentToggle();
          }
        });

        // 데이터 테이블에서 row선택시 선택한 row 정보 받아옴
        $scope.$parent.$on('OnSelectChangeBefore', _.debounce((event, data) => {
          rowData = data;
          tabActionDo();
        }, 200));

        // 데이터 테이블에서 선택한 rowList 정보 받아옴
        $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
          selectedAllRowList = data;
        }, 200));

        // 탭 변경
        $scope.tabAction = function (nv) {

          if ($scope.tabIndex === nv) { return; }

          $scope.tabIndex = nv;
          if ($scope.searchFn.leftContentsTabChange) {
            $scope.searchFn.leftContentsTabChange(nv);
          }
          tabActionDo();
        };

        // 그리드 선택값 초기화
        $scope.rowSelectInit = function () {
          rowData = {};
        };
        // 페이지 컨트롤러 액션처리용
        $scope.$parent.leftTabAction = $scope.tabAction;

        $scope.contentToggle = () => {
          $scope.show[$scope.el_id] = !$scope.show[$scope.el_id];
          localStorage.setItem(`${$scope.el_id}_leftContent`, $scope.show[$scope.el_id]);

          if ($scope.show[$scope.el_id]) {
            $(`#${$scope.el_id}`).addClass('left-content-hide');
            $(`#${$scope.el_id} > .a-sidebar-secondary`).addClass('w-10');
          } else {
            $(`#${$scope.el_id}`).removeClass('left-content-hide');
            $(`#${$scope.el_id} > .a-sidebar-secondary`).removeClass('w-10');
          }
          $('.toggle-btn').removeClass('toggle-btn-active');

          if ($scope.dtInstance) {
            $scope.dtInstance.setColumnAutoFitWidth();
          }
        };

        const leftContentInit = () => {
          // $scope.show[$scope.el_id] = false;
          // $('.left-content-toggle').on('mouseover', () => {
          //   if ($scope.contentHide) {
          //     $('.toggle-btn').addClass('toggle-btn-active');
          //   }
          // });

          // $('.left-content-toggle').on('mouseout', () => {
          //   $('.toggle-btn').removeClass('toggle-btn-active');
          // });

          $('.toggle-btn').on('mouseover', (e) => {
            e.preventDefault();
            if ($scope.show[$scope.el_id]) {
              $('.toggle-nav').stop().slideDown(150);
            }
          });

          $('.left-content-toggle').on('mouseleave', (e) => {
            e.preventDefault();
            $('.toggle-nav').stop().slideUp(200);
          });

          $(`#${$scope.el_id} > #left-content`).on('mouseover', () => {
            $('.left-content-toggle').removeClass('hide');
          });

          $(`#${$scope.el_id} > #left-content`).on('mouseleave', () => {
            if (!$scope.show[$scope.el_id]) {
              $('.left-content-toggle').addClass('hide');
            }
          });

          if ($scope.show[$scope.el_id]) {
            $(`#${$scope.el_id}`).addClass('left-content-hide');
            $(`#${$scope.el_id} > .a-sidebar-secondary`).addClass('w-10');
            $('.left-content-toggle').removeClass('hide');
          } else {
            $(`#${$scope.el_id}`).removeClass('left-content-hide');
          }
        };

        setTimeout(() => {
          leftContentInit();
        });

        // 메모 & 로그에 출력되는 Accordion 기본값 설정
        function initAccordionTitle(orderList, selectData) {
          if (!selectData) { return; }

          // 묶음 주문인 경우 메모를 아코드언으로 보여줌
          const accordionList = [];
          let uniqList = [];
          let uniqs = [];
          let one = {};

          if (['ord', 'consignment'].includes($scope.location)) {
            uniqList = selectedAllRowList.map(({ uniq }) => uniq);

            if (orderList.length) {
              uniqs = _(orderList).filter(function (o) {
                if (selectData.bundle_no && o.bundle_no === selectData.bundle_no) {
                  return o;
                } else if (o.uniq === selectData.uniq) {
                  return o;
                }
              })
                .map(function (row) {

                  const one = {
                    uniq: row.uniq,
                    title: `<div class="a-accordion-title">${row.shop_sale_name} </div><div class="a-accordion-text">${row.shop_opt_name} </div><div class="a-accordion-text">${row.shop_ord_no}</div>`,
                    list: []
                  };

                  accordionList.push(one);

                  return row.uniq;
                })
                .value();
            } else {
              uniqs = selectData.uniq;
              one = {
                uniq: selectData.uniq,
                title: `${selectData.shop_sale_name} ${selectData.shop_opt_name} ${selectData.shop_ord_no}`,
                list: []
              };

              accordionList.push(one);
            }

          } else if ($scope.location === 'prod') {
            uniqList = selectedAllRowList.map(({ prod_no }) => prod_no);
            uniqs = selectData.prod_no;
            one = {
              uniq: selectData.prod_no,
              title: `${selectData.prod_name} ${selectData.sku_cd}`,
              list: []
            };

            accordionList.push(one);
          } else if ($scope.location === 'prod_set') {
            uniqList = selectedAllRowList.map(({ set_no }) => set_no);
            uniqs = selectData.set_no;
            one = {
              uniq: selectData.set_no,
              title: `${selectData.set_name} ${selectData.set_cd}`,
              list: []
            };

            accordionList.push(one);
          } else if ($scope.location === 'ol') {
            uniqList = selectedAllRowList.map(({ ol_shop_no }) => ol_shop_no);
            uniqs = selectData.ol_shop_no;
            one = {
              uniq: selectData.ol_shop_no,
              title: `${selectData.shop_name} ${selectData.shop_sale_name}`,
              list: []
            };

            accordionList.push(one);
          } else if ($scope.location === 'cs') {
            uniqList = selectedAllRowList.map(({ inq_uniq }) => inq_uniq);
            uniqs = selectData.inq_uniq;
            one = {
              uniq: selectData.inq_uniq,
              title: selectData.inq_title,
              list: []
            };

            accordionList.push(one);
          } else if ($scope.location === 'depot') {
            uniqList = selectedAllRowList.map(({ depot_no }) => depot_no);
            uniqs = selectData.depot_no;
            one = {
              uniq: selectData.depot_no,
              title: selectData.depot_name,
              list: []
            };

            accordionList.push(one);
          } else if ($scope.location === 'supp') {
            uniqList = selectedAllRowList.map(({ supp_no }) => supp_no);
            uniqs = selectData.supp_no;
            one = {
              uniq: selectData.supp_no,
              title: selectData.supp_name,
              list: []
            };

            accordionList.push(one);
          } else if ($scope.location === 'map') {
            uniqList = selectedAllRowList.map(({ map_no }) => map_no);
            uniqs = selectData.map_no;
            one = {
              uniq: selectData.map_no,
              title: selectData.shop_sale_no,
              list: []
            };

            accordionList.push(one);
          } else if ($scope.location === 'as') {
            uniqList = selectedAllRowList.map(({ mend_no }) => mend_no);
            uniqs = selectData.mend_no;
            one = {
              uniq: selectData.mend_no,
              title: selectData.as_sale_name,
              list: []
            };

            accordionList.push(one);
          } else if ($scope.location === 'user') {
            uniqList = selectedAllRowList.map(({ m_no }) => m_no);
            uniqs = selectData.m_no;
            one = {
              uniq: selectData.m_no,
              title: `${selectData.name} (${selectData.email})`,
              list: []
            };

            accordionList.push(one);
          } else if ($scope.location === 'gift') {
            uniqList = selectedAllRowList.map(({ gift_no }) => gift_no);
            uniqs = selectData.gift_no;
            one = {
              uniq: selectData.gift_no,
              title: selectData.gift_rule_name,
              list: []
            };

            accordionList.push(one);
          } else if ($scope.location === 'sett') {
            uniqList = selectedAllRowList.map(({ sett_uniq }) => sett_uniq);
            uniqs = selectData.sett_uniq;
            one = {
              uniq: selectData.sett_uniq,
              title: selectData.shop_ord_no || '-',
              list: []
            };

            accordionList.push(one);
          } else if ($scope.location === 'atalk_auto_reg') {
            uniqList = selectedAllRowList.map(({ id }) => id);
            uniqs = selectData.id;
            one = {
              uniq: `${selectData.id}`,
              title: selectData.send_title,
              list: []
            };

            accordionList.push(one);
          }

          return {
            uniqList,
            uniqs,
            accordionList,
            sol_no: selectData.sol_no,
          };
        }

        // 현재 탭에 맞는 내용 출력
        function tabActionDo() {
          // 요약, 메모, 로그탭 초기화
          if ($scope.tabMemoInit) { $scope.tabMemoInit(); }
          if ($scope.tabOrderPreviewInit) { $scope.tabOrderPreviewInit(); }
          if ($scope.tabLogInit) { $scope.tabLogInit(); }

          // 그리드에서 선택한게 없으면 아무것도 안함
          if (rowData.lastSelectedOrdRowIndex < 0 || rowData.lastSelectedInqRowIndex < 0 || !rowData.selectData) { return; }
          let orderList = [];
          let inquiryList = [];
          let initData = [];
          const params = {};

          if ($scope.location === 'ord') {
            $scope.select_uniq = rowData.selectData.uniq;
            params.memo_code = 'uniq';
            params.type = 'ord';
            params.location = 'order_shipment';
          } else if ($scope.location === 'cs') {
            $scope.select_uniq = rowData.selectData.inq_uniq;
            params.location = 'customer_inquiry';
          } else if ($scope.location === 'prod') {
            $scope.select_uniq = rowData.selectData.prod_no;
            params.memo_code = 'prod_no';
            params.location = 'prod_product';
            params.type = 'prod';
          } else if ($scope.location === 'prod_set') {
            $scope.select_uniq = rowData.selectData.set_no;
            params.memo_code = 'set_no';
            params.location = 'depot_set';
            params.type = 'depot_set';
          } else if ($scope.location === 'ol') {
            $scope.select_uniq = rowData.selectData.ol_shop_no;
            params.memo_code = 'ol_shop_no';
            params.location = 'online_product';
            params.type = 'ol_shop';
          } else if ($scope.location === 'depot') {
            $scope.select_uniq = rowData.selectData.depot_no;
            params.location = 'warehouse';
          } else if ($scope.location === 'supp') {
            $scope.select_uniq = rowData.selectData.supp_no;
            params.location = 'supplier';
          } else if ($scope.location === 'map') {
            $scope.select_uniq = rowData.selectData.map_no;
            params.location = 'mapping_rule';
          } else if ($scope.location === 'as') {
            $scope.select_uniq = rowData.selectData.mend_no;
            params.memo_code = 'source_no';
            params.type = 'mend';
            params.location = 'as';

          } else if ($scope.location === 'user') {
            $scope.select_uniq = rowData.selectData.m_no;

            params.memo_code = 'm_no';
            params.type = 'mem';
            params.location = 'user';
          } else if ($scope.location === 'gift') {
            $scope.select_uniq = rowData.selectData.gift_no;
            params.location = 'gift';
          } else if ($scope.location === 'sett') {
            $scope.select_uniq = rowData.selectData.sett_uniq;
            params.location = 'sett';
          } else if ($scope.location === 'atalk_auto_reg') {
            $scope.select_uniq = rowData.selectData.id;
            params.location = 'atalk_auto_reg';
          } else if ($scope.location === 'consignment') {
            $scope.select_uniq = rowData.selectData.uniq;
            params.memo_code = 'uniq';
            params.type = 'ord';
            params.location = 'order_shipment';
          }

          // 요약
          if ($scope.tabIndex === 1) {
            if ($scope.searchFn.getOrderList) { orderList = $scope.searchFn.getOrderList(); }
            if ($scope.searchFn.getInquiryList) { inquiryList = $scope.searchFn.getInquiryList(); }
            const lastSelectedOrdRowIndex = orderList.findIndex((row) => {
              return rowData.selectData.uniq == row.uniq;
            });
            const lastSelectedInqRowIndex = inquiryList.findIndex((row) => {

              return rowData.selectData.inq_uniq == row.inq_uniq;
            });

            $scope.tabOrderPreview(orderList, lastSelectedOrdRowIndex);
            $scope.tabInquiryPreview(inquiryList, lastSelectedInqRowIndex);
          }
          // 메모
          else if ($scope.tabIndex === 2) {
            if ($scope.location === 'memo_etc') {
              // 2020-03-11 Boris
              // 메모 페이지에서 보는 메모 특별처리
              switch (rowData.selectData.memo_type) {
                case 'ord':
                  initData = {
                    uniqList: selectedAllRowList.map(({ uniq }) => uniq),
                    uniqs: rowData.selectData.uniq,
                    accordionList: [{
                      uniq: rowData.selectData.uniq,
                      title: `<div class="a-accordion-title">${rowData.selectData.ord_shop_sale_name} </div><div class="a-accordion-text">${rowData.selectData.ord_shop_opt_name} </div><div class="a-accordion-text">${rowData.selectData.ord_shop_ord_no}</div>`,
                      list: []
                    }]
                  };
                  $scope.select_uniq = rowData.selectData.uniq;
                  params.memo_code = 'uniq';
                  params.type = 'ord';
                  break;
                case 'prod':
                  initData = {
                    uniqList: selectedAllRowList.map(({ prod_no }) => prod_no),
                    uniqs: rowData.selectData.prod_no,
                    accordionList: [{
                      uniq: rowData.selectData.prod_no,
                      title: `${rowData.selectData.prod_prod_name || '-'} ${rowData.selectData.prod_sku_cd || '-'}`,
                      list: []
                    }]
                  };
                  $scope.select_uniq = rowData.selectData.prod_no;
                  params.memo_code = 'prod_no';
                  params.type = 'prod';
                  break;
                case 'ol_shop':
                  initData = {
                    uniqList: selectedAllRowList.map(({ ol_shop_no }) => ol_shop_no),
                    uniqs: rowData.selectData.ol_shop_no,
                    accordionList: [{
                      uniq: rowData.selectData.ol_shop_no,
                      title: `${rowData.selectData.ol_shop_shop_name || '-'} ${rowData.selectData.ol_shop_shop_sale_name || '-'}`,
                      list: []
                    }]
                  };
                  $scope.select_uniq = rowData.selectData.ol_shop_no;
                  params.memo_code = 'ol_shop_no';
                  params.type = 'ol_shop';
                  break;
                case 'depot_set':
                  initData = {
                    uniqList: selectedAllRowList.map(({ set_no }) => set_no),
                    uniqs: rowData.selectData.set_no,
                    accordionList: [{
                      uniq: rowData.selectData.set_no,
                      title: `${rowData.selectData.depot_set_set_name || '-'} ${rowData.selectData.depot_set_set_cd || '-'}`,
                      list: []
                    }]
                  };
                  $scope.select_uniq = rowData.selectData.set_no;
                  params.memo_code = 'set_no';
                  params.type = 'depot_set';
                  break;
                case 'mend':
                  initData = {
                    uniqList: selectedAllRowList.map(({ source_no }) => source_no),
                    uniqs: rowData.selectData.source_no,
                    accordionList: [{
                      uniq: rowData.selectData.source_no,
                      title: `${rowData.selectData.mend_as_sale_name || '-'}`,
                      list: []
                    }]
                  };
                  $scope.select_uniq = rowData.selectData.source_no;
                  params.memo_code = 'source_no';
                  params.type = 'mend';
                  break;
                case 'depot_inout_manual':
                  initData = {
                    uniqList: selectedAllRowList.map(({ source_no }) => source_no),
                    uniqs: rowData.selectData.source_no,
                    accordionList: [{
                      uniq: rowData.selectData.source_no,
                      title: `<div class="a-accordion-title">입출고수량: ${rowData.selectData.inout_cnt} </div>
                      ${rowData.selectData.prod_prod_name ? `<div class="a-accordion-text">SKU상품명: ${rowData.selectData.prod_prod_name}</div>` : ''}
                      ${rowData.selectData.prod_sku_cd ? `<div class="a-accordion-text">SKU코드: ${rowData.selectData.prod_sku_cd}</div>` : ''}
                      ${rowData.selectData.depot_name ? `<div class="a-accordion-text">배송처: ${rowData.selectData.depot_name}</div>` : ''}`,
                      list: []
                    }]
                  };
                  $scope.select_uniq = rowData.selectData.source_no;
                  params.memo_code = 'source_no';
                  params.type = 'depot_inout_manual';
                  break;
              }
            } else {
              if ($scope.searchFn.getOrderList) { orderList = $scope.searchFn.getOrderList(); }
              initData = initAccordionTitle(orderList, rowData.selectData);
            }
            $scope.tabMemo(initData, params);
          }
          // 로그
          else if ($scope.tabIndex === 3) {
            if ($scope.searchFn.getOrderList) {
              orderList = $scope.searchFn.getOrderList();
            } else if ($scope.location === 'cs') {
              orderList = $scope.searchFn.getInquiryList();
            } else if ($scope.location === 'map') {
              orderList = $scope.searchFn.getMapList();
            } else if ($scope.location === 'atalk_auto_reg') {
              orderList = $scope.searchFn.getAtalkList();
            }

            initData = initAccordionTitle(orderList, rowData.selectData);
            $scope.tabLog(initData, params);
          }
        }
      }
    };
  }

  );
