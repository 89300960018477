'use strict';

angular.module('gmpApp')
  .factory('supplierModel', function (settings, commonSVC) {
    const supplierUrl = `${settings.pa20ApiUrl}/app/stock/supplier`;

    return {

      /**
       * 신규 매입처 등록
       */
      insert: function (data) {
        const url = `${supplierUrl}/add`;

        return commonSVC.sendUrl('POST', url, data);
      },

      /**
       * 매입처 삭제
       * @param numbers 삭제할 supp_no 리스트
       */
      delete: function (numbers) {
        const url = `${supplierUrl}`,
              params = { supp_no_list: numbers };

        return commonSVC.sendUrl('DELETE', url, params);
      },

      /**
       * 매입처 단일 수정
       * @param number DB 번호
       * @param changeData 수정 데이터
       * @returns {*}
       */
      editOne: function (number, changeData) {
        const url = `${supplierUrl}/use-yn`,
              params = {
                supp_no: number,
                use_yn: changeData
              };

        return commonSVC.sendUrl('PATCH', url, params);
      },

      /**
       * 매입처 정보 로드
       * @param number DB 번호
       */
      load: function (number, next) {
        const url = `${supplierUrl}/${number}`;

        commonSVC.sendUrl('GET', url, {}, function (state, data) {
          if (next) {
            next(state, data);
          }
        });
      },

      /**
       * 매입처 정보 수정
       */
      update: function (data) {
        return commonSVC.sendUrl('PATCH', `${supplierUrl}/edit`, data);
      },

      /**
       * 매입처 전체 리스트 조회
       * @param data 조회데이터(use_yn)
       * @return supplierListPromise 전체리스트 promise 객체
       */
      ListAll: function (data) {
        const url = `${supplierUrl}/listOfAll`;

        return commonSVC.sendUrl('POST', url, data);
      },

      /**
       * 매입처 복사
       * @param numbers
       */
      Copy: function (supp, next) {
        const url = `${supplierUrl}/copy`;
        const params = { supp: supp };

        commonSVC.sendUrl('POST', url, params, function (state, data) {
          next(state, data);
        });
      },
    };

  });
