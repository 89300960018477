'use strict';

import io from 'socket.io-client';

angular.module('gmpApp')
  .service('workSVC', function ($rootScope, settings, commonSVC, errorSVC, $q, userInfo, $state, $timeout) {

    const __master = {

      /**
       * 작업 상태
       * delayed : 작업 배포 전 (작업 addJob은 성공) inactive : 대기 active: 진행중 complete: 완료 failed: 오류
       *
       * 여기는 gettext 로 번역하지 않는다. 실제 이 변수를 참조해서 사용하는 곳에서 번역시켜서 전달
       */
      stateText: {
        delayed: '대기',
        inactive: '대기',
        active: '진행중',
        complete: '완료',
        failed: '오류'
      },

      /**
       * 외부 노출용 인포메이션
       */
      info: {

        // 작업 진행중인지 여부
        is_working: false,

        // 전체 작업 건수
        total_working_cnt: 0,

        // 대기중+진행중 작업 건수
        working_cnt: 0,

        // 마지막 동기화 시간
        last_updated_time: {
          all: null,
          order: null,
          prod: null,
          cs: null
        },

        // 어떤작업인지 추가 2018-03-19 rony ( 주문수집, 동기화 구분.. 나머지 작업들은 추후 추가 )
        orderScrap: false,  // 주문수집작업 여부
        orderSync: false    // 주문동기화 작업여부

      },

      /**
       * 퀵수집 작업리스트
       */
      quickCollectJobListCheck: [
        { workName: '주문 상태 동기화하기(취소/교환/반품 수집이 포함됩니다.)', job_type: 'SyncOrderState', selected: false, auth: 'order', type: 'sync' },
        { workName: '신규 상품문의 가져오기', job_type: 'ScrapCS', selected: false, auth: 'inquiry', type: 'inquiry' },
        { workName: '신규 긴급메세지 가져오기', job_type: 'ScrapEmergencyCS', selected: false, auth: 'inquiry', type: 'inquiry' },
        { workName: '신규 상품평 가져오기', job_type: 'ScrapReview', selected: false, auth: 'inquiry', type: 'inquiry' },
        { workName: '판매 중 상품 가져오기 1회 실행', job_type: 'ScrapProd', selected: false, auth: 'online', type: 'online', tooltip: '선택한 쇼핑몰에서 판매중인 모든 상품을 가져와 PLTO2.0 에 저장된 상품과 자동으로 매칭합니다.\n(마스터상품이 10만건 이상인 경우 가져오기만 진행합니다.)\n상품의 수가 많은 경우 부하를 주는 원인이 되어 해당 화면에서 설정 당시 1회만 진행하며,\n퀵수집 작업 실행 시에는 본 작업은 진행되지 않습니다. (저장 후 퀵수집 바로 실행 시 진행)\n\n※ 초기 세팅 이후의 가져오기 작업은 아래 경로에서 진행할 수 있습니다.\n(경로: 상품 > 쇼핑몰 상품 가져오기 메뉴 > 좌측 상단의 [1. 쇼핑몰 상품 가져오기] 버튼 클릭)' },
      ],
      quickCollectJobListRadio: [
        { workName: '결제완료 주문 가져오기 + 신규주문 가져오기', job_type: 'ScrapOrderConfirmList', selected: false, tooltip: '쇼핑몰에서 결제완료, 배송준비중인 주문을 가져옵니다.', auth: 'order', type: 'collect' },
        { workName: '결제완료 주문 확인 + 신규주문 가져오기', job_type: 'ScrapOrderAndConfirmDoit', selected: false, tooltip: '쇼핑몰에서 결제완료 주문을 주문확인 처리 후 배송준비중인 주문을 가져옵니다.', auth: 'order', type: 'collect' },
      ],

      /**
       * 수집 작업시 (발주전주문수집)동시작업 실행 유무
       *  backoffice 설정 페이지 생성시 사라질 예정
       * */
      // isConfirmScrapWork : {
      //   'A000' : true,
      //   'A001' : true,
      //   'A004' : true,
      //   'A006' : true,
      //   'A011' : true,
      //   'A012' : true,
      //   'A017' : true,
      //   'A027' : true,
      //   'A077' : false,
      //   'A083' : true,
      //   'A084' : true,
      //   'A085' : true,
      //   'A112' : true,
      //   'A131' : true,
      //   'B378' : true,
      //   'B430' : true,
      //   'B719' : true,
      //   'B917' : true,
      //   'B956' : true,
      //   'C007' : true,
      //   'C009' : true,
      //   'G001' : true,
      //   'G002' : true
      // },

      /**
       * 작업관리의 멀티검색, Excel 다운로드 노출할 작업 액션
       * 검사를 indexOf 로 하기때문에 0 번째 값에는 빈값을 넣음.
       * 2019-02-19 rony
       */
      viewAddBtnAction: [
        '', '상품등록', '상품수정', '상품상태 동기화', '상품부분수정', '일시품절', '일시품절복구', '판매중지', '상품재등록', '송장전송'
      ],

      viewAddPayBtnAction: [
        '주문수집', '발주 확인전 주문수집', '발주확인후 주문수집'
      ],

      /**상품관련 작업시 작업량 체크*/
      checkProductCharge: function (workCnt) {
        const url = `${settings.pa20ApiUrl}/app/common/check-product-charge`;
        const method = 'GET';

        return commonSVC.sendUrl(method, url, { work_cnt: workCnt });
      },

      /**
       * 작업 등록
       * @param site_action
       * @param params
       */
      addWork: function (action, params) {
        const url = `${settings.pa20ApiUrl}/app/work/addWork`;
        const vdata = {
          act: action,
          params: params
        };

        // 알림여부 안넘어올시 무조건 가도록 설정
        params.notify = params.notify === undefined ? true : params.notify;

        vdata.params.prod_list = params.prod_list || [];

        return commonSVC.sendUrl('POST', url, vdata)
          .then(function (result) {
            if (result.status === 200) {
              return $q.resolve(result);
            } else {
              $(`.work-${action}`).attr('disabled', false);

              return $q.reject('error');
            }
          })
          .catch(function (err) {
            $(`.work-${action}`).attr('disabled', false);

            const errMsg = errorSVC.getError('work', err.data.error);
            const messageNotify = errorSVC.getNotify(err.data.error);

            if (messageNotify) {
              commonSVC.showMessage('실패', errMsg);
            }

            if (err.data.error === 'check_charge') {
              commonSVC.showConfirm('작업 카운트가 부족하여 작업 등록이 실패되었습니다.', '결제 > 부가서비스 에서 상품 또는 주문의 카운트를 구매 하시거나 버전을 업그레이드 하여 이용해주시기 바랍니다.', function (confirm) {
                if (confirm) {
                  $state.go('main.pay_list');
                  $state.reload();
                }
              });
            } else if (err.data.error.includes('쿠팡 작업 오류')) {
              commonSVC.showMessage('실패', '사이트 변경으로 인해 점검중입니다. 공지사항을 확인해주십시오.');
            }

            return err;
          });
      },

      /**
       * 동기 작업 등록
       * @param action
       * @param params
       */
      addWorkSync: function (action, params, next) {
        const url = `${settings.pa20ApiUrl}/app/work/addWorkSync`;
        const vdata = {
          act: action,
          params: params
        };

        if (next) {
          commonSVC.sendUrl('POST', url, vdata, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, vdata);
        }
      },

      /**
       * 엑셀 작업 중단
       */
      stopExcelWork: (params) => {
        return commonSVC.sendUrl('POST', `${settings.pa20BackgroundApiUrl}/app/work/stop-excel-work`, params);
      },

      /**
       * 엑셀 작업 등록
       * @param site_action
       * @param params
       */
      addExcelWork: async (action, params, file) => {
        params = {
          act: action,
          data: params,
          workFile: file
        };
        const url = `${settings.pa20BackgroundApiUrl}/app/work/addExcelWork`;

        try {
          await commonSVC.sendUrlFile('POST', url, params);

          commonSVC.showToaster('success', '성공', '엑셀 작업이 등록되었습니다.\n상세 내용은 좌측 [작업] 탭에서 반드시 확인해주세요.');
        } catch (err) {
          const errMsg = errorSVC.getError('work', err.data.error);

          commonSVC.showToaster('error', '엑셀 작업 등록 실패', errMsg);

          return err;
        }
      },

      // 솔루션 작업 등록
      addSolWork: async (action, params = {}, file = '') => {
        params = {
          act: action,
          data: params,
          workFile: file
        };
        const url = `${settings.pa20ApiUrl}/app/work/addSolWork`;

        try {
          await commonSVC.sendUrlFile('POST', url, params);

          commonSVC.showToaster('success', '성공', '솔루션 작업이 등록되었습니다.\n상세 내용은 좌측 [작업] 탭에서 반드시 확인해주세요.');
        } catch (err) {
          const errMsg = errorSVC.getError('work', err?.data?.error);

          // Cannot read properties of null (reading 'error') 해당 에러가 나는데 어떤 오류여서 data.error를 못찾는지 콘솔 찍음 추구 확인되면 삭제할 예정
          if (!err?.data?.error) {
            console.log('addSolWork error: ', { err });
          }

          commonSVC.showToaster('error', '솔루션 작업 등록 실패', errMsg);

          return err;
        }
      },

      /**
       * 2018-06-28 Daniel
       * G마켓물류 작업 등록
       */
      addEbaydepotWork: function (site_action, params, notNotify) {
        const url = `${settings.pa20ApiUrl}/app/work/addEbayWork`;
        const vdata = {
          act: site_action,
          site: params.site,
          data: params.data || {}
        };

        return commonSVC.sendUrl('POST', url, vdata)
          .then(function (result) {
            if (result.status === 200 && result.data.result === 'success') {
              if (!notNotify) {
                commonSVC.showToaster('success', '성공', '작업이 등록 되었습니다.\n상세 내용은 좌측 [작업] 탭에서 반드시 확인해주세요.');
              }

              return $q.resolve(result);
            } else {
              $(`.work-${site_action}`).attr('disabled', false);

              return $q.reject('error');
            }
          })
          .catch(function (err) {
            $(`.work-${site_action}`).attr('disabled', false);

            if (!notNotify) {
              const errMsg = errorSVC.getError('work', err.data.error);

              commonSVC.showToaster('error', '실패', errMsg);
            }

            return $q.reject(err);
          });
      },

      /**
       * 작업 셋 등록
       *
       * 2017-05-26 MatthewKim
       * @param work_set_type
       * @param channels
       * @returns {*}
       */
      addWorkSet: function (work_set_type, channels) {
        const url = `${settings.pa20ApiUrl}/app/work/addWorkSet`;
        const vdata = {};

        vdata.work_set_type = work_set_type;
        vdata.channels = channels || 'AUTO';

        return commonSVC.sendUrl('POST', url, vdata)
          .then(function (result) {
            if (result.status === 200 && result.data.result === 'success') {
              commonSVC.showToaster('success', '성공', '작업이 등록 되었습니다.\n상세 내용은 좌측 [작업] 탭에서 반드시 확인해주세요.');

              return result;
            }
          })
          .catch(function (err) {
            $(`.work-${work_set_type}`).attr('disabled', false);

            // 주문동기화는 미지원되어도 실패되면 안되니 성공처리 2018-05-21 Gargamel
            if (work_set_type === 'SYNC_ORDER' && err.data.error.includes('not_supported_act')) {
              commonSVC.showToaster('success', '성공', '작업이 등록 되었습니다.\n상세 내용은 좌측 [작업] 탭에서 반드시 확인해주세요.');
            } else if (err.data.error === 'none token') {
              commonSVC.showToaster('error', '실패', 'G마켓 물류 토큰을 확인해주세요.');
            } else if (err.data.error.includes('쿠팡 작업 오류')) {
              commonSVC.showMessage('실패', '사이트 변경으로 인해 점검중입니다. 공지사항을 확인해주십시오.');
            } else {
              const errMsg = errorSVC.getError('work', err.data.error);

              commonSVC.showToaster('error', '실패', errMsg);
            }

            return err;
          });
      },

      /**
       * 작업 셋 등록
       *
       * 2017-06-22 Harry
       * @param work_type
       * @param params
       * @returns {*}
       */
      addWorkSelect: function (work_type, params) {
        // 2017-08-22 chris params로 넘어온값 그대로 전달
        const url = `${settings.pa20ApiUrl}/app/work/addWorkSelect`;
        const vdata = params;

        vdata.work_type = work_type;
        vdata.numbers = vdata.numbers || 'ALL';

        return commonSVC.sendUrl('POST', url, vdata)
          .then(function (result) {
            if (result.status === 200 && result.data.result === 'success') {
              commonSVC.showToaster('success', '성공', '작업이 등록 되었습니다.\n상세 내용은 좌측 [작업] 탭에서 반드시 확인해주세요.');

              return result;
            }
          })
          .catch(function (err) {
            $(`.work-${work_type}`).attr('disabled', false);

            if (err.data && err.data.error && err.data.error.err_type && err.data.error.err_type === 'temporary') {
              commonSVC.showToaster('error', '일시적인 오류', '작업을 다시 진행하여 주시기 바랍니다.');
            } else {
              const errMsg = errorSVC.getError('work', err.data.error);

              commonSVC.showToaster('error', '실패', errMsg);
            }

          });

      },

      /**
       * 작업 갱신
       * 대기 상태에서 진행 안되던 작업
       * 결과처리중에서 진행 안되던 작업
       * 그거 20개씩 다시 하라고 명령 내림
       */
      refreshAllJobs: function () {
        const vdata = { domain: 'playauto.co.kr', sol_type: userInfo.user.sol_type, sol_code: userInfo.user.sol_no.toString() };
        const url = `${settings.engineApiUrl}/api/refreshAllJobs`;

        return commonSVC.sendUrl('POST', url, vdata);
      },

      /**
       * 작업 변경 내역 구독 (WebSocket)
       *
       * 2018-04-03 MatthewKim
       */
      Subscribe: function () {
        const channel_name = `${userInfo.user.domain}:${userInfo.user.sol_type}:${userInfo.user.sol_no}`;
        const ClusterWS = require('../libs/clusterws.min.js');
        const socket = new ClusterWS({
          url: settings.playapi_ws || 'ws://kr-ws.playapi.playauto.org',
          autoReconnect: true,
          autoReconnectOptions: {
            attempts: 20,
            minInterval: 1000,
            maxInterval: 5000,
          }
        });

        socket.on('connect', function () {
          const channel = socket.subscribe(channel_name);

          channel.watch(function (message) {
            // console.log('message received:', message);
            const event = message.event;
            const job = message.job;

            switch (event) {
              case 'enqueue':
                // 작업이 등록됨
                // 등록 팝업을 여기서 띄워도 됨
                // 작업 관리 새로고침을 여기서 해도 됨 (또는 새로고침이 아닌 추가만 여기서 해도 됨)
                job.socket_event = 'enqueue';
                if (typeof $rootScope.update_work_list === 'function') {
                  $rootScope.update_work_list();
                }
                break;
              case 'complete':
                // 작업이 완료됨
                // 완료 팝업을 여기서 띄워도 됨
                job.socket_event = 'complete';
                __master.info.last_updated_time.order = job.state == 'complete' && job.job_type == 'SyncOrderState' ? job.updated_at : null;   // 마지막 주문 동기화 시간
                break;
              case 'start':
                // 작업이 시작됨
                job.socket_event = 'start';
                if (typeof $rootScope.update_work_row == 'function') {
                  $rootScope.update_work_row(job); // 들어온 작업만 빠르게 즉시 갱신
                }
                break;
              case 'progress':
                job.socket_event = 'progress';
                // 작업 상황이 전달됨 (상태변경, progress, state_msg)
                if (typeof $rootScope.update_work_row == 'function') {
                  $rootScope.update_work_row(job); // 들어온 변경 사항만 빠르게 즉시 갱신
                }
                break;
            }
          });

        });
      },

      /**
       * pa5 연동용 웹소켓 연결
       * (작업진행률 표기용...)
       */
      pa5_job_connect: function (tempUUID = null) {

        // pa5 연동 설정된 계정만 추출
        const connectShops = _.filter($rootScope.use_channel_list, function (o) { return o.uuid; });

        if (connectShops.length || tempUUID) {
          const pa5_socket = io.connect(`https://${['app.playauto.io', 'playauto.makeshop.co.kr'].includes(window.location.host) ? 'pa5-websocket' : 'pa5-websocket-dev'}.playapi.io/job`, {
            transports: ['websocket'],
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 'Infinity'
          });

          // pa5 셋팅된 계정별 구독.
          pa5_socket.on('connect', () => {
            if (tempUUID) {
              pa5_socket.emit('subscribe', tempUUID);
            } else {
              for (const shops of connectShops) {
                const channelAccountID = shops.uuid.split('|')[0];

                pa5_socket.emit('subscribe', channelAccountID);
              }
            }
          });

          // pa5_socket.on('message', data => {
          //   console.log('PA5 소켓에 연결되었습니다. 접속 ID - ', (JSON.parse(data)).result.channelAccountId);
          // });

          // 작업진행율 구독
          pa5_socket.on('progress', data => {
            const retData = JSON.parse(data);

            if (retData.status === 200 && retData.result && typeof $rootScope.update_work_row === 'function') {
              $rootScope.update_work_row({
                id: retData.result.jobId,
                progress: retData.result.progress > 95 ? 95 : retData.result.progress,
                state: 'active',
                state_msg: retData.result.progress < 30 ? '작업 등록중..' : '작업 진행중..',
                socket_event: 'progress'
              });
            }
          });

          // // 상태변경 구독.
          // pa5_socket.on('jobStatus', data => {
          //   console.log('jobStatus', data);
          // });
        }
      },

      /**
       * 뷰 업데이트 (Jquery)
       *
       * 작업 데이터중 뷰에 업데이트할 내용은 여기서 Jquery 로 처리한다.
       * 앵귤러로 바인딩 하기에는 너무 깊숙히 디렉티브에 엮여 있기 때문에 Jquery 로 처리함
       * $timeout 큐로 넣어서 처리
       *
       * 2017-03-21 MatthewKim
       */
      updateView: function () {

        // spinner 처리
        if (__master.info.is_working) {
          // worksvc-spinner 클래스가 붙은것을 회전 시킴 (아닐때는 회전정지만)
          $('.worksvc-spinner').addClass('spinner');
          // worksvc-spinner-toggle 클래스가 붙은것을 회전시키고 보이게함 (아닐때는 숨김처리도 함)
          $('.worksvc-spinner-toggle').addClass('spinner').show();

          // 주문수집, 주문동기화 구분 2018-03-19 rony
          if (__master.info.orderScrap) {
            $('.worksvc-spinner-orderScrap').addClass('spinner');
          }
          if (__master.info.orderSync) {
            $('.worksvc-spinner-orderSync').addClass('spinner');
          }
        } else {
          $('.worksvc-spinner').removeClass('spinner');
          $('.worksvc-spinner-toggle').removeClass('spinner').hide();

          $('.worksvc-spinner-orderScrap').removeClass('spinner');
          $('.worksvc-spinner-orderSync').removeClass('spinner');
        }

        // 최종 업데이트 시간 처리
        _.each(__master.info.last_updated_time, function (ttime, key) {
          if (ttime) {
            const syncTime = moment.unix(ttime / 1000).fromNow();

            $(`.worksvc-last-updated.${key}`).text(syncTime);
          }
        });

      },

      /**
       * 작업 상세
       */
      workDetail: function (work_nos) {
        const url = `${settings.pa20ApiUrl}/app/work/detail`;
        const params = { job_ids: work_nos };

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 작업 삭제
       * @param number
       * @param next
       */
      delete: function (number) {
        const url = `${settings.engineApiUrl}/api/deleteJob`;
        const vdata = { domain: userInfo.user.domain, job_id: number };

        return commonSVC.sendEngineUrl('POST', url, vdata);
      },

      /**
       * 작업 취소
       * @param number
       * @param next
       */
      cancel: function (number) {
        const url = `${settings.engineApiUrl}/api/cancelJob`;
        const vdata = { domain: userInfo.user.domain, job_id: number };

        return commonSVC.sendEngineUrl('POST', url, vdata);
      },

      /**
       * 카운트 복구
       */
      restoreCnt: function (job_ids) {
        const vdata = { job_ids };

        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/work/restore-work-count`, vdata);
      },

      /**
       * 전체 작업 삭제
       * @param next
       */
      purge: function (next) {

        const url = `${settings.engineApiUrl}/api/purgeJobs`;

        // 실제 솔루션 타입으로 변경 2017-07-28 chris
        const vdata = { domain: userInfo.user.domain, sol_type: userInfo.user.sol_type, sol_code: userInfo.user.sol_no };

        commonSVC.sendEngineUrl('POST', url, vdata, function (result, data) {
          next(result, data);
        });
      },

      // x건 실패 붉은글씨 처리
      // x건 완료 푸른글씨 처리
      // x건 취소요청(발송완료)|중복 주황글씨 처리
      getTypeText: function (text, notChangeTextYn = true) {
        if (text) {
          const msgMatch = text.match(/((\d+)건 (실패|처리실패)|(\d+)건 (성공|처리성공|완료)|(\d+)건 (취소요청\(발송완료\)|중복)|(\d+)건 (요청중))/gi);

          if (msgMatch) {
            // 개수 체크용 변수
            let total = 0;

            _.each(msgMatch, function (msg) {
              const msgSplit = msg.split('건 ');
              const count = parseInt(msgSplit[0]);
              let type = 'purple';

              if (msgSplit[1].indexOf('성공') > -1) {
                type = 'primary';
              } else if (msgSplit[1].indexOf('실패') > -1) {
                type = 'danger';
              } else if (msgSplit[1].indexOf('취소요청') > -1 || msgSplit[1].indexOf('중복') > -1) {
                type = 'warning';
              }

              total = total + count;

              if (count > 0) {
                text = text.replace(msg, `<span class="text-${type}">${msg}</span>`);
              }
            });

            if (notChangeTextYn && total === 0) {
              text = '변경사항이 없습니다';
            }
          }
        }

        return text;
      }
    };

    return __master;

  });
