'use strict';

angular.module('gmpApp')
  .service('userSVC', function SettingsUser() {
    /**
     * 권한설정
     * 2018-12-28 rony
     */
    const permissions = {
      // 관리자
      master: {
        use_yn: true,
        roles: {}
      },
      // 주문관리
      order: {
        use_yn: true,
        roles: {
          scrapOrder: true,      // 주문수집
          sendDelivNo: true,     // 배송정보 전송
          excelOrder: true,      // 주문엑셀 업/다운로드
          deleteOrder: true,     // 주문삭제
          mappingProd: true,     // 주문재고매칭
          editColumn: true       // 주문 노출항목 추가/삭제
        }
      },
      // 상품관리
      online: {
        use_yn: true,
        roles: {
          registProd: true,      // 상품등록
          editProd: true,        // 상품수정(부분수정,판매기간연장,품절)
          restoreProd: true,     // 상품복구
          deleteProd: true,      // 상품삭제
          scrapProd: true,       // 상품가져오기
          linkageProd: true,     // 연동해제/수정
          excelProd: true        // 상품엑셀 업/다운로드
        }
      },
      // 출고관리
      shipping: {
        use_yn: true,
        roles: {
          excelOrder: true,      // 주문엑셀 업/다운로드
          printInvoice: true,    // 운송장출력
          deleteOrder: true,     // 주문삭제
          mappingProd: true,     // 주문재고매칭
          editColumn: true,      // 주문 노출항목 추가/삭제
          claimInvoice: true,    // 반송 접수/접수취소
          gift: true,        // 사은품규칙 관리
        }
      },
      // 정산관리
      calculate: {
        use_yn: true,
        roles: {
          scrapCalculate: true,  // 정산데이터수집
          excelCalculate: true   // 정산엑셀다운로드
        }
      },
      // 재고관리
      stock: {
        use_yn: true,
        roles: {
          addProd: true,         // SKU상품등록
          editStock: true,       // 재고수정
          deleteStock: true,     // 재고삭제
          excelStock: true,      // 재고엑셀 업/다운로드
          addSet: true,          // 세트상품생성
          stockInoutLog: true,   // 입/출고내역
          warehouse: true,       // 배송처관리
          supplier: true,        // 매입처관리
          editColumn: true       // 재고 노출항목 추가/삭제
        }
      },
      // 통계관리
      summary: {
        use_yn: true,
        roles: {
          excelSummary: true     // 통계 다운로드
        }
      },
      // CS 관리
      inquiry: {
        use_yn: true,
        roles: {
          scrapCs: true,         // 문의수집
          sendCs: true,          // 답변전송
          excelCs: true          // 문의엑셀 다운로드
        }
      },
      // 설정
      setting: {
        use_yn: true,
        roles: {
          channel: true,         // 쇼핑몰계정관리
          carrier: true,         // 택배관리
          company: true,         // 회사정보관리
          user: false            // 사용자관리
        }
      },
      // 환경설정
      config: {
        use_yn: true,
        roles: {
          order: true,           // 주문
          question: true,        // 문의
          prod: true,            // 재고
          onlineProd: true,      // 상품
        }
      },
      // 로그관리
      log: {
        use_yn: true,
        roles: {
          autoMapping: true,     // 주문SKU매칭내역
          deleteList: true,      // 삭제내역관리
          orderExcel: true,      // 주문엑셀다운로드
          autoSoldout: true,     // 상품자동품절내역
          stockAlloc: true,      // 재고할당규칙내역
          barcode: true,         // 바코드출고내역관리
          configs: true          // 환경설정변경내역
        }
      },
      // 판매자센터 접속
      scm: {
        use_yn: true,
        roles: {}
      },
      // 작업관리
      work: {
        use_yn: true,
        roles: {
          delete: true,          // 작업삭제
          restart: true          // 작업재실행
        }
      },
      // 결제관리
      pay: {
        use_yn: true,
        roles: {}
      },
      // 채널별관리
      channel: {
        use_yn: true,
        roles: {}
      },
      // 다른 사용자 문의이력 보기
      othersQuestion: {
        use_yn: true,
        roles: {}
      },
      // 메인 통계
      dashboardSummary: {
        use_yn: true,
        roles: {}
      }
    };

    const depotPermission = {
      // 관리자
      master: {
        use_yn: false,
        roles: {}
      },
      // 주문관리
      order: {
        use_yn: false,
        roles: {
          scrapOrder: false,      // 주문수집
          sendDelivNo: false,     // 배송정보 전송
          excelOrder: false,      // 주문엑셀 업/다운로드
          deleteOrder: false,     // 주문삭제
          mappingProd: false,     // 주문재고매칭
          editColumn: false       // 주문 노출항목 추가/삭제
        }
      },
      // 상품관리
      online: {
        use_yn: false,
        roles: {
          registProd: false,      // 상품등록
          editProd: false,        // 상품수정(부분수정,판매기간연장,품절)
          restoreProd: false,     // 상품복구
          deleteProd: false,      // 상품삭제
          scrapProd: false,       // 상품가져오기
          linkageProd: false,     // 상품연동 해제/수정
          excelProd: false        // 상품엑셀 업/다운로드
        }
      },
      // 출고관리
      shipping: {
        use_yn: true,
        roles: {
          excelOrder: true,       // 주문엑셀 업/다운로드
          printInvoice: true,     // 운송장출력
          deleteOrder: false,     // 주문삭제
          mappingProd: false,     // 주문재고매칭
          claimInvoice: true,     // 반송 접수/접수취소
          gift: true,             // 사은품규칙 관리
        }
      },
      // 정산관리
      calculate: {
        use_yn: false,
        roles: {
          scrapCalculate: false,  // 정산데이터수집
          excelCalculate: false   // 정산엑셀다운로드
        }
      },
      // 재고관리
      stock: {
        use_yn: false,
        roles: {
          addProd: false,         // SKU상품등록
          editStock: false,       // 재고수정
          deleteStock: false,     // 재고삭제
          excelStock: false,      // 재고엑셀 업/다운로드
          addSet: false,          // 세트상품생성
          stockInoutLog: false,   // 입/출고 내역
          warehouse: false,       // 배송처관리
          supplier: false,        // 매입처관리
          editColumn: false       // 재고 노출항목 추가/삭제
        }
      },
      // CS 관리
      inquiry: {
        use_yn: false,
        roles: {
          scrapCs: false,         // 문의수집
          sendCs: false,          // 답변전송
          excelCs: false          // 문의엑셀 다운로드
        }
      },
      // 설정관리
      setting: {
        use_yn: false,
        roles: {
          channel: false,         // 쇼핑몰계정관리
          carrier: false,         // 택배관리
          company: false,         // 회사정보관리
          user: false             // 사용자관리
        }
      },
      // 환경설정
      config: {
        use_yn: false,
        roles: {
          order: false,           // 주문
          question: false,        // 문의
          prod: false,            // 재고
          onlineProd: false,       // 상품
        }
      },
      // 로그관리
      log: {
        use_yn: false,
        roles: {
          autoMapping: false,     // 주문SKU매칭내역
          deleteList: false,      // 삭제내역관리
          orderExcel: false,      // 주문엑셀다운로드
          autoSoldout: false,     // 상품자동품절내역
          stockAlloc: false,      // 재고할당규칙내역
          barcode: false,         // 바코드출고내역관리
          configs: false          // 환경설정변경내역
        }
      },
      // 판매자센터 접속
      scm: {
        use_yn: false,
        roles: {}
      },
      // 작업관리
      work: {
        use_yn: false,
        roles: {
          delete: false,          // 작업삭제
          restart: false          // 작업재실행
        }
      },
      // 채널별관리
      channel: {
        use_yn: false,
        roles: {}
      },
      // 메인 통계
      dashboardSummary: {
        use_yn: true,
        roles: {}
      }
    };

    return {
      permissions: permissions,
      depotPermission: depotPermission,

    };

  });
