import React, { useState, useEffect, CSSProperties } from 'react';
import { openHomePageHelp } from '../../utills/common';

interface ImageHostingHeaderProps {
  isConnected: boolean;
  folderPath: string[];
  setFolderPath: React.Dispatch<React.SetStateAction<string[]>>;
  fileData: any;
  setSearchedData: React.Dispatch<React.SetStateAction<any>>;
  viewPage: 'file' | 'log';
  setSearchLogParams: React.Dispatch<React.SetStateAction<any>>;
}

const ImageHostingHeader: React.FC<ImageHostingHeaderProps> = ({
  isConnected, folderPath, setFolderPath, fileData, setSearchedData, viewPage, setSearchLogParams
}) => {
  const [searchFileText, setSearchFileText] = useState('');  // 파일 조회 검색어
  const [searchLogText, setSearchLogText] = useState('');  // 파일 조회 검색어
  const [fileListPlaceHolderMessage, setFileListPlaceHolderMessage] = useState('(현재 위치) 검색');  // 파일 목록 플레이스홀더 메시지
  const [viewFolderPath, setViewFolderPath] = useState('');  // 현재 보여지는 폴더 경로

  /**
   * (새로고침 등의 작업으로) 파일 데이터가 변경될 때 검색어 초기화
   */
  useEffect(() => {
    setSearchFileText('');
  }, [fileData]);

  /**
   * 폴더 위치 변경시 검색 placeholder 변경
   */
  useEffect(() => {
    if (!folderPath.length) {
      setFileListPlaceHolderMessage('(현재 위치) 검색');
    } else {
      setFileListPlaceHolderMessage(`${[...folderPath].pop()} 검색`);
    }

    getShortenedPath(folderPath, 75);
  }, [folderPath]);

  /**
   * FTP 목록 검색
   */
  const handleFileSearch = () => {
    const searchWords: string[] = searchFileText.toLowerCase().split(' ');
    const filteredData = fileData.filter((file) =>
      searchWords.every((word) => file.name.toLowerCase().includes(word))
    );

    setSearchedData(filteredData);
  };

  /**
   * 로그 조회
   */
  const handleLogSearch = () => {
    setSearchLogParams((prevParams) =>
      ({ ...prevParams, search_word: searchLogText })
    );
  };

  /**
   * 엔터!
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (viewPage === 'file') {
        handleFileSearch();
      } else {
        handleLogSearch();
      }
    }
  };

  /**
   * 경로를 줄여서 출력하는 함수
   */
  const getShortenedPath = (path: string[], maxLength: number) => {
    const checkPath = ['모든폴더', ...path];
    const fullPath = checkPath.join(' > ');
    if (fullPath.length <= maxLength) {
      setViewFolderPath(fullPath);

      return;
    }

    let shortenedPath = '';
    for (let i = 1; i <= checkPath.length; i++) {
      shortenedPath = `... > ${checkPath.slice(i).join(' > ')}`;
      if (shortenedPath.length <= maxLength) {
        break;
      }
    }

    setViewFolderPath(shortenedPath);
  };

  /**
   * 경로 이동
   */
  const handleMovePath = (idx: number) => {
    if (folderPath.length > idx) {
      setFolderPath([ ...folderPath.slice(0, idx) ]);
    }
  };

  return (
    <div className="modal-header" style={{ backgroundColor: 'unset', padding: '0', height: 'auto', borderBottom: '0' }}>
      <div style={styles.header}>
        <div style={styles.headerLogoBox}>
          <img src="../../../assets/images/imagehosting/logo_image_hosting.svg" />
        </div>
        <div style={styles.headerFormBox}>
          <div style={styles.headerForm}>
            {/* 연결없음 일 때 UI (비활성화 상태) */}
            {!isConnected && (
              <>
                <div style={styles.headerPathReadonly}>
                  표시할 경로가 없습니다.
                </div>
                <div className="input-group" style={styles.headerInputGroup}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="연결된 호스팅이 없습니다."
                    readOnly
                    disabled
                  />
                  <span className="input-group-addon" style={{ color: '#aaa', background: '#eee' }}>
                    <i className="icon-search4" />
                  </span>
                </div>
              </>
            )}

            {/* 연결일 때 UI (파일리스트) */}
            {isConnected && viewPage === 'file' && (
              <>
                <div style={styles.headerPath}>
                  {viewFolderPath.split(' > ').map((path, idx) => (
                    <>
                      {idx > 0 && <i className="fa fa-angle-right" style={{ marginRight: '5px', color: '#696969' }} />}
                      <span key={idx} style={{ cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }} onClick={() => handleMovePath(idx)}>
                        <i
                          className="fa fa-folder"
                          style={{ marginRight: '5px', color: '#696969' }}
                        />
                        {path}
                      </span>
                    </>
                  ))}
                </div>
                <div className="input-group" style={styles.headerInputGroup}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={fileListPlaceHolderMessage}
                    value={searchFileText}
                    onChange={(e) => setSearchFileText(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <span
                    className="input-group-addon"
                    style={{ cursor: 'pointer' }}
                    onClick={handleFileSearch}
                  >
                    <i className="icon-search4" />
                  </span>
                </div>
              </>
            )}

            {/* 연결일 때 UI (로그) */}
            {isConnected && viewPage === 'log' && (
              <>
                <div style={styles.headerPath}>
                  로그 관리
                </div>
                <div className="input-group" style={styles.headerInputGroup}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="로그관리 검색"
                    value={searchLogText}
                    onChange={(e) => setSearchLogText(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <span
                    className="input-group-addon"
                    style={{ cursor: 'pointer' }}
                    onClick={handleLogSearch}
                  >
                    <i className="icon-search4" />
                  </span>
                </div>
              </>
            )}
          </div>
          <button
            type="button"
            className="btn"
            style={styles.guideBtn}
            onClick={() => openHomePageHelp(16063)}
          >
            <i style={styles.guideIcon} />
            &nbsp;
            이용가이드 보기
          </button>
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  header: {
    display: 'flex',
    alignItems: 'center',
    height: '68px',
    borderBottom: '1px solid #ddd',
  },
  headerLogoBox: {
    width: '212px',
    textAlign: 'center',
  },
  headerFormBox: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
  },
  headerForm: {
    display: 'flex',
  },
  headerPath: {
    marginRight: '10px',
    paddingLeft: '20px',
    width: '600px',
    height: '36px',
    lineHeight: '36px',
    background: '#E8F0F6',
    fontSize: '12px',
    color: '#7A98AF',
    fontWeight: 500,
    letterSpacing: '-0.36px',
  },
  headerPathReadonly: {
    marginRight: '10px',
    paddingLeft: '20px',
    width: '600px',
    height: '36px',
    lineHeight: '36px',
    background: '#eee',
    fontSize: '12px',
    color: '#aaa',
    fontWeight: 500,
    letterSpacing: '-0.36px',
  },
  headerInputGroup: {
    width: '380px',
  },
  guideBtn: {
    padding: '0',
    width: '154px',
    height: '30px',
    lineHeight: '28px',
    fontSize: '12px',
    color: '#EF6B03',
    fontWeight: '500',
    border: '1px solid #EF6B03',
    background: '#fff',
  },
  guideIcon: {
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_guide.svg) no-repeat center',
    verticalAlign: 'sub',
  }
};

export default ImageHostingHeader;