import React, { useState, useEffect, CSSProperties } from 'react';
import * as imageHostModel from '../../models/imageHosting';
import { showConfirm, showMessage, showToaster, validateFtpAddr } from '../../utills/common';
import HostingFormComponent from '../../components/imageHosting/HostingForm';
import CommonModal from '../../components/common/commonModal';
import AddHostingComponent from './AddHosting';
import Loader from '../../components/common/Loader';

interface FTPInfo {
  ftp_no: number;
  ftp_name: string;
  ftp_addr: string;
  ftp_id: string;
  ftp_pw: string;
  remote_directory: string;
  download_directory: string;
  port_no: string;
  passive_mode_yn: boolean;
  protocal_type: string;
  anonymous_yn: boolean;
}

interface FormData {
  ftp_name: string;
  ftp_addr: string;
  ftp_id: string;
  ftp_pw?: string;
  new_ftp_pw?: string;
  anonymous_yn: boolean;
  remote_directory?: string;
  port_no: string;
  passive_mode_yn: boolean;
  protocal_type: string;
}

interface FtpManagerProps {
  handleClose: (result?: any) => void;
}

const FTPManager: React.FC<FtpManagerProps> = ({ handleClose }) => {
  const [ftpList, setFtpList] = useState<FTPInfo[]>([]);
  const [selectedFtpNo, setSelectedFtpNo] = useState<number | null>(null);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    ftp_name: '',
    ftp_addr: '',
    ftp_id: '',
    ftp_pw: '',
    remote_directory: '',
    port_no: '21',
    passive_mode_yn: false,
    protocal_type: '자동',
    anonymous_yn: false,
  });
  const [duplicateName, setDuplicateName] = useState(false);
  const [addHostingIsOpen, setAddHostingIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getFtpList();
  }, []);

  /**
   * FTP 리스트 조회
   */
  const getFtpList = async () => {
    const res = await imageHostModel.getFtpList();

    setFtpList(res);
  };

  /**
   * 내 호스트 선택
   */
  const handleHostClick = (ftp_no: number) => {
    setSelectedFtpNo(ftp_no);

    // 호스트 정보 설정
    const selectedHost = ftpList.find((ftp: FTPInfo) => ftp.ftp_no === ftp_no);
    setFormData({
      ...selectedHost,
      anonymous_yn: selectedHost.ftp_id === 'anonymous',
      new_ftp_pw: '',
    });
  };

  /**
   * 이미지 호스팅 서버에 접속
   */
  const handleConnect = async () => {
    // 변경사항이 있는경우 변경사항 저장해야 함.
    setIsSubmit(true);

    if (formData.ftp_name.trim() && formData.ftp_addr.trim() && validateFtpAddr(formData.ftp_addr) && !duplicateName) {
      try {
        setIsLoading(true);
        const res = await imageHostModel.addHosting(formData);

        if (res.results === 'success') {
          const connectRes = await imageHostModel.connectToFtpServer(selectedFtpNo);

          if (connectRes.results === 'success') {
            showToaster('success', '', '서버에 접속했습니다.');
            handleClose({
              ftp_no: selectedFtpNo,
              ftp_name: formData.ftp_name,
              remotePath: formData.remote_directory,
              host: formData.ftp_addr
            });
          } else {
            showToaster('error', '', connectRes.error || '서버 접속에 실패했습니다.');
          }
        } else {
          await showMessage('Error', res.error || (res.messages?.length ? res.messages[0] : '호스팅 수정 중 오류가 발생했습니다.'));
        }
      } catch (error: any) {
        await showMessage('Error', error.message || '호스팅 수정 중 오류가 발생했습니다.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  /**
   * 호스팅 추가 모달 오픈
   */
  const openAddHostingModal = () => {
    if (ftpList.length >= 3) {
      showMessage('호스팅 추가 불가', '내 호스팅은 최대 3개까지 저장할 수 있습니다.');

      return;
    }

    setAddHostingIsOpen(true);
  };

  /**
   * 호스팅 추가 모달 닫기
   */
  const addHostingModalClose = (result?: string) => {
    setAddHostingIsOpen(false);

    if (result === 'success') {
      getFtpList();
    }
  };

  /**
   * 등록된 호스팅 정보 삭제
   */
  const deleteHosting = async () => {
    if (!selectedFtpNo) {
      return;
    }

    const doConfirm = await showConfirm('호스팅 삭제', '선택한 호스팅을 내 호스팅에서 삭제합니다.');

    if (doConfirm) {
      const res = await imageHostModel.deleteFtpInfo(selectedFtpNo);

      if (res.results === 'success') {
        showToaster('success', '', '선택한 호스팅을 삭제했습니다.');
        getFtpList();
        setSelectedFtpNo(null);
      } else {
        showToaster('error', '', '선택한 호스팅 삭제에 실패했습니다.');
      }
    }
  };

  /**
   * FTP manager 닫기
   */
  const handleCancel = () => {
    handleClose();
  };

  return (
    <>
      {/* 로딩 스피너 */}
      {isLoading && <Loader isLoading={isLoading} />}
      <div className="modal-header">
        <button type="button" className="close" onClick={handleCancel}>×</button>
        <h5 className="modal-title">FTP 접속 관리자</h5>
      </div>
      <div className="modal-body">
        <p
          className="mb-10"
          style={styles.pageTitle}
        >
          FTP 접속 관리자
        </p>
        <p
          className="mb-30"
          style={styles.pageText}
        >
          내 호스팅에 저장된 호스팅 서버에만 접속할 수 있으며, 내 호스팅은 최대 3개까지 저장 가능합니다.<br />
          접속 정보 및 옵션을 정확하게 입력 후 저장해 주세요.
        </p>
        <div style={styles.ftpBody}>
          <div style={styles.menu}>
            <div style={styles.menuTitleWrapper}>
              <button
                type="button"
                className="btn"
                style={styles.addHostingBtn}
                onClick={openAddHostingModal}
              >
                <i style={styles.addHostingIcon} />
                호스팅
              </button>
              <button
                type="button"
                className="btn"
                style={styles.deleteHostingBtn}
                onClick={deleteHosting}
                disabled={!selectedFtpNo}
              >
                삭제
              </button>
            </div>
            <div style={styles.menuBox}>
              {/* 호스팅 리스트 영역 */}
              <div style={styles.hostingHeader}>내 호스팅</div>
              <ul style={styles.hostingList}>
                {ftpList.map((ftp: FTPInfo) => {
                  const isSelected = selectedFtpNo === ftp.ftp_no;

                  return (
                    <li
                      key={ftp.ftp_no}
                      style={{
                        ...styles.hostingItem,
                        ...(isSelected ? styles.selectedItem : {}),
                      }}
                      onClick={() => handleHostClick(ftp.ftp_no)}
                    >
                      {isSelected ?
                        <i style={styles.hostingItemSelectedIcon} />
                        :
                        <i style={styles.hostingItemIcon} />
                      }
                      {ftp.ftp_name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div style={styles.form}>
            <div style={styles.formHeader}>접속 정보</div>
            <div style={styles.formBody}>
              {!selectedFtpNo &&
                <div>
                  <label htmlFor="groupName" style={styles.formLabel}>
                    그룹 이름
                  </label>
                  <input
                    id="groupName"
                    type="text"
                    value="내 호스팅"
                    style={styles.formInput}
                    readOnly
                  />
                </div>
              }

              {selectedFtpNo &&
                <HostingFormComponent
                  selectedFtpNo={selectedFtpNo}
                  registeredFTPNames={ftpList.map((ftp) => ftp.ftp_name)}
                  formData={formData}
                  setFormData={setFormData}
                  isSubmit={isSubmit}
                  duplicateName={duplicateName}
                  setDuplicateName={setDuplicateName}
                />
              }
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default" onClick={handleCancel}>취소</button>
        <button type="button" className="btn btn-primary" disabled={!selectedFtpNo || isLoading} onClick={handleConnect}>
          {isLoading ? '접속 중...' : '접속'}
        </button>
      </div>

      {/* 호스팅 추가 모달 */}
      <CommonModal
        isOpen={addHostingIsOpen}
        size="md"
        backdrop={false}
        scrollable={true}
        escClose={false}
        handleClose={addHostingModalClose}
        component={AddHostingComponent}
        data={{
          registeredFTPNames: ftpList.map((ftp) => ftp.ftp_name),
        }}
      />
    </>
  );
};

const styles: { [key: string]: CSSProperties } = {
  pageTitle: {
    fontSize: '16px',
    color: '#333',
    fontWeight: 600,
  },
  pageText: {
    fontSize: '13px',
    color: '#333',
    fontWeight: 500,
  },
  headerTitle: {
    margin: '0',
    fontSize: '18px',
  },
  headerDescription: {
    fontSize: '12px',
    color: '#555',
    margin: '8px 0 0',
  },
  ftpBody: {
    display: 'flex',
    gap: '10px',
    minHeight: '380px',
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    width: '212px',
  },
  menuTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  menuBox: {
    flex: '1',
    border: '1px solid #ddd',
  },
  addHostingBtn: {
    padding: '0',
    width: '75px',
    height: '36px',
    lineHeight: '34px',
    fontSize: '13px',
    color: '#1E88E5',
    background: '#fff',
    border: '1px solid #1E88E5',
    letterSpacing: '-0.39px',
  },
  addHostingIcon: {
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_plus.svg) no-repeat center',
    verticalAlign: 'sub',
  },
  deleteHostingBtn: {
    padding: '0',
    width: '63px',
    height: '36px',
    lineHeight: '34px',
    fontSize: '13px',
    color: '#545454',
    background: '#fff',
    border: '1px solid #ddd',
    letterSpacing: '-0.39px',
  },
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  formHeader: {
    display: 'inline-block',
    width: '87px',
    height: '36px',
    lineHeight: '36px',
    fontSize: '13px',
    color: '#545454',
    fontWeight: '500',
    textAlign: 'center',
    letterSpacing: '-0.39px',
    background: '#F5F5F5',
  },
  formBody: {
    flex: '1',
    padding: '20px',
    background: '#F5F5F5',
  },
  formLabel: {
    display: 'block',
    fontSize: '12px',
    marginBottom: '6px',
  },
  formInput: {
    width: '100%',
    padding: '10px',
    fontSize: '12px',
    color: '#545454',
    border: '1px solid #E5E5E5',
  },
  hostingHeader: {
    margin: '10px 12px',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  hostingList: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  hostingItem: {
    padding: '8px 12px 8px 22px',
    fontSize: '12px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  itemHover: {
    backgroundColor: '#f5f5f5',
  },
  selectedItem: {
    backgroundColor: '#e0f3ff',
    color: '#007bff',
  },
  formGroupBox: {
    display: 'flex',
    alignItems: 'center',
  },
  formWidthAuto: {
    flex: 1,
  },
  formWidthFull: {
    width: '100%',
  },
  formWidthFix: {
    minWidth: '100px',
  },
  labelSub: {
    color: '#777',
  },
  fileAttachBtn: {
    margin: '0 0 0 10px',
    display: 'inline-block',
    width: '36px',
    height: '36px',
    border: '1px solid #ddd',
    cursor: 'pointer',
  },
  fileAttachIcon: {
    display: 'inline-block',
    width: '100%',
    height: '100%',
    background: 'url(../../assets/images/imagehosting/ico_fileattach.svg) no-repeat center',
  },
  hostingItemIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_hosting_item.svg) no-repeat center',
    verticalAlign: 'middle',
  },
  hostingItemSelectedIcon: {
    marginRight: '2px',
    display: 'inline-block',
    width: '16px',
    height: '16px',
    background: 'url(../../assets/images/imagehosting/ico_hosting_item_selected.svg) no-repeat center',
    verticalAlign: 'middle',
  },
};

export default FTPManager;
