'use strict';
/**
 * 커스텀 컬럼 일괄저장 모달
 */
angular.module('gmpApp')
  .controller('SaveCustomColumnCTRL', function ($scope, data, $uibModalInstance, commonSVC, columnModel, columnSVC) {
    $scope.addColList = [];
    $scope.page = data.page;

    // 추가항목이 주문과 sku 두개 뿐임
    // 추가 컬럼가져오기
    columnModel.addColumnList({ addcol_type: data.page === 'product' ? 'product' : 'ord' })
      .then((res) => {
        if (res.data && res.data.results && res.data.results.length) {
          $scope.addColList = res.data.results;

          // 컬럼명 보여주기
          $scope.addColList.forEach((col) => {
            col.header = columnSVC.setColumnName(col.col_name, 'GET');

            // 정규표현식 담기
            if (col.input_type === 'int') {
              col.regex = 'onlyNum_minus';
            } else if (col.input_type === 'date') {
              col.regex = 'onlyNum_';
            }

            // 저장 여부
            col.use_yn = false;
            // 값
            col.value = '';

            if (col.enum_data) {
              col.enumList = columnSVC.enumDataSet(col.enum_data);
            }
          });
        }
      });

    // 일괄저장
    $scope.save = async () => {
      // 변경 대상 컬럼
      try {
        const colList = $scope.addColList.filter((col) => {
          return col.use_yn;
        });

        if (!colList.length) {
          commonSVC.showMessage('실패', '선택된 적용 컬럼이 없습니다.');

          return false;
        }

        // 값 덮어 쓰기
        data.ordList.forEach((ord) => {
          colList.forEach((col) => {
            ord[col.col_name] = col.value;
          });
        });

        // 저장
        await columnSVC.addColValue(data.ordList, data.page, 'all');

        // 성공시 닫기
        $uibModalInstance.close('success');
      } catch (err) {
        commonSVC.showToaster('error', '실패', '일괄저장에 실패하였습니다.');

        return false;
      }
    };

    $scope.close = () => {
      $uibModalInstance.dismiss();
    };
  });