(function () {
  'use strict';

  /**
   * 2018-04-07 lucas
   * 단일상품 추가 모달
   */
  angular.module('gmpApp')
    .controller('addSingleProdCtrl', AddSingleProdCtrl);

  function AddSingleProdCtrl ($scope, $uibModalInstance, commonSVC, onlineProductModel, templateModel, onlineProductSVC, errorSVC, data, channelUseList, slaveProdList, systemList, categoryModel, $timeout, userInfo) {
    $scope.sol_stock_auth = userInfo.user.sol_stock > 0 && (userInfo.user.auth_type === '총괄관리자' || userInfo.permission?.stock?.use_yn);

    $scope.prodOpt = {
      esm_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '' }],    // 옵션없음 ESM 기초상품 리스트
      std_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '' }],    // 옵션없음 11번가 기초상품 리스트
      wmp_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '' }],    // 옵션없음 위메프 기초상품 리스트
      hplus_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '' }],  // 옵션없음 홈플러스 기초상품 리스트
      alibaba_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '' }],    // 옵션없음 알리 기초상품 리스트
      aliexpress_matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '' }],    // 옵션없음 알리 기초상품 리스트
      matchStockList: [{ prod_img: '/assets/images/upload.png', sku_cd: '' }],        // 옵션없음 쇼핑몰 기초상품 리스트

      esm_options_table: [],                                                          // ESM 조합형 테이블
      std_options_table: [],                                                          // 11번가 조합형 테이블
      wmp_options_table: [],                                                          // 위메프 조합형 테이블
      hplus_options_table: [],                                                        // 홈플러스
      alibaba_options_table: [],                                                          // 알리 조합형 테이블
      aliexpress_options_table: [],                                                          // 알리 조합형 테이블
      options_table: [],                                                              // 옵션없음

      esm_insert_opt_arr: [],                                                             // esm 입력형 옵션 데이터
      std_insert_opt_arr: [{ insert_opt: '' }],                                           // 11번가 입력형 옵션 데이터
      wmp_insert_opt_arr: [{ insert_opt: '' }],
      hplus_insert_opt_arr: [{ insert_opt: '' }],
      alibaba_insert_opt_arr: [],
      aliexpress_insert_opt_arr: [],
      insert_opt_arr: [{ insert_opt: '' }],

      esm_input_list: [],                                                             // esm 입력형 옵션 리스트
      esm_checked_opt: [],
      std_checked_opt: [],

      esm_opt: [{}, {}, {}],
      std_opt: [{}, {}, {}],
      wmp_opt: [{}, {}, {}],
      hplus_opt: [{}, {}, {}],
      alibaba_opt: [{}, {}, {}],
      aliexpress_opt: [{}, {}, {}]
    };

    // ESM 마스터상품으로 등록 가능 여부
    $scope.checkEsmMaster = {
      disabled: false,
      checked: false
    };
    $scope.canEsmMasterList = []; // ESM 마스터상품 생성 가능 계정 리스트
    $scope.esmIdCheck = false; // ESM 마스터 상품 생성 시 필수값 체크
    $scope.esmMatchShop = null;
    $scope.chkEsmOptDisabled = false;
    $scope.selectOptList = []; // esm 추천옵션 리스트

    const slaveList = _.groupBy(slaveProdList.data.results, 'sale_no');

    const site = {
      A001: 'esm_',
      A006: 'esm_',
      A112: 'std_',
      B719: 'wmp_',
      B502: 'hplus_',
      P020: '',
      A092: 'alibaba_',
      A093: 'aliexpress_'
    };
    const master_prod = data.result.map(o => {
      return {
        sale_no: o.sale_no,
        ol_shop_no: o.ol_shop_no,
        sol_cate_no: o.sol_cate_no,
        c_sale_cd: o.c_sale_cd
      };
    });
    $scope.originData = { ...data.result[0] };
    $scope.prodCount = master_prod.length;
    $scope.data = { ...master_prod[0] };
    $scope.data.esm_opt_type = '옵션없음';
    $scope.data.esm_options = [];
    $scope.data.std_opt_type = '옵션없음';
    $scope.data.wmp_opt_type = '옵션없음';
    $scope.data.hplus_opt_type = '옵션없음';
    $scope.data.alibaba_opt_type = '옵션없음';
    $scope.data.alibaba_options = [];
    $scope.data.aliexpress_opt_type = '옵션없음';
    $scope.data.aliexpress_options = [];
    $scope.data.opt_type = '옵션없음';
    $scope.data.esm_insert_opt = false;
    $scope.data.std_insert_opt = false;
    $scope.data.wmp_insert_opt = false;
    $scope.data.insert_opt = false;
    $scope.data.add_single_prod = true;    // 단일상품 추가 모달 여부
    $scope.data.chkEsmOptDisabled = false;
    $scope.checked = {
      esmBatchType: data.addType === 'addSmileProd' ? 'add_price' : 'sale_cnt',
      wmpBatchType: 'sale_cnt',
      alibabaBatchType: 'sale_cnt',
      aliexpressBatchType: 'sale_cnt',
      smileProd: data.addType === 'addSmileProd'
    };
    $scope.disabled = {
      esm_opt: false,
      std_opt: false,
      aliexpress_opt_not_color1: true,
      aliexpress_opt_not_color2: true,
      aliexpress_opt_not_color3: true,
    };
    $scope.shop = {};
    $scope.showCateMatch = false;     // 카테고리 선택 노출 여부
    $scope.optTableFn = {};           // 옵션테이블 함수
    $scope.esmMatchShop = { ...(data.result.map(o => ({
      sale_no: o.sale_no,
      ol_shop_no: o.ol_shop_no,
      sol_cate_no: o.sol_cate_no,
      c_sale_cd: o.c_sale_cd
    }))[0]) };
    $scope.esmMatchIdx = '';
    $scope.checkEsmExistOpt = false;

    // 등록가능 쇼핑몰
    $scope.channelUseList = angular.copy(channelUseList);

    // 물류상품인지, 단일상품인지 여부
    $scope.addType = data.addType === 'addSmileProd' ? '물류' : '단일';
    $scope.warehouseList = data.warehouseList.data?.result;
    $scope.ebayDepots = data.warehouseList.data?.result?.filter(o => o.ebaydepot_yn).map(o => o.code) || [];

    // 2018-06-21 lucas : 카테고리가 이미 설정되어있는 경우 관련 정보를 로딩하여 세팅해줌
    _.forEach($scope.channelUseList, function (shop) {

      const param = {
        categoryId: $scope.data.sol_cate_no,
        shop_cd: shop.shop_cd,
        shop_id: shop.shop_id
      };

      categoryModel.shopInfoDetailDataLoad(param, function (state, result) {
        if (state === 'success') {
          if (result.length > 0) {
            const data = result[0].sol_cate_shop_add_info;

            shop.match_cate_name = data.category_names;
            // 위메프의 경우 카테고리 코드를 categoryCd 라는 변수명으로 저장 하고 있어 category_code로 조회가 안되어 해당 처리 추가 2022-12-06 Denver
            shop.match_cate_code = result[0].shop_cd !== 'B719' ? data.category_code : data.categoryCd;
            shop.shop_cate_no = result[0].shop_cate_no;
            shop.shop_cate_no_add_info = data;
          }
        }
      });
    });

    // 쇼핑몰 선택
    $scope.selectedShop = async function(type) {
      if (type === 'shop') {
        $scope.shop = $scope.channelUseList[$scope.data.add_shop_id];
        $scope.templateListModal = false;
        $scope.data.chkEsmOptDisabled = false;

        if (['A001', 'A006'].includes($scope.shop.shop_cd) && $scope.shop.etc7) {
          // ESM 마스터상품 생성 가능 여부 판단
          // 마스터에 있는 계정 선택 시 묶을 계정은 마스터에 없는 계정
          // 마스터에 없는 계정 선택 시 마스터에 있는 계정도 노출
          const matchShopCd = $scope[type].shop_cd === 'A006' ? 'A001' : 'A006';
          $scope.canEsmMasterList = $scope.channelUseList.filter(shop => shop.shop_cd === matchShopCd && shop.etc7 === $scope.shop.etc7);

          Object.values(slaveList).forEach(olList => {
            if (olList.some(ol => ol.shop_cd === $scope.shop.shop_cd && ol.shop_id === $scope.shop.shop_id)) {

              // 이미 만들어진 ESM 마스터상품 계정이면 더 만들수 없음
              if (olList.find(ol => ol.shop_cd === $scope.shop.shop_cd && ol.shop_id === $scope.shop.shop_id)?.ebay_shop_mas_no) {
                $scope.canEsmMasterList = [];
              } else {
                $scope.canEsmMasterList = $scope.canEsmMasterList.filter(shop => {
                  const matchingOl = olList.find(ol => ol.shop_cd === matchShopCd && ol.shop_id === shop.shop_id);

                  return !matchingOl;
                });
              }
            } else {
              $scope.canEsmMasterList = $scope.canEsmMasterList.filter(shop => {
                const matchingOl = olList.find(ol => ol.shop_cd === matchShopCd && ol.shop_id === shop.shop_id);

                return !matchingOl || !matchingOl.ebay_shop_mas_no;
              });
            }
          });

          if ($scope.canEsmMasterList.length) {
            $scope.checkEsmMaster.disabled = true;
          } else {
            $scope.checkEsmMaster.disabled = false;
            $scope.checkEsmMaster.checked = false;
            $scope.canEsmMasterList = [];
          }
        } else {
          $scope.checkEsmMaster.disabled = false;
          $scope.checkEsmMaster.checked = false;
          $scope.canEsmMasterList = [];
        }
        $scope.esmMatchShop = null;
        $scope.esmMatchIdx = '';
        $scope.showEsmCateMatch = true;
      } else {
        $scope.esmTemplateListModal = false;

        const selMasterShop = $scope.canEsmMasterList[$scope.esmMatchIdx];
        $scope.esmMatchShop = $scope.channelUseList.find(ch => ch.shop_cd === selMasterShop.shop_cd && ch.shop_id === selMasterShop.shop_id);

        Object.values(slaveList).forEach(olList => {
          if (olList.some(ol => ol.shop_cd === $scope.esmMatchShop.shop_cd && ol.shop_id === $scope.esmMatchShop.shop_id)) {
            $scope.esmMatchShop.isExist = true;
          }
        });

        // ESM 지마켓 상품이 기존에 등록되어 있는 경우 옥션 상품을 기존 옵션으로 저장해야 되서 테이블 비활성화 처리

        $scope.data.chkEsmOptDisabled = ($scope.shop.isExist || $scope.esmMatchShop?.isExist);
      }

      if ($scope[type] && $scope[type].shop_cate_no_add_info) {
        // 위메프의 경우 카테고리 코드를 categoryCd 라는 변수명으로 저장 하고 있어 category_code로 조회가 안되어 해당 처리 추가 2020-02-08 Tim
        if (!$scope[type].shop_cate_no_add_info.category_code) {
          $scope[type].shop_cate_no_add_info.category_code = $scope[type].shop_cate_no_add_info.categoryCd;
        }
        const { category_code } = $scope[type].shop_cate_no_add_info;
        const { shop_cd } = $scope[type];

        // category_code 있을 경우에만 카테고리 조회
        if (category_code) {
          const { data: esmExistYn } = await categoryModel.getEsmCategoryExistYn({
            match_cate_code: category_code,
            shop_cd,
          });

          $scope[type].existEsmYn = esmExistYn.result;
        }
      }
      // 쇼핑몰 아이디 선택시 카테고리 선택 노출.
      $scope.showCateMatch = $scope.data.add_shop_id != '';
      $scope.showEsmCateMatch = $scope.esmMatchIdx != '';

      $timeout(function () {
        $('[data-popup="tooltip"]').tooltip();
      }, 100);
    };

    $scope.changeEsmMasterCheck = () => {
      if ($scope.checkEsmMaster.checked) {
        $scope.esmMatchShop = null;
        $scope.showEsmCateMatch = false;
        $scope.esmMatchIdx = '';
      } else {

        Object.values(slaveList).forEach(olList => {
          if (olList.some(ol => ol.shop_cd === $scope.shop.shop_cd && ol.shop_id === $scope.shop.shop_id)) {
            $scope.shop.isExist = true;
          }
        });
      }
    };

    $scope.chkCateEditDisabeld = (type) => {
      return type === 'shop' ? $scope.shop?.isExist : $scope.esmMatchShop?.isExist;
    };

    /**
     * 온라인 템플릿 리스트 가져오기
     */
    $scope.onlineTemplate = function (type) {

      $scope[type].search = '';

      const param = {
        shop_cd: $scope[type].shop_cd,
        shop_id: $scope[type].shop_id,
        bookmark: '1'
      };

      const paramAll = {
        shop_cd: $scope[type].shop_cd,
        shop_id: $scope[type].shop_id,
        bookmark: 'all'
      };

      // 템플릿 모달 열 때만 호출
      if (type === 'esmMatchShop' ? !$scope.esmTemplateListModal : !$scope.templateListModal) {
      //전체 리스트
        templateModel.templateOnlineProduct(paramAll)
          .then(function (res) {
            if (type === 'esmMatchShop') {
              $scope.esmTemplateList2 = res.data.results;
            } else {
              $scope.templateList2 = res.data.results;
            }
          })
          .catch(function(err) {
            commonSVC.showToaster('error', '', err);
          });

        //즐겨찾기 리스트
        templateModel.templateOnlineProduct(param)
          .then(function (res) {

            if (type === 'esmMatchShop') {
              $scope.esmTemplateList = res.data.results;
            } else {
              $scope.templateList = res.data.results;
            }

            // 즐겨찾기 없을경우 전체탭으로이동
            if (type === 'esmMatchShop' ? !$scope.esmTemplateList.length : !$scope.templateList.length) {
              $(`li#allTab${type === 'esmMatchShop' ? '_esm' : ''} a`).trigger('click');
            }
          })
          .catch(function(err) {
            commonSVC.showToaster('error', '', err);
          });
      }
      if (type === 'esmMatchShop') {
        $scope.esmTemplateListModal = !$scope.esmTemplateListModal;
      } else {
        $scope.templateListModal = !$scope.templateListModal;

      }
    };

    /**
     * 검색 필터
     */
    $scope.search = function(element) {
      if ($scope.shop.search === '') {
        return true;
      }

      return !!JSON.stringify(element).match(new RegExp(`(${$scope.shop.search})`, 'i'));
    };

    $scope.esmSearch = function(element) {
      if ($scope.esmMatchShop.search === '') {
        return true;
      }

      return !!JSON.stringify(element).match(new RegExp(`(${$scope.esmMatchShop.search})`, 'i'));
    };

    /**
     * 템플릿 선택
     */
    $scope.templateNameAdd = function (data, type) {
      if (data) {
        $scope[type].template_name = data.template_name;
        $scope[type].template_no = data.template_no;

        if (type === 'esmMatchShop') {
          $scope.esmTemplateListModal = false;
        } else {
          $scope.templateListModal = false;
        }
      }
    };

    /**
     * 온라인 템플릿 상세모달
     */
    $scope.onlineTemplateDetail = function (template_no, siteCode, siteId, shopName) {
      onlineProductSVC.onlineTemplateDetail(template_no, siteCode, siteId, shopName, $scope.openTemplate, $scope.onlineTemplate);
    };

    /**
     * 템플릿 등록
     */
    $scope.template_add = function(pa_shop_cd, shop_cd, shop_id, shop_name) {
      onlineProductSVC.onlineTemplateDetail(0, { pa_shop_cd, shop_cd }, shop_id, shop_name, $scope.openTemplate, $scope.onlineTemplate);
    },

    /**
     *  템플릿 리스트 닫기
     */
    $scope.closeListModal = function () {
      $scope.templateListModal = false;
      $scope.esmTemplateListModal = false;
    };

    // 옵션 정보 로딩
    $scope.categoryOptions = async () => {
      let masterOptInfo;

      if ($scope.shop && $scope.prodCount === 1 && $scope.originData.opt_type === '옵션없음') {

        // 옵션 없는 상품에 매칭된 SKU / 세트 상품 조회 하므로 반드시 1개만 존재함
        onlineProductModel.detail($scope.originData.shop_cd, $scope.originData.shop_id, $scope.originData.ol_shop_no, (state, result) => {
          if (state === 'success') {
            masterOptInfo = onlineProductSVC.initOpt({ ...$scope.originData, optData: result.results.opt_sub_table }, $scope.prodOpt, site[$scope.shop.shop_cd]).matchStockList;
            $scope.prodOpt[`${site[$scope.shop.shop_cd]}matchStockList`] = masterOptInfo;
          }
        });

      }
      if (!$scope.shop || !$scope.shop.match_cate_code || ['B719', 'B502', 'P020'].includes($scope.shop.shop_cd)) {
        return;
      }

      // 옵션사용 가능여부 초기화
      $scope.disabled.esm_opt = false;
      $scope.disabled.std_opt = false;
      $scope.disabled.alibaba_opt = false;
      $scope.disabled.aliexpress_opt = false;

      const prodOpt = $scope.prodOpt;

      $('.optionSpinner').removeClass('hidden');
      prodOpt.esm_input_list = [];

      const shop = $scope.shop;

      const addData = {};

      addData[shop.shop_cd] = [];

      let categoryCode;
      let hasOpt = false;

      if (shop.shop_cd === 'A001') {
        categoryCode = shop.match_cate_code.split('_');

        for (let i = categoryCode.length; i < 4; i++) {
          categoryCode.push('00');
        }

        if (categoryCode.join('')) {
          hasOpt = true;
          categoryCode = categoryCode.join('');
        }

      } else if (shop.shop_cd === 'A006') {
        const arr = shop.match_cate_code.split('_');

        categoryCode = '';
        let idx = 1;

        while (!categoryCode) {
          categoryCode = arr[arr.length - idx];

          idx++;
        }

        if (categoryCode) {
          hasOpt = true;
        }
      } else if (['A092', 'A093'].includes(shop.shop_cd)) {
        const arr = shop.match_cate_code.split('_');

        categoryCode = '';
        let idx = 1;

        while (!categoryCode) {
          categoryCode = arr[arr.length - idx];

          idx++;
        }

        if (categoryCode) {
          hasOpt = true;
        }
      }

      addData[shop.shop_cd].push(categoryCode);

      // 추천 옵션
      let data;
      let res;

      try {
        if (hasOpt) {
          try {
            res = await categoryModel.getScrapCategoryOption({ shopList: addData });

            data = res.data.result;

            if (!data || !data.length) {
              throw 'error';
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '옵션 정보 불러오기 실패');

            return false;
          }
        } else {
          return false;
        }

        const items = data[0].etc.options?.filter(option => !Object.prototype.hasOwnProperty.call(option, 'ObjOptNo') || option.ObjOptNo) || [];

        if (['A001', 'A006'].includes(shop.shop_cd)) {
          // 값 비교를 위해 초기 호출정보 담아둠.
          prodOpt.esm_opt = angular.copy(onlineProductSVC.default_opt);
          prodOpt.esm_options_table = [];
          $scope.data.esm_options = [];

          // 직접입력 가능 카테고리인지 조회
          const esmDirectOptionDisableCategorys = await categoryModel.getEsmDirectOptionDisableCate();

          $scope.data.esmDirectOptionEnable = !(new Set(esmDirectOptionDisableCategorys.data.result)).has(shop.match_cate_code.replace(/_/g, '').padEnd(8, '0'))
          || res.data.result.some(cate => cate.shop_cd === $scope.data.pa_shop_cd && cate.etc.options.find(opt => opt.ObjOptNo === 0));

          const esmOptionsFlag = items.filter(row => row.ObjOptClaseName && row.ObjOptClaseName !== '' && row.TextUseYn !== 'Y');

          if (!esmOptionsFlag.length && !$scope.data.esmDirectOptionEnable) {
            $scope.disabled.esm_opt = true;
            $scope.data.esm_opt_type = '옵션없음';

            if (masterOptInfo?.length) {
              $scope.prodOpt[`${site[$scope.shop.shop_cd]}matchStockList`] = masterOptInfo;
            }
          } else {
            // prodOpt.esm_opt = [];
            prodOpt.esm_options_table = [];

            for (const row of esmOptionsFlag) {
              const className = row.ObjOptClaseName;
              const esm_opt = {};

              esm_opt.shop_cd = shop.shop_cd;
              esm_opt.name = className;

              if (!_.isUndefined(row.ObjOptNo) || esm_opt.name_cd == '') {
                esm_opt.name_cd = row.ObjOptNo;
              }

              esm_opt.cateOpAttr = row.DirectInsEnableYn === 'N';

              const res = await categoryModel.getScrapCategoryOptionDetail({ shop_cd: shop.shop_cd, opt_cd: row.ObjOptNo });

              if (res.data.result && res.data.result.shop_cate_opt_detail) {
                esm_opt.cateOpAttrList = res.data.result.shop_cate_opt_detail;
              }

              $scope.data.esm_options.push(esm_opt);
            }
          }
        } else if (shop.shop_cd === 'A092') {
          prodOpt.alibaba_opt = angular.copy(onlineProductSVC.default_opt);
          prodOpt.alibaba_options_table = [];
          $scope.data.alibaba_options = [];

          if (!items.length) {
            $scope.disabled.alibaba_opt = true;
            $scope.data.alibaba_opt_type = '옵션없음';
          } else {
            for (const item of items) {
              const ali_opt = {
                shop_cd: shop.shop_cd,
                name: item.ObjOptClaseName,
                name_cd: item.ObjOptNo
              };

              const res = await categoryModel.getScrapCategoryOptionDetail({ shop_cd: shop.shop_cd, opt_cd: item.ObjOptNo });

              if (res.data.result && res.data.result.shop_cate_opt_detail?.length) {
                ali_opt.cateOpAttrList = res.data.result.shop_cate_opt_detail;
              }

              $scope.data.alibaba_options.push(ali_opt);
            }
          }
        } else if (shop.shop_cd === 'A093') {
          prodOpt.aliexpress_opt = angular.copy(onlineProductSVC.default_opt);
          prodOpt.aliexpress_options_table = [];
          $scope.data.aliexpress_options = [];

          if (!items.length) {
            $scope.disabled.aliexpress_opt = true;
            $scope.data.aliexpress_opt_type = '옵션없음';
          } else {
            for (const item of items) {
              const ali_opt = {
                shop_cd: shop.shop_cd,
                name: item.ObjOptClaseName,
                name_cd: item.ObjOptNo
              };

              const res = await categoryModel.getScrapCategoryOptionDetail({ shop_cd: shop.shop_cd, opt_cd: item.ObjOptNo });

              if (res.data.result && res.data.result.shop_cate_opt_detail?.length) {
                ali_opt.cateOpAttrList = res.data.result.shop_cate_opt_detail;
              }

              $scope.data.aliexpress_options.push(ali_opt);
            }
          }
        } else {
          const stdOptionsFlag = _.filter(items, function (row) {
            return row.stdOptNm && row.stdOptNm !== '';
          });

          if (!stdOptionsFlag.length) {
            $scope.disabled.std_opt = true;
            $scope.data.std_opt_type = '옵션없음';

            if (masterOptInfo?.length) {
              $scope.prodOpt[`${site[$scope.shop.shop_cd]}matchStockList`] = masterOptInfo;
            }
          } else {
            prodOpt.std_opt = [];
            prodOpt.std_options_table = [];

            for (const row of stdOptionsFlag) {
              const std_opt = {};

              std_opt.required = row.stdOptRecmYn === 'Y';
              std_opt.title = `${std_opt.required ? '(추천)' : ''}${row.stdOptNm}(${row.stdOptTypCd})`;
              std_opt.name = row.stdOptNm;
              std_opt.name_cd = row.stdOptNo;
              std_opt.shop_cd = row.shop_cd;
              std_opt.typeCd = row.stdOptTypCd;

              if (row.stdOptNo) {
                const res = await categoryModel.getScrapCategoryOptionDetail({ shop_cd: 'A112', opt_cd: row.stdOptNo });

                if (res.data.result && res.data.result.shop_cate_opt_detail) {
                  std_opt.cateOpAttrList = res.data.result.shop_cate_opt_detail;
                }
              }

              prodOpt.std_opt.push(std_opt);
            }
            //체크박스 초기화
            opt_checkbox_init('std');
          }
        }

        const inputOptionsFlag = _.filter(items, function (row) {
          const className = row.ObjOptClaseName;

          return className && row.TextUseYn == 'Y';
        });

        //입력형 옵션
        if (inputOptionsFlag.length) {

          _.each(inputOptionsFlag, function (row) {
            const className = row.ObjOptClaseName;

            prodOpt.esm_input_list.push(className);
            prodOpt.esm_input_list = _.uniq(prodOpt.esm_input_list);
          });

          $('.esm_input_opt').select2({
            minimumResultsForSearch: Infinity,
            tags: false,
            width: '100%',
            placeholder: '콤마(,) 또는 엔터(enter) 구분',
            closeOnSelect: false
          });
          $('.esm_input_opt').change(function () {
            const va = $(this).val() || [];

            prodOpt.esm_insert_opt_arr = va;
          });
        }

      } catch (err) {
        if (err !== 'SAME_OPTION') {
          commonSVC.showConfirmCustom({ title: '단일상품 옵션정보 호출에 실패했습니다.', text: '재시도 버튼을 클릭하시어 카테고리 호출을 다시 시도해 주세요.', confirmButtonText: '재시도' }, function (Confirm) {
            if (Confirm) {
              $scope.categoryOptions();
            }
          });
        }

        return false;
      } finally {
        $('.optionSpinner').addClass('hidden');
      }
    };

    //옵션 체크박스 초기화
    function opt_checkbox_init(type) {
      if (type == 'esm') {
        $scope.checked.esm_defaultOpt = false;
        $scope.checked.wmp_esm_defaultOpt = false;
      } else {
        //기본옵션을 std 옵션으로
        $scope.checked.std_defaultOpt = false;
        $scope.checked.wmp_std_defaultOpt = false;
      }

      //기본옵션을 esm 옵션으로
      $scope.checked.std_checkbox = false;
      $scope.checked.std_esm_defaultOpt = false;
    }

    $scope.$watch('shop.match_cate_code', $scope.categoryOptions);

    /**
     * 쇼핑몰 카테고리 매칭정보 open
     **/
    $scope.infoOpen = async (type) => {
      const resolve = {};

      resolve.data = {
        shop_cd: $scope[type].shop_cd,
        pa_shop_cd: $scope[type].pa_shop_cd,
        shop_id: $scope[type].shop_id,
        category_code: $scope.data.sol_cate_no,
        esm: { flag: false },
        cateEdit: true
      };

      try {
        const res = await categoryModel.shopInfoDetailDataLoad({
          categoryId: $scope.data.sol_cate_no,
          shop_cd: $scope[type].shop_cd,
          shop_id: $scope[type].shop_id
        });

        if (res.data.data && res.data.data.length) {
          resolve.data.shop_cate_no_add_info = res.data.data[0].sol_cate_shop_add_info;
        } else {
          resolve.data.shop_cate_no_add_info = null;
        }
      } catch (err) {
        commonSVC.showMessage('실패', '카테고리 로딩 실패');
      }

      const redata = commonSVC.openModal('full', resolve, 'OnlineCategoryMatchCtrl', 'views/online/product/modals/category_match.html');

      redata.result.then(async function (result) {
        const { data: esmExistYn } = await categoryModel.getEsmCategoryExistYn({
          match_cate_code: result.category_code,
          shop_cd: result.site_code,
        });

        $scope[type].existEsmYn = esmExistYn.result;
        $scope[type].match_cate_code = result.category_code;
        $scope[type].match_cate_name = result.category_names;
        $scope[type].shop_cate_no = result.shop_cate_no || 0;
        $scope[type].shop_cate_no_add_info = result.detailData;

        // 옵션테이블 초기화.
        $scope.optTableFn.changeCate();

        //shop_cate_no가 없을 경우
        if ($scope[type].shop_cate_no == 0) {

          const sData = {
            pa_shop_cd: $scope[type].pa_shop_cd,
            shop_cd: $scope[type].shop_cd,
            shop_id: $scope[type].shop_id,
            sol_cate_no: $scope.data.sol_cate_no,
            cate_code: result.category_code,
            cate_name: result.category_names
          };

          categoryModel.shopCategoryAdd(sData, function (state, data) {
            $scope[type].shop_cate_no = data.result;

          });
        }
      });
    };

    function getCurrentOpt(shop_cd) {
      let currentOpt = '';

      switch (shop_cd) {
        case 'A001':
        case 'A006':
          currentOpt = 'esm_';
          break;
        case 'A112':
          currentOpt = 'std_';
          break;
        case 'B719':
          currentOpt = 'wmp_';
          break;
        case 'B502':
          currentOpt = 'hplus_';
          break;
        case 'A092':
          currentOpt = 'alibaba_';
          break;
        case 'A093':
          currentOpt = 'aliexpress_';
          break;
      }

      return currentOpt;
    }

    //옵션 불러오기
    $scope.importOpt = function(type) {
      const resolve = {};
      const currentOpt = getCurrentOpt($scope.shop.shop_cd);

      resolve.data = {
        currentOpt,
        type,
        warehouseList: $scope.warehouseList,
        systemList,
        c_sale_cd: $scope.data.c_sale_cd
      };

      const modal = commonSVC.openModal('xg', resolve, 'importOpt', 'views/online/product/option/import_option.html');

      modal.result.then(function (re) {
        $scope.data[`${currentOpt}opt_type`] = re.opt_type;
        $scope.prodOpt.matchStockList = $scope.prodOpt[`${currentOpt}matchStockList`];

        const optData = onlineProductSVC.initOpt(re, $scope.prodOpt, currentOpt);

        if (re.opt_type == '옵션없음') {
          $scope.prodOpt[`${currentOpt}matchStockList`] = angular.copy(optData.matchStockList);
        } else {
          $scope.prodOpt[`${currentOpt}opt`] = angular.copy(optData.opt);

          // esm 상품은 추천옵션 포함한 경우 추천옵션 정보 설정해야 함
          if (currentOpt === 'esm_') {
            $scope.prodOpt.esm_opt.forEach((opt, i) => {
              const recommendOption = $scope.data.esm_options.find(cateOpt => opt.name === cateOpt.name);

              if (recommendOption?.cateOpAttrList?.filter(o => o.ObjOptEssenName)?.length) {
                opt.cateOpAttr = recommendOption.cateOpAttrList.filter(o => o.ObjOptEssenName);

                $timeout(() => {
                  const select2 = angular.element(document.querySelector(`#esm_opt_value${i}`));

                  if (select2) {
                    select2.controller('multiSelectCombo').destroySelect2(select2);
                  }
                  opt.attr = opt.attr.split(',');
                  $scope.selectOptList[i] = [
                    ...opt.cateOpAttr,
                    ...opt.attr
                      .filter(value => !opt.cateOpAttr.some(item => item.ObjOptEssenName === value))
                      .map(opt => { return { ObjOptEssenName: opt }; })
                  ];

                  $timeout(() => {
                    select2.controller('multiSelectCombo').initSelect2(select2, {});
                  }, 0);
                });
              } else {
                opt.cateOpAttr = [];
              }
            });
          }
        }

        const data = { opt: angular.copy($scope.prodOpt[`${currentOpt}opt`]) };

        $scope.prodOpt[`${currentOpt}options_table`] = onlineProductSVC.initOptionsTable(data, $scope.data[`${currentOpt}opt_type`], optData.optData);
      });
    };

    /**
     *  템플릿 리스트 닫기
     */
    $scope.listModal = function(param) {
      if (param == 'false') {
        $scope.templateListModal = false;
        $scope.esmTemplateListModal = false;
      }
    };

    /**
     * 등록
     */
    $scope.onSubmit = _.throttle(async () => {

      if ($scope.checkEsmMaster.checked && !$scope.esmMatchShop) {
        $scope.esmIdCheck = true;
      }

      const shop = $scope.shop;
      const origin = data.result.map(o => {
        return {
          sale_no: o.sale_no,
          ol_shop_no: o.ol_shop_no,
          opt_type: o.opt_type,
          add_single_prod: $scope.checked.smileProd ? 2 : 1,
          shop_id: o.shop_id,
          shop_cd: o.shop_cd,
          sol_cate_no: o.sol_cate_no
        };
      });

      try {

        const prodOptionsTable = $scope.prodOpt[`${site[shop.shop_cd]}options_table`];
        const combOptData = $scope.prodOpt[`${site[shop.shop_cd]}opt`];

        // 물류상품인경우 매칭된 SKU가 G마켓물류 배송처인지 체크필요.
        if ($scope.checked.smileProd && prodOptionsTable.some(o => (o.set_no || o.sku_cd) && !$scope.ebayDepots.includes(o.depot_no))) {
          commonSVC.showMessage('물류상품 추가 에러', ' G마켓물류배송처 SKU를 매칭해주세요.');

          return false;
        }

        if (site[shop.shop_cd] == 'std_') {
          for (const prodOption of prodOptionsTable) {
            for (let i = 0; i < 3; i++) {
              if (!prodOption[`name${i + 1}`]) {
                continue;
              }
              const combOptCurr = combOptData.find(({ name_cd }) => name_cd === prodOption[`name${i + 1}_cd`]);

              // 11번가 N형 옵션일 경우, attr에 단위 제거 및 attr_cd에 단위 코드 삽입
              if (combOptCurr?.typeCd === 'N' && prodOption[`attr${i + 1}_cd`] == 0) {
                let cateOptAttr = combOptCurr.cateOpAttrList.find(({ stdOptValNm }) =>
                  stdOptValNm === prodOption[`attr${i + 1}`].replace(/[0-9]/g, '').trim() ||
                    stdOptValNm === (prodOption[`attr${i + 1}_title`] ? prodOption[`attr${i + 1}_title`].replace(/[0-9]/g, '').trim() : '')
                );

                if (!cateOptAttr) {
                  cateOptAttr = combOptCurr.cateOpAttrList.find(({ stdOptValNo }) => stdOptValNo === combOptData[i].stdOpt);
                }
                prodOption[`attr${i + 1}`] = prodOption[`attr${i + 1}`].replace(cateOptAttr.stdOptValNm, '');
                prodOption[`attr${i + 1}_title`] = `${prodOption[`attr${i + 1}`]}${cateOptAttr.stdOptValNm}`;
                prodOption[`attr${i + 1}_cd`] = cateOptAttr.stdOptValNo;
              }
            }
          }
        } else if (['esm_', 'alibaba_', 'aliexpress'].includes(site[shop.shop_cd])) {
          for (const prodOption of prodOptionsTable) {
            for (let i = 0; i < 3; i++) {
              if (!prodOption[`name${i + 1}`]) {
                continue;
              }
              const combOptCurr = combOptData.find(({ name_cd }) => name_cd === prodOption[`name${i + 1}_cd`]);

              if (combOptCurr?.cateOpAttr?.length) {
                const cateOptAttr = combOptCurr.cateOpAttr.find(cate => cate.ObjOptEssenName === prodOption[`attr${i + 1}`]);

                if (cateOptAttr) {
                  prodOption[`attr${i + 1}_cd`] = cateOptAttr.ObjOptEssenNo;
                }
              }
            }
          }
        }

        // 옵션구분 추천옵션코드가 없는 경우 넣어줌
        if (site[shop.shop_cd] === 'esm_' && $scope.data.esm_opt_type !== '옵션없음' && $scope.prodOpt.esm_opt.length) {
          $scope.prodOpt.esm_opt.map(o => {
            if (o.name) {
              const recommendOption = $scope.data.esm_options.find(item => item.name === o.name);

              if (recommendOption) {
                o.name_cd = recommendOption.name_cd;
              } else {
                o.name_cd = 0;
              }
            }
          });
        }

        if ($scope.data[`${site[shop.shop_cd]}opt_type`] !== '옵션없음' && !prodOptionsTable.length) {
          commonSVC.showMessage('옵션테이블이 없습니다. 옵션을 생성해주세요.');

          return false;
        }

        const add = [{
          template_no: shop.template_no || '',
          shop_id: shop.shop_id,
          shop_cd: shop.shop_cd,
          pa_shop_cd: shop.pa_shop_cd,
          shop_name: shop.shop_name,
          shop_cate_no: shop.shop_cate_no,
          shop_cate_no_add_info: shop.shop_cate_no_add_info,        // 단일상품에서 필수
          opt_type: $scope.data[`${site[shop.shop_cd]}opt_type`],   // data.esm_opt_type
          isSmileProd: $scope.checked.smileProd || false            // 물류상품 여부
        }];
        const exceptShopCds = ['B719', 'A112', 'A001', 'A006', 'A092', 'A093'];
        const option_data = {};

        if (['조합형', '독립형'].includes(add[0].opt_type)) {
          for (const [index, elem] of combOptData.filter(({ checked }) => checked || ['A001', 'A006', 'A112', 'B719', 'A092', 'A093'].includes(shop.shop_cd)).entries()) {
            option_data[`opt_sort${index + 1}`] = elem?.checked || exceptShopCds.includes(shop.shop_cd) ? elem.name : '';
            option_data[`opt_sort${index + 1}_desc`] = elem?.checked || exceptShopCds.includes(shop.shop_cd) ? (['A001', 'A006'].includes(shop.shop_cd) && typeof elem.attr !== 'string' ? elem.attr.join() : elem.attr) : '';
            option_data[`opt_sort${index + 1}_cd`] = elem?.checked || exceptShopCds.includes(shop.shop_cd) ? elem?.name_cd : '';
          }

          option_data.options_prodList = prodOptionsTable;
        } else {
          option_data.options_prodList = $scope.prodOpt[`${site[shop.shop_cd]}matchStockList`];
        }

        // 옵션 테이블에 직접 값을 추가하는경우 option_data 의 desc 항목에 값이 누락되기 때문에 추가해줘야 함.
        if (['조합형', '독립형'].includes(add[0].opt_type) && prodOptionsTable.length) {
          for (let idx = 1; idx <= 3; idx++) {
            if (!prodOptionsTable[0][`attr${idx}`]) {
              continue;
            }

            prodOptionsTable.forEach(o => {
            // 동일한 컬럼과 매치업
              const matched = [o.name1, o.name2, o.name3].findIndex(e => e === option_data[`opt_sort${idx}`]);

              if (matched !== -1 && option_data[`opt_sort${idx}_desc`].indexOf(o[`attr${matched + 1}`]) < 0) {
                option_data[`opt_sort${idx}_desc`] = option_data[`opt_sort${idx}_desc`] + ',' + o[`attr${matched + 1}`];
              }
            });
          }
        }

        if ($scope.prodOpt[`${site[shop.shop_cd]}insert_opt_arr`].length) {
          option_data.insert_opt = JSON.stringify(_.map($scope.prodOpt[`${site[shop.shop_cd]}insert_opt_arr`], function (row) {
            return site[shop.shop_cd] === 'esm_' ? { insert_opt: row } : { insert_opt: row.insert_opt };
          }));
        }

        if (['조합형', '독립형'].includes(add[0].opt_type)) {
          option_data.options_prodList.forEach(function (prod) {
            prod.opt_name1 = prod.attr1 || '';
            prod.opt_name2 = prod.attr2 || '';
            prod.opt_name3 = prod.attr3 || '';
          });
        }

        if (['조합형', '독립형'].includes(add[0].opt_type) && !option_data.opt_sort1) {
          option_data.opt_sort1 = option_data.opt_sort2;
          option_data.opt_sort2 = option_data.opt_sort3;
          option_data.opt_sort1_desc = option_data.opt_sort2_desc;
          option_data.opt_sort2_desc = option_data.opt_sort3_desc;
          option_data.opt_sort1_cd = option_data.opt_sort2_cd;
          option_data.opt_sort2_cd = option_data.opt_sort3_cd;
        }

        let sale_opt_seq = 1;

        _.forEach(option_data.options_prodList, function (option) {
          option.sale_opt_seq = sale_opt_seq;
          sale_opt_seq++;

          // 독립형인경우 값 수정
          if (add[0].opt_type === '독립형') {
            option.opt_name1 = option.name;
            option.opt_name2 = option.attr;
            option.name1 = option_data.opt_sort1;
            option.name2 = option_data.opt_sort2;
          }
        });

        add[0].option_data = option_data;

        if (['A001', 'A006'].includes(shop.shop_cd)) {
          const isESMCateEmpty = !shop.shop_cate_no_add_info || shop.shop_cate_no_add_info.catename_esm === '' || shop.shop_cate_no_add_info.catename_esm === undefined;

          if (shop.existEsmYn && isESMCateEmpty) {
            commonSVC.showMessage('ESM카테고리를 등록해주세요');

            return false;
          }
        }

        if (_.isUndefined($scope.shop.shop_cate_no) || ($scope.checkEsmMaster.checked && _.isUndefined($scope.esmMatchShop.shop_cate_no))) {
          commonSVC.showMessage('카테고리를 설정해 주십시오.');

          return false;
        }

        if (!$scope.form1.$valid || $scope.esmIdCheck) {
          commonSVC.showMessage('입력값이 유효하지 않거나 필수 입력값이 누락되었습니다.');

          return false;
        }

        if ($scope.checkEsmMaster.checked) {
          const addData = {
            template_no: $scope.esmMatchShop.template_no || '',
            shop_id: $scope.esmMatchShop.shop_id,
            pa_shop_cd: $scope.esmMatchShop.pa_shop_cd,
            shop_cd: $scope.esmMatchShop.shop_cd,
            shop_name: $scope.esmMatchShop.shop_name,
            shop_cate_no: $scope.esmMatchShop.shop_cate_no,
            shop_cate_no_add_info: $scope.esmMatchShop.shop_cate_no_add_info,
            opt_type: add[0].opt_type,
            option_data: add[0].option_data,
            matchEsmInfo: `${add[0].shop_cd}||${add[0].shop_id}`,
            isSmileProd: add[0].isSmileProd
          };

          add[0].matchEsmInfo = `${addData.shop_cd}||${addData.shop_id}`;

          // 지마켓 먼저 보냄
          add[0].shop_cd === 'A006' ? add.push(addData) : add.unshift(addData);
        }

        const res = await onlineProductModel.addSlaveToMaster(_.uniqBy(origin, 'sale_no'), add);

        if (res.data.results === 'success') {
          if (res.data.prods.length) {
            commonSVC.showToaster('success', '성공', '쇼핑몰 상품이 추가되었습니다.');
            $uibModalInstance.close(data.isMaster ? data.result : 'refresh');
          } else {
            throw 'duplicated online product';
          }
        }
      } catch (err) {
        const errMsg = errorSVC.getError('prod', err.data ? err.data.messages[0] : err, '쇼핑몰 상품 추가에 실패하였습니다.');

        commonSVC.showToaster('error', '실패', errMsg);

        if (errMsg === '쇼핑몰 상품 등록 DB 한도 초과') {
          commonSVC.showMessage('LITE버전 쇼핑몰 상품 DB 한도 초과', '쇼핑몰 상품은 5,000개 이하로 관리해 주세요.\n초과되는 상품은 삭제 또는 버전 업그레이드 후 이용하실 수 있습니다.\n(업그레이드 시 더 이상 무료 버전은 이용할 수 없습니다.)');
        }

        return false;
      } finally {
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      }
    }, 2000, { trailing: false });

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

  }
})();
