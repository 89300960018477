'use strict';

import Home from '../../../react_src/pages/imageHosting/Home';

angular.module('gmpApp')
  .controller('imageHostingCtrl', function ($scope, data, userInfo, $uibModalInstance) {
    $scope.imageHostingProps = {
      returnType: data.returnType,
      handleClose: function(result) {
        $uibModalInstance.close(result);
      },
      userInfo: userInfo.user
    };
  })
  .value('imageHostingComponent', Home);
