import React, { useState, CSSProperties, useMemo, useEffect } from 'react';
import Pagination from './Pagenation';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  RowSelectionState,
  // getFilteredRowModel,
  // getPaginationRowModel,
  // getSortedRowModel,
  // SortingState,
  // ColumnFiltersState,
  // createColumnHelper,
  // getSelectedRowModel,
} from '@tanstack/react-table';

interface DatatableProps {
  dataList: any[];
  columns: any[];
  setSelectedList: (selectedList: any[]) => void;
  totalPages: number;
  setSearchLogParams: (params: any) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
}

const Datatable: React.FC<DatatableProps> = ({
  dataList,
  columns,
  setSelectedList,
  totalPages,
  setSearchLogParams,
  pageSize,
  setPageSize,
}) => {
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  // const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  // const [sorting, setSorting] = useState<SortingState>([]);

  /**
   * 선택 항목 변경시 selectList 에 저장
   */
  useEffect(() => {
    const selectedRows = table.getSelectedRowModel().rows.map((row) => row.original);

    if (selectedRows.length) {
      setSelectedList(selectedRows);
    } else {
      setSelectedList([]);
    }
  }, [rowSelection]);

  const handleMouseOver = (id: string) => {
    setHoveredRow(id);
  };

  const handleMouseOut = () => {
    setHoveredRow(null);
  };

  /**
   * 페이지 변경
   */
  const handlePageChange = (page: number) => {
    setCurrentPage(page);

    setSearchLogParams((prevParams) =>
      ({ ...prevParams, start: (page - 1) * pageSize })
    );
  };

  const tableData = useMemo(() => dataList, [dataList]);
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: pageSize || 50
      },
    },
    defaultColumn: {
      size: 150, // 기본 너비 설정
      minSize: 50,
      maxSize: 500
    },
    columnResizeMode: 'onChange',
    enableRowSelection: true
  });

  return (
    <div style={styles.dataTableArea}>
      <div style={styles.btnGroupContainer}>
        <div style={{ ...styles.logCntBox, paddingTop: '15px' }}>
          총 <span style={styles.logCnt}>{(dataList.length).toLocaleString()}</span>건
        </div>
        <div style={styles.statusArea}>
          <div>
            <select
              className="form-control"
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              <option value="50">50건씩 보기</option>
              <option value="100">100건씩 보기</option>
              <option value="200">200건씩 보기</option>
            </select>
          </div>
        </div>
      </div>

      <table className="table table-gmp table-gmp-border">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    ...styles.th,
                    width: header.getSize()
                  }}
                  onMouseDown={header.getResizeHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {!dataList.length && (
            <tr style={{ height: '300px' }}>
              <td colSpan={columns.length}>
                <div style={styles.isEmptyLogBox}>
                  <p style={styles.isEmptyLogText}>로그가 없습니다.</p>
                </div>
              </td>
            </tr>
          )}
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onMouseOver={() => handleMouseOver(row.id)}
              onMouseOut={handleMouseOut}
              onClick={row.getToggleSelectedHandler()}
              style={{
                backgroundColor: row.getIsSelected()
                  ? '#d3d3d3'
                  : hoveredRow === row.id
                    ? '#f0f0f0'
                    : 'transparent',
                cursor: 'pointer',
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} style={styles.td}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!!dataList.length && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  btnGroupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  statusArea: {
    display: 'flex',
  },
  logCntBox: {
    marginRight: '10px',
    fontSize: '12px',
    color: '#545454',
    fontWeight: '600',
  },
  logCnt: {
    color: '#1E88E5',
  },
  headerCheckbox: {
    marginLeft: '4px',
  },
  rowCheckbox: {
    marginLeft: '4px',
  },
  dataTableArea: {
    flex: 1,
    marginTop: '10px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '6px',
  },
  th: {
    // padding: '10px',
    textAlign: 'center',
    // borderBottom: '2px solid #ccc',
    // backgroundColor: '#f8f8f8'
  },
  td: {
    padding: '10px',
    textAlign: 'center',
    borderBottom: '1px solid #ddd',
  },
  isEmptyLogBox: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  isEmptyLogText: {
    paddingTop: '60px',
    fontSize: '14px',
    color: '#999',
    fontWeight: '500',
    letterSpacing: '-0.42px',
    background: 'url(../../assets/images/imagehosting/ico_is_empty_log.svg) no-repeat center top',
  },
};

export default Datatable;